import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL as URLs } from "../../utils/Constants";

class WorksheetHeader extends Component {
  state = {
    date: "",
    subject: "",
    subjects: [],
    title: "",
    yearLevel: "",
    yearLevels: [],
  };

  componentDidMount() {
    this.getYearLevels();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clearInputField !== this.props.clearInputField) {
      this.setState({
        date: "",
        subject: "",
        title: "",
        yearLevel: "",
      });
    }
  }

  getSubjectsByYearLevel = () => {
    let param = { yearLevelId: this.state.yearLevel };
    axiosPost(URLs.getSubjectForPlanner, param, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        this.setState({ subjects: datum });
      }
    });
  };

  getYearLevels = () => {
    axiosPost(URLs.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "yearLevel") {
        this.setState({ subjects: [], subject: "" }, function () {
          this.getSubjectsByYearLevel();
        });
      }
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-1 mt-1 text-right pr-0">
            <strong>
              Year Level{" "}
              {!this.props.value && (
                <span className="tt-assessment-module-mandatory">*</span>
              )}
            </strong>
          </div>

          <div className="col">
            <select
              className="form-control"
              name="yearLevel"
              value={this.state.yearLevel}
              onChange={this.handleChange}
              placeholder="Choose Year Level"
            >
              <option value="" disabled selected>
                Choose Year Level
              </option>
              {this.state.yearLevels.length > 0
                ? this.state.yearLevels.map((yLvl, idx) => {
                    return (
                      <option value={yLvl.id} key={idx}>
                        {yLvl.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>

          <div className="col-md-1 mt-1 text-right">
            <strong>
              Subject{" "}
              {!this.props.value && (
                <span className="tt-assessment-module-mandatory">*</span>
              )}
            </strong>
          </div>

          <div className="col">
            <select
              className="form-control"
              name="subject"
              value={this.state.subject}
              onChange={this.handleChange}
              placeholder="Choose Subject"
              disabled={this.state.yearLevel === ""}
            >
              <option value="" disabled selected>
                Choose Subject
              </option>
              {this.state.subjects.length > 0
                ? this.state.subjects.map((sub, idx) => {
                    return (
                      <option value={sub.id} key={idx}>
                        {sub.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>

          <div className="col-md-1 mt-1 text-right">
            <strong>
              Date{" "}
              {!this.props.value && (
                <span className="tt-assessment-module-mandatory">*</span>
              )}
            </strong>
          </div>

          <div className="col">
            <input
              type="date"
              className="form-control"
              name="date"
              value={this.state.date}
              onChange={this.handleChange}
              min={new Date().toISOString().split("T")[0]}
            ></input>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1 mt-1 text-right">
            <strong>
              Title
              {!this.props.value && (
                <span className="tt-assessment-module-mandatory">*</span>
              )}
            </strong>
          </div>

          <div className="col">
            <input
              type="text"
              placeholder="Enter Title"
              name="title"
              value={this.state.title}
              className="form-control"
              onChange={this.handleChange}
            ></input>
          </div>

          {this.props.value ? (
            <div className="col-md-2">
              <button
                style={{
                  justifyItems: "center",
                  justifyContent: "center",
                  marginTop: "2px",
                }}
                className="tt-button tt-button-primary text-center"
                onClick={this.props.handleWorkSheetSearch}
              >
                Search
              </button>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default WorksheetHeader;
