import React, { Component } from "react";
import { Table } from "reactstrap";
import moment from "moment";

export default class GeneralAssessmentForm extends Component {
  render() {
    return (
      <div>
        <Table bordered striped className="tt-create-assessment-maintable mb-0">
          <tr style={{ background: `rgba(66, 118, 234, 0.3)` }}>
            <td style={{ verticalAlign: "middle" }}>
              <input
                type="checkbox"
                name="generalAssessmentCheck"
                checked={this.props.classroom.generalAssessmentChecked}
                onClick={(e) =>
                  this.props.handleGeneralAssessmentCheckbox(
                    e,
                    this.props.yearLevelIndex,
                    this.props.classroomIndex
                  )
                }
              />
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <strong>General Assessment</strong>
            </td>
            <td>
              <strong>From: </strong>
              <input
                type="date"
                name="assessmentFromDate"
                value={this.props.classroom.assessmentFromDate}
                onChange={(e) =>
                  this.props.handleGeneralAssessmentDateChange(
                    e,
                    this.props.yearLevelId,
                    this.props.classroomId,
                    "assessmentFromDate"
                  )
                }
                min={this.props.minStartDate}
                max={this.props.maxEndDate}
                className="autoFormControl"
                // onKeyDown={(e) => e.preventDefault()}
              />
            </td>
            <td>
              <strong>To: </strong>
              <input
                type="date"
                name="assessmentToDate"
                className="autoFormControl"
                style={{ width: "80%" }}
                value={this.props.classroom.assessmentToDate}
                onChange={(e) =>
                  this.props.handleGeneralAssessmentDateChange(
                    e,
                    this.props.yearLevelId,
                    this.props.classroomId,
                    "assessmentToDate"
                  )
                }
                min={this.props.classroom.assessmentFromDate}
                max={this.props.maxEndDate}
                // onKeyDown={(e) => e.preventDefault()}
              />
            </td>
            <td>
              <strong>Recording Date: </strong>
              <input
                type="date"
                className="autoFormControl"
                style={{ width: "55%" }}
                name="recordingDate"
                value={
                  this.props.classroom.recordingDate
                    ? this.props.classroom.recordingDate
                    : ""
                }
                onChange={(e) =>
                  this.props.handleGeneralAssessmentDateChange(
                    e,
                    this.props.yearLevelId,
                    this.props.classroomId,
                    "recordingDate"
                  )
                }
                min={moment(new Date()).format("YYYY-MM-DD")}
                // onKeyDown={(e) => e.preventDefault()}
              />
            </td>
            <td>
              <strong>Deadline: </strong>
              <input
                type="date"
                name="recordingDeadlineDate"
                className="autoFormControl"
                style={{ width: "65%" }}
                value={
                  this.props.classroom.recordingDeadlineDate
                    ? this.props.classroom.recordingDeadlineDate
                    : ""
                }
                disabled={
                  this.props.classroom.recordingDate === "" ||
                  !this.props.classroom.recordingDate
                }
                onChange={(e) =>
                  this.props.handleGeneralAssessmentDateChange(
                    e,
                    this.props.yearLevelId,
                    this.props.classroomId,
                    "recordingDeadlineDate"
                  )
                }
                min={this.props.classroom.recordingDate}
                // onKeyDown={(e) => e.preventDefault()}
                disabled={
                  this.props.classroom.recordingDate &&
                  this.props.classroom.recordingDate !== ""
                    ? false
                    : true
                }
              />
            </td>
          </tr>
        </Table>
      </div>
    );
  }
}
