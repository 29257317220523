import React, { Component } from "react";
import avatar from "../../../assets/images/user-image.png";
import moment from "moment";
import {
  URL,
  reactServer,
  draggable,
  checkRolePermissions,
  profilePeopleId,
  modules,
} from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import JoditEditor from "jodit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import ModalWindow from "../../UI/ModalWindow";
import PostQuestion from "../PostSection/PostQuestion";
import { displayConfirmDeleteAlert, handleError } from "../../../utils/Utils";
import swal from "sweetalert";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import AddTheme from "../PostSection/AddTheme";
const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: ["file", "about"],
  uploader: {
    insertImageAsBase64URI: true,
    // url: "www.google.com"
  },
};
class MediaAcademicRepoEditModal extends Component {
  state = {
    id: "",
    heading: "",
    peoples: [],
    postKeywords: [],
    postThemes: [],
    textContent: "",
    themeList: [],
    selectedOrigin: "",
    selectedLinks: "",
    addQuestionModal: false,
    links: "",
    selectedSubjects: [],
    subjectList: [],
    questions: [
      {
        question: "",
      },
    ],
    selectedImages: [],
    embededLink: "",
    addThemeModal: false,
    theme: "",
    description: "",
  };

  handleAddThemeClick = (e) => {
    this.setState((prevState) => ({
      addThemeModal: !prevState.addThemeModal,
    }));
  };

  handleAddThemeSubmit = (e) => {
    if (this.state.theme !== "") {
      e.preventDefault();
      let param = {
        theme: this.state.theme,
        description: this.state.description,
      };
      axiosPost(URL.insertTheme, param, (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, "Theme is successfully added");
          this.handleAddThemeClick();
          this.getThemeList();
          this.setState({ theme: "", description: "" });
        } else {
          swal(MESSAGEHEADER.error, response.message);
        }
      });
    } else {
      swal({ title: "Error", text: "Theme is empty" });
    }
  };

  componentDidMount() {
    this.getThemeList();
    this.setState({
      id: this.props.post.id,
      heading: this.props.post.heading,
      peoples: this.props.post.peoples,
      postThemes:
        this.props.post.postThemes.length > 0
          ? this.props.post.postThemes[0].themeId
          : "",
      textContent: this.props.post.textContent,
      subjectList: this.props.subjectList,
    });
    this.manageSubjects();
    this.managePostKeywords();
    this.managePostQuestions();
    this.manageContents();
  }

  //For theme
  getThemeList = () => {
    axiosPost(URL.selectTheme, {}, (response) => {
      if (response.status === 200) {
        this.setState({ themeList: response.data.data });
      }
    });
  };

  manageContents = () => {
    let data = this.props.post.contents;
    let image = [];
    let links = "";
    let embededLink = "";
    if (data.length > 0) {
      data.forEach((el) => {
        if (el.contentTypeCode === "IMG") {
          el.updatedData = true;
          image.push(el);
        }
        if (el.contentTypeCode === "LNK") {
          links = el.content;
          embededLink = this.getEmbedLink(links);
        }
      });
    }
    this.setState({
      selectedImages: image,
      selectedLinks: links,
      embededLink: embededLink,
    });
  };

  manageSubjects = () => {
    let data = this.props.post.subjects;
    let selectedSubjects = [];
    if (data && data.length > 0) {
      data.forEach((el) => {
        selectedSubjects.push(el.subjectName);
      });
    }
    this.setState({ selectedSubjects: selectedSubjects });
  };

  managePostKeywords = () => {
    let data = this.props.post.postKeywords;
    let newValue = "";
    if (data.length > 0) {
      data.forEach((el) => {
        if (newValue === "") {
          newValue = el.keyword;
        } else {
          newValue = newValue + ", " + el.keyword;
        }
      });
    }
    this.setState({ postKeywords: newValue });
  };

  managePostQuestions = () => {
    let data = this.props.post.postQuestions;
    let questions = [];
    if (data.length > 0) {
      data.forEach((el) => {
        questions.push({
          question: el.question,
        });
      });
    } else {
      questions.push({
        question: "",
      });
    }
    this.setState({ questions: questions });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "links") {
        if (
          this.state.links.includes("youtube") ||
          this.state.links.includes("youtu.be")
        )
          this.setState({ selectedOrigin: "youtube" });
        if (this.state.links.includes("vimeo"))
          this.setState({ selectedOrigin: "vimeo" });
        if (
          this.state.links.includes("dailymotion") ||
          this.state.links.includes("dai.ly")
        )
          this.setState({ selectedOrigin: "dailymotion" });
        if (this.state.links.includes("slideshare"))
          this.setState({ selectedOrigin: "slideshare" });
        if (this.state.links.includes(reactServer))
          this.setState({ selectedOrigin: "insitePost" });
        if (this.state.links.includes("soundcloud"))
          this.setState({ selectedOrigin: "soundcloud" });
      }
    });
  };

  handleLink = () => {
    if (this.state.links !== "") {
      let embedLink = this.getEmbedLink(this.state.links);
      this.setState(
        {
          selectedLinks: this.state.links,
          embededLink: embedLink,
        },
        function () {
          this.setState({ links: "" });
        }
      );
    } else {
      swal({
        title: "Error",
        text: "add Link first",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    }
  };

  removePreviewURL = () => {
    this.setState({ selectedLinks: "", embededLink: "" });
  };

  handleSubjectChange = (e) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ selectedSubjects: value });
  };

  insertArticleKeyword = (e, value) => {
    e.preventDefault();
    let newValue;
    let keywords = this.state.postKeywords;
    if (keywords.slice(-1) !== "," && keywords.slice(-1) !== "") {
      newValue = keywords + ", " + value;
    } else if (keywords.slice(-1) === "") {
      newValue = keywords + value;
    } else {
      newValue = keywords + " " + value;
    }
    this.setState({ postKeywords: newValue });
  };

  handleTextEditor = (value) => {
    this.setState({ textContent: value });
  };

  //For links
  getEmbedLink = (link) => {
    if (link !== undefined) {
      let embeddedURL = "";
      let videoId = "";
      if (link.includes("youtube") || link.includes("youtu.be")) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = link.match(regExp);
        if (match && match[7].length === 11) {
          videoId = match[7];
          embeddedURL = `https://www.youtube.com/embed/${videoId}`;
        }
      } else if (link.includes("dailymotion") || link.includes("dai.ly")) {
        videoId = this.getDailyMotionId(link);
        embeddedURL = `https://www.dailymotion.com/embed/video/${videoId}`;
      } else if (link.includes("vimeo")) {
        videoId = this.getVimeoId(link);
        embeddedURL = `https://player.vimeo.com/video/${videoId}`;
      } else if (link.includes("slideshare")) {
        let self = this;
        let src = "";
        if (self.state.slideShareURl === "") {
          $.getJSON(
            "https://noembed.com/embed",
            { format: "json", url: link },
            function (data) {
              if (data !== undefined || data) {
                let htmlCode = data.html;
                src = self.getSlideShareSrc(htmlCode);
                self.setState({ slideShareURl: src });
              }
            }
          );
        }
        embeddedURL = this.state.slideShareURl;
      }
      return embeddedURL;
    }
  };

  getVimeoId = (url) => {
    var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    var parseUrl = url.match(regExp);
    return parseUrl[5];
  };

  getDailyMotionId = (url) => {
    var m = url.match(
      /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
    );
    if (m === null) {
      var n = url.match(
        /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
      );
      if (n !== null) {
        if (n[4] !== undefined) {
          return n[4];
        }
        return n[2];
      }
    } else if (m !== null) {
      if (m[4] !== undefined) {
        return m[4];
      }
      return m[2];
    } else {
      return null;
    }
  };

  //For qUESTIONs
  handleAddQuestionClick = (e) => {
    this.setState((prevState) => ({
      addQuestionModal: !prevState.addQuestionModal,
    }));
  };

  handleAddMoreQuestion = (e) => {
    this.setState((prevState) => ({
      questions: [
        ...prevState.questions,
        {
          question: "",
        },
      ],
    }));
  };

  handleRemoveQuestion = (e, index) => {
    e.preventDefault();
    displayConfirmDeleteAlert(index, this.handleDeleteQuestion);
  };

  handleDeleteQuestion = (id) => {
    let questions = [...this.state.questions];
    questions.splice(id, 1);
    this.setState({ questions });
  };

  handleQuestionChange = (e, index) => {
    let questions = [...this.state.questions];
    let name = e.target.name;
    let value = e.target.value;
    questions[index][name] = value;
    this.setState({ questions });
  };

  deleteQuestions = (e, idx) => {
    let questions = [...this.state.questions];
    questions.splice(idx, 1);
    this.setState({ questions });
  };

  //For image
  handleFileSelect = (e) => {
    e.preventDefault();
    let data = e.target.files;
    let files = [...this.state.selectedImages];
    for (let i = 0; i < data.length; i++) {
      files.push(data[i]);
      this.setState({ selectedImages: files });
    }
  };

  deleteFileContent = (e, idx) => {
    let files = [...this.state.selectedImages];
    files.splice(idx, 1);
    this.setState({ selectedImages: files });
  };

  submitData = (e) => {
    e.preventDefault();
    if (
      this.state.selectedLinks !== "" ||
      this.state.selectedImages.length > 0
    ) {
      let postKeywords = [];
      let postThemes = [
        {
          themeId: this.state.postThemes,
        },
      ];
      let subjects = [];
      if (this.state.selectedSubjects.length > 0) {
        this.state.selectedSubjects.forEach((el) => {
          subjects.push({
            subjectName: el,
          });
        });
      }
      let keywords = this.state.postKeywords.split(", ");
      keywords.forEach((el) => {
        postKeywords.push({
          keyword: el,
        });
      });
      let data = {};
      if (this.props.postSection) {
        data = {
          post: {
            id: this.state.id,
            isVerified: 0,
            textContent: this.state.textContent,
            heading: this.state.heading,
            contents:
              this.state.selectedLinks !== ""
                ? this.getMediaContentJSON(this.state.selectedLinks)
                : [],
            postThemes:
              postThemes[0].themeId === null || postThemes[0].themeId === ""
                ? []
                : postThemes,
            postKeywords: postKeywords[0].keyword === "" ? [] : postKeywords,
            postQuestions:
              this.state.questions.length > 0
                ? this.state.questions[0].question === ""
                  ? []
                  : this.state.questions
                : [],
            groups: [],
            peopleIds: [],
            postType: this.props.post.postType,
          },
          moduleCode: modules["Post Section"],
          profilePeopleId: profilePeopleId,
        };
        this.props.updateMediaAcademicRepo(e, data, this.state.selectedImages);
      } else {
        data = {
          id: this.state.id,
          isVerified: 0,
          textContent: this.state.textContent,
          heading: this.state.heading,
          contents:
            this.state.selectedLinks !== ""
              ? this.getMediaContentJSON(this.state.selectedLinks)
              : [],
          postThemes:
            postThemes[0].themeId === null || postThemes[0].themeId === ""
              ? []
              : postThemes,
          postKeywords: postKeywords[0].keyword === "" ? [] : postKeywords,
          postQuestions:
            this.state.questions[0].question === "" ? [] : this.state.questions,
          groups: [],
          peopleIds: [],
          postType: this.props.post.postType,
          subjects: subjects,
          postId: this.props.post.postId,
        };
        this.props.updateMediaAcademicRepo(e, data, this.state.selectedImages);
      }
    } else {
      swal({
        title: "Error",
        text: "Atleast attach an image or media link",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    }
  };

  getMediaContentJSON = (link) => {
    let contentArray = [
      {
        contentTypeCode: "LNK",
        content: link,
        origin: this.getOrigin(link),
      },
    ];
    return contentArray;
  };

  getOrigin = (link) => {
    if (link.includes("youtube") || link.includes("youtu.be")) return "youtube";
    if (link.includes("vimeo")) return "vimeo";
    if (link.includes("dailymotion") || link.includes("dai.ly"))
      return "dailymotion";
    if (link.includes(reactServer)) return "insitePost";
    if (link.includes("slideshare")) return "slideshare";
    if (link.includes("soundcloud")) return "soundcloud";
    return "others";
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "80vh", padding: "10px" }}
        >
          <div className="row">
            <div className="col-md-2 text-center">
              {this.props.post ? (
                this.props.post.peoples ? (
                  this.props.post.peoples.photo ? (
                    <img
                      //   src={require(`../../../assets/images/${this.props.post.peoples.photo}`)}
                      src={URL.imageSelectURL + this.props.post.peoples.photo}
                      alt="aca"
                      onError={(e) => handleError(e)}
                      className={
                        "img-postDisplay " +
                        (this.props.fullPage
                          ? "imageNewDimension1"
                          : "image-dimension")
                      }
                    ></img>
                  ) : (
                    <img
                      src={avatar}
                      alt="pp"
                      className={
                        "img-fluid " +
                        (this.props.fullPage
                          ? "imageNewDimension1"
                          : "image-dimension")
                      }
                    ></img>
                  )
                ) : (
                  <img
                    src={avatar}
                    alt="pp1"
                    className={
                      "img-fluid " +
                      (this.props.fullPage
                        ? "imageNewDimension1"
                        : "image-dimension")
                    }
                  ></img>
                )
              ) : null}
            </div>
            <div className="col-md-10">
              <div className="row">
                <p className="mb-0" style={{ lineHeight: "20px" }}>
                  <strong>
                    {this.props.post
                      ? this.props.post.peoples
                        ? this.props.post.peoples.name
                        : "Anonymous"
                      : "Anonymous"}
                    {this.props.post ? (
                      this.props.post.groupName ? (
                        <>&nbsp;&#62; {this.props.post.groupName}</>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}{" "}
                  </strong>
                </p>
              </div>
              <div className="row">
                {this.props.post ? (
                  <>
                    <span className="text-muted date-displayPosts">
                      <i>
                        posted {moment(this.props.post.createdAt).calendar()} (
                        {moment(this.props.post.createdAt).fromNow()})
                      </i>
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="row mt-1">
                <div className="col-md-1 mt-1">
                  <strong>Heading</strong>
                </div>
                <div className="col-md-11">
                  <input
                    type="text"
                    name="heading"
                    className="form-control"
                    value={this.state.heading}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-1 text-center mt-1">
                  <strong>Keywords</strong>
                </div>
                <div className="col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="postKeywords"
                    value={this.state.postKeywords}
                    onChange={this.handleChange}
                  ></input>
                  <div className="tt-buttonChips">
                    <div
                      className="green"
                      onClick={(e) =>
                        this.insertArticleKeyword(e, "Approaches")
                      }
                    >
                      Approaches
                    </div>
                    <div
                      className="blue"
                      onClick={(e) => this.insertArticleKeyword(e, "Skill")}
                    >
                      Skill
                    </div>
                    <div
                      className="red"
                      onClick={(e) => this.insertArticleKeyword(e, "Knowledge")}
                    >
                      Knowledge
                    </div>
                  </div>
                </div>
                <div className="col-md-1 text-center mt-1">
                  <strong>Theme</strong>
                </div>
                <div className="col-md-5">
                  <div className="row">
                    <div className="col">
                      <select
                        className="form-control"
                        name="postThemes"
                        value={this.state.postThemes}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled selected>
                          Select Theme
                        </option>
                        {this.state.themeList
                          ? this.state.themeList.map((theme, i) => (
                              <option value={theme.id}>{theme.theme}</option>
                            ))
                          : null}
                      </select>
                    </div>
                    {checkRolePermissions("add-theme") ? (
                      <div className="col-md-auto">
                        <button
                          className="tt-button tt-button-primary tt-button-fullWidth"
                          onClick={this.handleAddThemeClick}
                        >
                          Add Theme
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="tt-videoContainer">
                    {this.state.selectedImages.length > 0 ? (
                      <div className="tt-mediaImageHolder">
                        {this.state.selectedImages.map((image, idx) => {
                          return (
                            <div
                              key={idx}
                              className={idx === 0 ? "fullWidthImage" : ""}
                            >
                              <img
                                src={
                                  image.updatedData
                                    ? URL.imageSelectURL + image.content
                                    : window.URL.createObjectURL(image)
                                }
                                alt="media"
                                className={
                                  this.state.selectedImages.length > 1
                                    ? "imageMediaHeight"
                                    : ""
                                }
                              ></img>
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="tt-fileDelete"
                                onClick={(e) => {
                                  this.deleteFileContent(e, idx);
                                }}
                              ></FontAwesomeIcon>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <iframe
                        title="previewURL"
                        src={this.state.embededLink}
                        frameBorder="1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}
                    {this.state.selectedLinks === "" ? null : (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="tt-videoClose"
                        onClick={this.removePreviewURL}
                      ></FontAwesomeIcon>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <JoditEditor
                    value={this.state.textContent}
                    onChange={(e) => this.handleTextEditor(e)}
                    config={config}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <p>
                        <strong>Attach Image (jpg , png, gif)</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        id="myInput"
                        type="file"
                        ref={(ref) => (this.upload = ref)}
                        style={{ display: "none" }}
                        onChange={this.handleFileSelect}
                        multiple
                      />
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth"
                        onClick={() => {
                          this.upload.click();
                        }}
                        disabled={
                          this.state.selectedLinks !== "" ? true : false
                        }
                      >
                        Attach File
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">OR</div>
                  </div>
                  <div className="row text-center">
                    <p
                      className="tt-postNoticeHeading"
                      style={{ width: "100%" }}
                    >
                      Embed Media
                    </p>
                    <br></br>
                    <select
                      className="form-control"
                      name="selectedOrigin"
                      onChange={this.handleChange}
                      value={this.state.selectedOrigin}
                    >
                      <option value="" disabled>
                        Insite Post / Youtube / Vimeo / Dailymotion / SlideShare
                      </option>
                      <option value="insitePost">Insite Post</option>
                      <option value="youtube">Youtube</option>
                      <option value="vimeo">Vimeo</option>
                      <option value="dailymotion">Dailymotion</option>
                      <option value="slideshare">SlideShare</option>
                    </select>
                    <input
                      type="text"
                      name="links"
                      value={this.state.links}
                      onChange={this.handleChange}
                      placeholder="URL"
                      className="form-control mt-1"
                    ></input>
                    <div className="col text-center mt-1">
                      <button
                        className="tt-button tt-button-primary"
                        onClick={this.handleLink}
                        disabled={
                          this.state.selectedImages.length > 0 ? true : false
                        }
                      >
                        Add Media
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row mt-2">
                    <div className="col-md-8 text-right">
                      <p className="mb-0">
                        What questions does this article address ?{" "}
                      </p>
                    </div>
                    <div className="col-md-4 mb-2">
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth"
                        onClick={this.handleAddQuestionClick}
                      >
                        Add Questions
                      </button>
                    </div>
                  </div>
                  <div className="tt-postNoticeBoxes mt-0">
                    <strong>Questions</strong>
                    <div className="tt-postEmbeded">
                      {this.state.questions.length > 0 &&
                      this.state.questions[0].question !== ""
                        ? this.state.questions.map((question, idx) => {
                            return (
                              <div className="row" key={idx}>
                                <div className="col-md-11">
                                  <p>{question.question}</p>
                                </div>
                                <div className="col-md-1">
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={(e) =>
                                      this.deleteQuestions(e, idx)
                                    }
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            );
                          })
                        : "No questions added"}
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              {this.props.postSection ? null : (
                <div className="row mt-3">
                  <div className="col-md-2">
                    <strong>Assign Subjects</strong>
                    <br></br>
                    <i className="text-muted" style={{ fontSize: "13px" }}>
                      Hold <strong>Ctrl</strong> to select multiple
                    </i>
                  </div>
                  <div className="col-md-5">
                    <select
                      className="form-control"
                      name="selectedSubjects"
                      value={this.state.selectedSubjects}
                      onChange={this.handleSubjectChange}
                      multiple
                    >
                      <option value="" disabled>
                        Choose Subjects
                      </option>
                      {this.state.subjectList
                        ? this.state.subjectList.map((subject, idx) => {
                            return (
                              <option value={subject.name} key={idx}>
                                {subject.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="col-md-5">
                    <div className="tt-selectedSubjects">
                      <p>Selected Subjects</p>
                      {this.state.selectedSubjects.length > 0 ? (
                        <ol>
                          {this.state.selectedSubjects.map((subject, idx) => {
                            return <li key={idx}>{subject}</li>;
                          })}
                        </ol>
                      ) : (
                        <p>No Subjects selected</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row editModalFooter mt-3">
          <div className="col text-right">
            <buttton
              className="tt-button tt-button-primary mr-3"
              onClick={this.submitData}
            >
              Save
            </buttton>
            <buttton
              className="tt-button tt-button-primary"
              onClick={this.props.toggleMediaEditModal}
            >
              Close
            </buttton>
          </div>
        </div>
        <ModalWindow
          modal={this.state.addQuestionModal}
          fullWidth={true}
          toggleModal={this.handleAddQuestionClick}
          modalHeader="Quesiton"
          modalBody={
            <PostQuestion
              handleAddMoreQuestion={this.handleAddMoreQuestion}
              questions={this.state.questions}
              handleRemoveQuestion={this.handleRemoveQuestion}
              handleQuestionChange={this.handleQuestionChange}
              handleQuestionSubmit={this.handleAddQuestionClick}
            />
          }
        />
        <ModalWindow
          modal={this.state.addThemeModal}
          toggleModal={this.handleAddThemeClick}
          modalHeader="Add New Theme"
          modalBody={
            <AddTheme
              handleChange={this.handleChange}
              theme={this.state.theme}
              description={this.state.description}
              handleAddThemeSubmit={this.handleAddThemeSubmit}
            />
          }
        />
      </>
    );
  }
}

export default MediaAcademicRepoEditModal;
