import React, { Component } from "react";
import { Spinner } from "react-mdl";
import Pagination from "../../UI/Pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { checkRolePermissions } from "../../../utils/Constants";

const animatedComponents = makeAnimated();

class ListCourseTerm extends Component {
  state = {};
  render() {
    return (
      <div
        className="card mb-2 p-2 courseTermTable tt-lms"
        style={
          checkRolePermissions("can_create_term", "activity")
            ? { height: "46.8vh", overflow: "auto" }
            : { height: "auto" }
        }
      >
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Phases</th>
              <th>Subjects</th>
              {checkRolePermissions("can_assign_subject_to_term", "activity") ||
              checkRolePermissions("can_edit_term", "activity") ||
              checkRolePermissions("can_delete_term", "activity") ? (
                <th width="150px">Options</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {this.props.termList.length > 0 ? (
              this.props.termList.map((term, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {term.isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          name="updateName"
                          value={this.props.updateName}
                          onChange={this.props.handleChange}
                        ></input>
                      ) : (
                        term.name
                      )}
                    </td>
                    <td width="50%">
                      {term.isEditable ? (
                        <textarea
                          name="updateDescription"
                          className="form-control"
                          value={this.props.updateDescription}
                          onChange={this.props.handleChange}
                        ></textarea>
                      ) : (
                        term.description
                      )}
                    </td>
                    <td>
                      {term.coursePhases && term.coursePhases.length > 0
                        ? term.coursePhases.map((phase, tIdx) => {
                            return (
                              <span key={tIdx}>
                                <p>{phase.name}</p>
                                <br></br>
                              </span>
                            );
                          })
                        : "No phase assigned"}
                    </td>
                    <td width="20%">
                      {term.editSubject ? (
                        <Select
                          key={idx}
                          isClearable={true}
                          closeMenuOnScroll={false}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          value={this.props.updateSubjectOption}
                          options={this.props.courseSubjectList}
                          name="subjects"
                          menuPlacement="auto"
                          placeholder="Select course subjects"
                          onChange={this.props.handleEditSubjectValueChange}
                          menuShouldScrollIntoView={true}
                        ></Select>
                      ) : term.courseSubjects &&
                        term.courseSubjects.length > 0 ? (
                        term.courseSubjects.map((sub, sIdx) => {
                          return (
                            <span key={sIdx}>
                              <p>{sub.name}</p>
                              <br></br>
                            </span>
                          );
                        })
                      ) : checkRolePermissions(
                          "can_assign_subject_to_term",
                          "activity"
                        ) ? (
                        <Select
                          key={idx}
                          isClearable={true}
                          closeMenuOnScroll={false}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          value={term.selectedCourseSubjects}
                          options={this.props.courseSubjectList}
                          name="subjects"
                          menuPlacement="auto"
                          placeholder="Select course subjects"
                          onChange={(item) =>
                            this.props.handleSubjectChange(item, idx)
                          }
                          menuShouldScrollIntoView={true}
                          isDisabled={term.isEditable}
                        ></Select>
                      ) : (
                        "No permission to assign"
                      )}
                    </td>
                    {checkRolePermissions(
                      "can_assign_subject_to_term",
                      "activity"
                    ) ||
                    checkRolePermissions("can_edit_term", "activity") ||
                    checkRolePermissions("can_delete_term", "activity") ? (
                      <td className="text-center">
                        {term.courseSubjects &&
                        term.courseSubjects.length > 0 ? (
                          term.editSubject ? (
                            <>
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth"
                                onClick={
                                  this.props.updateCourseSubjectToCourseTerm
                                }
                              >
                                Save
                              </button>
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth"
                                onClick={() =>
                                  this.props.cancelEditSubject(idx)
                                }
                              >
                                Cancel
                              </button>
                            </>
                          ) : term.isEditable ? null : checkRolePermissions(
                              "can_assign_subject_to_term",
                              "activity"
                            ) ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() => this.props.handleEditSubject(idx)}
                            >
                              Edit Subjects
                            </button>
                          ) : null
                        ) : checkRolePermissions(
                            "can_assign_subject_to_term",
                            "activity"
                          ) ? (
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={() => this.props.assignCourseSubjects(idx)}
                            disabled={term.selectedCourseSubjects === 0}
                          >
                            Assign Subjects
                          </button>
                        ) : null}
                        {term.isEditable && !term.editSubject ? (
                          <>
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={this.props.updateTerm}
                            >
                              Save
                            </button>
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() => this.props.cancelUpdate(idx)}
                            >
                              Cancel
                            </button>
                          </>
                        ) : term.editSubject ? null : checkRolePermissions(
                            "can_edit_term",
                            "activity"
                          ) ? (
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={() =>
                              this.props.toggleCourseTermEditModal(idx)
                            }
                          >
                            Edit
                          </button>
                        ) : null}
                        {!term.isEditable && !term.editSubject ? (
                          checkRolePermissions(
                            "can_delete_term",
                            "activity"
                          ) ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() => this.props.handleDelete(term.id)}
                            >
                              Delete
                            </button>
                          ) : null
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="text-center"
                  colSpan={
                    checkRolePermissions(
                      "can_assign_subject_to_term",
                      "activity"
                    ) ||
                    checkRolePermissions("can_edit_term", "activity") ||
                    checkRolePermissions("can_delete_term", "activity")
                      ? 5
                      : 4
                  }
                >
                  {this.props.termSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading Data ...
                    </>
                  ) : (
                    "No data"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemsCount={this.props.termTotalRecords}
            pageSize={this.props.termRowCountLimit}
            currentPage={this.props.termCurrentPage}
            onPageChange={this.props.handlePageChange}
            nextPage={this.props.handleNextPage}
            previousPage={this.props.handlePreviousPage}
          ></Pagination>
        </div>
      </div>
    );
  }
}

export default ListCourseTerm;
