//default message
const insertMessage = "Record has been successfully added.",
  updateMessage = "Record has been successfully updated.";
// deleteMessage="Record has been successfully deleted.";

export const DISPLAYMESSAGE = {
    insertSystemSetup: "",

    //subject
    insertSubject: "Subject has been successfully added.",
    updateSubject: "Subject has been successfully updated.",
    deleteSubject: "Subject has been successfully deleted.",
    insertSubjectSetting: "Settings have been successfully saved.",

    //subjectArea
    deleteSubjectArea: "Subject area has been successfully deleted.",
    updateSubjectArea: "Subject area has been successfully updated.",
    //floor
    updateFloor: updateMessage,
    //videoConference
    insertVideoConference: insertMessage,
  },
  MESSAGEHEADER = {
    success: "Success",
    error: "Error",
  };
