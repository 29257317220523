import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

class CreateCourseClass extends Component {
  state = {};
  render() {
    return (
      <div
        style={
          this.props.newWidget
            ? {}
            : { maxHeight: "33.7vh", overflowX: "hidden", overflowY: "auto" }
        }
      >
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-2"></div>
          <div className="col-md-3 text-center">
            <strong>Type</strong>
            {/* <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span> */}
          </div>
          <div className="col-md-3 text-center">
            <strong>Name</strong>
            {/* <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span> */}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-2">
            <strong>Activity:</strong>
            <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span>
          </div>
          <div className="col-md-3" style={{ display: "flex" }}>
            <select
              className="form-control"
              name="activityType"
              value={this.props.activityType}
              onChange={this.props.handleChange}
            >
              <option value="" disabled>
                Choose Activity Type
              </option>
              <option value="Lect">Lecture</option>
              <option value="Prac">Practical</option>
            </select>
          </div>
          <div className="col-md-3" style={{ display: "flex" }}>
            <input
              type="text"
              className="form-control"
              name="activityName"
              value={this.props.activityName}
              onChange={this.props.handleChange}
            ></input>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-2"></div>
          <div className="col-md-3 text-center">
            <strong>Date</strong>{" "}
            {/* <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span> */}
          </div>
          <div className="col-md-3">
            <div className="row text-center">
              <div className="col">
                <strong>Start Time</strong>{" "}
                {/* <span
                  className="tt-assessment-module-mandatory"
                  style={{ paddingLeft: "5px" }}
                >
                  *
                </span> */}
              </div>
              <div className="col">
                <strong>End Time</strong>{" "}
                {/* <span
                  className="tt-assessment-module-mandatory"
                  style={{ paddingLeft: "5px" }}
                >
                  *
                </span> */}
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-2">
            <strong>Duration:</strong>
            <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span>
          </div>
          <div className="col-md-3" style={{ display: "flex" }}>
            <input
              type="date"
              className="form-control"
              name="date"
              value={this.props.date}
              onChange={this.props.handleChange}
            ></input>
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-6" style={{ display: "flex" }}>
                <input
                  type="time"
                  className="form-control"
                  name="startTime"
                  value={this.props.startTime}
                  onChange={this.props.handleChange}
                ></input>
              </div>
              <div className="col-6" style={{ display: "flex" }}>
                <input
                  type="time"
                  className="form-control"
                  name="endTime"
                  value={this.props.endTime}
                  onChange={this.props.handleChange}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-2 mt-1">
            <strong>Faculty: </strong>
          </div>
          <div className="col-md-6">
            <Select
              isClearable={true}
              closeMenuOnScroll={false}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={this.props.recruitments}
              value={this.props.selectedRecruitment}
              name="terms"
              menuPlacement="auto"
              placeholder="Select Lecturer"
              onChange={this.props.handleRecruitmentChange}
              menuShouldScrollIntoView={true}
            ></Select>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row button-bg-color mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-2 mt-1">
            <strong>Venue:</strong>{" "}
            <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span>
          </div>
          <div className="col-md-6" style={{ display: "flex" }}>
            <select
              name="venue"
              className="form-control"
              onChange={this.props.handleChange}
              value={this.props.venue}
            >
              <option value="" disabled>
                Choose a {localStorage.getItem("classroom")} Location
              </option>
              {this.props.classroomLocations
                ? this.props.classroomLocations.map((room, idx) => {
                    let roomName = ` ${room.name}(${room.number}) - ${
                      room.buildingName
                    }${
                      room.buildingSectionAliasName !== ""
                        ? `, ${room.buildingSectionAliasName}`
                        : ""
                    }${
                      room.buildingSectionName !== null
                        ? ` ${room.buildingSectionName}`
                        : ""
                    }, ${room.floorName}`;
                    return (
                      <option key={idx} value={room.id}>
                        {roomName}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>

          <div className="col-md-2 text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.props.createCourseClass}
            >
              Create
            </button>
            {this.props.newWidget ? null : (
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.props.clearState}
              >
                Cancel
              </button>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    );
  }
}

export default CreateCourseClass;
