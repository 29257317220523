import React, { Component } from "react";
import ArticleTemplate from "../Templates/ArticleTemplate";
import MediaTemplate from "../Templates/MediaTemplate";
import { postType } from "../../../utils/Constants";
import Pagination from "../../UI/Pagination";

class RepositoryList extends Component {
  state = {};
  render() {
    return (
      <div className="tt-repoList">
        {this.props.personAcademicRepoPosts ? (
          this.props.personAcademicRepoPosts.length > 0 ? (
            this.props.personAcademicRepoPosts.map((academicPosts, idx) => {
              return (
                <div className="tt-post-template" key={idx}>
                  {academicPosts.postType === postType.articleCode ? (
                    <ArticleTemplate
                      post={academicPosts}
                      fullPage={true}
                      isAcademicRepo={true}
                      acceptAcademicRepo={this.props.acceptAcademicRepo}
                      subjectList={this.props.subjectList}
                      updateAcademicRepo={this.props.updateAcademicRepo}
                      toggleArticleEditModal={this.props.toggleArticleEditModal}
                      selectedPost={this.props.selectedPost}
                      editArticleAcademicModal={
                        this.props.editArticleAcademicModal
                      }
                    ></ArticleTemplate>
                  ) : academicPosts.postType === postType.mediaCode ? (
                    <MediaTemplate
                      post={academicPosts}
                      fullPage={true}
                      isAcademicRepo={true}
                      subjectList={this.props.subjectList}
                      acceptAcademicRepo={this.props.acceptAcademicRepo}
                      toggleMediaEditModal={this.props.toggleMediaEditModal}
                      editMediaAcademicModal={this.props.editMediaAcademicModal}
                      selectedPost={this.props.selectedPost}
                      updateMediaAcademicRepo={
                        this.props.updateMediaAcademicRepo
                      }
                    ></MediaTemplate>
                  ) : null}
                </div>
              );
            })
          ) : (
            <div className="text-center mt-3 mb-3">No data to display</div>
          )
        ) : (
          <div className="text-center mt-3 mb-3">
            No academic repository post to display
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Pagination
            itemsCount={this.props.totalAcademicRepoPosts}
            pageSize={this.props.rowCountLimit}
            currentPage={this.props.currentPage}
            onPageChange={this.props.handlePageChange}
            previousPage={this.props.handlePreviousPage}
            nextPage={this.props.handleNextPage}
          ></Pagination>
        </div>
      </div>
    );
  }
}

export default RepositoryList;
