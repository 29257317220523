import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import ReactToPrint from "react-to-print";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";

class ViewPlanner extends React.Component {
  state = {
    schoolInfos: "",
  };

  componentDidMount() {
    this.getSchoolInfo();
  }

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data[0],
        });
      }
    });
  };
  render() {
    const plannerList = this.props.selectedPlannerList.plannerList;
    return (
      <>
        <div className="text-right mb-2">
          <ReactToPrint
            trigger={() => (
              <button className="tt-button tt-button-primary">
                Print <FontAwesomeIcon icon={faPrint} />
              </button>
            )}
            content={() => this.componentRef}
          />
        </div>

        <div
          className="row printPlanner"
          ref={(el) => (this.componentRef = el)}
        >
          <div className="col-md-12">
            <div className="container row  my-3 d-flex justify-content-center">
              <div className="text-center">
                <h2>{this.state.schoolInfos?.name}</h2>
                <h5>{this.state.schoolInfos?.address}</h5>
              </div>
            </div>
            <div className=" py-2">
              <div className="">
                <b>Class: </b>
                {this.props.selectedPlannerList.yearLevelName}
              </div>

              <div className="">
                <b>Subject: </b>
                {this.props.selectedPlannerList?.subjects[0]?.subjectName}
              </div>
            </div>
          </div>
          <div className="col">
            <table className="table table-bordered table-striped mb-0 text-center">
              <thead className="tt-group-header">
                <tr>
                  <th>Day / Date</th>
                  <th>Topic</th>
                  <th>Objectives</th>
                  <th>Materials / Methodology</th>
                  <th>Class Assignment</th>
                  <th>Home Assignment</th>
                  <th>Evalution / Marks</th>
                  <th>Resources</th>
                </tr>
              </thead>
              <tbody>
                {plannerList?.length > 0 ? (
                  plannerList.map((list, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {moment(list.date).format("dddd, Do MMMM YYYY")}
                        </td>
                        <td>{list.topic}</td>
                        <td>{list.objectives}</td>
                        <td>{list.methodology}</td>
                        <td>{list.classAssignment}</td>
                        <td>{list.homeAssignment}</td>
                        <td>{list.remark}</td>
                        <td>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() => this.props.handleViewFiles(list)}
                          >
                            View Files
                          </button>{" "}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>No Planner List</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default ViewPlanner;
