/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, Staff, renders the view for List of Staffs binded with the Education  Community
 *
 */

import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import swal from "@sweetalert/with-react";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { groupBy } from "lodash";

import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import {
  getStaffList,
  getStaffListPagination,
} from "../../../../ducks/RecruitmentDucks";
import ModalWindow from "../../../UI/ModalWindow";
import StaffModalData from "./StaffDataModal";
import userImage from "../../../../assets/images/user-image.png";
import "../../../../assets/scss/admissionList.scss";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import Pagination from "../../../UI/Pagination";
import { displayNepaliDate, handleError } from "../../../../utils/Utils";
import RecruitmentForm from "../../../Recruitment/Recruitment/RecruitmentForm";

class Staff extends Component {
  state = {
    countries: null,
    countryId: "",
    currentCount: 0,
    currentListName: "current",
    currentPage: 1,
    designations: [
      "Educational Assistant",
      "Pre-Kindergarten Teacher",
      "Middle School Teacher",
      "Gym Teacher",
      "Computer Teacher",
      "Head of Department",
      "Principal",
    ],
    detailActivityCode: "view-detail-recruitment",
    displaySpinner: true,
    editActivityCode: "edit-recruitment",
    editModal: false,
    endIndex: 10,
    gender: "",
    lastNameCode: "",
    linkCopied: false,
    name: "",
    nameFields: null,
    offset: 0,
    pageSize: 10,
    peopleAddress: [],
    peopleId: null,
    permissionEditRecruitment: false,
    permissionViewDetailRecruitment: false,
    recruitmentId: null,
    recruitmentListByNonCluster: [],
    recruitmentListBySelect: [],
    recruitmentStatusId: 3,
    recruitmentStatusId: null,
    searchData: "",
    searchName: "",
    searchValue: "",
    selectedStaffStatus: "",
    showSpinner: false,
    staffModal: false,
    staffModalData: "",
    staffs: [],
    staffSearch: false,
    staffSearchType: "",
    staffStatus: [],
    staffTypes: null,
    startIndex: 0,
    totalRecords: 0,
  };

  checkPermissions = () => {
    this.setState({
      permissionEditRecruitment: checkRolePermissions(
        this.state.editActivityCode,
        "activity"
      ),
      permissionViewDetailRecruitment: checkRolePermissions(
        this.state.detailActivityCode,
        "activity"
      ),
    });
  };

  closeStaffEdit = () => {
    let staffs = [...this.state.staffs];
    staffs.forEach((el) => {
      el.status = false;
    });
    this.setState({
      staffs: staffs,
      selectedStaffStatus: "",
    });
  };

  componentDidMount() {
    this.getStaffTypes();
    this.getCountryList();
    this.getNameFields();
    if (this.props.fromEducationCommunity) {
      this.setState({
        editActivityCode: "edit-staff-list",
        detailActivityCode: "detail-staff-list",
      });
    }
    this.checkPermissions();
  }

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.staffs !== this.props.staffs) {
      this.setState(
        { staffs: this.props.staffs, totalRecords: this.props.totalRecords },
        function () {
          this.setDisplayNumber();
          this.setState({ displaySpinner: false });
        }
      );
    }
  }

  copyToClipboard = (data) => {
    let dummy1 = document.createElement("input");
    document.body.appendChild(dummy1);
    dummy1.setAttribute("value", data);
    dummy1.select();
    document.execCommand("copy");
    document.body.removeChild(dummy1);
  };

  editStaffStatus = (idx) => {
    let staffs = [...this.state.staffs];
    staffs.forEach((el) => {
      el.status = false;
    });
    staffs[idx].status = true;
    this.setState({
      staffs: staffs,
      selectedStaffStatus: staffs[idx].recruitmentStatusId,
    });
  };

  /**
   * @author Saurav Sitaula
   */
  getCountryList = () => {
    axiosPost(URL.getCountry, {}, (response) => {
      if (response.status === 200) {
        this.setState({ countries: response.data.data });
      }
    });
  };

  /**
   * @author Saurav Sitaula
   */
  getNameFields = () => {
    axiosPost(URL.getIdentityCluster, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          this.setState({
            nameFields: response.data.data,
            searchName: response.data.data[0].id
              ? response.data.data[0].id
              : "",
          });
          let nameFields = response.data.data;
          if (nameFields.length > 0) {
            nameFields.forEach((al) => {
              if (al.fieldName === "Last Name") {
                this.setState({ lastNameCode: al.id });
              }
            });
          }
        }
      }
    });
  };

  getRecruitmentListBySelect = (id) => {
    axiosPost(
      URL.selectByKeyRecruitmentList,
      {
        id: parseInt(id),
      },
      (response) => {
        if (response.status === 200) {
          const recruitmentListBySelect =
            response.data.data.people.customTableFieldValues;
          const peopleAddress = response.data.data.people.peopleAddresses;

          this.setState(
            { recruitmentListBySelect, peopleAddress: peopleAddress },
            () => {
              axiosPost(
                URL.selectRecruitmentByNonClusterFields,
                {
                  id: parseInt(id),
                },
                (response) => {
                  if (response.status === 200) {
                    const values =
                      response.data.data.people.customTableFieldValues;
                    values.forEach((field) => {
                      if (field.fieldTypeCode === "table") {
                        field.highestLength = 0;
                        field.customFieldTableColumns.forEach((column) => {
                          if (column.customFieldTableSubColumns === null) {
                            column.customFieldTableColumnValues.forEach(
                              (value) => {
                                if (value.value.length > field.highestLength) {
                                  field.highestLength = value.value.length;
                                }
                              }
                            );
                          } else {
                            column.customFieldTableSubColumns.forEach(
                              (subCol) => {
                                subCol.customFieldTableColumnValues.forEach(
                                  (value) => {
                                    if (
                                      value.value.length > field.highestLength
                                    ) {
                                      field.highestLength = value.value.length;
                                    }
                                  }
                                );
                              }
                            );
                          }
                        });
                      }
                    });
                    const groupedValues = groupBy(values, "groupName");
                    this.setState({
                      recruitmentListByNonCluster: groupedValues,
                    });
                  }
                }
              );
            }
          );
        }
      }
    );
  };

  getStaffTypes = () => {
    axiosPost(URL.getStaffType, {}, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            staffTypes: response.data.data,
            staffSearchType: response.data.data[0].id,
          },
          function () {
            if (this.state.currentCount === 0) {
              this.staffStatus();
            }
          }
        );
      }
    });
  };

  //Edit Detail
  handleEditModal = (e, el) => {
    this.setState(
      {
        staffModalData: el,
        peopleId: el.peopleId,
        recruitmentStatusId: el.recruitmentStatusId,
        recruitmentId: el.id,
      },
      () => {
        this.setState({
          editModal: !this.state.editModal,
        });
      }
    );
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
      return;
    else {
      this.reRenderStaffList(nextPage);
      this.setState({ currentPage: nextPage }, function () {
        this.setDisplayNumber();
      });
    }
  };

  handlePageChange = (page) => {
    this.reRenderStaffList(page);
    this.setState({ currentPage: page }, function () {
      this.setDisplayNumber();
    });
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1) {
      this.reRenderStaffList(this.state.currentPage - 1);
      this.setState(
        (prevState) => ({
          currentPage: prevState.currentPage - 1,
        }),
        function () {
          this.setDisplayNumber();
        }
      );
    }
  };

  handleRenderAlphabet = (e, data) => {
    this.handleStaffSearch(data);
  };

  handleSelectChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({ [name]: value }, function () {
      if (name === "pageSize") {
        this.setDisplayNumber();
      }
    });
  };

  handleStaffSearch = (alphabet) => {
    let data = {
      recruitmentStatusId: this.state.recruitmentStatusId,
      countryId: this.state.countryId,
      gender: this.state.gender,
      staffTypeId: this.state.staffSearchType,
    };
    if (alphabet !== "noAlphabet") {
      data.value = alphabet;
      data.customTableFieldId = this.state.lastNameCode;
    } else {
      data.value = this.state.searchValue;
      data.customTableFieldId = this.state.searchName;
    }
    let statusIndex = this.state.staffStatus.findIndex(
      (i) => i.id === parseInt(this.state.recruitmentStatusId)
    );
    statusIndex !== -1 &&
      this.setState({
        currentListName: this.state.staffStatus[statusIndex].name,
      });
    data.offset = 0;
    data.rowCountLimit = this.state.pageSize;
    this.setState(
      {
        displaySpinner: true,
        searchData: data,
        offset: 0,
        currentPage: 1,
        staffSearch: true,
      },
      function () {
        this.searchAPIPagination(data);
      }
    );
  };

  nameFormatter = (name) => {
    let convertedName = "";
    for (let i = 0; i < name.length; i++) {
      if (i === 0) {
        convertedName += name.charAt(i).toUpperCase();
      } else {
        if (name.charAt(i - 1) === " " && name.charAt(i) !== name.length) {
          convertedName += name.charAt(i).toUpperCase();
        } else {
          convertedName += name.charAt(i).toLowerCase();
        }
      }
    }
    return convertedName;
  };

  //Rendering alphabets
  renderAlphabets = () => {
    let max = 91;
    let letters = [];
    for (let base = 65; base < max; base++) {
      letters.push(`${String.fromCharCode(base)}`);
    }
    return letters.map((letter, i) => {
      return (
        <th
          className="alphabet-search-header"
          onClick={(e) => this.handleRenderAlphabet(e, letter)}
          key={i}
        >
          {letter}
        </th>
      );
    });
  };

  renderLinkCopyText = () => {
    this.setState({ linkCopied: true });
  };

  reRenderStaffList = (page) => {
    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);
    this.setState({ displaySpinner: true, offset: offset }, function () {
      if (this.state.staffSearch) {
        let data = this.state.searchData;
        data.offset = this.state.offset;
        this.searchAPIPagination(data);
      } else {
        this.props.getStaffListPagination(
          this.state.recruitmentStatusId,
          offset,
          this.state.pageSize,
          this.state.staffSearchType
        );
      }
    });
  };

  searchAPIPagination = (data) => {
    axiosPost(URL.searchRecruitmentListByStatus, data, (response) => {
      if (response.status === 200) {
        let totalRecords = response.data.totalRecordsCount;
        const staffs = response.data.data;
        staffs.forEach((el) => {
          el.status = false;
        });
        this.setState({ staffs: response.data.data, totalRecords }, () => {
          this.setState(
            {
              displaySpinner: false,
            },
            function () {
              this.setDisplayNumber();
            }
          );
        });
      }
    });
  };

  setDisplayNumber = () => {
    // const staffs = this.state.staffs;
    let pageNumber = this.state.currentPage,
      pageSize = this.state.pageSize;
    let startIndex = (pageNumber - 1) * pageSize + 1;
    var offValue = pageSize - 1;
    var endIndex = parseInt(startIndex) + parseInt(offValue);
    if (endIndex > this.state.totalRecords) {
      endIndex = this.state.totalRecords;
    }
    this.setState({
      startIndex: startIndex,
      endIndex: endIndex,
    });
  };

  staffDataModal = () => {
    return (
      <StaffModalData
        staffModalData={this.state.staffModalData}
        recruitmentListBySelect={this.state.recruitmentListBySelect}
        recruitmentListByNonCluster={this.state.recruitmentListByNonCluster}
        peopleAddress={this.state.peopleAddress}
      ></StaffModalData>
    );
  };

  staffStatus = () => {
    const params = {
      isVisible: true,
    };
    axiosPost(
      URL.getRecruitmentStatus,
      this.props.isVisible ? params : {},
      (response) => {
        if (response.status === 200) {
          this.setState({ staffStatus: response.data.data }, function () {
            let status = this.state.staffStatus;
            let id = "";
            status.forEach((el) => {
              if (el.name === "Current") {
                id = el.id;
              }
            });
            this.setState(
              { recruitmentStatusId: id, currentCount: 1 },
              function () {
                this.props.getStaffListPagination(
                  this.state.recruitmentStatusId,
                  this.state.offset,
                  this.state.pageSize,
                  this.state.staffSearchType
                );
              }
            );
          });
        }
      }
    );
  };

  updateStaffList = (idx, el) => {
    if (this.state.selectedStaffStatus !== "") {
      let staffs = [...this.state.staffs];
      staffs[idx].status = false;
      let data = {
        id: el.id,
        recruitmentStatusId: this.state.selectedStaffStatus,
        staffTypeId: el.staffTypeId,
        emailAddress: el.emailAddress,
      };
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.updateStaff,
          data,
          (response) => {
            if (response.status === 200) {
              swal({
                title: `Updated Successfully`,
                content: (
                  <div>
                    {response.data.data.updatedUser !== null &&
                    response.data.data.updatedUser !== "" ? (
                      <>
                        {response.data.data.updatedUser}
                        <br></br>
                        {!response.data.data.updatedUser.includes(
                          "enabled again"
                        ) && (
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() => {
                              this.copyToClipboard(
                                `${response.data.data.updatedUser}`
                              );
                              this.renderLinkCopyText();
                            }}
                          >
                            {this.state.linkCopied ? "Copied" : "Copy"}
                          </button>
                        )}
                        {/* <button
                          className="tt-button tt-button-primary"
                          onClick={() => {
                            this.copyToClipboard(
                              `${response.data.data.updatedUser}`
                            );
                            this.renderLinkCopyText();
                          }}
                        >
                          {this.state.linkCopied ? "Copied" : "Copy"}
                        </button> */}
                      </>
                    ) : (
                      <div>Status updated successfully</div>
                    )}
                  </div>
                ),
              });
              this.setState(
                {
                  staffs,
                  displaySpinner: true,
                  recruitmentStatusId: 3,
                  showSpinner: false,
                },
                function () {
                  this.props.getStaffListPagination(
                    this.state.recruitmentStatusId,
                    this.state.offset,
                    this.state.pageSize,
                    this.state.staffSearchType
                  );
                }
              );
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        );
      });
    } else {
      this.setState({ showSpinner: false });
      swal({
        title: "Warning",
        text: "Please choose staff status",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  toggleEditModal = () => {
    this.setState({ editModal: !this.state.editModal, staffModalData: "" });
  };

  toggleStaffModal = () => {
    this.setState({
      staffModal: !this.state.staffModal,
    });
  };

  //Detail of Staff
  toggleStaffModalWithData = (e, el) => {
    this.setState(
      {
        staffModal: !this.state.staffModal,
        name: el.people.name,
        staffModalData: el,
      },
      () => {
        this.getRecruitmentListBySelect(el);
      }
    );
  };

  getRecruitmentListBySelect = (el) => {
    axiosPost(
      URL.selectByKeyRecruitmentList,
      {
        id: parseInt(el.id),
        emailAddress: el.emailAddress,
      },
      (response) => {
        if (response.status === 200) {
          const recruitmentListBySelect =
            response.data.data.people.customTableFieldValues;
          const peopleAddress = response.data.data.people.peopleAddresses;

          this.setState(
            { recruitmentListBySelect, peopleAddress: peopleAddress },
            () => {
              axiosPost(
                URL.selectRecruitmentByNonClusterFields,
                {
                  id: parseInt(el.id),
                },
                (response) => {
                  if (response.status === 200) {
                    const values =
                      response.data.data.people.customTableFieldValues;
                    values.forEach((field) => {
                      if (field.fieldTypeCode === "table") {
                        field.highestLength = 0;
                        field.customFieldTableColumns.forEach((column) => {
                          if (column.customFieldTableSubColumns === null) {
                            column.customFieldTableColumnValues.forEach(
                              (value) => {
                                if (value.value.length > field.highestLength) {
                                  field.highestLength = value.value.length;
                                }
                              }
                            );
                          } else {
                            column.customFieldTableSubColumns.forEach(
                              (subCol) => {
                                subCol.customFieldTableColumnValues.forEach(
                                  (value) => {
                                    if (
                                      value.value.length > field.highestLength
                                    ) {
                                      field.highestLength = value.value.length;
                                    }
                                  }
                                );
                              }
                            );
                          }
                        });
                      }
                    });
                    const groupedValues = groupBy(values, "groupName");
                    this.setState({
                      recruitmentListByNonCluster: groupedValues,
                    });
                  }
                }
              );
            }
          );
        }
      }
    );
  };

  toggleStaffModal = () => {
    this.setState({
      staffModal: !this.state.staffModal,
    });
  };

  staffDataModal = () => {
    return (
      <StaffModalData
        staffModalData={this.state.staffModalData}
        recruitmentListBySelect={this.state.recruitmentListBySelect}
        recruitmentListByNonCluster={this.state.recruitmentListByNonCluster}
        peopleAddress={this.state.peopleAddress}
      ></StaffModalData>
    );
  };

  //Rendering alphabets
  renderAlphabets = () => {
    let max = 91;
    let letters = [];
    for (let base = 65; base < max; base++) {
      letters.push(`${String.fromCharCode(base)}`);
    }
    return letters.map((letter, i) => {
      return (
        <th
          className="alphabet-search-header"
          onClick={(e) => this.handleRenderAlphabet(e, letter)}
          key={i}
        >
          {letter}
        </th>
      );
    });
  };

  handleRenderAlphabet = (e, data) => {
    this.handleStaffSearch(data);
  };

  handlePageChange = (page) => {
    this.reRenderStaffList(page);
    this.setState({ currentPage: page }, function () {
      this.setDisplayNumber();
    });
  };

  reRenderStaffList = (page) => {
    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);
    this.setState({ displaySpinner: true, offset: offset }, function () {
      if (this.state.staffSearch) {
        let data = this.state.searchData;
        data.offset = this.state.offset;
        this.searchAPIPagination(data);
      } else {
        this.props.getStaffListPagination(
          this.state.recruitmentStatusId,
          offset,
          this.state.pageSize,
          this.state.staffSearchType
        );
      }
    });
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1) {
      this.reRenderStaffList(this.state.currentPage - 1);
      this.setState(
        (prevState) => ({
          currentPage: prevState.currentPage - 1,
        }),
        function () {
          this.setDisplayNumber();
        }
      );
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
      return;
    else {
      this.reRenderStaffList(nextPage);
      this.setState({ currentPage: nextPage }, function () {
        this.setDisplayNumber();
      });
    }
  };

  setDisplayNumber = () => {
    // const staffs = this.state.staffs;
    let pageNumber = this.state.currentPage,
      pageSize = this.state.pageSize;
    let startIndex = (pageNumber - 1) * pageSize + 1;
    var offValue = pageSize - 1;
    var endIndex = parseInt(startIndex) + parseInt(offValue);
    if (endIndex > this.state.totalRecords) {
      endIndex = this.state.totalRecords;
    }
    this.setState({
      startIndex: startIndex,
      endIndex: endIndex,
    });
  };

  render() {
    const staffs = this.state.staffs;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header ">
          Staffs
          {checkWidgetAdminOrNot("Academic Administration") === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.linkCopied}
          autoHideDuration={1000}
          onClose={() => this.setState({ linkCopied: false })}
          message="Copied to Clipboard"
        />
        <div className="tt-admissionListBox">
          <div>
            <table className="table table-bordered table-striped mb-0">
              <thead className="tt-group-header-dark text-center">
                <tr>{this.renderAlphabets()}</tr>
              </thead>
            </table>
          </div>
          <div className="student-search container-fluid">
            <strong className="search-header">
              Search on {this.state.totalRecords} Staffs
            </strong>
            <div className="row search-form mt-2">
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-auto">
                    <label>Users:</label>
                  </div>
                  <div className="col-md-auto">
                    <select
                      id="status-search"
                      name="staffSearchType"
                      value={this.state.staffSearchType}
                      onChange={this.handleSelectChange}
                      style={{ width: "100%" }}
                    >
                      <option value="" disabled selected>
                        Choose Staff Type
                      </option>
                      {this.state.staffTypes &&
                        this.state.staffTypes.map((staffType, idx) => (
                          <option value={staffType.id} key={idx}>
                            {staffType.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-2" style={{ paddingRight: 0 }}>
                <select
                  id="name-search"
                  name="searchName"
                  onChange={this.handleSelectChange}
                  value={this.state.searchName}
                  style={{ width: "95.5%" }}
                >
                  <option value="" disabled selected>
                    Choose Field Name
                  </option>
                  {this.state.nameFields &&
                    this.state.nameFields.map((field, id) => (
                      <option value={field.id} key={id}>
                        {field.fieldName}
                      </option>
                    ))}
                </select>
                <span style={{ position: "relative", left: "10px" }}>:</span>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  name="searchValue"
                  id="name-search"
                  value={this.state.searchValue}
                  onChange={this.handleSelectChange}
                />
              </div>
              <div className="col-md-3">
                <label className="mr-2">Status:</label>
                <select
                  id="status-search"
                  value={this.state.recruitmentStatusId}
                  name="recruitmentStatusId"
                  onChange={this.handleSelectChange}
                >
                  {this.state.staffStatus &&
                    this.state.staffStatus.map((status, sIdx) => (
                      <option value={status.id} key={sIdx}>
                        {status.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row search-form">
              <div className="col-md-2">
                <select
                  id="status-search"
                  name="gender"
                  value={this.state.gender}
                  onChange={this.handleSelectChange}
                >
                  <option value="" disabled selected>
                    Choose gender
                  </option>
                  <option value="">All Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div className="col-md-4">
                <label className="mr-2">Show :</label>
                <select
                  id="status-search"
                  className="ml-2 mr-4"
                  name="pageSize"
                  onChange={this.handleSelectChange}
                  value={this.state.pageSize}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>100</option>
                  <option>250</option>
                  <option>500</option>
                </select>
                <button
                  className="tt-button tt-button-primary"
                  onClick={() => this.handleStaffSearch("noAlphabet")}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid index-info1">
            <div className="row">
              <div className="col-md-12 p-2">
                <strong className="ml-3">
                  {staffs.length === 0
                    ? `0 Staff found`
                    : `Displaying ${this.state.startIndex} to ${this.state.endIndex} of ${this.state.totalRecords} [
              ${this.state.currentListName}]`}
                </strong>
              </div>
            </div>
          </div>
          <table className="table text-center table-bordered table-striped tt-listTable">
            <thead className="tt-group-header">
              <tr>
                <th width="20%">Name</th>
                <th>Detail</th>
                <th width="15%">Status</th>
                {this.state.permissionEditRecruitment ||
                this.state.permissionViewDetailRecruitment ? (
                  <th width="11%">Options</th>
                ) : null}
              </tr>
            </thead>
            {this.state.displaySpinner ? (
              <tbody>
                <tr>
                  <td colSpan={4} className="mt-3 mb-3">
                    <Spinner color="primary" />
                    <br />
                    Loading Data ...
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {this.state.staffs ? (
                  this.state.staffs.length > 0 ? (
                    this.state.staffs.map((staff, idx) => {
                      return (
                        <tr key={staff.id}>
                          <td style={{ width: "20%" }} className="text-left">
                            <div className="tt-pp">
                              <div className="tt-ppHolder">
                                <img
                                  src={
                                    staff.photo
                                      ? URL.imageSelectURL + staff.people.photo
                                      : userImage
                                  }
                                  onError={(e) => handleError(e)}
                                  alt=""
                                ></img>
                              </div>
                              <div className="tt-ppName">
                                {staff.isUser ? (
                                  <Link
                                    to={{
                                      pathname: `/profile/${staff.people.username}`,
                                    }}
                                  >
                                    <strong className="user-details">
                                      {this.nameFormatter(staff.people.name)}
                                    </strong>
                                  </Link>
                                ) : (
                                  <strong className="user-details">
                                    {this.nameFormatter(staff.people.name)}
                                  </strong>
                                )}
                              </div>
                            </div>
                          </td>
                          <td style={{ width: "40%" }} className="text-left">
                            <div className="user-details">
                              <strong className="mr-1">Designation:</strong>
                              {staff.designations.length > 0 &&
                                staff.designations.map(
                                  (stfDes) => stfDes.name + " ,"
                                )}
                            </div>
                            <div className="user-details">
                              <strong className="mr-1">Department:</strong>
                              {staff.departments.length > 0 &&
                                staff.departments.map(
                                  (stfDep) => stfDep.name + " , "
                                )}
                            </div>
                            <div className="user-details">
                              <strong className="mr-1">Date of Hire:</strong>
                              <span>
                                {moment(staff.dateOfHire).format(
                                  "Do MMMM, YYYY"
                                )}
                              </span>
                              <span className="ml-2">
                                {displayNepaliDate(staff.dateOfHire)}
                              </span>
                            </div>
                            <div className="user-details">
                              <strong className="mr-1">Birthday:</strong>
                              <span>
                                {moment(staff.people.dateOfBirth).format(
                                  "Do MMMM, YYYY"
                                )}
                              </span>
                              <span className="ml-2">
                                {displayNepaliDate(staff.people.dateOfBirth)}
                              </span>
                            </div>
                            <div className="user-details">
                              <strong className="mr-1">Age:</strong>
                              {moment().diff(
                                staff.people.dateOfBirth.substring(0, 10),
                                "years"
                              ) === 0
                                ? 1
                                : moment().diff(
                                    staff.people.dateOfBirth.substring(0, 10),
                                    "years"
                                  )}{" "}
                              years old
                            </div>
                            <div className="user-details">
                              <strong className="mr-1">Gender:</strong>
                              {staff.people.gender}
                            </div>
                          </td>
                          <td>
                            {staff.status ? (
                              <select
                                className="form-control"
                                name="selectedStaffStatus"
                                value={this.state.selectedStaffStatus}
                                onChange={this.handleSelectChange}
                              >
                                <option value="" disabled>
                                  Select Status
                                </option>
                                {this.state.staffStatus.map((status, idx) => {
                                  return (
                                    <option key={idx} value={status.id}>
                                      {status.name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              staff.recruitmentStatusName
                            )}
                          </td>
                          {this.state.permissionEditRecruitment ||
                          this.state.permissionViewDetailRecruitment ? (
                            <td>
                              {staff.status ? (
                                <>
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={(e) =>
                                      this.updateStaffList(idx, staff)
                                    }
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={this.closeStaffEdit}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  {this.state.permissionEditRecruitment ? (
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) =>
                                        this.handleEditModal(e, staff)
                                      }
                                    >
                                      Edit
                                    </button>
                                  ) : null}
                                  {this.state
                                    .permissionViewDetailRecruitment ? (
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) =>
                                        this.toggleStaffModalWithData(e, staff)
                                      }
                                    >
                                      Details
                                    </button>
                                  ) : null}
                                  {this.state.permissionEditRecruitment ? (
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) => this.editStaffStatus(idx)}
                                    >
                                      Change Status
                                    </button>
                                  ) : null}
                                </>
                              )}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>No staff to display</td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan={4}>No staff to display</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemsCount={this.state.totalRecords}
              pageSize={this.state.pageSize}
              currentPage={this.state.currentPage}
              onPageChange={this.handlePageChange}
              nextPage={this.handleNextPage}
              previousPage={this.handlePreviousPage}
            />
          </div>
          <ModalWindow
            fullWidth={true}
            modal={this.state.staffModal}
            toggleModal={this.toggleStaffModal}
            modalHeader={this.state.name}
            modalClass="tt-peopleList"
            modalBody={this.staffDataModal()}
            modalFooter={
              <button
                className="tt-button tt-button-primary"
                onClick={this.toggleStaffModal}
              >
                Close
              </button>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.permissionModal}
            size="lg"
            id="tt-permissionModal"
            toggleModal={() => {
              this.setState({
                permissionModal: !this.state.permissionModal,
              });
            }}
            modalHeader={"Assign permission to user"}
            modalBody={
              <GranularPermissionModal
                widgetName="Academic Administration"
                moduleName="Education Community"
                header="View Staffs"
                activityName="select-staff"
              />
            }
          ></ModalWindow>
          {this.state.peopleId ? (
            <ModalWindow
              modal={this.state.editModal}
              toggleModal={this.toggleEditModal}
              size="xl"
              modalHeader="Edit Recruitment"
              modalBody={
                <RecruitmentForm
                  editMode={true}
                  staffData={this.state.staffModalData}
                  peopleId={this.state.peopleId}
                  recruitmentStatusId={this.state.recruitmentStatusId}
                  recruitmentId={this.state.recruitmentId}
                  handleStaffSearch={this.handleStaffSearch}
                ></RecruitmentForm>
              }
            ></ModalWindow>
          ) : null}

          <div>
            <table className="table table-bordered table-striped mb-0">
              <thead className="tt-group-header-dark text-center">
                <tr>{this.renderAlphabets()}</tr>
              </thead>
            </table>
          </div>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Updating User Staff ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  staffs: state.recruitment.staffs,
  totalRecords: state.recruitment.totalRecords,
});

const mapActionToProps = {
  getStaffList,
  getStaffListPagination,
};

export default connect(mapStateToProps, mapActionToProps)(Staff);
