import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  checkWidgetAdminOrNot,
  postType,
} from "../../../../utils/Constants";

import { Collapse, Card, CardBody, Spinner } from "reactstrap";
import { showErroMessage } from "../../../../utils/Utils";
import ReportGeneratorUI from "./ReportGeneratorUI";
import swal from "sweetalert";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";

import swal2 from "sweetalert2";
import { connect } from "react-redux";
import { getYearLevelForPublishAssessmentReport } from "../../../../ducks/PrimaryARRDucks";
import { getNotices } from "../../../../ducks/PostsDucks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { MESSAGEHEADER } from "../../../../utils/DisplayMessage";

class ReportGenerator extends Component {
  state = {
    yearLevels: [],
    isOpen: false,
    classrooms: [],
    assessmentList: [],
    assessmentName: "",
    studentList: [],
    reportSetups: [],
    reportSetupId: "",
    studentIds: [],
    zipHref: null,
    recruitmentId: null,
    loggedIdUserRecruitmentId: {},
    allStudentChecked: false,
  };

  getLoggedInRecruitmentId = () => {
    let param = {
      peopleId: localStorage.getItem("peopleId"),
    };
    axiosPost(URL.selectRecruitmentByPeopleId, param, (response) => {
      if (response.status === 200) {
        this.setState({ loggedIdUserRecruitmentId: response.data.data });
      }
    });
  };

  componentDidMount() {
    this.getLoggedInRecruitmentId();
    this.props.getYearLevelForPublishAssessmentReport(this.props.code);
    // let param = {
    //   assessmentModuleCode: this.props.code,
    // };
    // axiosPost(URL.selectFinalizedAssessmentYearLevel, param, (response) => {
    //   if (response.status === 200) {
    //     const yearLevels = response.data.data;
    //     this.setState(
    //       {
    //         yearLevels,
    //       },
    //       () => {
    //         let yearLevels = [...this.state.yearLevels];
    //         yearLevels.forEach((level) => {
    //           level.isYearLevelOpen = false;
    //           level.classroomYearLevelRecruitmentRoom.forEach((classroom) => {
    //             classroom.isClassroomOpen = false;
    //           });
    //         });
    //         this.setState({ yearLevels });
    //       }
    //     );
    //   }
    // });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { yearLevels: nextProps.yearLevels };
  }

  toggleCollapse = (e, idx, yearLevelId) => {
    // this.getClassroom(yearLevelId);
    let yearLevels = [...this.state.yearLevels];
    yearLevels.forEach((yearLevel, i) => {
      yearLevel.classroomYearLevelRecruitmentRoom.forEach((classroom) => {
        classroom.isClassroomOpen = false;
      });
      idx === i
        ? (yearLevel.isYearLevelOpen = !yearLevel.isYearLevelOpen)
        : (yearLevel.isYearLevelOpen = false);
    });
    this.setState({
      yearLevels,
    });
  };

  getAssessments = (classroomId, yearLevelId, educationLevelId) => {
    let param = {
      classroomId: classroomId,
      yearLevelId: yearLevelId,
      educationLevelId: educationLevelId,
    };

    axiosPost(
      URL.selectFinalizedGeneralAndSubjectAssessment,
      param,
      (response) => {
        if (response.status === 200) {
          this.setState({ assessmentList: response.data.data });
        }
      }
    );
  };

  getStudents = (e, classroomId, yearLevelId) => {
    let param = {
      classroomId: classroomId,
      yearLevelId: yearLevelId,
    };

    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status === 200) {
        this.setState({ studentList: response.data.data });
      } else {
        showErroMessage(response);
      }
    });
  };
  toggleClassroomCollapse = (
    e,
    index,
    classroomId,
    yearLevelId,
    educationLevelId
  ) => {
    this.getAssessments(classroomId, yearLevelId, educationLevelId);
    this.getStudents(classroomId, yearLevelId);
    let classrooms = [...this.state.classrooms];
    classrooms.forEach((classroom, i) => {
      index === i
        ? (classroom.isClassroomOpen = !classroom.isClassroomOpen)
        : (classroom.isClassroomOpen = false);
    });
    this.setState({ classrooms }, () => {
      this.setState({
        assessmentName: "",
        reportSetupId: "",
        reportSetups: [],
      });
    });
  };

  toggleClassroom = (e, mainIdx, classIdx) => {
    let yearLevels = [...this.state.yearLevels];
    yearLevels.forEach((yearLevel, yearLevelIdx) => {
      if (parseInt(mainIdx) === parseInt(yearLevelIdx)) {
        yearLevel.classroomYearLevelRecruitmentRoom.forEach(
          (classroom, classroomIndex) => {
            if (parseInt(classIdx) === parseInt(classroomIndex)) {
              classroom.isClassroomOpen = !classroom.isClassroomOpen;
            } else {
              classroom.isClassroomOpen = false;
            }
          }
        );
      }
    });
    this.setState({ yearLevels }, () => {
      this.setState({
        assessmentName: "",
        reportSetupId: "",
        reportSetups: [],
      });
    });
  };

  // getClassroom = (yearLevelId) => {
  //   let param = {
  //     yearLevelId: yearLevelId,
  //   };

  //   axiosPost(
  //     URL.selectGeneralSubjectAssessmentFinalizedClassroom,
  //     param,
  //     (response) => {
  //       if (response.status === 200) {
  //         const classrooms = response.data.data;
  //         this.setState({ classrooms }, () => {
  //           let classrooms = [...this.state.classrooms];
  //           classrooms.forEach((classroom) => {
  //             classroom.isClassroomOpen = false;
  //           });
  //           this.setState({ classrooms });
  //         });
  //       } else {
  //         showErroMessage(response);
  //       }
  //     }
  //   );
  // };

  handleAssessmentChange = (e, yearLevelId) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      this.getReportSetups(yearLevelId);
    });
  };

  universalHandleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      if (name === "reportSetupId") {
        let reportSetups = [...this.state.reportSetups];
        reportSetups.forEach((setup) => {
          if (parseInt(setup.id) === parseInt(this.state.reportSetupId)) {
            this.setState({ recruitmentId: setup.recruitmentId });
          }
        });
      }
    });
  };

  getReportSetups = (yearLevelId) => {
    let param = {
      yearLevelId: yearLevelId,
      assessmentId: parseInt(this.state.assessmentName),
    };

    axiosPost(URL.selectByReportSetupYearLevel, param, (response) => {
      if (response.status === 200) {
        this.setState({ reportSetups: response.data.data });
      }
    });
  };

  handlePublishReport = (yearLevelId, classroomId) => {
    let studentList = [...this.state.studentList];

    let studentIds = [];
    studentList.forEach((student, i) => {
      let data = {};
      if (student.checked) {
        // data.studentId = student.people.id;
        data = student.admissionId;
        studentIds.push(data);
      }
    });
    if (studentIds.length > 0) {
      this.setState({ studentIds }, () => {
        //   for (let i = 0; i < this.state.studentIds.length; i++) {
        let param = {
          yearLevelId: parseInt(yearLevelId),
          classroomId: parseInt(classroomId),
          assessmentId: parseInt(this.state.assessmentName),
          reportSetupId: parseInt(this.state.reportSetupId),
          admissionIds: this.state.studentIds,
        };
        this.setState({ showSpinner: true }, function () {
          axiosPost(
            URL.publishReport,
            param,
            (response) => {
              if (response.status === 200) {
                // let noticeParam = {
                //   createdAt: new Date(),
                //   postType: "PT_NOTE",
                //   peopleId: localStorage.getItem("peopleId"),
                // };
                // this.props.getNotices(noticeParam);
                // swal(MESSAGEHEADER.success, "Report successfully published");

                let zipFolderName =
                  response.data !== ""
                    ? response.data.data !== null || response.data.data !== ""
                      ? response.data.data
                      : "zipFolder.zip"
                    : "zipFolder.zip";
                this.setState({ zipHref: URL.downloadReport + zipFolderName });
                let texts = `<p>Report successfully published<br>
              <a target="_blank"
              href=${URL.downloadReport + zipFolderName}
              >Click to Download </a></p>`;
                swal2.fire({
                  title: "Success",
                  html: texts,
                  allowOutsideClick: false,
                  closeOnClickOutside: false,
                });
                let yearLevels = [...this.state.yearLevels];
                yearLevels.forEach((yearLevel) => {
                  yearLevel.classroomYearLevelRecruitmentRoom.forEach(
                    (classroom) => {
                      classroom.isClassroomOpen = false;
                    }
                  );
                });
                let param = {
                  postType: postType.noticeCode,
                  createdAt: new Date(),
                  peopleId: localStorage.getItem("peopleId"),
                };
                this.props.getNotices(param);

                this.setState({
                  yearLevels,
                  showSpinner: false,
                  allStudentChecked: false,
                });
                // swal("success", "Report has been successfully generated");
              } else {
                swal("error", "Error downloading");
              }
            },
            (failure) => {
              swal({
                title: "Error",
                text: "Error publishing",
              });
              this.setState({ showSpinner: false });
            }
          );
        });

        //   }
      });
    } else {
      swal("Error", "Check atleast one student");
    }
  };

  handleGenerateReport = (yearLevelId, classroomId) => {
    let studentList = [...this.state.studentList];

    let studentIds = [];
    studentList.forEach((student, i) => {
      let data = {};
      if (student.checked) {
        // data.studentId = student.people.id;
        data = student.admissionId;
        studentIds.push(data);
      }
    });
    if (studentIds.length > 0) {
      this.setState({ studentIds }, () => {
        //   for (let i = 0; i < this.state.studentIds.length; i++) {
        let param = {
          yearLevelId: parseInt(yearLevelId),
          classroomId: parseInt(classroomId),
          assessmentId: parseInt(this.state.assessmentName),
          reportSetupId: parseInt(this.state.reportSetupId),
          admissionIds: this.state.studentIds,
        };
        this.setState({ showSpinner: true }, function () {
          axiosPost(
            URL.generateReport,
            param,
            (response) => {
              if (response.status === 200) {
                let zipFolderName =
                  response.data !== ""
                    ? response.data.data !== null || response.data.data !== ""
                      ? response.data.data
                      : "zipFolder.zip"
                    : "zipFolder.zip";
                this.setState({ zipHref: URL.downloadReport + zipFolderName });
                let texts = `<p>Report has been successfully generated<br>
                  <a target="_blank"
                  href=${URL.downloadReport + zipFolderName}
                  >Click to Download </a></p>`;
                swal2.fire({
                  title: "Success",
                  html: texts,
                  allowOutsideClick: false,
                  closeOnClickOutside: false,
                });
                let yearLevels = [...this.state.yearLevels];
                yearLevels.forEach((yearLevel) => {
                  yearLevel.classroomYearLevelRecruitmentRoom.forEach(
                    (classroom) => {
                      classroom.isClassroomOpen = false;
                    }
                  );
                });
                this.setState({
                  yearLevels,
                  showSpinner: false,
                  allStudentChecked: false,
                });
                // swal("success", "Report has been successfully generated");
              } else {
                this.setState({ showSpinner: false });
                swal("error", "Error downloading");
              }
            },
            (failure) => {
              this.setState({ showSpinner: false });
              swal({
                title:
                  "Error - " + failure && failure?.response
                    ? failure.response?.data?.status
                    : "",
                text:
                  failure && failure?.response
                    ? failure.response?.data?.message
                    : "",
              });
            }
          );
        });

        //   }
      });
    } else {
      swal("Error", "Check atleast one student");
    }
  };

  handleSelectAllCheckBoxClick = (e) => {
    let studentList = this.state.studentList;
    studentList.forEach((student, i) => {
      student.checked = e.target.checked;
    });
    this.setState({
      studentList,
      allStudentChecked: e.target.checked,
    });
  };

  handlSelectRowCheckBoxClick = (e, studentId) => {
    let studentList = [...this.state.studentList];
    let count = 0;
    studentList.forEach((student, i) => {
      if (student.people.id === studentId) {
        student.checked = e.target.checked;
        if (!e.target.checked) {
          count++;
        }
      } else {
        if (!student.checked) {
          count++;
        }
      }
    });

    this.setState({
      studentList,
      allStudentChecked: studentList.length == count ? true : false,
    });
  };
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          Publish Assessment Report
          {checkWidgetAdminOrNot(this.props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="tt-newListBox">
          {this.state.yearLevels.length > 0 ? (
            this.state.yearLevels.map((yearLevel, idx) => {
              return (
                <div className="container-fluid">
                  <div
                    onClick={(e) => this.toggleCollapse(e, idx, yearLevel.id)}
                    key={idx}
                    className="row tt-group-header text-left"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="col">{yearLevel.name}</div>
                    <div className="col-md-1 text-center">
                      <FontAwesomeIcon
                        icon={
                          yearLevel.isYearLevelOpen ? faAngleUp : faAngleDown
                        }
                      ></FontAwesomeIcon>
                    </div>
                  </div>

                  <Collapse isOpen={yearLevel.isYearLevelOpen}>
                    <Card>
                      <CardBody>
                        {yearLevel.classroomYearLevelRecruitmentRoom
                          ? yearLevel.classroomYearLevelRecruitmentRoom.map(
                              (classroom, classIdx) => {
                                return (
                                  <>
                                    <div
                                      key={classIdx}
                                      className="tt-group-header text-left"
                                      onClick={(e) => {
                                        this.toggleClassroom(e, idx, classIdx);
                                        this.getStudents(
                                          e,
                                          classroom.id,
                                          classroom.yearLevelId
                                        );
                                      }}
                                    >
                                      {classroom.name}
                                    </div>

                                    <ReportGeneratorUI
                                      allStudentChecked={
                                        this.state.allStudentChecked
                                      }
                                      isClassroomOpen={
                                        classroom.isClassroomOpen
                                      }
                                      handleAssessmentChange={
                                        this.handleAssessmentChange
                                      }
                                      assessmentName={this.state.assessmentName}
                                      assessmentList={classroom.assessments}
                                      studentList={this.state.studentList}
                                      yearLevelId={yearLevel.id}
                                      classroomId={classroom.id}
                                      reportSetups={this.state.reportSetups}
                                      reportSetupId={this.state.reportSetupId}
                                      handleGenerateReport={
                                        this.handleGenerateReport
                                      }
                                      zipHref={this.state.zipHref}
                                      universalHandleChange={
                                        this.universalHandleChange
                                      }
                                      handleSelectAllCheckBoxClick={
                                        this.handleSelectAllCheckBoxClick
                                      }
                                      handlSelectRowCheckBoxClick={
                                        this.handlSelectRowCheckBoxClick
                                      }
                                      recruitmentId={this.state.recruitmentId}
                                      loggedIdUserRecruitmentId={
                                        this.state.loggedIdUserRecruitmentId
                                      }
                                      handlePublishReport={
                                        this.handlePublishReport
                                      }
                                      showSpinner={this.state.showSpinner}
                                      name={this.props.name}
                                    />
                                  </>
                                );
                              }
                            )
                          : `No classroom for this ${localStorage.getItem(
                              "yearLevel"
                            )}`}
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              );
            })
          ) : this.props.publishAssessmentReportSpinner ? (
            <div className="text-center">
              <Spinner color="primary"></Spinner>
              <br></br>Loading Data...
            </div>
          ) : (
            <div className="text-center">No records found.</div>
          )}
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Reports"
              header="Create Assessment Report"
              activityName="publish-assessment-report"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  yearLevels: state.primaryArr.yearLevels,
  publishAssessmentReportSpinner:
    state.primaryArr.publishAssessmentReportSpinner,
});

const mapActionsToProps = {
  getYearLevelForPublishAssessmentReport:
    getYearLevelForPublishAssessmentReport,
  getNotices: getNotices,
};

export default connect(mapStateToProps, mapActionsToProps)(ReportGenerator);
