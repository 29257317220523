import React, { Component } from "react";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import Switch from "@material-ui/core/Switch";
import { connect } from "react-redux";
import { getRenderArray } from "../../../ducks/PrimaryARRDucks";
import "../../../assets/scss/form.scss";

class SubjectSettings extends Component {
  state = {
    allYearLevels: [],
    educationLevelDivisions: [],
    checkedA: true,
    checkedB: false,
    selectAll: false,
    subjectSettings: [],
    allSubjectSettingChecked: "",
  };

  handleSelectAll = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.checked;

    if (value) {
      let renderArray = this.props.renderArray;
      renderArray.forEach((element) => {
        element.yearLevelDto.checked = true;
      });
      this.props.getRenderArray(renderArray);
    }
    if (!value) {
      let renderArray = this.props.renderArray;
      renderArray.forEach((element) => {
        element.yearLevelDto.checked = false;
      });
      this.props.getRenderArray(renderArray);
    }

    this.setState({
      [name]: value,
    });
  };

  handleChange = (e, idx, stateName, objectName, attributeName) => {
    let array = [...this.props[stateName]];
    attributeName === "subjectCoordinatorRecruitmentId" ||
    attributeName === "creditHour"
      ? (array[idx][objectName][attributeName] = e.target.value)
      : (array[idx][objectName][attributeName] =
          !array[idx][objectName][attributeName]);
    if (attributeName !== "creditHour") {
      if (!array[idx][objectName][attributeName]) {
        array[idx].educationLevelDivisionDtos.forEach((el) => {
          el.doesExist = false;
          el.isCompulsory = false;
        });
      }
    }

    this.props.getRenderArray(array);
  };

  handleNestedChange = (
    e,
    idx,
    index,
    stateName,
    objectName,
    attributeName
  ) => {
    let array = JSON.parse(JSON.stringify(this.props[stateName]));
    array[idx][objectName][index][attributeName] = e.target.checked;
    if (e.target.checked) {
      this.initialPageRenderCheckBoxStatus(array);
    } else {
      // if (this.state.allSubjectSettingChecked) {
      //   this.setState({ allSubjectSettingChecked: false });
      // }
    }
    this.props.getRenderArray(array);
  };

  componentDidMount() {
    this.props.getRenderArray([]);
    this.getSubjectSettings();
    this.initialPageRenderCheckBoxStatus([...this.props.renderArray]);
  }

  initialPageRenderCheckBoxStatus = (renderArray) => {
    const subjectSettingArr = renderArray;
    let doesExistValue;
    for (let i = 0; i < subjectSettingArr.length; i++) {
      doesExistValue = subjectSettingArr[i].educationLevelDivisionDtos.every(
        (el) => el.doesExist === true
      );
      if (!doesExistValue) {
        return;
      }
    }

    let checkedValue;
    for (let i = 0; i < subjectSettingArr.length; i++) {
      checkedValue = [subjectSettingArr[i].yearLevelDto].every(
        (el) => el.checked === true
      );
      if (!checkedValue) {
        return;
      }
    }

    // if (checkedValue && doesExistValue) {
    //   this.setState({ allSubjectSettingChecked: true });
    // }
  };

  getSubjectSettings = () => {
    this.setState({ subjectSettings: [] }, function () {
      axiosPost(
        URL.getSubjectSettings,
        {
          subjectId: this.props.subjectId,
          assessmentModuleCode: this.props.code,
        },
        (response) => {
          if (response.status === 200) {
            let subjectSettings = response.data.data;
            if (subjectSettings.length > 0) {
              subjectSettings.forEach((el) => {
                el.yearLevelDto.creditHour = el.yearLevelDto.creditHour
                  ? el.yearLevelDto.creditHour
                  : null;
              });
            }
            this.setState(
              {
                subjectSettings,
              },
              () => {
                this.props.getRenderArray(subjectSettings);
              }
            );
          }
        }
      );
    });
  };

  handleSubjectSettingCheckbox = (e, idx) => {
    let subjectSettingArr = [...this.props.renderArray];
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
    if (!this.state?.allSubjectSettingChecked === true) {
      for (let i = 0; i < subjectSettingArr.length; i++) {
        subjectSettingArr[i].educationLevelDivisionDtos.forEach(
          (el) => (el.doesExist = true)
        );
        [subjectSettingArr[i].yearLevelDto].forEach(
          (el) => (el.checked = true)
        );
      }
    } else {
      for (let i = 0; i < subjectSettingArr.length; i++) {
        subjectSettingArr[i].educationLevelDivisionDtos.forEach(
          (el) => (el.doesExist = false)
        );
        [subjectSettingArr[i].yearLevelDto].forEach(
          (el) => (el.checked = false)
        );
      }
    }
  };

  render() {
    return (
      <>
        <div className="row mx-2">
          <table className="table table-bordered table-striped">
            <thead className="tt-group-header text-center">
              <tr>
                <th>
                  <input
                    name="allSubjectSettingChecked"
                    type="checkbox"
                    checked={this.state.allSubjectSettingChecked}
                    onChange={(e) =>
                      this.handleSubjectSettingCheckbox(e, "all")
                    }
                  />
                </th>
                <th>{localStorage.getItem("yearLevel")}</th>
                <th>Credit Hour (Optional)</th>
                <th>{localStorage.getItem("educationLevelDivision")}</th>
                <th>Coordinator</th>
              </tr>
            </thead>
            <tbody>
              {this.props.renderArray ? (
                this.props.renderArray.length > 0 ? (
                  this.props.renderArray.map((el, idx) => {
                    return (
                      <tr key={idx}>
                        <td></td>
                        <td>
                          <Switch
                            checked={el.yearLevelDto.checked}
                            onClick={(e) =>
                              this.handleChange(
                                e,
                                idx,
                                "renderArray",
                                "yearLevelDto",
                                "checked"
                              )
                            }
                          />

                          {el.yearLevelDto.name}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="creditHour"
                            value={el.yearLevelDto.creditHour}
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                idx,
                                "renderArray",
                                "yearLevelDto",
                                "creditHour"
                              )
                            }
                          ></input>
                        </td>
                        <td>
                          {el.educationLevelDivisionDtos
                            ? el.educationLevelDivisionDtos.map(
                                (element, index) => {
                                  return (
                                    <table key={index} className="table">
                                      <tbody>
                                        <tr>
                                          <td width="66.66%">
                                            <div className="row">
                                              <div className="col-md-3">
                                                <input
                                                  type="checkbox"
                                                  id={`${idx}check${index}`}
                                                  checked={element.doesExist}
                                                  onChange={(e) =>
                                                    this.handleNestedChange(
                                                      e,
                                                      idx,
                                                      index,
                                                      "renderArray",
                                                      "educationLevelDivisionDtos",
                                                      "doesExist"
                                                    )
                                                  }
                                                  disabled={
                                                    el.yearLevelDto.checked
                                                      ? false
                                                      : "disabled"
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-8">
                                                {" "}
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`${idx}check${index}`}
                                                >
                                                  {element.name}
                                                </label>
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            width="33.33%"
                                            className="text-center"
                                          >
                                            {/* <span className="ml-3 mr-2">
                                              Optional
                                            </span> */}
                                            <div>
                                              <input
                                                type="checkbox"
                                                // id={`${idx}check${index}`}
                                                checked={element.isCompulsory}
                                                onChange={(e) =>
                                                  this.handleNestedChange(
                                                    e,
                                                    idx,
                                                    index,
                                                    "renderArray",
                                                    "educationLevelDivisionDtos",
                                                    "isCompulsory"
                                                  )
                                                }
                                                disabled={
                                                  element.doesExist === true
                                                    ? false
                                                    : true
                                                }
                                                style={{ marginRight: "5px" }}
                                              />
                                              <span
                                                style={{
                                                  position: "relative",
                                                  top: "-2px",
                                                }}
                                              >
                                                Mandatory
                                              </span>
                                            </div>
                                            {/* <Switch
                                              size="small"
                                              checked={element.isCompulsory}
                                              onClick={(e) =>
                                                this.handleNestedChange(
                                                  e,
                                                  idx,
                                                  index,
                                                  "renderArray",
                                                  "educationLevelDivisionDtos",
                                                  "isCompulsory"
                                                )
                                              }
                                              value="compulsory"
                                              color="primary"
                                              disabled={
                                                element.doesExist === true
                                                  ? false
                                                  : true
                                              }
                                            /> */}
                                            {/* <span className="ml-2 mr-3">
                                              Mandatory
                                            </span> */}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                }
                              )
                            : null}
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name="subjectCoordinatorRecruitmentId"
                            value={
                              this.props.renderArray[idx].yearLevelDto[
                                "subjectCoordinatorRecruitmentId"
                              ]
                            }
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                idx,
                                "renderArray",
                                "yearLevelDto",
                                "subjectCoordinatorRecruitmentId"
                              )
                            }
                            disabled={
                              el.yearLevelDto.checked ? false : "disabled"
                            }
                          >
                            <option value="" selected disabled>
                              Choose Subject Coordinator
                            </option>
                            {this.props.recruitments
                              ? this.props.recruitments.map(
                                  (recruitment, idx) => {
                                    return (
                                      <option key={idx} value={recruitment.id}>
                                        {recruitment.people.name}
                                      </option>
                                    );
                                  }
                                )
                              : null}
                          </select>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3" align="center">
                      Please add {localStorage.getItem("yearLevel")} to proceed
                      with the setting.
                    </td>
                  </tr>
                )
              ) : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  renderArray: state.primaryArr.renderArray,
});

const mapActionToProps = {
  getRenderArray,
};

export default connect(mapStateToProps, mapActionToProps)(SubjectSettings);
