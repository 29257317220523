import React, { Component, Suspense } from "react";
import Navbar from "./Navbar";
import CoverPicture from "./CoverPicture";
import ProfilePicture from "./ProfilePicture";
import cover1 from "../../assets/images/profile-bg.png";
import swal from "@sweetalert/with-react";
import { axiosPost } from "../../utils/AxiosApi";
import {
  URL,
  profilePeopleId,
  mainUserId,
  userRoleCode,
  userRole,
  checkRolePermissions,
  getPermissionFromStorage,
  modules,
} from "../../utils/Constants";
import NoticeDisplay from "./Notice/NoticeDisplay";
import CalendarDisplay from "./CalendarDisplay";
import { connect } from "react-redux";
import {
  getPosts,
  getGroupPosts,
  getPersonProfilePosts,
  getFamilyPosts,
  getPersonProfileRecentPosts,
  getPersonPostSectionAcademicRepoPosts,
  resetSearchBoolean,
} from "../../ducks/PostsDucks";
import { setPermissions } from "../../ducks/LogInDucks";
import history from "../../utils/History";
import ModalWindow from "./../UI/ModalWindow";
import ChangePassword from "./../Login/ChangePassword";
import MobileUserDetail from "./MobileUserDetail";
import { isEqual } from "lodash";
import CryptoJS from "crypto-js";
import { displayErrorAlert } from "../../utils/Utils";
import YearLevelOrder from "../UI/YearLevelOrder";

const WidgetArea = React.lazy(() => import("./WidgetArea"));
const PostArea = React.lazy(() => import("./PostArea"));
const GroupArea = React.lazy(() => import("./GroupArea"));

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    userRole === userRoleCode.roleStudentContact &&
      (this.props.location.pathname === "/" ||
        this.props.location.pathname === "/profile" ||
        this.props.location.pathname === "/profile/") &&
      history.push(
        `${process.env.PUBLIC_URL}/familyPage/${localStorage.getItem(
          "familyId"
        )}`
      );

    this.state = {
      passwordModal: false,
      dropdownOpen: false,
      pictures: [
        { id: "tt-cover1", src: cover1 },
        { id: "tt-cover2", src: cover1 },
        { id: "tt-cover3", src: cover1 },
        { id: "tt-cover4", src: cover1 },
      ],
      fullName: "",
      userRole: "",
      userData: "",
      minimize: false,
      setting: false,
      peopleId: "",
      assessmentModuleandEducationLevel: [],
      loggedInUser: null,
      idForRendering: 0,
      isLegalGuardian: false,
      counter: 60000,
      yearLevel: "",
      classroom: "",
    };
  }

  increaseIdForRendering = () => {
    this.setState({ idForRendering: this.state.idForRendering + 1 });
  };

  getUserByUserName = () => {
    const data = {
      username: this.props.match.params.username,
    };
    axiosPost(URL.getLoggedInUser, data, (response) => {
      if (response.status === 200) {
        this.setState({ loggedInUser: response.data.data }, () => {
          this.props.getPersonProfilePosts(
            this.state.loggedInUser.peopleId,
            "initCall"
          );
          this.props.getPersonProfileRecentPosts(
            this.state.loggedInUser.peopleId
          );
          if (userRole === userRoleCode.roleStudentContact) {
            this.checkIsLegal(this.state.loggedInUser.peopleId);
          }

          this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
          this.getUserProfileInfo();
        });
      }
    });
  };

  getPermissions = () => {
    axiosPost(URL.getPermissions, {}, (response) => {
      if (response.status === 200) {
        const permissionFromStorage = getPermissionFromStorage();
        const widgets = response.data.data.widgets;
        const academicYearId = response.data.data.academicYearId;
        if (isEqual(widgets, permissionFromStorage)) {
          clearInterval(this.callPermissionInterval);
          this.setState({ counter: this.state.counter * 2 }, () => {
            this.callPermissionInterval = setInterval(
              this.getPermissions,
              this.state.counter
            );
          });
        } else {
          if (
            academicYearId === parseInt(localStorage.getItem("academicYearId"))
          ) {
            swal({
              title: "Your permission has changed.",
              text: "Please log in again.",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            }).then(() => {
              localStorage.clear();
              history.push("/");
            });
          } else {
            swal({
              title: "Your permission has changed.",
              text: "Redirecting you back to profile page.",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            }).then(() => {
              localStorage.setItem(
                "widgets",
                CryptoJS.AES.encrypt(
                  JSON.stringify(widgets),
                  "saurav_imagine_sys"
                )
              );
              localStorage.setItem("academicYearId", academicYearId);
              window.location.reload();
            });
          }
        }
      }
    });
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  toggleHover = (component, action) => {
    let systemDefault = document.getElementById("tt-systemDefault");
    let personalChange = document.getElementById("tt-personalChange");
    let id = parseInt(
        this.state.loggedInUser
          ? this.state.loggedInUser.peopleId
          : profilePeopleId
      ),
      actualId = parseInt(profilePeopleId);
    if (component === "cover") {
      if (action === "show") {
        if (
          id === actualId ||
          checkRolePermissions("can-update-personal-cp", "activity") ||
          this.state.isLegalGuardian
        ) {
          if (document.getElementById("tt-editButton")) {
            document.getElementById("tt-editButton").style.opacity = 1;
          }
          if (systemDefault !== null && personalChange !== null) {
            systemDefault.style.opacity = 1;
            personalChange.style.opacity = 1;
          }
        } else {
          if (document.getElementById("tt-editButton")) {
            document.getElementById("tt-editButton").style.opacity = 0;
          }
          if (systemDefault !== null && personalChange !== null) {
            systemDefault.style.opacity = 0;
            personalChange.style.opacity = 0;
          }
        }
      } else {
        if (document.getElementById("tt-editButton")) {
          document.getElementById("tt-editButton").style.opacity = 0;
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.opacity = 0;
          personalChange.style.opacity = 0;
        }
      }
    } else {
      if (action === "show") {
        let adminOrModerator = checkRolePermissions(
          "can-update-profile-pic",
          "activity"
        );
        if (id === actualId || adminOrModerator || this.state.isLegalGuardian) {
          if (document.getElementById("tt-editProfileButton"))
            document.getElementById("tt-editProfileButton").style.opacity = 1;
        } else {
          if (document.getElementById("tt-editProfileButton"))
            document.getElementById("tt-editProfileButton").style.opacity = 0;
        }
      } else {
        if (document.getElementById("tt-editProfileButton"))
          document.getElementById("tt-editProfileButton").style.opacity = 0;
      }
    }
  };

  checkIsLegal = (profilePeopleId) => {
    let data = {
      peopleId: profilePeopleId,
    };
    axiosPost(URL.checkIsLegal, data, (response) => {
      if (response.status === 200) {
        this.setState({ isLegalGuardian: response.data.data });
      }
    });
  };

  checkFirstLogin = () => {
    axiosPost(URL.isFirstLogin, {}, (response) => {
      if (response.status === 200) {
        this.setState({ passwordModal: response.data.data.isFirstLogin });
      }
    });
  };

  componentDidMount() {
    //check first login to show change password modal, only for non studnet contacts in profile
    userRole !== userRoleCode.roleStudentContact && this.checkFirstLogin();
    if (userRole !== userRoleCode.roleStudentContact) {
      localStorage.removeItem("familyId");
    }
    if (
      Object.keys(this.props.match.params).length > 0 &&
      this.props.match.params.constructor === Object
    ) {
      this.setState({ loggedInUser: null }, () => {
        this.getUserByUserName();
      });
    } else {
      this.getUserProfileInfo();
      //get posts
      this.props.getPersonProfilePosts(profilePeopleId, "initCall");
      this.props.getPersonProfileRecentPosts(profilePeopleId);
      this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
    }
    axiosPost(URL.getAllAlias, {}, (response) => {
      response.data.data.slice(0, 4).map((alias) => {
        localStorage.setItem(`${alias.systemName}`, `${alias.displayName}`);
        this.setState({ [alias.systemName]: alias.displayName });
        return null;
      });
    });
    this.props.resetSearchBoolean();

    this.minimizeCoverToggle();
    this.getAssesmentModuleAndEducationLevelForCurrentUser();
    const { username } = this.props.match.params;

    //interval for permission
    this.callPermissionInterval = setInterval(
      this.getPermissions,
      this.state.counter
    );
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.callPermissionInterval);
    clearInterval(this.callClearConnectionInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.location.username !== this.props.location.username) {
    //   this.setState({ loggedInUser: null, minimize: false }, () => {
    //     this.getUserByUserName();
    //     this.minimizeCoverToggle();
    //     this.props.resetSearchBoolean();
    //   });

    // }
    if (prevProps.match.params.username !== this.props.match.params.username) {
      this.setState({ loggedInUser: null, minimize: false }, () => {
        this.getUserByUserName();
        this.minimizeCoverToggle();
        this.props.resetSearchBoolean();
      });
    }
  }
  displayNotice = () => {
    let canDisplayNotice = true;
    if (userRole === userRoleCode.roleStudent && this.state.loggedInUser) {
      if (this.state.loggedInUser.id != mainUserId) {
        canDisplayNotice = false;
      }
    }
    return canDisplayNotice;
  };

  getAssesmentModuleAndEducationLevelForCurrentUser = () => {
    axiosPost(
      // URL.getAssesmentModuleAndEducationLevelForCurrentUser,
      URL.getEducationLevelForCurrentUser,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({
            assessmentModuleandEducationLevel: response.data.data,
          });
        }
      }
    );
  };

  //   componentDidUpdate(prevProps, prevState) {
  //     if (
  //       prevProps.location.profilePeopleId !== this.props.location.profilePeopleId
  //     ) {
  //       this.setState({ peopleId: this.props.location.profilePeopleId });
  //       this.minimizeCoverToggle();
  //       this.getUserProfileInfo();
  //     }
  //   }

  minimizeCoverToggle = () => {
    var coverImage = document.querySelectorAll(".tt-slider-content");
    var coverImageHolder = document.getElementById("tt-coverImage");
    var editButton = document.getElementById("tt-editButton");
    let systemDefault = document.getElementById("tt-systemDefault");
    let personalChange = document.getElementById("tt-personalChange");

    this.setState({ minimize: !this.state.minimize }, function () {
      if (this.state.minimize) {
        coverImageHolder.classList.add("tt-cover-minimize");
        if (editButton) {
          editButton.style.display = "none";
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.display = "none";
          personalChange.style.display = "none";
        }
        for (let i = 0; i < coverImage.length; i++) {
          coverImage[i].classList.add("tt-slider-content-minimize");
        }
      } else {
        coverImageHolder.classList.remove("tt-cover-minimize");
        if (editButton) {
          editButton.style.display = "block";
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.display = "block";
          personalChange.style.display = "block";
        }
        for (let i = 0; i < coverImage.length; i++) {
          coverImage[i].classList.remove("tt-slider-content-minimize");
        }
      }
    });
  };

  getUserProfileInfo = () => {
    let data = {
      peopleId: this.state.loggedInUser
        ? this.state.loggedInUser.peopleId
        : profilePeopleId,
    };
    axiosPost(
      URL.userProfileInfo,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState(
            {
              fullName: response.data.data.fullName,
              userRole: response.data.data.role,
              userData: response.data.data,
            },
            function () {
              document.title = `ImagineSys : ${this.state.fullName}`;
            }
          );
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
          setting={this.state.setting}
          peopleId={this.state.peopleId}
        ></Navbar>
        {this.state.loggedInUser ? (
          <ProfilePicture
            toggleHover={this.toggleHover}
            fullName={this.state.fullName}
            userId={this.state.loggedInUser.id}
            profilePeopleId={this.state.loggedInUser.peopleId}
            peopleId={this.state.loggedInUser.peopleId}
            otherProfile={
              this.props.location.otherProfile
                ? this.props.location.otherProfile
                : false
            }
            memberPhoto={
              this.props.location.memberPhoto
                ? this.props.location.memberPhoto
                : null
            }
            isLegalGuardian={this.state.isLegalGuardian}
          ></ProfilePicture>
        ) : (
          <ProfilePicture
            toggleHover={this.toggleHover}
            fullName={this.state.fullName}
            isLegalGuardian={this.state.isLegalGuardian}
            userId={mainUserId}
            peopleId={profilePeopleId}
            profilePeopleId={profilePeopleId}
            memberPhoto={
              this.props.location.memberPhoto
                ? this.props.location.memberPhoto
                : null
            }
          ></ProfilePicture>
        )}
        {this.state.loggedInUser ? (
          <CoverPicture
            notices={this.state.notices}
            toggleHover={this.toggleHover}
            pictures={this.state.pictures}
            minimizeCoverToggle={this.minimizeCoverToggle}
            userRole={this.state.userRole}
            minimize={this.state.minimize}
            userId={this.state.loggedInUser.id}
            profilePeopleId={this.state.loggedInUser.peopleId}
            peopleId={this.state.loggedInUser.peopleId}
            loggedInUser={this.state.loggedInUser}
            userData={this.state.userData}
            isLegalGuardian={this.state.isLegalGuardian}
            username={this.props.match.params.username}
          />
        ) : (
          <CoverPicture
            notices={this.state.notices}
            toggleHover={this.toggleHover}
            pictures={this.state.pictures}
            minimizeCoverToggle={this.minimizeCoverToggle}
            userRole={this.state.userRole}
            minimize={this.state.minimize}
            userId={
              this.props.location.userId
                ? this.props.location.userId
                : mainUserId
            }
            peopleId={profilePeopleId}
            profilePeopleId={
              this.props.location.profilePeopleId
                ? this.props.location.profilePeopleId
                : profilePeopleId
            }
            userData={this.state.userData}
            isLegalGuardian={this.state.isLegalGuardian}
            username={this.props.match.params.username}
          />
        )}

        <div className="tt-userInfoMobile">
          <MobileUserDetail
            userRole={this.state.userRole}
            userData={this.state.userData}
          ></MobileUserDetail>
        </div>
        {this.displayNotice() ? (
          <div className="container">
            <NoticeDisplay
              userData={this.state.userData}
              peopleId={
                this.state.loggedInUser
                  ? this.state.loggedInUser.peopleId
                  : profilePeopleId
              }
              userId={
                this.props.location.userId
                  ? this.props.location.userId
                  : mainUserId
              }
              username={this.props.match.params.username}
              increaseIdForRendering={this.increaseIdForRendering}
              idForRendering={this.state.idForRendering}
            ></NoticeDisplay>
          </div>
        ) : null}

        <div className="container">
          <div id="tt-contentSection" className={"tt-content-section"}>
            <div className="row reverseContent">
              <div className="col-md-6">
                <Suspense fallback={<div>Loading...</div>}>
                  <PostArea
                    profilePeopleId={
                      this.state.loggedInUser
                        ? this.state.loggedInUser.peopleId
                        : localStorage.getItem("peopleId")
                    }
                    loggedInUser={this.state.loggedInUser}
                    memberName={
                      this.props.location.name
                        ? this.props.location.name
                        : this.state.loggedInUser?.peopleName
                    }
                    userId={
                      this.state.loggedInUser
                        ? this.state.loggedInUser.id
                        : mainUserId
                    }
                    username={this.props.match.params.username}
                    personProfileRecentPosts={
                      this.props.personProfileRecentPosts
                    }
                    // personAcademicRepoPosts={
                    //   this.props.personPostSectionRepoPosts
                    // }
                  />
                </Suspense>
              </div>
              <div className="col-md-6">
                {this.state.loggedInUser ? (
                  <Suspense fallback={<div>Loading...</div>}>
                    <GroupArea
                      groupId={
                        this.props.location.groupId
                          ? this.props.location.groupId
                          : null
                      }
                      profilePeopleId={this.state.loggedInUser.peopleId}
                    ></GroupArea>
                  </Suspense>
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    <GroupArea
                      groupId={
                        this.props.location.groupId
                          ? this.props.location.groupId
                          : null
                      }
                      profilePeopleId={profilePeopleId}
                    ></GroupArea>
                  </Suspense>
                )}
                <div className="mt-4 mobilemarginView"></div>
                <CalendarDisplay
                  assessmentModuleandEducationLevel={
                    this.state.assessmentModuleandEducationLevel
                  }
                ></CalendarDisplay>
                <div className="mt-4"></div>
                <span className="mobileWidgetArea">
                  {this.state.loggedInUser ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <WidgetArea
                        assessmentModuleandEducationLevel={
                          this.state.assessmentModuleandEducationLevel
                        }
                        profilePeopleId={this.state.loggedInUser.peopleId}
                      ></WidgetArea>
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div>Loading...</div>}>
                      <WidgetArea
                        assessmentModuleandEducationLevel={
                          this.state.assessmentModuleandEducationLevel
                        }
                        profilePeopleId={profilePeopleId}
                      ></WidgetArea>
                    </Suspense>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.passwordModal}
          modalHeader="Change Password"
          size="lg"
          // fullWidth={true}
          toggleModal={this.togglePasswordModal}
          modalBody={<ChangePassword />}
        ></ModalWindow>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  personPostSectionRepoPosts: state.posts.personPostSectionRepoPosts,
});

const mapActionToProps = {
  getPosts,
  getGroupPosts,
  getPersonProfilePosts,
  getFamilyPosts,
  getPersonProfileRecentPosts,
  getPersonPostSectionAcademicRepoPosts,
  setPermissions,
  resetSearchBoolean,
};
export default connect(mapStateToProps, mapActionToProps)(ProfilePage);
