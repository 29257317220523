import React, { Component } from "react";
import { Spinner } from "reactstrap";
import Pagination from "../UI/Pagination";
import { checkSystemAdmin } from "./../../utils/Constants";

class AllUsers extends Component {
  checkSystemAdminInList = (user) => {
    if (
      user.subRoleCodes?.findIndex(
        (subRoleCode) => subRoleCode.subRoleCode === "SYS_ADMIN"
      ) !== -1
    )
      return true;
    else return false;
  };

  render() {
    let userList = this.props.allUsers;
    return (
      <div className="p-2">
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>People Name</th>
              <th>Username</th>
              <th>User Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userList.length > 0 ? (
              userList.map((user, idx) => {
                return (
                  <tr key={idx}>
                    <td>{user.peopleName}</td>
                    <td>{user.username}</td>
                    <td>{user.userRole}</td>
                    <td className="text-center">
                      {checkSystemAdmin() &&
                      this.checkSystemAdminInList(user) ? null : (
                        <>
                          <button
                            className="tt-button tt-button-primary mr-3"
                            onClick={() =>
                              this.props.toggleForgotModal(
                                user.username,
                                user.id
                              )
                            }
                          >
                            Update Password
                          </button>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() =>
                              this.props.enableDisableUser(
                                user.id,
                                !user.enabled
                              )
                            }
                          >
                            {user.enabled ? "Disable User" : "Enable User"}
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="text-center">
                  {this.props.displayAllUserSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading data...
                    </>
                  ) : (
                    "No users"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemsCount={this.props.allUserTotalRecords}
            pageSize={this.props.allUserRowCountLimit}
            currentPage={this.props.allUserCurrentPage}
            onPageChange={this.props.allUserHandlePageChange}
            nextPage={this.props.allUserHandleNextPage}
            previousPage={this.props.allUserHandlePreviousPage}
          ></Pagination>
        </div>
      </div>
    );
  }
}

export default AllUsers;
