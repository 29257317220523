import React, { Component } from "react";
import { checkRolePermissions } from "../../../utils/Constants";

class RepositorySearchSection extends Component {
  state = {};

  render() {
    return (
      <>
        <div className="tt-group-header-dark">
          <div className="row">
            <div className="col-md-4">
              <input
                name="searchText"
                value={this.props.searchText}
                onChange={this.props.handleChange}
                type="text"
                className="form-control"
                placeholder="Search Post by title,..."
              />
            </div>
            <div className="col-md-1 mt-1">Keywords </div>
            <div className="col-md-3">
              <input
                name="keywords"
                value={this.props.keywords}
                onChange={this.props.handleChange}
                type="text"
                list="labels"
                id="keywords"
                className="form-control"
              />
              <datalist id="labels">
                <option value="Knowledge">Knowledge</option>
                <option value="Skill">Skill</option>
                <option value="Approaches">Approaches</option>
              </datalist>
            </div>
            <div className="col-md-1 mt-1 text-center">Theme </div>
            <div className="col-md-3">
              <select
                className="form-control"
                name="theme"
                onChange={this.props.handleChange}
                value={this.props.theme}
              >
                <option value="" disabled>
                  {/* Choose Multidisciplinary Theme */}
                  Choose Theme
                </option>
                <option value="">None</option>
                {this.props.themeList
                  ? this.props.themeList.map((theme, idx) => {
                      return (
                        <option value={theme.id} key={idx}>
                          {theme.theme}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-1 mt-2 pr-0" style={{ fontSize: "12px" }}>
              Date From
            </div>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="from"
                name="fromDate"
                value={this.props.fromDate}
                onChange={this.props.handleChange}
              />
            </div>
            <div className="col-md-1 mt-2 pr-0" style={{ fontSize: "12px" }}>
              Date To
            </div>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                id="to"
                name="toDate"
                value={this.props.toDate}
                onChange={this.props.handleChange}
                //   min={this.props.fromDate}
              />
            </div>
            {checkRolePermissions("accept-academic-repository") ||
            checkRolePermissions("edit-academic-repository") ? (
              <div className="col-md-2 pr-0">
                <select
                  className="form-control"
                  name="postStatus"
                  value={this.props.postStatus}
                  onChange={this.props.handleChange}
                >
                  <option value="" disabled>
                    Choose Status
                  </option>
                  <option value={false}>Submitted</option>
                  <option value={true}>Accepted</option>
                </select>
              </div>
            ) : null}
            <div
              className={
                checkRolePermissions("accept-academic-repository") ||
                checkRolePermissions("edit-academic-repository")
                  ? "col-md-2"
                  : "col-md-4"
              }
            >
              <select
                className="form-control"
                name="subjects"
                value={this.props.subjects}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Choose Subjects
                </option>
                <option value="">None</option>
                {this.props.subjectList
                  ? this.props.subjectList.map((subject, idx) => {
                      return (
                        <option value={subject.name} key={idx}>
                          {subject.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.props.searchPost}
              >
                Search Academic Repository
              </button>
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.clearSearch}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid index-info">
          <div className="row">
            <div
              className="col-md-10"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                paddingTop: "7px",
              }}
            >
              <strong>Searched By: </strong>
              {this.props.searchParam}
            </div>
            <div className="col-md-2 p-2">
              <strong>Results Found: </strong>
              {this.props.totalAcademicRepoPosts}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RepositorySearchSection;
