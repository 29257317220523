import React, { Component } from "react";
import { prrCode, URL, draggable } from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import CreateCommentTemplate from "./CreateCommentTemplate";
import swal from "sweetalert";
import CommentsBankDisplay from "./CommentsBankDisplay";
import "jodit";
import $ from "jquery";

class CommentsBank extends Component {
  state = {
    subjectList: [],
    assessmentType: "",
    editAssessmentType: "",
    editSharingOption: "",
    sharingOption: "",
    editComment: "",
    valueArr: "",
    optionData: "",
    space: "&nbsp;",
    commentTemplates: true,
    comments: [],
    commentBanks: "",
    pronouns: [
      { name: "He/She" },
      { name: "he/she" },
      { name: "His/Her" },
      { name: "his/her" },
      { name: "his/hers" },
      { name: "him/her" },
    ],
    cursorPosition: null,
  };

  createEditorRef = React.createRef();
  editEditorRef = React.createRef();

  componentDidMount() {
    this.getSubjects();
    this.getCustomFields();
    this.getCommentBanks(this.state.commentTemplates);
  }

  getCommentBanks = (value) => {
    let data;
    if (value === "both") {
      data = {};
    } else {
      data = {
        myTemplate: value,
      };
    }
    axiosPost(URL.getCommentBanks, data, (response) => {
      if (response.status === 200) {
        let commentBanks = response.data.data;
        commentBanks.forEach((el) => {
          el.editable = false;
        });
        this.setState({ commentBanks });
      }
    });
  };

  getCustomFields = () => {
    axiosPost(URL.getIdentityCluster, {}, (response) => {
      if (response.status === 200) {
        this.setState({ comments: response.data.data });
      }
    });
  };
  //jodit
  changeHandler = (value) => {
    if (value === undefined) {
      //   var selection = document.getSelection();
      //   var cursorPos = selection.anchorOffset;

      //   if (cursorPos === 0 && selection.anchorNode.nodeName === "#text") {
      //     let values = this.state.valueArr;
      //     values =
      //       `${values} <span data-template="${this.state.optionData}" class="tt-commentTag">[${this.state.optionData}]</span>` +
      //       `${this.state.space}`;
      //     this.setState({ valueArr: values, optionData: "" });
      //   } else if (selection.anchorNode.nodeName === "#text" && cursorPos > 0) {
      //     var oldContent = this.state.valueArr;
      //     var toInsert =
      //       ` <span data-template="${this.state.optionData}" class="tt-commentTag">[${this.state.optionData}]</span>` +
      //       `${this.state.space}`;
      //     var newContent =
      //       oldContent.substring(0, cursorPos) +
      //       toInsert +
      //       oldContent.substring(cursorPos);
      //     this.setState({ valueArr: newContent, optionData: "" });
      //   } else {
      let values = this.state.valueArr;
      values =
        `${values} <span data-template="${this.state.optionData}" class="tt-commentTag">[${this.state.optionData}]</span>` +
        `${this.state.space}`;
      //   const start = this.createEditorRef.current.selectionStart;
      //   values = `${values.slice(0, start)}
      //     ${this.state.optionData} [${this.state.optionData}] ${values.slice(
      //     start + 1
      //   )}`;

      this.setState({ valueArr: values, optionData: "" });
      //   }
    } else {
      this.setState({ valueArr: value });
    }
  };

  // for text area
  changeHandlerTextArea = (e) => {
    const target = e.target;
    const value = target.value;
    var updateState = true;
    $(document).keypress(function (event) {
      var keycode = event.keyCode ? event.keyCode : event.which;
      if (keycode == 93 || keycode == 91) {
        updateState = false;
      }
    });
    if (updateState) {
      this.setState({ valueArr: value });
    } else {
      swal("Character not allowed", "");
    }
  };

  //jodit
  clickHandler = (data) => {
    this.setState({ optionData: data }, function () {
      this.changeHandler();
    });
  };

  //for text area
  clickHandlerTextArea = (data) => {
    var $t = document.getElementById("createCommentBank");
    this.insertAtCursor($t, "[" + data + "]", "valueArr");
  };

  //insert at cursor for textarea
  insertAtCursor = (myField, myValue, stateVariableKey) => {
    //IE support
    if (document.selection) {
      myField.focus();
      var sel = document.selection.createRange();
      sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == "0") {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      myField.value =
        myField.value.substring(0, startPos) +
        myValue +
        myField.value.substring(endPos, myField.value.length);
      myField.selectionStart = startPos + myValue.length;
      myField.selectionEnd = startPos + myValue.length;
    } else {
      myField.value += myValue;
    }
    myField.focus();
    this.setState({ [stateVariableKey]: myField.value });
  };
  //jodit
  changeEditHandler = (value) => {
    if (value === undefined) {
      //   const start = this.editEditorRef.current.selectionStart;
      //   const end = this.editEditorRef.current.selectionEnd;
      let values = this.state.editComment;
      values =
        `${values} <span data-template="${this.state.optionData}" class="tt-commentTag">[${this.state.optionData}]</span>` +
        `${this.state.space}`;

      //   values = `${values.slice(0, start)}
      // ${this.state.optionData} [${this.state.optionData}] ${values.slice(
      //     start + 1
      //   )}`;

      this.setState({ editComment: values, optionData: "" });
    } else {
      this.setState({ editComment: value });
    }
  };

  // for text area
  changeEditHandlerTextArea = (e) => {
    const target = e.target;
    const value = target.value;

    this.setState({ editComment: value });
  };

  //jodit
  clickEditHandler = (data) => {
    this.setState({ optionData: data }, function () {
      this.changeEditHandler();
    });
  };

  //for text area
  clickEditHandlerTextArea = (data) => {
    var $t = document.getElementById("editCommentBank");
    this.insertAtCursor($t, "[" + data + "]", "editComment");
  };

  getSubjects = () => {
    let data = {
      assessmentModuleCode: prrCode.code,
    };
    axiosPost(URL.subjectList, data, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
      }
    });
  };

  handleSelectChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  handleCommentChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      this.getCommentBanks(value);
    });
  };

  updateCommentTemplate = (e, id) => {
    e.preventDefault();
    let isGeneralAssessment;
    let subjectId;
    if (this.state.editAssessmentType === "general") {
      isGeneralAssessment = true;
      subjectId = null;
    } else {
      isGeneralAssessment = false;
      subjectId = this.state.editAssessmentType;
    }

    let data = {
      id: id,
      commentText: this.state.editComment,
      isGeneralAssessment: isGeneralAssessment,
      subjectId: subjectId,
      isPublic: this.state.editSharingOption,
    };

    axiosPost(URL.updateCommentBank, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Comment template successfully edited",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getCommentBanks(this.state.commentTemplates);
      }
    });
  };

  saveCommentTemplate = (e) => {
    if (
      this.state.valueArr === "" ||
      this.state.assessmentType === "" ||
      this.state.sharingOption === ""
    ) {
      swal({
        title: "Error",
        text: "Fill the comment template properly",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let isGeneralAssessment;
      let subjectId;
      if (this.state.assessmentType === "general") {
        isGeneralAssessment = true;
        subjectId = null;
      } else {
        isGeneralAssessment = false;
        subjectId = this.state.assessmentType;
      }

      let data = {
        commentText: this.state.valueArr,
        isGeneralAssessment: isGeneralAssessment,
        subjectId: subjectId,
        isPublic: this.state.sharingOption,
      };

      axiosPost(URL.insertCommentBank, data, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: "Comment template successfully saved",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          this.getCommentBanks(this.state.commentTemplates);
          this.setState({
            valueArr: "",
            sharingOption: "",
            assessmentType: "",
          });
        }
      });
    }
  };

  commentDelete = (e) => {
    let data = {
      id: parseInt(e.id),
    };
    axiosPost(URL.deleteCommentBank, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Comment template successfully deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getCommentBanks(this.state.commentTemplates);
      }
    });
  };

  cancelEdit = () => {
    let commentBanks = [...this.state.commentBanks];
    commentBanks.forEach((el) => {
      el.editable = false;
    });
    this.setState({ commentBanks: commentBanks, editComment: "" });
  };

  commentEdit = (e, idx, comments) => {
    let assessmentType;
    let commentBanks = [...this.state.commentBanks];
    commentBanks.forEach((el) => {
      el.editable = false;
    });
    commentBanks[idx].editable = true;

    if (comments.isGeneralAssessment) {
      assessmentType = "general";
    } else {
      assessmentType = comments.subjectId;
    }

    this.setState({
      commentBanks: commentBanks,
      editAssessmentType: assessmentType,
      editSharingOption: comments.isPublic,
      editComment: comments.commentText,
    });
  };

  render() {
    return (
      <div style={{ overflowY: "scroll", height: "90vh" }}>
        <div className="card">
          <div
            className="card-body"
            style={{ height: "60vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-2 tt-calendarLabel ">
                <label>Choose Template</label>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  name="commentTemplates"
                  value={this.state.commentTemplates}
                  onChange={this.handleCommentChange}
                >
                  <option value="" disabled>
                    Choose Comment Template
                  </option>
                  {/* <option value="both">Public and My Comment Template</option> */}
                  <option value={true}>My Comment Template</option>
                  <option value={false}>Public Comment Template</option>
                </select>
                <div></div>
              </div>
              <div className="col-md-3 mt-1">
                (<strong>{this.state.commentBanks.length}</strong> out of{" "}
                <strong>{this.state.commentBanks.length}</strong> results
                displayed)
              </div>
            </div>
            <hr></hr>
            {this.state.commentBanks ? (
              <CommentsBankDisplay
                commentBanks={this.state.commentBanks}
                commentDelete={this.commentDelete}
                editComment={this.state.editComment}
                commentEdit={this.commentEdit}
                // changeEditHandler={this.changeEditHandler}
                changeEditHandler={this.changeEditHandlerTextArea}
                handleSelectChange={this.handleSelectChange}
                editAssessmentType={this.state.editAssessmentType}
                editSharingOption={this.state.editSharingOption}
                pronouns={this.state.pronouns}
                subjectList={this.state.subjectList}
                comments={this.state.comments}
                updateCommentTemplate={this.updateCommentTemplate}
                // clickEditHandler={this.clickEditHandler}
                clickEditHandler={this.clickEditHandlerTextArea}
                cancelEdit={this.cancelEdit}
                commentTemplates={this.state.commentTemplates}
                handleInsertComment={this.props.handleInsertComment}
                commentId={this.props.commentId}
                handleCommentBankHandler={this.props.handleCommentBankHandler}
                editEditorRef={this.editEditorRef}
              ></CommentsBankDisplay>
            ) : (
              <div className="text-center">No comments to display</div>
            )}
          </div>
          <div
            className="card-body"
            style={{ height: "39vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <CreateCommentTemplate
              subjectList={this.state.subjectList}
              handleSelectChange={this.handleSelectChange}
              assessmentType={this.state.assessmentType}
              sharingOption={this.state.sharingOption}
              comments={this.state.comments}
              // changeHandler={this.changeHandler}
              // clickHandler={this.clickHandler}
              changeHandler={this.changeHandlerTextArea}
              clickHandler={this.clickHandlerTextArea}
              pronouns={this.state.pronouns}
              valueArr={this.state.valueArr}
              saveCommentTemplate={this.saveCommentTemplate}
              createEditorRef={this.createEditorRef}
            ></CreateCommentTemplate>
          </div>
        </div>
      </div>
    );
  }
}

export default CommentsBank;
