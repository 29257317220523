/* author:sameer pokhrel 
-Component for Inserting Event Type with Color Code & holiday flag
*/
import React, { Component } from "react";
import IndicatorTypeColorPicker from "../../AssessmentRecordReport/AssessmentRecord/IndicatorTypeColorPicker";
import { URL, checkWidgetAdminOrNot } from "../../../utils/Constants";

import { axiosPost } from "../../../utils/AxiosApi";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  displaySuccessAlert,
} from "../../../utils//Utils";

import swal from "sweetalert";
import ModalWindow from "../../UI/ModalWindow";
import GranularPermissionModal from "../../Permissions/GranularPermissionModal";
import CalendarEventList from "./CalenderEventList";

class CalendarEventType extends Component {
  state = {
    changeColor: "#f17013",
    color: { a: "1", b: "19", g: "112", r: "241" },
    colorCode: "#f17013",
    colorCodeError: false,
    eventnameError: false,
    eventTypeName: "",
    holidaysOption: [],
    isColorPickerOpen: false,
    isHoliday: false,
    oldColorCode: "",
    permissionModal: false,
    showSpinner: false,
    updateColorCode: "",
    updateEventName: "",
    updateIsHoliday: false,
  };

  cancelUpdate = () => {
    let holidaysOption = [...this.state.holidaysOption];
    holidaysOption.forEach((el) => {
      el.edit = false;
    });
    this.setState({
      holidaysOption,
      updateEventName: "",
      updateIsHoliday: false,
      updateColorCode: "",
    });
  };

  componentDidMount() {
    this.getHolidays();
  }

  confirmDelete = (data) => {
    axiosPost(
      URL.deleteCalendarEventType,
      data,
      (response) => {
        if (response.status === 200) {
          displaySuccessAlert(response);
          this.getHolidays();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  //calender Events
  getHolidays = () => {
    axiosPost(URL.getHolidayOptions, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0) {
          data.forEach((el) => {
            el.edit = false;
          });
        }
        this.setState({
          holidaysOption: data,
        });
      }
    });
  };

  getParams = () => {
    return {
      eventTypeName: this.state.eventTypeName,
      colorCode: this.state.changeColor,
      isHoliday: this.state.isHoliday ? this.state.isHoliday : false,
    };
  };

  handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  handleChangeForColorPicker = (color) => {
    this.setState({
      color: color.rgb,
      changeColor: color.hex,
      colorCode: color.hex,
    });
  };

  handleClickColorPicker = (index) => {
    let isColorPickerOpen = !this.state.isColorPickerOpen;
    this.setState({ isColorPickerOpen: isColorPickerOpen });
  };

  handleCloseForColorPicker = () => {
    this.setState({ isColorPickerOpen: false });
  };

  handleDelete = (colorCode) => {
    displayConfirmDeleteAlert({ colorCode }, this.confirmDelete);
  };

  handleSubmit = (e, params) => {
    e.preventDefault();
    const reqParams = [{ ...params }];
    if (this.state.eventTypeName === "" || this.state.colorCode === "") {
      swal({
        title: "Error",
        text: "Fill all the necessary fields.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      this.setState({
        eventnameError: this.state.eventTypeName === "" ? true : false,
        colorCodeError: this.state.changeColor === "" ? true : false,
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertCalenderEventType,
          reqParams,
          (response) => {
            if (response.status === 200) {
              displaySuccessAlert(response);
              this.getHolidays();
              this.setState({
                isColorPickerOpen: false,
                color: { a: "1", b: "19", g: "112", r: "241" },
                changeColor: "#f17013",
                colorCode: "#f17013",
                eventnameError: false,
                colorCodeError: false,
                showSpinner: false,
                isHoliday: false,
                eventTypeName: "",
                permissionModal: false,
              });
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            displayErrorAlert(error);
          }
        );
      });
    }
  };

  handleUpdate = (item) => {
    let holidaysOption = [...this.state.holidaysOption];
    holidaysOption.forEach((el) => {
      if (el.colorCode === item.colorCode) {
        el.edit = true;
        this.setState({
          updateEventName: el.eventTypeName,
          updateIsHoliday: el.isHoliday,
          updateColorCode: el.colorCode,
          oldColorCode: el.colorCode,
        });
      } else {
        el.edit = false;
      }
    });
    this.setState({ holidaysOption });
  };

  updateEvent = (colorCodeData) => {
    let param = {};
    if (colorCodeData !== "") {
      param = {
        newColorCode: colorCodeData,
        eventTypeName: this.state.updateEventName,
        colorCode: this.state.oldColorCode,
        isHoliday: this.state.updateIsHoliday,
      };
    } else {
      param = {
        eventTypeName: this.state.updateEventName,
        colorCode: this.state.oldColorCode,
        isHoliday: this.state.updateIsHoliday,
      };
    }

    axiosPost(
      URL.updateCalendarEventType,
      param,
      (response) => {
        if (response.status === 200) {
          displaySuccessAlert(response);
          this.cancelUpdate();
          this.getHolidays();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Insert Event Type
            {checkWidgetAdminOrNot("Academic Administration") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>

          <div className="container">
            <form onSubmit={(e) => this.handleSubmit(e, this.getParams())}>
              <div className="form-group row">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <label htmlFor="name">
                    <strong>Event Type Name:</strong>
                  </label>
                  <span className="tt-assessment-module-mandatory">*</span>
                </div>
                <div className="col-md-4">
                  <input
                    className={
                      this.state.eventnameError
                        ? "form-control mb-2 indicate-error"
                        : "form-control mb-2"
                    }
                    type="text"
                    name="eventTypeName"
                    placeholder="Enter EventType Name"
                    onChange={this.handleChange}
                    value={this.state.eventTypeName}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <label htmlFor="name">
                    <strong>Color Code:</strong>
                  </label>
                  <span className="tt-assessment-module-mandatory">*</span>
                </div>
                <div className="col-md-4">
                  <IndicatorTypeColorPicker
                    color={this.state.color}
                    handleChangeForColorPicker={this.handleChangeForColorPicker}
                    handleClickColorPicker={this.handleClickColorPicker}
                    handleCloseForColorPicker={this.handleCloseForColorPicker}
                    isColorPickerOpen={this.state.isColorPickerOpen}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <label htmlFor="name">
                    <strong>isHoliday:</strong>
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    name="isHoliday"
                    className="form-control"
                    onChange={(e) => this.handleChange(e)}
                    checked={
                      this.state.isHoliday ? this.state.isHoliday : false
                    }
                  />
                </div>
              </div>
              <div className="row button-bg-color">
                <div className="col-md-2"></div>
                <div className="col-md-3"></div>
                <div className="col-md-4 text-right">
                  <button type="submit" className="tt-button tt-button-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <CalendarEventList
            cancelUpdate={this.cancelUpdate}
            handleChange={this.handleChange}
            handleDelete={this.handleDelete}
            handleUpdate={this.handleUpdate}
            holidaysOption={this.state.holidaysOption}
            updateEvent={this.updateEvent}
            updateEventName={this.state.updateEventName}
            updateIsHoliday={this.state.updateIsHoliday}
            updateColorCode={this.state.updateColorCode}
          />
        </div>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="Calender"
              header="Add Event Type"
              activityName="insert-event"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}
export default CalendarEventType;
