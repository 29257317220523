import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "react-mdl";
import {
  checkRolePermissions,
  userRole,
  userRoleCode,
} from "../../../../utils/Constants";
import ModalWindow from "../../../UI/ModalWindow";
import Pagination from "../../../UI/Pagination";
import AssignmentDropbox from "./CourseAssignment/AssignmentDropbox";
import CourseAssignment from "./CourseAssignment/CourseAssignment";
import CourseClassResource from "./CourseClassResources/CourseClassResource";
import CourseResourceTemplate from "./CourseClassResources/CourseResourceTemplate";

class ListCourseClasses extends Component {
  state = {
    addResourceModal: false,
    courceClassId: "",
    resourceModal: false,
    selectedResources: "",
    resourceHeader: "",
    selectedClassId: "",
    assignmentModal: false,
    assignmenDropboxModal: false,
  };

  //Add Resource Modal
  handleAddResourceModal = (id) => {
    this.setState({
      addResourceModal: !this.state.addResourceModal,
      courceClassId: id,
    });
  };

  toggleAddResourceModal = () => {
    this.setState({
      addResourceModal: !this.state.addResourceModal,
      courceClassId: "",
    });
  };

  //ResourceModal
  handleResourceModal = (data) => {
    this.setState({
      resourceModal: !this.state.resourceModal,
      selectedResources: data.courseClassResources,
      resourceHeader: `${data.activityType} : ${data.activityName} - Resources`,
    });
  };

  toggleResourceModal = () => {
    this.setState({
      resourceModal: !this.state.resourceModal,
      selectedResources: "",
    });
  };

  //Assignment
  handleAddAssignment = (id) => {
    this.setState({
      selectedClassId: id,
      assignmentModal: !this.state.assignmentModal,
    });
  };

  toggleAssignmentModal = () => {
    this.setState({ assignmentModal: !this.state.assignmentModal });
  };

  handleAssignmentDropboxModal = (id) => {
    this.setState({
      selectedClassId: id,
      assignmenDropboxModal: !this.state.assignmenDropboxModal,
    });
  };

  toggleAssignmentDropboxModal = () => {
    this.setState({ assignmenDropboxModal: !this.state.assignmenDropboxModal });
  };

  render() {
    return (
      <div
        className="card mb-2 p-2 tt-lms"
        style={
          this.props.detailView || this.props.courseWidget
            ? { maxHeight: "77vh", height: "auto", overflow: "auto" }
            : checkRolePermissions("can_create_program_class", "activity")
            ? { height: "36vh", overflow: "auto" }
            : { height: "auto" }
        }
      >
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th width="220px">Date</th>
              <th width="150px">Time</th>
              <th>Course</th>
              <th>Venue</th>
              <th>Activity</th>
              <th width="120px">Resources</th>
              {checkRolePermissions(
                "can_add_resource_to_program_class",
                "activity"
              ) ||
              checkRolePermissions(
                "can_add_assignment_to_program_class",
                "activity"
              ) ? (
                <th width="150px">Options</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {this.props.courseClasses.length > 0 ? (
              this.props.courseClasses.map((cclass, idx) => {
                return (
                  <tr key={idx}>
                    <td>{moment(cclass.date).format("dddd, MMMM Do YYYY")}</td>
                    <td>
                      {moment(cclass.startTime, "HH:mm:ss").format("hh:mm A")} -{" "}
                      {moment(cclass.endTime, "HH:mm:ss").format("hh:mm A")}
                    </td>
                    <td className="text-center">
                      {cclass.topicName ? cclass.topicName : "-"}
                    </td>
                    <td>{`Room: ${cclass.name}(${cclass.number}) - Building: ${
                      cclass.buildingName
                    }${
                      cclass.buildingSectionAliasName !== ""
                        ? `, ${cclass.buildingSectionAliasName}`
                        : ""
                    }${
                      cclass.buildingSectionName !== null
                        ? ` ${cclass.buildingSectionName}`
                        : ""
                    },Floor:  ${cclass.floorName}`}</td>
                    <td style={{ paddingTop: "8px !important" }}>
                      {cclass.activityType === "Lect" ? (
                        <>
                          <span className="tt-lectureChip">
                            {cclass.activityType}:{" "}
                          </span>
                          {cclass.activityName}
                        </>
                      ) : (
                        <>
                          <span className="tt-practicalChip">
                            {cclass.activityType}:{" "}
                          </span>
                          {cclass.activityName}
                        </>
                      )}
                      {cclass.peoples.length > 0 ? (
                        <>
                          <br></br>
                          <strong>Faculty: </strong>
                          {cclass.peoples.map((people, idx) => {
                            return (
                              <p>
                                {people.name}
                                {idx === cclass.peoples.length - 1 ? "" : ", "}
                              </p>
                            );
                          })}
                        </>
                      ) : null}
                    </td>
                    <td className="text-center">
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth"
                        onClick={() => this.handleResourceModal(cclass)}
                      >
                        Resources
                      </button>
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                        onClick={() =>
                          this.handleAssignmentDropboxModal(cclass.id)
                        }
                      >
                        Assignment Dropbox
                      </button>
                    </td>
                    {checkRolePermissions(
                      "can_add_resource_to_program_class",
                      "activity"
                    ) ||
                    checkRolePermissions(
                      "can_add_assignment_to_program_class",
                      "activity"
                    ) ? (
                      <td className="text-center">
                        {this.props.detailView ? null : (
                          <>
                            {checkRolePermissions(
                              "can_add_resource_to_program_class",
                              "activity"
                            ) ? (
                              <button
                                className="tt-button tt-button-primary mb-1 tt-button-fullWidth"
                                onClick={() =>
                                  this.handleAddResourceModal(cclass.id)
                                }
                              >
                                Add Resources
                              </button>
                            ) : null}
                            {checkRolePermissions(
                              "can_add_assignment_to_program_class",
                              "activity"
                            ) ? (
                              userRole === userRoleCode.roleStudent ? null : (
                                <button
                                  className="tt-button tt-button-primary mb-1 tt-button-fullWidth"
                                  onClick={() =>
                                    this.handleAddAssignment(cclass.id)
                                  }
                                >
                                  Add Assignment
                                </button>
                              )
                            ) : null}
                          </>
                        )}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="text-center"
                  colSpan={
                    checkRolePermissions(
                      "can_add_resource_to_program_class",
                      "activity"
                    ) ||
                    checkRolePermissions(
                      "can_add_assignment_to_program_class",
                      "activity"
                    )
                      ? 7
                      : 6
                  }
                >
                  {this.props.courseClassSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading Data ...
                    </>
                  ) : (
                    "No classes available"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemsCount={this.props.courseClassTotalRecords}
            pageSize={this.props.courseClassRowCountLimit}
            currentPage={this.props.courseClassCurrentPage}
            onPageChange={this.props.handlePageChange}
            nextPage={this.props.handleNextPage}
            previousPage={this.props.handlePreviousPage}
          ></Pagination>
        </div>
        <ModalWindow
          modal={this.state.addResourceModal}
          toggleModal={this.toggleAddResourceModal}
          size="lg"
          modalHeader="Cource Class Resources"
          modalBody={
            <CourseClassResource
              courceClassId={this.state.courceClassId}
              getCourseClasses={this.props.getCourseClasses}
              toggleAddResourceModal={this.toggleAddResourceModal}
            ></CourseClassResource>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.resourceModal}
          toggleModal={this.toggleResourceModal}
          fullWidth={true}
          modalHeader={this.state.resourceHeader}
          modalBody={
            <CourseResourceTemplate
              selectedResources={this.state.selectedResources}
            ></CourseResourceTemplate>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.assignmentModal}
          toggleModal={this.toggleAssignmentModal}
          modalHeader="Add Assignment"
          modalBody={
            <CourseAssignment
              toggleAssignmentModal={this.toggleAssignmentModal}
              selectedClassId={this.state.selectedClassId}
            ></CourseAssignment>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.assignmenDropboxModal}
          toggleModal={this.toggleAssignmentDropboxModal}
          modalHeader="Assignment Dropbox"
          fullWidth={true}
          modalBody={
            <AssignmentDropbox
              selectedClassId={this.state.selectedClassId}
            ></AssignmentDropbox>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default ListCourseClasses;
