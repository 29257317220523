import React, { Component } from "react";
import AssignedStudentHeader from "../SchoolGrouping/Classroom/AssignedStudentHeader";

class DisplayStudentMemberList extends Component {
  state = {
    termAlias:
      localStorage.getItem("classroom") === null
        ? "Section"
        : localStorage.getItem("classroom"), //get from localStorage
  };
  render() {
    return (
      <>
        <AssignedStudentHeader
          yearLevels={this.props.yearLevels}
          handleYearLevelChange={this.props.handleYearLevelChange}
          selectYearLevel={this.props.selectYearLevel}
          selectClassroom={this.props.selectClassroom}
          classroomList={this.props.classroomList}
          handleClassroomChange={this.props.handleClassroomChange}
        ></AssignedStudentHeader>
        {this.props.assignedStudentsList ? (
          <table className="table table-bordered text-center table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={this.props.allStudentChecked}
                    onChange={(e) => this.props.handleStudentCheckbox(e, "all")}
                  ></input>
                </th>
                <th>Student's name</th>
              </tr>
            </thead>
            <tbody>
              {this.props.assignedStudentsList.length > 0 ? (
                this.props.assignedStudentsList.map((stu, idx) => {
                  return (
                    <tr key={idx}>
                      {stu.people !== null ? (
                        <>
                          <td>
                            <input
                              type="checkbox"
                              checked={stu.checked}
                              onChange={(e) =>
                                this.props.handleStudentCheckbox(e, idx)
                              }
                            ></input>
                          </td>
                          <td>{stu.people.name}</td>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={2}>
                    No students available in this {this.state.termAlias}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default DisplayStudentMemberList;
