import React, { Component } from "react";
import { Spinner } from "react-mdl";
import { connect } from "react-redux";
import { getCourseList } from "../../ducks/LearningManagementSystemDucks";
import Pagination from "../UI/Pagination";
import ModalWindow from "../UI/ModalWindow";
import LmsModalBody from "./lmsModalBody";
import "../../assets/scss/lms.scss";
import PhaseSetting from "./PhaseSetting/PhaseSetting";
import TermSetting from "./TermSetting/TermSetting";
import CourseSubjectSetting from "./SubjectSetting/CourseSubjectSetting";
import {
  checkRolePermissions,
  draggable,
  regEx,
  URL,
} from "../../utils/Constants";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import AddStudentModal from "./AddStudentModal";

class ListLMS extends Component {
  state = {
    courseList: [],
    courseSpinner: "",
    courseTotalRecords: 0,
    courseOffset: 0,
    courseRowCountLimit: 15,
    courseCurrentPage: 1,
    detailModal: false,
    selectedList: "",
    phaseModal: false,
    termModal: false,
    subjectModal: false,
    name: "",
    abbreviation: "",
    duration: "",
    courseTermFormatCode: "",
    id: "",
    courseTermFormatList: [],
    description: "",
    admissionIds: [],
    selectedAdmissions: [],
    studentListModal: false,
    selectedCourseId: "",
    permissionShowProgramStudents: false,
    permissionCanEditProgram: false,
    permissionCanDeleteProgram: false,
  };

  componentDidMount() {
    this.props.getCourseList(
      this.state.courseOffset,
      this.state.courseRowCountLimit
    );
    this.getCourseTermFormat();
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionShowProgramStudents: checkRolePermissions(
        "show_program_students",
        "activity"
      ),
      permissionCanEditProgram: checkRolePermissions(
        "can_edit_program",
        "activity"
      ),
      permissionCanDeleteProgram: checkRolePermissions(
        "can_delete_program",
        "activity"
      ),
    });
  };

  getCourseTermFormat = () => {
    axiosPost(URL.getCourseTermFormat, {}, (response) => {
      if (response.status === 200) {
        this.setState({ courseTermFormatList: response.data.data });
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      courseList: nextProps.courseList,
      courseSpinner: nextProps.courseSpinner,
      courseTotalRecords: nextProps.courseTotalRecords,
    };
  }

  //Pagination

  handlePreviousPage = () => {
    if (this.state.courseCurrentPage !== 1) {
      this.reRenderCourseList(this.state.courseCurrentPage - 1);
      this.setState((prevState) => ({
        courseCurrentPage: prevState.courseCurrentPage - 1,
      }));
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.courseCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.courseTotalRecords / this.state.courseRowCountLimit)
    )
      return;
    else {
      this.reRenderCourseList(nextPage);
      this.setState({ courseCurrentPage: nextPage });
    }
  };

  handlePageChange = (page) => {
    this.reRenderCourseList(page);
    this.setState({ courseCurrentPage: page });
  };

  reRenderCourseList = (page) => {
    let offset =
      page * parseInt(this.state.courseRowCountLimit) -
      parseInt(this.state.courseRowCountLimit);
    this.setState({ courseOffset: offset }, function () {
      this.props.getCourseList(offset, this.state.courseRowCountLimit);
    });
  };

  //Detail
  openModal = () => {
    return (
      <LmsModalBody
        selectedList={this.state.selectedList}
        togglePhaseDetail={this.togglePhaseDetail}
      ></LmsModalBody>
    );
  };

  togglePhaseDetail = (e, idx) => {
    let course = this.state.selectedList;
    if (course.coursePhases[idx].isOpen === true) {
      course.coursePhases.forEach((el) => {
        el.isOpen = false;
      });
    } else {
      course.coursePhases.forEach((el) => {
        el.isOpen = false;
      });
      course.coursePhases[idx].isOpen = true;
    }
    this.setState({ selectedList: course });
  };

  toggleModal = () => {
    this.setState({ detailModal: !this.state.detailModal, selectedList: "" });
  };

  toggleModalList = (list) => {
    this.setState({ detailModal: !this.state.detailModal, selectedList: list });
  };

  //Phase Setting

  phaseModalSetting = (list) => {
    this.setState({ phaseModal: !this.state.phaseModal, selectedList: list });
  };

  togglePhaseModal = () => {
    this.props.getCourseList(
      this.state.courseOffset,
      this.state.courseRowCountLimit
    );
    this.setState({ phaseModal: !this.state.phaseModal, selectedList: "" });
  };

  //Term Setting
  termModalSetting = (list) => {
    this.setState({ termModal: !this.state.termModal, selectedList: list });
  };

  toggleTermModal = () => {
    this.props.getCourseList(
      this.state.courseOffset,
      this.state.courseRowCountLimit
    );
    this.setState({ termModal: !this.state.termModal, selectedList: "" });
  };

  //Subject Setting
  subjectModalSetting = (list) => {
    this.setState({
      subjectModal: !this.state.subjectModal,
      selectedList: list,
    });
  };

  toggleSubjectModal = () => {
    this.props.getCourseList(
      this.state.courseOffset,
      this.state.courseRowCountLimit
    );
    this.setState({ subjectModal: !this.state.subjectModal, selectedList: "" });
  };

  //Update Course
  toggleEditConfig = (idx) => {
    let courseList = [...this.state.courseList];
    courseList.forEach((el) => {
      el.isEditable = false;
    });
    courseList[idx].isEditable = true;
    this.setState({
      courseList,
      id: this.state.courseList[idx].id,
      name: this.state.courseList[idx].name,
      abbreviation: this.state.courseList[idx].abbreviation,
      duration: this.state.courseList[idx].duration,
      courseTermFormatCode: this.state.courseList[idx].courseTermFormatCode,
      description: this.state.courseList[idx].description,
    });
  };

  cancelUpdate = (idx) => {
    let courseList = [...this.state.courseList];
    courseList[idx].isEditable = false;
    this.setState({
      courseList,
      id: "",
      name: "",
      abbreviation: "",
      duration: "",
      courseTermFormatCode: "",
      description: "",
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      if (!value.match(regEx)) {
        swal({
          title: "Warning",
          text: "Character not allowed",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  updateCourse = () => {
    let data = {
      id: this.state.id,
      name: this.state.name,
      abbreviation: this.state.abbreviation,
      description: this.state.description,
      duration: this.state.duration,
      courseTermFormatCode: this.state.courseTermFormatCode,
    };
    axiosPost(URL.updateCourse, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Course Successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.props.getCourseList(
          this.state.courseOffset,
          this.state.courseRowCountLimit
        );
      }
    });
  };

  //Delete course

  handleDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deleteCourse);
  };

  deleteCourse = (datum) => {
    let data = { id: datum.id };
    axiosPost(URL.deleteCourse, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Course Successfully deleted",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.props.getCourseList(
          this.state.courseOffset,
          this.state.courseRowCountLimit
        );
      }
    });
  };

  //Student Modal
  toggleStudentModal = (list) => {
    let data = {
      courseId: list.id,
    };
    axiosPost(URL.getProgramAdmissionList, data, (res) => {
      if (res.status === 200) {
        let selectedAdmissions = res.data.data;
        let admissionIds = [];
        if (selectedAdmissions.length > 0) {
          selectedAdmissions.forEach((el) => {
            admissionIds.push(el.id);
          });
        }
        this.setState({ selectedAdmissions, admissionIds }, function () {
          this.setState({
            studentListModal: !this.state.studentListModal,
            selectedCourseId: list.id,
          });
        });
      }
    });
  };

  toggleStudentListModal = () => {
    this.setState({
      selectedAdmissions: [],
      admissionIds: [],
      studentListModal: !this.state.studentListModal,
    });
  };

  assignAdmissionIds = (ids) => {
    let data = {
      id: this.state.selectedCourseId,
      admissionIds: ids,
    };
    axiosPost(URL.assignUnassignCourseAdmission, data, (res) => {
      if (res.status === 200) {
        swal({ title: "Success", text: res.data.message });
        this.toggleStudentListModal();
      }
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "80.5vh" }}>
        <div className="tt-group-header">List Programs</div>
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Program Name</th>
              <th width="150px">Abbreviation</th>
              <th width="180px">Program Duration</th>
              <th>Terms</th>
              <th width="150px">Settings</th>
              <th width="90px">Options</th>
            </tr>
          </thead>
          <tbody>
            {this.state.courseList.length > 0 ? (
              this.state.courseList.map((list, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {list.isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                      ) : (
                        list.name
                      )}
                    </td>
                    <td>
                      {list.isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          name="abbreviation"
                          value={this.state.abbreviation}
                          onChange={this.handleChange}
                        ></input>
                      ) : (
                        list.abbreviation
                      )}
                    </td>
                    <td>
                      {list.isEditable ? (
                        <input
                          type="number"
                          className="form-control"
                          name="duration"
                          value={this.state.duration}
                          onChange={this.handleChange}
                        ></input>
                      ) : (
                        list.duration + " months"
                      )}
                    </td>
                    <td>
                      {list.isEditable ? (
                        <select
                          className="form-control"
                          name="courseTermFormatCode"
                          value={this.state.courseTermFormatCode}
                          onChange={this.handleChange}
                        >
                          {this.state.courseTermFormatList.map((list, idx) => {
                            return (
                              <option key={idx} value={list.code}>
                                {list.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        list.termName
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth"
                        onClick={() => this.phaseModalSetting(list)}
                      >
                        Phase Settings
                      </button>
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                        onClick={() => this.termModalSetting(list)}
                      >
                        Term Settings
                      </button>
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                        onClick={() => this.subjectModalSetting(list)}
                      >
                        Subject Settings
                      </button>
                    </td>
                    <td className="text-center">
                      {list.isEditable ? (
                        <>
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={this.updateCourse}
                          >
                            Save
                          </button>
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={() => this.cancelUpdate(idx)}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          {this.state.permissionShowProgramStudents ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() => this.toggleStudentModal(list)}
                            >
                              Students
                            </button>
                          ) : null}
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                            onClick={() => this.toggleModalList(list)}
                          >
                            Details
                          </button>
                          {this.state.permissionCanEditProgram ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                              onClick={() => this.toggleEditConfig(idx)}
                            >
                              Edit
                            </button>
                          ) : null}
                          {this.state.permissionCanDeleteProgram ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                              onClick={() => this.handleDelete(list.id)}
                            >
                              Delete
                            </button>
                          ) : null}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan={6}>
                  {this.state.courseSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading Programs ...
                    </>
                  ) : (
                    "No programs found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemsCount={this.state.courseTotalRecords}
            pageSize={this.state.courseRowCountLimit}
            currentPage={this.state.courseCurrentPage}
            onPageChange={this.handlePageChange}
            nextPage={this.handleNextPage}
            previousPage={this.handlePreviousPage}
          ></Pagination>
        </div>
        <ModalWindow
          modal={this.state.detailModal}
          toggleModal={this.toggleModal}
          size="xl"
          modalHeader={this.state.selectedList.name}
          modalBody={this.openModal()}
        ></ModalWindow>
        <ModalWindow
          modal={this.state.phaseModal}
          toggleModal={this.togglePhaseModal}
          fullWidth={true}
          modalHeader={this.state.selectedList.name + " - Phase Setting"}
          modalBody={
            <PhaseSetting selectedList={this.state.selectedList}></PhaseSetting>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.termModal}
          toggleModal={this.toggleTermModal}
          fullWidth={true}
          modalHeader={this.state.selectedList.name + " - Term Setting"}
          modalBody={
            <TermSetting selectedList={this.state.selectedList}></TermSetting>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.subjectModal}
          toggleModal={this.toggleSubjectModal}
          fullWidth={true}
          modalHeader={this.state.selectedList.name + " - Subject Setting"}
          modalBody={
            <CourseSubjectSetting
              selectedList={this.state.selectedList}
            ></CourseSubjectSetting>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.studentListModal}
          toggleModal={this.toggleStudentListModal}
          fullWidth={true}
          modalHeader="Assigned Students"
          modalBody={
            <AddStudentModal
              admissionIds={this.state.admissionIds}
              assignAdmissionIds={this.assignAdmissionIds}
            ></AddStudentModal>
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  courseList: state.lms.courseList,
  courseSpinner: state.lms.courseSpinner,
  courseTotalRecords: state.lms.courseTotalRecords,
});

const mapActionsToProps = {
  getCourseList,
};

export default connect(mapStateToProps, mapActionsToProps)(ListLMS);
