import React from "react";
import BillStudentBillConfig from "./BillStudentBillConfig";
import BillStudentList from "./BillStudentList";

function StudentAccountConfig(props) {
  return (
    <div className="row">
      <div className="col-md-4" style={{ borderRight: "1px solid #ccc" }}>
        <BillStudentList
          allChecked={props.allChecked}
          allInventoriesChecked={props.allInventoriesChecked}
          handleActiveStatus={props.handleActiveStatus}
          handleChange={props.handleChange}
          handleStudentCheckbox={props.handleStudentCheckbox}
          students={props.students}
        />
      </div>
      <div className="col-md-8">
        <BillStudentBillConfig
          fromDraft={props.fromDraft}
          configType={props.configType}
          handleGeneralMonthChange={props.handleGeneralMonthChange}
          months={props.months}
          selectedMonths={props.selectedMonths}
          addCategory={props.addCategory}
          addNew={props.addNew}
          cancelNewCategory={props.cancelNewCategory}
          disableAmount={props.disableAmount}
          generalAmount={props.generalAmount}
          generalFee={props.generalFee}
          generalFeeCategory={props.generalFeeCategory}
          generalNewCategoryName={props.generalNewCategoryName}
          handleCategoryCheckboxChange={props.handleCategoryCheckboxChange}
          handleChange={props.handleChange}
          handleFeeAmountChange={props.handleFeeAmountChange}
          handleInventoryModal={props.handleInventoryModal}
          handleRemoveCategory={props.handleRemoveCategory}
          selectedFeeCategory={props.selectedFeeCategory}
        />
      </div>
    </div>
  );
}

export default StudentAccountConfig;
