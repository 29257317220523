import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { handleError } from "../../utils/Utils";

export default class CreateNewFlashCard extends Component {
  render() {
    let flashCards = this.props.flashCards;

    return (
      <div className="container-fluid">
        <div
          className={
            "row " +
            (this.props.addNew
              ? "tt-flashCardOuterBoxNew"
              : "mt-2 tt-flashCardOuterBox")
          }
        >
          {flashCards.length > 0
            ? flashCards.map((flashCard, idx) => {
                return (
                  <div className="col-md-auto text-center" key={idx}>
                    <div className="tt-flashCardBox button-bg-color">
                      <div className="text-center tt-input">
                        <strong>
                          Flash Card
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="float-right mt-1"
                            onClick={(e) => this.props.removeFlashCard(e, idx)}
                          ></FontAwesomeIcon>
                        </strong>
                      </div>
                      <div className="mt-2 tt-flashCard-img-box text-center">
                        {flashCard.imagePreview ? (
                          <>
                            <img
                              src={flashCard.imagePreview}
                              alt="flash card"
                              onError={(e) => handleError(e)}
                              className="tt-img"
                            ></img>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="tt-imgDeleteFlashCard"
                              onClick={(e) => this.props.removeImage(e, idx)}
                            ></FontAwesomeIcon>
                          </>
                        ) : (
                          <>
                            <div className="row h-100 justify-content-center align-items-center">
                              <div className="col-12">
                                <input
                                  name="image"
                                  id={"fileImage" + idx}
                                  type="file"
                                  ref={(ref) => {
                                    this[`fileImage + ${idx}`] = ref;
                                  }}
                                  onChange={(e) =>
                                    this.props.handleImageChange(e, idx)
                                  }
                                  style={{ display: "none" }}
                                  accept="image/x-png,image/gif,image/jpeg"
                                />
                                <button
                                  className="tt-button tt-button-primary"
                                  onClick={() => {
                                    this[`fileImage + ${idx}`].click();
                                  }}
                                >
                                  Attach File
                                </button>

                                <br />
                                <small>
                                  Accepts image/x-png, image/gif, image/jpeg
                                </small>
                              </div>
                            </div>
                          </>
                        )}

                        {/* </div> */}
                      </div>
                      <div className="mt-2">
                        <textarea
                          name="question"
                          className="form-control"
                          value={flashCard.question}
                          placeholder="Enter Front text"
                          onChange={(e) =>
                            this.props.handleFlashCardChange(e, idx)
                          }
                        />
                      </div>
                      <div className="mt-2">
                        <textarea
                          name="answer"
                          className="form-control"
                          value={flashCard.answer}
                          placeholder="Enter Back text"
                          onChange={(e) =>
                            this.props.handleFlashCardChange(e, idx)
                          }
                        />
                      </div>
                      <div className="mt-2">
                        <textarea
                          name="hint"
                          className="form-control"
                          value={flashCard.hint}
                          onChange={(e) =>
                            this.props.handleFlashCardChange(e, idx)
                          }
                          placeholder="Enter Hint"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          <div className="col-md-auto text-center">
            <div
              id="addFlashCard"
              className="tt-flashCardBox button-bg-color"
              onClick={this.props.addFlashCard}
            >
              <div className="row h-100 justify-content-center align-items-center text-center">
                <div className="col-12">
                  <FontAwesomeIcon icon={faPlus} size="2x"></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
