import { axiosPost } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";
import { displayErrorAlert } from "../utils/Utils";

const admissionTableFieldGroupsLOAD =
  "admission/admission-form/admissionTableFieldGroups/LOAD";
const studentContactTableFieldGroupsLOAD =
  "admission/admission-form/studentContactTableFieldGroups/LOAD";
const dynamicFieldLOAD = "admission/admission-form/dynamicField/LOAD";
const admissionListLOAD = "admission/admission-list/LOAD";
const admissionListPaginationLOAD = "admissionList/pagination/LOAD";
export default function reducer(
  state = {
    admissionTableFieldGroups: null,
    studentContactTableFieldGroups: null,
    dynamicField: null,
    admissionList: [],

    totalRecords: 0,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case admissionTableFieldGroupsLOAD:
      newState.admissionTableFieldGroups = action.admissionTableFieldGroups;
      return newState;
    case studentContactTableFieldGroupsLOAD:
      newState.studentContactTableFieldGroups =
        action.studentContactTableFieldGroups;
      return newState;
    case dynamicFieldLOAD:
      newState.dynamicField = action.dynamicField;
      return newState;
    case admissionListLOAD:
      newState.admissionList = action.admissionList;
      return newState;
    case admissionListPaginationLOAD:
      newState.admissionList = action.admissionList;
      newState.totalRecords = action.totalRecords;
      return newState;
    default:
      return newState;
  }
}

//action creators
export function loadAdmissionTableFieldGroups(admissionTableFieldGroups) {
  return { type: admissionTableFieldGroupsLOAD, admissionTableFieldGroups };
}
export function loadStudentContactTableFieldGroups(
  studentContactTableFieldGroups
) {
  return {
    type: studentContactTableFieldGroupsLOAD,
    studentContactTableFieldGroups,
  };
}
export function loadDynamicField(dynamicField) {
  return { type: dynamicFieldLOAD, dynamicField };
}
export function loadAdmissionList(admissionList) {
  return {
    type: admissionListLOAD,
    admissionList,
  };
}

export function loadAdmissionListPagination(admissionList, totalRecords) {
  return {
    type: admissionListPaginationLOAD,
    admissionList,
    totalRecords,
  };
}
//disptach actionwith function
export function getAdmissionFormConfig(crudName, peopleId) {
  return (dispatch) => {
    dispatch(loadAdmissionTableFieldGroups([]));
    dispatch(loadStudentContactTableFieldGroups([]));
    dispatch(loadDynamicField([]));
    axiosPost(
      URL.getAdmissionConfigurations,
      {
        crudName,
        peopleId,
      },
      (response) => {
        if (response.status === 200) {
          let admissionTableFieldGroups =
              response.data.data.admissionTableFieldGroups,
            studentContactTableFieldGroups =
              response.data.data.studentContactTableFieldGroups,
            dynamicField = JSON.parse(
              JSON.stringify({ ...studentContactTableFieldGroups[0] })
            );
          admissionTableFieldGroups.forEach((element) => {
            element.customTableFields.forEach((field) => {
              // field.value = "";
              field.year = null;
              field.month = null;
              field.day = null;
              field.gender = null;
              //below code added for custom table
              field.rows = [];
              field.rows.push({
                rowId: 0,
              });
              if (field.fieldTypeCode === "multi-checkbox") {
                if (field.customTableFieldDatas) {
                  field.customTableFieldDatas.forEach((value) => {
                    value.isChecked = false;
                  });
                }
              }
              return field.customFieldTableColumns?.forEach((col) => {
                col.rows = [];
                col.rows.push({
                  rowId: 0,
                });
              });
            });
          });
          studentContactTableFieldGroups.forEach((element) => {
            element["Is Legal Guardian"] = false;
            element["Relationship"] = "";
            // element["emailAddress"] = null;
            element.customTableFields.forEach((field) => {
              // field.value = "";
              field.year = null;
              field.month = null;
              field.day = null;
              field.gender = null;
              //below code added for custom table
              field.rows = [];
              field.rows.push({
                rowId: 0,
              });

              return field.customFieldTableColumns?.forEach((col) => {
                col.rows = [];
                col.rows.push({
                  rowId: 0,
                });
              });
            });
          });
          dispatch(loadAdmissionTableFieldGroups(admissionTableFieldGroups));
          dispatch(
            loadStudentContactTableFieldGroups(studentContactTableFieldGroups)
          );
          dynamicField.customTableFields.forEach((field) => {
            if (field.fieldTypeCode === "pre-peop") {
              if (field.customTableFieldValues) {
                field.customTableFieldValues.forEach((value) => {
                  value.value = "";
                });
              }
            }
            if (field.fieldTypeCode === "pre-addr") {
              if (field.customTableFieldValues) {
                field.customTableFieldValues.forEach((value) => {
                  value.value = "";
                });
              }
            }
          });
          dispatch(loadDynamicField(dynamicField));
        }
      }
    );
  };
}

export function getAdmissionList(id) {
  return (dispatch) => {
    axiosPost(URL.admissionList, { admissionStatusId: id }, (response) => {
      if (response.status === 200) {
        const admissionList = response.data.data;
        admissionList.forEach((el) => {
          el.status = false;
        });
        dispatch(loadAdmissionList(admissionList));
      }
    });
  };
}

export function getAdmissionListPagination(id, offset, rowCountLimit) {
  return (dispatch) => {
    let data = {
      admissionStatusId: id,
      offset: offset,
      rowCountLimit: rowCountLimit,
    };
    axiosPost(
      URL.admissionList,
      data,
      (response) => {
        if (response.status === 200) {
          let totalRecords = response.data.totalRecordsCount;
          const admissionList = response.data.data;
          admissionList.forEach((el) => {
            el.status = false;
          });
          dispatch(loadAdmissionListPagination(admissionList, totalRecords));
        }
      },
      (error) => {
        displayErrorAlert(error);
      }
    );
  };
}
