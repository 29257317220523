import React from "react";
import { Spinner } from "react-mdl";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { cloneDeep } from "lodash";

class FeeConfiguration extends React.Component {
  state = {
    yearLevels: null,
    categories: null,
    categoriesList: null,
    yearlevelId: null,
    fetchingConfig: false,
    configType: "monthly",
  };

  componentDidMount() {
    this.getYearLevels();
    this.getCategories({ isInventory: false });
  }

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          yearLevels: response.data.data,
        });
      }
    });
  };

  getCategories = (param) => {
    axiosPost(URL.getAllFeeCategory, param, (response) => {
      if (response.status === 200) {
        this.setState({
          categories: response.data.data,
          categoriesList: response.data.data,
        });
      }
    });
  };

  handleChange = (e, idx) => {
    const { value, name } = e.target;
    if (name === "yearlevelId") {
      this.setState({ fetchingConfig: true });
      this.state.configType &&
        this.getFeeCategoryConfiguration(value, this.state.configType);
    }
    if (name === "configType") {
      if (this.state.yearlevelId) {
        this.setState({ fetchingConfig: true });
        this.getFeeCategoryConfiguration(this.state.yearlevelId, value);
      }
      if (value === "general") {
        this.setState({ yearlevelId: "" });
        this.getFeeCategoryConfiguration(null, value);
      }
      if (value !== "general" && this.state.yearlevelId === "") {
        swal("Info", "Select Year Level Id also");
      }
    }
    this.setState({ [name]: value });
  };

  getFeeCategoryConfiguration = (yl, ct) => {
    const param = {
      yearlevelId: yl,
      type: ct,
    };
    axiosPost(
      URL.selectFeeCategoryConfiguration,
      param,
      (response) => {
        if (response.status === 200) {
          const config = response.data.data;
          let categoriesList = cloneDeep(this.state.categoriesList);
          categoriesList.forEach((cat) => {
            const findConfig = config.find((c) => c.feeCategoryId === cat.id);
            if (findConfig) {
              cat.checked = true;
              cat.amount = findConfig.amount;
            }
          });
          this.setState({ fetchingConfig: false, categories: categoriesList });
        } else {
          this.setState({ fetchingConfig: false });
        }
      },
      (err) => {}
    );
  };

  handleCategoryDataChange = (e, idx) => {
    const { name, value, checked } = e.target;
    let categories = [...this.state.categories];
    if (name === "feeCategoryId") {
      categories[idx].checked = checked;
    } else {
      categories[idx][name] = value;
    }
    this.setState({ categories });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const selectedYearLevel = this.state.yearLevels.find(
      (y) => y.id === parseInt(this.state.yearlevelId)
    );
    if (selectedYearLevel || this.state.configType === "general") {
      let param = [];
      let categories = [...this.state.categories];
      const checkedCategories = categories.filter((c) => c.checked);
      for (const cat of checkedCategories) {
        if (cat.amount && cat.amount !== "") {
          param.push({
            educationLevelId: this.state.yearlevelId
              ? selectedYearLevel.educationLevelId
              : null,
            yearlevelId: this.state.yearlevelId,
            feeCategoryId: cat.id,
            amount: cat.amount,
            type: this.state.configType,
          });
        } else {
          swal("Warning", "Missing amount.", "");
          return;
        }
      }
      if (checkedCategories.length > 0) {
        axiosPost(
          URL.insertFeeCategoryConfiguration,
          param,
          (response) => {
            if (response.status === 200) {
              swal("Success", "Configuration saved.", "");
              this.state.configType === "general"
                ? this.getFeeCategoryConfiguration(null, this.state.configType)
                : this.getFeeCategoryConfiguration(
                    this.state.yearlevelId,
                    this.state.configType
                  );
            } else {
              swal("Error", "Configuration could not be saved.", "");
            }
          },
          (err) => {
            swal("Error", "Configuration could not be saved.", "");
          }
        );
      } else {
        swal("Warning", "Select some category first.", "");
      }
    } else {
      swal("Warning", "Select a year level first.", "");
    }
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">Category Configuration</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <strong>Year Level</strong>
              <select
                name="yearlevelId"
                onChange={this.handleChange}
                className="form-control"
                value={this.state.yearlevelId}
                disabled={this.state.configType === "general"}
              >
                <option value="" selected disabled>
                  Choose year level
                </option>
                {this.state.yearLevels?.map((y) => (
                  <option value={y.id}>{y.name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <strong>Fee Type</strong>
              <select
                name="configType"
                onChange={this.handleChange}
                className="form-control"
                value={this.state.configType}
              >
                <option value="" selected disabled>
                  Choose year level
                </option>
                <option value={"monthly"}>Monthly</option>
                <option value={"annually"}>Annual</option>
                <option value={"general"}>General</option>
              </select>
            </div>
          </div>
          {this.state.fetchingConfig ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <div className="row mt-2">
              <div className="col-md-12">
                <table className="table text-center table-bordered table-striped mt-2">
                  <thead className="tt-group-header">
                    <tr>
                      <th width="80px">SN</th>
                      <th width="100px">Choose</th>
                      <th>Category</th>
                      <th width="200px">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.categories?.map((cat, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        <td>
                          <input
                            type="checkbox"
                            name="feeCategoryId"
                            checked={cat?.checked}
                            onChange={(e) =>
                              this.handleCategoryDataChange(e, idx)
                            }
                          />
                        </td>
                        <td>{cat.category}</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            name="amount"
                            className="form-control"
                            value={cat?.amount}
                            onChange={(e) =>
                              this.handleCategoryDataChange(e, idx)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <button
                className="tt-button tt-button-primary float-right"
                onClick={this.handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeeConfiguration;
