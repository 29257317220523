import React, { Component } from "react";
import "../../assets/scss/loginPage.scss";
import "../../assets/scss/ResponsiveScss/responsiveLoginPage.scss";
import loginImageMobile from "../../assets/images/logo/logo2.png";
import logo3 from "../../assets/images/logo/logo3.png";
import appStoreImg from "../../assets/images/appStore.png";
import playStoreImg from "../../assets/images/playStore.png";
import techartLogo from "../../assets/images/logo/logoYellow.png";
import iigLogo from "../../assets/images/logo/IIG.png";
import loginBg1 from "../../assets/images/silverBg3.jpg";
import { withRouter } from "react-router";
import { axiosPost } from "../../utils/AxiosApi";
import { loginUrl, URL, reactServer } from "../../utils/Constants";
import history from "../../utils/History";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ModalWindow from "../UI/ModalWindow";
import swal from "sweetalert";
import BG1 from "../../assets/images/BG1.jpg";
import BG2 from "../../assets/images/BG2.jpg";
import {
  draggable,
  username,
  password,
  rememberMe,
} from "../../utils/Constants";
import CryptoJS from "crypto-js";
import { displayErrorMessage, displaySuccessAlert } from "../../utils/Utils";

import { connect } from "react-redux";
import { compose } from "redux";
import { setPermissions } from "./../../ducks/LogInDucks";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

class LoginPage extends Component {
  state = {
    backPictures: [BG1, BG2],
    forgotUsername: "",
    forgotUserType: "",
    inputType: "password",
    logoSpinner: true,
    modal: false,
    password:
      rememberMe === "true" && localStorage.getItem("password")
        ? CryptoJS.AES.decrypt(password, "saurav_imagine_sys").toString(
            CryptoJS.enc.Utf8
          )
        : "",
    rememberMe: rememberMe === "true" ? true : false,
    schoolImage: null,
    schoolName: null,
    showSpinner: false,
    submitSpinner: false,
    username:
      rememberMe === "true" && localStorage.getItem("username")
        ? CryptoJS.AES.decrypt(username, "saurav_imagine_sys").toString(
            CryptoJS.enc.Utf8
          )
        : "",
    userType: [],
  };

  componentDidMount() {
    document.title = "ImagineSys";
    this.getUserRoles();
    this.getSchoolInfo();
  }

  forgotModalBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-5">
            <strong>Username </strong>
          </div>
          <div className="col-md-7">
            <input
              name="forgotUsername"
              type="text"
              className="form-control"
              value={this.state.forgotUsername}
              placeholder="Type your username"
              onChange={this.handleDataChange}
            ></input>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-md-5">
            <strong>User Type</strong>
          </div>
          <div className="col-md-7">
            <select
              className="form-control"
              name="forgotUserType"
              value={this.state.forgotUserType}
              onChange={this.handleDataChange}
            >
              <option value="" disabled>
                Choose user type
              </option>
              {this.state.userType.map((type, idx) => {
                return (
                  <option key={idx} value={type.code}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-md-5">
            <strong>Reset Password Option</strong>
          </div>
          <div className="col-md-7">
            <div>
              <label className="tt-resetOption">Via Email</label>
              <input
                style={{ marginLeft: "10px" }}
                name="viaEmail"
                type="checkbox"
                checked={this.state?.viaEmail}
                onChange={(e) => this.handleCheckboxA(e)}
                className="tt-checkbox"
                // disabled={this.state?.viaAdmin}
              />
            </div>
            <div>
              <label className="tt-resetOption">Via Admin</label>
              <input
                style={{ marginLeft: "10px" }}
                name="viaAdmin"
                type="checkbox"
                checked={this.state?.viaAdmin}
                onChange={(e) => this.handleCheckboxB(e)}
                className="tt-checkbox"
                // disabled={this.state?.viaEmail}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  forgotModalFooter = () => {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.handleForgetPassword}
            >
              Submit
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.forgotPasswordModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  forgotPasswordModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  /**
   * @author Saurav Sitaula
   * js async function that gets year level id and classroom id using recruitment id after
   * recruiment id is received from people id
   */
  async getRecruitmentIdPromise(peopleId) {
    let promise = new Promise((resolve, reject) => {
      axiosPost(URL.getStaffList, { peopleId: peopleId }, (response) => {
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            resolve(response.data.data[0].id);
          }
        }
      });
    });
    let recruitmentId = await promise;
    recruitmentId &&
      axiosPost(
        URL.selectClassroom,
        { recruitmentId: recruitmentId },
        (response) => {
          if (response.status === 200) {
            localStorage.setItem("recruitmentId", recruitmentId);
            response.data.data[0] &&
              localStorage.setItem(
                "yearLevelId",
                response.data.data[0].yearLevelId
              );
            response.data.data[0] &&
              localStorage.setItem("classroomId", response.data.data[0].id);
          }
        }
      );
  }

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, "", (response) => {
      if (response.status === 200) {
        var data = response.data.data;
        if (data.length > 0) {
          this.setState({
            logoSpinner: false,
            schoolImage: data[0].imageName
              ? URL.imageSelectURL + data[0].imageName
              : null,
            schoolName: data[0].name,
          });
        }
      } else {
        this.setState({ logoSpinner: false });
      }
    });
  };

  getUserRoles = () => {
    axiosPost(URL.getUserRoleNoAuth, {}, (response) => {
      this.setState({ userType: response.data.data });
    });
  };

  handleCheckboxA = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked, viaAdmin: false });
  };

  handleCheckboxB = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked, viaEmail: false });
  };

  handleDataChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleForgetPassword = (e) => {
    e.preventDefault();
    let params = {
      username: this.state.forgotUsername,
      userRoleCode: this.state.forgotUserType,
      link: this.state?.viaAdmin ? "" : `${reactServer}/resetPassword/:userId`,
    };

    //url must be different for different password reset options for user

    let url = "";
    if (this.state.viaAdmin) {
      url = URL.forgetPassword;
    } else {
      url = URL.resetViaEmail;
    }

    this.setState(
      {
        submitSpinner: true,
      },
      () => {
        axiosPost(
          url,
          params,
          (response) => {
            this.setState({ submitSpinner: false });
            if (response.status === 200) {
              setTimeout(() => {
                history.push(`${process.env.PUBLIC_URL}/`);
              }, 5000);
              displaySuccessAlert(response);
              this.setState({
                modal: !this.state.modal,
                forgotUsername: "",
                forgotUserType: "",
              });
            }
          },
          (err) => {
            this.setState({ submitSpinner: false });
            displayErrorMessage(err);
          }
        );
      }
    );
  };

  handleInputTypeChange = () => {
    if (this.state.inputType === "password") {
      this.setState({ inputType: "text" });
    } else {
      this.setState({ inputType: "password" });
    }
  };

  handlelogin = (e) => {
    e.preventDefault();
    let params = {
      username: this.state.username.trim(),
      password: this.state.password,
    };

    if (this.state.username === "" && this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input username and password",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      //   swal("Error", "Please input username and password", "");
    } else if (this.state.username === "") {
      swal({
        title: "Error",
        text: "Please input your username",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else if (this.state.password === "") {
      swal({
        title: "Error",
        text: "Please input your password",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          loginUrl,
          params,
          (response) => {
            if (response.status === 200) {
              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("schoolSetup", response.data.schoolSetup);
              localStorage.setItem(
                "educationCommunityGroupName",
                response.data.educationCommunityGroupName
              );
              localStorage.setItem(
                "academicYears",
                response.data.academicYears
              );
              localStorage.setItem("refreshToken", response.data.refresh_token);

              localStorage.setItem("isFirstLogin", response.data.isFirstLogin);

              localStorage.setItem(
                "widgets",
                CryptoJS.AES.encrypt(
                  JSON.stringify(response.data.academicPermissionDto.widgets),
                  "saurav_imagine_sys"
                )
              );
              response.data.substituteTeacherRolePermissions =
                response.data.substituteTeacherRolePermissions || null;
              localStorage.setItem(
                "substituteTeacherRolePermissions",
                CryptoJS.AES.encrypt(
                  JSON.stringify(
                    response.data.substituteTeacherRolePermissions
                  ),
                  "saurav_imagine_sys"
                )
              );

              localStorage.setItem(
                "academicYearId",
                response.data.academicPermissionDto.academicYearId
              );
              localStorage.setItem(
                "subRoles",
                CryptoJS.AES.encrypt(
                  JSON.stringify(response.data.subRoles),
                  "saurav_imagine_sys"
                )
              );
              if (response.data.associatedUsers) {
                localStorage.setItem(
                  "associatedUsers",
                  CryptoJS.AES.encrypt(
                    JSON.stringify(response.data.associatedUsers),
                    "saurav_imagine_sys"
                  )
                );
              }

              localStorage.setItem(
                "role",
                CryptoJS.AES.encrypt(
                  response.data.userRoleCode,
                  "saurav_imagine_sys"
                ).toString()
              );
              localStorage.setItem("peopleId", response.data.peopleId);
              localStorage.setItem("profilePeopleId", response.data.peopleId);
              localStorage.setItem("familyId", response.data.familyId);
              localStorage.setItem("mainUserId", response.data.userId);
              //   localStorage.setItem(
              //     "isCoordinator",
              //     response.data.isCoordinator
              //   );
              //   localStorage.setItem(
              //     "isHomeRoomTeacher",
              //     response.data.isHomeRoomTeacher
              //   );
              if (this.state.rememberMe) {
                localStorage.setItem(
                  "username",
                  CryptoJS.AES.encrypt(
                    this.state.username,
                    "saurav_imagine_sys"
                  ).toString()
                );
                localStorage.setItem(
                  "password",
                  CryptoJS.AES.encrypt(
                    this.state.password,
                    "saurav_imagine_sys"
                  ).toString()
                );
              }
              //this.getRecruitmentIdPromise(response.data.peopleId);
              this.setState({ showSpinner: false });
              if (response.data.userRoleCode === "RL_STC") {
                history.push(
                  `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
                );
              } else {
                response.data.schoolSetup
                  ? response.data.academicYears
                    ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                    : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                  : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
              }
            }
          },
          (error) => {
            let errorResponse = error.response ? error.response.data : error;
            let errorTitle = errorResponse.error
              ? errorResponse.error === "Internal Server Error"
                ? "Unable to Login"
                : errorResponse.error
              : "Unable to Login";

            swal({
              title: errorTitle,
              text: errorResponse.message
                ? errorResponse.message
                : "Could not connect to the server",
            });

            // swal({
            //   title: "Login Error",
            //   text: failure.message,
            //   allowOutsideClick: false,
            //   closeOnClickOutside: false,
            // });
            this.setState({ showSpinner: false });
          }
        );
      });
    }
    draggable();
  };

  render() {
    const settings = {
      infinite: true,
      autoplaySpeed: 12000,
      speed: 900,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <>
        {this.state.submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Your request is being processed!!
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="tt-desktopVersion">
          <form onSubmit={(e) => this.handlelogin(e)}>
            <div className="tt-login" id="tt-login">
              <div id="overlay">
                <img src={loginBg1} className="img-fluid" />
              </div>
              {/* <div id="overlay"></div>
              <Slider {...settings}>
                {this.state.backPictures.map((backPic, idx) => {
                  return (
                    <div
                      key={idx}
                      className="tt-slider-content tt-loginBackground-slider"
                    >
                      <img alt="back-picture" src={backPic} />
                    </div>
                  );
                })}
              </Slider> */}
              <div className="loginBox">
                {this.state.showSpinner ? (
                  <div className="fullWindow-Spinner">
                    <div>
                      <Spinner color="white"></Spinner>
                    </div>
                    <div style={{ fontSize: "16px", marginTop: "15px" }}>
                      Please Wait. Logging In...
                    </div>
                  </div>
                ) : null}
                <div className="innerLoginBox">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4 px-0">
                        <div className="leftLogoBox">
                          <div className="newLogoDiv">
                            <img src={logo3} className="img-fluid" />
                          </div>
                          <div className="newAppLinkDiv">
                            <p>Also Available On:</p>
                            <div className="appLinkImages">
                              <div className="ml-0">
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.techartTrekkies.imagineInquirySys"
                                  target="_blank"
                                >
                                  <img
                                    src={playStoreImg}
                                    className="img-fluid"
                                  ></img>
                                </a>
                              </div>
                              <div className="mr-0">
                                <a
                                  href="https://apps.apple.com/np/app/imagine-inquiry-sys/id1615905256"
                                  target="_blank"
                                >
                                  <img
                                    src={appStoreImg}
                                    className="img-fluid"
                                  ></img>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 px-0">
                        <div className="rightFormBox">
                          <div className="tt-login-image">
                            {this.state.logoSpinner ? (
                              <Spinner
                                color="success"
                                style={{ width: "40px", height: "40px" }}
                              />
                            ) : this.state.schoolImage !== null ? (
                              <img
                                src={this.state.schoolImage}
                                alt="Not Loading"
                                className="img-fluid tt-login-school-image"
                              />
                            ) : (
                              <img
                                src={loginImageMobile}
                                alt="Not Loading"
                                className="img-fluid tt-login-school-image"
                              />
                            )}

                            <div>
                              <input
                                type="text"
                                id="username"
                                name="username"
                                className="form__input"
                                placeholder="Username or email"
                                onChange={this.handleDataChange}
                                value={this.state.username}
                              />
                            </div>
                            <div className="position-relative">
                              <input
                                type={this.state.inputType}
                                id="password"
                                name="password"
                                className="form__input mb-1"
                                placeholder="Password"
                                onChange={this.handleDataChange}
                              />
                              <span>
                                <FontAwesomeIcon
                                  icon={
                                    this.state.inputType === "password"
                                      ? faEye
                                      : faEyeSlash
                                  }
                                  className="passwordIcon"
                                  onClick={this.handleInputTypeChange}
                                />
                              </span>
                            </div>
                            <div className="row">
                              <div className="col px-0">
                                <input
                                  id="remember"
                                  type="checkbox"
                                  name="rememberMe"
                                  className="tt-checkboxNew"
                                  onChange={(e) => {
                                    this.setState({
                                      rememberMe: e.target.checked,
                                    });
                                    localStorage.setItem(
                                      "rememberMe",
                                      e.target.checked
                                    );
                                  }}
                                  checked={this.state.rememberMe}
                                />
                                <label
                                  htmlFor="remember"
                                  className="tt-rememberMeNew"
                                >
                                  Remember me
                                </label>
                              </div>
                              <div className="col px-0 text-right">
                                <p
                                  className="tt-forgot-password"
                                  onClick={this.forgotPasswordModal}
                                >
                                  Forgot password?
                                </p>
                              </div>
                            </div>
                            <div className="btnDiv">
                              <button
                                type="submit"
                                className="tt-login-btn tt-button tt-button-primary"
                                style={{ borderRadius: "50px" }}
                              >
                                Sign in
                              </button>
                            </div>
                            <div className="row brandLogoHolder ">
                              <div class="col d-flex align-items-center">
                                <a class="mr-1">A product by</a>
                                <a
                                  target="_blank"
                                  href="https://techarttrekkies.com/"
                                >
                                  <img height="30" src={techartLogo} alt="" />
                                </a>
                              </div>
                              <div class="col d-flex justify-content-end align-items-center">
                                <a class="mr-1"> Powered by</a>
                                <a target="_blank" href="https://groupiig.com/">
                                  <img height="30" src={iigLogo} alt="" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="tt-mobileVersion mobileBack">
          <div className="container-fluid handleMarginMobile">
            <div className="row">
              <div className="col-md-7 text-center">
                <div className="tt-mobile-loginLogo-holder">
                  <img
                    src={loginImageMobile}
                    alt="Not Loading"
                    className="tt-mobile-login-logo"
                  />
                </div>
                <div className="tt-mobile-loginBox">
                  <div>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      className="form-control"
                      placeholder="Username or email"
                      onChange={this.handleDataChange}
                      value={this.state.username}
                    />
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control mt-3"
                      placeholder="Password"
                      onChange={this.handleDataChange}
                    />
                    <button
                      type="submit"
                      className="tt-button tt-button-primary tt-button-fullWidth mt-3"
                      onClick={(e) => this.handlelogin(e)}
                    >
                      Sign in
                    </button>
                    <input
                      id="remember"
                      type="checkbox"
                      name="rememberMe"
                      className="tt-checkbox"
                      onChange={(e) => {
                        this.setState({ rememberMe: e.target.checked });
                        localStorage.setItem("rememberMe", e.target.checked);
                      }}
                      checked={this.state.rememberMe}
                    />
                    <label htmlFor="remember" className="tt-rememberMe">
                      Remember me
                    </label>
                    <p
                      className="tt-forgot-password-mobile"
                      onClick={this.forgotPasswordModal}
                    >
                      Forgot password?
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.modal}
          modalClass="forgotModal"
          modalHeader="Request New Password"
          modalBody={this.forgotModalBody()}
          modalFooter={this.forgotModalFooter()}
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = () => ({});
const mapActionsToProps = { setPermissions };

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionsToProps)
)(LoginPage);
