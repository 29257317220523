import React, { Component } from "react";
import "../../../assets/scss/innerModal.scss";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";

import CrudContainer from "./CrudContainer";

// const CrudContainer = React.lazy(() => import("./CrudContainer"));

class Container extends Component {
  state = {
    tabName: "",
    backBtn: false,
    activeId: "",
    currentTab: "",
    tabId: "content-0",
  };

  render() {
    return (
      <div>
        <Tabs
          defaultTab="content-0"
          // className="widgetHolder"
          onChange={(tabId) => {
            this.setState({ tabId });
          }}
        >
          <TabList style={{ marginBottom: "10px !important" }}>
            {this.props.contents.map((content, idx) => (
              <Tab
                tabFor={`content-${idx}`}
                key={idx}
                className="rwt__tab_main-tab"
              >
                <h6>{content.name}</h6>
              </Tab>
            ))}
          </TabList>

          {this.props.contents.map((content, id) => (
            <TabPanel
              tabId={`content-${id}`}
              key={id}
              render={() =>
                this.state.tabId === `content-${id}` ? (
                  <CrudContainer
                    content={content}
                    name={this.props.name}
                    tabName={content.name}
                  />
                ) : null
              }
            />
          ))}
        </Tabs>
      </div>
    );
  }
}

export default Container;
