import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, userRole } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";

import "../../assets/scss/ListClassRoutine.scss";
import { groupBy, orderBy } from "lodash";
import swal from "sweetalert";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";

import ClassRoutineFormat from "./ClassRoutineFormat";

class ListClassRoutine extends Component {
  state = {
    mergeData: "",

    weekDays: [],
    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: "",

    classrooms: [],
    selectedClassrooms: "",

    classRoutineUpdateModal: false,

    routine1: [],
    subjects: [],

    teachers: [],
    insertedRoutine: "",
    render: false,
    schoolInfos: "",
  };
  componentDidMount = () => {
    this.getWeekdays();
    this.getEducationLevels();
    this.getTeachers();
    this.getSchoolInfo();
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data[0],
        });
      }
    });
  };

  getTeachers = () => {
    axiosPost(URL.getSubjectTeachers, {}, (response) => {
      if (response.status == 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            teachers: data,
          });
      }
    });
  };

  getInsertedRoutine = () => {
    this.setState({ routine1: [] }, () => {
      let filter = {
        educationLevelId: this.state.selectedEducationLevelId,
        yearlevelId: this.state.selectedYearLevel,
        classroomId: this.state.selectedClassrooms,
      };
      axiosPost(URL.getClassRoutine, filter, (response) => {
        if (response.data.status === 200) {
          let data = [...response.data.data];
          this.setState({ mergeData: data });
          data = data.map((d) => {
            d.columnIndex = d.indexKey.split(",")[1];
            return d;
          });

          const groupByRoutine = groupBy(data, (d) => d.indexKey.split(",")[0]);
          for (const property in groupByRoutine) {
            groupByRoutine[property] = orderBy(
              groupByRoutine[property],
              ["columnIndex"],
              ["asc"]
            );
          }

          let output = [];
          Object.keys(groupByRoutine).forEach((key, id) => {
            let newOutput = groupByRoutine[key].reduce(function (o, cur) {
              // Get the index of the key-value pair.
              let occurs = o.reduce(function (n, item, i) {
                return item.indexKey === cur.indexKey ? i : n;
              }, -1);

              // If the name is found,

              if (occurs >= 0) {
                let subjectName = [];
                if (cur.subjectName) {
                  subjectName = [
                    {
                      aliasName: cur.aliasName,
                      name: cur.subjectName,
                      hasAliasName: cur.aliasName ? true : false,
                    },
                  ];
                }
                // append the current value to its list of values.
                o[occurs].peopleName = o[occurs].peopleName.concat(
                  cur.peopleName
                );
                o[occurs].subjectName =
                  o[occurs].subjectName.concat(subjectName);
                o[occurs].subjectId = o[occurs].subjectId.concat(cur.subjectId);
                o[occurs].peopleId = o[occurs].peopleId.concat(cur.peopleId);
                o[occurs].selectedSubjectValue = [];

                if (o[occurs].subjectId.length > 0) {
                  o[occurs].subjectId.forEach((el, idx) => {
                    let data = {
                      value: el,
                      label: o[occurs].subjectName[idx].name,
                      hasAliasName: o[occurs].subjectName[idx].hasAliasName,
                      aliasName: o[occurs].subjectName[idx].aliasName,
                    };
                    o[occurs].selectedSubjectValue.push(data);
                  });
                }

                o[occurs].selectedPeopleValue = [];
                if (o[occurs].peopleId.length > 0) {
                  o[occurs].peopleId.forEach((el, idx) => {
                    let data = {
                      value: el,
                      label: o[occurs].peopleName[idx],
                    };
                    o[occurs].selectedPeopleValue.push(data);
                  });
                }

                // Otherwise,
              } else {
                // add the current item to o (but make sure the value is an array).
                let subjectName = [];
                if (cur.subjectName) {
                  subjectName = [
                    {
                      aliasName: cur.aliasName,
                      name: cur.subjectName,
                      hasAliasName: cur.aliasName ? true : false,
                    },
                  ];
                }

                var obj = {
                  indexKey: cur.indexKey,
                  peopleName: [cur.peopleName],
                  subjectName: subjectName,
                  peopleId: cur.peopleId ? [cur.peopleId] : [],
                  subjectId: cur.subjectId ? [cur.subjectId] : [],
                  selectedSubjectValue: [],
                  selectedPeopleValue: [],
                };

                if (obj.subjectId.length > 0) {
                  obj.subjectId.forEach((el, idx) => {
                    let data = {
                      value: el,
                      label: obj.subjectName[idx].name,
                      hasAliasName: obj.subjectName[idx].hasAliasName,
                      aliasName: obj.subjectName[idx].aliasName,
                    };
                    obj.selectedSubjectValue.push(data);
                  });
                }
                if (obj.peopleId.length > 0) {
                  obj.peopleId.forEach((el, idx) => {
                    let data = {
                      value: el,
                      label: obj.peopleName[idx],
                    };
                    obj.selectedPeopleValue.push(data);
                  });
                }

                var finalObj = {
                  ...cur,
                  ...obj,
                };

                o = o.concat([finalObj]);
              }

              return o;
              // this.setState({ finalRoutinee: routine });
            }, []);
            output.push(newOutput);
          });

          var routine = Object.assign({}, output);
          this.setState({ routine1: routine, render: true });
          // localStorage.setItem("routine", JSON.stringify(routine));
        }
      });
    });
  };

  getWeekdays = () => {
    axiosPost(
      URL.getWeekDaysByEducationLevelDivision,
      { educationLevelId: this.state.selectedEducationLevelId },
      (response) => {
        if (response.data.status === 200) {
          const data = response.data.data;
          const sort = data.sort((a, b) => a.id - b.id);
          if (data.length > 0)
            this.setState({
              weekDays: sort,
            });
          // const data = response.data.data;
          // if (data.length > 0)
          //   this.setState({
          //     weekDays: data,
          //   });
        }
      }
    );
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
          });
      }
    });
  };

  handleEducationLevelChange = (e) => {
    this.setState({ selectedEducationLevelId: e.target.value });

    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          classrooms: [],
          selectedClassrooms: "",
          subjects: [],
          selectedSubjects: "",
          selectedYearLevel: "",
          routine1: "",
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState(
      {
        selectedYearLevel: e.target.value,
        selectedClassrooms: "",
      },
      function () {
        var data = {
          yearLevelId: this.state.selectedYearLevel,
        };
        axiosPost(URL.selectClassroom, data, (response) => {
          if (response.status === 200) {
            const datas = response.data.data;
            if (datas.length > 0) {
              this.setState({
                classrooms: datas,
                selectedClassrooms: "",
                subjects: [],
                selectedSubjects: "",
              });
            }
          }
        });
      }
    );
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value }, () => {
      this.getInsertedRoutine();
      // this.handleClassRoutineDelete();
    });
    var data = {
      classroomId: e.target.value,
    };

    axiosPost(URL.getSubjectByClassroom, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            subjects: data,
            selectedSubjects: data[0].id,
            render: true,
          });
      }
    });
  };

  handleClassRoutineUpdateModal = (e) => {
    this.setState({
      classRoutineUpdateModal: !this.state.classRoutineUpdateModal,
    });
  };

  handleClassRoutineDelete = () => {
    let data = {
      educationLevelId: this.state.selectedEducationLevelId,
      yearlevelId: this.state.selectedYearLevel,
      classroomId: this.state.selectedClassrooms,
    };
    axiosPost(URL.deleteClassRoutine, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Class Routine successfully Deleted" });
        this.setState({
          routine1: [],
        });
      }
    });
    localStorage.removeItem("routine");
  };

  handleClassRoutineDownload = async () => {
    const element = document.getElementById("printRoutine");
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    // pdf.addImage(data, "PNG", 0, 0);
    pdf.save("classRoutine.pdf");
  };

  renderWeekDayPeople = (el) => {
    return this.state.routine1[el].map((rout, idx) => {
      return this.state.weekDays.map((wD) => {
        return wD.id === rout.weekdayId ? (
          <td width="200px" style={{ verticalAlign: "middle" }}>
            <strong>
              {rout.subjectName.length > 0
                ? rout.subjectName.map((sub, idx) => {
                    if (idx == rout?.subjectName.length - 1) {
                      if (sub.aliasName) {
                        return sub.aliasName;
                      } else {
                        return sub.name;
                      }
                    } else {
                      return `${sub.aliasName ? sub.aliasName : sub.name} / `;
                    }
                  })
                : "-"}
            </strong>
            {rout.peopleId.length > 0 ? (
              <>
                <br />(
                {rout.peopleName.map((name, idx) => {
                  if (idx == rout?.peopleName.length - 1) {
                    return name;
                  } else {
                    return `${name} / `;
                  }
                })}
                )
              </>
            ) : null}
          </td>
        ) : null;
      });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">List Class Routine</div>

          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-4 col-md">
                  <strong>Education Level</strong>
                  <select
                    className="form-control"
                    name="educationLevel"
                    value={this.state.selectedEducationLevelId}
                    onChange={this.handleEducationLevelChange}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.educationLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-4 col-md">
                  <strong>Class</strong>
                  <select
                    className="form-control"
                    name="yearLevel"
                    value={this.state.selectedYearLevel}
                    onChange={this.handleYearLevel}
                    disabled={this.state.selectedEducationLevelId === ""}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.yearLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-4 col-md">
                  <strong>Section</strong>
                  <select
                    className="form-control"
                    name="sectionId"
                    value={this.state.selectedClassrooms}
                    onChange={this.handleSectionChange}
                    disabled={this.state.selectedYearLevel === ""}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.classrooms.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              {this.state.render ? (
                <div className="row mt-2" id="printRoutine">
                  <div className="col-12 my-3 text-center">
                    <h2>{this.state.schoolInfos?.name}</h2>
                    <h5>{this.state.schoolInfos?.address}</h5>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      {/* <div className="">
                        <b>Education Level: </b>
                        {this.state.educationLevels.map(
                          (el) =>
                            el.id == this.state.selectedEducationLevelId && (
                              <span>{el.name}</span>
                            )
                        )}
                      </div> */}

                      <div className="">
                        <b>Class: </b>
                        {this.state.yearLevels.map(
                          (el) =>
                            el.id == this.state.selectedYearLevel && (
                              <span>{el.name}</span>
                            )
                        )}
                      </div>

                      <div className="">
                        <b>Section: </b>
                        {this.state.classrooms.map(
                          (el) =>
                            el.id == this.state.selectedClassrooms && (
                              <span>{el.name}</span>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <table className="table table-bordered text-center mt-3">
                      <thead className="tt-group-header">
                        <tr>
                          <th width="180px"></th>
                          {this.state.weekDays.map((weeks, id) => {
                            return <th key={id}>{weeks.name}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(this.state.routine1).length > 0 ? (
                          Object.keys(this.state.routine1).map((el, id) => {
                            return (
                              <>
                                {this.state.routine1[el].map((r, ridx) => {
                                  return (
                                    ridx === 0 && (
                                      <tr>
                                        <td
                                          className="tableData"
                                          style={{ verticalAlign: "middle" }}
                                        >
                                          <strong>
                                            {moment(r?.startDateTime).format(
                                              "LT"
                                            )}
                                          </strong>
                                          {` - `}
                                          <strong>
                                            {moment(r?.endDateTime).format(
                                              "LT"
                                            )}
                                          </strong>
                                        </td>
                                        {r?.isBreak ? (
                                          <td
                                            colspan={this.state.weekDays.length}
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <strong
                                              className="text-center"
                                              style={{
                                                justifyContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                display: "flex",
                                              }}
                                            >
                                              {r?.breakType}
                                            </strong>
                                          </td>
                                        ) : (
                                          this.renderWeekDayPeople(el)
                                        )}
                                      </tr>
                                    )
                                  );
                                })}
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {Object.keys(this.state.routine1).length > 0 ? (
            <div className="text-center mt-3">
              {userRole.toString() !== "RL_STD" ? (
                <button
                  className="tt-button tt-button-primary mr-2"
                  onClick={(e) => this.handleClassRoutineUpdateModal(e)}
                >
                  Update
                </button>
              ) : null}

              {userRole.toString() !== "RL_STD" ? (
                <button
                  className="tt-button tt-button-primary mr-2"
                  onClick={() => this.handleClassRoutineDelete()}
                >
                  Delete
                </button>
              ) : null}
              <button
                className="tt-button tt-button-primary"
                onClick={() => this.handleClassRoutineDownload()}
              >
                Download
              </button>
            </div>
          ) : null}
        </div>

        <ModalWindow
          modal={this.state.classRoutineUpdateModal}
          toggleModal={this.handleClassRoutineUpdateModal}
          modalHeader={"Update Class Routine"}
          size="xl"
          fullWidth={true}
          modalBody={
            <ClassRoutineFormat
              condition="update"
              educationLevelId={this.state.selectedEducationLevelId}
              yearlevelId={this.state.selectedYearLevel}
              classroomId={this.state.selectedClassrooms}
              routine1={this.state.routine1}
              teachers={this.state.teachers}
              subjects={this.state.subjects}
              getInsertedRoutine={this.getInsertedRoutine}
              handleClassRoutineUpdateModal={this.handleClassRoutineUpdateModal}
            ></ClassRoutineFormat>
          }
        ></ModalWindow>
      </React.Fragment>
    );
  }
}

export default ListClassRoutine;
