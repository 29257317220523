import React, { Component } from "react";
import userImage from "../../assets/images/user-image.png";
import { Modal, ModalBody } from "reactstrap";
import "../../assets/scss/profilePicture.scss";
import ModalWindow from "../UI/ModalWindow";
import UploadForm from "../Upload/UploadForm";
import { axiosPost } from "../../utils/AxiosApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  URL as URLs,
  checkRolePermissions,
  modules,
} from "../../utils/Constants";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FullImage from "./FullImage";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import swal from "sweetalert";

class ProfilePicture extends Component {
  state = {
    modal: false,
    image: userImage,
    tempImage: null,
    imageModal: false,
    userData: "",
    profilePicture: null,
    imagePreview: null,
    newImage: false,
    showSpinner: false,
    fullImage: null,
  };

  getProfilePicture = () => {
    let data = {
      id: this.props.userId,
    };

    axiosPost(URLs.getProfilePicture, data, (response) => {
      if (
        response.status === 200 &&
        response.data.data !== null &&
        response.data.data.name !== null
      ) {
        let newPath = response.data.data.name.replace("profilePics", "136x136");
        this.setState({
          image: URLs.imageSelectURL + newPath,
          // image:
          //   URLs.imageSelectURL + "136x136" + "/" + response.data.data.name,
          profilePicture: response.data.data,
          fullImage: URLs.imageSelectURL + response.data.data.name,
        });
      } else {
        if (!this.props.otherProfile) {
          this.getUserProfileInfo();
        } else {
          let newPath = response.data.data.name.replace(
            "profilePics",
            "136-136"
          );
          this.setState({
            image: this.props.memberPhoto
              ? URLs.imageSelectURL + newPath
              : //   ? require(`../../assets/images/${this.props.memberPhoto}`)
                userImage,
            fullImage: URLs.imageSelectURL + this.props.memberPhoto,
          });
        }
      }
    });
  };

  componentDidMount() {
    this.getProfilePicture();
  }

  getUserProfileInfo = () => {
    let data = {
      peopleId: this.props.peopleId,
    };
    axiosPost(URLs.userProfileInfo, data, (response) => {
      if (response.status === 200) {
        let userData = response.data.data;
        if (userData.photo !== null) {
          let newPath = userData.photo.replace("profilePics", "136-136");
          this.setState({
            image: URLs.imageSelectURL + newPath,
            fullImage: URLs.imageSelectURL + userData.photo,
          });
        } else {
          this.setState({ image: userImage, fullImage: userImage });
        }
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      this.setState({ image: userImage }, () => {
        this.getProfilePicture();
      });
    }
  }

  profileModal = () => {
    this.setState({
      modal: !this.state.modal,
      tempImage: null,
      imagePreview: this.state.image,
    });
  };

  toggleImageModal = (e) => {
    this.setState({ imageModal: !this.state.imageModal });
  };

  handleImageChange = (e) => {
    this.setState(
      {
        tempImage: e.target.files[0],
        newImage: true,
      },
      function () {
        if (this.state.tempImage) {
          let image = URL.createObjectURL(this.state.tempImage);
          this.setState({ imagePreview: image });
        } else {
          this.setState({
            imagePreview: null,
          });
        }
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ showSpinner: true }, function () {
      if (this.state.tempImage && this.state.tempImage.name) {
        let formData = new FormData();
        formData.append(
          "file",
          this.state.tempImage,
          this.state.tempImage.name
        );
        let peopleId = this.props.profilePeopleId;
        formData.append("peopleId", peopleId);
        formData.append("moduleCode", `${modules["Profile Picture"]}`);
        axiosPost(
          URLs.insertProfilePicture,
          formData,
          (response) => {
            if ((response.status = 200)) {
              this.setState({ modal: false, tempImage: null });
              //   this.getProfilePicture();
              window.location.reload();
            }
          },
          (failure) => {
            swal({
              title: "Error",
              text: "Sorry, your profile picture cannot be updated",
            });
            this.setState({ showSpinner: false });
          }
        );
      }
    });
  };

  handleRemoveExistingProfilePicture = (e) => {
    let param = this.state.profilePicture;
    if (param != null) {
      param.isActive = false;
      axiosPost(URLs.removeExistingProfilePicture, param, (response) => {
        if ((response.status = 200)) {
          this.setState({
            modal: false,
            image: userImage,
            imagePreview: userImage,
            fullImage: userImage,
          });
        }
      });
    }
  };

  removeProfilePicture = (e) => {
    if (this.state.newImage) {
      this.setState({
        tempImage: null,
        imagePreview: this.state.image,
        newImage: false,
      });
    } else {
      displayConfirmDeleteAlert(e, this.handleRemoveExistingProfilePicture);
    }
  };

  modalFooter = () => {
    return (
      <div className="row">
        <div className="text-right">
          <button
            onClick={this.handleSubmit}
            disabled={this.state.tempImage !== null ? false : true}
            className="tt-button tt-button-primary mr-3"
          >
            Upload
          </button>
          {/* <Button
            onClick={this.handleRemoveExistingProfilePicture}
            className="tt-button tt-button-primary mr-3"
          >
            Remove
          </Button> */}
          <button
            onClick={this.profileModal}
            className="tt-button tt-button-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  handleError = (e) => {
    let self = this;
    let img = new Image();
    img.src = this.state.fullImage;
    img.onload = function () {
      self.setState({ image: img.src, fullImage: img.src });
    };
    img.onerror = function () {
      self.setState({ image: userImage, fullImage: userImage });
    };
  };

  checkDisable = () => {
    let profilePeopleId = this.props.profilePeopleId,
      peopleId = localStorage.getItem("peopleId");
    let adminOrModerator = checkRolePermissions(
      "can-update-profile-pic",
      "activity"
    );
    let isLegalGuardian = this.props.isLegalGuardian;
    if (
      profilePeopleId !== peopleId &&
      adminOrModerator === false &&
      isLegalGuardian === false
    ) {
      return true;
    } else if (
      profilePeopleId === peopleId ||
      adminOrModerator === true ||
      isLegalGuardian === true
    ) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div
            id="tt-profileImage"
            className="tt-profile-image"
            onMouseEnter={() => this.props.toggleHover("profile", "show")}
            onMouseLeave={() => this.props.toggleHover("profile", "hide")}
          >
            {/* eslint-disable-next-line */}
            <img
              alt="profile-photo"
              src={this.state.image}
              onClick={this.toggleImageModal}
              onError={(e) => this.handleError(e)}
            />

            <button
              className="tt-editProfileButton"
              id="tt-editProfileButton"
              onClick={this.profileModal}
              disabled={this.checkDisable()}
            >
              Edit Profile Image
            </button>

            <div className="tt-userInfo">
              {/* {this.props.fullName
                ? userRole === userRoleCode.roleStudentContact
                  ? `The ${this.props.fullName.split(/[, ]+/).pop()}'s`
                  : `${this.props.fullName.split(" ")[0]}`
                : null} */}
              {this.props.fullName.split(" ")[0]}
            </div>
          </div>
        </div>

        <ModalWindow
          fullWidth={false}
          modal={this.state.modal}
          toggleModal={this.profileModal}
          modalHeader="Change Profile Picture"
          modalBody={
            <UploadForm
              handleSubmit={this.handleSubmit}
              handleImageChange={this.handleImageChange}
              tempImage={this.state.tempImage}
              removeProfilePicture={this.removeProfilePicture}
              imagePreview={this.state.imagePreview}
              showSpinner={this.state.showSpinner}
            />
          }
          modalFooter={this.modalFooter()}
        ></ModalWindow>
        <Modal
          id="fullProfileImage"
          isOpen={this.state.imageModal}
          toggle={this.toggleImageModal}
          centered
          className="tt-profileFullImage"
        >
          <FontAwesomeIcon
            icon={faTimes}
            size="2x"
            className="tt-ImageCloseIcon"
            onClick={this.toggleImageModal}
          ></FontAwesomeIcon>
          <ModalBody>
            <FullImage image={this.state.fullImage}></FullImage>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ProfilePicture;
