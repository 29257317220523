import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { displayErrorMessage } from "../../../utils/Utils";
import ModalWindow from "../../UI/ModalWindow";
import InventoryItemForm from "./InventoryItemForm";

class InventoryItems extends Component {
  state = {
    addModal: false,
    categories: [],
    filterCategoryId: "",
    inventories: [],
    loader: false,
    quantity: 0,
  };

  componentDidMount() {
    this.getInventories();
    this.getCategories();
  }

  getCategories = () => {
    axiosPost(
      URL.getAllFeeCategory,
      { isInventoryCategory: true },
      (response) => {
        if (response.status === 200) {
          this.setState({ categories: response.data.data });
        }
      }
    );
  };

  getInventories = () => {
    this.setState({ loader: true, inventories: [] }, function () {
      let param = {
        feeCategoryId: this.state.filterCategoryId,
      };
      axiosPost(
        URL.getInventories,
        param,
        (res) => {
          if (res.status === 200) {
            let datum = res.data.data;
            if (datum.length > 0) {
              datum.forEach((el) => {
                el.quantity = 0;
                el.editQuantity = false;
              });
            }
            this.setState({ inventories: datum, loader: false });
          }
        },
        (err) => {
          this.setState({ loader: false });
        }
      );
    });
  };

  handleAddModal = () => {
    this.setState((prevState) => {
      return { ...prevState, addModal: !prevState.addModal };
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "quantity") {
      if (value < 0) {
        swal("Error", "Cannot enter a negative value");
        return;
      }
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value }, () => {
        if (name === "filterCategoryId") {
          this.getInventories();
        }
      });
    }
  };

  handleEditQuantity = (idx) => {
    let inventories = this.state.inventories;
    let preValue = inventories[idx].editQuantity;
    if (preValue) {
      inventories.forEach((el) => {
        el.editQuantity = false;
      });
    } else {
      inventories[idx].editQuantity = true;
    }
    this.setState({ inventories, quantity: 0 });
  };

  handleQuantityChange = (e, idx) => {
    const { name, value } = e.target;
    let inventories = [...this.state.inventories];
    inventories[idx][name] = value;
    this.setState({ inventories });
  };

  submitQuantity = (id) => {
    if (this.state.quantity !== "") {
      let data = {
        inventoryItemId: id,
        quantity: this.state.quantity,
      };
      axiosPost(
        URL.insertInventoryQuantity,
        data,
        (res) => {
          if (res.status === 200) {
            swal("Success", "Quantity successfully added");
            this.getInventories();
          }
        },
        (err) => {
          displayErrorMessage(err);
        }
      );
    } else {
      swal("Error", "Add the number of quantity to add");
    }
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">Inventory Items</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <button
                className="tt-button tt-button-primary"
                onClick={this.handleAddModal}
              >
                Add Inventory Items
              </button>
            </div>
            <div className="col-md-6 text-right">
              <div className="row">
                <div className="col-md-4 mt-1">
                  <strong>Filter By Category</strong>
                </div>
                <div className="col-md-8">
                  <select
                    className="form-control"
                    name="filterCategoryId"
                    value={this.state.filterCategoryId}
                    onChange={this.handleChange}
                    placeholder="Choose Category"
                  >
                    <option value="">All</option>
                    {this.state.categories.map((category, idx) => (
                      <option key={idx} value={category.id}>
                        {category.category}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table className="table table-bordered table-striped mt-2">
              <thead className="tt-group-header">
                <tr>
                  <th width="60px" className="text-center">
                    SN
                  </th>
                  <th>Item Name [ Item Code ]</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th></th>
                  <th width="100px" className="text-center">
                    Quantity
                  </th>
                  <th width="200px" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.inventories.length > 0 ? (
                  this.state.inventories.map((inventory, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="text-center">{idx + 1}</td>
                        <td>
                          {inventory.itemName}{" "}
                          <strong>[ {inventory.itemCode} ]</strong>
                        </td>
                        <td className="text-center">
                          {inventory.feeCategoryId}
                        </td>
                        <td>{inventory.description}</td>
                        <td className="text-left">
                          <strong>Purchase Price: </strong>
                          Rs. {inventory.purchasePrice}
                          <br />
                          <strong>Selling Price: </strong>
                          Rs. {inventory.sellingPrice}
                          <br />
                          <strong>Reorder Level: </strong>
                          {inventory.reorderLevel}
                        </td>
                        <td className="text-center">
                          {inventory.editQuantity ? (
                            <input
                              type="number"
                              className="form-control"
                              name="quantity"
                              value={this.state.quantity}
                              min={0}
                              onChange={this.handleChange}
                            />
                          ) : inventory.inventoryItemStockLevels.length > 0 ? (
                            //Add numbers inside array of object
                            // inventory.inventoryItemStockLevels
                            //   .map((item) => item.quantity)
                            //   .reduce(
                            //     (prev, next) => parseInt(prev) + parseInt(next)
                            //   )
                            inventory.instock
                          ) : (
                            0
                          )}
                        </td>
                        <td className="text-center">
                          {inventory.editQuantity ? (
                            <>
                              <button
                                className="tt-button tt-button-primary"
                                onClick={() =>
                                  this.submitQuantity(inventory.id)
                                }
                              >
                                Save
                              </button>
                              <button
                                className="tt-button tt-button-danger ml-2"
                                onClick={() => this.handleEditQuantity(idx)}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="tt-button tt-button-primary"
                                onClick={() => this.handleEditQuantity(idx)}
                              >
                                Add Quantity
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      {this.state.loader ? (
                        <>
                          <Spinner color="primary"></Spinner>
                          <br />
                          Loading ...
                        </>
                      ) : (
                        "No items added"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* {this.state.inventories.length > 0 ? <div></div> : <div>No data</div>} */}
        <ModalWindow
          modal={this.state.addModal}
          toggleModal={this.handleAddModal}
          modalHeader={"Add Inventory Items"}
          size="xl"
          modalBody={
            <InventoryItemForm
              toggleModal={this.handleAddModal}
              getInventories={this.getInventories}
              categories={this.state.categories}
            ></InventoryItemForm>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default InventoryItems;
