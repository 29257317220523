import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "./../UI/ModalWindow";
import GranularPermissionModal from "./../Permissions/GranularPermissionModal";
import swal from "sweetalert";
import moment from "moment";
import ListStudentAttendanceDetails from "./ListStudentAttendanceDetails";
import { groupBy } from "lodash";

class AttendanceForStudent extends Component {
  state = {
    month: "",
    classrooms: [],
    classRoomName: "",
    yearLevelId: "",
    sectionId: "",
    students: [],
    calendarDate: moment().format("YYYY-MM-DD"),
    checkMonthSelected: true,
    showBtn: false,
    selectedClassroom: "",
  };

  componentDidMount() {
    this.getEnglishMonth();
    this.getClassrooms();
    this.getYearLevels();
    this.getWeekDays();
    this.checkForCalendarEvents();
  }

  getWeekDays = async () => {
    const weekDayPromise = new Promise((res, rej) => {
      axiosPost(
        URL.getWeekDaysByEduDiv,
        {
          educationLevelId: this.state.educationLevelId,
        },
        (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        }
      );
    });
    const weekDays = await weekDayPromise;
    weekDays &&
      this.setState({
        weekDays: weekDays.map(({ id }) => id),
      });
    return weekDays.map(({ id }) => id);
  };

  //getCLassRoom
  getClassrooms = () => {
    let data = {
      yearLevelId: this.state.yearLevel,
    };
    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.status === 200) {
        let classrooms = response.data.data;
        this.setState({
          classrooms,
        });
      }
    });
  };

  //getEnglishMonth Date
  getEnglishMonth = () => {
    axiosPost(URL.getEnglishMonth, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            month: data,
          });
      }
    });
  };

  getOldStudentsData = (date) => {
    const params = {
      classroomId: this.state.cid,
      subjectId: this.state.selectedSubject,
      date,
    };

    this.setState({ attendanceLoading: true }, () => {
      if (this.state.cid != null) {
        axiosPost(
          URL.getAssignedStudents,
          params,
          (response) => {
            if (response.status === 200) {
              let students = response.data.data;
              let studentAttendances = [...this.state.studentAttendances];
              for (let index = 0; index < students.length; index++) {
                studentAttendances.push({
                  yearLevelId: this.state.yid,
                  classroomId: this.state.cid,
                  admissionId: students[index].admissionId,
                  isPresent: students[index].isPresent || false,
                  remarks: "",
                  studentName: students[index].people.name,
                });
              }
              this.setState(
                {
                  students,
                  studentAttendances,
                },
                () => {
                  axiosPost(
                    URL.getStudentsAttendance,
                    params,
                    (response) => {
                      if (response.status == 200) {
                        let studentAttendents = [...response.data.data];
                        let studentAttendances = [
                          ...this.state.studentAttendances,
                        ];
                        studentAttendents.forEach((attendent) => {
                          const index = this.state.studentAttendances.findIndex(
                            (student) =>
                              student.admissionId === attendent.admissionId
                          );
                          if (index !== -1) {
                            studentAttendances[index].isPresent =
                              attendent.isPresent || false;
                          }
                        });
                        this.setState({
                          studentAttendances,
                          attendanceLoading: false,
                        });
                      }
                    },
                    (err) => {
                      this.setState({ attendanceLoading: false });
                    }
                  );
                }
              );
            }
          },
          (err) => {
            this.setState({ attendanceLoading: false });
          }
        );
      } else {
        swal("Select both yearLevel and Classroom");
      }
    });
  };

  getStudents = (cid, yid) => {
    this.setState({ attendanceLoading: true }, () => {});
    if (this.state.classRoomName != null) {
      let params = {
        classroomId: cid,
      };
      axiosPost(
        URL.getAssignedStudents,
        params,
        (response) => {
          if (response.status === 200) {
            let students = response.data.data;
            let studentAttendances = [...this.state.studentAttendances];
            for (let index = 0; index < students.length; index++) {
              studentAttendances.push({
                yearLevelId: yid,
                classroomId: cid,
                admissionId: students[index].admissionId,
                isPresent: students[index].isPresent || false,
                remarks: "",
                studentName: students[index].people.name,
              });
            }
            this.setState({
              students,
              studentAttendances,
              attendanceLoading: false,
            });
          }
        },
        (err) => {
          this.setState({ attendanceLoading: false });
        }
      );
    } else {
      swal("Select both yearLevel and Classroom");
    }
  };

  getStudentsInClassroom = () => {
    let data = {
      yearLevelId: this.state.yid,
      classroomId: this.state.cid,
    };
    axiosPost(URL.getAssignedStudents, data, (response) => {
      if (response.status === 200) {
        this.setState({
          students: response.data.data,
        });
      }
    });
  };

  getYearLevels = () => {
    axiosPost(
      URL.getYearLevelByEducationLevel,
      {
        educationLevelId: this.state.educationlevelId,
      },
      (response) => {
        if (response.status === 200) {
          this.setState({ yearLevels: response.data.data });
        }
      }
    );
  };

  getClassroomSubjects = (cid, yid) => {
    var data = {
      classroomId: cid,
      yearLevelId: yid,
    };
    axiosPost(URL.getSubjectByClassroom, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        const filterDuplicateOne =
          data &&
          data.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.id === value.id && t.name === value.name)
          );
        if (data.length > 0) {
          this.setState({ subjects: filterDuplicateOne });
        }
      }
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      axiosPost(
        URL.getAdmissionDetailSummary,
        {
          classroomId: this.state.cid,
          yearLevelId: this.state.yid,
          admissionId: this.state.studentId,
          dateFrom: this.state.firstDayFormat,
          dateTo: this.state.lastDayFormat,
        },
        (response) => {
          if (response.status === 200) {
            const groupByDate = groupBy(response.data.data, (d) => d.date);
            this.setState({ studentData: groupByDate, showBtn: true });
          }
        }
      );
    });
  };

  handleClassroomSelect = (e) => {
    const target = e.target;
    const value = target.value.split(",");
    const name = target.name;
    const cid = value[0];
    const yid = value[1];
    //get selected classroom for its education level
    const classroom = this.state.classrooms.find((c) => c.id === parseInt(cid));
    this.setState(
      {
        selectedClassroom: classroom,
        [name]: value,
        studentId: "",
        studentAttendances: [],
        students: [],
        selectedSubject: null,
        subjects: [],
        cid,
        yid,
        educationLevelId: classroom && classroom.educationLevelId,
        checkedAll: false,
        studentData: {},
      },
      async () => {
        this.getClassroomSubjects(cid, yid);
        if (this.state.calendarDate) {
          this.getOldStudentsData(this.state.calendarDate);
          const weekDays = await this.getWeekDays();
          const weekDay = new Date(this.state.calendarDate).getUTCDay();
          const isHoliday = weekDays.findIndex((day) => day === weekDay);
          this.setState({ isHoliday: isHoliday === -1 ? true : false });
          if (isHoliday === -1) return;
          //check for holidays in calendar event as well
          const eventHoliday = await this.checkForCalendarEvents();
          this.setState({ isHoliday: eventHoliday ? true : false });
        } else {
          this.getStudents(cid, yid);
        }
      }
    );
  };

  checkForCalendarEvents = async () => {
    const calendarEventsPromise = new Promise((res, rej) => {
      axiosPost(
        URL.getEventsByEducationLevel,
        {
          educationLevelId: this.state.educationLevelId,
        },
        (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        }
      );
    });
    const calendarEvents = await calendarEventsPromise;
    if (calendarEvents) {
      //return true if selected day is holiday event
      for (let i = 0; i < calendarEvents.length; i++) {
        if (calendarEvents[i].isHoliday) {
          let range = moment(this.state.calendarDate).isBetween(
            calendarEvents[i].start,
            calendarEvents[i].end,
            "days",
            "[]"
          );
          if (range) return true;
        }
      }
    }
    return false;
  };
  handleMonthChange = (e) => {
    let copyOfMonthArr = [...this.state.month];
    let year, month;
    copyOfMonthArr.forEach((el, id) => {
      if (el.english === e.target.value) {
        // year = el.packageName ? el.packageName.split("_")[1] : "";
        year = parseInt(new Date().toDateString().split(" ")[3]);
        month = id;
      }
    });
    // const daysInSelectedMonth = new Date(year, month, 0).getDate();
    const firstDay = new Date(year, month, 1);
    const firstDayFormat = moment(firstDay).format("YYYY-MM-DD");
    const lastDay = new Date(year, month + 1, 0);
    const lastDayFormat = moment(lastDay).format("YYYY-MM-DD");

    this.setState(
      {
        firstDayFormat,
        lastDayFormat,
        selectedMonth: e.target.value,
        checkMonthSelected: false,
      },

      () => {
        if (
          this.state?.studentId !== "" &&
          this.state?.studentId !== null &&
          this.state?.studentId !== undefined
        ) {
          axiosPost(
            URL.getAdmissionDetailSummary,
            {
              classroomId: this.state.cid,
              yearLevelId: this.state.yid,
              admissionId: this.state.studentId,
              dateFrom: this.state.firstDayFormat,
              dateTo: this.state.lastDayFormat,
            },
            (response) => {
              if (response.status === 200) {
                const groupByDate = groupBy(response.data.data, (d) => d.date);
                this.setState({ studentData: groupByDate });
              }
            }
          );
        }
      }
    );
  };
  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-newListBox container-fluid">
            <div className="tt-group-header">
              <div className="row">
                <div className="col-md-4">
                  <label>Attendance For</label>
                  <select
                    name="classRoomName"
                    id="classRoomName"
                    value={this.state.classRoomName}
                    className="form-control"
                    onChange={this.handleClassroomSelect}
                  >
                    <option value="" disabled>
                      Select Classroom
                    </option>
                    {this.state.classrooms ? (
                      this.state.classrooms.map((classroom, idx) => (
                        <option
                          key={idx}
                          value={`${classroom.id},${classroom.yearLevelId}`}
                        >
                          {/* What if yearlevel name is inserted as number(like:1,2,..) */}
                          {classroom.yearLevelName}
                          {" - "}
                          {classroom.name}
                        </option>
                      ))
                    ) : (
                      <option value="null">No Classroom available</option>
                    )}
                  </select>
                </div>

                <div className="col-md-4">
                  <label>Month</label>
                  <select
                    name="Month"
                    className="form-control"
                    onChange={this.handleMonthChange}
                  >
                    <option value="" disabled selected>
                      Select Month
                    </option>
                    {this.state.month ? (
                      this.state.month.map((classroom, idx) => (
                        <option key={idx} value={classroom.english}>
                          {/* What if yearlevel name is inserted as number(like:1,2,..) */}
                          {classroom.english}
                        </option>
                      ))
                    ) : (
                      <option value="null">No Month available</option>
                    )}
                  </select>
                </div>

                <div className="col-md-4 ">
                  <label>Student</label>
                  <select
                    name="studentId"
                    className="form-control float-left"
                    value={this.state.studentId}
                    onChange={this.handleChange}
                    disabled={this.state.checkMonthSelected ? true : false}
                  >
                    <option disabled selected value="">
                      Choose Student:
                    </option>

                    {this.state.students.length > 0
                      ? this.state.students.map((std, id) => (
                          <option value={std.admissionId} key={id}>
                            {std.people.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>
            </div>

            <ListStudentAttendanceDetails
              selectedClassroom={this.state.selectedClassroom}
              selectedMonth={this.state.selectedMonth}
              student={this.state.students?.find(
                (s) => s.admissionId == this.state.studentId
              )}
              subjects={this.state.subjects ? this.state.subjects : []}
              studentAttendanceRecord={
                this.state.studentData ? this.state.studentData : []
              }
              showBtn={this.state.showBtn}
            />
          </div>
        </div>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Attendance"
              moduleName="Student Attendance"
              header="Student Attendance"
              activityName="insert-attendance"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}
export default AttendanceForStudent;
