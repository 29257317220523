import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import CustomCollapse from "../../UI/CustomCollapse";
import CollapsiblePhaseSemester from "./CollpasiblePhaseSemester";

class ListCourseModule extends Component {
  state = {
    programs: [],
    programSpinner: false,
    programPhaseSemester: [],
  };

  componentDidMount() {
    this.getProgramList();
  }

  getProgramList = () => {
    this.setState({ programSpinner: true }, function () {
      axiosPost(
        URL.getProgramList,
        {},
        (res) => {
          if (res.status === 200) {
            let programs = res.data.data;
            if (programs.length > 0) {
              programs.forEach((el, idx) => {
                if (idx !== 0) {
                  el.isOpen = false;
                } else {
                  el.isOpen = true;
                  this.getPhaseTermList(el);
                }
              });
            }
            this.setState({ programs, programSpinner: false });
          }
        },
        (error) => {
          this.setState({ programSpinner: false });
        }
      );
    });
  };

  toggleCollapsible = (idx) => {
    let programs = [...this.state.programs];
    if (!programs[idx].isOpen) {
      programs.forEach((el) => {
        el.isOpen = false;
      });
      programs[idx].isOpen = true;
      this.getPhaseTermList(programs[idx]);
    } else {
      programs.forEach((el) => {
        el.isOpen = false;
      });
    }
    this.setState({ programs });
  };

  getPhaseTermList = (param) => {
    let data = {
      id: param.id,
    };
    this.setState({ subjectSpinner: true }, function () {
      axiosPost(
        URL.getProgramSubjects,
        data,
        (res) => {
          if (res.status === 200) {
            let data = res.data.data;
            if (data.coursePhases !== null) {
              data.coursePhases.forEach((el, idx) => {
                if (idx !== 0) {
                  el.isOpen = false;
                } else {
                  el.isOpen = true;
                }
              });
            }
            this.setState({
              programPhaseSemester: data,
              subjectSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({ subjectSpinner: false });
        }
      );
    });
  };

  togglePhaseDetails = (e, idx) => {
    let programPhaseSemester = this.state.programPhaseSemester;
    if (programPhaseSemester.coursePhases.length > 0) {
      if (programPhaseSemester.coursePhases[idx].isOpen) {
        programPhaseSemester.coursePhases.map((ppS, idx) => {
          ppS.isOpen = false;
        });
      } else {
        programPhaseSemester.coursePhases.map((ppS, idx) => {
          ppS.isOpen = false;
        });
        programPhaseSemester.coursePhases[idx].isOpen = true;
      }
    }
    this.setState({ programPhaseSemester });
  };

  listBody = () => {
    return (
      <CollapsiblePhaseSemester
        programPhaseSemester={this.state.programPhaseSemester}
        togglePhaseDetails={this.togglePhaseDetails}
        type={this.props.type}
      ></CollapsiblePhaseSemester>
    );
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "80.5vh" }}>
        {this.state.programs.length > 0 ? (
          this.state.programs.map((program, idx) => {
            return (
              <div key={idx} className="mb-2">
                <div
                  className="tt-group-header mb-0"
                  style={{ borderRadius: 0 }}
                >
                  <div className="row">
                    <div className="col">{program.name}</div>
                    <div
                      className="col-md-1 text-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.toggleCollapsible(idx)}
                    >
                      <FontAwesomeIcon
                        icon={program.isOpen ? faAngleUp : faAngleDown}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                </div>
                <CustomCollapse
                  isOpen={program.isOpen}
                  collapsibleBody={this.listBody()}
                ></CustomCollapse>
              </div>
            );
          })
        ) : (
          <div className="text-center p-4">No programs created</div>
        )}
      </div>
    );
  }
}

export default ListCourseModule;
