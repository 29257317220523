import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import ModalWindow from "../UI/ModalWindow";
import { Spinner } from "react-mdl";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import AssignmentDetails from "../Assignment/AssignmentDetails";
// import StudentSummary from "../Attendance/StudentSummary";
import StudentAttendanceDetails from "./StudentAttendaceDetails";
import {
  userRole,
  userRoleCode,
  URL,
  draggable,
  checkRolePermissions,
} from "../../utils/Constants";
// import { userRole } from "../../utils/Constants";

class Information extends Component {
  state = {
    customTableFieldGroups: [],
    showAssigned: true,
    viewModal: false,
    spinner: false,
    assignments: [],
    assignment: null,
    assignmentStudent: [],
    submissionModal: false,
    selectedId: "",
    currentStudentStatus: "",
    currentAdmissionId: "",
    currentAssignmentId: "",
    point: "",
  };
  componentDidMount() {
    this.getAssignments();

    //People type parameter
    let params = {
      id: this.props.profilePeopleId,
    };
    axiosPost(URL.getCrucialProfilePeopleInfo, params, (response) => {
      if (response.status == 200) {
        this.setState({ customTableFieldGroups: response.data.data });
      }
    });
  }

  getAssignments = () => {
    this.setState({ spinner: true });
    axiosPost(
      URL.selectAssignment,
      { isAssigned: this.state.showAssigned },
      (response) => {
        if (response.status === 200) {
          this.setState({ assignments: response.data.data, spinner: false });
        }
      }
    );
  };

  handleViewDetails = (id, idx) => {
    //call api to update view
    let params = {
      assignmentId: id,
    };
    this.setState({ assignment: this.state.assignments[idx] });
    axiosPost(URL.viewAssignment, params, (response) => {
      this.getStudent(id);
    });

    this.toggleViewModal();
  };

  getStudent = (id) => {
    this.setState({ assignmentStudent: [] });
    let params = {
      assignmentId: id,
    };

    axiosPost(URL.selectAssignmentStudents, params, (response) => {
      if (response.status == 200) {
        this.setState({ assignmentStudent: response.data.data });
      }
    });
  };

  toggleViewModal = () => {
    this.setState({ viewModal: !this.state.viewModal });
  };
  handleSubmissionModal = (id) => {
    this.setState({
      submissionModal: !this.state.submissionModal,
      selectedId: id,
    });
  };

  handleViewSubmittedResources = (
    assignmentId,
    studentId,
    studentStatus,
    point
  ) => {
    this.setState({
      currentStudentStatus: studentStatus,
      currentAdmissionId: studentId,
      currentAssignmentId: assignmentId,
      point: point,
    });
    let params = {
      assignmentId: assignmentId,
      admissionId: studentId,
    };
    axiosPost(URL.viewStudentAssignmentResources, params, (response) => {
      if (response.status === 200) {
        this.setState({ submittedResources: response.data.data });
        this.toggleSubmittedResourceModal();
      }
    });
  };

  render() {
    return (
      <div
        className="container-fluid"
        style={{ maxHeight: "88.5vh", border: "none" }}
      >
        <div className="row">
          <div className="col-md-5">
            {this.state.customTableFieldGroups.length > 0
              ? this.state.customTableFieldGroups.map((group, idx) => {
                  return (
                    <>
                      <div className="col-md detailStaffStudent">
                        <table className="table table-bordered table-striped">
                          <thead className="tt-group-header">
                            <tr>
                              <th colSpan={2}>{group.name}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group.customTableFieldValues.length > 0
                              ? group.customTableFieldValues.map(
                                  (field, idx) => {
                                    return (
                                      <tr>
                                        <td width="40%">
                                          <strong>
                                            {field.customTableFieldName}
                                          </strong>
                                        </td>
                                        <td>{field.value}</td>
                                      </tr>
                                    );
                                  }
                                )
                              : "No Information"}
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })
              : "No Information"}
          </div>

          {/* {userRole.toString() === "RL_STD" ? ( */}
          <div className="col-md-7">
            <div className="col-md detailStaffStudent text-center ">
              <div className="tt-group-header"> Attendance</div>

              <div>
                <StudentAttendanceDetails />
              </div>
            </div>
            <br></br>
            <div className="col-md detailStaffStudent text-center ">
              <div className="tt-group-header ">Assignment</div>
              <div className="tt-profile-assignment">
                <table className="table table-striped text-left">
                  <tbody>
                    {this.state.assignments.length > 0 ? (
                      this.state.assignments.map((assignment, idx) => {
                        return (
                          <tr
                            key={idx}
                            onClick={() =>
                              this.handleViewDetails(assignment.id, idx)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <td>
                              <strong>{assignment.title} </strong>( Submission
                              Date :{" "}
                              {moment(assignment.submissionDate).format("LLL")}{" "}
                              )
                              <br />
                              <Chip
                                color="primary"
                                className="override-chip"
                                label={
                                  assignment.yearLevelName +
                                  "-" +
                                  assignment.classroomName +
                                  "-" +
                                  assignment.subjectName
                                }
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center">
                          {this.state.spinner ? (
                            <Spinner color="white"></Spinner>
                          ) : (
                            "No assignments"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* ) : null} */}
        </div>
        <ModalWindow
          modal={this.state.viewModal}
          toggleModal={this.toggleViewModal}
          modalHeader="View Assignment Details"
          fullWidth={true}
          modalBody={
            <AssignmentDetails
              propFromStudent="stdInformation"
              assignment={this.state.assignment}
              assignmentStudent={this.state.assignmentStudent}
              handleSubmissionModal={this.handleSubmissionModal}
              handleViewSubmittedResources={this.handleViewSubmittedResources}
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}
export default Information;
