/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, GeneralAssessmentSettings, render the Model for General Assessment Settings in the
 *              Record Setup in the Primary ARR
 */

import React, { Component } from "react";
import GeneralAssessmentSettingsModal from "./GeneralAssessmentSettingsModal";
import {
  displayConfirmDeleteAlert,
  displaySuccessAlert,
  showErroMessage,
} from "../../../../utils/Utils";
import swal from "sweetalert";
import { URL, draggable, scaleTypeCode } from "../../../../utils/Constants";
import { axiosPost } from "../../../../utils/AxiosApi";
import { connect } from "react-redux";
import { getGeneralAssessmentList } from "../../../../ducks/PrimaryARRDucks";

class GeneralAssessmentSettings extends Component {
  state = {
    generalAssessmentSettingsModal: false,
    modalHeader: "General Assessment Setting",
    minLength: 100,
    maxLength: 500,
    commentName: "",
    commentTextType: "words",
    rubric: "",
    description: "",
    commentor: "",
    comments: [],
    assessmentGeneralCustomAssessmentRubricsConfigurations: [
      {
        rubricsName: "",
        description: "",
      },
    ],
    customAssessmentHeaderName: "",
    levelScales: [],
    assessor: "",
    assessmentType: "overallAssessment",
    overallAssessmentData: [],
    scaleValues: [],
    selectedScaleLevels: [],
    selectedLevelsForOverall: [],
    selectedLevelsForRubric: [],
    assessmentScaleIdsForOverall: [],
    assessmentScaleIdsForRubric: [],
    recruitmentList: [],
    classroomList: [],
    applyToMultiple: [],
    applySettingToOther: false,
    generalAssessmentUpdate: false,
    customRubricsName: "",
    customDescription: "",
    editGeneralAssessment: true,
    scaleName: "",
    scaleNameOverall: [],
    scaleNameRubric: [],
    roleList: [],
    selectedAssessmentIds: [],
    //edit: false
  };

  /**
   * This method toggle the General Asssessment Settings Modal
   */
  toggleGeneralAssessmentSettingsModal = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      generalAssessmentSettingsModal: !prevState.generalAssessmentSettingsModal,
    }));
  };
  handleChangeMultipleSelect = (e) => {
    var options = e.target.options;
    var selectedValues = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    return selectedValues;
  };

  handleApplyToMultiple = (e) => {
    let classroomList = [...this.state.classroomList];
    let assessmentIds = this.handleChangeMultipleSelect(e);
    let selectedAssessmentIds = [];
    assessmentIds.forEach((id) => {
      classroomList.forEach((classroom) => {
        classroom.classrooms.forEach((section) => {
          if (section.id === parseInt(id)) {
            selectedAssessmentIds.push(section);
          }
        });
      });
    });

    this.setState({
      applyToMultiple: selectedAssessmentIds,
      selectedAssessmentIds: assessmentIds,
    });
  };

  getClassroomList = () => {
    let param = {
      assessmentId: this.props.generalParams.assessmentId,
      yearLevelId: this.props.generalParams.yearLevelId,
      classroomId: this.props.generalParams.classroomId,
    };
    axiosPost(URL.selectClassroomList, param, (response) => {
      if (response.status === 200) {
        this.setState({ classroomList: response.data.data });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.generalParams !== this.props.generalParams) {
      this.getClassroomList();
    }
  }

  getAlreadyInsertedConfig = () => {
    let param = {
      assessmentId: this.props.generalParams.assessmentId,
      yearLevelId: this.props.generalParams.yearLevelId,
      classroomId: this.props.generalParams.classroomId,
    };

    axiosPost(URL.generalAssessmentSelect, param, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            overallAssessmentData:
              response.data.data
                .assessmentGeneralCustomAssessmentConfigurations,
            comments: response.data.data.assessmentGeneralCommentConfigurations,
            editGeneralAssessment: response.data.data.editable,
          },
          () => {
            let data = [...this.state.overallAssessmentData];
            // let arr = [];
            data.forEach((overall) => {
              overall.newScaleConfig = [];
              if (
                overall.assessmentGeneralCustomAssessmentScaleConfigurations
                  .length > 0
              ) {
                overall.assessmentGeneralCustomAssessmentScaleConfigurations.forEach(
                  (scale, i) => {
                    let newObj = {};
                    newObj = scale.assessmentScale;
                    overall.newScaleConfig.push(newObj);
                    // overall.newScaleConfig = arr;
                  }
                );
              }
            });
            this.setState({ overallAssessmentData: data }, () => {
              let updatedData = [...this.state.overallAssessmentData];
              updatedData.forEach((overall) => {
                // overall.rubricsScales = [];
                if (
                  overall.assessmentGeneralCustomAssessmentRubricsConfigurations
                    .length > 0
                ) {
                  overall.assessmentGeneralCustomAssessmentRubricsConfigurations.forEach(
                    (scale) => {
                      scale.rubricsScales = [];
                      scale.assessmentGeneralCustomAssessmentRubricsScaleConfigurations.forEach(
                        (scaleLevel) => {
                          let newObj = {};
                          newObj = scaleLevel.assessmentScale;
                          // overall.rubricsScales.push(newObj);
                          scale.rubricsScales.push(newObj);
                        }
                      );
                    }
                  );
                }
              });
              this.setState({ overallAssessmentData: updatedData }, () => {
                let finalData = [...this.state.overallAssessmentData];
                finalData.forEach((overall) => {
                  if (
                    overall
                      .assessmentGeneralCustomAssessmentRubricsConfigurations
                      .length > 0
                  ) {
                    overall.rubricsScales =
                      overall.assessmentGeneralCustomAssessmentRubricsConfigurations[0].rubricsScales;
                  }
                });

                this.setState({ overallAssessmentData: finalData });
              });
            });
            if (
              this.state.overallAssessmentData.length > 0 ||
              this.state.comments.length > 0
            ) {
              this.setState({ generalAssessmentUpdate: true });
            } else {
              this.setState({ generalAssessmentUpdate: false });
            }
          }
        );
      }
    });
  };

  getRoleList = () => {
    axiosPost(URL.selectGeneralAssessmentAccessorRole, {}, (response) => {
      if (response.status === 200) {
        this.setState({ roleList: response.data.data });
      }
    });
  };

  componentDidMount() {
    // $(".selectpicker").selectpicker();
    this.getAlreadyInsertedConfig();
    this.getRecruitmentList();
    this.getRoleList();
    this.getClassroomList();
    let param = {
      assessmentScaleTypeCode: scaleTypeCode.level,
      assessmentModuleCode: this.props.code,
    };
    axiosPost(URL.selectAssessmentScale, param, (response) => {
      this.setState({ levelScales: response.data.data });
    });
  }

  getRecruitmentList = () => {
    axiosPost(URL.getStaffList, {}, (response) => {
      if (response.status === 200) {
        this.setState({ recruitmentList: response.data.data });
      }
    });
  };

  addNewCommentSection = () => {
    let commenter = this.state.commentor.split(",");
    let roleList = [...this.state.roleList];
    if (commenter[1] === "role") {
      var subRoleId = commenter[0];
      var roleCode = "";
      roleList.forEach((role) => {
        if (parseInt(subRoleId) === parseInt(role.subRoleId)) {
          roleCode = role.roleCode;
        }
      });
    }
    this.setState((prevState) => ({
      comments: [
        ...prevState.comments,
        {
          comment: "",
          commentor: commenter[1] === "role" ? null : commenter[0],
          minLength: this.state.minLength,
          maxLength: this.state.maxLength,
          commentName: this.state.commentName,
          commentTextType: this.state.commentTextType,
          subRoleId: subRoleId,
          roleCode: roleCode,
          hasRole: commenter[1] === "role" ? true : false,
        },
      ],
    }));
  };

  handleCommentValueChange = (e, index) => {
    let newState = [...this.state.comments];
    const target = e.target;
    let value = target.value;
    const name = target.name;
    if (name === "commentor") {
      let roleList = [...this.state.roleList];
      let newVal = value.split(",");

      if (newVal[1] === "role") {
        let subRoleId = newVal[0];
        let roleCode = "";
        roleList.forEach((role) => {
          if (parseInt(subRoleId) === parseInt(role.id)) {
            roleCode = role.roleCode;
          }
        });
        newState[index].subRoleId = subRoleId;
        newState[index].roleCode = roleCode;
        newState[index].hasRole = true;
        newState[index].commentor = null;
      } else {
        newState[index].commentor = newVal[0];
        newState[index].hasRole = false;
        newState[index].subRoleId = null;
        newState[index].roleCode = "";
      }
    } else {
      newState[index][name] = value;
    }

    this.setState({
      comments: newState,
    });
  };

  universalhandleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value }, function () {
      if (name === "assessmentType") {
        this.setState({ scaleNameRubric: [], scaleNameOverall: [] });
      }
    });
  };

  decreaseminLengthValue = () => {
    if (this.state.minLength > 0) {
      let newVal = this.state.minLength - 1;
      this.setState({ minLength: newVal });
    }
  };

  increaseminLengthValue = () => {
    let newVal = this.state.minLength + 1;
    this.setState({ minLength: newVal });
  };

  decreasemaxLengthValue = () => {
    if (this.state.maxLength > 0) {
      let newVal = this.state.maxLength - 1;
      this.setState({ maxLength: newVal });
    }
  };

  increasemaxLengthValue = () => {
    let newVal = this.state.maxLength + 1;
    this.setState({ maxLength: newVal });
  };

  handleRemoveCommentClick = (e, id) => {
    e.preventDefault();
    displayConfirmDeleteAlert(id, this.handleDeleteComment);
  };

  handleDeleteComment = (id) => {
    let arr = [...this.state.comments];
    arr.splice(id, 1);
    this.setState({ comments: arr });
    swal({
      title: "Success",
      text: "Records deleted successfully",
      allowOutsideClick: false,
      closeOnClickOutside: false,
    });
    draggable();
  };

  handleUpdateCommentClick = (e, id) => {
    e.preventDefault();
    let comments = [...this.state.comments];
    comments.forEach((comment, i) => {
      if (id === i) {
        document.getElementById("commentLabel" + id).focus();
        comment.edit = true;
      } else {
        comment.edit = false;
      }
    });
    this.setState({ comments });
  };

  handleNestedChange = (e, idx, stateName) => {
    let newArr = [...this.state[stateName]];
    const name = e.target.name;
    newArr[idx][name] = e.target.value;

    this.setState({ [stateName]: newArr });
  };

  handleRemoveDisciplineRow = (e, mainIndex, index) => {
    e.preventDefault();
    let param = {
      mainIndex: mainIndex,
      index: index,
    };

    displayConfirmDeleteAlert(param, this.handleDeleteDisciplineRow);
  };

  handleDeleteDisciplineRow = ({ mainIndex, index }) => {
    let newArr = [...this.state.overallAssessmentData];
    newArr[
      mainIndex
    ].assessmentGeneralCustomAssessmentRubricsConfigurations.splice(index, 1);
    this.setState({ overallAssessmentData: newArr });
    swal({
      title: "Success",
      text: "Records deleted successfully",
      allowOutsideClick: false,
      closeOnClickOutside: false,
    });
    draggable();
  };

  addNewDisciplineRowHandler = (e) => {
    e.preventDefault();
    let assessor = this.state.assessor.split(",");
    let roleList = [...this.state.roleList];
    if (assessor[1] === "role") {
      var subRoleId = assessor[0];
      var roleCode = "";
      roleList.forEach((role) => {
        if (parseInt(subRoleId) === parseInt(role.subRoleId)) {
          roleCode = role.roleCode;
        }
      });
    }
    let RubricScale = this.state.selectedLevelsForRubric;
    let OverallScale = this.state.selectedLevelsForOverall;
    let scaleError = false;

    if (this.state.assessmentType === "both") {
      if (RubricScale.length === 0 || OverallScale.length === 0) {
        scaleError = true;
      }
    } else if (this.state.assessmentType === "overallAssessment") {
      if (OverallScale.length === 0) {
        scaleError = true;
      }
    } else {
      if (RubricScale.length === 0) {
        scaleError = true;
      }
    }

    if (this.state.customAssessmentHeaderName === "" || scaleError) {
      swal({
        title: "Warning",
        text:
          "Enter custom assessment header name and choose level scales for selected assessment type first",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      this.setState((prevState) => ({
        overallAssessmentData: [
          ...prevState.overallAssessmentData,
          {
            assessmentGeneralCustomAssessmentScaleConfigurations:
              this.state.assessmentType === "overallAssessment" ||
              this.state.assessmentType === "both"
                ? this.state.selectedLevelsForOverall.length === 0
                  ? this.state.levelScales
                  : this.state.selectedLevelsForOverall
                : null,
            rubricsScales: this.state.selectedLevelsForRubric,
            assessmentGeneralCustomAssessmentRubricsConfigurations: [],
            assessmentType: this.state.assessmentType,
            customAssessmentHeaderName: this.state.customAssessmentHeaderName,
            assessor: assessor[1] === "role" ? null : assessor[0],
            subRoleId: assessor[1] === "role" ? subRoleId : null,
            roleCode: assessor[1] === "role" ? roleCode : "",
            hasRole: assessor[1] === "role" ? true : false,
            addedNew: true,
          },
        ],
      }));

      this.setState({
        assessmentGeneralCustomAssessmentRubricsConfigurations: [
          ...this.state.assessmentGeneralCustomAssessmentRubricsConfigurations,
          { rubricsName: "", description: "" },
        ],
      });
    }
  };

  handleUpdateDisciplineRow = (e, id, mainIndex) => {
    e.preventDefault();
    let overallAssessmentData = [...this.state.overallAssessmentData];
    overallAssessmentData.forEach((data, index) => {
      if (mainIndex === index) {
        data.assessmentGeneralCustomAssessmentRubricsConfigurations.forEach(
          (eachRow, i) => {
            if (id === i) {
              document.getElementById("rubricsName" + mainIndex + id).focus();
            }
          }
        );
      }
    });
    this.setState({ overallAssessmentData });
  };

  multipleScaleNameChangeForOverallAssessment = (e) => {
    const levelScales = [...this.state.levelScales],
      levelScaleIds = this.handleChangeSelect(e);
    let selectedLevelsForOverall = [];
    levelScaleIds.forEach((id) => {
      levelScales.forEach((levelScale) => {
        if (levelScale.id === parseInt(id)) {
          selectedLevelsForOverall.push(levelScale);
        }
      });
    });
    this.setState({
      selectedLevelsForOverall,
      scaleNameOverall: levelScaleIds,
    });
  };

  multipleScaleNameChangeForRubric = (e) => {
    const levelScales = [...this.state.levelScales],
      levelScaleIds = this.handleChangeSelect(e);
    let selectedLevelsForRubric = [];
    levelScaleIds.forEach((id) => {
      levelScales.forEach((levelScale) => {
        if (levelScale.id === parseInt(id)) {
          selectedLevelsForRubric.push(levelScale);
        }
      });
    });
    this.setState({ selectedLevelsForRubric, scaleNameRubric: levelScaleIds });
  };

  handleChangeSelect = (e) => {
    var options = e.target.options;
    var selectedValues = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    return selectedValues;
  };

  handleDisciplineInputChange = (e, index) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  addMultipleDisciplineRow = (e, index) => {
    e.preventDefault();

    let data = [...this.state.overallAssessmentData];
    data[index]["assessmentGeneralCustomAssessmentRubricsConfigurations"].push({
      rubricsName: this.state.customRubricsName,
      description: this.state.customDescription,
      edit: false,
      rubricsScales:
        this.state.selectedLevelsForRubric.length > 0
          ? this.state.selectedLevelsForRubric
          : data[index].rubricsScales,
    });

    this.setState(
      {
        overallAssessmentData: data,
      },
      () => {
        this.setState({ customRubricsName: "", customDescription: "" });
      }
    );
  };

  handleDeleteCustomOverallAssessmentRow = (e, id) => {
    // console.log(id);
    let overallAssessmentData = [...this.state.overallAssessmentData];
    overallAssessmentData.splice(id, 1);
    this.setState({ overallAssessmentData });
  };

  handleUpdateCustomHeader = (e, id) => {
    let overallAssessmentData = [...this.state.overallAssessmentData];
    overallAssessmentData.forEach((data, i) => {
      if (id === i) {
        document.getElementById("customHeader" + id).focus();
      }
    });
  };
  handleDeleteCustomHeader = (e, id) => {
    e.preventDefault();
    displayConfirmDeleteAlert(id, this.handleRemoveCustomHeader);
  };

  handleRemoveCustomHeader = (id) => {
    let overallAssessmentData = [...this.state.overallAssessmentData];
    overallAssessmentData.splice(id, 1);
    this.setState({ overallAssessmentData });
  };

  handleDeleteScaleTableData = (e, mainIndex, index) => {
    let overallAssessmentData = [...this.state.overallAssessmentData];
    overallAssessmentData[
      mainIndex
    ].assessmentGeneralCustomAssessmentScaleConfigurations.splice(index, 1);
    this.setState({ overallAssessmentData });
  };
  handleDeleteScaleTableDataForRubric = (e, mainIndex, index) => {
    let overallAssessmentData = [...this.state.overallAssessmentData];
    overallAssessmentData[mainIndex].rubricsScales.splice(index, 1);
    this.setState({ overallAssessmentData });
  };

  handleCustomDisciplineRowChange = (e, mainIndex, index) => {
    let overallAssessmentData = [...this.state.overallAssessmentData];
    const name = e.target.name;
    overallAssessmentData[mainIndex][
      "assessmentGeneralCustomAssessmentRubricsConfigurations"
    ][index][name] = e.target.value;

    this.setState({ overallAssessmentData });
  };

  handleGeneralAssessmentSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.overallAssessmentData.length > 0 ||
      this.state.comments.length > 0
    ) {
      let applyToMultiple = [...this.state.applyToMultiple];

      var assessmentIds = [
        {
          assessmentId: this.props.generalParams.assessmentId,
          yearLevelId: this.props.generalParams.yearLevelId,
          classroomId: this.props.generalParams.classroomId,
        },
      ];

      if (applyToMultiple.length > 0) {
        applyToMultiple.forEach((classes) => {
          let newObj = {};
          newObj.assessmentId = this.props.generalParams.assessmentId;
          newObj.yearLevelId = classes.yearLevelId;
          newObj.classroomId = classes.id;
          assessmentIds.push(newObj);
        });
      }

      let overallAssessmentData = [...this.state.overallAssessmentData];
      overallAssessmentData.forEach((overall) => {
        overall.customAssessmentScales =
          overall.assessmentGeneralCustomAssessmentScaleConfigurations;
      });
      this.setState({ overallAssessmentData }, () => {
        let param = {
          assessmentIds: assessmentIds,
          assessmentGeneralCommentConfigurations: this.state.comments,
          assessmentGeneralCustomAssessmentConfigurations: this.state
            .overallAssessmentData,
        };

        axiosPost(URL.generalAssessmentInsert, param, (response) => {
          if (response.status === 200) {
            displaySuccessAlert(response);
            this.props.toggleGeneralAssessmentSettingModal();
            this.props.getGeneralAssessmentList(this.props.code);
          } else {
            showErroMessage(response);
          }
        });
      });
    } else {
      swal({
        title: "Error",
        text: "Please add configuration first!!!",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  handleMinLengthChange = (val) => {
    this.setState({ minLength: val });
  };

  handleMaxLengthChange = (val) => {
    this.setState({ maxLength: val });
  };

  handleApplySettingToOther = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, function () {
      if (name === "applySettingToOther") {
        this.setState({ applyToMultiple: [], selectedAssessmentIds: [] });
      }
    });
  };

  handleGeneralAssessmentUpdateSubmit = (e) => {
    e.preventDefault();

    var assessmentIds = [
      {
        assessmentId: this.props.generalParams.assessmentId,
        yearLevelId: this.props.generalParams.yearLevelId,
        classroomId: this.props.generalParams.classroomId,
      },
    ];

    let overallAssessmentData = [...this.state.overallAssessmentData];

    overallAssessmentData.forEach((overall) => {
      if (overall.addedNew) {
        overall.customAssessmentScales =
          overall.assessmentGeneralCustomAssessmentScaleConfigurations;
      } else {
        overall.customAssessmentScales = overall.newScaleConfig;
      }
    });
    this.setState({ overallAssessmentData }, () => {
      let param = {
        assessmentId: this.props.generalParams.assessmentId,
        yearLevelId: this.props.generalParams.yearLevelId,
        classroomId: this.props.generalParams.classroomId,
        assessmentIds: assessmentIds,
        assessmentGeneralCommentConfigurations: this.state.comments,
        assessmentGeneralCustomAssessmentConfigurations: this.state
          .overallAssessmentData,
      };

      axiosPost(URL.generalAssessmentConfigUpdate, param, (response) => {
        if (response.status === 200) {
          displaySuccessAlert(response);
          this.props.toggleGeneralAssessmentSettingModal();
          this.props.getGeneralAssessmentList(this.props.code);
        } else {
          showErroMessage(response);
        }
      });
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "86.8vh" }}>
        <GeneralAssessmentSettingsModal
          roleList={this.state.roleList}
          editGeneralAssessment={this.state.editGeneralAssessment}
          customDescription={this.state.customDescription}
          customRubricsName={this.state.customRubricsName}
          generalAssessmentUpdate={this.state.generalAssessmentUpdate}
          applySettingToOther={this.state.applySettingToOther}
          handleApplySettingToOther={this.handleApplySettingToOther}
          handleMinLengthChange={this.handleMinLengthChange}
          handleMaxLengthChange={this.handleMaxLengthChange}
          assessmentName={this.props.assessmentName}
          sectionName={this.props.sectionName}
          className={this.props.className}
          addNewCommentSection={this.addNewCommentSection}
          comments={this.state.comments}
          handleCommentValueChange={this.handleCommentValueChange}
          minLength={this.state.minLength}
          maxLength={this.state.maxLength}
          decreaseminLengthValue={this.decreaseminLengthValue}
          increaseminLengthValue={this.increaseminLengthValue}
          decreasemaxLengthValue={this.decreasemaxLengthValue}
          increasemaxLengthValue={this.increasemaxLengthValue}
          handleRemoveCommentClick={this.handleRemoveCommentClick}
          universalhandleChange={this.universalhandleChange}
          commentName={this.state.commentName}
          handleUpdateCommentClick={this.handleUpdateCommentClick}
          handleNestedChange={this.handleNestedChange}
          handleWordCharacterChange={this.universalhandleChange}
          commentTextType={this.state.commentTextType}
          handlecommentorChange={this.handleCommentValueChange}
          addMultipleDisciplineRow={this.addMultipleDisciplineRow}
          assessmentGeneralCustomAssessmentRubricsConfigurations={
            this.state.assessmentGeneralCustomAssessmentRubricsConfigurations
          }
          universalHandleChange={this.universalhandleChange}
          rubricsName={this.state.rubricsName}
          description={this.state.description}
          handleRemoveDisciplineRow={this.handleRemoveDisciplineRow}
          handleUpdateDisciplineRow={this.handleUpdateDisciplineRow}
          levelScales={this.state.levelScales}
          commentor={this.state.commentor}
          customAssessmentHeaderName={this.state.customAssessmentHeaderName}
          assessmentType={this.state.assessmentType}
          addNewDisciplineRowHandler={this.addNewDisciplineRowHandler}
          overallAssessmentData={this.state.overallAssessmentData}
          scaleName={this.state.scaleName}
          handleDisciplineInputChange={this.handleDisciplineInputChange}
          selectedScaleLevels={this.state.selectedScaleLevels}
          handleDeleteCustomOverallAssessmentRow={
            this.handleDeleteCustomOverallAssessmentRow
          }
          multipleScaleNameChangeForOverallAssessment={
            this.multipleScaleNameChangeForOverallAssessment
          }
          multipleScaleNameChangeForRubric={
            this.multipleScaleNameChangeForRubric
          }
          selectedLevelsForRubric={this.state.selectedLevelsForRubric}
          selectedLevelsForOverall={this.state.selectedLevelsForOverall}
          assessor={this.state.assessor}
          handleUpdateCustomHeader={this.handleUpdateCustomHeader}
          handleDeleteCustomHeader={this.handleDeleteCustomHeader}
          handleDeleteScaleTableData={this.handleDeleteScaleTableData}
          handleCustomDisciplineRowChange={this.handleCustomDisciplineRowChange}
          handleGeneralAssessmentSubmit={this.handleGeneralAssessmentSubmit}
          handleDeleteScaleTableDataForRubric={
            this.handleDeleteScaleTableDataForRubric
          }
          recruitmentList={this.state.recruitmentList}
          classroomList={this.state.classroomList}
          applyToMultiple={this.state.applyToMultiple}
          handleApplyToMultiple={this.handleApplyToMultiple}
          handleGeneralAssessmentUpdateSubmit={
            this.handleGeneralAssessmentUpdateSubmit
          }
          scaleNameOverall={this.state.scaleNameOverall}
          scaleNameRubric={this.state.scaleNameRubric}
          selectedAssessmentIds={this.state.selectedAssessmentIds}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionsToProps = {
  getGeneralAssessmentList: getGeneralAssessmentList,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(GeneralAssessmentSettings);
