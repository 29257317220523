import React, { Component } from "react";
import CustomCollapse from "../../../UI/CustomCollapse";
import CollapisbleBody from "./CollapsibleBody";
import {
  checkRolePermissions,
  checkPermissionForSubstituteTeachers,
} from "../../../../utils/Constants";
import ModalWindow from "../../../UI/ModalWindow";
import AssignStudentModalNewBody from "./AssignStudentModalNewBody";
import SubstituteTeacherPermissionTab from "./SubstituteTeacherPermissionTab";
import Chip from "@material-ui/core/Chip";

class SectionCollapsible extends Component {
  state = {
    assignStudentModal: false,
    substituteRolesModal: false,
    permissionEditSection: false,
    permissionDeleteSection: false,
    permissionAssignStudent: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditSection: checkRolePermissions("edit-section", "activity"),
      permissionDeleteSection: checkRolePermissions(
        "delete-section",
        "activity"
      ),
      permissionAssignStudent: checkRolePermissions(
        "assign-student-to-section",
        "activity"
      ),
    });
  };

  toggleAssignStudentModal = () => {
    this.setState({
      assignStudentModal: !this.state.assignStudentModal,
    });
  };

  toggleSubstituteRolesModal = () => {
    this.setState({
      substituteRolesModal: !this.state.substituteRolesModal,
    });
  };

  render() {
    const assessmentModuleCode = this.props.assessmentModuleCode;
    const peopleId = this.props.classroom?.peopleId;
    return (
      <>
        <tr key={this.props.classroomIdx}>
          <td width="20%">
            {this.props.classroom.name}
            <div classsName="text-muted">
              <Chip
                // color={"default"}
                color="primary"
                className="override-chip"
                label={this.props.classroom.homeRoomTeacher}
              />
            </div>
          </td>
          <td width="40%">
            {this.props.classroom.subjectClassroomTeachers
              ? this.props.classroom.subjectClassroomTeachers.map((el, idx) => {
                  return <div key={idx}>{el.name}</div>;
                })
              : null}
          </td>
          <td width="40%">
            {this.props.classroom.buildingInformationDto ? (
              <div>
                {`${this.props.classroom.buildingInformationDto.roomName}(${
                  this.props.classroom.buildingInformationDto.number
                })-${
                  this.props.classroom.buildingInformationDto.buildingName
                } ${
                  this.props.classroom.buildingInformationDto
                    .sectionAliasName === ""
                    ? ""
                    : "," +
                      this.props.classroom.buildingInformationDto
                        .sectionAliasName +
                      this.props.classroom.buildingInformationDto
                        .buildingSectionName
                }, ${this.props.classroom.buildingInformationDto.floorName}`}
              </div>
            ) : null}
          </td>
          <td width="20%">
            {this.state.permissionEditSection ? (
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={(e) => {
                  this.props.handleSectionLevelUpdateBtnClick(
                    this.props.index,
                    this.props.yearLevelIdx,
                    this.props.classroomIdx
                  );
                }}
              >
                Update
              </button>
            ) : null}
            {this.state.permissionDeleteSection ? (
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={(e) =>
                  this.props.deleteRecods(e, this.props.classroom.id)
                }
              >
                Delete
              </button>
            ) : null}
            {this.state.permissionAssignStudent ? (
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.toggleAssignStudentModal}
              >
                Assign Students
              </button>
            ) : null}
            {checkPermissionForSubstituteTeachers(
              peopleId,
              assessmentModuleCode,
              this.props.assessmentModules
            ) ? (
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.toggleSubstituteRolesModal}
              >
                Substitute Roles
              </button>
            ) : null}
          </td>
          {/* // ) : null} */}
        </tr>
        <span className="tt-divRow">
          <td colSpan="5">
            <CustomCollapse
              isOpen={this.props.classroom.isOpen}
              collapsibleBody={
                <CollapisbleBody
                  classroom={this.props.classroom}
                  handleChange={this.props.handleChange}
                  handleSectionLevelCancelBtnClick={
                    this.props.handleSectionLevelCancelBtnClick
                  }
                  updateTuple={this.props.updateTuple}
                  {...this.props}
                  classroomIdx={this.props.classroomIdx}
                  yearLevelId={this.props.yearLevelId}
                  yearLevelIdx={this.props.yearLevelIdx}
                  index={this.props.index}
                />
              }
            />
          </td>
        </span>

        <ModalWindow
          fullWidth={true}
          modal={this.state.assignStudentModal}
          toggleModal={this.toggleAssignStudentModal}
          modalHeader={`${this.props.yearLevel} -> ${
            localStorage.getItem("classroom")
              ? localStorage.getItem("classroom")
              : "Section"
          } ${this.props.classroom.name}`}
          modalBody={
            <AssignStudentModalNewBody
              classroom={this.props.classroom}
              yearLevelId={this.props.yearLevelId}
              yearLevel={this.props.yearLevel}
              assessmentModuleCode={this.props.assessmentModuleCode}
            />
          }
        ></ModalWindow>
        <ModalWindow
          fullWidth={true}
          modal={this.state.substituteRolesModal}
          toggleModal={this.toggleSubstituteRolesModal}
          modalHeader={"Substitute Teacher Role Permission"}
          modalBody={
            <SubstituteTeacherPermissionTab
              classroomId={this.props.classroom.id}
              yearLevelId={this.props.yearLevelId}
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

export default SectionCollapsible;
