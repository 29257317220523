import React, { Component } from "react";
import JoditEditor from "jodit-react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: [
    "file",
    "about",
    "copyformat",
    "print",
    "image",
    "table",
    "video",
    "source",
  ],
  showTooltip: true,
  uploader: {
    insertImageAsBase64URI: true,
    // url: "www.google.com"
  },
};

class CreateMCQExplanation extends Component {
  state = {};
  render() {
    return (
      <div>
        <JoditEditor
          onChange={(e) => this.props.handleExplanation(e, this.props.idx)}
          value={this.props.explanation}
          config={config}
        />
        <div className="tt-addExplanation ml-0 mr-0 mb-0 text-right">
          <button
            className="tt-button tt-button-primary mr-3"
            onClick={this.props.saveExplanation}
          >
            Save
          </button>
          <button
            className="tt-button tt-button-primary"
            onClick={(e) => this.props.closeExplanation(e, this.props.idx)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

export default CreateMCQExplanation;
