import React, { Component } from "react";
import "../../assets/scss/navbar.scss";
import "../../assets/scss/ResponsiveScss/responsiveNavbarPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from "../../utils/History";
import ModalWindow from "../UI/ModalWindow";
import $ from "jquery";
import axios from "axios";

import {
  faBars,
  faUser,
  faCog,
  faSignOutAlt,
  faServer,
  faDotCircle,
  faClock,
  faUserCog,
  faUserLock,
  faGraduationCap,
  faCheckCircle,
  faPeopleArrows,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { axiosPost } from "../../utils/AxiosApi";
import {
  URL,
  profilePeopleId,
  familyId,
  schoolSetup,
  academicYears,
  getLinkedAccount,
  schoolProfileGroup,
} from "../../utils/Constants";
import { Link } from "react-router-dom";
import ChangeAcademicYear from "../AcademicYearHistory/ChangeAcademicYear";
import StaffModalData from "../AcademicAdministration/EducationCommunity/Staff/StaffDataModal";
import ModalBody from "../Admission/AdmissionList/ModalBody";
import ChangePassword from "../Login/ChangePassword";
import {
  userRole,
  userRoleCode,
  rememberMe,
  checkDevAdmin,
} from "./../../utils/Constants";
import AboutUs from "./AboutUs";
import swal from "sweetalert";
import CryptoJS from "crypto-js";
import { displayErrorAlert } from "../../utils/Utils";

// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Navbar extends Component {
  state = {
    schoolName: "",
    acYrModal: false,
    admin: false,
    staffModal: false,
    staffModalData: "",
    isStaff: false,
    admissionModal: false,
    admissionListBySelect: "",
    studentContactBySelect: "",
    passwordModal: false,
    aboutUsModal: false,
    acronym: "",
    linkedAccount: [],
    linkedAccountActive: false,
  };

  componentDidMount() {
    this.getSchoolInfo();
    this.getUserInfo();
    this.setLinkedAccount();
  }

  toggleStaffModal = () => {
    if (this.state.isStaff) {
      this.setState({
        staffModal: !this.state.staffModal,
      });
    } else {
      this.setState({
        admissionModal: !this.state.admissionModal,
      });
    }
  };

  toggleAdmissionModal = () => {
    this.setState({
      admissionModal: !this.state.admissionModal,
    });
  };

  getAdmissionListBySelect = () => {
    axiosPost(
      URL.selectByKeyAdmissionList,
      {
        peopleId: this.props.peopleId
          ? this.props.peopleId
          : parseInt(profilePeopleId),
      },
      (response) => {
        if (response.status === 200) {
          const admissionListBySelect =
              response.data.data.people.customTableFieldValues,
            studentContactBySelect = response.data.data.studentContacts;

          this.setState({ admissionListBySelect, studentContactBySelect });
        }
      }
    );
  };

  getUserInfo() {
    let data = {
      peopleId: this.props.peopleId
        ? this.props.peopleId
        : parseInt(profilePeopleId),
    };
    if (userRole !== userRoleCode.roleAdmin) {
      axiosPost(URL.admissionList, data, (response) => {
        if (
          response.status === 200 &&
          response.data.data !== null &&
          response.data.data.length > 0
        ) {
          this.setState({
            admissionModalData: response.data.data[0],
            isStaff: false,
            admin: false,
          });

          this.getAdmissionListBySelect();
        } else {
          axiosPost(URL.getStaffList, data, (response) => {
            if (
              response.status === 200 &&
              response.data.data !== null &&
              response.data.data.length > 0
            ) {
              this.setState({
                staffModalData: response.data.data,
                isStaff: true,
                admin: false,
              });
            } else {
              this.setState({ admin: true, isStaff: false });
            }
          });
        }
      });
    } else {
      this.setState({ admin: true, isStaff: false });
    }
  }

  staffDataModal = () => {
    return (
      <StaffModalData
        staffModalData={this.state.staffModalData[0]}
      ></StaffModalData>
    );
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          logoSpinner: false,
          schoolName: response.data.data[0].name,
          acronym: response.data.data[0].acronym,
          schoolImage: response.data.data[0].imageName
            ? URL.imageSelectURL + response.data.data[0].imageName
            : null,
          logoLocation: response.data.data[0].logoLocation,
        });
      }
    });
  };

  handleLogout = () => {
    this.deleteUserAcademicYear();
  };

  clearLocalStorage = () => {
    let keysToRemove = [
      "token",
      "refreshToken",
      "role",
      "peopleId",
      "mainUserId",
      "isCoordinator",
      "isHomeRoomTeacher",
      "peopleName",
      "educationLevelDivision",
      "yearLevel",
      "classroom",
      "profilePeopleId",
      "familyId",
      "permissions",
      "widgets",
      "subRoles",
      "schoolSetup",
      "academicYears",
      "associatedUsers",
      "substituteTeacherRolePermissions",
      "isFirstLogin",
      "academicYearId",
    ];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
    if (rememberMe === "false") {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    }
    history.push(`${process.env.PUBLIC_URL}/`);
  };

  deleteUserAcademicYear = () => {
    axiosPost(URL.deleteUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        axios
          .post(URL.logout, {})
          .then((res) => {
            if (res.status === 200) {
              this.clearLocalStorage();
            }
          })
          .catch((err) => {
            displayErrorAlert(err);
          });
      }
    });
  };

  toggleChangeAcYrModal = () => {
    this.setState({ acYrModal: !this.state.acYrModal });
  };

  changeAcademicYear = () => {
    this.toggleChangeAcYrModal();
  };

  togglePasswordModal = () => {
    this.setState({ passwordModal: !this.state.passwordModal });
  };

  openAboutUsModal = () => {
    this.setState({ aboutUsModal: !this.state.aboutUsModal }, function () {
      if (this.state.aboutUsModal) {
        var self = this;
        $(document).keypress(function (event) {
          var keycode = event.keyCode ? event.keyCode : event.which;
          if (keycode == "13") {
            self.setState({ aboutUsModal: false });
          }
        });
      }
    });
  };

  setLinkedAccount = () => {
    let linkedAccount = getLinkedAccount();
    this.setState({ linkedAccount });
  };

  openLinkedAccountSubMenu = () => {
    this.setState({ linkedAccountActive: !this.state.linkedAccountActive });
  };

  handleAssociateUer = (acc, id) => {
    axiosPost(
      URL.getAssociatedLink,
      {
        username: acc.username,
      },
      (response) => {
        if (response.status === 200) {
          if (
            response.data.data.message === null ||
            response.data.data.message === ""
          ) {
            localStorage.setItem("token", response.data.data.access_token);
            localStorage.setItem("schoolSetup", response.data.data.schoolSetup);
            localStorage.setItem(
              "academicYears",
              response.data.data.academicYears
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.refresh_token
            );

            localStorage.setItem(
              "isFirstLogin",
              response.data.data.isFirstLogin
            );
            localStorage.setItem(
              "widgets",
              CryptoJS.AES.encrypt(
                JSON.stringify(
                  response.data.data.academicPermissionDto.widgets
                ),
                "saurav_imagine_sys"
              )
            );
            localStorage.setItem(
              "substituteTeacherRolePermissions",
              CryptoJS.AES.encrypt(
                JSON.stringify(
                  response.data.data.substituteTeacherRolePermissions
                ),
                "saurav_imagine_sys"
              )
            );

            localStorage.setItem(
              "academicYearId",
              response.data.data.academicPermissionDto.academicYearId
            );
            localStorage.setItem(
              "subRoles",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data.subRoles),
                "saurav_imagine_sys"
              )
            );
            if (response.data.data.associatedUsers) {
              localStorage.setItem(
                "associatedUsers",
                CryptoJS.AES.encrypt(
                  JSON.stringify(response.data.data.associatedUsers),
                  "saurav_imagine_sys"
                )
              );
            }

            localStorage.setItem(
              "role",
              CryptoJS.AES.encrypt(
                response.data.data.userRoleCode,
                "saurav_imagine_sys"
              ).toString()
            );
            localStorage.setItem("peopleId", response.data.data.peopleId);
            localStorage.setItem(
              "profilePeopleId",
              response.data.data.peopleId
            );
            localStorage.setItem("familyId", response.data.data.familyId);
            localStorage.setItem("mainUserId", response.data.data.userId);
            if (this.state.rememberMe) {
              localStorage.setItem(
                "username",
                CryptoJS.AES.encrypt(
                  this.state.username,
                  "saurav_imagine_sys"
                ).toString()
              );
              localStorage.setItem(
                "password",
                CryptoJS.AES.encrypt(
                  this.state.password,
                  "saurav_imagine_sys"
                ).toString()
              );
            }
            //this.getRecruitmentIdPromise(response.data.peopleId);
            this.setState({ showSpinner: false });
            if (response.data.data.userRoleCode === "RL_STC") {
              history.push(
                `${process.env.PUBLIC_URL}/familyPage/${response.data.data.familyId}`
              );
            } else {
              response.data.data.schoolSetup
                ? response.data.data.academicYears
                  ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                  : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
            }
          } else {
            swal({
              title: "Warning",
              text: response.data.message,
              allowOutsideClick: false,
              closeOnClickOutside: false,
              buttons: true,
              dangerMode: true,
            }).then((deleteConfirm) => {
              if (deleteConfirm) {
                localStorage.clear();
              } else {
              }
            });
          }
        }
      },
      (err) => {
        swal({
          title: "Warning",
          text: err.response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
          buttons: true,
          dangerMode: true,
        }).then((deleteConfirm) => {
          if (deleteConfirm) {
            localStorage.clear();
          } else {
          }
        });
      }
    );
  };
  render() {
    return (
      <div
        className={
          this.props.nonSystemSetup
            ? "container-fluid tt-nav navHeight"
            : "container-fluid tt-nav"
        }
      >
        <div className="row">
          <div className="col">
            <div className="d-flex tt-nav-div">
              <div
                className={
                  this.state.logoLocation
                    ? "tt-nav-schoolname"
                    : "tt-nav-schoolname landscape"
                }
              >
                {/* {this.props.nonSystemSetup ? (
                  ""
                ) : this.state.logoSpinner ? (
                  <Spinner color="success" />
                ) : this.state?.schoolImage !== null ? (
                  <img
                    src={this.state.schoolImage}
                    alt="Not Loading"
                    className="img-fluid"
                    onError={(e) => this.handleError(e)}
                  />
                ) : (
                  ""
                )} */}
                <p
                  className="tt-navbar-heading pl-4"
                  style={
                    this.state.schoolName === "" ? { height: "26px" } : null
                  }
                >
                  {this.props.title
                    ? this.props.title
                    : `${this.state.schoolName}`}
                </p>
                <p className="tt-navbar-heading-responsive">
                  {this.props.title
                    ? this.props.title.split(" ")[0]
                    : this.state.acronym && this.state.acronym !== ""
                    ? this.state.acronym
                    : this.state.schoolName.split(" ")[0]}
                </p>
              </div>
              <div
                className={
                  this.props.nonSystemSetup
                    ? "tt-menu-bar menuBarHeight"
                    : "tt-menu-bar"
                }
              >
                <Dropdown
                  className="dropdown-trigger"
                  isOpen={this.props.dropdownOpen}
                  toggle={this.props.toggleMenu}
                >
                  <DropdownToggle>
                    <FontAwesomeIcon icon={faBars} />
                  </DropdownToggle>
                  <DropdownMenu className="tt-dropdown-content">
                    {/* {this.props.nonSystemSetup ? null : ( */}
                    <>
                      {/* {this.props.nonSystemSetup ? (
                        ""
                      ) : this.state?.schoolImage !== null ? (
                        <img
                          src={this.state.schoolImage}
                          alt="Not Loading"
                          className="img-fluid"
                          onError={(e) => this.handleError(e)}
                        />
                      ) : (
                        ""
                      )} */}
                      {/* <img
                        src={
                          this.state?.schoolImage !== null
                            ? this.state.schoolImage
                            : 
                        }
                        alt="Not Loading"
                        className="img-fluid tt-dropdown-logo"
                        onError={(e) => this.handleError(e)}
                      /> */}

                      {schoolSetup === "true" &&
                      academicYears === "true" &&
                      !this.props.nonSystemSetup ? (
                        <Link
                          to={
                            userRole === "RL_STC"
                              ? `/familyPage/${familyId}`
                              : "/"
                          }
                        >
                          <DropdownItem className="tt-dropdown-button">
                            <span className="tt-dropdnItem">My Profile</span>

                            <span className="badge">
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                          </DropdownItem>
                        </Link>
                        
                      ) : null}

                      {schoolSetup === "true" &&
                      academicYears === "true" &&
                      !this.props.nonSystemSetup ? (
                         <Link
                          to={schoolProfileGroup?`/groupPage/${schoolProfileGroup.replace(/ /g, "_")}`:"/"}
                          className="menu-link mobileNavbarMenu"

                        >
                          <DropdownItem
                            className="tt-dropdown-button"
                          >
                            <span className="tt-dropdnItem">School Profile</span>
                            <span className="badge">
                              <FontAwesomeIcon icon={faSchool} />
                            </span>
                          </DropdownItem>
                        </Link>
                      ):null}
                      {/* {this.state.admin ? null : (
                      <DropdownItem
                        className="tt-dropdown-button"
                        onClick={this.toggleStaffModal}
                      >
                        <span className="tt-dropdnItem">Profile Info</span>

                        <span className="badge">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                      </DropdownItem>
                    )} */}
                      {schoolSetup === "true" &&
                      academicYears === "true" &&
                      !this.props.nonSystemSetup ? (
                        <DropdownItem
                          className="tt-dropdown-button mobileNavbarMenu"
                          onClick={this.changeAcademicYear}
                        >
                          <span className="tt-dropdnItem">
                            Change
                            <br />
                            Academic Year
                          </span>

                          <span className="badge">
                            <FontAwesomeIcon icon={faClock} />
                          </span>
                        </DropdownItem>
                      ) : null}

                      {schoolSetup === "true" &&
                      academicYears === "true" &&
                      !this.props.nonSystemSetup ? (
                        <DropdownItem divider />
                      ) : null}
                      {this.props.nonSystemSetup ? null : (
                        <DropdownItem
                          className="tt-dropdown-button"
                          toggle={false}
                          onClick={this.props.openSubMenu}
                          active={this.props.setting ? true : false}
                        >
                          <span className="tt-dropdnItem">Settings</span>
                          <span className="badge">
                            <FontAwesomeIcon icon={faCog} />
                          </span>
                        </DropdownItem>
                      )}

                      <DropdownItem
                        className="tt-dropdown-button"
                        id="tt-subMenu"
                        onClick={() => {
                          this.setState({ passwordModal: true });
                        }}
                      >
                        <span className="badge">
                          <FontAwesomeIcon icon={faDotCircle} />
                        </span>
                        <span className="tt-dropdnItem">Change Password</span>
                      </DropdownItem>
                      {/* <DropdownItem
                      className="tt-dropdown-button"
                      onClick={this.openAboutUsModal}
                    >
                      <span className="tt-dropdnItem">About ImagineSys</span>
                      <span className="badge">
                        <FontAwesomeIcon icon={faAddressCard} />
                      </span>
                    </DropdownItem> */}
                      <DropdownItem divider />
                    </>
                    {this.props.academicYears ? (
                      <Link to={`/systemSetup`} className="menu-link">
                        <DropdownItem className="tt-dropdown-button">
                          <span className="tt-dropdnItem">System Setup</span>
                          <span className="badge">
                            <FontAwesomeIcon icon={faServer} />
                          </span>
                        </DropdownItem>
                      </Link>
                    ) : null}
                    {checkDevAdmin() ? (
                      <Link
                        to={"/permissionConfiguration"}
                        className="menu-link mobileNavbarMenu"
                      >
                        <DropdownItem className="tt-dropdown-button">
                          <span className="tt-dropdnItem">Permission</span>

                          <span className="badge">
                            <FontAwesomeIcon icon={faUserLock} />
                          </span>
                        </DropdownItem>
                      </Link>
                    ) : null}
                    {checkDevAdmin() ? (
                      <Link
                        to={"/arrmanager"}
                        className="menu-link mobileNavbarMenu"
                      >
                        <DropdownItem className="tt-dropdown-button">
                          <span className="tt-dropdnItem">ARR Manager</span>

                          <span className="badge">
                            <FontAwesomeIcon icon={faGraduationCap} />
                          </span>
                        </DropdownItem>
                      </Link>
                    ) : null}
                    {checkDevAdmin() ? (
                      <Link
                        to={"/moderation_manager"}
                        className="menu-link mobileNavbarMenu"
                      >
                        <DropdownItem className="tt-dropdown-button">
                          <span className="tt-dropdnItem">
                            Moderation Manager
                          </span>

                          <span className="badge">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        </DropdownItem>
                      </Link>
                    ) : null}
                    {checkDevAdmin() ? (
                      <Link
                        to={"/usermanager"}
                        className="menu-link mobileNavbarMenu"
                      >
                        <DropdownItem className="tt-dropdown-button">
                          <span className="tt-dropdnItem">User Manager</span>

                          <span className="badge">
                            <FontAwesomeIcon icon={faUserCog} />
                          </span>
                        </DropdownItem>
                      </Link>
                    ) : null}

                    {getLinkedAccount().length > 0 ? (
                      <DropdownItem
                        className="tt-dropdown-button"
                        toggle={false}
                        onClick={this.openLinkedAccountSubMenu}
                        active={this.state.linkedAccountActive}
                      >
                        <span className="tt-dropdnItem">Linked Account</span>
                        <span className="badge">
                          <FontAwesomeIcon icon={faPeopleArrows} />
                        </span>
                      </DropdownItem>
                    ) : null}

                    {this.state.linkedAccount.length > 0
                      ? this.state.linkedAccount.map((item, id) => {
                          return (
                            <>
                              <DropdownItem
                                className="tt-dropdown-button"
                                id="tt-subMenu-linkedAccount"
                                style={{
                                  display: this.state?.linkedAccountActive
                                    ? "block"
                                    : "none",
                                }}
                                onClick={() =>
                                  this.handleAssociateUer(item, id)
                                }
                              >
                                <span className="badge mr-1 p-0">
                                  <FontAwesomeIcon icon={faDotCircle} />
                                </span>

                                <span className="tt-dropdnItem">
                                  {item.userRoleCode == userRoleCode.roleStaff
                                    ? "Staff"
                                    : item.userRoleCode ==
                                      userRoleCode.roleStudentContact
                                    ? "Student Contact"
                                    : item.userRoleCode ==
                                      userRoleCode.roleAdmin
                                    ? "Admin"
                                    : "Student"}{" "}
                                  Account
                                  {/* <br /> */}
                                  <div style={{ fontSize: "12px" }}>
                                    ({item.username})
                                  </div>
                                </span>
                              </DropdownItem>
                            </>
                          );
                        })
                      : null}
                   
                    <DropdownItem
                      className="tt-dropdown-button"
                      onClick={() => this.handleLogout()}
                    >
                      <span className="tt-dropdnItem">Sign Out</span>
                      <span className="badge">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <ModalWindow
            modal={this.state.acYrModal}
            modalHeader="Academic Year Navigator"
            size="lg"
            toggleModal={this.toggleChangeAcYrModal}
            modalBody={
              <ChangeAcademicYear
                toggleChangeAcYrModal={this.toggleChangeAcYrModal}
              />
            }
          ></ModalWindow>
          <ModalWindow
            fullWidth={true}
            modal={this.state.staffModal}
            toggleModal={this.toggleStaffModal}
            modalHeader="Profile Information"
            modalClass="tt-peopleList"
            modalBody={this.staffDataModal()}
            modalFooter={
              <button
                className="tt-button tt-button-primary"
                onClick={this.toggleStaffModal}
              >
                Close
              </button>
            }
          ></ModalWindow>
          <ModalWindow
            fullWidth={true}
            modal={this.state.admissionModal}
            toggleModal={this.toggleAdmissionModal}
            modalClass="tt-peopleList"
            modalBody={
              <ModalBody
                admissionListBySelect={this.state.admissionListBySelect}
                studentContactBySelect={this.state.studentContactBySelect}
                admissionModalData={this.state.admissionModalData}
              />
            }
            modalHeader="Profile Information"
          ></ModalWindow>
          <ModalWindow
            modal={this.state.passwordModal}
            modalHeader="Change Password"
            toggleModal={this.togglePasswordModal}
            modalBody={<ChangePassword />}
          ></ModalWindow>
          <ModalWindow
            modal={this.state.aboutUsModal}
            size="lg"
            toggleModal={this.openAboutUsModal}
            modalBody={<AboutUs></AboutUs>}
            noModelHeader={true}
            modalClass="aboutUsModal"
          ></ModalWindow>
        </div>
      </div>
    );
  }
}

export default Navbar;
