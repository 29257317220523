// Actions
const permissionsLOAD = "PERMISSIONS_LOAD";

export default function reducer(
  state = {
    permissions: [],
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case permissionsLOAD:
      newState.permissions = action.permissions;
      return newState;
    default:
      return newState;
  }
}

// Action Creators
export function loadPermissions(permissions) {
  return { type: permissionsLOAD, permissions };
}

export function setPermissions(permissions) {
  return (dispatch) => {
    dispatch(loadPermissions(permissions));
  };
}
