import React, { Component } from "react";

class DisplayStaffMemberList extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row tt-calendar-dateNavigation">
          <div className="col-md-3"></div>
          <div className="col-md-2">Staff type</div>
          <div className="col-md-4">
            <select
              className="form-control"
              name="staffTypeId"
              value={this.props.staffTypeId}
              onChange={this.props.handleStaffChange}
            >
              <option value="" disabled>
                Choose staff type
              </option>
              {this.props.staffType.map((type, idx) => {
                return (
                  <option key={idx} value={type.id}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <table className="table table-bordered text-center table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={this.props.allStaffChecked}
                  onChange={(e) => this.props.handleStaffCheckbox(e, "all")}
                ></input>
              </th>
              <th>Name of Staff</th>
            </tr>
          </thead>
          <tbody>
            {this.props.staffs?.length > 0 ? (
              this.props.staffs &&
              this.props.staffs.map((member, idx) => {
                return (
                  <tr key={idx}>
                    {member.people !== null ? (
                      <>
                        <td>
                          <input
                            type="checkbox"
                            checked={member.checked}
                            onChange={(e) =>
                              this.props.handleStaffCheckbox(e, idx)
                            }
                          ></input>
                        </td>
                        <td>{member.people.name}</td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3}>No staffs to select</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }
}

export default DisplayStaffMemberList;
