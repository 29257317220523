import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class McqResultTemplate extends Component {
  state = {};
  render() {
    let mcqResult = this.props.mcqResult;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.6vh" }}>
        <div className="row tt-mcqTestHead text-center">
          <div className="col-md-3">
            <strong>Correct : {this.props.correctAnswer}</strong>{" "}
          </div>
          <div className="col-md-3">
            <strong>Incorrect : {this.props.incorrect}</strong>
          </div>
          <div className="col-md-3">
            <strong>Total Answered: {this.props.totalAnswered}</strong>
          </div>
          <div className="col-md-3">
            <strong>Unanswered : {this.props.unanswered}</strong>
          </div>
        </div>
        <hr></hr>
        {mcqResult.map((ques, idx) => {
          return (
            <div key={idx} className="tt-mcqQuestionHolder pb-2">
              <div className="question1">
                {idx + 1}) {ques.question}
              </div>
              <ul className="answerOptions mb-0">
                {ques.mcqQuestionOptions.map((opt, oIdx) => {
                  return (
                    <li
                      className={
                        "answerOption tt-mcqResultOption " +
                        (opt.id === ques.selectedOptionId
                          ? opt.isCorrect
                            ? "correctAnswer"
                            : "wrongAnswer"
                          : "")
                      }
                      key={oIdx}
                    >
                      <div className="row">
                        <div className="col-md-10">
                          {/* <FontAwesomeIcon
                            icon={faCircle}
                            className={
                              opt.id === ques.selectedOptionId
                                ? "correctOption"
                                : ""
                            }
                          ></FontAwesomeIcon> */}
                          <span className="answerChoice">
                            {opt.optionValue}
                          </span>
                        </div>
                        {/* <div className="col-md-2 text-center">
                          {opt.id === ques.selectedOptionId ? (
                            <span>
                              <FontAwesomeIcon
                                icon={
                                  opt.id === ques.selectedOptionId &&
                                  opt.isCorrect
                                    ? faCheck
                                    : faTimes
                                }
                                className={
                                  opt.id === ques.selectedOptionId &&
                                  opt.isCorrect
                                    ? "tt-correct"
                                    : "tt-wrong"
                                }
                              ></FontAwesomeIcon>
                            </span>
                          ) : null}
                        </div> */}
                      </div>
                    </li>
                  );
                })}
              </ul>
              {ques.mcqQuestionOptions.map((opt, oIdx) => {
                return (
                  <div>
                    {opt.isCorrect ? (
                      <div>
                        <div>
                          <strong>Correct Answer: </strong>
                          {opt.optionValue}
                        </div>
                        {opt.explanation !== "" ? (
                          <>
                            <div>
                              <strong>Explanation: </strong>
                            </div>
                            <div>{ReactHtmlParser(opt.explanation)}</div>
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default McqResultTemplate;
