import React, { Component } from "react";
import FileViewer from "react-file-viewer";
import { URL as URLs, getValidHrefLink } from "../../utils/Constants";

export default class FileModal extends Component {
  checkFilePath = (path) => {
    let data = URLs.imageSelectURL + path;
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", data, false);
    xhr.send();

    let status = parseInt(xhr.status);
    if (status == 404 || status == 401) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-8">
            <div className="download-col mb-3">
              <h6>File Preview</h6>
              {this.props.fileExist && this.props?.type !== "link" ? (
                this.checkFilePath(this.props.filePath) ? (
                  <a
                    href={URLs.fileDownloadURL + this.props.filePath}
                    target="_blank"
                    download
                  >
                    <button className="tt-button tt-button-primary mr-3">
                      Download
                    </button>
                  </a>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="download-col mb-3">
              <h6>File viewer</h6>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-8 file-preview-col">
            <>
              {this.props.fileType === "TXT" ? (
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.filePath }}
                />
              ) : this.props.fileExist && this.props?.type !== "link" ? (
                this.checkFilePath(this.props.filePath) ? (
                  this.props.fileType === "IMG" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "500px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ objectFit: "contain" }}
                        src={`${URLs.imageSelectURL + this.props.filePath}`}
                        alt="Resource File"
                      />
                    </div>
                  ) : (
                    <FileViewer
                      fileType={this.props.fileType.toLowerCase()}
                      filePath={URLs.imageSelectURL + this.props.filePath}
                    />
                  )
                ) : (
                  <h5 className="text-center">"File Not Available"</h5>
                )
              ) : (
                <h5 className="text-center">"File Not Available"</h5>
              )}
            </>
          </div>
          {this.props.isNotice || this.props.isResourceFile ? (
            <div className="col-md-4 file-list-col">
              {this.props.files.length > 0 ? (
                <>
                  <h2>Files</h2>
                  <ul>
                    {this.props.files.map((datum, idx) => {
                      return (
                        <li style={{ wordWrap: "break-word" }}>
                          <a
                            onClick={
                              this.props.isNotice
                                ? () =>
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.content,
                                      datum.contentTypeCode,
                                      idx
                                    )
                                : () =>
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.filePath,
                                      datum.fileType,
                                      idx
                                    )
                            }
                          >
                            {this.props.activeIndex === datum.id &&
                            this.props.fileType === "PDF" ? (
                              <span className="activeIndex">
                                <u>
                                  {this.props.isNotice
                                    ? datum.origin
                                    : datum.fileName}
                                </u>
                              </span>
                            ) : this.props.isNotice ? (
                              datum.origin
                            ) : (
                              datum.fileName
                            )}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                ""
              )}
              {this.props.images.length > 0 ? (
                <>
                  <h2>Images</h2>
                  <ul>
                    {this.props.images.map((datum, idx) => {
                      return (
                        <li style={{ wordWrap: "break-word" }}>
                          <a
                            onClick={
                              this.props.isNotice
                                ? () =>
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.content,
                                      datum.contentTypeCode,
                                      idx
                                    )
                                : () =>
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.filePath,
                                      datum.fileType,
                                      idx
                                    )
                            }
                          >
                            {this.props.activeIndex === datum.id &&
                            this.props.fileType === "IMG" ? (
                              <span className="activeIndex">
                                <u>
                                  {this.props.isNotice
                                    ? datum.origin
                                    : datum.fileName}
                                </u>
                              </span>
                            ) : this.props.isNotice ? (
                              datum.origin
                            ) : (
                              datum.fileName
                            )}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="col-md-4 file-list-col">
              {this.props.images.length > 0 ? (
                <div>
                  <ul>
                    <li>Images</li>
                    <ol type="i">
                      {this.props.images.map((datum, idx) => {
                        return (
                          <li style={{ wordWrap: "break-word" }}>
                            <a
                              onClick={() =>
                                this.props.handleChangeFile(
                                  datum.filePath,
                                  idx,
                                  "image"
                                )
                              }
                            >
                              {this.props.activeIndex === idx &&
                              this.props.type === "image" ? (
                                <span className="activeIndex">
                                  <u>{datum.fileName}</u>
                                </span>
                              ) : (
                                datum.fileName
                              )}
                            </a>
                          </li>
                        );
                      })}
                    </ol>
                  </ul>
                </div>
              ) : (
                ""
              )}

              {this.props.files.length > 0 ? (
                <div>
                  <ul>
                    {this.props.files.filter((el) => el.fileType === "PDF")
                      .length > 0 ? (
                      <li>Attached Files</li>
                    ) : null}

                    <ol type="i">
                      {this.props.files
                        // .filter((datum) => datum.fileType == "PDF")
                        .map((filterDatum, idx) => {
                          return (
                            <li style={{ wordWrap: "break-word" }}>
                              <a
                                onClick={() =>
                                  this.props.handleChangeFile(
                                    filterDatum.filePath,
                                    idx,
                                    "files"
                                  )
                                }
                              >
                                {this.props.activeIndex === idx &&
                                this.props.type === "files" ? (
                                  <span className="activeIndex">
                                    <u>{filterDatum.fileName}</u>
                                  </span>
                                ) : (
                                  filterDatum.fileName
                                )}
                              </a>
                            </li>
                          );
                        })}
                    </ol>
                  </ul>
                </div>
              ) : null}

              {this.props.links.length > 0 ? (
                <div>
                  <ul>
                    <li>Links</li>
                    <ol type="i">
                      {this.props.links.map((datum, idx) => {
                        return (
                          <li style={{ wordWrap: "break-word" }}>
                            <a
                              onClick={() =>
                                this.props.handleChangeFile(
                                  datum.filePath,
                                  idx,
                                  "link"
                                )
                              }
                              href={getValidHrefLink(
                                datum.link ?? datum.links ?? datum.fileName
                              )}
                              // href={
                              //   getValidHrefLink(
                              //     datum.link
                              //       ? datum.link
                              //       : datum.links
                              //       ? datum.links
                              //       : datum.fileName
                              //   )

                              // }
                              target="_blank"
                            >
                              {datum.link == null
                                ? datum.fileName
                                : datum.links || datum.link}
                            </a>
                          </li>
                        );
                      })}
                    </ol>
                  </ul>
                </div>
              ) : (
                ""
              )}

              {this.props.contents?.length > 0 ? (
                <div>
                  <ul>
                    <li>Contents</li>
                    <ol type="i">
                      {this.props.contents?.map((datum, idx) => {
                        return (
                          <li style={{ wordWrap: "break-word" }}>
                            <a
                              onClick={() =>
                                this.props.handleChangeFile(datum, idx, "txt")
                              }
                            >
                              {this.props.activeIndex === idx &&
                              this.props.type === "txt" ? (
                                <span className="activeIndex">
                                  <u>{datum.filePath}</u>
                                </span>
                              ) : (
                                datum.filePath
                              )}
                            </a>
                          </li>
                        );
                      })}
                    </ol>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}
