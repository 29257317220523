import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import ModalWindow from "../UI/ModalWindow";
import ViewPlanner from "./ViewPlanner";
import { Spinner } from "reactstrap";
import { displayErrorAlert } from "../../utils/Utils";
import Planner from "./Planner";
import AddResourcesFile from "./AddResourcesFile";
import ViewResourceFiles from "./ViewResourceFiles";
import swal from "sweetalert";
import FileModal from "../ViewFile/FileModal";

const animatedComponents = makeAnimated();

class ListPlanner extends Component {
  state = {
    activeIndex: "",
    endDate: "",
    fileViewList: [],
    fileViewerModal: false,
    filePath: "",
    fileType: "",
    files: [],
    images: [],
    plannerEditModal: false,
    planners: [],
    selectedPlannerList: [],
    selectedSubjects: [],
    spinner: false,
    startDate: "",
    subjects: [],
    viewModal: false,
    yearLevels: [],
    yearLevel: "",
    viewFileModal: false,
  };

  closeEditModal = () => {
    this.setState({
      plannerEditModal: !this.state.plannerEditModal,
      selectedPlannerList: [],
    });
  };

  closeViewFileModal = () => {
    this.setState({
      viewFileModal: !this.state.viewFileModal,
    });
  };
  closeViewModal = () => {
    this.setState({
      viewModal: !this.state.viewModal,
      // selectedPlannerList: [],
    });
  };

  componentDidMount() {
    this.getYearLevels();
  }

  deletePlanner = (plId) => {
    axiosPost(URL.deletePlanner, { id: plId }, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Planner has been successfully deleted.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        this.handleSearch();
      }
    });
  };

  filterFiles = () => {
    let file = [];
    let image = [];
    this.state.fileViewList.plannerListResources.length > 0 &&
      this.state.fileViewList.plannerListResources.map((content) => {
        if (content.fileType === "PDF" || content.fileType === "TXT") {
          file.push(content);
        } else if (content.fileType === "IMG") {
          image.push(content);
        }
      });
    this.setState({ files: file, images: image }, () => {
      if (this.state.files.length > 0) {
        // let initialFile = this.state.files[0];
        this.setState({
          filePath: this.state.files[0].filePath,
          fileType: this.state.files[0].fileType,
          activeIndex: this.state.files[0].id || this.state.images[0].id,
        });
      } else {
        this.setState({
          filePath: "",
          fileType: "",
          activeIndex: "",
        });
      }
    });
  };

  getSubjectsByYearLevel = () => {
    let param = { yearLevelId: this.state.yearLevel };
    axiosPost(URL.getSubjectForPlanner, param, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        let newData = [];
        if (datum.length > 0) {
          datum.forEach((el) => {
            newData.push({
              label: el.name,
              value: el.id,
            });
          });
        }
        this.setState({ subjects: newData });
      }
    });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  handleChangeEvent = (item) => {
    let selectedSubject = [];

    selectedSubject.push(item);

    this.setState({ selectedSubjects: selectedSubject });
  };

  handleDataChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name === "yearLevel") {
        this.setState({ subject: "" }, function () {
          this.getSubjectsByYearLevel();
        });
      }
    });
  };

  handleChangeFile = (file, filePath, fileType, idx) => {
    this.setState(
      {
        filePath: "",
        fileType: "",
      },
      () => {
        // this.checkFileExist(content);
        this.setState({
          filePath: filePath,
          fileType: fileType,
          activeIndex: file.id,
        });
      }
    );
  };

  handleFileViewer = (e, item, idx) => {
    this.toggleFileViewer();

    this.setState({
      filePath: item.filePath,
      fileType: item.fileType,
      activeIndex: idx,
    });
  };

  handleSearch = () => {
    let selectedSubjects = [...this.state.selectedSubjects];
    let subjects = [];
    if (selectedSubjects.length > 0) {
      selectedSubjects.forEach((el) => {
        subjects.push(el.value);
      });
    }
    let param = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      yearlevelId: this.state.yearLevel,
      subjects: subjects,
    };
    this.setState({ spinner: true, planners: [] }, function () {
      axiosPost(
        URL.searchPlanner,
        param,
        (response) => {
          if (response.status === 200) {
            this.setState({ planners: response.data.data, spinner: false });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleViewFiles = (fileList) => {
    this.setState(
      {
        viewFileModal: true,
        fileViewList: fileList,
      },
      () => {
        this.filterFiles();
      }
    );
  };
  handleViewModal = (list) => {
    this.setState({
      viewModal: !this.state.viewModal,
      selectedPlannerList: list,
    });
  };

  handleEditPlannerBtnClick = (plannerItem) => {
    this.setState({
      plannerEditModal: !this.state.plannerEditModal,
      selectedPlannerList: plannerItem,
    });
  };

  toggleFileViewer = () => {
    this.setState({
      // fileUrl: URLs.imageSelectURL + param,
      viewFileModal: !this.state.viewFileModal,
    });
    this.filterFiles();
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">Search Planner</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <select
                className="form-control"
                name="yearLevel"
                value={this.state.yearLevel}
                onChange={this.handleDataChange}
                placeholder="Choose Year Level"
              >
                <option value="" disabled selected>
                  Choose Year Level
                </option>
                {this.state.yearLevels.length > 0
                  ? this.state.yearLevels.map((yLvl, idx) => {
                      return (
                        <option value={yLvl.id} key={idx}>
                          {yLvl.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="col">
              <Select
                menuPlacement="auto"
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={this.state.subjects}
                placeholder="Choose Subjects"
                name="Subject"
                onChange={this.handleChangeEvent}
                closeMenuOnScroll={false}
                value={this.state.selectedSubjects}
                isDisabled={this.state.yearLevel === ""}
              />
            </div>
            <div className="col">
              <input
                placeholder="Date From"
                class="form-control"
                type="text"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) =>
                  this.state.startDate === "" ? (e.target.type = "text") : null
                }
                name="startDate"
                value={this.state.startDate}
                onChange={this.handleDataChange}
              />
            </div>
            <div className="col">
              <input
                placeholder="Date To"
                class="form-control"
                type="text"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) =>
                  this.state.endDate === "" ? (e.target.type = "text") : null
                }
                name="endDate"
                value={this.state.endDate}
                onChange={this.handleDataChange}
              />
            </div>
            <div className="col-auto">
              <button
                className="tt-button tt-button-primary"
                onClick={this.handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="col"
              style={{
                border:
                  this.state.planners.length > 0 ? "1px solid #ccc" : "none",
                margin: "10px",
                padding: "15px",
              }}
            >
              {this.state.planners.length > 0 ? (
                this.state.planners.map((planner, idx) => {
                  let yearLevelName = planner.yearLevelName.includes("Class")
                    ? planner.yearLevelName
                    : "Class " + planner.yearLevelName;
                  return (
                    <div
                      className={
                        "d-flex justify-content-around tt-group-header " +
                        (idx === this.state.planners.length - 1
                          ? "mb-0"
                          : "mb-2")
                      }
                    >
                      <div
                        className={
                          "tt-group-header " +
                          (idx === this.state.planners.length - 1
                            ? "mb-0"
                            : "mb-2")
                        }
                        key={idx}
                        style={{
                          borderRadius: 0,
                          fontWeight: "normal",
                          textShadow: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => this.handleViewModal(planner)}
                      >
                        <div className="text-center">
                          <strong>
                            {yearLevelName} (
                            {planner.subjects &&
                              planner.subjects.length > 0 &&
                              planner.subjects[0].subjectName}
                            )
                          </strong>
                          <br />
                          <span>
                            {moment(planner.startDate).format("LL")} -{" "}
                            {moment(planner.endDate).format("LL")}
                          </span>
                        </div>
                        {/* <div>
                          <strong>
                            {moment(planner.startDate).format("LLL")} -{" "}
                            {moment(planner.endDate).format("LLL")}
                          </strong>
                        </div> */}
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          className="tt-button tt-button-primary m-1"
                          onClick={() =>
                            this.handleEditPlannerBtnClick(planner)
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="tt-button tt-button-danger m-1"
                          onClick={() => this.deletePlanner(planner.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  {this.state.spinner ? (
                    <>
                      <Spinner></Spinner>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.viewModal}
          modalBody={
            <ViewPlanner
              selectedPlannerList={this.state.selectedPlannerList}
              handleViewFiles={this.handleViewFiles}
            />
          }
          modalHeader="Planner List"
          size="xl"
          toggleModal={this.closeViewModal}
        ></ModalWindow>

        <ModalWindow
          modal={this.state.plannerEditModal}
          modalBody={
            <Planner
              selectedPlannerList={this.state.selectedPlannerList}
              plannerEditModal={this.state.plannerEditModal}
              handleSearch={this.handleSearch}
              closeEditModal={this.closeEditModal}
            />
          }
          modalHeader="Edit Planner"
          size="xl"
          toggleModal={this.closeEditModal}
        ></ModalWindow>

        <ModalWindow
          modal={this.state.viewFileModal}
          // modal={this.state.fileViewerModal}
          modalClass="fullModal"
          // toggleModal={this.toggleFileViewer}
          modalBody={
            // <ViewResourceFiles fileViewList={this.state.fileViewList} />
            <FileModal
              files={this.state.files}
              images={this.state.images}
              fileType={this.state.fileType}
              filePath={this.state.filePath}
              handleChangeFile={this.handleChangeFile}
              activeIndex={this.state.activeIndex}
              fileExist={true}
              isNotice={false}
              isResourceFile={true}
              type="resourceFile"
            ></FileModal>
          }
          modalHeader="Resource Files"
          size="xl"
          toggleModal={this.closeViewFileModal}
        ></ModalWindow>
      </div>
    );
  }
}

export default ListPlanner;
