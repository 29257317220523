/**
 * @author Azzeta Karmacharya
 * @version 2.0
 * @editedby @author Sandeep Shakya
 */
import React, { Component } from "react";
import "../../assets/scss/groupArea.scss";
import { connect } from "react-redux";
import {
  getGroups,
  getFamilyGroup,
} from "../../ducks/AcademicAdministrationDucks";

import { axiosPost } from "../../utils/AxiosApi";
import { URL, userRoleCode, userRole } from "../../utils/Constants";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { getPersonProfilePosts } from "./../../ducks/PostsDucks";
import userImage from "../../assets/images/user-image.png";
import { Spinner } from "reactstrap";

import $ from "jquery";

class GroupArea extends Component {
  state = {
    groupId: "",
    groupLists: "",
    groupData: "",
    groupMemberList: [],
    horizontalGroup: [],
    people: "",
    initialId: "",
    minimize: false,
    personProfileData: [],
    groupSpinner: false,
    items: 20,
    loadingState: false,
    offset: 0,
    totalRecordsCount: 0,
    rowCountLimit: 20,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profilePeopleId !== this.props.profilePeopleId) {
      this.props.getGroups(this.props.profilePeopleId);
      this.setState(
        { groupId: "", groupMemberList: [], horizontalGroup: [] },
        () => {
          this.getDefaultGroup();
        }
      );
    }
    ReactTooltip.rebuild();
  }

  addMinimizeClass = () => {
    let self = this;
    $(window).on("load", function () {
      if ($(window).width() <= 768) {
        self.setState({ minimize: true });
      }
    });
  };

  componentDidMount() {
    if (this.props.familyPage) {
      this.props.getFamilyGroup(this.props.familyId);
    } else {
      this.props.getGroups(this.props.profilePeopleId);
    }
    this.addMinimizeClass();
    this.refs.iScroll.addEventListener("scroll", () => {
      if (
        this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >=
        this.refs.iScroll.scrollHeight
      ) {
        this.loadMoreItems();
      }
    });
    this.getDefaultGroup();
  }
  //   initialGroupDisplay = () => {
  //     axiosPost(URL.getHorizontalGroup, {}, (response) => {
  //       if (response.status === 200 && response.data.data.length > 0) {
  //         let data = response.data.data;
  //         let val = `${data[0].id},horizontal`;
  //         this.setState({
  //           groupMemberList: data[0].peoples,
  //           groupId: val,
  //           groupData: response.data.data[0],
  //         });
  //       }
  //     });
  //   };

  getDefaultGroup = () => {
    let peopleId = this.props.profilePeopleId;
    if (this.props.familyPage) {
      peopleId = localStorage.getItem("peopleId");
    }

    // let peopleId = localStorage.getItem("peopleId");

    let data = {
      peopleId: peopleId,
      familyId:
        userRole === userRoleCode.roleStudentContact
          ? this.props.familyId
          : null,
    };

    if (this.props.familyPage) {
      data.familyId = this.props.familyId;
    }

    axiosPost(URL.selectUserDefaultGroup, data, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState(
          {
            groupId: data.id,
            groupData: data,
            groupSpinner: true,
          },
          () => {
            this.getGroupMemberList();
          }
        );
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.familyPage) {
      return { groupLists: nextProps.familyGroupLists };
    } else {
      return { groupLists: nextProps.groupLists };
    }
  }

  // getHorizontalGroup = () => {
  //   axiosPost(URL.getHorizontalGroup, {}, (response) => {
  //     if (response.status === 200) {
  //       this.setState({
  //         horizontalGroup: response.data.data,
  //       });
  //     }
  //   });
  // };

  getGroupMemberList = () => {
    if (this.state.groupId !== "") {
      let data = {
        id: this.state.groupId,
        offset: this.state.offset,
        peopleName: this.state.people,
        rowCountLimit: this.state.rowCountLimit,
      };
      axiosPost(URL.getGroupSelectByKey, data, (response) => {
        if (response.status === 200) {
          let peoples = response.data?.data?.groupPeople;
          if (peoples && peoples?.length > 0) {
            peoples.forEach((el) => {
              el.displayPhoto = URL.imageSelectURL + "136x136" + "/" + el.photo;
            });
          }
          this.setState({
            groupSpinner: false,
            groupMemberList: peoples,
            groupData: response.data.data,
            totalRecordsCount: response.data.totalRecordsCount,
          });
        }
      });
    }
  };

  searchPeopleInGroup = () => {
    this.setState({ offset: 0 });
    // let groupId = this.state.groupId.split(",");
    // let data;
    // if (groupId[1] === "horizontal") {
    //   data = {
    //     classroomId: parseInt(groupId[0]),
    //     name: this.state.people,
    //   };
    // } else {
    //   data = {
    //     groupId: parseInt(groupId[0]),
    //     // name: this.state.people,
    //     peopleName: this.state.people,

    //   };
    // }
    this.setState({ groupSpinner: true }, function () {
      // axiosPost(URL.searchPeopleInGroup, data, (response) => {
      //   if (response.status === 200) {
      //     this.setState({
      //       groupMemberList: response.data.data,
      //       groupSpinner: false,
      //     });
      //   }
      // });
      this.getGroupMemberList();
    });
  };

  searchPeopleText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  handleDataChange = (e) => {
    const target = e.target;
    // const value = target.value.split(",");
    const value = target.value;

    const name = target.name;
    this.setState({ [name]: e.target.value, people: "" }, function () {
      // if (value[1] === "groups") {
      this.setState(
        {
          groupSpinner: true,
          groupMemberList: [],
          offset: 0,
        },
        function () {
          this.getGroupMemberList();
        }
      );
      // }
    });
  };

  minimizeGroupContainer = () => {
    this.setState({ minimize: !this.state.minimize });
  };

  handlePersonProfileData = (e, id) => {
    window.scrollTo(0, 0);
    this.props.getPersonProfilePosts(id, "initCall");
  };

  loadMoreItems() {
    if (this.state.groupMemberList.length < this.state.totalRecordsCount) {
      this.setState({ loadingState: true });
      let offset = this.state.offset + this.state.rowCountLimit;
      this.setState({ offset: offset });
      // let groupId = this.state.groupId.split(",");

      let data = {
        // id: groupId[0],
        id: this.state.groupId,
        offset: this.state.offset,
        peopleName: this.state.people,
        rowCountLimit: this.state.rowCountLimit,
      };
      setTimeout(() => {
        this.setState({ items: this.state.items + 20, loadingState: false });
      }, 500);
      axiosPost(URL.getGroupSelectByKey, data, (response) => {
        if (response.status === 200) {
          let groupMemberList = this.state.groupMemberList;
          let peoples = response.data.data?.groupPeople;
          if (peoples && peoples.length > 0) {
            peoples.forEach((el) => {
              el.displayPhoto = URL.imageSelectURL + "136x136" + "/" + el.photo;
            });
          }
          this.setState({
            groupSpinner: false,
            groupMemberList: [...groupMemberList, ...peoples],
            groupData: response.data.data,
            totalRecordsCount: response.data.totalRecordsCount,
          });
        }
      });
    }
  }

  handleError = (e, member) => {
    let self = e.target;
    let img = new Image();
    img.src = URL.imageSelectURL + member.photo;
    img.onload = function () {
      self.src = img.src;
    };
    img.onerror = function () {
      self.src = userImage;
    };
  };

  render() {
    let urlName = this.state.groupData
      ? this.state.groupData.yearLevelName
        ? `${this.state.groupData.yearLevelName} - ${this.state.groupData.name}`
        : `${this.state.groupData.name}`
      : "";
    return (
      <div
        id="tt-groupContainer"
        className={
          this.state.minimize
            ? "tt-group-container minimize-group"
            : "tt-group-container"
        }
      >
        <div
          className="tt-group-header"
          onDoubleClick={this.minimizeGroupContainer}
        >
          <div className="mobileGroupHeader">
            <div className="row">
              <div className="col-md-auto col-sm-auto resGroupTitle mt-1">
                Groups
              </div>
              <div className="col resGroupDropdown">
                <select
                  name="groupId"
                  value={this.state.groupId}
                  className="form-control"
                  onChange={this.handleDataChange}
                  onDoubleClick={(e) => e.stopPropagation()}
                >
                  <option value="" disabled>
                    Choose a group
                  </option>
                  {this.state.groupLists !== undefined
                    ? this.state.groupLists.map((group, idx) => {
                        let val = `${group.id}`;
                        return (
                          <option key={idx} value={val}>
                            {group.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col resGroupSearch">
                <input
                  type="text"
                  name="people"
                  className="form-control"
                  value={this.state.people}
                  onChange={this.searchPeopleText}
                  placeholder="Search people"
                  onDoubleClick={(e) => e.stopPropagation()}
                ></input>
              </div>
              <div
                className="col-md-auto col-sm-auto resGroupButton text-right"
                style={{ marginTop: "2px" }}
              >
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.searchPeopleInGroup}
                  onDoubleClick={(e) => e.stopPropagation()}
                >
                  View
                </button>
              </div>
            </div>
          </div>

          <div className="row desktopGroupHeader">
            <div className="col-md-6">
              <div className="row">
                <div
                  className="col-md-3 mt-1"
                  style={{ paddingRight: 0 }}
                  data-tip="Double click to expand and collapse this widget"
                >
                  Groups
                </div>
                <div className="col-md-9" style={{ paddingLeft: "10px" }}>
                  <select
                    name="groupId"
                    value={this.state.groupId}
                    className="form-control"
                    onChange={this.handleDataChange}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    <option value="" disabled>
                      Choose a group
                    </option>
                    {/* <optgroup label="Groups"> */}

                    {this.state.groupLists !== undefined
                      ? this.state.groupLists.map((group, idx) => {
                          // let val = `${group.id},groups`;
                          let val = `${group.id}`;
                          return (
                            <option key={idx} value={val}>
                              {group.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8">
                  <input
                    type="text"
                    name="people"
                    className="form-control"
                    value={this.state.people}
                    onChange={this.searchPeopleText}
                    placeholder="Search people"
                    onDoubleClick={(e) => e.stopPropagation()}
                  ></input>
                </div>
                <div
                  className="col-md-4"
                  style={{ paddingLeft: 0, marginTop: "2px" }}
                >
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.searchPeopleInGroup}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="iScroll" className="tt-group-box">
          {this.state.groupMemberList.length > 0 ? (
            this.state.groupMemberList.map((member, idx) => {
              if (idx <= this.state.items) {
                return (
                  <div key={idx}>
                    {member.userRole === "RL_STC" ? (
                      <div
                        className="tt-group-holder"
                        key={idx}
                        id={idx}
                        data-tip={
                          member.name +
                          " [" +
                          (member.userRole === "RL_STC"
                            ? "Student Contact"
                            : member.userRole === "RL_STD"
                            ? "Student"
                            : member.userRole === "RL_ADM"
                            ? "Admin"
                            : "Staff") +
                          "]"
                        }
                      >
                        <Link
                          to={{
                            pathname: `/familyPage/${member.familyId}`,
                            profilePeopleId: member.peopleId,
                            familyPageOpen: true,
                            userId: member.userId,
                          }}
                        >
                          <div className="tt-memeber-img">
                            <img
                              src={
                                member.photo !== null
                                  ? member.displayPhoto
                                  : userImage
                              }
                              // src={
                              //   member.photo !== null
                              //     ? URL.imageSelectURL + member.photo
                              //     : userImage
                              // }
                              onError={(e) => this.handleError(e, member)}
                            ></img>
                          </div>
                          <div className="tt-member-name">{member.name}</div>
                        </Link>
                      </div>
                    ) : (
                      <div
                        className="tt-group-holder"
                        key={idx}
                        id={idx}
                        data-tip={
                          member.name +
                          " [" +
                          (member.userRole === "RL_STC"
                            ? "Student Contact"
                            : member.userRole === "RL_STD"
                            ? "Student"
                            : member.userRole === "RL_ADM"
                            ? "Admin"
                            : "Staff") +
                          "]"
                        }
                      >
                        <Link
                          to={{
                            pathname: `/profile/${member.username}`,
                            username: member.username,
                            memberPhoto: member.photo,
                            groupId: this.state.groupId,
                            name: member.name,
                          }}
                          onClick={(e) => {
                            this.handlePersonProfileData(e, member.id);
                          }}
                        >
                          <div className="tt-memeber-img">
                            <img
                              src={
                                member.photo !== null
                                  ? member.displayPhoto
                                  : userImage
                              }
                              // src={
                              //   member.photo !== null
                              //     ? URL.imageSelectURL + member.photo
                              //     : userImage
                              // }
                              onError={(e) => this.handleError(e, member)}
                            ></img>
                          </div>
                          <div className="tt-member-name">{member.name}</div>
                        </Link>
                      </div>
                    )}

                    <ReactTooltip globalEventOff="click"></ReactTooltip>
                  </div>
                );
              }
            })
          ) : (
            <div className="text-center mt-3 mb-3">
              {this.state.groupSpinner ? (
                <>
                  <Spinner color="primary"></Spinner>
                  <br></br>
                  Loading Data ...
                </>
              ) : this.state.groupId !== "" ? (
                <p>No members found</p>
              ) : (
                <p>Select a group first</p>
              )}
            </div>
          )}

          {/* {this.state.groupMemberList.length >= this.state.items ? ( */}
          {this.state.loadingState ? (
            <div className="tt-group-holder">
              <div className="tt-memeber-img">
                <Spinner color="primary"></Spinner>
              </div>
            </div>
          ) : null}
          {/* ) : null} */}
        </div>
        <div className="tt-group-header mt-2">
          <div className="row">
            <div className="col text-left tt-totalMemberMobile">
              {/* Total Members : {this.state.groupMemberList.length} */}
              {this.state.totalRecordsCount > 1
                ? this.state.totalRecordsCount + " members found"
                : this.state.totalRecordsCount + " member found"}
            </div>
            <div className="col text-right">
              <Link
                to={{
                  pathname: `/groupPage/${urlName.replace(/ /g, "_")}`,
                }}
              >
                <button
                  className="tt-button tt-button-primary tt-mobileViewButton"
                  disabled={this.state.groupId === ""}
                >
                  View Group Page
                </button>
              </Link>
            </div>
          </div>
        </div>
        <ReactTooltip globalEventOff="click"></ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  groupLists: state.academicAdministration.groupLists,
  familyGroupLists: state.academicAdministration.familyGroupLists,
});

const mapActionToProps = {
  getGroups,
  getPersonProfilePosts,
  getFamilyGroup,
};

export default connect(mapStateToProps, mapActionToProps)(GroupArea);
