import React, { Component } from "react";
import ModalWindow from "../../../UI/ModalWindow";
import AddNewBuildingForm from "./AddNewBuildingForm";
import FloorTable from "./FloorTable";
import BuildingSectionTable from "./BuildingSectionTable";
import BuildingDetailList from "./BuildingDetailList";
import { checkRolePermissions } from "../../../../utils/Constants";
import RoomInsertForm from "./RoomInsertForm";
import { Spinner } from "reactstrap";

class ListFacilities extends Component {
  state = {
    permissionEditBuilding: false,
    permissionDeleteBuilding: false,
    permissionInsertFacilitySection: false,
    permissionEditFacilitySection: false,
    permissionDeleteFacilitySection: false,
    permissionAddRow: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditBuilding: checkRolePermissions("edit-building", "activity"),
      permissionDeleteBuilding: checkRolePermissions(
        "delete-building",
        "activity"
      ),
      permissionInsertFacilitySection: checkRolePermissions(
        "insert-facility-section",
        "activity"
      ),
      permissionEditFacilitySection: checkRolePermissions(
        "edit-facility-section",
        "activity"
      ),
      permissionDeleteFacilitySection: checkRolePermissions(
        "delete-facility-section",
        "activity"
      ),
      permissionAddRow: checkRolePermissions("add-room", "activity"),
    });
  };

  render() {
    return (
      <>
        {!this.props.isLoading ? (
          <div className="container-fluid">
            {this.props.buildingList && this.props.buildingList.length > 0 ? (
              this.props.buildingList.map((facility, idx) => {
                return (
                  <div className="row tt-facilities-border my-1" key={idx}>
                    <div className="col-md-11">
                      <div className="form-group row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-3">
                          <strong>Building Name:</strong>
                        </div>
                        <div className="col-sm-2">{facility.name}</div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-3">
                          <strong>No of Floors:</strong>
                        </div>
                        <div className="col-sm-2">{facility.noOfFloors}</div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-3">
                          <strong>Section Name:</strong>
                        </div>
                        <div className="col-sm-4">
                          {facility.buildingSectionName
                            ? facility.buildingSectionName
                            : "No building section"}
                        </div>
                      </div>
                      <div className="form-group row mt-4">
                        <div className="col-sm-1"></div>
                        <div className="col-md-4">
                          {facility.floors.length > 0 ? (
                            <table className="table text-center table-bordered">
                              <thead className="tt-group-header">
                                <tr>
                                  <th colSpan="2">Floors</th>
                                </tr>
                                <tr>
                                  <th>Number</th>
                                  <th>Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {facility.floors.map((floor, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{floor.number}</td>
                                      <td>{floor.name}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1">
                      {this.state.permissionEditBuilding ? (
                        <div className="row">
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={(e) => {
                              this.props.handleBuildingUpdate(e, facility.id);
                              this.props.toggleBuildingUpdateModal(e);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      ) : null}
                      {this.state.permissionDeleteBuilding ? (
                        <div className="row">
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={(e) =>
                              this.props.deleteBuildings(facility.id)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      ) : null}
                      <div className="row">
                        <button
                          className="tt-button tt-button-primary tt-button-fullWidth"
                          onClick={(e) => {
                            this.props.handleFloorClick(e);
                            this.props.getValuesForFloorView(e, facility.id);
                          }}
                        >
                          Floors
                        </button>
                      </div>
                      {facility.buildingSectionName ? (
                        <>
                          {this.state.permissionInsertFacilitySection ||
                          this.state.permissionEditFacilitySection ||
                          this.state.permissionDeleteFacilitySection ? (
                            <div className="row">
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth"
                                onClick={(e) => {
                                  this.props.toggleSection(e);
                                  this.props.getSectionValues(
                                    facility.id,
                                    facility.buildingSectionName
                                  );
                                }}
                              >
                                Sections
                              </button>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                      {this.state.permissionAddRow ? (
                        <div className="row">
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={(e) => {
                              this.props.handleRoomInsertFormModal(e);
                              this.props.handleGetOtherValues(e, facility);
                              //this.props.getRoomValues(e, facility.id);
                            }}
                          >
                            Rooms
                          </button>
                        </div>
                      ) : null}

                      <div className="row">
                        <button
                          className="tt-button tt-button-primary tt-button-fullWidth"
                          onClick={(e) => {
                            this.props.handleBuildingDetailModal(e);
                            this.props.handleBuildingDetailClick(
                              facility.id,
                              facility.buildingSectionName
                            );
                          }}
                        >
                          Details
                        </button>
                      </div>
                    </div>
                    {/* ) : null} */}
                  </div>
                );
              })
            ) : (
              <div className="text-center"> No Data</div>
            )}
          </div>
        ) : (
          <div className="text-center" style={{ marginTop: "50px" }}>
            <Spinner color="primary" />
          </div>
        )}

        <ModalWindow
          size="lg"
          modal={this.props.modalForUpdate}
          toggleModal={this.props.toggleBuildingUpdateModal}
          modalHeader={"Update Building"}
          modalBody={
            <AddNewBuildingForm
              errors={this.props.errors}
              building={this.props.building}
              floors={this.props.floors}
              buildingSection={this.props.buildingSection}
              handleChange={this.props.handleChange}
              updateBuilding={this.props.modalForUpdate}
              handleUpdateSubmit={this.props.handleUpdateSubmit}
              toggleBuildingUpdateModal={this.props.toggleBuildingUpdateModal}
              roomNumber={this.props.roomNumber}
              roomName={this.props.roomName}
              floor={this.props.floor}
              section={this.props.section}
              details={this.props.details}
              addRoom={this.props.addRoom}
            />
          }
        />
        <ModalWindow
          size="lg"
          modal={this.props.modalForFloor}
          toggleModal={this.props.handleFloorClick}
          modalHeader={"List of Floors"}
          modalBody={
            <FloorTable
              noOfFloors={this.props.noOfFloors}
              handleFloorChange={this.props.handleFloorChange}
              handleFloorUpdateClick={this.props.handleFloorUpdateClick}
              handleDeleteFloorClick={this.props.handleDeleteFloorClick}
              handleFloorClick={this.props.handleFloorClick}
            />
          }
        />
        <ModalWindow
          size="lg"
          modal={this.props.modalForSection}
          toggleModal={this.props.toggleSection}
          modalHeader={"Sections"}
          modalBody={
            <BuildingSectionTable
              wings={this.props.wings}
              deleteSectionName={this.props.deleteSectionName}
              addWing={this.props.addWing}
              buildingId={this.props.buildingId}
              handleChange={this.props.handleChange}
              wing={this.props.wing}
              toggleSection={this.props.toggleSection}
              handleSectionUpdate={this.props.handleSectionUpdate}
              updatedSectionName={this.props.updatedSectionName}
              updateSectionSubmit={this.props.updateSectionSubmit}
              handleCancelUpdate={this.props.handleCancelUpdate}
              sectionModalName={this.props.sectionModalName}
            />
          }
        />
        <ModalWindow
          size="lg"
          modal={this.props.modalRoomInsertForm}
          toggleModal={this.props.handleRoomInsertFormModal}
          modalHeader={"Add Rooms"}
          modalBody={
            <RoomInsertForm
              errors={this.props.errors}
              noOfFloors={this.props.noOfFloors}
              isAvailable={this.props.isAvailable}
              wings={this.props.wings}
              facility={this.props.facility}
              roomObject={this.props.roomObject}
              roomNumber={this.props.roomNumber}
              roomName={this.props.roomName}
              floor={this.props.floor}
              section={this.props.section}
              details={this.props.details}
              addRoom={this.props.addRoom}
              handleChange={this.props.handleChange}
              modalRoomInsertForm={this.props.modalRoomInsertForm}
              modalRoomUpdateForm={this.props.modalRoomUpdateForm}
              handleRoomInsertFormModal={this.props.handleRoomInsertFormModal}
            />
          }
        />
        <ModalWindow
          fullWidth={true}
          modal={this.props.modalBuildingDetailModal}
          toggleModal={this.props.handleBuildingDetailModal}
          modalHeader={"Details"}
          modalBody={
            <BuildingDetailList
              buildingDetailList={this.props.buildingDetailList}
              handleUpdateRoomClick={this.props.handleUpdateRoomClick}
              modalRoomUpdateForm={this.props.modalRoomUpdateForm}
              roomNumber={this.props.roomNumber}
              roomName={this.props.roomName}
              floor={this.props.floor}
              section={this.props.section}
              details={this.props.details}
              handleChange={this.props.handleChange}
              errors={this.props.errors}
              noOfFloors={this.props.noOfFloors}
              wings={this.props.wings}
              obj={this.props.obj}
              handleRoomUpdateSubmitClick={
                this.props.handleRoomUpdateSubmitClick
              }
              handleRoomUpdateForm={this.props.handleRoomUpdateForm}
              wings={this.props.wings}
              handledeleteRoom={this.props.handledeleteRoom}
              roomObject={this.props.roomObject}
              sectionModalName={this.props.sectionModalName}
            />
          }
        />
      </>
    );
  }
}

export default ListFacilities;
