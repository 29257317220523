import React, { Component } from "react";

class StudentClassroomOptionalSubjectTableRow extends Component {
  state = {};
  render() {
    let subj = this.props.subject;
    return (
      <tr key={this.props.idx}>
        <td>{subj.studentName}</td>
        <td>{subj.subjectName}</td>
        {this.props.permissionEditOptionalSubject ||
        this.props.permissionDeleteOptionalSubject ? (
          <td>
            {this.props.permissionDeleteOptionalSubject ? (
              <button
                className="tt-button tt-button-primary"
                onClick={(e) => this.props.handleDelete(e, subj)}
              >
                Unassign
              </button>
            ) : null}
          </td>
        ) : null}
      </tr>
    );
  }
}

export default StudentClassroomOptionalSubjectTableRow;
