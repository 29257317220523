import React, { Component } from "react";
import CreateMCQHeader from "./CreateMCQHeader";
import CreateMCQQuestion from "./CreateMCQQuestion";
import "../../assets/scss/mcq.scss";
import swal from "sweetalert";
import { URL, draggable, userRoleCode } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import { connect } from "react-redux";
import { getMcqList } from "../../ducks/MCQDucks";

class CreateMCQ extends Component {
  state = {
    topic: "",
    subjectId: "",
    creatorPeopleId: "",
    educationLevels: [],
    educationLevelCode: "",
    subjectList: [],
    groupsArray: [],
    selectedGroups: [],
    testType: [],
    selectedTestType: "",
    timer: "",
    peopleSpinner: false,
    selectedIndividual: [],
    peopleArray: [],
    mcqQuestions: [
      {
        Question: "",
        explanation: "",
        isOpen: false,
        mcqQuestionOptions: [
          {
            optionValue: "",
            isCorrect: false,
          },
        ],
      },
    ],
  };

  clearData = () => {
    this.setState({
      topic: "",
      subjectId: "",
      creatorPeopleId: "",
      educationLevelCode: "",
      selectedGroups: [],
      selectedIndividual: [],
      selectedTestType: "",
      timer: "",
      mcqQuestions: [
        {
          Question: "",
          explanation: "",
          isOpen: false,
          mcqQuestionOptions: [
            {
              optionValue: "",
              isCorrect: false,
            },
          ],
        },
      ],
    });
  };

  clearState = () => {
    swal({
      title: "Are you sure?",
      text: "Once cleared, you will not be able to recover this record!",
      // icon: "warning",
      closeOnClickOutside: false,
      allowOutsideClick: false,
      buttons: true,
      dangerMode: true,
    }).then((deleteConfirm) => {
      if (deleteConfirm) {
        this.clearData();
      } else {
      }
    });
    draggable();
  };

  componentDidMount() {
    this.getEducationLevel();
    this.getAllGroups();
    this.getTestType();
    this.getGroupAssociatedPeople();
    if (this.props.update) {
      this.setUpdateData();
    }
  }

  setUpdateData = () => {
    let updateData = this.props.updateData;
    if (updateData.subjectId) {
      let data = { id: updateData.subjectId };
      axiosPost(URL.selectByKeySubjectList, data, (res) => {
        if (res.status === 200) {
          this.setState(
            {
              educationLevelCode: res.data.data.assessmentModuleCode,
            },
            function () {
              this.getSubjects();
              // this.setState({
              //   subjectId: parseInt(updateData.subjectId),
              // });
            }
          );
        }
      });
    }

    //For Group
    let updatedGroups = updateData.groups;
    let selectedGroups = [];
    if (updatedGroups && updatedGroups.length > 0) {
      updatedGroups.forEach((el) => {
        selectedGroups.push({ label: el.name, value: el.id });
      });
    }

    //For People
    let updatedPeople = updateData.people;
    let selectedIndividual = [];
    if (updatedPeople && updatedPeople.length > 0) {
      updatedPeople.forEach((up) => {
        selectedIndividual.push({
          label: up.name,
          value: up.id,
        });
      });
    }
    //For mcqQuestions
    let updatedMcqQuestions = updateData.mcqQuestions;
    let mcqQuestions = [];
    if (updatedMcqQuestions && updatedMcqQuestions.length > 0) {
      updatedMcqQuestions.forEach((mq) => {
        let correctExplanation = "";
        let mcqQuestionOptions = [];
        if (mq.mcqQuestionOptions.length > 0) {
          mq.mcqQuestionOptions.forEach((mqO) => {
            mcqQuestionOptions.push({
              optionValue: mqO.optionValue,
              isCorrect: mqO.isCorrect,
            });
            if (mqO.isCorrect) {
              correctExplanation = mqO.explanation;
            }
          });
        }
        mcqQuestions.push({
          Question: mq.question,
          explanation: correctExplanation,
          isOpen: false,
          mcqQuestionOptions: mcqQuestionOptions,
        });
      });
    }
    this.setState({
      topic: updateData.topic,
      selectedGroups,
      selectedTestType: updateData.testTypeId,
      timer: updateData.timer,
      selectedIndividual,
      mcqQuestions,
    });
  };

  getGroupAssociatedPeople = () => {
    let data = {
      peopleId: localStorage.getItem("peopleId"),
    };
    this.setState({ peopleSpinner: true }, function () {
      axiosPost(
        URL.getGroupAssociatedPeople,
        data,
        (response) => {
          if (response.status === 200) {
            let people = response.data.data;
            let peopleArray = [];
            let yearLevel = localStorage.getItem("yearLevel");
            let classroom = localStorage.getItem("classroom");
            if (people.length > 0) {
              people.forEach((el) => {
                let displayName = "";
                if (el.userRoleCode === userRoleCode.roleStudent) {
                  displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
                } else {
                  displayName = `${el.name} [${el.userRole}]`;
                }
                peopleArray.push({ label: displayName, value: el.id });
              });
            }

            this.setState({ peopleArray, peopleSpinner: false });
          }
        },
        (failur) => {
          this.setState({ peopleSpinner: false });
        }
      );
    });
  };

  getTestType = () => {
    axiosPost(URL.getTestType, {}, (response) => {
      if (response.status === 200) {
        this.setState({ testType: response.data.data });
      }
    });
  };

  getAllGroups = () => {
    axiosPost(URL.getAllGroups, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        let groupsArray = [];
        groups.forEach((element) => {
          groupsArray.push({ label: element.name, value: element.id });
        });
        this.setState({
          groupsArray,
        });
      }
    });
  };

  getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ educationLevels: response.data.data });
      }
    });
  };

  getSubjects = () => {
    let data = {
      assessmentModuleCode: this.state.educationLevelCode,
    };
    axiosPost(URL.subjectList, data, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
        if (this.props.update) {
          this.setState({ subjectId: this.props.updateData.subjectId });
        }
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "educationLevelCode") {
        this.getSubjects();
      }
    });
  };

  addOption = (e, idx) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    mcqQuestions[idx].mcqQuestionOptions.push({
      optionValue: "",
      isCorrect: false,
    });
    this.setState({ mcqQuestions });
  };

  removeOption = (e, idx, oIdx) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    if (mcqQuestions[idx].mcqQuestionOptions.length < 2) {
      swal({ title: "Error", text: "Atleast one option should be given" });
      return;
    } else {
      mcqQuestions[idx].mcqQuestionOptions.splice(oIdx, 1);
      this.setState({ mcqQuestions });
    }
  };

  handleChangeSelect = (selectedItem) => {
    this.setState({
      selectedGroups: selectedItem,
    });
  };

  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  handleMcqOptionChange = (e, oIdx, idx) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let mcqQuestions = [...this.state.mcqQuestions];
    if (name === "isCorrect") {
      if (mcqQuestions[idx].mcqQuestionOptions.length > 0) {
        mcqQuestions[idx].mcqQuestionOptions.forEach((el) => {
          el.isCorrect = false;
        });
      }
    }
    mcqQuestions[idx].mcqQuestionOptions[oIdx][name] = value;
    this.setState({ mcqQuestions });
  };

  handleMcqQuestionChange = (e, idx) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let mcqQuestions = [...this.state.mcqQuestions];
    mcqQuestions[idx][name] = value;
    this.setState({ mcqQuestions });
  };

  handleExplanation = (e, idx) => {
    let mcqQuestions = [...this.state.mcqQuestions];
    mcqQuestions[idx]["explanation"] = e;
    this.setState({ mcqQuestions });
  };

  addQuestion = (e) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    mcqQuestions.push({
      Question: "",
      explanation: "",
      isOpen: false,
      mcqQuestionOptions: [
        {
          optionValue: "",
          isCorrect: false,
        },
      ],
    });
    this.setState({ mcqQuestions });
  };

  removeQuestion = (e, idx) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    mcqQuestions.splice(idx, 1);
    if (mcqQuestions.length < 1) {
      swal({ title: "Error", text: "Atleast one question is necessary" });
    } else {
      this.setState({ mcqQuestions });
    }
  };

  openExplanation = (e, idx) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    mcqQuestions.forEach((el) => {
      el.isOpen = false;
    });
    mcqQuestions[idx].isOpen = true;
    this.setState({ mcqQuestions });
  };

  closeExplanation = (e, idx) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    if (idx !== undefined) {
      mcqQuestions[idx].explanation = "";
    }
    mcqQuestions.forEach((el) => {
      el.isOpen = false;
    });
    this.setState({ mcqQuestions });
  };

  saveExplanation = (e, idx) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    mcqQuestions.forEach((el) => {
      el.isOpen = false;
    });
    this.setState({ mcqQuestions });
  };

  handleSubmit = (e, param) => {
    e.preventDefault();
    let mcqQuestions = [...this.state.mcqQuestions];
    let selectedGroups = this.state.selectedGroups
      ? [...this.state.selectedGroups]
      : [];
    let selectedIndividual = this.state.selectedIndividual
      ? [...this.state.selectedIndividual]
      : [];
    let groups = [];
    let newMcqQuestions = [];
    let count = 0;
    let correctAnswer = 0;

    //Validating Data
    if (mcqQuestions.length > 0) {
      mcqQuestions.forEach((el) => {
        if (el.Question === "") {
          count++;
        }
        let correct = 0;
        el.mcqQuestionOptions.forEach((opt) => {
          if (opt.optionValue === "") {
            count++;
          }
          if (opt.isCorrect === true) {
            correct++;
          }
        });
        if (correct === 0 || correct > 1) {
          correctAnswer++;
        }
      });
      if (count > 0) {
        swal({
          title: "Error",
          text: "Fill all the questions and options first",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        return;
      }
    }
    if (this.state.topic === "" || this.state.selectedTestType === "") {
      swal({
        title: "Error",
        text: "MCQ title or test type is missing",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    } else if (selectedGroups.length === 0 && selectedIndividual.length === 0) {
      swal({
        title: "Error",
        text: "MCQ should be target to atleast group or individual",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
    if (this.state.selectedTestType == 1) {
      if (this.state.timer === "") {
        swal({
          title: "Error",
          text: "Timer is missing",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        return;
      }
    }
    if (correctAnswer > 0) {
      swal({
        title: "Error",
        text: "Each question must have 1 correct answer",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return;
    }
    //End of Validation

    if (mcqQuestions.length > 0) {
      mcqQuestions.forEach((el) => {
        let options = [];
        el.mcqQuestionOptions.forEach((opt) => {
          options.push({
            optionValue: opt.optionValue,
            isCorrect: opt.isCorrect,
            explanation: opt.isCorrect ? el.explanation : "",
          });
        });
        newMcqQuestions.push({
          question: el.Question,
          mcqQuestionOptions: options,
        });
      });
    }
    if (selectedGroups.length > 0) {
      selectedGroups.forEach((el) => {
        groups.push({ id: el.value });
      });
    }
    let peopleIds = [];
    if (selectedIndividual.length > 0) {
      selectedIndividual.forEach((el) => {
        peopleIds.push(el.value);
      });
    }

    let data = {
      topic: this.state.topic,
      subjectId: parseInt(this.state.subjectId),
      mcqQuestions: newMcqQuestions,
      groups: groups,
      testTypeId: this.state.selectedTestType,
      timer: this.state.timer,
      peopleIds: peopleIds,
      isDraft: param,
    };
    if (this.props.update) {
      data.id = this.props.updateData.id;
    }

    let url = "";
    if (this.props.update) {
      url = URL.updateMcq;
    } else {
      url = URL.insertMcq;
    }
    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: response.data.message
            ? response.data.message
            : "Record successfully inserted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.clearData();
        this.props.getMcqList("my");
        if (this.props.update) {
          this.props.toggleUpdateMOdal();
        }
      }
    });
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={this.props.update ? { height: "87.5vh" } : { height: "79.5vh" }}
      >
        <div className="tt-group-header">Create MCQ</div>
        <div
          className="tt-newListBox mb-1"
          style={
            this.props.update
              ? { height: "69.4vh", overflow: "hidden auto" }
              : { height: "60.5vh", overflowX: "hidden", overflowY: "auto" }
          }
        >
          <CreateMCQHeader
            educationLevels={this.state.educationLevels}
            educationLevelCode={this.state.educationLevelCode}
            topic={this.state.topic}
            handleChange={this.handleChange}
            handleChangeSelect={this.handleChangeSelect}
            subjectList={this.state.subjectList}
            subjectId={this.state.subjectId}
            groupsArray={this.state.groupsArray}
            selectedGroups={this.state.selectedGroups}
            peopleSpinner={this.state.peopleSpinner}
            peopleArray={this.state.peopleArray}
            selectedIndividual={this.state.selectedIndividual}
            handleIndividualSelect={this.handleIndividualSelect}
          ></CreateMCQHeader>
          <CreateMCQQuestion
            mcqQuestions={this.state.mcqQuestions}
            addOption={this.addOption}
            addQuestion={this.addQuestion}
            openExplanation={this.openExplanation}
            closeExplanation={this.closeExplanation}
            saveExplanation={this.saveExplanation}
            handleMcqOptionChange={this.handleMcqOptionChange}
            handleMcqQuestionChange={this.handleMcqQuestionChange}
            removeOption={this.removeOption}
            removeQuestion={this.removeQuestion}
            handleExplanation={this.handleExplanation}
          ></CreateMCQQuestion>
          <div className="row">
            <div className="col text-right mt-2">
              <div>
                <button
                  className="tt-button tt-button-primary"
                  onClick={(e) => this.addQuestion(e)}
                  id="addQuestion"
                >
                  Add Question
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row button-bg-color pr-4">
            <div className="col-md-2 text-right mt-1">
              <strong>MCQ Type: </strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </div>
            <div className="col">
              <select
                className="form-control"
                name="selectedTestType"
                value={this.state.selectedTestType}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Select MCQ Type
                </option>
                {this.state.testType.map((type, idx) => {
                  return <option value={type.id}>{type.type}</option>;
                })}
              </select>
            </div>
            {this.state.selectedTestType == 1 ? (
              <>
                <div className="col-md-1 text-right mt-1">
                  <strong>Time: </strong>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="timer"
                    value={this.state.timer}
                    onChange={this.handleChange}
                    placeholder="in minutes"
                  ></input>
                </div>
              </>
            ) : null}
            {/* <div className="col-md-auto text-right">
              <div>
                <button
                  className="tt-button tt-button-primary"
                  onClick={(e) => this.addQuestion(e)}
                >
                  Add Question
                </button>
              </div>
            </div> */}
            <div className="col-md-3 text-right">
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={(e) => this.handleSubmit(e, false)}
              >
                Publish
              </button>
              {/* {this.props.update ? (
                ""
              ) : (
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={(e) => this.handleSubmit(e, true)}
                >
                  Save Draft
                </button>
              )} */}
              <button
                className="tt-button tt-button-primary"
                onClick={this.clearState}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionsToProps = {
  getMcqList,
};

export default connect(mapStateToProps, mapActionsToProps)(CreateMCQ);
