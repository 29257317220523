import swal from "sweetalert";
import { draggable, checkRolePermissions } from "../utils/Constants";
import moment from "moment";
import userImage from "../assets/images/user-image.png";
import adbs from "ad-bs-converter";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";

export const wildcardFilterArray = (str, array) =>
  array.filter((item) =>
    new RegExp("^" + str.toLowerCase().replace(/\*/g, ".*") + "$").test(
      item.toLowerCase()
    )
  );

/**
 *
 * @param {*} str A string value which is checked against item inside array.
 * @param {*} array An array of objects with value id and name.
 * @description This method is mostly used by Combobox component to filter an array of objects by string value input by user.
 */

export const wildcardFilterObjectArray = (str, array) =>
  array.filter((item) =>
    new RegExp("^" + str.toLowerCase().replace(/\*/g, ".*") + "$").test(
      item.name.toLowerCase()
    )
  );

/**
 *
 * @param {HttpResponse} response
 * @description Displays an error alert box. Built specifically for displaying http error message.
 */
export const displayErrorAlert = (error) => {
  swal(
    "Error",
    `${error ? (error.response ? error.response.data.message : error) : error}`,
    ""
  );
};

export const displayFormDataValidationError = (error) => {
  swal("Error", `${error.response.data.data}`, "");
};

export const displayErrorMessage = (error) => {
  swal("Error", `${error?.response?.data?.message}`, "");
};

export const showErroMessage = (response) => {
  swal("Error", response?.data?.message, "");
};
export const displayNoRecordAlert = () => {
  swal("Error", "No Roles for this Department", "");
};

export const displaySuccessAlert = (response, callback, param) => {
  swal("Success", `${response?.data?.message}`, "").then(() => {
    if (callback !== undefined) callback(param);
  });
};

export const displayConfirmDeleteAlert = (params, handleDelete) => {
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this record!",
    closeOnClickOutside: false,
    allowOutsideClick: false,
    buttons: true,
    dangerMode: true,
  }).then((deleteConfirm) => {
    if (deleteConfirm) {
      handleDelete(params);
    } else {
    }
  });
  draggable();
};

export const displayConfirmDeleteSelectedAlert = (
  getSelectedItems,
  handleDeleteSelected
) => {
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this record!",
    // icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((deleteConfirm) => {
    if (deleteConfirm) {
      let params = getSelectedItems();
      handleDeleteSelected(params);
    } else {
      swal("Your record is safe!");
    }
  });
};

/**
 * Function to map arrays and objects from underScore to camelcase
 *
 */

export const toCamelCase = (o) => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (value !== null && typeof value === "object") {
        value = toCamelCase(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = origKey.replace(/([-_][a-z])/gi, (s) => {
          return s.toUpperCase().replace("-", "").replace("_", "");
        });

        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const toSnakeCase = (o) => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (value !== null && typeof value === "object") {
        value = toSnakeCase(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = origKey
          .split(/(?=[A-Z])/)
          .join("_")
          .toLowerCase();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toSnakeCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const formatDate = (date) => {
  return moment(date).format("Do MMMM, YYYY");
};

export const convertNepaliDate = (dateArg, displayArg) => {
  // if (checkRolePermissions("select-nepali-date", "activity")) {

  let dateData = dateArg !== "" ? dateArg : new Date();
  const date = adbs.ad2bs(moment(dateData).format("YYYY/MM/DD"));

  const nepaliDate = date.ne;
  const newDate = `${nepaliDate.year}-${
    nepaliDate.month === 11 || nepaliDate.month === 12
      ? nepaliDate.month
      : "0" + nepaliDate.month
  }-${nepaliDate.day}`;
  return nepaliToEnglishNumber(newDate);
};

export const displayNepaliDate = (dateArg, displayArg) => {
  if (checkRolePermissions("select-nepali-date", "activity")) {
    if (new Date(dateArg).getFullYear() > 1925) {
      const date = adbs.ad2bs(moment(dateArg).format("YYYY/MM/DD"));

      const nepaliDate = date.ne;
      if (displayArg) {
        return `[ ${nepaliDate[displayArg]}, ${nepaliDate.year}-${nepaliDate.month}-${nepaliDate.day} ]`;
      } else {
        return `[ ${nepaliDate.year}-${nepaliDate.month}-${nepaliDate.day} ]`;
      }
    } else {
      return null;
    }
  }
};

export const displayMessage = (title, text, callbackFunc) => {
  swal({
    title: title,
    text: text,
    allowOutsideClick: false,
    closeOnClickOutside: false,
  }).then(() => {
    callbackFunc && callbackFunc();
  });
  draggable();
};
/**
 * @author azzetaKarmacharya
 * @description to check if the object with given value
 *  for the given uniqueAttribute already exists or not in given array.
 * @param {*} array  name of array to be checked
 * @param {*} uniqueAttribute  name of the uniqueAttribute whose value is to be compared
 * @param {*} value  value to check
 */
export const checkValueExitsInArray = (array, uniqueAttribute, value) => {
  if (value !== "") {
    var index = array.findIndex(function (a) {
      return a[uniqueAttribute] === value;
    });
    return index;
  }
};

export const handleError = (e) => {
  e.target.src = userImage;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const emailValidate = (email) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(mailformat)) {
    return true;
  } else {
    return false;
  }
};

export const compareGardeLevelsByGPAPoint = (a, b) => {
  const nameA = a.gpaMax;
  const nameB = b.gpaMax;

  // Check if both names are strings
  if (isNaN(nameA) && isNaN(nameB)) {
    return nameA.localeCompare(nameB);
  }

  // If only the first name is a string, move it to the front
  if (isNaN(nameA)) {
    return -1;
  }

  // If only the second name is a string, move it to the front
  if (isNaN(nameB)) {
    return 1;
  }

  // Both names are numbers, compare them numerically
  return parseFloat(nameB) - parseFloat(nameA);
};

export const mobilePhoneValidator = (num) => {
  const r = /^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  if (num && r.test(num)) {
    return true;
  } else {
    return false;
  }
};

export function generateGrades() {
  const grades = [];

  for (
    let charCode = "A".charCodeAt(0);
    charCode <= "Z".charCodeAt(0);
    charCode++
  ) {
    const letter = String.fromCharCode(charCode);

    grades.push(`${letter}+`, letter);
  }

  return grades;
}

export const checkValidURL = (link) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  let isValid = pattern.test(link);
  return isValid;
};
