import React, { Component } from "react";
import UserSearchSection from "./UserSearchSection";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "../../assets/scss/userSetting.scss";
import { axiosPost } from "../../utils/AxiosApi";
import { draggable, URL } from "../../utils/Constants";
import ForgetPasswordRequestUser from "./ForgetPasswordRequestUser";
import ModalWindow from "../UI/ModalWindow";
import UpdatePasswordModal from "./UpdatePasswordModal";
import AllUsers from "./AllUsers";
import swal from "sweetalert";

class UserSetting extends Component {
  state = {
    statuses: ["pending", "updated", "rejected"],
    enableStatus: [
      { name: "Enabled User", value: true },
      { name: "Disabled User", value: false },
    ],
    status: "",
    enabled: "",
    peopleName: "",
    username: "",
    userRole: "",
    userRoles: [],
    forgetPasswordRequestUsers: [],
    requestOffset: 0,
    requestTotalRecords: 0,
    requestRowCountLimit: 20,
    requestCurrentPage: 1,
    displayRequestSpinner: false,
    search: false,
    allUsers: [],
    allUserOffset: 0,
    allUserTotalRecords: 0,
    allUserRowCountLimit: 20,
    allUserCurrentPage: 1,
    displayAllUserSpinner: false,
    forgotModal: false,
    selectedUsername: "",
    selectedId: "",
    selectedTabId: "allUser",
  };

  componentDidMount() {
    this.getForgetPasswordUsers();
    this.getAllUsers();
    this.getUserRoles();
  }

  getUserRoles = () => {
    axiosPost(URL.getUserRoles, {}, (response) => {
      if (response.status === 200) {
        this.setState({ userRoles: response.data.data });
      }
    });
  };

  getAllUsers = (action) => {
    let data = {
      offset: this.state.allUserOffset,
      rowCountLimit: this.state.allUserRowCountLimit,
    };
    if (action !== "paginate") {
      this.setState({ allUserCurrentPage: 1, allUserOffset: 0 });
    }
    this.setState({ displayAllUserSpinner: true }, function () {
      axiosPost(
        URL.getAllUsers,
        data,
        (response) => {
          if (response.status === 200) {
            this.setState({
              allUsers: response.data.data,
              allUserTotalRecords: response.data.totalRecordsCount,
              displayAllUserSpinner: false,
            });
          }
        },
        (error) => {
          this.setState({ displayAllUserSpinner: false });
        }
      );
    });
  };

  getForgetPasswordUsers = (action) => {
    let data = {
      offset: this.state.requestOffset,
      rowCountLimit: this.state.requestRowCountLimit,
    };
    if (action !== "paginate") {
      this.setState({ requestCurrentPage: 1, requestOffset: 0 });
    }
    this.setState({ displayRequestSpinner: true }, function () {
      axiosPost(
        URL.getForgetPasswordRequestUsers,
        data,
        (response) => {
          if (response.status === 200) {
            this.setState({
              forgetPasswordRequestUsers: response.data.data,
              requestTotalRecords: response.data.totalRecordsCount,
              displayRequestSpinner: false,
            });
          }
        },
        (error) => {
          this.setState({ displayRequestSpinner: false });
        }
      );
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };

  //Search User Setting

  handleSearch = () => {
    this.setState(
      {
        requestOffset: 0,
        requestCurrentPage: 1,
        allUserOffset: 0,
        allUserCurrentPage: 1,
        search: true,
        displayRequestSpinner: true,
        displayAllUserSpinner: true,
      },
      function () {
        this.requestSearch();
        this.allSearch();
      }
    );
  };

  allSearch = () => {
    let data = {
      username: this.state.username,
      userRoleCode: this.state.userRole,
      peopleName: this.state.peopleName,
      enabled: this.state.enabled,
      offset: 0,
      rowCountLimit: 20,
    };
    this.searchAllUsers(data);
  };

  requestSearch = () => {
    let data = {
      username: this.state.username,
      userRoleCode: this.state.userRole,
      peopleName: this.state.peopleName,
      status: this.state.status,
      offset: 0,
      rowCountLimit: 20,
    };
    this.searchForgetRequestUsers(data);
  };

  searchForgetRequestUsers = (data) => {
    axiosPost(
      URL.getForgetPasswordRequestUsers,
      data,
      (response) => {
        this.setState({
          forgetPasswordRequestUsers: response.data.data,
          requestTotalRecords: response.data.totalRecordsCount,
          displayRequestSpinner: false,
        });
      },
      (error) => {
        this.setState({ displayRequestSpinner: false });
      }
    );
  };

  searchAllUsers = (data) => {
    this.setState(
      {
        allUsers: [],
      },
      () => {
        axiosPost(
          URL.getAllUsers,
          data,
          (response) => {
            this.setState({
              allUsers: response.data.data,
              allUserTotalRecords: response.data.totalRecordsCount,
              displayAllUserSpinner: false,
            });
          },
          (error) => {
            this.setState({ displayAllUserSpinner: false });
          }
        );
      }
    );
  };

  //For ForgetPassword pagiantion
  requestHandlePageChange = (page) => {
    this.reRenderForgetRequest(page);
    this.setState({ requestCurrentPage: page });
  };

  reRenderForgetRequest = (page) => {
    let offset =
      page * parseInt(this.state.requestRowCountLimit) -
      parseInt(this.state.requestRowCountLimit);
    this.setState(
      { displayRequestSpinner: true, requestOffset: offset },
      function () {
        if (this.state.search) {
          let data = {
            username: this.state.username,
            userRoleCode: this.state.userRole,
            peopleName: this.state.peopleName,
            status: this.state.status,
            offset: this.state.requestOffset,
            rowCountLimit: this.state.requestRowCountLimit,
          };
          this.searchForgetRequestUsers(data);
        } else {
          this.getForgetPasswordUsers("paginate");
        }
      }
    );
  };

  requestHandleNextPage = () => {
    let nextPage = this.state.requestCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(
        this.state.requestTotalRecords / this.state.requestRowCountLimit
      )
    )
      return;
    else {
      this.reRenderForgetRequest(nextPage);
      this.setState({ requestCurrentPage: nextPage });
    }
  };

  requestHandlePreviousPage = () => {
    if (this.state.requestCurrentPage !== 1) {
      this.reRenderForgetRequest(this.state.requestCurrentPage - 1);
      this.setState((prevState) => ({
        requestCurrentPage: prevState.requestCurrentPage - 1,
      }));
    }
  };

  //forAllUserPagination
  allUserHandlePageChange = (page) => {
    this.reRenderAllUsers(page);
    this.setState({ allUserCurrentPage: page });
  };

  reRenderAllUsers = (page) => {
    let offset =
      page * parseInt(this.state.allUserRowCountLimit) -
      parseInt(this.state.allUserRowCountLimit);
    this.setState(
      { displayAllUserSpinner: true, allUserOffset: offset, allUsers: [] },
      function () {
        if (this.state.search) {
          let data = {
            username: this.state.username,
            userRoleCode: this.state.userRole,
            peopleName: this.state.peopleName,
            offset: this.state.allUserOffset,
            rowCountLimit: this.state.allUserRowCountLimit,
          };
          this.searchAllUsers(data);
        } else {
          this.getAllUsers("paginate");
        }
      }
    );
  };

  allUserHandleNextPage = () => {
    let nextPage = this.state.allUserCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(
        this.state.allUserTotalRecords / this.state.allUserRowCountLimit
      )
    )
      return;
    else {
      this.reRenderAllUsers(nextPage);
      this.setState({ allUserCurrentPage: nextPage });
    }
  };

  allUserHandlePreviousPage = () => {
    if (this.state.allUserCurrentPage !== 1) {
      this.reRenderAllUsers(this.state.allUserCurrentPage - 1);
      this.setState((prevState) => ({
        allUserCurrentPage: prevState.allUserCurrentPage - 1,
      }));
    }
  };
  //

  togalForgotModal = (username, id) => {
    this.setState({ forgotModal: !this.state.forgotModal });
    if (username) {
      this.setState({ selectedUsername: username, selectedId: id });
    }
  };

  changeTab = (tabId) => {
    this.setState({ selectedTabId: tabId, enabled: "", status: "" });
  };

  enableDisableUser = (id, status) => {
    let data = {
      id: id,
      enabled: status,
    };
    axiosPost(URL.enableDisableUser, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: `User ${status ? "enabled" : "disabled"}`,
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getAllUsers();
      }
    });
  };

  rejectPassword = (username, id) => {
    let data = {
      username: username,
      id: id,
    };
    axiosPost(URL.rejectPassword, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Password Update request rejected",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getForgetPasswordUsers();
      }
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "80.5vh" }}>
        <UserSearchSection
          statuses={this.state.statuses}
          status={this.state.status}
          enabled={this.state.enabled}
          peopleName={this.state.peopleName}
          username={this.state.username}
          userRole={this.state.userRole}
          userRoles={this.state.userRoles}
          handleChange={this.handleChange}
          handleSearch={this.handleSearch}
          selectedTabId={this.state.selectedTabId}
          enableStatus={this.state.enableStatus}
        ></UserSearchSection>
        <div className="tt-postTabs tt-inner-content">
          <Tabs
            onChange={(tabId) => this.changeTab(tabId)}
            className="tt-userSetting"
          >
            <TabList style={{ marginBottom: "0 !important" }}>
              <Tab tabFor="allUser">All Users</Tab>
              <Tab tabFor="fogetPasswordRequest">Forget Password Requests</Tab>
            </TabList>
            <TabPanel tabId="allUser" style={{ height: "58vh" }}>
              <AllUsers
                allUserOffset={this.state.allUserOffset}
                allUserTotalRecords={this.state.allUserTotalRecords}
                allUserCurrentPage={this.state.allUserCurrentPage}
                allUserRowCountLimit={this.state.allUserRowCountLimit}
                allUserHandlePageChange={this.allUserHandlePageChange}
                allUserHandleNextPage={this.allUserHandleNextPage}
                allUserHandlePreviousPage={this.allUserHandlePreviousPage}
                toggleForgotModal={this.togalForgotModal}
                displayAllUserSpinner={this.state.displayAllUserSpinner}
                allUsers={this.state.allUsers}
                enableDisableUser={this.enableDisableUser}
              ></AllUsers>
            </TabPanel>
            <TabPanel tabId="fogetPasswordRequest" style={{ height: "59vh" }}>
              <ForgetPasswordRequestUser
                forgetPasswordRequestUsers={
                  this.state.forgetPasswordRequestUsers
                }
                requestOffset={this.state.requestOffset}
                requestTotalRecords={this.state.requestTotalRecords}
                requestCurrentPage={this.state.requestCurrentPage}
                requestRowCountLimit={this.state.requestRowCountLimit}
                requestHandlePageChange={this.requestHandlePageChange}
                requestHandleNextPage={this.requestHandleNextPage}
                requestHandlePreviousPage={this.requestHandlePreviousPage}
                toggleForgotModal={this.togalForgotModal}
                displayRequestSpinner={this.state.displayRequestSpinner}
                rejectPassword={this.rejectPassword}
              ></ForgetPasswordRequestUser>
            </TabPanel>
          </Tabs>
        </div>
        <ModalWindow
          modal={this.state.forgotModal}
          toggleModal={this.togalForgotModal}
          modalHeader="Update New Password"
          modalBody={
            <UpdatePasswordModal
              toggleForgotModal={this.togalForgotModal}
              getForgetPasswordUsers={this.getForgetPasswordUsers}
              selectedUsername={this.state.selectedUsername}
              selectedId={this.state.selectedId}
              getAllUsers={this.getAllUsers}
            ></UpdatePasswordModal>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default UserSetting;
