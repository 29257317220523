import React, { Component } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { postType, reactServer, URL } from "../../../utils/Constants";
import Comment from "../Notice/Comment";
import { Modal, ModalBody } from "reactstrap";
import FullImage from "../FullImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import ModalWindow from "../../UI/ModalWindow";
import { displayNepaliDate } from "../../../utils/Utils";

class ReadMoreTemplate extends Component {
  state = {
    posts: "",
    imageModal: false,
    imageSrc: "",
    slideShareURl: "",
    keywordModal: false,
  };

  componentDidMount() {
    this.embedVideo();
  }

  embedVideo = () => {
    let posts = this.props.post;
    return posts
      ? posts.contents.forEach((el) => {
          if (el.content.includes("youtube")) {
            el.content.replace("watch?v=", "embed/");
          }
        })
      : null;
  };

  getEmbedLink = (link) => {
    let embeddedURL = "";
    let videoId = "";
    if (link.includes("youtube") || link.includes("youtu.be")) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = link.match(regExp);
      if (match && match[7].length === 11) {
        videoId = match[7];
        embeddedURL = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (link.includes("dailymotion") || link.includes("dai.ly")) {
      videoId = this.getDailyMotionId(link);
      embeddedURL = `https://www.dailymotion.com/embed/video/${videoId}`;
    } else if (link.includes("vimeo")) {
      videoId = this.getVimeoId(link);
      embeddedURL = `https://player.vimeo.com/video/${videoId}`;
    } else if (link.includes("slideshare")) {
      let self = this;
      let src = "";
      $.getJSON(
        "https://noembed.com/embed",
        { format: "json", url: link },
        function (data) {
          if (data !== undefined || data) {
            let htmlCode = data.html;
            src = self.getSlideShareSrc(htmlCode);
            self.setState({ slideShareURl: src });
          }
        }
      );
      embeddedURL = this.state.slideShareURl;
    } else if (link.includes("soundcloud.com")) {
      embeddedURL = "https://w.soundcloud.com/player/?url=" + link;
    }
    return embeddedURL;
  };

  getSlideShareSrc = (code) => {
    let count = 0;
    let link = "";
    for (let i = 0; i < code.length; i++) {
      if (code.charAt(i) === '"') {
        count++;
      }
      if (count !== 0 && count < 2 && code.charAt(i + 1) !== '"') {
        link += code.charAt(i + 1);
      }
    }
    return link;
  };

  getDailyMotionId = (url) => {
    var m = url.match(
      /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
    );
    if (m === null) {
      var n = url.match(
        /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
      );
      if (n !== null) {
        if (n[4] !== undefined) {
          return n[4];
        }
        return n[2];
      }
    } else if (m !== null) {
      if (m[4] !== undefined) {
        return m[4];
      }
      return m[2];
    } else {
      return null;
    }
  };

  getVimeoId = (url) => {
    var regExp =
      /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    var parseUrl = url.match(regExp);
    return parseUrl[5];
  };

  //For full Image display

  openFullImage = (e, imageSrc) => {
    this.setState({ imageModal: true, imageSrc: imageSrc });
  };

  toggleImageModal = () => {
    this.setState({ imageModal: !this.state.imageModal });
  };

  displayKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.props.post
          ? this.props.post.postKeywords
            ? this.props.post.postKeywords.map((keyword, idx) => {
                return <li key={idx}>{keyword.keyword}</li>;
              })
            : null
          : null}
      </ol>
    );
  };

  render() {
    return (
      <>
        <div
          className={
            "container-fluid " + (this.props.moderation ? "autoHeight" : "")
          }
          id="noticeModalBody"
        >
          <div className="row mb-1">
            <div className="col">
              {this.props.moderation ? (
                <p className="mb-0">
                  <strong>{this.props.post.heading}</strong>
                </p>
              ) : null}
              <p className="tt-noticeModalDate">
                {this.props.post ? (
                  <span>
                    {moment(this.props.post.createdAt).format(
                      "dddd, MMMM Do YYYY"
                    )}{" "}
                    {displayNepaliDate(
                      this.props.post.createdAt,
                      "strDayOfWeek"
                    )}
                  </span>
                ) : null}
              </p>
            </div>
          </div>
          <div className="tt-mobilePostKeywords">
            {this.props.post ? (
              this.props.post.postKeywords.length > 0 ? (
                <div className="row mb-3">
                  <div className="tt-postTargetList col">
                    {this.props.post.postKeywords.map((keyWord, idx) => {
                      return idx <= 3 ? (
                        keyWord.keyword !== "" ? (
                          <div key={idx}>{keyWord.keyword}</div>
                        ) : null
                      ) : idx === this.props.post.postKeywords.length - 1 ? (
                        <div
                          key={idx}
                          style={{ cursor: "pointer" }}
                          onClick={this.displayKeywordModal}
                        >
                          + {this.props.post.postKeywords.length - 4} more
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          <div className="tt-desktopPostKeywords">
            {this.props.post ? (
              this.props.post.postKeywords.length > 0 ? (
                <div className="row mb-3">
                  <div className="tt-postTargetList col">
                    {this.props.post.postKeywords.map((keyWord, idx) => {
                      return idx <= 11 ? (
                        keyWord.keyword !== "" ? (
                          <div key={idx}>{keyWord.keyword}</div>
                        ) : null
                      ) : idx === this.props.post.postKeywords.length - 1 ? (
                        <div
                          key={idx}
                          style={{ cursor: "pointer" }}
                          onClick={this.displayKeywordModal}
                        >
                          + {this.props.post.postKeywords.length - 12} more
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          {this.props.post ? (
            this.props.post.postThemes.length > 0 ||
            this.props.post.postQuestions.length > 0 ? (
              <div className="container-fluid tt-themeQuestionHolder">
                {this.props.post ? (
                  this.props.post.postThemes ? (
                    this.props.post.postThemes.length > 0 ? (
                      <div className="row">
                        <div className="col tt-theme-header">
                          {this.props.post.postThemes.map((theme, idx) => {
                            return (
                              <p key={idx}>
                                <strong>Theme : </strong>
                                {theme.themeName}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}
                {this.props.post ? (
                  this.props.post.postQuestions ? (
                    this.props.post.postQuestions.length > 0 ? (
                      <div className="row mt-1">
                        <div className="col tt-question-header">
                          <div className="mb-0">
                            Questions that this media addresses :{" "}
                          </div>
                          {this.props.post.postQuestions.map(
                            (question, idx) => {
                              return (
                                <>
                                  <p key={idx}>{question.question}</p>
                                  <span>, </span>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}
              </div>
            ) : null
          ) : null}

          {/* {this.props.post ? (
            this.props.post.contents ? (
              <div className="row">
                <div className="col">
                  <div className="tt-notice-link-media mt-1">
                    {this.props.post.contents.map((content) => {
                      if (
                        content.contentTypeCode === "LNK" &&
                        content.content !== ""
                      ) {
                        return (
                          <iframe
                            key={content.id}
                            title="VideoTab"
                            src={this.getEmbedLink(content.content)}
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            className={
                              this.props.post
                                ? this.props.post.postType ===
                                  postType.mediaCode
                                  ? "mediaIframe-media"
                                  : ""
                                : ""
                            }
                          ></iframe>
                        );
                      }
                      if (content.contentTypeCode === "IMG") {
                        return (
                          <img
                            src={URL.imageSelectURL + content.content}
                            alt="asdaf"
                            className="mediaImgBig"
                            onClick={(e) =>
                              this.openFullImage(
                                e,
                                URL.imageSelectURL + content.content
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        );
                      }
                    })}
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-8 wordBreakHyphen editorPostContent">
                      {ReactHtmlParser(
                        this.props.post
                          ? this.props.post.textContent
                          : "No text content"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
           */}

          {this.props.insertedInsitePostData ? (
            this.props.insertedInsitePostData.contents ? (
              <div className="row">
                <div className="col">
                  <div className="tt-notice-link-media mt-1">
                    {this.props.insertedInsitePostData.contents.map(
                      (content) => {
                        if (
                          content.contentTypeCode === "LNK" &&
                          content.content !== "" &&
                          content.content.includes(reactServer)
                        ) {
                          return (
                            <iframe
                              key={content.id}
                              title="VideoTab"
                              src={this.getEmbedLink(content.content)}
                              frameborder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                              className={
                                this.props.post
                                  ? this.props.insertedInsitePostData
                                      .postType === postType.mediaCode
                                    ? "mediaIframe-media"
                                    : ""
                                  : ""
                              }
                            ></iframe>
                          );
                        }
                        if (content.contentTypeCode === "IMG") {
                          return (
                            <img
                              src={URL.imageSelectURL + content.content}
                              alt="asdaf"
                              className="mediaImgBig"
                              onClick={(e) =>
                                this.openFullImage(
                                  e,
                                  URL.imageSelectURL + content.content
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          );
                        }
                      }
                    )}
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-8 wordBreakHyphen editorPostContent">
                      {ReactHtmlParser(
                        this.props.insertedInsitePostData
                          ? this.props.insertedInsitePostData.textContent
                          : "No text content"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          ) : this.props.post ? (
            this.props.post.contents ? (
              <div className="row">
                <div className="col">
                  <div className="tt-notice-link-media mt-1">
                    {this.props.post.contents.map((content) => {
                      if (
                        content.contentTypeCode === "LNK" &&
                        content.content !== ""
                      ) {
                        return (
                          <iframe
                            key={content.id}
                            title="VideoTab"
                            src={this.getEmbedLink(content.content)}
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            className={
                              this.props.post
                                ? this.props.post.postType ===
                                  postType.mediaCode
                                  ? "mediaIframe-media"
                                  : ""
                                : ""
                            }
                          ></iframe>
                        );
                      }
                      if (content.contentTypeCode === "IMG") {
                        return (
                          <img
                            src={URL.imageSelectURL + content.content}
                            alt="asdaf"
                            className="mediaImgBig"
                            onClick={(e) =>
                              this.openFullImage(
                                e,
                                URL.imageSelectURL + content.content
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        );
                      }
                    })}
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-8 wordBreakHyphen editorPostContent">
                      {ReactHtmlParser(
                        this.props.post
                          ? this.props.post.textContent
                          : "No text content"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}

          {this.props.comments ? (
            this.props.comments.length > 0 ? (
              <div className="tt-noticeRSVP">
                <div className="tt-group-header text-center">Discussion</div>
                <ul className="list-group">
                  {this.props.comments
                    ? this.props.comments.map((comment, idx) => {
                        // if (!comment.deleteStatus)
                        return (
                          <Comment
                            key={comment.id}
                            post={this.props.post}
                            comment={comment}
                            deleteComment={this.props.deleteComment}
                            toggleEditable={this.props.toggleEditable}
                            idx={idx}
                            newCommentValue={this.props.newCommentValue}
                            handleChange={this.props.handleChange}
                            updateComment={this.props.updateComment}
                          />
                        );
                      })
                    : null}
                </ul>
              </div>
            ) : null
          ) : null}
        </div>

        <Modal
          id="fullProfileImage"
          isOpen={this.state.imageModal}
          toggle={this.toggleImageModal}
          centered
          className="tt-profileFullImage"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="tt-ImageCloseIcon"
            onClick={this.toggleImageModal}
          ></FontAwesomeIcon>
          <ModalBody>
            <FullImage image={this.state.imageSrc}></FullImage>
          </ModalBody>
        </Modal>
        <ModalWindow
          modal={this.state.keywordModal}
          toggleModal={this.displayKeywordModal}
          modalHeader="Keywords Assigned"
          modalBody={this.keywordModalBody()}
        ></ModalWindow>
      </>
    );
  }
}

export default ReadMoreTemplate;
