import React, { Component } from "react";

class SubjectiveExamForm extends Component {
  state = {};
  render() {
    return <>Subjective Exam Form</>;
  }
}

export default SubjectiveExamForm;
