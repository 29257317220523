import React, { Component } from "react";
import Chart from "chart.js";
import "../../assets/scss/attendance.scss";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import moment from "moment";
import { isEqual } from "lodash";

class StudentAttendanceDetails extends Component {
  state = {
    attendanceRecord: [],
    attendanceRecordAcademicYear: [],
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    dateFrom: "",
    dateTo: "",
    attendanceRecordFilterDate: [],
    loading: false,
    resolved: false,
    educationLevels: [],
    yearLevels: [],
    educationlevelId: null,
    yearLevelId: null,
    sectionId: null,
    studentId: null,
  };
  componentDidMount() {
    //get attendance records and render chart
    const monthdata = {};
    monthdata.yearMonth = `${this.state.year}-${this.state.month}`;
    if (this.props.admissionId) {
      monthdata.admissionId = this.props.admissionId;
    }
    monthdata.subjectId = this.props.subjectId;
    axiosPost(URL.getAttendanceByDayMonthYear, monthdata, (response) => {
      if (response.status === 200) {
        this.setState({ attendanceRecord: response.data.data }, () => {
          const ctxMonth = document.getElementById("monthSummary");
          this.renderChart(ctxMonth, this.state.attendanceRecord);
        });
      }
    });

    const acaData = {};
    if (this.props.admissionId) {
      acaData.admissionId = this.props.admissionId;
    }
    acaData.subjectId = this.props.subjectId;
    axiosPost(URL.getAttendanceByDayMonthYear, acaData, (response) => {
      if (response.status === 200) {
        this.setState(
          { attendanceRecordAcademicYear: response.data.data },
          () => {
            const ctxAcademic = document.getElementById("academicSummary");
            this.renderChart(
              ctxAcademic,
              this.state.attendanceRecordAcademicYear
            );
          }
        );
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(
        this.state.attendanceRecordFilterDate,
        prevState.attendanceRecordFilterDate
      ) &&
      this.state.attendanceRecordFilterDate.length === 0
    ) {
      const ctxMonth = document.getElementById("monthSummary");
      this.renderChart(ctxMonth, this.state.attendanceRecord);

      const ctxAcademic = document.getElementById("academicSummary");
      this.renderChart(ctxAcademic, this.state.attendanceRecordAcademicYear);
    }
  }

  renderChart = (ctx, attendanceRecord) => {
    const totalDays = attendanceRecord.length;
    const presentDays = attendanceRecord.filter((rec) => rec.isPresent).length;
    const absentDays = totalDays - presentDays;
    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Present Days", "Absent Days"],
        datasets: [
          {
            label: "Attendance",
            data: [presentDays, absentDays],
            backgroundColor: ["#4db14d", "#bd5151"],
            borderColor: ["#4db14d", "#bd5151"],
            borderWidth: 1,
          },
        ],
      },
    });
  };
  render() {
    const totalDays = this.state.attendanceRecord.length;
    const presentDays = this.state.attendanceRecord.filter(
      (rec) => rec.isPresent
    ).length;
    const absentDays = totalDays - presentDays;
    // const name =
    //   this.state.attendanceRecord[0] &&
    //   this.state.attendanceRecord[0].studentName;
    const totalAcademicDays = this.state.attendanceRecordAcademicYear.length;
    const presentAcademicDays = this.state.attendanceRecordAcademicYear.filter(
      (rec) => rec.isPresent
    ).length;
    const absentAcademicDays = totalAcademicDays - presentAcademicDays;

    // const totalDaysFilter = this.state.attendanceRecordFilterDate.length;
    // const presentDaysFilter = this.state.attendanceRecordFilterDate.filter(
    //   (aFil) => aFil.isPresent
    // ).length;
    // const absentDaysFilter = this.state.attendanceRecordFilterDate.filter(
    //   (aFil) => !aFil.isPresent
    // ).length;

    return (
      <>
        {this.state.attendanceRecordFilterDate.length > 0 ? null : (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="tt-group-header">
                  This Month <span>[{moment().format("MMMM")}]</span>
                </div>
                {/* <div className="row pl-4 ml-3" style={{ width: "90%" }}>
                  <div className="col-md-4 p-4 total-days">
                    <div style={{ color: "white" }}>
                      Total Days: {totalDays}
                    </div>
                  </div>
                  <div className="col-md-4 p-4 present-days">
                    <div style={{ color: "white" }}>
                      Present Days: {presentDays}
                    </div>
                  </div>
                  <div className="col-md-4 p-4 absent-days">
                    <div style={{ color: "white" }}>
                      Absent Days: {absentDays}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-md-6">
                <div className="tt-group-header">Academic Year</div>
                {/* <div className="row pl-4 ml-3" style={{ width: "90%" }}>
                  <div className="col-md-4 p-4 total-days">
                    <div style={{ color: "white" }}>
                      Total Days: {totalAcademicDays}
                    </div>
                  </div>
                  <div className="col-md-4 p-4 present-days">
                    <div style={{ color: "white" }}>
                      Present Days: {presentAcademicDays}
                    </div>
                  </div>
                  <div className="col-md-4 p-4 absent-days">
                    <div style={{ color: "white" }}>
                      Absent Days: {absentAcademicDays}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <canvas
                  id="monthSummary"
                  width="200"
                  height="200"
                  className="custom-canvas-size"
                />
              </div>
              <div className="col-md-6">
                <canvas
                  id="academicSummary"
                  width="200"
                  height="200"
                  className="custom-canvas-size"
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default StudentAttendanceDetails;
