import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displaySuccessAlert } from "../../utils/Utils";

const animatedComponents = makeAnimated();

class GranularPermissionModal extends Component {
  state = {
    users: [],
    widgets: [],
    widgetModules: [],
    selectedUsers: [],
    usersPresent: false,
    usersWithPermission: [],
  };

  componentDidMount() {
    this.getWidgets();
    axiosPost(
      URL.getUsersByRoleCodeForPermission,
      { userRoleCode: "RL_STF" },
      (response) => {
        if (response.status === 200) {
          let users = response.data.data.map((user) => {
            return {
              userPermissionId: user.userPermissionId,
              value: user.id,
              label: user.name,
            };
          });
          this.setState({ users: users });
        }
      }
    );
  }

  getWidgets = () => {
    axiosPost(URL.getWidgets, {}, (response) => {
      if (response.status === 200) {
        this.setState({ widgets: response.data.data }, () => {
          this.getAllModules();
        });
      }
    });
  };
  getAllModules = () => {
    axiosPost(URL.getAllModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ modules: response.data.data }, () => {
          this.getAllActivity();
        });
      }
    });
  };

  getAllActivity = () => {
    axiosPost(URL.getAllActivity, {}, (response) => {
      if (response.status === 200) {
        let activitylist = response.data.data;
        this.setState({ activities: activitylist }, () => {
          let widget = this.state.widgets.find(
            (widget) => widget.name === this.props.widgetName
          );
          let module = this.state.modules.find(
            (module) => module.name === this.props.moduleName
          );
          let activity = this.state.activities.find(
            (activity) => activity.activityCode === this.props.activityName
          );
          if (widget && module && activity) {
            this.setState(
              {
                widgetId: widget.id,
                moduleId: module.id,
                activityId: activity.id,
              },
              () => {
                this.getUsersWithPermission();
              }
            );
          }
        });
      }
    });
  };

  getUsersWithPermission = () => {
    const data = {
      widgetId: this.state.widgetId,
      moduleId: this.state.moduleId,
      activityId: this.state.activityId,
    };
    axiosPost(URL.getUserPermissionByWidgetModuleActivity, data, (response) => {
      if (response.status === 200) {
        this.setState({
          selectedUsers: response.data.data,
          usersPresent: response.data.data.length > 0 ? true : false,
        });
      }
    });
  };

  handlePermissionUsersChange = (users) => {
    this.setState({ selectedUsers: users });
  };

  handlePermissionSave = () => {
    if (this.state.widgetId && this.state.moduleId && this.state.activityId) {
      const educationLevelId = parseInt(this.props.educationLevelIdMultiple);
      let data = [];
      if (educationLevelId !== 0) {
        data = [
          {
            widgetId: this.state.widgetId,
            moduleId: this.state.moduleId,
            activityId: this.state.activityId,
            educationLevelId: parseInt(this.props.educationLevelIdMultiple),
          },
        ];
      } else {
        this.props.educationLevels.forEach((level) => {
          data.push({
            widgetId: this.state.widgetId,
            moduleId: this.state.moduleId,
            activityId: this.state.activityId,
            educationLevelId: level.id,
          });
        });
      }
      if (this.state.selectedUsers) {
        this.state.selectedUsers.forEach((user) => {
          data.push({
            id: user.id,
            userId: user.value,
            widgetId: this.state.widgetId,
            moduleId: this.state.moduleId,
            activityId: this.state.activityId,
          });
        });
      }
      axiosPost(URL.insertUserPermission, data, (response) => {
        this.setState({ usersPresent: false }, () => {
          this.getUsersWithPermission();
        });
        displaySuccessAlert(response);
      });
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="mt-3">
              <strong>{this.props.header}</strong>
            </div>
          </div>
          <div className="col-md-6 mt-1">
            <Select
              value={this.state.selectedUsers}
              closeMenuOnSelect={false}
              placeholder={<div>select users</div>}
              components={animatedComponents}
              isMulti
              options={this.state.users}
              name="users"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(users) => this.handlePermissionUsersChange(users)}
            />
          </div>
        </div>
        <div>
          <div className="mt-4">
            <button
              className="tt-button tt-button-primary float-right"
              onClick={this.handlePermissionSave}
              disabled={
                this.state.usersPresent
                  ? false
                  : this.state.selectedUsers === null ||
                    this.state.selectedUsers.length === 0
                  ? true
                  : false
              }
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default GranularPermissionModal;
