import React, { Component } from "react";
import { connect } from "react-redux";
import "../../../../assets/scss/subjectSetups.scss";
import "../../../../assets/scss/form.scss";
import UpdateSubjectArea from "./UpdateSubjectArea";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  checkRolePermissions,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import { getSubjectAreas } from "../../../../ducks/PrimaryARRDucks";
import swal from "sweetalert";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";

class SubjectAreas extends Component {
  constructor() {
    super();
    this.updateSubjectAreaElement = [];
  }

  state = {
    name: "",
    description: "",
    infos: [],
    assessmentModuleCode: "",
    nameError: false,
    permissionInsertSubjectArea: false,
    permissionEditSubjectArea: false,
    permissionDeleteSubjectArea: false,
  };

  componentDidMount() {
    this.setState({ assessmentModuleCode: this.props.code });
    this.props.create === false && this.props.getSubjectAreas(this.props.code);
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionInsertSubjectArea: checkRolePermissions(
        "insert-subject-area",
        "activity",
        this.props.name
      ),
      permissionEditSubjectArea: checkRolePermissions(
        "edit-subject-area",
        "activity",
        this.props.name
      ),
      permissionDeleteSubjectArea: checkRolePermissions(
        "delete-subject-area",
        "activity",
        this.props.name
      ),
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return { subjectAreas: nextProps.subjectAreas };
  }

  handleChange = (e, id) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      if (name === "name") {
        if (this.state.name.length >= 40) {
          swal("Warning", "Maximum 40 characters are allowed!!!");
        }
        value.length > 0 && this.setState({ nameError: false });
      } else if (name === "description") {
        if (this.state.description.length >= 256) {
          swal("Warning", "Maximum 256 characters are allowed!!!");
        }
      }
    });
  };

  submitSubjectArea = (e) => {
    e.preventDefault();
    if (this.state.name === "") {
      swal({
        title: "Error",
        text: "Name of subject area cannot be empty",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else {
      let data = {
        name: this.state.name,
        assessmentModuleCode: this.state.assessmentModuleCode,
        description: this.state.description ? this.state.description : "",
      };
      axiosPost(URL.insertSubjectAreaList, data, (response) => {
        if (response.status === 200) {
          swal("Success", "Subject area has been sucessfully added.", "");
          this.resetForm();
          this.props.getSubjectAreas(this.props.code);
        }
      });
    }
  };

  resetForm = (e, id) => {
    if (this.props.create) {
      this.setState({
        name: "",
        description: "",
        nameError: false,
      });
    }
  };

  deleteList = ({ e, id }) => {
    e.preventDefault();
    axiosPost(URL.deleteSubjectAreaList, { id: parseInt(id) }, (response) => {
      if (response.status === 200) {
        swal("Success", "Subject area has been successfully deleted.", "");
        this.props.getSubjectAreas(this.props.code);
      }
    });
  };

  updateSubjectArea = (e, id) => {
    e.preventDefault();
    if (this.state.name === "") {
      swal({
        title: "Error",
        text: "Name of subject area cannot be empty",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else {
      let data = {
        id: parseInt(id),
        name: this.state.name,
        description: this.state.description,
      };

      axiosPost(URL.updateSubjectAreaList, data, (response) => {
        if (response.status === 200) {
          swal("Success", "Subject area has been successfully updated.", "");
          this.props.getSubjectAreas(this.props.code);
        }
      });
    }
  };

  //   to update data
  handleUpdateSubjectAreaBtnClick = (index, dBIndex) => {
    let subjectAreas = [...this.state.subjectAreas];
    subjectAreas.forEach((subjectArea) => {
      subjectArea.isOpen = false;
    });
    subjectAreas[index].isOpen = true;

    this.setState({
      subjectAreas: subjectAreas,
    });

    axiosPost(
      URL.getSubjectAreaListByKey,
      {
        id: parseInt(dBIndex),
        assessmentModuleCode: this.state.assessmentModuleCode,
      },
      (response) => {
        this.setState({
          name: response.data.data.name,
          description: response.data.data.description,
        });
      }
    );
  };

  handleSubjectAreaChange = (e, index) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      if (name === "name") {
        if (this.state.name.length >= 40) {
          swal("Warning", "Maximum 40 characters are allowed!!!");
        }
        value.length > 0 && this.setState({ nameError: false });
      } else if (name === "description") {
        if (this.state.description.length >= 256) {
          swal("Warning", "Maximum 256 characters are allowed!!!");
        }
      }
    });
  };

  cancelUpdateSubjectAreaBtnClick = (index) => {
    let subjectAreas = [...this.state.subjectAreas];
    subjectAreas[index].isOpen = false;
    this.setState({
      subjectAreas: subjectAreas,
      name: "",
      description: "",
    });
  };

  subjectAreaContent = (id) => {
    let idx = 0;
    let array = this.props.subjectAreas;
    for (let x = 0; x < array.length; x++) {
      if (array[x]["id"] === id) {
        idx = x;
      }
    }
    return (
      <div className="tt-subjectAreaContent tt-form">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-3 text-right">
            <label htmlFor="name">Name of subject area:</label>
            <span className="tt-assessment-module-mandatory">*</span>
          </div>
          <div className="col-md-5">
            <input
              type="text"
              id="name"
              name="name"
              className={
                this.state.nameError
                  ? "form-control indicate-error"
                  : "form-control"
              }
              value={this.state.name}
              onChange={
                this.props.create
                  ? (e) => this.handleChange(e, idx)
                  : (e) => this.handleSubjectAreaChange(e, idx)
              }
              maxLength={40}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2"></div>
          <div className="col-md-3 text-right">
            <label htmlFor="description" className="optional">
              Description (optional):
            </label>
          </div>
          <div className="col-md-5">
            <textarea
              id="description"
              //   placeholder="Write Something"
              name="description"
              className="form-control"
              value={this.state.description}
              onChange={
                this.props.create
                  ? (e) => this.handleChange(e, idx)
                  : (e) => this.handleSubjectAreaChange(e, idx)
              }
              maxLength={256}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        {this.state.permissionInsertSubjectArea ? (
          <div className="container-fluid">
            <div className="row form-bg-color">
              <div className="col-md-2"></div>
              <div className="col-md-3"></div>
              <div className="col-md-5">
                <div className="tt-subjectAreaButtons">
                  <button
                    className={`tt-button tt-button-primary ${
                      this.props.create ? `mr-1` : `mr-4`
                    }`}
                    onClick={
                      this.props.create
                        ? this.submitSubjectArea
                        : (e) => this.updateSubjectArea(e, id)
                    }
                  >
                    {this.props.create ? "Create" : "Update"}
                  </button>
                  {this.props.create ? null : (
                    <button
                      className="tt-button tt-button-primary"
                      onClick={(e) => this.cancelUpdateSubjectAreaBtnClick(idx)}
                    >
                      {!this.props.create && "Cancel"}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.props.create ? (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <div className="tt-group-header">
              Create Subject Area
              {checkWidgetAdminOrNot(this.props.name) === true ? (
                <button
                  className="tt-button tt-button-primary float-right permissionBtnCSS"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
            {this.subjectAreaContent()}
          </div>
        ) : (
          <>
            <div
              className="tt-widgetContent-tab-holder"
              style={{ height: "79.5vh" }}
            >
              <div className="tt-group-header">
                List Subject Areas
                {checkWidgetAdminOrNot(this.props.name) === true ? (
                  <button
                    className="tt-button tt-button-primary float-right permissionBtnCSS"
                    onClick={() => {
                      this.setState({
                        permissionModal: !this.state.permissionModal,
                      });
                    }}
                  >
                    Permissions
                  </button>
                ) : null}
              </div>
              <div className="tt-newListBox container-fluid">
                <div className="row">
                  {/* <div className="col-md-1"></div> */}
                  <div className="col-md-12 tt-listSubjectAreas">
                    <table className="table table-bordered text-center table-striped">
                      <thead className="tt-group-header">
                        <tr className="text-center">
                          <th>Subject Area</th>
                          <th>Description</th>
                          {this.state.permissionEditSubjectArea ||
                          this.state.permissionDeleteSubjectArea ? (
                            <th>Options</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.subjectAreas ? (
                          this.state.subjectAreas.length > 0 ? (
                            this.state.subjectAreas.map((subArea, idx) => {
                              return (
                                <UpdateSubjectArea
                                  name={this.props.name}
                                  info={subArea}
                                  idx={idx}
                                  key={idx}
                                  subjectAreaContent={this.subjectAreaContent}
                                  deleteList={this.deleteList}
                                  ref={(ref) =>
                                    (this.updateSubjectAreaElement[idx] = ref)
                                  }
                                  handleUpdateSubjectAreaBtnClick={
                                    this.handleUpdateSubjectAreaBtnClick
                                  }
                                  code={this.props.code}
                                  permissionEditSubjectArea={
                                    this.state.permissionEditSubjectArea
                                  }
                                  permissionDeleteSubjectArea={
                                    this.state.permissionDeleteSubjectArea
                                  }
                                />
                              );
                            })
                          ) : (
                            <tr>
                              {this.props.subjectAreaSpinner ? (
                                <td colSpan={3} className="center">
                                  <Spinner color="primary"></Spinner>
                                  <br></br>
                                  Loading Data ...
                                </td>
                              ) : (
                                <td colSpan={3}>No subject area available</td>
                              )}
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan={3}>No subject area available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <ModalWindow
          modal={this.state.permissionModal}
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          size="lg"
          id="tt-permissionModal"
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Subjects Setup"
              header={
                this.props.create ? "Create Subject Area" : "List Subject Area"
              }
              activityName={
                this.props.create
                  ? "insert-subject-area"
                  : "select-subject-area"
              }
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  subjectAreas: state.primaryArr.subjectAreas,
  subjectAreaSpinner: state.primaryArr.subjectAreaSpinner,
});

const mapActionsToProps = {
  getSubjectAreas: getSubjectAreas,
};
export default connect(mapStateToProps, mapActionsToProps)(SubjectAreas);
