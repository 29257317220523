import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import ModalWindow from "../UI/ModalWindow";
import FileViewer from "react-file-viewer";

const animatedComponents = makeAnimated();

export class PaymentHistory extends Component {
  state = {
    yearLevels: [],
    students: [],
    yearlevelId: "",
    studentLoader: false,
    selectedStudents: null,
    transactionData: [],
    fetchingData: null,
    receiptModal: false,
    filePath: "",
    fileType: "",
  };

  componentDidMount() {
    this.getYearLevels();
  }
  checkFilePath = (path) => {
    let data = URL.imageSelectURL + path;
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", data, false);
    xhr.send();

    let status = parseInt(xhr.status);
    if (status == 404 || status == 401) {
      return false;
    } else {
      return true;
    }
  };

  getStudentAsPerYearLevel = () => {
    let param = {
      yearLevelId: this.state.yearlevelId,
    };
    this.setState({ studentLoader: true }, function () {
      axiosPost(
        URL.getStudentAsPerYearLevel,
        param,
        (response) => {
          if (response.status === 200) {
            let students = response.data.data;
            students = students.map((s) => {
              s.label = s.people.name;
              s.value = s.id;
              return s;
            });
            this.setState({ students, studentLoader: false });
          }
        },
        (err) => {
          this.setState({ studentLoader: false });
        }
      );
    });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          yearLevels: response.data.data,
        });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ selectedStudents: [], [name]: value }, function () {
      if (name === "yearlevelId") {
        this.setState({
          selectedStudents: null,
        });
        this.getStudentAsPerYearLevel();
      }
    });
  };

  getPaymentHistory = () => {
    let params = {
      admissionId: this.state.selectedStudents.id,
    };

    this.setState({ fetchingData: true }, () => {
      axiosPost(URL.getPaymentHistory, params, (response) => {
        if (response.status === 200) {
          this.setState({
            transactionData: response.data.data,
            fetchingData: false,
          });
        } else {
          this.setState({
            fetchingData: null,
          });
        }
      });
    });
  };

  handleStudentsChange = (items) => {
    this.setState({ selectedStudents: items });
  };

  toggleReceiptModal = () => {
    this.setState({
      receiptModal: !this.state.receiptModal,
    });
  };

  viewReceipt = (item) => {
    this.setState(
      {
        filePath: item.paymentTransaction.receiptFilePath,
        fileType: "pdf",
      },
      () => {
        this.setState({
          receiptModal: true,
        });
      }
    );
  };

  render() {
    return (
      <div>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "81.5vh" }}
        >
          {this.state.loader ? (
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please Wait. Processing...
              </div>
            </div>
          ) : null}
          <div className="tt-group-header">Payment Transaction History</div>
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <div>
                  <strong>{localStorage.getItem("yearLevel")}</strong>
                </div>
                <select
                  className="form-control"
                  name="yearlevelId"
                  onChange={this.handleChange}
                  value={this.state.yearlevelId}
                >
                  <option value="" selected disabled>
                    Choose {localStorage.getItem("yearLevel")}
                  </option>
                  {this.state.yearLevels?.map((y, idx) => (
                    <option value={y.id} key={idx}>
                      {y.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <strong>Student</strong>
                <Select
                  components={animatedComponents}
                  isClearable={true}
                  isLoading={
                    this.state.students.length > 0 || !this.state.studentLoader
                      ? false
                      : true
                  }
                  menuPlacement="auto"
                  name="students"
                  onChange={this.handleStudentsChange}
                  options={this.state.students || []}
                  placeholder="Choose Students"
                  value={this.state.selectedStudents}
                />
              </div>

              <div className="col-md-2" style={{ position: "relative" }}>
                <strong></strong>
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.getPaymentHistory}
                  disabled={this.state.selectedStudents === null ? true : false}
                  style={{ position: "absolute", bottom: 0 }}
                >
                  Get Transaction History
                </button>
              </div>
              <div className="col-md-1"></div>
            </div>

            {this.state.transactionData &&
            this.state.transactionData.length > 0 ? (
              <div className="mt-4 payment-history-div">
                <table className="table text-center table-bordered table-striped">
                  <thead className="tt-group-header">
                    <tr>
                      <td>S.N.</td>
                      <td>Date</td>
                      <td>Bill Info</td>
                      <td>Bill Amount</td>
                      <td>Payable Amount</td>
                      <td>Paid Amount</td>
                      <td>Paid By</td>
                      <td>Remaining Balance</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.transactionData.map((data, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div> {moment(data.createdAt).format("Do MMMM")}</div>
                          <div> {moment(data.createdAt).format("YYYY")}</div>
                        </td>
                        <td className="text-left">
                          <div>
                            <b>Bill No. : </b>
                            {data.billNo}
                          </div>
                          <div>
                            <b>Receipt No. : </b>
                            {data.paymentTransaction.receiptNo}
                          </div>
                        </td>
                        <td>{data.paymentTransaction.actualAmount}</td>
                        <td>{data.paymentTransaction.payableAmount}</td>
                        <td>{data.paymentTransaction.paidAmount}</td>
                        <td>{data.paymentTransaction.billPayedBy}</td>
                        <td>
                          {data.paymentTransaction.advanceDue < 0 ? (
                            <div>
                              <b>Due: </b>
                              {Math.abs(data.paymentTransaction.advanceDue)}
                            </div>
                          ) : (
                            <div>
                              <b>Advance: </b>
                              {Math.abs(data.paymentTransaction.advanceDue)}
                            </div>
                          )}
                        </td>
                        <td>
                          {data.status === "partial" ? (
                            <span class="c-pill c-pill--warning">
                              {/* {data.status} */}
                              Partial
                            </span>
                          ) : (
                            data.status === "paid" && (
                              <span class="c-pill c-pill--success">
                                {/* {data.status} */}
                                Paid
                              </span>
                            )
                          )}
                        </td>
                        <td>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() => this.viewReceipt(data)}
                          >
                            View receipt
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              // </div>
              <div className="text-center mt-4">
                {this.state.fetchingData ? (
                  <Spinner color="primary" />
                ) : (
                  "No records to display!!"
                )}
              </div>
            )}
          </div>
        </div>

        <ModalWindow
          modal={this.state.receiptModal}
          toggleModal={this.toggleReceiptModal}
          modalHeader="View Receipt"
          size="xl"
          modalBody={
            <div>
              {this.state.filePath === "" || this.state.fileType === "" ? (
                <div className="p-4 text-center">
                  No bill receipt avalible for this transaction
                </div>
              ) : (
                <>
                  {this.checkFilePath(this.state.filePath) ? (
                    <a
                      href={URL.fileDownloadURL + this.state.filePath}
                      target="_blank"
                      download
                    >
                      <button className="tt-button tt-button-primary mr-3">
                        Download
                      </button>
                    </a>
                  ) : (
                    ""
                  )}
                  <div style={{ height: "100%" }} className="bill-receipt-pdf">
                    <FileViewer
                      className="file-viewer-style"
                      fileType={this.state.fileType.toLowerCase()}
                      filePath={URL.imageSelectURL + this.state.filePath}
                    />
                  </div>
                </>
              )}
            </div>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default PaymentHistory;
