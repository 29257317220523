import React, { Component } from "react";
import { connect } from "react-redux";
import "../../../../assets/scss/educationLevel.scss";
import {
  getEducationLevelsAcademicAdmin as getEducationLevels,
  getGroups,
} from "../../../../ducks/AcademicAdministrationDucks";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  recruitmentStatus,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import swal from "sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";
import { displayErrorMessage } from "../../../../utils/Utils";

const animatedComponents = makeAnimated();

class CreateNewEducationLevel extends Component {
  state = {
    regEx: /^[0-9a-zA-Z\-!;:"' ]*$/,
    name: "",
    recruitmentStatusId: "",
    errorMessage: "",
    showSpinner: false,
    recruitments: [],
    administrators: [],
    nameError: false,
  };

  componentDidMount() {
    this.getRecruitmentStatusByKey();
    document.getElementById("educationName").focus();
    document.getElementById("educationName").select();
    if (this.props.edit) {
      this.setUpdateConfig();
    }
  }

  setUpdateConfig = () => {
    this.setState({
      name: this.props.eduLevel ? this.props.eduLevel.name : "",
      administrators: this.props.eduLevel
        ? this.props.defaultAdministratorName
        : [],
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.defaultAdministratorName !==
        prevProps.defaultAdministratorName ||
      this.props.name !== prevProps.name
    ) {
      this.setUpdateConfig();
    }
  }

  getRecruitmentStatusByKey = () => {
    let data = {
      code: recruitmentStatus.recruitmentCurrent,
    };
    axiosPost(URL.getRecruitmentStatusByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { recruitmentStatusId: response.data.data.id },
          function () {
            let data = {
              recruitmentStatusId: this.state.recruitmentStatusId,
            };
            this.getStaffList(data);
          }
        );
      }
    });
  };

  getStaffList = (data) => {
    axiosPost(URL.getStaffList, data, (response) => {
      if (response.status === 200) {
        let recruitments = [];
        let staffList = response.data.data;
        staffList.forEach((element) => {
          recruitments.push({
            value: element.id,
            label: element.people.name,
          });
        });

        this.setState({
          recruitments,
        });
      }
    });
  };

  handleChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (name === "name") {
      name.length > 0 && this.setState({ nameError: false });
      if (!value.match(this.state.regEx)) {
        let errorCharacter = value.slice(-1);
        swal({
          title: "Warning",
          text: `${errorCharacter} not allowed`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleChangeSelect = (selectedItem) => {
    let administrators = [];
    if (selectedItem != null) {
      selectedItem.forEach((element) => {
        administrators.push({
          recruitmentId: element.value,
          value: element.value,
          label: element.label,
        });
      });
    }
    this.setState({
      administrators: administrators,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.name === "") {
      this.setState({ nameError: true });
      swal({
        title: "Warning",
        text: "Please, fill all the fields first.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let data = {
        name: this.state.name,
        educationLevelAdministrators: this.state.administrators,
      };
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertEduLevel,
          data,
          (response) => {
            if (response.status === 200) {
              swal({
                title: "Success",
                text: "Successfully inserted",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              this.setState({
                showSpinner: false,
                name: "",
                nameError: false,
                administrators: [],
              });
              draggable();
              this.props.getEducationLevels();
              document.getElementById("educationName").focus();
              document.getElementById("educationName").select();
            }
          },
          (error) => {
            displayErrorMessage(error);
            this.setState({ showSpinner: false });
          }
        );
      });
    }
  };

  handleUpdate = (e) => {
    e.preventDefault();
    if (this.state.name === "") {
      this.setState({ nameError: true });
      swal({
        title: "Warning",
        text: "Please, fill all the fields first.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      const educationLevelAdministrators = [...this.state.administrators];
      educationLevelAdministrators.forEach((adms) => {
        adms.recruitmentId = adms.value;
      });
      let data = {
        id: this.props.eduLevel.id,
        name: this.state.name,
        educationLevelAdministrators,
      };
      //update api to be made
      this.setState({ showSpinner: true }, () => {
        axiosPost(
          URL.updateEduLevel,
          data,
          (response) => {
            if (response.status === 200) {
              this.props.getEducationLevels();
              swal({
                title: "Success",
                text: "Successfully updated",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.setState({
                name: "",
                showSpinner: false,
              });
            } else {
              this.setState({
                name: "",
                showSpinner: false,
              });
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            displayErrorMessage(error);
          }
        );
      });
    }
  };
  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={this.props.edit ? null : { height: "79.5vh" }}
        >
          <div className="tt-group-header">
            {this.props.edit
              ? "Update Education Level"
              : "Create Education Level"}
            {this.props.edit ? null : checkWidgetAdminOrNot(
                "Academic Administration"
              ) === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div className="container">
            <form
              onSubmit={this.props.edit ? this.handleUpdate : this.handleSubmit}
            >
              <div className="form-group row">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <label htmlFor="name">
                    <strong>Education level name</strong>
                  </label>
                  <span className="tt-assessment-module-mandatory">*</span>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    className={
                      this.state.nameError
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    name="name"
                    id="educationName"
                    placeholder="Enter education level name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    maxLength="255"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <label htmlFor="administrators">
                    <strong className="optional">Administrators</strong>
                  </label>
                </div>
                <div className="col-md-4">
                  {this.props.edit ? (
                    <Select
                      value={
                        this.state.administrators.length > 0
                          ? this.state.administrators
                          : ""
                      }
                      key={this.props.idx}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={this.state.recruitments}
                      placeholder="Choose Administrators"
                      name="administrators"
                      onChange={this.handleChangeSelect}
                      closeMenuOnScroll={false}
                    />
                  ) : (
                    <Select
                      value={
                        this.state.administrators.length > 0
                          ? this.state.administrators
                          : ""
                      }
                      closeMenuOnSelect={false}
                      isClearable={true}
                      components={animatedComponents}
                      isMulti
                      options={this.state.recruitments}
                      placeholder="Choose Administrators"
                      name="administrators"
                      onChange={this.handleChangeSelect}
                    />
                  )}
                </div>
              </div>
              <div className="row button-bg-color">
                <div className="col-md-2"></div>
                <div className="col-md-3"></div>
                <div className="col-md-4 text-right ">
                  <button type="submit" className="tt-button tt-button-primary">
                    {this.props.edit ? "Update" : " Submit"}
                  </button>
                  {this.props.edit ? (
                    <button
                      className="ml-3 tt-button tt-button-primary"
                      onClick={this.props.closeCollapsible}
                    >
                      Cancel
                    </button>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School Groupings"
              header="Add Education Level"
              activityName="insert-education-level"
            />
          }
        ></ModalWindow>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait.{" "}
              {this.props.edit
                ? "Updating Education level ..."
                : "Creating Education level ..."}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = () => ({});
const mapActionToProps = {
  getEducationLevels,
  getGroups,
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(CreateNewEducationLevel);
