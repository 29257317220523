// import React, { Component } from "react";
// import IncreaseDecreaseTerm from "../../AcademicAdministration/AcademicYears/IncreaseDecreaseTerm";
// class ScaleTypeNumberForm extends Component {
//   state = {};
//   render() {
//     return (
//       <>
//         <div className="row scale-name">
//           <div className="col-md-2"></div>
//           <div className="col-md-3 tt-paddingLeft">
//             <label>
//               <strong>Score name</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//           </div>
//           <div className="col-md-4 tt-paddingRight">
//             <input
//               type="text"
//               value={this.props.scoreName}
//               onChange={this.props.universalHandleChange}
//               name="scoreName"
//               className="form-control  scale-name-field"
//             />
//           </div>
//           <div className="col-md-6"></div>
//         </div>
//         <div className="row mt-3">
//           <div className="col-md-1"></div>
//           <div className="col-md-1 mt-2">
//             <label>
//               <strong>Range</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//           </div>
//           <div className="col-md-4 scale-content">
//             <IncreaseDecreaseTerm
//               numberOfTerms={this.props.range}
//               decreaseValue={this.props.decreaseValue}
//               increaseValue={this.props.increaseValue}
//             />
//             <IncreaseDecreaseTerm
//               numberOfTerms={this.props.rangeNext}
//               decreaseValue={this.props.decreaseRangeNextValue}
//               increaseValue={this.props.increaseRangeNextValue}
//             />
//           </div>
//           <div className="col-md-3 pr-0 text-right mt-2">
//             <label>
//               <strong>Minimum achievement</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//           </div>
//           <div className="col-md-2 scale-content">
//             <IncreaseDecreaseTerm
//               numberOfTerms={this.props.minAchievementLevel}
//               decreaseValue={this.props.decreaseMinAchievementLevel}
//               increaseValue={this.props.increaseMinAchievementLevel}
//             />
//           </div>
//         </div>
//         {/* Sandeep Added */}
//         <div className="row mt-3">
//           <div className="col-md-3"></div>
//           <div className="col-md-2"></div>
//           <div className="col-md-2 text-center">
//             <strong>Min Value</strong>
//           </div>
//           <div className="col-md-2 text-center">
//             <strong>Max Value</strong>
//           </div>
//         </div>
//         <div className="row mt-2">
//           <div className="col-md-3"></div>
//           <div className="col-md-2 mt-2">
//             <label>
//               <strong>Theory Range</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//           </div>
//           <div className="col-md-2 scale-content">
//             <input
//               type="number"
//               name="theoryMin"
//               className="form-control"
//               value={this.props.theoryMin}
//               onChange={this.props.handlePracticalValue}
//               max={this.props.rangeNext}
//               min={0}
//             />
//           </div>
//           <div className="col-md-2 scale-content">
//             <input
//               type="number"
//               className="form-control ml-2"
//               name="theoryMax"
//               min={this.props.theoryMin}
//               value={this.props.theoryMax}
//               onChange={this.props.handlePracticalValue}
//               max={this.props.rangeNext}
//               disabled={this.props.theoryMin > 0 ? false : true}
//             />
//           </div>
//         </div>

//         <div className="row mt-3">
//           <div className="col-md-3"></div>
//           <div className="col-md-2 mt-2">
//             <label>
//               <strong>Practical Range</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//           </div>
//           <div className="col-md-2 scale-content">
//             <input
//               type="number"
//               name="practicalMin"
//               className="form-control"
//               min={0}
//               value={this.props.practicalMin}
//               onChange={this.props.handlePracticalValue}
//               max={this.props.rangeNext}
//             />
//           </div>
//           <div className="col-md-2 scale-content">
//             <input
//               type="number"
//               className="form-control ml-2"
//               name="practicalMax"
//               min={this.props.practicalMin}
//               value={this.props.practicalMax}
//               onChange={this.props.handlePracticalValue}
//               max={this.props.rangeNext}
//               disabled={this.props.practicalMin > 0 ? false : true}
//             />
//           </div>
//         </div>

//         <div className="row scale-name">
//           <div className="col-md-1"></div>
//           <div className="col-md-10 scale-content">
//             <label className="min-level-label">
//               <strong>Show score as percentage</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//             <input
//               type="radio"
//               className="score-radio-button"
//               id="both"
//               value="both"
//               checked={this.props.score === "both"}
//               onChange={this.props.scoreAsPerChange}
//             />
//             <label htmlFor="both">Show both score and percentage.</label>

//             <input
//               type="radio"
//               className="score-radio-button"
//               checked={this.props.score === "pctg"}
//               id="pctg"
//               value="pctg"
//               onChange={this.props.scoreAsPerChange}
//             />
//             <label htmlFor="pctg">Show only percentage.</label>
//             <input
//               type="radio"
//               className="score-radio-button"
//               checked={this.props.score === "Nopctg"}
//               id="Nopctg"
//               value="Nopctg"
//               onChange={this.props.scoreAsPerChange}
//             />
//             <label htmlFor="Nopctg">Dont show percentage.</label>
//           </div>
//           <div className="col-md-1"></div>
//         </div>
//         <div className="row scale-name">
//           <div className="col-md-1"></div>
//           <div className="col-md-10 text-center">
//             <input
//               type="checkbox"
//               className="tt-record-deadline-checkbox mr-2"
//               name="checked"
//               checked={this.props.checked}
//               onChange={this.props.universalHandleChange}
//               id="checked"
//             />
//             <label htmlFor="checked">
//               <strong>Calculate aggregate (of subjects)</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//           </div>
//           <div className="col-md-1"></div>
//         </div>
//         <div className="row scale-name">
//           <div className="col text-center">
//             <label>
//               <strong>Show aggregation as a percentage</strong>
//               <span className="tt-assessment-module-mandatory">*</span>
//             </label>
//             <input
//               type="radio"
//               className="score-radio-button"
//               id="aggregateAndPer"
//               value="aggregateAndPer"
//               checked={this.props.aggregate === "aggregateAndPer"}
//               onChange={this.props.aggregateAsPerChange}
//               //   disabled={!this.props.checked}
//             />
//             <label htmlFor="aggregateAndPer">
//               Show both aggregate and percentage.
//             </label>
//             <input
//               type="radio"
//               className="score-radio-button"
//               id="per"
//               value="per"
//               checked={this.props.aggregate === "per"}
//               onChange={this.props.aggregateAsPerChange}
//               //   disabled={!this.props.checked}
//             />
//             <label htmlFor="per">Show only percentage.</label>

//             <input
//               type="radio"
//               className="score-radio-button"
//               id="noPer"
//               value="noPer"
//               checked={this.props.aggregate === "noPer"}
//               onChange={this.props.aggregateAsPerChange}
//               //   disabled={!this.props.checked}
//             />
//             <label htmlFor="noPer">Dont show percentage.</label>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default ScaleTypeNumberForm;
import React, { Component } from "react";
import IncreaseDecreaseTerm from "../../AcademicAdministration/AcademicYears/IncreaseDecreaseTerm";
class ScaleTypeNumberForm extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row scale-name">
          <div className="col-md-2"></div>
          <div className="col-md-3 tt-paddingLeft">
            <label>
              <strong>Score name</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4 tt-paddingRight">
            <input
              type="text"
              value={this.props.scoreName}
              onChange={this.props.universalHandleChange}
              name="scoreName"
              className="form-control  scale-name-field"
            />
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-1"></div>
          <div className="col-md-1 mt-2">
            <label>
              <strong>Range</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4 scale-content">
            <IncreaseDecreaseTerm
              numberOfTerms={this.props.range}
              decreaseValue={this.props.decreaseValue}
              increaseValue={this.props.increaseValue}
              handleRangeChange={this.props.handleRangeChange}
              name="range"
              inputEnabled
            />
            <IncreaseDecreaseTerm
              numberOfTerms={this.props.rangeNext}
              decreaseValue={this.props.decreaseRangeNextValue}
              increaseValue={this.props.increaseRangeNextValue}
              handleRangeChange={this.props.handleRangeChange}
              name="rangeNext"
              inputEnabled
            />
          </div>
          <div className="col-md-3 pr-0 text-right mt-2">
            <label>
              <strong>Minimum achievement</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-2 scale-content">
            <IncreaseDecreaseTerm
              numberOfTerms={this.props.minAchievementLevel}
              decreaseValue={this.props.decreaseMinAchievementLevel}
              increaseValue={this.props.increaseMinAchievementLevel}
              handleRangeChange={this.props.handleRangeChange}
              name="minAchievement"
              inputEnabled
            />
          </div>
        </div>
        {/* Sandeep Added */}
        <div className="row mt-3">
          <div className="col-md-3"></div>
          <div className="col-md-2"></div>
          <div className="col-md-2 text-center">
            <strong>Min Value</strong>
          </div>
          <div className="col-md-2 text-center">
            <strong>Max Value</strong>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-2">
            <label>
              <strong>Theory Range</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-2 scale-content">
            <input
              type="number"
              name="theoryMin"
              className="form-control"
              value={this.props.theoryMin}
              onChange={this.props.handlePracticalValue}
              max={this.props.rangeNext}
              min={0}
            />
          </div>
          <div className="col-md-2 scale-content">
            <input
              type="number"
              className="form-control ml-2"
              name="theoryMax"
              min={this.props.theoryMin}
              value={this.props.theoryMax}
              onChange={this.props.handlePracticalValue}
              max={this.props.rangeNext}
              // disabled={this.props.theoryMin > 0 ? false : true}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-2">
            <label>
              <strong>Practical Range</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-2 scale-content">
            <input
              type="number"
              name="practicalMin"
              className="form-control"
              min={0}
              value={this.props.practicalMin}
              onChange={this.props.handlePracticalValue}
              max={this.props.rangeNext}
            />
          </div>
          <div className="col-md-2 scale-content">
            <input
              type="number"
              className="form-control ml-2"
              name="practicalMax"
              min={this.props.practicalMin}
              value={this.props.practicalMax}
              onChange={this.props.handlePracticalValue}
              max={this.props.rangeNext}
              // disabled={this.props.practicalMin > 0 ? false : true}
            />
          </div>
        </div>

        <div className="row scale-name">
          <div className="col-md-1"></div>
          <div className="col-md-10 scale-content">
            <label className="min-level-label">
              <strong>Show score as percentage</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
            <input
              type="radio"
              className="score-radio-button"
              id="both"
              value="both"
              checked={this.props.score === "both"}
              onChange={this.props.scoreAsPerChange}
            />
            <label htmlFor="both">Show both score and percentage.</label>

            <input
              type="radio"
              className="score-radio-button"
              checked={this.props.score === "pctg"}
              id="pctg"
              value="pctg"
              onChange={this.props.scoreAsPerChange}
            />
            <label htmlFor="pctg">Show only percentage.</label>
            <input
              type="radio"
              className="score-radio-button"
              checked={this.props.score === "Nopctg"}
              id="Nopctg"
              value="Nopctg"
              onChange={this.props.scoreAsPerChange}
            />
            <label htmlFor="Nopctg">Dont show percentage.</label>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row scale-name">
          <div className="col-md-1"></div>
          <div className="col-md-10 text-center">
            <input
              type="checkbox"
              className="tt-record-deadline-checkbox mr-2"
              name="checked"
              checked={this.props.checked}
              onChange={this.props.universalHandleChange}
              id="checked"
            />
            <label htmlFor="checked">
              <strong>Calculate aggregate (of subjects)</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row scale-name">
          <div className="col text-center">
            <label>
              <strong>Show aggregation as a percentage</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
            <input
              type="radio"
              className="score-radio-button"
              id="aggregateAndPer"
              value="aggregateAndPer"
              checked={this.props.aggregate === "aggregateAndPer"}
              onChange={this.props.aggregateAsPerChange}
              //   disabled={!this.props.checked}
            />
            <label htmlFor="aggregateAndPer">
              Show both aggregate and percentage.
            </label>
            <input
              type="radio"
              className="score-radio-button"
              id="per"
              value="per"
              checked={this.props.aggregate === "per"}
              onChange={this.props.aggregateAsPerChange}
              //   disabled={!this.props.checked}
            />
            <label htmlFor="per">Show only percentage.</label>

            <input
              type="radio"
              className="score-radio-button"
              id="noPer"
              value="noPer"
              checked={this.props.aggregate === "noPer"}
              onChange={this.props.aggregateAsPerChange}
              //   disabled={!this.props.checked}
            />
            <label htmlFor="noPer">Dont show percentage.</label>
          </div>
        </div>
      </>
    );
  }
}

export default ScaleTypeNumberForm;
