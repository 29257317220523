/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, ModalBody, render the body part for the modal in AdmissionList Component
 * @edited      @author Sandeep Shakya
 */

import React, { Component } from "react";
import ModalWindow from "../../UI/ModalWindow";
import { checkRolePermissions, URL } from "../../../utils/Constants";
import moment from "moment";
import userImage from "../../../assets/images/user-image.png";
import StudentContactForm from "./StudentContactForm";
import { handleError } from "../../../utils/Utils";
import Chip from "@material-ui/core/Chip";

class ModalBody extends Component {
  state = {
    studentContactModal: false,
    addStudentContactModal: false,
    studentContacts: [],
    name: "",
  };

  toggleAddStudentContactModal = () => {
    this.setState({
      addStudentContactModal: !this.state.addStudentContactModal,
    });
  };

  checkAge = (dateOfBirth) => {
    const year = moment().diff(dateOfBirth.substring(0, 10), "years");
    let message = year < 10 ? `${year} year old` : `${year} years old`;
    return message;
  };

  renderTableData = (field) => {
    const { customFieldTableColumns, highestLength } = field;
    let height = highestLength < 15 ? `30px` : `${highestLength + 10}px`;
    return (
      <div className="row">
        {customFieldTableColumns?.map((column) => (
          <>
            {column.customFieldTableColumnValues === null &&
            column.customFieldTableSubColumns !== null ? (
              <>
                <div className="col-md-12 text-center">
                  <strong>{column.name}</strong>
                  <hr></hr>
                </div>
                <div className="row container-fluid">
                  {column?.customFieldTableSubColumns?.map(
                    (subCol, subColIdx) => (
                      <div
                        className="col-md-3 mx-auto text-center"
                        key={subCol.id}
                      >
                        <span>
                          <strong>{subCol.name}</strong>
                        </span>
                        <hr></hr>
                        {subCol?.customFieldTableColumnValues?.map(
                          (value, valueIdx) => (
                            <div
                              style={{ height: height }}
                              className="border-bottom"
                            >
                              {value.value === "true"
                                ? "Yes"
                                : value.value === "false"
                                ? "No"
                                : value.value}
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <div className="col-md-3 mx-auto text-center">
                <span>
                  <strong>{column.name}</strong>
                </span>
                <hr></hr>
                {column?.customFieldTableColumnValues?.map((value) => (
                  <div
                    style={{ height: height }}
                    className="border-bottom"
                    key={value.id}
                  >
                    {value.value === "true"
                      ? "Yes"
                      : value.value === "false"
                      ? "No"
                      : value.value}
                  </div>
                ))}
              </div>
            )}
          </>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "86.9vh", overflowX: "hidden", overflowY: "auto" }}
      >
        <div className="row">
          <div className="col-md-5">
            <div className="tt-ppDetail">
              <div>
                <img
                  src={
                    this.props.admissionModalData.people.photo
                      ? URL.imageSelectURL +
                        this.props.admissionModalData.people.photo
                      : userImage
                  }
                  alt="people"
                  onError={(e) => handleError(e)}
                ></img>
              </div>
            </div>
          </div>
          <div className="col-md-7 detailStaffStudent">
            <table className="table table-bordered table-striped">
              <thead className="tt-group-header">
                <tr>
                  <th colSpan={2}>General information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="40%">
                    <strong>Name</strong>
                  </td>
                  <td>{this.props.admissionModalData.people.name}</td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Gender</strong>
                  </td>
                  <td>{this.props.admissionModalData.people.gender}</td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Email Address</strong>
                  </td>
                  <td>
                    {this.props.admissionModalData.people.emailAddress
                      ? this.props.admissionModalData.people.emailAddress !==
                        null
                        ? this.props.admissionModalData.people.emailAddress
                        : "No Email Address"
                      : "No Email Address"}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Date of Birth</strong>
                  </td>
                  <td>
                    {moment(
                      this.props.admissionModalData.people.dateOfBirth
                    ).format("Do MMMM, YYYY")}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Age</strong>
                  </td>
                  <td>
                    {moment().diff(
                      this.props.admissionModalData.people.dateOfBirth.substring(
                        0,
                        10
                      ),
                      "years"
                    ) === 0
                      ? `1 year old`
                      : this.checkAge(
                          this.props.admissionModalData.people.dateOfBirth
                        )}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Admission Status</strong>
                  </td>
                  <td>{this.props.admissionModalData.admissionStatusName}</td>
                </tr>
              </tbody>
            </table>
            {/* <table className="table table-bordered table-striped mt-3">
              <thead className="tt-group-header">
                <tr>
                  <th colSpan={2}>Address information</th>
                </tr>
              </thead>
              <tbody>
                {this.props.admissionListBySelect.length > 0 ? (
                  this.props.admissionListBySelect.map((el, idx) => {
                    return (
                      <tr key={idx}>
                        <td width="40%">
                          <strong>{el.customTableFieldName}</strong>
                        </td>
                        <td>{el.value ? el.value : "No data"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2}>No information available</td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {this.props.peopleAddress.length > 0
              ? this.props.peopleAddress.map((pA, idx) => {
                  return (
                    <table
                      key={idx}
                      className="table table-bordered table-striped"
                    >
                      <thead className="tt-group-header">
                        <tr>
                          <th colSpan={2}>
                            {pA.customTableFieldGroupName
                              ? pA.customTableFieldGroupName
                              : "Address Information"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="40%">
                            <strong>Country</strong>
                          </td>
                          <td>{pA.countryName}</td>
                        </tr>
                        {pA.countryFirstLevelId &&
                        pA.countryFirstLevelId !== "" ? (
                          <>
                            <tr>
                              <td width="40%">
                                <strong>{pA.firstLevelDisplayName}</strong>
                              </td>
                              <td>{pA.countryFirstLevelName}</td>
                            </tr>
                            {pA.countrySecondLevelId &&
                            pA.countrySecondLevelId !== "" ? (
                              <>
                                <tr>
                                  <td width="40%">
                                    <strong>{pA.secondLevelDisplayName}</strong>
                                  </td>
                                  <td>{pA.countrySecondLevelName}</td>
                                </tr>
                                {pA.countryThirdLevelId &&
                                pA.countryThirdLevelId !== "" ? (
                                  <>
                                    <tr>
                                      <td width="40%">
                                        <strong>
                                          {pA.thirdLevelDisplayName}
                                        </strong>
                                      </td>
                                      <td>{pA.countryThirdLevelName}</td>
                                    </tr>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {pA.addressCustomTableFieldValues.length > 0
                          ? pA.addressCustomTableFieldValues.map(
                              (addr, aIdx) => {
                                return (
                                  <tr key={aIdx}>
                                    <td width="40%">
                                      <strong>
                                        {addr.customTableFieldName}
                                      </strong>
                                    </td>
                                    <td>
                                      {addr.value ? addr.value : "No data"}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  );
                })
              : null}
            <table className="table table-bordered table-striped mt-3">
              <thead className="tt-group-header">
                <tr>
                  <th colSpan={2}>Other information</th>
                </tr>
              </thead>
              <tbody>
                {/* {this.props.admissionListByNonCluster.length > 0 ? (
                  this.props.admissionListByNonCluster.map((el, idx) => {
                    return (
                      <tr key={idx}>
                        <td width="40%">
                          <strong>{el.customTableFieldName}</strong>
                        </td>
                        <td>{el.value ? el.value : "No data"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2}>No information available</td>
                  </tr>
                )} */}

                {Object.keys(this.props.admissionListByNonCluster).map(
                  (key) => {
                    return (
                      <>
                        <tr>
                          <th className="tt-group-header" colSpan={2}>
                            <strong>{`${key}`}</strong>
                          </th>
                        </tr>
                        {this.props.admissionListByNonCluster[key].map(
                          (el, idx) => (
                            <>
                              {el.fieldTypeCode !== "table" ? (
                                <tr key={idx}>
                                  <td width="40%">
                                    <strong>{el.customTableFieldName}</strong>
                                  </td>
                                  <td>{el.value ? el.value : "No data."}</td>
                                </tr>
                              ) : (
                                <>
                                  <td>
                                    <strong>{el.customTableFieldName}</strong>
                                  </td>
                                  <td>{this.renderTableData(el)}</td>
                                </>
                              )}
                            </>
                          )
                        )}
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
        {checkRolePermissions("admission-list-detail-add-contact") ? (
          <div className="row">
            <div className="col tt-group-header">
              <div className="row">
                <div className="col-md-8 mt-1 pl-5">
                  Student contact details
                </div>
                <div className="col-md-4 text-right pr-5">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.toggleAddStudentContactModal}
                  >
                    Add Contacts
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.props.studentContactBySelect ? (
          <>
            <div className="detailStaffStudent">
              <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <th width="15%">Name</th>
                    <th>Description</th>
                    <th>Contact Details</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.studentContactBySelect.length > 0
                    ? this.props.studentContactBySelect.map((el, idx) => {
                        return (
                          <tr id={idx} key={idx}>
                            <td className="text-left">
                              <strong>{el.contactPeople.name}</strong>
                              {` (${el.relationship})`}
                              {el.isLegalGuardian ? (
                                <Chip
                                  color={"default"}
                                  className="override-chip"
                                  label={"Legal Guardian"}
                                />
                              ) : null}
                            </td>
                            <td className="text-left">
                              <p>
                                <strong>Gender: </strong>
                              </p>
                              {el.contactPeople.gender}
                              <br></br>
                              <p>
                                <strong>Date of Birth: </strong>
                              </p>
                              {moment(el.contactPeople.dateOfBirth).format(
                                "Do MMMM, YYYY"
                              )}
                              <br></br>
                              <p>
                                <strong>Age: </strong>
                              </p>
                              {moment().diff(
                                el.contactPeople.dateOfBirth.substring(0, 10),
                                "years"
                              ) === 0
                                ? 1
                                : moment().diff(
                                    el.contactPeople.dateOfBirth.substring(
                                      0,
                                      10
                                    ),
                                    "years"
                                  )}
                              <br />
                              {/* <p>
                                <strong>Is Legal Guardian : </strong>
                              </p>
                              {el.isLegalGuardian ? "Yes" : "No"}
                              <br /> */}
                              <p>
                                <strong>Email Address: </strong>
                              </p>
                              {el.contactPeople.emailAddress
                                ? el.contactPeople.emailAddress
                                : "No email address"}
                            </td>
                            <td className="text-left">
                              {el.contactPeople.customTableFieldValues.length >
                              0
                                ? el.contactPeople.customTableFieldValues.map(
                                    (customValue, cusIdx) => {
                                      return (
                                        <span key={cusIdx}>
                                          <p>
                                            <strong>
                                              {customValue.customTableFieldName}
                                              :
                                            </strong>
                                          </p>{" "}
                                          {customValue.value}
                                          <br></br>
                                        </span>
                                      );
                                    }
                                  )
                                : "No contact details available"}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <tr>
            <td colSpan={6}>No information available</td>
          </tr>
        )}
        <ModalWindow
          modal={this.state.addStudentContactModal}
          fullWidth={true}
          toggleModal={this.toggleAddStudentContactModal}
          modalBody={
            <StudentContactForm
              admissionModalData={this.props.admissionModalData}
            />
          }
          modalHeader={"Add Contacts"}
          modalFooter={
            <button
              className="tt-button tt-button-primary"
              onClick={this.toggleAddStudentContactModal}
            >
              Close
            </button>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default ModalBody;
