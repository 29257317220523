import React, { Component } from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL, postType, checkRolePermissions } from "../../../utils/Constants";

import JoditEditor from "jodit-react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: ["file", "about", "copyformat", "print"],
  limitChars: 60000,
  showTooltip: true,
  uploader: {
    insertImageAsBase64URI: true,
    // url: "www.google.com"
  },
};

class Article extends Component {
  state = {
    groupsArray: [],
  };

  componentDidMount() {
    axiosPost(URL.getAllGroups, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        let groupsArray = [];
        groups.forEach((element) => {
          groupsArray.push({ label: element.name, value: element.id });
        });
        this.setState({
          groupsArray,
        });
      }
    });
  }

  render() {
    return (
      <div className="tt-article">
        <div className="row mb-1">
          <div className="col-md-1 mt-1 text-center">
            <strong>Heading</strong>
          </div>
          <div className="col-md-11">
            <input
              type="text"
              className="form-control"
              name="heading"
              value={this.props.heading}
              onChange={this.props.handleChange}
            ></input>
          </div>
        </div>

        <JoditEditor
          onChange={(e) => this.props.handleTextEditor(e)}
          value={this.props.textContent}
          config={config}
        />

        <div className="row mt-2">
          <div className="col-md-auto col-sm-auto text-center mt-1">
            <strong>Keywords</strong>
          </div>
          <div className="col-md-4 col-sm-auto">
            <input
              type="text"
              className="form-control"
              name="articleKeywords"
              value={this.props.articleKeywords}
              onChange={this.props.handleChange}
            ></input>
            <div className="tt-buttonChips">
              <div
                className="green"
                onClick={(e) =>
                  this.props.insertArticleKeyword(e, "Approaches")
                }
              >
                Approaches
              </div>
              <div
                className="blue"
                onClick={(e) => this.props.insertArticleKeyword(e, "Skill")}
              >
                Skill
              </div>
              <div
                className="red"
                onClick={(e) => this.props.insertArticleKeyword(e, "Knowledge")}
              >
                Knowledge
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-auto">
            <select
              className="form-control"
              name="themes"
              value={this.props.themes}
              onChange={this.props.handleChange}
            >
              <option value="" disabled selected>
                Select Theme
              </option>
              {this.props.themeList
                ? this.props.themeList.map((theme, i) => (
                    <option value={theme.id}>{theme.theme}</option>
                  ))
                : null}
            </select>
          </div>
          <div className="col-md-auto col-sm-auto">
            <input
              id="myInput"
              type="file"
              ref={(ref) => (this.upload = ref)}
              style={{ display: "none" }}
              onChange={this.props.handleFileSelect}
              multiple
            />
            {/* <button
              className="tt-button tt-button-primary tt-button-fullWidth mb-2"
              onClick={() => {
                this.upload.click();
              }}
              //   disabled={
              //     this.props.files.length > 0 || this.props.selectedURL
              //       ? true
              //       : false
              //   }
            >
              Attach Image
            </button> */}
            {checkRolePermissions("add-theme") ? (
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleAddThemeClick}
              >
                Add Theme
              </button>
            ) : null}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-auto col-sm-auto acaCheckbox">
            <input
              type="checkbox"
              className="tt-record-deadline-checkbox"
              id="isAcademicRepo"
              name="isAcademicRepo"
              onChange={this.props.handleChange}
              value={this.props.isAcademicRepo}
              checked={this.props.isAcademicRepo}
            ></input>
            <label htmlFor="isAcademicRepo">
              &nbsp;<strong>Submit for Academic Repository</strong>
            </label>
          </div>
          <div className="col questionTitle">
            <p className="mb-0">
              <strong>What questions does this article address ? </strong>
            </p>
          </div>
          <div className="col-md-auto col-sm-auto mb-2">
            <button
              className="tt-button tt-button-primary tt-button-fullWidth"
              onClick={(e) =>
                this.props.handleAddQuestionClick(e, postType.articleCode)
              }
            >
              Add Questions
            </button>
          </div>
        </div>
        {/* Changes made by Azzeta */}
        <div className="row">
          <div className="col-md-6">
            <div className="tt-postNoticeBoxes mt-0">
              <strong>Link References</strong>
              <div className="tt-postEmbeded">
                {this.props.selectedLinks.length > 0
                  ? this.props.selectedLinks.map((link, idx) => {
                      return (
                        <div className="row" key={idx}>
                          <div className="col-md-11">
                            <p>{link}</p>
                          </div>
                          <div className="col-md-1">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={(e) =>
                                this.props.deleteLinkContent(e, idx)
                              }
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="tt-postNoticeBoxes mt-0">
              <strong>Questions</strong>
              <div className="tt-postEmbeded">
                {this.props.questions.length > 0 &&
                this.props.questions[0].question !== ""
                  ? this.props.questions.map((question, idx) => {
                      return (
                        <div className="row" key={idx}>
                          <div className="col-md-11">
                            <p>{question.question}</p>
                          </div>
                          <div className="col-md-1">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={(e) =>
                                this.props.deleteQuestions(e, idx)
                              }
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      );
                    })
                  : "No questions added"}
              </div>
            </div>
          </div>
        </div>
        {/* Changes made by Azzeta */}
        <div className="row mt-2">
          <div className="col-md-auto col-sm-auto text-right">
            <p className="tt-postNoticeHeading">Add Links</p>
          </div>
          <div className="col">
            <select
              className="form-control"
              name="selectedOrigin"
              onChange={this.props.handleChange}
              value={this.props.selectedOrigin}
            >
              <option value="" disabled>
                Insite Post / Youtube / Vimeo / Dailymotion / SlideShare /
                Soundcloud / External Links
              </option>
              <option value="insitePost">Insite Post</option>
              <option value="youtube">Youtube</option>
              <option value="vimeo">Vimeo</option>
              <option value="dailymotion">Dailymotion</option>
              <option value="slideshare">SlideShare</option>
              <option value="soundcloud">Sound Cloud</option>
              <option value="externalLink">External Link</option>
            </select>
          </div>
          <div className="col">
            <input
              type="text"
              name="links"
              value={this.props.links}
              onChange={this.props.handleChange}
              placeholder="URL"
              className={
                this.props.error
                  ? "form-control form-control-warn"
                  : "form-control"
              }
            ></input>
          </div>
          <div className="col-md-auto col-sm-auto manageTOp">
            <button
              className="tt-button tt-button-primary tt-button-fullWidth"
              onClick={this.props.handleLinkChanges}
              disabled={this.props.links === ""}
            >
              Add Link
            </button>
          </div>
        </div>
        <div className="row manageTOp mt-1">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={(e) =>
                this.props.submitPost(
                  e,
                  postType.articleCode,
                  this.props.groupPost,
                  this.props.familyPost
                )
              }
              disabled={
                this.props.heading === "" || this.props.textContent === ""
              }
            >
              Post Article
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Article;
