import React, { Component } from "react";
import "../../../assets/scss/repository.scss";
import RepositorySearchSection from "./RepositorySearchSection";
import swal from "sweetalert";
import RepositoryList from "./RepositoryList";
import { axiosPost } from "../../../utils/AxiosApi";
import { connect } from "react-redux";
import {
  URL,
  draggable,
  postType,
  profilePeopleId,
} from "../../../utils/Constants";
import {
  getPersonAcademicRepoPosts,
  searchAcademicRepoPosts,
  getPersonPostSectionAcademicRepoPosts,
  getPersonProfilePosts,
} from "../../../ducks/PostsDucks";
import RepositoryQuestionLists from "./RepositoryQuestionLists";
import $ from "jquery";
import moment from "moment";
import { DISPLAYMESSAGE, MESSAGEHEADER } from "../../../utils/DisplayMessage";

class Repository extends Component {
  state = {
    searchText: "",
    keywords: "",
    theme: "",
    fromDate: "",
    toDate: "",
    postStatus: true,
    subjects: "",
    themeList: null,
    subjectList: null,
    academicRepoList: null,
    personAcademicRepoPosts: [],
    questionLists: "",
    isVerified: false,
    editArticleAcademicModal: false,
    editMediaAcademicModal: false,
    selectedPost: "",
    spationQuestions: [],
    whoQuestions: [],
    howQuestions: [],
    whatQuestions: [],
    totalAcademicRepoPosts: 0,
    offset: 0,
    rowCountLimit: 8,
    searchParam: "All",
    currentPage: 1,
  };

  toggleArticleEditModal = (e, data, action) => {
    this.setState({
      editArticleAcademicModal: !this.state.editArticleAcademicModal,
    });
    if (action === "open") {
      this.setState({ selectedPost: data });
    }
  };
  toggleMediaEditModal = (e, data, action) => {
    this.setState({
      editMediaAcademicModal: !this.state.editMediaAcademicModal,
    });
    if (action === "open") {
      this.setState({ selectedPost: data });
    }
  };

  componentDidMount() {
    this.getThemeList();
    this.getSubjectList();
    this.props.getPersonAcademicRepoPosts(
      true,
      this.state.offset,
      this.state.rowCountLimit
    );
  }

  //Pagination

  handlePageChange = (page) => {
    this.reRenderRepoList(page);
    this.setState({ currentPage: page }, function () {
      //   this.setDisplayNumber();
    });
  };

  reRenderRepoList = (page) => {
    let offset =
      page * parseInt(this.state.rowCountLimit) -
      parseInt(this.state.rowCountLimit);
    this.setState({ offset: offset }, function () {
      this.props.getPersonAcademicRepoPosts(
        true,
        offset,
        this.state.rowCountLimit
      );
    });
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1)
      this.reRenderRepoList(this.state.currentPage - 1);
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage - 1,
      }),
      function () {
        // this.setDisplayNumber();
      }
    );
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.totalAcademicRepoPosts / this.state.rowCountLimit)
    )
      return;
    else {
      this.reRenderRepoList(nextPage);
      this.setState({ currentPage: nextPage }, function () {
        // this.setDisplayNumber();
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let academicPosts = nextProps.personAcademicRepoPosts;
    let questionLists = [];
    academicPosts.forEach((el) => {
      if (el.postQuestions && el.postQuestions.length > 0) {
        el.postQuestions.forEach((questions) => {
          let count = 0;
          if (questionLists && questionLists.length > 0) {
            questionLists.forEach((storeQuestion) => {
              if (questions.id === storeQuestion.id) {
                count++;
              }
            });
          }
          if (count === 0) {
            questionLists.push(questions);
          }
        });
      }
    });
    let spationQuestions = [];
    let whoQuestions = [];
    let howQuestions = [];
    let whatQuestions = [];
    if (questionLists.length > 0) {
      questionLists.forEach((el) => {
        var firstWord = el.question.substr(0, el.question.indexOf(" "));
        if (
          firstWord.includes("when") ||
          firstWord.includes("where") ||
          firstWord.includes("When") ||
          firstWord.includes("Where")
        ) {
          spationQuestions.push(el);
        }
        if (firstWord.includes("who") || firstWord.includes("Who")) {
          whoQuestions.push(el);
        }
        if (firstWord.includes("how") || firstWord.includes("How")) {
          howQuestions.push(el);
        }
        if (firstWord.includes("what") || firstWord.includes("What")) {
          whatQuestions.push(el);
        }
      });
    }
    let newRepoPosts = [];
    if (academicPosts.length > 0) {
      academicPosts.forEach((post) => {
        if (
          post.postType === postType.articleCode ||
          post.postType === postType.mediaCode
        ) {
          post.contents.forEach((content) => {
            if (content.contentTypeCode === "LNK" && content.content !== "") {
              let videoId = "";
              if (
                content.content.includes("youtube") ||
                content.content.includes("youtu.be")
              ) {
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                var match = content.content.match(regExp);
                if (match && match[7].length == 11) {
                  videoId = match[7];
                }

                var url = "https://www.youtube.com/watch?v=" + videoId;
                //to get youtube title
                $.getJSON(
                  "https://noembed.com/embed",
                  { format: "json", url: url },
                  function (data) {
                    if (data !== undefined || data) {
                      content.youtubeTitle = data.title;
                    } else {
                      content.youtubeTitle = content.content;
                    }
                  }
                );

                //to get thumbnail
                content.thumbnail = `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
              } else if (
                content.content.includes("dailymotion") ||
                content.content.includes("dai.ly")
              ) {
                let url = content.content;
                var m = url.match(
                  /^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/
                );
                if (m === null) {
                  var n = url.match(
                    /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
                  );
                  if (n !== null) {
                    if (n[4] !== undefined) {
                      videoId = n[4];
                    }
                    videoId = n[2];
                  }
                } else if (m !== null) {
                  if (m[4] !== undefined) {
                    videoId = m[4];
                  }
                  videoId = m[2];
                }
                $.ajax({
                  type: "GET",
                  url:
                    "https://api.dailymotion.com/video/" +
                    videoId +
                    "?fields=title,duration,user",
                  dataType: "jsonp",
                  cache: true,
                  success: function (data) {
                    content.youtubeTitle = data.title;
                  },
                });
                content.thumbnail = `https://www.dailymotion.com/thumbnail/video/${videoId}`;
              } else if (content.content.includes("vimeo")) {
                var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
                var parseUrl = content.content.match(regExp);
                videoId = parseUrl[5];
                $.ajax({
                  type: "GET",
                  url: "http://vimeo.com/api/v2/video/" + videoId + ".json",
                  jsonp: "callback",
                  dataType: "jsonp",
                  success: function (data) {
                    content.youtubeTitle = data[0].title;
                    content.thumbnail = data[0].thumbnail_medium;
                  },
                });
              } else if (
                content.content.includes("slideshare") &&
                content.youtubeTitle !== undefined
              ) {
                $.getJSON(
                  "https://noembed.com/embed",
                  { format: "json", url: content.content },
                  function (data) {
                    if (data !== undefined || data) {
                      content.youtubeTitle = data.title;
                      content.thumbnail = data.thumbnail;
                    }
                  }
                );
              } else {
                $.getJSON(
                  "https://noembed.com/embed",
                  { format: "json", url: content.content },
                  function (data) {
                    if (data !== undefined && data && !data.error) {
                      content.youtubeTitle = data.title;
                      if (data.thumbnail_url) {
                        content.thumbnail = data.thumbnail_url;
                      }
                    } else {
                      content.youtubeTitle = content.content;
                    }
                  }
                );
              }
            }
          });
          newRepoPosts.push(post);
        }
      });
    }
    return {
      personAcademicRepoPosts: newRepoPosts,
      questionLists: questionLists,
      spationQuestions: spationQuestions,
      whoQuestions: whoQuestions,
      whatQuestions: whatQuestions,
      howQuestions: howQuestions,
      totalAcademicRepoPosts: nextProps.totalAcademicRepoPosts,
    };
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "toDate" || name === "fromDate") {
      if (name === "toDate" && this.state.fromDate !== "") {
        let toDate = moment(value).format("YYYY-MM-DD");
        let fromDate = moment(this.state.fromDate).format("YYYY-MM-DD");
        if (toDate < fromDate) {
          swal({
            title: "Error",
            text: "Date To cannot be less than Date From",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          return false;
        } else {
          this.setState({
            [name]: value,
          });
        }
      } else if (name === "fromDate" && this.state.toDate !== "") {
        let toDate = moment(this.state.toDate).format("YYYY-MM-DD");
        let fromDate = moment(value).format("YYYY-MM-DD");
        if (fromDate > toDate) {
          swal({
            title: "Error",
            text: "Date From cannot be greater than Date to",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          return false;
        } else {
          this.setState({
            [name]: value,
          });
        }
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  searchPost = (e) => {
    e.preventDefault();
    let data = {
      searchPost: this.state.searchText,
      keywords: this.state.keywords,
      themeId: this.state.theme,
      isVerified: this.state.postStatus,
      from: this.state.fromDate,
      to: this.state.toDate,
      subject: this.state.subjects,
      offset: 0,
      rowCountLimit: this.state.rowCountLimit,
    };
    this.setState({
      searchParam: this.state.searchText !== "" ? this.state.searchText : "All",
    });
    this.props.searchAcademicRepoPosts(data);
    // this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
  };

  acceptAcademicRepo = (e, post) => {
    let data = {
      id: post.id,
      isVerified: true,
      postKeywords: [],
      postQuestions: [],
      subjects: [],
      textContent: post.textContent,
      heading: post.heading,
      contents: [],
      postThemes: [],
    };
    axiosPost(URL.updateAcademicRepo, data, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: "Updated successfully",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.searchPost(e);
        this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
        this.props.getPersonProfilePosts(profilePeopleId, "initCall");
      }
    });
  };

  updateAcademicRepo = (e, data) => {
    axiosPost(URL.updateAcademicRepo, data, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: "Updated successfully",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        if (this.state.editArticleAcademicModal) {
          this.toggleArticleEditModal(e);
        }
        this.searchPost(e);
      }
    });
  };

  updateMediaAcademicRepo = (e, data, selectedImages) => {
    e.preventDefault();
    let newImages = [];
    let oldImages = [];
    if (selectedImages.length > 0) {
      selectedImages.forEach((el) => {
        if (el.updatedData) {
          oldImages.push(el);
        } else {
          newImages.push(el);
        }
      });
      if (oldImages.length > 0) {
        oldImages.forEach((ol) => {
          data.contents.push(ol);
        });
      }
    }
    axiosPost(URL.updateAcademicRepo, data, (response) => {
      if (response.status === 200) {
        if (newImages.length > 0) {
          let formData = new FormData();

          for (const key of Object.keys(newImages)) {
            formData.append("content", newImages[key]);
          }
          formData.append(
            "jsonData",
            JSON.stringify({
              origin: "myPC",
              postId: response.data.data.postId,
            })
          );

          axiosPost(URL.insertPostContent, formData, (response) => {
            if (response.status === 200) {
              swal({
                title: MESSAGEHEADER.success,
                text: "Updated successfully",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.searchPost(e);
              this.toggleMediaEditModal(e);
            }
          });
        } else {
          swal({
            title: MESSAGEHEADER.success,
            text: "Updated successfully",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          this.searchPost(e);
          this.toggleMediaEditModal(e);
        }
      }
    });
  };

  searchQuestion = (e, question) => {
    e.preventDefault();
    this.setState({ searchText: question }, function () {
      this.searchPost(e);
    });
  };

  getThemeList = () => {
    axiosPost(URL.selectTheme, {}, (response) => {
      if (response.status === 200) {
        this.setState({ themeList: response.data.data });
      }
    });
  };
  getSubjectList = () => {
    axiosPost(URL.subjectList, {}, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
      }
    });
  };

  clearSearch = (e) => {
    this.setState(
      {
        searchText: "",
        keywords: "",
        theme: "",
        fromDate: "",
        toDate: "",
        postStatus: true,
        subjects: "",
      },
      function () {
        this.searchPost(e);
      }
    );
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "87vh" }}>
        <div className="row">
          <div className="col-md-9">
            <RepositorySearchSection
              searchText={this.state.searchText}
              keywords={this.state.keywords}
              handleChange={this.handleChange}
              theme={this.state.theme}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              postStatus={this.state.postStatus}
              subjects={this.state.subjects}
              searchPost={this.searchPost}
              themeList={this.state.themeList}
              subjectList={this.state.subjectList}
              totalAcademicRepoPosts={this.state.totalAcademicRepoPosts}
              clearSearch={this.clearSearch}
              searchParam={this.state.searchParam}
            ></RepositorySearchSection>
            <RepositoryList
              personAcademicRepoPosts={this.state.personAcademicRepoPosts}
              acceptAcademicRepo={this.acceptAcademicRepo}
              subjectList={this.state.subjectList}
              updateAcademicRepo={this.updateAcademicRepo}
              toggleArticleEditModal={this.toggleArticleEditModal}
              toggleMediaEditModal={this.toggleMediaEditModal}
              editArticleAcademicModal={this.state.editArticleAcademicModal}
              editMediaAcademicModal={this.state.editMediaAcademicModal}
              selectedPost={this.state.selectedPost}
              updateMediaAcademicRepo={this.updateMediaAcademicRepo}
              totalAcademicRepoPosts={this.state.totalAcademicRepoPosts}
              rowCountLimit={this.state.rowCountLimit}
              currentPage={this.state.currentPage}
              handlePageChange={this.handlePageChange}
              handleNextPage={this.handleNextPage}
              handlePreviousPage={this.handlePreviousPage}
            ></RepositoryList>
          </div>
          <div className="col-md-3">
            <RepositoryQuestionLists
              questionLists={this.state.questionLists}
              searchQuestion={this.searchQuestion}
              spationQuestions={this.state.spationQuestions}
              whoQuestions={this.state.whoQuestions}
              whatQuestions={this.state.whatQuestions}
              howQuestions={this.state.howQuestions}
            ></RepositoryQuestionLists>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  personAcademicRepoPosts: state.posts.personAcademicRepoPosts,
  totalAcademicRepoPosts: state.posts.totalAcademicRepoPosts,
});

const mapActionToProps = {
  getPersonAcademicRepoPosts,
  searchAcademicRepoPosts,
  getPersonPostSectionAcademicRepoPosts,
  getPersonProfilePosts,
};
export default connect(mapStateToProps, mapActionToProps)(Repository);
