/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, Departments, renders view to create a department with field Department Name
 *
 */

import React, { Component } from "react";
import {
  URL,
  checkRolePermissions,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import {
  showErroMessage,
  displayConfirmDeleteAlert,
  displaySuccessAlert,
} from "../../../../utils/Utils";
import { axiosPost } from "../../../../utils/AxiosApi";
import swal from "sweetalert";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";

const animatedComponents = makeAnimated();

class Departments extends Component {
  state = {
    departmentName: "",
    departmentNames: [],
    details: "",
    assignModal: false,
    teachers: [],
    selectedTeachers: [],
    showSpinner: false,
    departmenteditable: false,
    updatedDepartmentName: "",
    updatedDepartmentDetails: "",
    updatedId: "",
    isLoading: true,
    nameError: false,
    permissionInsertDepartment: false,
    permissionAssignStaffDepartment: false,
  };

  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree)
   */
  componentDidMount() {
    this.getListOfDepartments();
    this.staffStatus();
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionInsertDepartment: checkRolePermissions(
        "insert-department",
        "activity"
      ),
      permissionAssignStaffDepartment: checkRolePermissions(
        "assign-staff-department",
        "activity"
      ),
    });
  };

  staffStatus = () => {
    axiosPost(URL.getRecruitmentStatus, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        if (data.length > 0) {
          data.forEach((el) => {
            if (el.name === "Current") {
              this.getStaffList(el.id);
            }
          });
        }
      }
    });
  };

  getStaffList = (id) => {
    let data = {
      recruitmentStatusId: parseInt(id),
    };
    axiosPost(URL.getStaffList, data, (response) => {
      if (response.status === 200) {
        let teachers = [];
        let data = response.data.data;
        data.forEach((el) => {
          teachers.push({
            label: el.people.name,
            value: el.id,
          });
        });
        this.setState({
          recruitments: response.data.data,
          teachers: teachers,
        });
      }
    });
  };

  /**
   * The method gets list of Departments from the Database
   */
  getListOfDepartments = () => {
    this.setState({ isLoading: true }, function () {
      axiosPost(URL.getDepartment, {}, (response) => {
        this.setState({ isLoading: false });
        if (response.status === 200) {
          let data = response.data.data;
          if (data.length > 0) {
            data.forEach((el) => {
              el.editable = false;
              el.departmenteditable = false;
              el.selectedTeachers = [];
              if (el.peoples.length > 0) {
                el.peoples.forEach((p) => {
                  el.selectedTeachers.push({
                    label: p.name,
                    value: p.recruitmentId,
                  });
                });
              }
            });
          }
          this.setState({
            departmentNames: data,
          });
        }
      });
    });
  };
  /**
   * This method console logs out all the states
   */
  submit = (e, params) => {
    e.preventDefault();
    //Api call to post JSON
    if (params.name === "") {
      swal({
        title: "Warning",
        text: "First enter department name",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertDepartment,
          params,
          (response) => {
            if (response.status === 200) {
              var self = this;
              swal({
                title: "Success",
                text: "Department has been successfully added",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              }).then(function () {
                self.nameInput.focus();
              });
              draggable();
              this.setState({
                departmentName: "",
                details: "",
                showSpinner: false,
              });
              this.getListOfDepartments();
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        );
      });
    }
  };
  /**
   * This method return the JSON structure that need to been send through the promise
   */
  getParameter = () => {
    return {
      name: this.state.departmentName,
      details: this.state.details,
    };
  };

  handleChangeAssignSelect = (selectedItem, idx) => {
    let departmentNames = [...this.state.departmentNames];
    departmentNames[idx].selectedTeachers = selectedItem;
    this.setState({ departmentNames });
  };

  handleUpdateDepartment = (e, index) => {
    let departments = [...this.state.departmentNames];
    if (departments.length > 0) {
      departments.forEach((department, i) => {
        department.departmenteditable = false;
      });
      departments[index].departmenteditable = true;
      this.setState({
        departmentNames: departments,
        updatedDepartmentName: departments[index].name,
        updatedDepartmentDetails: departments[index].details,
        updatedId: departments[index].id,
      });
    }
  };

  handleCancelUpdate = (e, index) => {
    e.preventDefault();
    let departments = [...this.state.departmentNames];
    departments[index].departmenteditable = false;
    this.setState({ departmentNames: departments });
  };

  handleSubmitUpdate = (e, id) => {
    e.preventDefault();
    let param = {
      name: this.state.updatedDepartmentName,
      details: this.state.updatedDepartmentDetails,
      id: id,
    };
    axiosPost(URL.updateDepartment, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.setState({ departmentNames: [] }, function () {
          this.getListOfDepartments();
        });
      } else {
        showErroMessage(response);
      }
    });
  };

  assignStaff = (datas) => {
    let departmentId = datas.id;
    let recruitmentIds = [];
    let selectedTeachers = datas.selectedTeachers;
    // if (selectedTeachers && selectedTeachers.length > 0) {
    if (selectedTeachers && selectedTeachers.length > 0) {
      selectedTeachers.forEach((el) => {
        recruitmentIds.push(parseInt(el.value));
      });
    }
    let data = {
      departmentId: departmentId,
      recruitmentIds: recruitmentIds,
    };
    axiosPost(URL.insertDepartmentStaff, data, (response) => {
      if (response.status === 200) {
        this.getListOfDepartments();
      }
    });
  };

  updateDepartment = (el, index) => {};

  deleteDepartment = (datas) => {
    displayConfirmDeleteAlert({ datas }, this.handleDeleteDepartment);
  };

  handleDeleteDepartment = (param) => {
    let datas = param.datas;
    axiosPost(URL.deleteDepartment, datas, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Department has been successfully deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.setState({ departmentNames: [] }, function () {
          this.getListOfDepartments();
        });
      }
    });
  };

  editStaff = (idx) => {
    let departmentNames = [...this.state.departmentNames];
    if (departmentNames.length > 0) {
      departmentNames.forEach((el) => {
        el.editable = false;
      });
      departmentNames[idx].editable = true;
    }
    this.setState({ departmentNames });
  };

  updateStaff = (datas) => {
    this.assignStaff(datas);
  };

  cancelUpdate = () => {
    let departmentNames = [...this.state.departmentNames];
    if (departmentNames.length > 0) {
      departmentNames.forEach((el) => {
        el.editable = false;
      });
    }
    this.setState({ departmentNames });
  };

  /**
   * @author Suyog Manandhar
   *
   * This method maps the states in the class with respective input fields with the corrsponding name
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "departmentName" && value.length > 0) {
      this.setState({ nameError: false });
    }
    this.setState({
      [name]: value,
    });
  };
  /**
   * This method renders the list of Departments
   */
  renderListOfDepartments = () => {
    return (
      <>
        {this.state.departmentNames ? (
          <>
            <div
              className="tt-department-table"
              style={
                this.state.permissionInsertDepartment
                  ? {}
                  : { height: "67.5vh" }
              }
            >
              {!this.state.isLoading ? (
                <table className="table text-center table-bordered table-striped">
                  <thead className="tt-group-header">
                    <tr>
                      <th width="20%">Department Name</th>
                      <th width="40%">Details</th>
                      <th>Staffs</th>
                      {this.state.permissionAssignStaffDepartment ? (
                        <th width="120px">Option</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.departmentNames.length > 0 ? (
                      this.state.departmentNames.map((el, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {el.departmenteditable ? (
                                <input
                                  type="text"
                                  id={"name" + idx}
                                  className="form-control"
                                  value={this.state.updatedDepartmentName}
                                  name="updatedDepartmentName"
                                  onChange={this.handleChange}
                                />
                              ) : (
                                el.name
                              )}
                            </td>
                            <td>
                              {el.departmenteditable ? (
                                <textarea
                                  id={"details" + idx}
                                  className="form-control"
                                  value={this.state.updatedDepartmentDetails}
                                  name="updatedDepartmentDetails"
                                  onChange={this.handleChange}
                                />
                              ) : (
                                el.details
                              )}
                            </td>
                            <td className="text-left">
                              {el.peoples ? (
                                el.peoples.length > 0 ? (
                                  !el.editable ? (
                                    el.peoples.map((people, pIdx) => {
                                      return (
                                        <p
                                          key={pIdx}
                                          className="listedTeachers"
                                        >
                                          <span>{people.name}</span>
                                          {pIdx !== el.peoples.length - 1 ? (
                                            <span>{", "}</span>
                                          ) : null}
                                        </p>
                                      );
                                    })
                                  ) : this.state
                                      .permissionAssignStaffDepartment ? (
                                    <Select
                                      closeMenuOnSelect={false}
                                      isClearable={false}
                                      components={animatedComponents}
                                      isMulti
                                      value={el.selectedTeachers}
                                      placeholder="Assign staff to department"
                                      options={this.state.teachers}
                                      name="assignStaff"
                                      onChange={(newValue) =>
                                        this.handleChangeAssignSelect(
                                          newValue,
                                          idx
                                        )
                                      }
                                      hideSelectedOptions={true}
                                    ></Select>
                                  ) : (
                                    "No Staff Assigned"
                                  )
                                ) : this.state
                                    .permissionAssignStaffDepartment ? (
                                  <Select
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    components={animatedComponents}
                                    isMulti
                                    placeholder="Assign staff to department"
                                    options={this.state.teachers}
                                    name="assignStaff"
                                    onChange={(newValue) =>
                                      this.handleChangeAssignSelect(
                                        newValue,
                                        idx
                                      )
                                    }
                                    hideSelectedOptions={true}
                                  ></Select>
                                ) : (
                                  "No staff Assigned"
                                )
                              ) : (
                                "No data"
                              )}
                            </td>
                            {this.state.permissionAssignStaffDepartment ? (
                              <td>
                                {el.peoples ? (
                                  el.peoples.length > 0 ? (
                                    <>
                                      <button
                                        className="tt-button tt-button-primary tt-button-fullWidth"
                                        onClick={
                                          el.editable
                                            ? () => this.updateStaff(el)
                                            : () => this.editStaff(idx)
                                        }
                                      >
                                        {el.editable
                                          ? "Update Staff"
                                          : "Edit Staff"}
                                      </button>
                                      {el.editable ? (
                                        <button
                                          className="tt-button tt-button-primary tt-button-fullWidth"
                                          onClick={this.cancelUpdate}
                                        >
                                          Cancel
                                        </button>
                                      ) : null}
                                    </>
                                  ) : (
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={() => this.assignStaff(el)}
                                    >
                                      Assign Staff
                                    </button>
                                  )
                                ) : (
                                  <button
                                    className="tt-button tt-button-primary tt-button-fullWidth"
                                    onClick={() => this.assignStaff(el)}
                                  >
                                    Assign Staff
                                  </button>
                                )}
                                {/* <button
                                  className="tt-button tt-button-primary"
                                  onClick={() => this.updateDepartment(el, idx)}
                                >
                                  Update
                                </button> */}
                                {/* <br></br> */}
                                <button
                                  className="tt-button tt-button-primary tt-button-fullWidth"
                                  onClick={() => this.deleteDepartment(el)}
                                >
                                  Delete
                                </button>
                                <br></br>
                                {el.departmenteditable ? (
                                  <>
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) =>
                                        this.handleSubmitUpdate(e, el.id)
                                      }
                                    >
                                      Save
                                    </button>
                                    <br></br>
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) =>
                                        this.handleCancelUpdate(e, idx)
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="tt-button tt-button-primary tt-button-fullWidth"
                                    onClick={(e) =>
                                      this.handleUpdateDepartment(e, idx)
                                    }
                                  >
                                    Update
                                  </button>
                                )}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            this.state.permissionAssignStaffDepartment ? 4 : 3
                          }
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="text-center" style={{ marginTop: "50px" }}>
                  <Spinner color="primary" />
                </div>
              )}
            </div>
          </>
        ) : null}
      </>
    );
  };
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          Departments
          {checkWidgetAdminOrNot("Academic Administration") === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        {this.renderListOfDepartments()}
        {this.state.permissionInsertDepartment === false ? null : (
          <>
            <div className="tt-group-header">Add New Department</div>
            <form onSubmit={(e) => this.submit(e, this.getParameter())}>
              <div className="form-group row">
                <div className="form-group col-md-2"></div>
                <div className="form-group col-md-3">
                  <label htmlFor="departmentName">
                    <strong>Department name</strong>
                    <span className="tt-assessment-module-mandatory">*</span>
                  </label>
                </div>
                <div className="form-group col-md-4">
                  <input
                    className={
                      this.state.nameError
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    type="text"
                    ref={(input) => {
                      this.nameInput = input;
                    }}
                    name="departmentName"
                    id="departmentName"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.departmentName}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="form-group col-md-2"></div>
                <div className="form-group col-md-3">
                  <label htmlFor="details">
                    <strong className="optional">Details</strong>
                  </label>
                </div>
                <div className="form-group col-md-4">
                  <textarea
                    className="form-control"
                    name="details"
                    id="details"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.details}
                  />
                </div>
              </div>
              <div className="row tt-group-header mb-0">
                <div className="col-md-2"></div>
                <div className="col-md-3"></div>
                <div className="col-md-4 text-right">
                  <button className="tt-button tt-button-primary" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <ModalWindow
              modal={this.state.permissionModal}
              size="lg"
              id="tt-permissionModal"
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              modalBody={
                <GranularPermissionModal
                  widgetName="Academic Administration"
                  moduleName="School"
                  header="View Departments"
                  activityName="select-departments"
                />
              }
            ></ModalWindow>
            {this.state.showSpinner ? (
              <div className="fullWindow-Spinner">
                <div>
                  <Spinner color="white"></Spinner>
                </div>
                <div style={{ fontSize: "16px", marginTop: "15px" }}>
                  Please Wait. Creating Department ...
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

export default Departments;
