import React, { Component } from "react";
import {
  scaleTypeCode,
  URL,
  checkSubsTeacherPermissions,
} from "../../../utils/Constants";
import { connect } from "react-redux";
import { updateStudentList } from "../../../ducks/PrimaryARRDucks";
import JoditEditor from "jodit-react";
import "../../../../src/assets/scss/form.scss";
import userImage from "../../../assets/images/user-image.png";
import CounterInput from "react-counter-input";
import ModalWindow from "../../UI/ModalWindow";
import CommentsBank from "./CommentsBank";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";
import { Spinner } from "reactstrap";
import {
  compareGardeLevelsByGPAPoint,
  handleError,
} from "../../../utils/Utils";
import { checkRolePermissions } from "../../../utils/Constants";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: [
    "hr",
    "about",
    "fullsize",
    "eraser",
    "undo",
    "redo",
    "table",
    "image",
    "format",
    "brush",
    "source",
    "fontsize",
    "paragraph",
    "left",
    "center",
    "right",
    "justify",
    "video",
    "superscript",
    "subscript",
    "file",
    "print",
  ],
};
class StudentSubjectReport extends Component {
  state = {
    modal: false,
    commentIdx: null,
    finalizeReportPermission: null,
    unFinalizeReportPermission: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isOpen) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    let finalizeReportPermission =
      checkRolePermissions(
        "finalize-subject-assessment",
        "activity",
        this.props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "finalize-subject-assessment",
        "activity",
        this.props.widgetName
      );

    let unFinalizeReportPermission =
      checkRolePermissions(
        "unfinalize-subject-assessment",
        "activity",
        this.props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "unfinalize-subject-assessment",
        "activity",
        this.props.widgetName
      );

    this.setState({
      finalizeReportPermission,
      unFinalizeReportPermission,
    });
  };

  componentDidUpdate() {
    if (this.props.isOpen) {
      $(".selectpicker").selectpicker();
      $(".selectpicker").selectpicker("refresh");
    } else {
      $(".selectpicker").selectpicker("destroy");
    }
  }

  handleCommentBankClick = (e, idx) => {
    this.setState({ modal: !this.state.modal, commentIdx: idx });
  };

  render() {
    let students = this.props.students;
    let assessmentScales = null,
      assessmentScalesForPractical = null,
      assessmentScalesForTheory = null;
    if (students && students.length > 0) {
      assessmentScalesForPractical = students[0].assessmentScalesForPractical;
      assessmentScalesForTheory = students[0].assessmentScalesForTheory;
    }
    let assessmentSubjectComment = this.props.assessmentSubjectComment;
    return (
      <>
        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Processing...
            </div>
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div
              className="col tt-widgetContent-tab-holder"
              style={{ height: "75vh", marginBottom: "10px" }}
            >
              <table className="table table-striped table-bordered">
                <thead
                  className="tt-group-header text-center"
                  style={{ position: "sticky", top: "-1px", zIndex: 1000 }}
                >
                  <tr>
                    <th width="100px" rowSpan={2}>
                      Image
                    </th>
                    <th width="130px" rowSpan={2}>
                      Student Name
                    </th>
                    {assessmentScalesForTheory &&
                    assessmentScalesForTheory.length > 0 ? (
                      <th
                        rowSpan={1}
                        colSpan={assessmentScalesForTheory.length}
                      >
                        Theory
                      </th>
                    ) : null}
                    {assessmentScalesForPractical &&
                    assessmentScalesForPractical.length > 0 ? (
                      <th
                        rowSpan={1}
                        colSpan={assessmentScalesForPractical.length}
                      >
                        Practical
                      </th>
                    ) : null}
                    {assessmentSubjectComment ? (
                      <th width="35%" rowSpan={2}>
                        Comment
                      </th>
                    ) : null}
                  </tr>
                  <tr>
                    {assessmentScalesForTheory &&
                    assessmentScalesForTheory.length
                      ? assessmentScalesForTheory.map((scale) => (
                          <th rowSpan={1}>{scale.scaleName}</th>
                        ))
                      : null}
                    {assessmentScalesForPractical &&
                    assessmentScalesForPractical.length
                      ? assessmentScalesForPractical.map((scale) => (
                          <th rowSpan={1}>{scale.scaleName}</th>
                        ))
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {students ? (
                    students.length > 0 ? (
                      students.map((student, idx) => (
                        <tr>
                          <td>
                            <img
                              width="90"
                              height="90"
                              src={
                                student.people.photo
                                  ? URL.imageSelectURL + student.people.photo
                                  : userImage
                              }
                              style={{ objectFit: "cover" }}
                              onError={(e) => handleError(e)}
                            ></img>
                          </td>
                          <td>
                            {student.people
                              ? student.people.name
                              : "Student Name"}
                          </td>
                          {student.assessmentScalesForTheory &&
                          student.assessmentScalesForTheory.length > 0
                            ? student.assessmentScalesForTheory.map(
                                (assessmentScale, i) => (
                                  <td className="text-center">
                                    {assessmentScale.isTheoryAbsent ? null : (
                                      <div>
                                        {assessmentScale.assessmentScaleTypeCode ===
                                        scaleTypeCode.level ? (
                                          <select
                                            style={{ Width: "50%" }}
                                            value={
                                              assessmentScale.assessmentScaleLevelTheoryId
                                                ? assessmentScale.assessmentScaleLevelTheoryId
                                                : ""
                                            }
                                            onChange={(e) =>
                                              this.props.handleAssessmentScaleForTheoryLevelChange(
                                                e,
                                                idx,
                                                i
                                              )
                                            }
                                            className="form-control selectpicker"
                                            disabled={
                                              this.props.finalize ? true : false
                                            }
                                          >
                                            <option value="" disabled>
                                              {"Select Level"}
                                            </option>
                                            {assessmentScale.assessmentScaleLevels
                                              ? assessmentScale.assessmentScaleLevels.map(
                                                  (scaleLevel) => (
                                                    <>
                                                      {scaleLevel.levelType ===
                                                      "colourStickers" ? (
                                                        <option
                                                          value={scaleLevel.id}
                                                          style={{
                                                            backgroundColor:
                                                              scaleLevel.level,
                                                            color: "#fff",
                                                            fontSize: "25px",
                                                          }}
                                                        >
                                                          {scaleLevel.level}
                                                        </option>
                                                      ) : scaleLevel.levelType ===
                                                        "image" ? (
                                                        <option
                                                          value={scaleLevel.id}
                                                          data-content={`<img src=${
                                                            URL.imageSelectURL +
                                                            scaleLevel.level
                                                          } class="img-responsive" height="42" width="42"/>`}
                                                        ></option>
                                                      ) : (
                                                        <option
                                                          value={scaleLevel.id}
                                                        >
                                                          {scaleLevel.level}
                                                        </option>
                                                      )}
                                                    </>
                                                  )
                                                )
                                              : null}
                                          </select>
                                        ) : assessmentScale.assessmentScaleTypeCode ===
                                          scaleTypeCode.mark ? (
                                          <CounterInput
                                            min={0}
                                            max={
                                              assessmentScale
                                                ? assessmentScale.theoryMax
                                                  ? assessmentScale.theoryMax
                                                  : 100
                                                : 100
                                            }
                                            count={
                                              assessmentScale
                                                ? assessmentScale.theoryObtainedMarks
                                                  ? assessmentScale.theoryObtainedMarks
                                                  : 0
                                                : 0
                                            }
                                            onCountChange={(count) =>
                                              this.props.theoryCounterHandler(
                                                count,
                                                idx,
                                                i
                                              )
                                            }
                                            btnStyle={{
                                              background: this.props.finalize
                                                ? "#dddddd"
                                                : "#0417a3",
                                              color: this.props.finalize
                                                ? "grey"
                                                : "white",
                                            }}
                                            inputStyle={{
                                              border: this.props.finalize
                                                ? "1px solid grey"
                                                : "1px solid black",
                                              height: "41px",
                                              width: "40px !important",
                                              background: this.props.finalize
                                                ? "#dddddd"
                                                : "",
                                              color: this.props.finalize
                                                ? "grey"
                                                : "",
                                            }}
                                            wrapperStyle={{
                                              justifyContent: "center",
                                              pointerEvents: this.props.finalize
                                                ? "none"
                                                : "",
                                            }}
                                          />
                                        ) : assessmentScale.assessmentScaleTypeCode ===
                                          scaleTypeCode.gradePoint ? (
                                          <select
                                            value={
                                              assessmentScale.assessmentScaleGradePointTheoryId
                                                ? assessmentScale.assessmentScaleGradePointTheoryId
                                                : ""
                                            }
                                            onChange={(e) =>
                                              this.props.handleAssessmentScaleTheoryGradePointChange(
                                                e,
                                                idx,
                                                i
                                              )
                                            }
                                            style={{ Width: "50%" }}
                                            disabled={
                                              this.props.finalize ? true : false
                                            }
                                          >
                                            <option value="" disabled>
                                              {"Select Grade Point"}
                                            </option>
                                            {assessmentScale.assessmentScaleGradePoints
                                              ? assessmentScale.assessmentScaleGradePoints
                                                  .sort(
                                                    compareGardeLevelsByGPAPoint
                                                  )
                                                  .map((scaleGradePoint) => (
                                                    <option
                                                      value={scaleGradePoint.id}
                                                    >
                                                      {scaleGradePoint.grade}
                                                    </option>
                                                  ))
                                              : null}
                                          </select>
                                        ) : null}
                                      </div>
                                    )}

                                    <div className="mt-2">
                                      <input
                                        id={
                                          "isTheoryAbsent" +
                                          assessmentScale.assessmentScaleTypeCode +
                                          i +
                                          idx
                                        }
                                        type="checkbox"
                                        name="isTheoryAbsent"
                                        checked={assessmentScale.isTheoryAbsent}
                                        disabled={
                                          this.props.finalize ? true : false
                                        }
                                        onChange={(e) =>
                                          this.props.handleIsAbsentForTheory(
                                            e,
                                            idx,
                                            i
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          "isTheoryAbsent" +
                                          assessmentScale.assessmentScaleTypeCode +
                                          i +
                                          idx
                                        }
                                        style={{
                                          paddingLeft: "5px",
                                          position: "relative",
                                          top: "-3px",
                                        }}
                                      >
                                        <strong>Is Absent</strong>
                                      </label>
                                    </div>
                                  </td>
                                )
                              )
                            : null}
                          {student.assessmentScalesForPractical &&
                          student.assessmentScalesForPractical.length > 0
                            ? student.assessmentScalesForPractical.map(
                                (assessmentScale, i) => (
                                  <td className="text-center">
                                    {assessmentScale.isPracticalAbsent ? null : (
                                      <div>
                                        {assessmentScale.assessmentScaleTypeCode ===
                                        scaleTypeCode.level ? (
                                          <select
                                            style={{ Width: "50%" }}
                                            value={
                                              assessmentScale.assessmentScaleLevelPracticalId
                                                ? assessmentScale.assessmentScaleLevelPracticalId
                                                : ""
                                            }
                                            onChange={(e) =>
                                              this.props.handleAssessmentScaleForPracticalLevelChange(
                                                e,
                                                idx,
                                                i
                                              )
                                            }
                                            className="form-control selectpicker"
                                            disabled={
                                              this.props.finalize ? true : false
                                            }
                                          >
                                            <option value="" disabled>
                                              {"Select Level"}
                                            </option>
                                            {assessmentScale.assessmentScaleLevels
                                              ? assessmentScale.assessmentScaleLevels.map(
                                                  (scaleLevel) => (
                                                    <>
                                                      {scaleLevel.levelType ===
                                                      "colourStickers" ? (
                                                        <option
                                                          value={scaleLevel.id}
                                                          style={{
                                                            backgroundColor:
                                                              scaleLevel.level,
                                                            color: "#fff",
                                                            fontSize: "25px",
                                                          }}
                                                        >
                                                          {scaleLevel.level}
                                                        </option>
                                                      ) : scaleLevel.levelType ===
                                                        "image" ? (
                                                        <option
                                                          value={scaleLevel.id}
                                                          data-content={`<img src=${
                                                            URL.imageSelectURL +
                                                            scaleLevel.level
                                                          } class="img-responsive" height="42" width="42"/>`}
                                                        ></option>
                                                      ) : (
                                                        <option
                                                          value={scaleLevel.id}
                                                        >
                                                          {scaleLevel.level}
                                                        </option>
                                                      )}
                                                    </>
                                                  )
                                                )
                                              : null}
                                          </select>
                                        ) : assessmentScale.assessmentScaleTypeCode ===
                                          scaleTypeCode.mark ? (
                                          <CounterInput
                                            min={0}
                                            max={
                                              assessmentScale
                                                ? assessmentScale.practicalMax
                                                  ? assessmentScale.practicalMax
                                                  : 100
                                                : 100
                                            }
                                            count={
                                              assessmentScale
                                                ? assessmentScale.practicalObtainedMarks
                                                  ? assessmentScale.practicalObtainedMarks
                                                  : 0
                                                : 0
                                            }
                                            onCountChange={(count) =>
                                              this.props.practicalCounterHandler(
                                                count,
                                                idx,
                                                i
                                              )
                                            }
                                            btnStyle={{
                                              background: this.props.finalize
                                                ? "#dddddd"
                                                : "#0417a3",
                                              color: this.props.finalize
                                                ? "grey"
                                                : "white",
                                            }}
                                            inputStyle={{
                                              border: this.props.finalize
                                                ? "1px solid grey"
                                                : "1px solid black",
                                              height: "41px",
                                              width: "40px !important",
                                              background: this.props.finalize
                                                ? "#dddddd"
                                                : "",
                                              color: this.props.finalize
                                                ? "grey"
                                                : "",
                                            }}
                                            wrapperStyle={{
                                              justifyContent: "center",
                                              pointerEvents: this.props.finalize
                                                ? "none"
                                                : "",
                                            }}
                                          />
                                        ) : assessmentScale.assessmentScaleTypeCode ===
                                          scaleTypeCode.gradePoint ? (
                                          <select
                                            value={
                                              assessmentScale.assessmentScaleGradePointPracticalId
                                                ? assessmentScale.assessmentScaleGradePointPracticalId
                                                : ""
                                            }
                                            onChange={(e) =>
                                              this.props.handleAssessmentScalePracticalGradePointChange(
                                                e,
                                                idx,
                                                i
                                              )
                                            }
                                            style={{ Width: "50%" }}
                                            disabled={
                                              this.props.finalize ? true : false
                                            }
                                          >
                                            <option value="" disabled>
                                              {"Select Grade Point"}
                                            </option>
                                            {assessmentScale.assessmentScaleGradePoints
                                              ? assessmentScale.assessmentScaleGradePoints
                                                  .sort(
                                                    compareGardeLevelsByGPAPoint
                                                  )
                                                  .map((scaleGradePoint) => (
                                                    <option
                                                      value={scaleGradePoint.id}
                                                    >
                                                      {scaleGradePoint.grade}
                                                    </option>
                                                  ))
                                              : null}
                                          </select>
                                        ) : null}
                                      </div>
                                    )}

                                    <div className="mt-2">
                                      <input
                                        id={
                                          "isPracticalAbsent" +
                                          assessmentScale.assessmentScaleTypeCode +
                                          i +
                                          idx
                                        }
                                        type="checkbox"
                                        name="isPracticalAbsent"
                                        checked={
                                          assessmentScale.isPracticalAbsent
                                        }
                                        disabled={
                                          this.props.finalize ? true : false
                                        }
                                        onChange={(e) =>
                                          this.props.handleIsAbsentForPractical(
                                            e,
                                            idx,
                                            i
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          "isPracticalAbsent" +
                                          assessmentScale.assessmentScaleTypeCode +
                                          i +
                                          idx
                                        }
                                        style={{
                                          paddingLeft: "5px",
                                          position: "relative",
                                          top: "-3px",
                                        }}
                                      >
                                        <strong>Is Absent</strong>
                                      </label>
                                    </div>
                                  </td>
                                )
                              )
                            : null}

                          {assessmentSubjectComment ? (
                            <td>
                              <div
                                className={
                                  this.props.finalize
                                    ? "tt-disable-comment-box tt-studentReportHeading mt-0"
                                    : "tt-studentReportHeading mt-0"
                                }
                              >
                                {`${localStorage.getItem(
                                  "classroom"
                                )} Teachers Comment`}
                              </div>

                              <div
                                id={"subject" + idx}
                                className={
                                  this.props.finalize
                                    ? "tt-disable-comment-box tt-commentBankEditor"
                                    : "tt-commentBankEditor"
                                }
                              >
                                <JoditEditor
                                  value={student.commentText}
                                  onChange={(e) =>
                                    this.props.handleStudentCommentChange(
                                      e,
                                      idx,
                                      "subject" + idx
                                    )
                                  }
                                  config={config}
                                />
                              </div>
                              <div className="row mt-2">
                                <div className="col-md-6">
                                  <i>{`${assessmentSubjectComment.minLength} to ${assessmentSubjectComment.maxLength} ${assessmentSubjectComment.textType}`}</i>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={(e) => {
                                      this.handleCommentBankClick(e, idx);
                                      this.props.getStudentNameValuePair(
                                        student.people.id
                                      );
                                    }}
                                    disabled={
                                      this.props.finalize ? true : false
                                    }
                                  >
                                    {"Comments Bank"}
                                  </button>
                                </div>
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            assessmentScalesForPractical &&
                            assessmentScalesForPractical.length > 0 &&
                            assessmentScalesForTheory &&
                            assessmentScalesForTheory.length > 0
                              ? 4
                              : 3
                          }
                          align="center"
                        >
                          No students
                        </td>
                      </tr>
                    )
                  ) : null}
                </tbody>
              </table>
              <ModalWindow
                modal={this.state.modal}
                toggleModal={this.handleCommentBankClick}
                fullWidth={true}
                modalBody={
                  <CommentsBank
                    handleInsertComment={this.props.handleInsertComment}
                    handleCommentBankHandler={this.handleCommentBankClick}
                    commentId={this.state.commentIdx}
                  />
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="text-right">
                {this.state.finalizeReportPermission && (
                  <button
                    className="tt-button tt-button-primary mr-2"
                    onClick={this.props.handleSubmit}
                    disabled={
                      students.length > 0
                        ? this.props.finalize
                          ? true
                          : false
                        : true
                    }
                  >
                    Save Assessment Records
                  </button>
                )}

                {this.state.finalizeReportPermission ? (
                  <button
                    className="tt-button tt-button-primary ml-2"
                    onClick={this.props.handleStudentSubjectFinalizeConfirm}
                    disabled={
                      this.props.finalizeClickable &&
                      this.props.finalize === false
                        ? false
                        : true
                    }
                  >
                    Finalize assessment
                  </button>
                ) : null}

                {this.state.unFinalizeReportPermission ? (
                  <button
                    className="tt-button tt-button-primary ml-2"
                    onClick={this.props.handleStudentSubjectUnFinalizeConfirm}
                    disabled={this.props.finalize ? false : true}
                  >
                    Unfinalize assessment
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionToProps = {
  updateStudentList,
};

export default connect(mapStateToProps, mapActionToProps)(StudentSubjectReport);
