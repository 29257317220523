import React, { Component } from "react";
import { Spinner } from "react-mdl";
import { checkRolePermissions } from "../../../utils/Constants";
import Pagination from "../../UI/Pagination";

class ListCourseSubject extends Component {
  state = {
    permissionCanCreateProgramSubject: false,
    permissionCanAddCourse: false,
    permissionCanEditSubject: false,
    permissionCanDeleteSubject: false,
  };
  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionCanCreateProgramSubject: checkRolePermissions(
        "can_create_program_subject",
        "activity"
      ),
      permissionCanAddCourse: checkRolePermissions(
        "can_add_course_to_program_subject",
        "activity"
      ),
      permissionCanEditSubject: checkRolePermissions(
        "can_edit_program_subject",
        "activity"
      ),
      permissionCanDeleteSubject: checkRolePermissions(
        "can_delete_program_subject",
        "activity"
      ),
    });
  };

  render() {
    return (
      <div
        className="card mb-2 p-2 tt-lms"
        style={
          this.state.permissionCanCreateProgramSubject
            ? { height: "40.1vh", overflow: "auto" }
            : { height: "auto" }
        }
      >
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th width="140px">Type</th>
              <th width="35%">Description</th>
              <th>Credit (in hours)</th>
              <th width="145px">Courses / Classes</th>
              {this.state.permissionCanAddCourse ||
              this.state.permissionCanEditSubject ||
              this.state.permissionCanDeleteSubject ? (
                <th width="130px">Options</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {this.props.courseSubjectList.length > 0 ? (
              this.props.courseSubjectList.map((sub, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {sub.isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          name="updateName"
                          value={this.props.updateName}
                          onChange={this.props.handleChange}
                        ></input>
                      ) : (
                        sub.name
                      )}
                    </td>
                    <td>
                      {sub.isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          name="updateCode"
                          value={this.props.updateCode}
                          onChange={this.props.handleChange}
                        ></input>
                      ) : sub.code ? (
                        sub.code
                      ) : (
                        "No code"
                      )}
                    </td>
                    <td>
                      {sub.isEditable ? (
                        <select
                          className="form-control"
                          name="updateType"
                          value={this.props.updateType}
                          onChange={this.props.handleChange}
                        >
                          <option value={true}>Mandatory</option>
                          <option value={false}>Optional</option>
                        </select>
                      ) : sub.isMandatory ? (
                        "Mandatory"
                      ) : (
                        "Optional"
                      )}
                    </td>
                    <td width="35%">
                      {sub.isEditable ? (
                        <textarea
                          name="updateDescription"
                          className="form-control"
                          value={this.props.updateDescription}
                          onChange={this.props.handleChange}
                        ></textarea>
                      ) : (
                        sub.description
                      )}
                    </td>
                    <td>
                      {sub.isEditable ? (
                        <input
                          type="number"
                          className="form-control pr-0"
                          name="updateCredit"
                          value={this.props.updateCredit}
                          onChange={this.props.handleChange}
                          placeholder="in hours"
                        ></input>
                      ) : sub.credit && sub.credit !== "" ? (
                        sub.credit
                      ) : (
                        "No credit added"
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth"
                        onClick={() => this.props.handleTopicDetailModal(sub)}
                      >
                        Course Details
                      </button>
                      <button
                        className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                        onClick={() => this.props.handleClassModal(sub)}
                      >
                        Classes
                      </button>
                    </td>
                    {this.state.permissionCanAddCourse ||
                    this.state.permissionCanEditSubject ||
                    this.state.permissionCanDeleteSubject ? (
                      <td className="text-center">
                        {sub.isEditable ? (
                          <>
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={this.props.updateSubject}
                            >
                              Save
                            </button>
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                              onClick={() => this.props.cancelUpdate(idx)}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            {this.state.permissionCanAddCourse ? (
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth"
                                onClick={() => this.props.handleTopicModal(sub)}
                              >
                                Add Courses
                              </button>
                            ) : null}
                            {this.state.permissionCanEditSubject ? (
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                                onClick={() =>
                                  this.props.toggleCourseSubjectEditModal(idx)
                                }
                              >
                                Edit
                              </button>
                            ) : null}
                            {this.state.permissionCanDeleteSubject ? (
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                                onClick={() => this.props.handleDelete(sub.id)}
                              >
                                Delete
                              </button>
                            ) : null}
                          </>
                        )}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="text-center"
                  colSpan={
                    this.state.permissionCanAddCourse ||
                    this.state.permissionCanEditSubject ||
                    this.state.permissionCanDeleteSubject
                      ? 6
                      : 5
                  }
                >
                  {this.props.courseSubjectSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading Data ...
                    </>
                  ) : (
                    "No data"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemsCount={this.props.courseSubjectTotalRecords}
            pageSize={this.props.courseSubjectRowCountLimit}
            currentPage={this.props.courseSubjectCurrentPage}
            onPageChange={this.props.handlePageChange}
            nextPage={this.props.handleNextPage}
            previousPage={this.props.handlePreviousPage}
          ></Pagination>
        </div>
      </div>
    );
  }
}

export default ListCourseSubject;
