import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import SubjectTopicSetting from "../LearningManagementSystem/SubjectSetting/SubjectTopicSetting";
import ModalWindow from "../UI/ModalWindow";

export default class CreateCourseSyllabus extends Component {
  state = {
    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: "",

    classrooms: [],
    selectedClassrooms: "",

    subjects: [],
    selectedSubjects: "",

    addCourseModal: "",
  };

  componentDidMount = () => {
    this.getEducationLevels();
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
            selectedEducationLevelId: "",
          });
      }
    });
  };

  handleEducationLevelChange = (e) => {
    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          selectedYearLevel: "",

          classrooms: [],
          selectedClassrooms: "",

          subjects: [],
          selectedSubjects: "",
          selectedEducationLevelId: e.target.value,
          classroomId: "",
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState({ selectedYearLevel: e.target.value });
    var data = {
      yearLevelId: e.target.value,
    };

    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.data.status === 200) {
        const datas = response.data.data;
        if (datas.length > 0) {
          this.setState({
            classrooms: datas,
            selectedClassrooms: "",
            subjects: [],
            selectedSubjects: "",
            classroomId: "",
          });
        }
      }
    });
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value });
    var data = {
      classroomId: e.target.value,
    };

    axiosPost(URL.getSubjectByClassroom, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            subjects: data,
            selectedSubjects: "",
          });
      }
    });
  };

  handleSubjects = (e) => {
    this.setState({
      selectedSubjects: e.target.value,
    });
  };

  handleAddCourse = (subject) => {
    this.setState({
      // selectedSubject: subject,
      addCourseModal: !this.state.addCourseModal,
    });
  };

  toggleAddCourseModal = () => {
    this.setState({ addCourseModal: !this.state.addCourseModal });
  };

  resetState = () => {
    this.setState({
      selectedEducationLevelId: "",

      yearLevels: [],
      selectedYearLevel: "",

      classrooms: [],
      selectedClassrooms: "",

      subjects: [],
      selectedSubjects: "",
    });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">Create Course Plan</div>
          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-3 col-md">
                  <strong>
                    Education Level
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>

                  <select
                    className="form-control"
                    name="educationLevel"
                    value={this.state.selectedEducationLevelId}
                    onChange={this.handleEducationLevelChange}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select education level
                    </option>
                    {this.state.educationLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>
                    Class
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>

                  <select
                    className="form-control"
                    name="yearLevel"
                    value={this.state.selectedYearLevel}
                    onChange={this.handleYearLevel}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select class
                    </option>
                    {this.state.yearLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>
                    Section{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                  <select
                    className="form-control"
                    name="sectionId"
                    value={this.state.selectedClassrooms}
                    onChange={this.handleSectionChange}
                  >
                    <option value="" selected disabled>
                      Select section
                    </option>
                    {this.state.classrooms.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>
                    Subject{" "}
                    <span className="tt-assessment-module-mandatory">*</span>{" "}
                  </strong>
                  <select
                    className="form-control"
                    name="subjectId"
                    value={this.state.selectedSubjects}
                    onChange={this.handleSubjects}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select subject
                    </option>
                    {this.state.subjects.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md-2">
                  <button
                    className="mt-4 mx-auto d-block tt-button tt-button-primary"
                    onClick={() => this.handleAddCourse("subject")}
                    disabled={this.state.selectedSubjects === "" ? true : false}
                  >
                    Add Course
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state.addCourseModal}
          toggleModal={this.toggleAddCourseModal}
          size="xl"
          modalHeader="Add New Course Plan for Subject"
          modalBody={
            <SubjectTopicSetting
              selectedEducationLevelId={this.state.selectedEducationLevelId}
              selectedYearLevel={this.state.selectedYearLevel}
              selectedClassrooms={this.state.selectedClassrooms}
              selectedSubjects={this.state.selectedSubjects}
              academicRelated={true}
              toggleAddCourseModal={this.toggleAddCourseModal}
              resetState={this.resetState}
            ></SubjectTopicSetting>
          }
        ></ModalWindow>
      </>
    );
  }
}
