/**
 * @author Azzeta Karmacharya
 *
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getYearLevels,
  getEducationLevelsAcademicAdmin as getEducationLevels,
  getUnAssignedYearLevels,
  getGroups,
} from "../../../../ducks/AcademicAdministrationDucks";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  displayErrorMessage,
  displaySuccessAlert,
} from "../../../../utils/Utils";
import {
  URL,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import swal from "sweetalert";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import NoteDisplay from "../../../../utils/noteDisplay";
import { Spinner } from "reactstrap";

class CreateYearLevel extends Component {
  state = {
    regEx: /^[0-9a-zA-Z!.;':/" ]*$/,
    name: localStorage.getItem("yearLevel"),
    description: "",
    eduLevel: "",
    isAvailable: false,
    showSpinner: false,
    render: false,
    nameError: false,
    eduLevelError: false,
  };

  componentDidMount() {
    this.props.getEducationLevels();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.educationLevels !== prevProps.educationLevels) {
      this.setState(
        { educationLevels: this.props.educationLevels },
        function () {
          this.checkAvailability();
        }
      );
    }
  }

  checkAvailability = () => {
    if (this.state.educationLevels.length > 0) {
      this.setState({ render: true, isAvailable: true });
    } else {
      this.setState({ render: true });
    }
  };

  handleDataChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    name === "name" && name.length > 0 && this.setState({ nameError: false });
    name === "eduLevel" &&
      name.length > 0 &&
      this.setState({ eduLevelError: false });

    if (name === "name") {
      if (!value.match(this.state.regEx)) {
        let errorCharacter = value.slice(-1);
        swal({
          title: "Warning",
          text: `${errorCharacter} not allowed`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = (e, params) => {
    e.preventDefault();
    if (this.state.name === "" || this.state.eduLevel === "") {
      swal({
        title: "Error",
        text: "Fill all the necessary fields.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      this.setState({
        nameError: this.state.name === "" ? true : false,
        eduLevelError: this.state.eduLevel === "" ? true : false,
      });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertYearLevel,
          params,
          (response) => {
            if (response.status === 200) {
              displaySuccessAlert(response, this.props.getYearLevels);
              this.props.getUnAssignedYearLevels();
            }
            this.setState({ showSpinner: false });
            this.props.getGroups(this.props.profilePeopleId);
            this.resetForm();
          },
          (error) => {
            this.setState({ showSpinner: false });
            displayErrorMessage(error);
          }
        );
      });
    }
  };

  resetForm = () => {
    this.setState({
      name: localStorage.getItem("yearLevel"),
      description: "",
      eduLevel: "",
    });
  };
  getParameters = () => {
    return {
      name: this.state.name,
      description: this.state.description,
      educationLevelId: this.state.eduLevel,
    };
  };

  render() {
    return this.state.render ? (
      <>
        {this.state.isAvailable ? (
          <>
            <div
              className="tt-widgetContent-tab-holder"
              style={{ height: "79.5vh" }}
            >
              <div className="tt-group-header">
                Create {localStorage.getItem("yearLevel")}
                {checkWidgetAdminOrNot("Academic Administration") === true ? (
                  <button
                    className="tt-button tt-button-primary float-right permissionBtnCSS"
                    onClick={() => {
                      this.setState({
                        permissionModal: !this.state.permissionModal,
                      });
                    }}
                  >
                    Permissions
                  </button>
                ) : null}
              </div>

              <div className="container">
                <form
                  onSubmit={(e) => this.handleSubmit(e, this.getParameters())}
                >
                  <div className="form-group row">
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                      <label htmlFor="name">
                        <strong>
                          {localStorage.getItem("yearLevel")} name
                        </strong>
                      </label>
                      <span className="tt-assessment-module-mandatory">*</span>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className={
                          this.state.nameError
                            ? "form-control indicate-error"
                            : "form-control"
                        }
                        name="name"
                        // placeholder="Enter Class  Name"
                        value={this.state.name}
                        onChange={this.handleDataChange}
                        maxLength="255"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                      <label htmlFor="name">
                        <strong>Education Level</strong>
                      </label>
                      <span className="tt-assessment-module-mandatory">*</span>
                    </div>
                    <div className="col-md-4">
                      <select
                        name="eduLevel"
                        onChange={this.handleDataChange}
                        value={this.state.eduLevel}
                        id="eduLevel"
                        className={
                          this.state.eduLevelError
                            ? "form-control indicate-error"
                            : "form-control"
                        }
                      >
                        <option value="" disabled>
                          Choose Options...
                        </option>
                        {this.state.educationLevels
                          ? this.state.educationLevels.map((el, idx) => {
                              return (
                                <option value={el.id} key={idx}>
                                  {el.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                      <label htmlFor="name">
                        <strong className="optional">Description</strong>
                      </label>
                    </div>
                    <div className="col-md-4">
                      <textarea
                        type="text"
                        className="form-control"
                        name="description"
                        placeholder="Enter Description"
                        value={this.state.description}
                        onChange={this.handleDataChange}
                        maxLength="1000"
                      />
                    </div>
                  </div>
                  <div className="row button-bg-color">
                    <div className="col-md-2"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-4 text-right">
                      <button
                        type="submit"
                        className="tt-button tt-button-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ModalWindow
              modal={this.state.permissionModal}
              size="lg"
              id="tt-permissionModal"
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              modalBody={
                <GranularPermissionModal
                  widgetName="Academic Administration"
                  moduleName="School Groupings"
                  header="Add Year Level"
                  activityName="insert-grade"
                />
              }
            ></ModalWindow>
            {this.state.showSpinner ? (
              <div className="fullWindow-Spinner">
                <div>
                  <Spinner color="white"></Spinner>
                </div>
                <div style={{ fontSize: "16px", marginTop: "15px" }}>
                  Please Wait.Creating{" "}
                  {localStorage.getItem("yearLevel")
                    ? localStorage.getItem("yearLevel")
                    : "Grade"}{" "}
                  ...
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <NoteDisplay
              item={
                localStorage.getItem("yearLevel")
                  ? localStorage.getItem("yearLevel")
                  : "Grade"
              }
              dependentItem="educationLevels"
            ></NoteDisplay>
          </div>
        )}
      </>
    ) : (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      ></div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  //yearLevels: state.academicAdministration.yearLevels
  educationLevels: state.academicAdministration.educationLevels,
});

const mapActionsToProps = {
  getYearLevels: getYearLevels,
  getUnAssignedYearLevels,
  getEducationLevels,
  getGroups,
};

export default connect(mapStateToProps, mapActionsToProps)(CreateYearLevel);
