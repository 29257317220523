import React, { Component } from "react";
import { Spinner } from "reactstrap";
import Pagination from "../UI/Pagination";

class ForgetPasswordRequestUser extends Component {
  state = {};
  render() {
    let userList = this.props.forgetPasswordRequestUsers;
    return (
      <div className="p-2">
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>People Name</th>
              <th>Username</th>
              <th>User Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userList.length > 0 ? (
              userList.map((user, idx) => {
                return (
                  <tr key={idx}>
                    <td>{user.peopleName}</td>
                    <td>{user.username}</td>
                    <td>{user.userRole}</td>
                    <td>
                      {user.status.charAt(0).toUpperCase() +
                        user.status.slice(1)}
                    </td>
                    <td className="text-center">
                      <button
                        className="tt-button tt-button-primary mr-3"
                        onClick={() =>
                          this.props.toggleForgotModal(user.username, user.id)
                        }
                      >
                        Update Password
                      </button>
                      {user.status !== "rejected" ? (
                        <button
                          className="tt-button tt-button-primary"
                          onClick={() =>
                            this.props.rejectPassword(user.username, user.id)
                          }
                        >
                          Reject
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  {this.props.displayRequestSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading data...
                    </>
                  ) : (
                    "No users"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemsCount={this.props.requestTotalRecords}
            pageSize={this.props.requestRowCountLimit}
            currentPage={this.props.requestCurrentPage}
            onPageChange={this.props.requestHandlePageChange}
            nextPage={this.props.requestHandleNextPage}
            previousPage={this.props.requestHandlePreviousPage}
          ></Pagination>
        </div>
      </div>
    );
  }
}

export default ForgetPasswordRequestUser;
