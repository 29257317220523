import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  checkWidgetAdminOrNot,
  checkRolePermissions,
} from "../../../../utils/Constants";
import { Spinner } from "reactstrap";
import StudentClassroomOptionalSubjectTableRow from "./StudentClassroomOptionalSubjectTableRow";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import {
  displayConfirmDeleteAlert,
  displaySuccessAlert,
  showErroMessage,
} from "../../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "reactstrap";

class StudentClassroomOptionalSubjectList extends Component {
  state = {
    studentOptionalSubjectList: [],
    listSpinner: false,
    educationLevelDivisions: [],
    educationLevelDivision: "",
    educationLevel: "",
    educationLevels: [],
    yearLevels: [],
    selectedYearLevel: "",
    selectedClassroom: "",
    permissionEditOptionalSubject: false,
    permissionDeleteOptionalSubject: false,
  };
  componentDidMount() {
    // this.getStudentClassroomOptionalSubjects(this.props.code);
    this.getEducationLevelByAssessmentModule();
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditOptionalSubject: checkRolePermissions(
        "edit-optional-subject",
        "activity",
        this.props.name
      ),
      permissionDeleteOptionalSubject: checkRolePermissions(
        "delete-optional-subject",
        "activity",
        this.props.name
      ),
    });
  };

  getEducationLevelByAssessmentModule = () => {
    let data = {
      code: this.props.code,
    };
    axiosPost(URL.getEducationLevelByAssessmentModule, data, (response) => {
      if (response.status === 200) {
        this.setState({ educationLevels: response.data.data }, () => {
          if (this.state.educationLevels.length === 1) {
            this.setState(
              { educationLevel: this.state.educationLevels[0].id },
              function () {
                this.getEducationLevelDivisions();
              }
            );
          }
        });
      }
    });
  };

  getEducationLevelDivisions = () => {
    const param = {
      educationLevelId: this.state.educationLevel,
    };
    axiosPost(URL.getEducationLevelDivisions, param, (response) => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          this.setState(
            {
              educationLevelDivisions: response.data.data,
              educationLevelDivision: response.data.data[0].id,
            },
            function () {
              this.getYearLevelByEducationLevel();
            }
          );
        }
      }
    });
  };

  handleDelete = (e, obj) => {
    displayConfirmDeleteAlert(obj, this.handleRemove);
  };

  universalHandleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, function () {
      this.setState({ yearLevels: [], yearLevel: null }, () => {
        this.getYearLevelByEducationLevel();
      });
    });
  };

  getYearLevelByEducationLevel = () => {
    let data = {
      educationLevelId: this.state.educationLevel,
      assessmentModuleCode: this.props.code,
      educationLevelDivisionId: this.state.educationLevelDivision,
    };
    axiosPost(URL.getYearLevelByEducationLevel, data, (response) => {
      if (response.status === 200) {
        let yearLevels = response.data.data;
        if (yearLevels.length > 0) {
          yearLevels.forEach((el, idx) => {
            if (idx === 0) {
              el.isOpen = true;
            } else {
              el.isOpen = false;
            }
            if (el.classrooms && el.classrooms.length > 0) {
              el.classrooms.forEach((c, cIdx) => {
                c.isOpen = false;
              });
            }
          });
        }
        this.setState({ yearLevels });
      }
    });
  };

  handleRemove = (obj) => {
    let param = {
      admissionId: obj.admissionId,
      classroomId: obj.classroomId,
      yearLevelId: obj.yearLevelId,
      subjectId: obj.subjectId,
      educationLevelId: obj.educationLevelId,
      educationLevelDivisionId: obj.educationLevelDivisionId,
    };
    axiosPost(URL.deleteStudentClassroomOptionalSubject, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.getStudentClassroomOptionalSubjects();
      } else {
        showErroMessage(response);
      }
    });
  };

  tableRow() {
    return this.state.studentOptionalSubjectList.length > 0 ? (
      this.state.studentOptionalSubjectList.map((subject, idx) => {
        return (
          <StudentClassroomOptionalSubjectTableRow
            subject={subject}
            idx={idx}
            handleDelete={this.handleDelete}
            name={this.props.name}
            permissionEditOptionalSubject={
              this.state.permissionEditOptionalSubject
            }
            permissionDeleteOptionalSubject={
              this.state.permissionDeleteOptionalSubject
            }
          />
        );
      })
    ) : (
      <tr>
        {this.state.listSpinner ? (
          <td colSpan={3} className="text-center">
            <Spinner color="primary"></Spinner>
            <br></br>Loading Data...
          </td>
        ) : (
          <td colSpan={3} className="text-center">
            No records found.
          </td>
        )}
      </tr>
    );
  }

  getStudentClassroomOptionalSubjects = () => {
    this.setState(
      { listSpinner: true, studentOptionalSubjectList: [] },
      function () {
        let param = {
          assessmentModuleCode: this.props.code,
          yearLevelId: this.state.selectedYearLevel,
          classroomId: this.state.selectedClassroom,
          educationLevelId: this.state.educationLevel,
          educationLevelDivisionId: this.state.educationLevelDivision,
        };
        axiosPost(
          URL.selectStudentClassroomOptionalSubject,
          param,
          (response) => {
            if (response.status === 200) {
              this.setState({
                studentOptionalSubjectList: response.data.data,
                listSpinner: false,
              });
            }
          },
          (error) => {
            this.setState({ listSpinner: false });
          }
        );
      }
    );
  };

  handleYearLevelCollapsible = (idx) => {
    let yearLevels = [...this.state.yearLevels];
    if (yearLevels[idx].isOpen) {
      yearLevels.forEach((el) => {
        el.isOpen = false;
      });
      this.setState({ yearLevels, selectedYearLevel: "" });
    } else {
      yearLevels.forEach((el) => {
        el.isOpen = false;
      });
      yearLevels[idx].isOpen = true;
      this.setState({ yearLevels, selectedYearLevel: yearLevels[idx].id });
    }
  };

  handleClassroomCollapsible = (idx, cIdx) => {
    let yearLevels = [...this.state.yearLevels];
    let classrooms = yearLevels[idx].classrooms;
    if (classrooms[cIdx].isOpen) {
      classrooms.forEach((el) => {
        el.isOpen = false;
      });
      this.setState({ yearLevels, selectedClassroom: "" });
    } else {
      classrooms.forEach((el) => {
        el.isOpen = false;
      });
      classrooms[cIdx].isOpen = true;
      this.setState(
        { yearLevels, selectedClassroom: classrooms[cIdx].id },
        function () {
          this.getStudentClassroomOptionalSubjects();
        }
      );
    }
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          List Student Optional Subjects
          {checkWidgetAdminOrNot(this.props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="tt-newListBox container-fluid">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3 mt-1">
              <label>
                <strong>
                  Select {localStorage.getItem("educationLevelDivision")} :
                </strong>
                <span className="tt-assessment-module-mandatory">*</span>
              </label>
            </div>
            <div className="col-md-3">
              <select
                className="form-control"
                onChange={this.universalHandleChange}
                name="educationLevelDivision"
                value={this.state.educationLevelDivision}
              >
                <option disabled value="">
                  Select {localStorage.getItem("educationLevelDivision")}
                </option>
                {this.state.educationLevelDivisions
                  ? this.state.educationLevelDivisions.map(
                      (educationLevelDivision, idx) => (
                        <option key={idx} value={educationLevelDivision.id}>
                          {educationLevelDivision.name}
                        </option>
                      )
                    )
                  : null}
              </select>
            </div>
            <div className="col-md-3"></div>
          </div>
          <hr style={{ marginTop: "5px", marginBottom: "10px" }}></hr>
          <div className="row">
            <div className="col text-center">
              {this.state.yearLevels.length > 0 ? (
                this.state.yearLevels.map((ylvl, idx) => {
                  return (
                    <div className="card mb-2 text-left" key={idx}>
                      <div
                        className="card-header tt-group-header mb-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.handleYearLevelCollapsible(idx);
                        }}
                      >
                        <div className="row">
                          <div className="col">{ylvl.name}</div>
                          <div className="col-md-1 text-center">
                            <FontAwesomeIcon
                              icon={ylvl.isOpen ? faAngleUp : faAngleDown}
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      </div>

                      <Collapse isOpen={ylvl.isOpen}>
                        <div className="p-3">
                          {ylvl.classrooms && ylvl.classrooms.length > 0 ? (
                            ylvl.classrooms.map((c, cIdx) => {
                              return (
                                <div className="card mb-2 text-left" key={cIdx}>
                                  <div
                                    className="card-header tt-group-header mb-0"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.handleClassroomCollapsible(
                                        idx,
                                        cIdx
                                      );
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col">
                                        {/* {localStorage.getItem("classroom")}{" "} */}
                                        {c.name}
                                      </div>
                                      <div className="col-md-1 text-center">
                                        <FontAwesomeIcon
                                          icon={
                                            c.isOpen ? faAngleUp : faAngleDown
                                          }
                                        ></FontAwesomeIcon>
                                      </div>
                                    </div>
                                  </div>
                                  {c.isOpen ? (
                                    <Collapse isOpen={c.isOpen}>
                                      <div className="p-2">
                                        <div className="row">
                                          <div className="col-md-12 tt-listSubjectAreas">
                                            <table className="table table-bordered text-center table-striped mb-0">
                                              <thead className="tt-group-header">
                                                <tr>
                                                  <th>Student</th>
                                                  <th>Subject</th>
                                                  {this.state
                                                    .permissionEditOptionalSubject ||
                                                  this.state
                                                    .permissionDeleteOptionalSubject ? (
                                                    <th>Options</th>
                                                  ) : null}
                                                </tr>
                                              </thead>
                                              <tbody>{this.tableRow()}</tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  ) : null}
                                </div>
                              );
                            })
                          ) : (
                            <div>No {localStorage.getItem("classroom")}</div>
                          )}
                        </div>
                      </Collapse>
                    </div>
                  );
                })
              ) : (
                <div>No {localStorage.getItem("yearLevel")}</div>
              )}
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Subjects Setup"
              header="List Student Optional Subjects"
              activityName="select-student-optional-subjects"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default StudentClassroomOptionalSubjectList;
