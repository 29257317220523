import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { axiosPost } from "../../../utils/AxiosApi";
import { checkValueExitsInArray } from "../../../utils/Utils";
import {
  URL,
  dbTable,
  recruitmentStatus,
  draggable,
  checkWidgetAdminOrNot,
  scaleTypeCode,
  marksBreakdownTypeCode,
} from "../../../utils/Constants";
import ReportingConfigurationPageSetup from "./ReportingConfigurationPageSetup";
import ReportingConfigurationPageContent from "./ReportingConfigurationPageContent";
import ReportingConfigurationPageMiscellaneous from "./ReportingConfigurationPageMiscellaneous";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import ModalWindow from "../../UI/ModalWindow";
import ReportingConfigurationSubjectSetting from "./ReportingConfigurationSubjectSetting";
import ReportingConfigurationCustomColumnCalculation from "./ReportingConfigurationCustomColumnCalculation";
import "../../../assets/scss/reportFormat.scss";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";

import moment from "moment";
import { connect } from "react-redux";
import { getReportFormatList } from "../../../ducks/PrimaryARRDucks";
import NoteDisplay from "../../../utils/noteDisplay";
import ReportingConfigurationDescriptionBox from "./ReportingConfigurationDescriptionBox";

class ReportingConfiguration extends Component {
  state = {
    // Page 1
    activeColumn: "1",
    activeColumnHeader: 0,
    activeDescriptionColumn: 0,
    activeReportPageIndex: 0,
    activeSubjectSettingBdIndex: 0,
    assessmentGeneralCustomAssessments: {
      generalCustomAssessmentOveral: [],
      generalCustomAssessmentRubric: [],
      generalCustomAssessmentOveralAndRubric: [],
    },
    assessmentGeneralCustomAssessmentsDefault: null,
    assessmentId: null,
    assessments: [],
    assessmentSubjectScaleConfigGroups: [],
    assignEducationlevel: true,
    comment: [],
    commentsChip: [],
    contentAlignments: [],
    contentFontSizes: [],
    currentSettingAssessmentScaleId: null,
    currentSettingAssessmentTypeCode: "",
    currentSettingGroupId: null,
    currentSubjectAssessmentScaleForSetting: "",
    customColumnCalculationModal: false,
    customText: "",
    descriptionBox: {},
    descriptionCustomText: "",
    educationLevelId: null,
    footer: {},
    footerHeight: 15,
    groupedYearLevels: [],
    header: {},
    headerCustomText: "",
    headerFooter: "header",
    headerHeight: 30,
    id: "",
    isAvailable: false,
    legends: null,
    margin: {
      top: 10,
      left: 10,
      right: 10,
      bottom: 10,
    },
    marginSizeUnit: "mm",
    orientation: "portrait",
    pageSize: {
      height: 297,
      width: 210,
    },
    pageSizeUnit: "mm",
    paperSize: "a4",
    previousAssessments: [],
    publisher: "",
    publishingDate: null,
    recruitmentStatusId: "",
    reportName: null,
    reportPages: [],
    reportSetupPageColumn2VerticalAlign: "middle",
    reportSetupPageColumn1VerticalAlign: "middle",
    reportSetupSubjectSettingBds: [],
    render: false,
    sampleValueAverage: 0,
    sampleValuePercentage: 0,
    sampleValueSummation: 0,
    school: {},
    sctMarksSubjectSettingModal: false,
    selectedMultipleYearLevels: [],
    selectedReportSetupBdsIndex: 0,
    selectedYearLevel: "",
    showPresetGpaLegend: true,
    staffList: [],
    subjectAssessmentScales: [],
    subjectAssessmentScaleSettingOptions: [],
    // Tab Index
    tabIndex: 0,
    yearLevelIds: [], //selected year level ids
    yearLevels: [],
    zoom: 36,
    assessmentScales: null,
    isSubjectSetupBdsaved: false,
    defaultSubjectScaleConfigGroups: null,
  };

  addAssessmentChipAgain = (contents, contentIndex) => {
    let assessmentGeneralCustomAssessments =
        this.state.assessmentGeneralCustomAssessments,
      legends = this.state.legends,
      commentsChip = this.state.commentsChip,
      assessmentSubjectScaleConfigGroups =
        this.state.assessmentSubjectScaleConfigGroups;
    let content = contents[contentIndex];
    if (!content.updatedData) {
      if (
        content.customAssessmentHeaderName.includes("GENERAL OVERALL ONLY:")
      ) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace(
            "GENERAL OVERALL ONLY: ",
            ""
          );
        assessmentGeneralCustomAssessments.generalCustomAssessmentOveral.push(
          content
        );
      }
      if (content.customAssessmentHeaderName.includes("GENERAL RUBRIC ONLY:")) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace(
            "GENERAL RUBRIC ONLY: ",
            ""
          );
        assessmentGeneralCustomAssessments.generalCustomAssessmentRubric.push(
          content
        );
      }
      if (
        content.customAssessmentHeaderName.includes(
          "GENERAL OVERALL AND RUBRIC:"
        )
      ) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace(
            "GENERAL OVERALL AND RUBRIC: ",
            ""
          );
        assessmentGeneralCustomAssessments.generalCustomAssessmentOveralAndRubric.push(
          content
        );
      }
      if (content.customAssessmentHeaderName.includes("SUBJECTS:")) {
        let subjectAssessmentScaleSettingOptions = [
          ...this.state.subjectAssessmentScaleSettingOptions,
        ];
        let data = [];
        subjectAssessmentScaleSettingOptions.forEach((el) => {
          if (el.groupId !== content.id) {
            data.push(el);
          }
        });
        this.setState({ subjectAssessmentScaleSettingOptions: data });
        assessmentSubjectScaleConfigGroups.push(content);
      }
      // if (content.customAssessmentHeaderName.includes("LEGENDGP:")) {
      //   this.setState({ showPresetGpaLegend: true });
      // }
      if (content.customAssessmentHeaderName.includes("LEGEND:")) {
        if (content.gpTable) {
          this.setState({ showPresetGpaLegend: true });
        } else {
          content.customAssessmentHeaderName =
            content.customAssessmentHeaderName.replace("LEGEND: ", "");
          legends.push(content);
        }
      }

      if (content.customAssessmentHeaderName.includes("GENERAL:")) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace("GENERAL: ", "");
        commentsChip.push(content);
      }
      this.setState({
        assessmentGeneralCustomAssessments,
        legends,
        assessmentSubjectScaleConfigGroups,
      });
    }
    if (content.updatedData) {
      if (content.customAssessmentHeaderName.includes("SUBJECTS:")) {
        let subjectAssessmentScaleSettingOptions = [
          ...this.state.subjectAssessmentScaleSettingOptions,
        ];
        let data = [];
        subjectAssessmentScaleSettingOptions.forEach((el) => {
          if (el.groupId !== content.id) {
            data.push(el);
          }
        });
        this.setState({ subjectAssessmentScaleSettingOptions: data });
        content.scales = content.customAssessmentHeaderName.replace(
          "SUBJECTS: ",
          ""
        );
        assessmentSubjectScaleConfigGroups.push(content);
      }

      if (
        content.customAssessmentHeaderName.includes("GENERAL OVERALL ONLY:")
      ) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace(
            "GENERAL OVERALL ONLY: ",
            ""
          );
        assessmentGeneralCustomAssessments.generalCustomAssessmentOveral.push(
          content
        );
      }
      if (content.customAssessmentHeaderName.includes("GENERAL RUBRIC ONLY:")) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace(
            "GENERAL RUBRIC ONLY: ",
            ""
          );
        assessmentGeneralCustomAssessments.generalCustomAssessmentRubric.push(
          content
        );
      }
      if (
        content.customAssessmentHeaderName.includes(
          "GENERAL OVERALL AND RUBRIC:"
        )
      ) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace(
            "GENERAL OVERALL AND RUBRIC: ",
            ""
          );
        assessmentGeneralCustomAssessments.generalCustomAssessmentOveralAndRubric.push(
          content
        );
      }
      if (content.customAssessmentHeaderName.includes("LEGEND:")) {
        if (content.gpTable) {
          this.setState({ showPresetGpaLegend: true });
        } else {
          content.customAssessmentHeaderName =
            content.customAssessmentHeaderName.replace("LEGEND: ", "");
          content.scaleName = content.customAssessmentHeaderName;
          legends.push(content);
        }
      }
      if (content.customAssessmentHeaderName.includes("GENERAL:")) {
        content.customAssessmentHeaderName =
          content.customAssessmentHeaderName.replace("GENERAL: ", "");
        commentsChip.push(content);
      }
      this.setState({
        assessmentGeneralCustomAssessments,
        legends,
        assessmentSubjectScaleConfigGroups,
      });
    }
  };

  addFromOriginalArray = (content, idx) => {
    let reportSetupPageColumnContents = [];
    let tempArray = [];
    let attribute = "";
    if (content.tableName == dbTable.assessmentGeneralCommentConfiguration) {
      tempArray = [...this.state.comments];
      attribute = "commentName";
    } else if (
      content.tableName == dbTable.generalAssessmentOverallConfiguration
    ) {
      let assessmentGeneralCustomAssessmentsDefault =
        this.state.assessmentGeneralCustomAssessmentsDefault;

      if (assessmentGeneralCustomAssessmentsDefault) {
        let generalCustomAssessmentOveral =
          assessmentGeneralCustomAssessmentsDefault.generalCustomAssessmentOveral;
        let generalCustomAssessmentOveralAndRubric =
          assessmentGeneralCustomAssessmentsDefault.generalCustomAssessmentOveralAndRubric;
        tempArray = [
          ...generalCustomAssessmentOveral,
          ...generalCustomAssessmentOveralAndRubric,
        ];
        attribute = "customAssessmentHeaderName";
      }
    } else if (
      content.tableName == dbTable.generalAssessmentRubricsConfiguration
    ) {
      let assessmentGeneralCustomAssessmentsDefault =
        this.state.assessmentGeneralCustomAssessmentsDefault;
      if (assessmentGeneralCustomAssessmentsDefault) {
        let generalCustomAssessmentRubric =
          assessmentGeneralCustomAssessmentsDefault.generalCustomAssessmentRubric;
        tempArray = [...generalCustomAssessmentRubric];
        attribute = "customAssessmentHeaderName";
      }
    }
    if (content["updatedData"]) {
      let newContent = {
        uiOrder: idx + 1,
        tableName: content.tableName,
        fieldName: content.fieldName,
        tableUnId: content.tableUnId,
        customRecord: content.customRecord,
        horizontalAlign:
          content.horizontalAlign && content.horizontalAlign.length > 0
            ? content.horizontalAlign
            : "center",
        fontSize: content.fontSize ? content.fontSize : 10,
        customRecord:
          content.customRecord !== null ? content.customRecord : false,
        customText: content.customText,
      };
      reportSetupPageColumnContents.push(newContent);
    } else {
      tempArray.forEach((tempData) => {
        if (tempData[attribute] == content[attribute]) {
          let columnContent = {
            uiOrder: idx + 1,
            tableName: content.tableName,
            fieldName: content.fieldName,
            tableUnId: tempData.id,
            customRecord: content.customRecord,
            horizontalAlign:
              content.horizontalAlign && content.horizontalAlign.length > 0
                ? content.horizontalAlign
                : "center",
            fontSize: content.fontSize ? content.fontSize : 10,
            customRecord:
              content.customRecord !== null ? content.customRecord : false,
            customText: content.customText,
          };
          reportSetupPageColumnContents.push(columnContent);
        }
      });
    }

    return reportSetupPageColumnContents;
  };

  changePageSizeUnit = (name, value) => {
    const pageSize = { ...this.state.pageSize };
    const margin = { ...this.state.margin };
    let headerHeight = this.state.headerHeight;
    let footerHeight = this.state.footerHeight;
    let reportPages = [...this.state.reportPages];
    let header = { ...this.state.header };
    let footer = { ...this.state.footer };
    let descriptionBox = { ...this.state.descriptionBox };
    if (value === "in") {
      pageSize["width"] = pageSize["width"] / 25.4;
      pageSize["height"] = pageSize["height"] / 25.4;
      margin.top = margin.top / 25.4;
      margin.bottom = margin.bottom / 25.4;
      margin.left = margin.left / 25.4;
      margin.right = margin.right / 25.4;
      headerHeight = headerHeight / 25.4;
      footerHeight = footerHeight / 25.4;
      header["marginTop"] = header["marginTop"] / 25.4;
      header["marginBottom"] = header["marginBottom"] / 25.4;
      header["marginLeft"] = header["marginLeft"] / 25.4;
      header["marginRight"] = header["marginRight"] / 25.4;
      header["height"] = header["height"] / 25.4;
      footer["marginTop"] = footer["marginTop"] / 25.4;
      footer["marginBottom"] = footer["marginBottom"] / 25.4;
      footer["marginLeft"] = footer["marginLeft"] / 25.4;
      footer["marginRight"] = footer["marginRight"] / 25.4;
      footer["height"] = footer["height"] / 25.4;
      descriptionBox["marginTop"] = descriptionBox["marginTop"] / 25.4;
      descriptionBox["marginBottom"] = descriptionBox["marginBottom"] / 25.4;
      descriptionBox["marginLeft"] = descriptionBox["marginLeft"] / 25.4;
      descriptionBox["marginRight"] = descriptionBox["marginRight"] / 25.4;
      descriptionBox["height"] = descriptionBox["height"] / 25.4;

      reportPages.forEach((el) => {
        el.margin.top = el.margin.top / 25.4;
        el.margin.bottom = el.margin.bottom / 25.4;
        el.margin.left = el.margin.left / 25.4;
        el.margin.right = el.margin.right / 25.4;
        el.pageSize.width = el.pageSize.width / 25.4;
        el.pageSize.height = el.pageSize.height / 25.4;
      });
    }
    if (value === "mm") {
      pageSize["width"] = pageSize["width"] * 25.4;
      pageSize["height"] = pageSize["height"] * 25.4;
      margin.top = margin.top * 25.4;
      margin.bottom = margin.bottom * 25.4;
      margin.left = margin.left * 25.4;
      margin.right = margin.right * 25.4;
      headerHeight = headerHeight * 25.4;
      footerHeight = footerHeight * 25.4;
      header["marginTop"] = header["marginTop"] * 25.4;
      header["marginBottom"] = header["marginBottom"] * 25.4;
      header["marginLeft"] = header["marginLeft"] * 25.4;
      header["marginRight"] = header["marginRight"] * 25.4;
      header["height"] = header["height"] * 25.4;
      footer["marginTop"] = footer["marginTop"] * 25.4;
      footer["marginBottom"] = footer["marginBottom"] * 25.4;
      footer["marginLeft"] = footer["marginLeft"] * 25.4;
      footer["marginRight"] = footer["marginRight"] * 25.4;
      footer["height"] = footer["height"] * 25.4;
      descriptionBox["marginTop"] = descriptionBox["marginTop"] * 25.4;
      descriptionBox["marginBottom"] = descriptionBox["marginBottom"] * 25.4;
      descriptionBox["marginLeft"] = descriptionBox["marginLeft"] * 25.4;
      descriptionBox["marginRight"] = descriptionBox["marginRight"] * 25.4;
      descriptionBox["height"] = descriptionBox["height"] * 25.4;

      reportPages.forEach((el) => {
        el.margin.top = el.margin.top * 25.4;
        el.margin.bottom = el.margin.bottom * 25.4;
        el.margin.left = el.margin.left * 25.4;
        el.margin.right = el.margin.right * 25.4;
        el.pageSize.width = el.pageSize.width * 25.4;
        el.pageSize.height = el.pageSize.height * 25.4;
      });
    }
    this.setState({
      pageSize,
      headerHeight,
      footerHeight,
      margin,
      reportPages,
      header,
      footer,
      descriptionBox,
    });
  };

  clearState = () => {
    this.setState(
      {
        id: "",
        school: {},
        descriptionBox: {},
        assessments: [],
        assessmentId: null,
        educationLevelId: null,
        previousAssessments: [],
        selectedYearLevel: "",
        yearLevels: [],
        yearLevelIds: [], //selected year level ids
        selectedMultipleYearLevels: [],
        paperSize: "a4",
        pageSize: {
          height: 297,
          width: 210,
        },
        pageSizeUnit: "mm",
        orientation: "portrait",
        margin: {
          top: 10,
          left: 10,
          right: 10,
          bottom: 10,
        },
        marginSizeUnit: "mm",
        headerHeight: 30,
        footerHeight: 15,
        customText: "",
        tabIndex: 0,
        reportPages: [],
        activeReportPageIndex: 0,
        contentFontSizes: [],
        contentAlignments: [],
        subjectAssessmentScales: [],
        assessmentGeneralCustomAssessmentsDefault: null,
        assessmentGeneralCustomAssessments: {
          generalCustomAssessmentOveral: [],
          generalCustomAssessmentRubric: [],
          generalCustomAssessmentOveralAndRubric: [],
        },
        activeColumn: "1",
        legends: null,
        commentsChip: [],
        comment: [],
        assessmentSubjectScaleConfigGroups: [],
        headerCustomText: "",
        headerFooter: "header",
        header: {},
        footer: {},
        reportName: null,
        publishingDate: null,
        activeColumnHeader: 0,
        sctMarksSubjectSettingModal: false,
        activeSubjectSettingBdIndex: 0,
        selectedReportSetupBdsIndex: 0,
        reportSetupSubjectSettingBds: [],
        customColumnCalculationModal: false,
        sampleValueSummation: 0,
        sampleValueAverage: 0,
        sampleValuePercentage: 0,
        recruitmentStatusId: "",
        staffList: [],
        publisher: "",
        reportSetupPageColumn1VerticalAlign: "middle",
        reportSetupPageColumn2VerticalAlign: "middle",
        groupedYearLevels: [],
        subjectAssessmentScaleSettingOptions: [],
        currentSubjectAssessmentScaleForSetting: "",
        currentSettingGroupId: null,
        currentSettingAssessmentScaleId: null,
        currentSettingAssessmentTypeCode: "",
        showPresetGpaLegend: true,
        isAvailable: false,
        assignEducationlevel: true,
        zoom: 36,
      },
      function () {
        this.getEducationLevelByAssessmentModule();
        this.setDefaultConfiguration();
        this.getDefaultRecruitmentId();
        this.getSchoolInfo();
        this.getRecruitmentStatus();
      }
    );
  };

  closeSctMarksSubjectSettingModal = () => {
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    if (this.props.update) {
      let setup = this.props.setup;
      if (setup.reportSetupSubjectSettingBds.length > 0) {
        setup.reportSetupSubjectSettingBds.forEach((el) => {
          if (
            el.assessmentSubjectScaleConfigurationGroupId ===
              reportSetupSubjectSettingBds[
                this.state.selectedReportSetupBdsIndex
              ].assessmentSubjectScaleConfigurationGroupId &&
            el.assessmentScaleId ===
              reportSetupSubjectSettingBds[
                this.state.selectedReportSetupBdsIndex
              ].assessmentScaleId
          ) {
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].id = el.id;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].reportSetupId = el.reportSetupId;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].breakdownTypeCode = el.breakdownTypeCode;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showScoreAs = el.showScoreAs;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showTotalFor = el.showTotalFor;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showGradePoints = el.showGradePoints;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showGrades = el.showGrades;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showGpa = el.showGpa;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showGradeEvaluation = el.showGradeEvaluation;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showClassAverage = el.showClassAverage;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].showClassHighest = el.showClassHighest;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].highlightIfHighest = el.highlightIfHighest;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].highlightIfBelowMinimum = el.highlightIfBelowMinimum;
            reportSetupSubjectSettingBds[
              this.state.selectedReportSetupBdsIndex
            ].selectedGradeFormatId = el?.selectedGradeFormatId || null;
          }
        });
      } else {
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].breakdownTypeCode = null;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].highlightIfBelowMinimum = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].highlightIfHighest = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].id = null;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].reportSetupId = null;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showClassAverage = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showClassHighest = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showGpa = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showGradeEvaluation = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showGradePoints = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showGrades = true;
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showScoreAs = "nopctg";
        reportSetupSubjectSettingBds[
          this.state.selectedReportSetupBdsIndex
        ].showTotalFor = "nopctg";
        // reportSetupSubjectSettingBds[
        //   this.state.selectedReportSetupBdsIndex
        // ].selectedGradeFormatId = el?.selectedGradeFormatId || null;
      }
    } else {
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].breakdownTypeCode = null;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].highlightIfBelowMinimum = true;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].highlightIfHighest = true;
      reportSetupSubjectSettingBds[this.state.selectedReportSetupBdsIndex].id =
        null;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].reportSetupId = null;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showClassAverage = true;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showClassHighest = true;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showGpa = true;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showGradeEvaluation = true;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showGradePoints = true;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showGrades = true;
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showScoreAs = "nopctg";
      reportSetupSubjectSettingBds[
        this.state.selectedReportSetupBdsIndex
      ].showTotalFor = "nopctg";
      // reportSetupSubjectSettingBds[
      //   this.state.selectedReportSetupBdsIndex
      // ].selectedGradeFormatId = el?.selectedGradeFormatId || null;
    }
    this.setState(
      {
        reportSetupSubjectSettingBds: reportSetupSubjectSettingBds,
      },
      () => {
        this.setState((prevState) => ({
          sctMarksSubjectSettingModal: !prevState.sctMarksSubjectSettingModal,
        }));
      }
    );
  };

  componentDidMount() {
    // if (this.props.update) {
    //   this.setUpdateConfiguration();
    // } else {
    //   this.setDefaultConfiguration();
    //   this.getDefaultRecruitmentId();
    // }
    this.getEducationLevelByAssessmentModule();
    // this.getAssessments();
    this.getSchoolInfo();
    this.getRecruitmentStatus();
    this.getAssessmentScaleByCode();
  }

  customCalculationFooter = () => {
    let previousAssessments = [...this.state.previousAssessments];
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    let reportSetupSubjectSettingBd =
      reportSetupSubjectSettingBds[this.state.selectedReportSetupBdsIndex];
    let colCals = [];
    if (reportSetupSubjectSettingBd !== undefined) {
      colCals = reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs;
    }

    return (
      <div>
        {colCals.length > 0 ? (
          <button
            className="tt-button tt-button-primary mr-3"
            onClick={this.updatePercentageWeightChange}
          >
            Update
          </button>
        ) : (
          <button
            className="tt-button tt-button-primary mr-3"
            onClick={this.savePercentageWeightChange}
          >
            Save
          </button>
        )}

        <button
          className="tt-button tt-button-primary"
          onClick={this.toggleCustomColumnCalculationModal}
        >
          Close
        </button>
      </div>
    );
  };

  getAssessments = () => {
    //URL.getAssessmentByAssessmentModule replaced by URL.getRecordingStartedAssessment
    axiosPost(
      URL.getRecordingStartedAssessment,
      { code: this.props.code },
      (response) => {
        if (response.status === 200) {
          let assessments = response.data.data;
          this.setState({ assessments }, function () {
            if (this.state?.assessments?.length > 0) {
              this.setState({ render: true, isAvailable: true });
            } else {
              this.setState({ render: true });
            }
          });
        } else {
          swal("Error", response.data.message, "");
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };

  getDefaultRecruitmentId = () => {
    let data = {
      assessmentModuleCode: this.props.code,
    };
    axiosPost(
      URL.getRecruitmentIdFromEducationLevelAssessmentModule,
      data,
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            this.setState({ publisher: response.data.data.recruitmentId });
          }
        }
      }
    );
  };

  getEducationLevelByAssessmentModule = () => {
    let data = {
      code: this.props.code,
    };
    axiosPost(
      URL.getEducationLevelByAssessmentModule,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({ educationLevels: response.data.data }, () => {
            if (this.state.educationLevels.length === 1) {
              this.getAssessments();
            } else {
              this.setState({ assignEducationlevel: false, render: true });
            }
          });
        } else {
          this.setState({ render: true });
        }
        if (response.status === 204) {
          this.setState({ assignEducationlevel: false, render: true });
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };

  /**
   * To get general assessments
   */
  getGeneralAssessmentsByAssessmentAndYearLevel = (param) => {
    axiosPost(
      URL.selectGeneralCustomAssessmentConfigurations,
      param,
      (response) => {
        if (response.status === 200) {
          let assessmentGeneralCustomAssessmentsDefault = response.data.data;
          let assessmentGeneralCustomAssessments =
            this.state.assessmentGeneralCustomAssessments;
          // Selecting assessmentGeneralCustomAssessments with unique name for display.

          assessmentGeneralCustomAssessmentsDefault.generalCustomAssessmentOveral.forEach(
            (generalCustomAssessmentOveral) => {
              let index = checkValueExitsInArray(
                assessmentGeneralCustomAssessments.generalCustomAssessmentOveral,
                "customAssessmentHeaderName",
                generalCustomAssessmentOveral.customAssessmentHeaderName
              );
              if (index === -1 || index === undefined) {
                assessmentGeneralCustomAssessments.generalCustomAssessmentOveral =
                  [
                    ...this.state.assessmentGeneralCustomAssessments
                      .generalCustomAssessmentOveral,
                    generalCustomAssessmentOveral,
                  ];
                this.setState({ assessmentGeneralCustomAssessments });
              }
            }
          );
          assessmentGeneralCustomAssessmentsDefault.generalCustomAssessmentRubric.forEach(
            (generalCustomAssessmentRubric) => {
              let index = checkValueExitsInArray(
                assessmentGeneralCustomAssessments.generalCustomAssessmentRubric,
                "customAssessmentHeaderName",
                generalCustomAssessmentRubric.customAssessmentHeaderName
              );
              if (index === -1 || index === undefined) {
                assessmentGeneralCustomAssessments.generalCustomAssessmentRubric =
                  [
                    ...this.state.assessmentGeneralCustomAssessments
                      .generalCustomAssessmentRubric,
                    generalCustomAssessmentRubric,
                  ];
                this.setState({ assessmentGeneralCustomAssessments });
              }
            }
          );
          assessmentGeneralCustomAssessmentsDefault.generalCustomAssessmentOveralAndRubric.forEach(
            (generalCustomAssessmentOveralAndRubric) => {
              let index = checkValueExitsInArray(
                assessmentGeneralCustomAssessments.generalCustomAssessmentOveralAndRubric,
                "customAssessmentHeaderName",
                generalCustomAssessmentOveralAndRubric.customAssessmentHeaderName
              );
              if (index === -1 || index === undefined) {
                assessmentGeneralCustomAssessments.generalCustomAssessmentOveralAndRubric =
                  [
                    ...this.state.assessmentGeneralCustomAssessments
                      .generalCustomAssessmentOveralAndRubric,
                    generalCustomAssessmentOveralAndRubric,
                  ];
                this.setState({ assessmentGeneralCustomAssessments });
              }
            }
          );
          // let assessmentGeneralCustomAssessments = response.data.data;
          this.setState(
            {
              assessmentGeneralCustomAssessments,
              assessmentGeneralCustomAssessmentsDefault,
            },
            function () {
              if (this.props.update) {
                let GeneralAssessments =
                  this.state.assessmentGeneralCustomAssessments;
                let newGeneralCustomAssessmentOveral = [];
                let newGeneralCustomAssessmentOveralAndRubric = [];
                let newGeneralCustomAssessmentRubric = [];
                let reportPage = this.props.setup.reportSetupPages;
                if (reportPage.length > 0) {
                  let countRubric = [];
                  let countOveral = [];
                  let countOveralAndRubric = [];
                  reportPage.forEach((el) => {
                    el.reportSetupPageColumns.forEach((pc) => {
                      let contents = pc.reportSetupPageColumnContents;
                      if (contents.length > 0) {
                        contents.forEach((content) => {
                          if (
                            content.tableName ===
                            dbTable.generalAssessmentOverallConfiguration
                          ) {
                            let GeneralAssessmentsOverallConfig =
                              GeneralAssessments.generalCustomAssessmentOveral;
                            let GeneralAssessmentsOverallAndRubricConfig =
                              GeneralAssessments.generalCustomAssessmentOveralAndRubric;
                            if (GeneralAssessmentsOverallConfig.length > 0) {
                              for (
                                let i = 0;
                                i < GeneralAssessmentsOverallConfig.length;
                                i++
                              ) {
                                if (
                                  GeneralAssessmentsOverallConfig[i].id ===
                                  content.tableUnId
                                ) {
                                  countOveral.push(
                                    GeneralAssessmentsOverallConfig[i]
                                  );
                                }
                              }
                            }
                            if (
                              GeneralAssessmentsOverallAndRubricConfig.length >
                              0
                            ) {
                              for (
                                let i = 0;
                                i <
                                GeneralAssessmentsOverallAndRubricConfig.length;
                                i++
                              ) {
                                if (
                                  GeneralAssessmentsOverallAndRubricConfig[i]
                                    .id === content.tableUnId
                                ) {
                                  countOveralAndRubric.push(
                                    GeneralAssessmentsOverallAndRubricConfig[i]
                                  );
                                }
                              }
                            }
                          } else if (
                            content.tableName ===
                            dbTable.generalAssessmentRubricsConfiguration
                          ) {
                            let GeneralAssessmentsRubricConfig =
                              GeneralAssessments.generalCustomAssessmentRubric;
                            if (GeneralAssessmentsRubricConfig.length > 0) {
                              for (
                                let i = 0;
                                i < GeneralAssessmentsRubricConfig.length;
                                i++
                              ) {
                                if (
                                  GeneralAssessmentsRubricConfig[i].id ===
                                  content.tableUnId
                                ) {
                                  countRubric.push(
                                    GeneralAssessmentsRubricConfig[i]
                                  );
                                }
                              }
                            }
                          }
                        });
                      }
                    });
                  });

                  let GeneralAssessmentsRubricConfig =
                    GeneralAssessments.generalCustomAssessmentRubric;
                  if (GeneralAssessmentsRubricConfig.length > 0) {
                    if (countRubric.length > 0) {
                      newGeneralCustomAssessmentRubric =
                        GeneralAssessmentsRubricConfig;
                      countRubric.forEach((cR) => {
                        newGeneralCustomAssessmentRubric =
                          newGeneralCustomAssessmentRubric.filter(function (
                            el
                          ) {
                            return el.id !== cR.id;
                          });
                      });
                    } else {
                      newGeneralCustomAssessmentRubric =
                        GeneralAssessmentsRubricConfig;
                    }
                  }

                  let GeneralAssessmentsOverallConfig =
                    GeneralAssessments.generalCustomAssessmentOveral;
                  if (GeneralAssessmentsOverallConfig.length > 0) {
                    if (countOveral.length > 0) {
                      newGeneralCustomAssessmentOveral =
                        GeneralAssessmentsOverallConfig;
                      countOveral.forEach((cO) => {
                        newGeneralCustomAssessmentOveral =
                          newGeneralCustomAssessmentOveral.filter(function (
                            el
                          ) {
                            return el.id !== cO.id;
                          });
                      });
                    } else {
                      newGeneralCustomAssessmentOveral =
                        GeneralAssessmentsOverallConfig;
                    }
                  }

                  let GeneralAssessmentsOverallAndRubricConfig =
                    GeneralAssessments.generalCustomAssessmentOveralAndRubric;
                  if (GeneralAssessmentsOverallAndRubricConfig.length > 0) {
                    if (countOveralAndRubric.length > 0) {
                      newGeneralCustomAssessmentOveralAndRubric =
                        GeneralAssessmentsOverallAndRubricConfig;
                      countOveralAndRubric.forEach((cOR) => {
                        newGeneralCustomAssessmentOveralAndRubric =
                          newGeneralCustomAssessmentOveralAndRubric.filter(
                            function (el) {
                              return el.id !== cOR.id;
                            }
                          );
                      });
                    } else {
                      newGeneralCustomAssessmentOveralAndRubric =
                        GeneralAssessmentsOverallAndRubricConfig;
                    }
                  }
                }

                GeneralAssessments.generalCustomAssessmentOveral =
                  newGeneralCustomAssessmentOveral;
                GeneralAssessments.generalCustomAssessmentRubric =
                  newGeneralCustomAssessmentRubric;
                GeneralAssessments.generalCustomAssessmentOveralAndRubric =
                  newGeneralCustomAssessmentOveralAndRubric;
                this.setState({
                  assessmentGeneralCustomAssessments: GeneralAssessments,
                });
              }
            }
          );
        } else {
          swal("Error", response.data.message, "");
        }
      }
    );
  };

  /**
   * @author azzetaKarmacharya
   * To get comments configuration for reporting setup according to  assessment and yearlevels id
   */
  getGeneralComments = (param) => {
    axiosPost(URL.commentChipForReportingSetup, param, (response) => {
      if (response.status === 200) {
        let comments = response.data.data;
        let commentsChip = [...this.state.commentsChip];
        // Selecting Comments with unique name for display.
        comments.forEach((comment) => {
          let index = checkValueExitsInArray(
            commentsChip,
            "commentName",
            comment.commentName
          );
          if (index === -1 || index === undefined) {
            commentsChip = [...this.state.commentsChip, comment];
            this.setState({ commentsChip });
          }
        });
        this.setState({ comments });
      }
    });
  };

  /**
   * @author azzetaKarmacharya
   * To get legends for reporting setup according to  assessment and yearlevels id
   */
  getLegends = (param) => {
    axiosPost(URL.legendsForReportingSetup, param, (response) => {
      if (response.status === 200) {
        let legends = response.data.data;
        let newLegends = [];
        if (this.props.update) {
          let reportPage = this.props.setup.reportSetupPages;
          if (reportPage.length > 0) {
            let countLegends = [];
            reportPage.forEach((el) => {
              el.reportSetupPageColumns.forEach((pc) => {
                let contents = pc.reportSetupPageColumnContents;
                if (contents.length > 0) {
                  contents.forEach((content) => {
                    if (content.tableName === dbTable.assessmentScale) {
                      for (let i = 0; i < legends.length; i++) {
                        if (legends[i].id === content.tableUnId) {
                          countLegends.push(legends[i]);
                        }
                      }
                    }
                    if (
                      content.tableName ===
                      dbTable.presetAssessmentScaleGradePoint
                    ) {
                      this.setState({ showPresetGpaLegend: false });
                    }
                  });
                }
              });
            });

            if (legends.length > 0) {
              if (countLegends.length > 0) {
                newLegends = legends;
                countLegends.forEach((cL) => {
                  newLegends = newLegends.filter(function (el) {
                    return el.id !== cL.id;
                  });
                });
              } else {
                newLegends = legends;
              }
            }
          }
        } else {
          newLegends = legends;
        }
        this.setState({ legends: newLegends });
      } else {
        swal("Error", response.data.message, "");
      }
    });
  };

  /**
   * @author azzetaKarmacharya
   * To get PreviousAssessments for reporting setup according to  assessment and yearlevel id and education level
   */
  getPreviousAssessments = (param) => {
    axiosPost(URL.selectPreviousAssessment, param, (response) => {
      if (response.status === 200) {
        let previousAssessments = response.data.data;
        if (previousAssessments.length > 0) {
          let params = {
            id: parseInt(this.state.assessmentId),
          };
          axiosPost(URL.selectAssessmentByKey, params, (response) => {
            if (response.status == 200) {
              previousAssessments = [
                ...previousAssessments,
                response.data.data,
              ];

              let sampleValueSummation = 0,
                sampleValueAverage = 0;
              previousAssessments.forEach((previousAssessment) => {
                let sampleValue = Math.floor(Math.random() * 71) + 30;
                previousAssessment.sampleValue = sampleValue;
                sampleValueSummation += sampleValue;
                previousAssessment.percentageWeight = 0;
                previousAssessment.isCurrentAssessment =
                  previousAssessment.id ==
                  parseInt(this.state.assessmentId.split(",")[0])
                    ? true
                    : false;
                previousAssessment.selectedForCustomColCalc = false;
              });
              sampleValueAverage =
                sampleValueSummation / previousAssessments.length;
              // this.setState({ sampleValueSummation, sampleValueAverage, assessments });

              this.setState({ previousAssessments });
            }
          });
        }
      }
    });
  };

  getRecruitmentList = () => {
    let data = { recruitmentStatusId: this.state.recruitmentStatusId };
    axiosPost(URL.getStaffList, data, (response) => {
      if (response.status === 200) {
        this.setState({ staffList: response.data.data });
      }
    });
  };

  getRecruitmentStatus = () => {
    let data = {
      code: recruitmentStatus.recruitmentCurrent,
    };
    axiosPost(URL.getRecruitmentStatusByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { recruitmentStatusId: response.data.data.id },
          function () {
            this.getRecruitmentList();
          }
        );
      }
    });
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        let school = response.data.data[0];
        this.setState({ school }, function () {
          if (this.props.update) {
            this.setUpdateConfiguration();
          } else {
            this.setDefaultConfiguration();
            this.getDefaultRecruitmentId();
          }
        });
      } else {
        swal("Error", response.data.message, "");
        if (this.props.update) {
          this.setUpdateConfiguration();
        } else {
          this.setDefaultConfiguration();
          this.getDefaultRecruitmentId();
        }
      }
    });
  };

  /**
   * @author azzetaKarmacharya
   * To get subject assessment scales
   */
  getSubjectAssessmentScaleByAssessmentAndYearLevel = (param) => {
    axiosPost(
      URL.selectAssessmentSubjectScaleConfigGroups,
      param,
      (response) => {
        if (response.status === 200) {
          let assessmentSubjectScaleConfigGroups = response.data.data;
          let newSubjectScaleConfigGroups = [];
          if (this.props.update) {
            let reportPage = this.props.setup.reportSetupPages;
            if (reportPage.length > 0) {
              let countSubjectScale = [];
              reportPage.forEach((el) => {
                el.reportSetupPageColumns.forEach((pc) => {
                  let contents = pc.reportSetupPageColumnContents;
                  if (contents.length > 0) {
                    contents.forEach((content) => {
                      if (
                        content.tableName ===
                        dbTable.subjectAssessmentConfigurationGroup
                      ) {
                        for (
                          let i = 0;
                          i < assessmentSubjectScaleConfigGroups.length;
                          i++
                        ) {
                          if (
                            assessmentSubjectScaleConfigGroups[i].id ===
                            content.tableUnId
                          ) {
                            countSubjectScale.push(
                              assessmentSubjectScaleConfigGroups[i]
                            );
                          }
                        }
                      }
                    });
                  }
                });
              });

              if (assessmentSubjectScaleConfigGroups.length > 0) {
                if (countSubjectScale.length > 0) {
                  newSubjectScaleConfigGroups =
                    assessmentSubjectScaleConfigGroups;
                  newSubjectScaleConfigGroups = [
                    ...assessmentSubjectScaleConfigGroups,
                  ];
                  countSubjectScale.forEach((cSS) => {
                    newSubjectScaleConfigGroups =
                      newSubjectScaleConfigGroups.filter(function (el) {
                        return el.id !== cSS.id;
                      });
                  });
                } else {
                  newSubjectScaleConfigGroups = [
                    ...assessmentSubjectScaleConfigGroups,
                  ];
                }
                // assessmentSubjectScaleConfigGroups.forEach((aSSCG) => {
                //   for (let i = 0; i < countSubjectScale.length; i++) {
                //     if (countSubjectScale[i].id !== aSSCG.id) {
                //       newSubjectScaleConfigGroups.push(aSSCG);
                //     }
                //   }
                // });
              }
            }
          } else {
            newSubjectScaleConfigGroups = [
              ...assessmentSubjectScaleConfigGroups,
            ];
          }

          //aded for special souvenir case where two similar assessment scale type will be shown as same chip
          let chips = [...newSubjectScaleConfigGroups];

          let markschip = chips?.filter(
            (c) => c.assessmentScaleTypeCode === scaleTypeCode.mark
          );

          let levelChip = chips?.filter(
            (c) => c.assessmentScaleTypeCode === scaleTypeCode.level
          );

          let gpaChip = chips?.filter(
            (c) => c.assessmentScaleTypeCode === scaleTypeCode.gradePoint
          );

          let mscIds = markschip.map((msc) => {
            return msc.id;
          });
          if (markschip) {
            let x = markschip[0];
            x.scales = "Subject Assessment Scale Mark";
            x["mapMultipleAssessmentScale"] = mscIds;
            markschip = [markschip[0]];
          }

          let levelIds = levelChip.map((msc) => {
            return msc.id;
          });
          if (levelChip.length > 0) {
            let x = levelChip[0];
            x.scales = "Subject Assessment Scale Level";
            x["mapMultipleAssessmentScale"] = levelIds;
            levelChip = [levelChip[0]];
          }

          let gpaIds = gpaChip.map((msc) => {
            return msc.id;
          });
          if (gpaChip.length > 0) {
            let x = gpaChip[0];
            x.scales = "Subject Assessment Scale GPA";
            x["mapMultipleAssessmentScale"] = gpaIds;
            gpaChip = [gpaChip[0]];
          }

          let newChips = [...markschip, ...levelChip, ...gpaChip];
          this.setState({
            assessmentSubjectScaleConfigGroups: newChips,
            defaultSubjectScaleConfigGroups: [
              ...assessmentSubjectScaleConfigGroups,
            ],
          });
        } else {
          swal("Error", response.data.message, "");
        }
      }
    );
  };

  getYearLevelByAssessment = (param) => {
    axiosPost(
      URL.getYearLevelByAssessmentForReportConfig,
      param,
      (response) => {
        if (response.status === 200) {
          let data = response.data.data;
          let yearLevels = data[0];
          this.setState({ yearLevels });
        } else {
          swal("Error", response.data.message, "");
        }
      }
    );
  };

  /**
   * @description this method handles clicking of subject or general chips.
   * @param orb O = overall, R = Rubric, B = Both
   */
  handleAddAssessmentChipClick = (orb, orbIndex) => {
    let reportPages = [...this.state.reportPages],
      activeReportPageIndex = this.state.activeReportPageIndex,
      activeColumn = this.state.activeColumn,
      assessmentGeneralCustomAssessments =
        this.state.assessmentGeneralCustomAssessments,
      subjectAssessmentScales = this.state.subjectAssessmentScales,
      legends = this.state.legends,
      commentsChip = this.state.commentsChip,
      assessmentSubjectScaleConfigGroups =
        this.state.assessmentSubjectScaleConfigGroups;
    let reportPage = reportPages[activeReportPageIndex];
    let assessment = {};
    switch (orb) {
      case "O":
        assessment =
          assessmentGeneralCustomAssessments.generalCustomAssessmentOveral[
            orbIndex
          ];
        assessmentGeneralCustomAssessments.generalCustomAssessmentOveral.splice(
          orbIndex,
          1
        );
        assessment.customAssessmentHeaderName =
          "GENERAL OVERALL ONLY: " + assessment.customAssessmentHeaderName;
        assessment.tableName = dbTable.generalAssessmentOverallConfiguration;
        break;
      case "R":
        assessment =
          assessmentGeneralCustomAssessments.generalCustomAssessmentRubric[
            orbIndex
          ];
        assessmentGeneralCustomAssessments.generalCustomAssessmentRubric.splice(
          orbIndex,
          1
        );
        assessment.customAssessmentHeaderName =
          "GENERAL RUBRIC ONLY: " + assessment.customAssessmentHeaderName;
        assessment.tableName = dbTable.generalAssessmentRubricsConfiguration;
        break;
      case "B":
        assessment =
          assessmentGeneralCustomAssessments
            .generalCustomAssessmentOveralAndRubric[orbIndex];
        assessmentGeneralCustomAssessments.generalCustomAssessmentOveralAndRubric.splice(
          orbIndex,
          1
        );
        assessment.customAssessmentHeaderName =
          "GENERAL OVERALL AND RUBRIC: " +
          assessment.customAssessmentHeaderName;
        assessment.tableName = dbTable.generalAssessmentOverallConfiguration;
        break;

      case "SUB":
        assessment = assessmentSubjectScaleConfigGroups[orbIndex];
        assessmentSubjectScaleConfigGroups.splice(orbIndex, 1);
        let groupId = assessment.id;
        assessment.customAssessmentHeaderName =
          "SUBJECTS: " + assessment.scales;
        // "G-" + groupId + " SUBJECTS: " + assessment.scales;

        //todo NEED TO DO IT HERE

        // "SUBJECTS: " + assessment.scales;
        assessment.tableName = dbTable.subjectAssessmentConfigurationGroup;

        //get assessment scale by group and  add to arraylist shown in dropdown
        this.selectAssessmentScaleByGroup(groupId, null, assessment);
        break;
      case "LEGEND":
        assessment = legends[orbIndex];
        legends.splice(orbIndex, 1);
        assessment.customAssessmentHeaderName =
          "LEGEND: " + assessment.scaleName;
        assessment.tableName = dbTable.assessmentScale;
        // assessment.selectedLegendId =
        //   this.state.reportSetupSubjectSettingBds[
        //     this.state.selectedReportSetupBdsIndex
        //   ].selectedGradeFormatId;
        // assessment.selectedTableName =
        //   dbTable.userDefinedAssessmentScaleGradePoint;
        break;
      case "LEGENDGP":
        const gFormatId =
          this.state.reportSetupSubjectSettingBds[
            this.state.selectedReportSetupBdsIndex
          ].selectedGradeFormatId;
        if (!gFormatId) {
          swal(
            "Warning",
            "Please setup the grade format in selected assessment scale.",
            ""
          );
          return;
        }
        assessment.customAssessmentHeaderName = "LEGEND: Grade Point Standard";
        assessment.tableName = dbTable.presetAssessmentScaleGradePoint;
        assessment.gpTable = true;
        // assessment.selectedLegendId =
        //   this.state.reportSetupSubjectSettingBds[
        //     this.state.selectedReportSetupBdsIndex
        //   ].selectedGradeFormatId;
        // assessment.selectedTableName =
        //   dbTable.userDefinedAssessmentScaleGradePoint;
        this.setState({ showPresetGpaLegend: false });
        break;
      case "COMMENT":
        assessment = commentsChip[orbIndex];
        commentsChip.splice(orbIndex, 1);
        assessment.customAssessmentHeaderName =
          "GENERAL: " + assessment.commentName;
        assessment.tableName = dbTable.assessmentGeneralCommentConfiguration;

        break;
      default:
        break;
    }
    if (activeColumn === "1") {
      reportPage.col1Contents.push(assessment);
    } else if (activeColumn === "2") {
      reportPage.col2Contents.push(assessment);
    }
    this.setState({
      reportPages,
      assessmentGeneralCustomAssessments,
      legends,
      assessmentSubjectScaleConfigGroups,
    });
  };

  handleAddCustomRowInDescriptionBtnClick = () => {
    let descriptionBox = { ...this.state.descriptionBox },
      activeDescriptionColumn = this.state.activeDescriptionColumn;
    const descriptionCustomText = this.state.descriptionCustomText;
    if (descriptionCustomText.length <= 0) {
      swal("Warning", "No custom text found.", "");
      return;
    }
    let content = {
      name: descriptionCustomText,
      customRecord: true,
      customText: descriptionCustomText,
      prefix: "",
    };
    let columns = descriptionBox.columns;
    if (columns.length > activeDescriptionColumn) {
      content.horizontalAlign = "left";
      content.fontSize = 10;
      columns[activeDescriptionColumn].contents.push(content);
    }
    this.setState({ descriptionBox, descriptionCustomText: "" });
  };

  handleAddCustomRowInHeaderBtnClick = () => {
    let header =
        this.state.headerFooter === "header"
          ? { ...this.state.header }
          : { ...this.state.footer },
      activeColumnHeader = this.state.activeColumnHeader;
    const headerCustomText = this.state.headerCustomText;
    if (headerCustomText.length <= 0) {
      swal("Warning", "No custom text found.", "");
      return;
    }
    let content = {
      name: headerCustomText,
      customRecord: true,
      customText: headerCustomText,
    };
    let columns = header.columns;
    if (columns.length > activeColumnHeader) {
      content.horizontalAlign = "left";
      content.fontSize = 10;
      columns[activeColumnHeader].contents.push(content);
    }
    this.state.headerFooter === "header"
      ? this.setState({ header, headerCustomText: "" })
      : this.setState({ footer: header, headerCustomText: "" });
  };

  handleAddImageInHeader = (e) => {
    if (e.target.files[0].type.includes("image")) {
      let header =
          this.state.headerFooter === "header"
            ? { ...this.state.header }
            : { ...this.state.footer },
        activeColumnHeader = this.state.activeColumnHeader;
      let content = {
        name: e.target.files[0].name,
        file: e.target.files[0],
        type: "IMG",
      };
      let columns = header.columns;
      if (columns.length > activeColumnHeader) {
        content.horizontalAlign = "left";
        content.fontSize = null;
        content.height = header.height + header.heightUnit;
        content.width = "auto";
        columns[activeColumnHeader].contents.push(content);
      }
      this.state.headerFooter === "header"
        ? this.setState({ header })
        : this.setState({ footer: header });
    } else {
      swal({
        title: "Error",
        text: "Please select a valid image file",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  handleAddPrevAssmtScoreBtnClick = (e) => {
    let previousAssessments = [...this.state.previousAssessments];
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    let reportSetupSubjectSettingBd =
      reportSetupSubjectSettingBds[this.state.selectedReportSetupBdsIndex];
    let colCals = reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs;
    if (colCals.length > 0) {
      colCals.forEach((el) => {
        for (let i = 0; i < previousAssessments.length; i++) {
          if (el.assessmentId === previousAssessments[i].id) {
            previousAssessments[i].percentageWeight = el.percentageWeight;
            previousAssessments[i].selectedForCustomColCalc = true;
          }
        }
      });
    }
    this.setState({ previousAssessments }, function () {
      this.toggleCustomColumnCalculationModal();
    });
  };

  handleAddReportPageBtnClick = () => {
    let reportPages = [...this.state.reportPages],
      reportPage = {};
    if (reportPages.length > 0) {
      let index = reportPages.slice(-1).pop();
      reportPage.pageOrder = index.pageOrder + 1;
    }
    reportPage.layout = {
      layout: "1col",
      col1Width: "100%",
      col2Width: "0%",
      col2VerticalAlign: "top",
      col1VerticalAlign: "top",
    };
    reportPage.margin = {
      top: this.state.margin.top,
      bottom: this.state.margin.bottom,
      left: this.state.margin.left,
      right: this.state.margin.right,
    };
    reportPage.pageSize = {
      width: this.state.pageSize.width,
      height: this.state.pageSize.height,
    };
    reportPage.marginSizeUnit = this.state.marginSizeUnit;
    reportPage.col1Contents = [];
    reportPage.col2Contents = [];
    reportPages.push(reportPage);
    this.setState({
      reportPages,
      activeReportPageIndex: reportPages.length - 1,
    });
  };

  handleAddRowBtnClick = () => {
    let reportPages = [...this.state.reportPages],
      activeReportPageIndex = this.state.activeReportPageIndex,
      activeColumn = this.state.activeColumn;
    let reportPage = reportPages[activeReportPageIndex];
    const customText = this.state.customText;
    if (customText.length <= 0) {
      swal("Warning", "No custom text found.", "");
      return;
    }
    let row = {
      customAssessmentHeaderName: customText,
      customRecord: true,
      customText: customText,
    };
    if (activeColumn === "1") {
      reportPage.col1Contents.push(row);
    } else if (activeColumn === "2") {
      reportPage.col2Contents.push(row);
    }
    this.setState({ reportPages, customText: "" });
  };

  handleAddRowChipClick = (rowIndicator) => {
    let reportPages = [...this.state.reportPages],
      activeReportPageIndex = this.state.activeReportPageIndex,
      activeColumn = this.state.activeColumn;
    let reportPage = reportPages[activeReportPageIndex];
    let row = {};
    switch (rowIndicator) {
      case "reportName":
        const reportName = this.state.reportName;
        row = {
          customAssessmentHeaderName: reportName,
          tableName: dbTable.reportSetup,
          fieldName: "name",
        };
        break;
      case "publishingDate":
        const publishingDate = this.state.publishingDate;
        row = {
          customAssessmentHeaderName: publishingDate,
          tableName: dbTable.reportSetup,
          fieldName: "publishing_date",
        };
        break;
      case "schoolName":
        const schoolName = this.state.school
          ? this.state.school.name
            ? this.state.school.name
            : "School Name"
          : "School Name";
        row = {
          customAssessmentHeaderName: schoolName,
          tableName: dbTable.school,
          fieldName: "name",
        };
        break;
      case "schoolAddress":
        const schoolAddress = this.state.school
          ? this.state.school.address
            ? this.state.school.address
            : "School Address"
          : "School Address";
        row = {
          customAssessmentHeaderName: schoolAddress,
          tableName: dbTable.school,
          fieldName: "address",
        };
        break;
      case "educationLevel":
        const educationLevel = "Education Level";
        row = {
          customAssessmentHeaderName: educationLevel,
          tableName: dbTable.educationLevel,
          fieldName: "name",
        };
        break;
      case "yearLevel":
        const yearLevel = "Year Level";
        row = {
          customAssessmentHeaderName: yearLevel,
          tableName: dbTable.yearLevel,
          fieldName: "name",
        };
        break;
      case "classroom":
        const classroom = "Classroom";
        row = {
          customAssessmentHeaderName: classroom,
          tableName: dbTable.classroom,
          fieldName: "name",
        };
        break;
      case "studentName":
        const studentName = "Student Name";
        row = {
          customAssessmentHeaderName: studentName,
          tableName: dbTable.admission,
          fieldName: "name",
        };
        break;
      case "rollNumber":
        const rollNumber = "Roll Number";
        row = {
          customAssessmentHeaderName: rollNumber,
          tableName: dbTable.studentClassroom,
          fieldName: "roll_number",
        };
        break;
      case "academicYear":
        const academicYear = "Academic Year";
        row = {
          customAssessmentHeaderName: academicYear,
          tableName: dbTable.academicYear,
          fieldName: "name",
        };
        break;
      case "studentPic":
        const studentPhoto = "Student Photo";
        row = {
          customAssessmentHeaderName: studentPhoto,
          tableName: dbTable.admission,
          fieldName: "photo",
        };
        break;
      // case "resultStatus":
      //   const resultStatus = "Result Status";
      //   row = {
      //     customAssessmentHeaderName: resultStatus,
      //     tableName: dbTable.resultTable,
      //     fieldName: "result",
      //   };
      //   break;
      case "separator":
        const separator = "Separator";
        row = {
          customAssessmentHeaderName: separator,
          tableName: dbTable.separator,
          fieldName: "separator",
        };
        break;
      case "pageNumber":
        const pageNumber = "Page Number";
        row = {
          customAssessmentHeaderName: pageNumber,
          tableName: dbTable.pageNumber,
          fieldName: "Page Number",
        };
        break;
      default:
        break;
    }
    if (activeColumn === "1") {
      reportPage.col1Contents.push(row);
    } else if (activeColumn === "2") {
      reportPage.col2Contents.push(row);
    }
    this.setState({ reportPages });
  };

  handleAddRowDescriptionChipClick = (contentIndicator) => {
    let descriptionBox = { ...this.state.descriptionBox },
      activeDescriptionColumn = this.state.activeDescriptionColumn;
    let content = {};

    switch (contentIndicator) {
      case "reportName":
        const reportName = this.state.reportName;
        content = {
          name: reportName,
          tableName: dbTable.reportSetup,
          fieldName: "name",
          prefix: "",
        };
        break;
      case "publishingDate":
        const publishingDate = this.state.publishingDate;
        content = {
          name: "Published Date: " + publishingDate,
          tableName: dbTable.reportSetup,
          fieldName: "publishing_date",
          prefix: "Published Date: ",
        };
        break;

      case "schoolName":
        const schoolName = this.state.school
          ? this.state.school.name
            ? this.state.school.name
            : "School Name"
          : "School Name";
        content = {
          name: "School Name: " + schoolName,
          tableName: dbTable.school,
          fieldName: "name",
          prefix: "School Name: ",
        };
        break;
      case "schoolAddress":
        const schoolAddress = this.state.school
          ? this.state.school.address
            ? this.state.school.address
            : "School Address"
          : "School Address";
        content = {
          name: "School Address: " + schoolAddress,
          tableName: dbTable.school,
          fieldName: "address",
          prefix: "School Address: ",
        };
        break;
      case "educationLevel":
        content = {
          name: "Education Level: XXXXXX",
          tableName: dbTable.educationLevel,
          fieldName: "name",
          prefix: "Education Level: ",
        };
        break;
      case "yearLevel":
        content = {
          name: `${localStorage.getItem("yearLevel")}: XXXXXX`,
          tableName: dbTable.yearLevel,
          fieldName: "name",
          prefix: `${localStorage.getItem("yearLevel")}: `,
        };
        break;
      case "classroom":
        content = {
          name: `${localStorage.getItem("classroom")}: XXX`,
          tableName: dbTable.classroom,
          fieldName: "name",
          prefix: `${localStorage.getItem("classroom")}: `,
        };
        break;
      case "studentName":
        content = {
          name: "Student Name: Xxxxxxxxx Xxxxxx",
          tableName: dbTable.admission,
          fieldName: "name",
          prefix: "Student Name: ",
        };
        break;

      case "rollNumber":
        content = {
          name: "Roll Number: XX",
          tableName: dbTable.studentClassroom,
          fieldName: "roll_number",
          prefix: "Roll Number: ",
        };
        break;
      case "academicYear":
        content = {
          name: "Academic Year: XXXXXXXX",
          tableName: dbTable.academicYear,
          fieldName: "name",
          prefix: "Academic Year: ",
        };
        break;
      case "studentPic":
        const studentPhoto = "Student Photo";
        content = {
          name: studentPhoto,
          tableName: dbTable.admission,
          fieldName: "photo",
          prefix: "",
        };
        break;

      case "separator":
        const separator = "Separator";
        content = {
          name: separator,
          tableName: dbTable.separator,
          fieldName: "separator",
          prefix: "",
        };
        break;
      default:
        break;
    }
    let columns = descriptionBox.columns;
    if (columns.length > activeDescriptionColumn) {
      content.horizontalAlign = content.horizontalAlign
        ? content.horizontalAlign
        : "left";
      content.fontSize = content.fontSize ? content.fontSize : 10;
      columns[activeDescriptionColumn].contents.push(content);
    }
    this.setState({ descriptionBox });
  };

  handleAddRowInHeaderChipClick = (contentIndicator) => {
    let header =
        this.state.headerFooter === "header"
          ? { ...this.state.header }
          : { ...this.state.footer },
      activeColumnHeader = this.state.activeColumnHeader;
    let content = {};

    switch (contentIndicator) {
      case "reportName":
        const reportName = this.state.reportName;
        content = {
          name: reportName,
          tableName: dbTable.reportSetup,
          fieldName: "name",
        };
        break;
      case "publishingDate":
        const publishingDate = this.state.publishingDate;
        content = {
          name: publishingDate,
          tableName: dbTable.reportSetup,
          fieldName: "publishing_date",
        };
        break;

      case "schoolName":
        const schoolName = this.state.school
          ? this.state.school.name
            ? this.state.school.name
            : "School Name"
          : "School Name";
        content = {
          name: schoolName,
          tableName: dbTable.school,
          fieldName: "name",
        };
        break;
      case "schoolAddress":
        const schoolAddress = this.state.school
          ? this.state.school.address
            ? this.state.school.address
            : "School Address"
          : "School Address";
        content = {
          name: schoolAddress,
          tableName: dbTable.school,
          fieldName: "address",
        };
        break;
      case "educationLevel":
        const educationLevel = "Education Level";
        content = {
          name: educationLevel,
          tableName: dbTable.educationLevel,
          fieldName: "name",
        };
        break;
      case "yearLevel":
        const yearLevel = "Year Level";
        content = {
          name: yearLevel,
          tableName: dbTable.yearLevel,
          fieldName: "name",
        };
        break;
      case "classroom":
        const classroom = "Classroom";
        content = {
          name: classroom,
          tableName: dbTable.classroom,
          fieldName: "name",
        };
        break;
      case "studentName":
        const studentName = "Student Name";
        content = {
          name: studentName,
          tableName: dbTable.admission,
          fieldName: "name",
        };
        break;

      case "rollNumber":
        const rollNumber = "Roll Number";
        content = {
          name: rollNumber,
          tableName: dbTable.studentClassroom,
          fieldName: "roll_number",
        };
        break;
      case "academicYear":
        const academicYear = "Academic Year";
        content = {
          name: academicYear,
          tableName: dbTable.academicYear,
          fieldName: "name",
        };
        break;
      case "studentPic":
        const studentPhoto = "Student Photo";
        content = {
          name: studentPhoto,
          tableName: dbTable.admission,
          fieldName: "photo",
        };
        break;

      case "separator":
        const separator = "Separator";
        content = {
          name: separator,
          tableName: dbTable.separator,
          fieldName: "separator",
        };
        break;
      case "pageNumber":
        const pageNumber = "Page Number";
        content = {
          name: pageNumber,
          tableName: dbTable.pageNumber,
          fieldName: "Page Number",
        };
        break;
      default:
        break;
    }
    let columns = header.columns;
    if (columns.length > activeColumnHeader) {
      content.horizontalAlign = content.horizontalAlign
        ? content.horizontalAlign
        : "left";
      content.fontSize = content.fontSize ? content.fontSize : 10;
      columns[activeColumnHeader].contents.push(content);
    }
    this.state.headerFooter === "header"
      ? this.setState({ header })
      : this.setState({ footer: header });
  };

  handleAssessmentChange = (e) => {
    const target = e.target;
    const value = target.value.split(",");
    this.setState(
      {
        assessmentId: target.value,
        reportName: value[1],
        educationLevelId: parseInt(value[2]),
      },
      () => {
        let param = { assessmentId: parseInt(value[0]) };
        if (!this.props.update) {
          let reportPages = [
            {
              layout: {
                layout: "1col",
                col1Width: "100%",
                col2Width: "0%",
                col1VerticalAlign: "top",
                col2VerticalAlign: "top",
              },

              margin: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10,
              },
              pageSize: {
                height: 297,
                width: 210,
              },
              pageOrder: 1,
              marginSizeUnit: "mm",
              pageSizeUnit: "mm",
              col1Contents: [],
              col2Contents: [],
            },
          ];
          this.setState({
            yearLevelIds: [],
            yearLevels: [],
            selectedYearLevel: [],
            selectedMultipleYearLevels: [],
            groupedYearLevels: [],
            reportPages,
          });
        }

        this.getYearLevelByAssessment(param);
      }
    );
  };

  /**
   * Handles removal of items from column contents
   * @param col column number. From which column to remove items
   * @param reportIndex index of report pages
   * @param contentIndex index of content to be removed
   */
  handleAssessmentRemoval = (col, reportIndex, contentIndex) => {
    let reportPages = [...this.state.reportPages];
    let reportPage = reportPages[reportIndex];
    if (col == "1") {
      let contents = reportPage.col1Contents;
      this.manageMarksDropdownBdsRemoval(contents, contentIndex);
      this.addAssessmentChipAgain(contents, contentIndex);

      contents.splice(contentIndex, 1);
    } else if (col == "2") {
      let contents = reportPage.col2Contents;
      this.manageMarksDropdownBdsRemoval(contents, contentIndex);
      this.addAssessmentChipAgain(contents, contentIndex);
      contents.splice(contentIndex, 1);
    } else {
      return;
    }
    // let subCount = 0;
    // reportPages.forEach((rp) => {
    //   let rPcol1 = rp.col1Contents;
    //   let rPcol2 = rp.col2Contents;
    //   if (rPcol1.length > 0) {
    //     rPcol1.forEach((el) => {
    //       if (el.customAssessmentHeaderName.includes("SUBJECTS:")) {
    //         subCount++;
    //       }
    //     });
    //   }
    //   if (rPcol2.length > 0) {
    //     let subCount = 0;
    //     rPcol2.forEach((el) => {
    //       if (el.customAssessmentHeaderName.includes("SUBJECTS:")) {
    //         subCount++;
    //       }
    //     });
    //   }
    // });

    // if(subCount===0){
    //   this.setState({showPresetGpaLegend:false})
    // }
    this.setState({ reportPages });
  };

  /**
   * Handles removal of items from column contents
   * @param col column number. From which column to remove items
   * @param reportIndex index of report pages
   * @param fromIndex index from where content to be moved
   * @param toIndex index to where content to be moved
   */
  handleAssessmentReorderBtnClick = (col, reportIndex, fromIndex, toIndex) => {
    let reportPages = [...this.state.reportPages];
    let reportPage = reportPages[reportIndex];
    if (col == "1") {
      let contents = reportPage.col1Contents;
      let contentToMove = contents[fromIndex];
      contents.splice(fromIndex, 1);
      contents.splice(toIndex, 0, contentToMove);
    } else if (col == "2") {
      let contents = reportPage.col2Contents;
      let contentToMove = contents[fromIndex];
      contents.splice(fromIndex, 1);
      contents.splice(toIndex, 0, contentToMove);
    } else {
      return;
    }
    this.setState({ reportPages });
  };

  handleContentFontSize = (e, reportIndex, col, contentIndex) => {
    let reportPages = [...this.state.reportPages];
    let reportPage = reportPages[reportIndex];
    if (col == "1") {
      let contents = reportPage.col1Contents;
      contents[contentIndex].fontSize = e.target.value;
    } else if (col == "2") {
      let contents = reportPage.col2Contents;
      contents[contentIndex].fontSize = e.target.value;
    } else {
      return;
    }
    this.setState({ reportPages });
  };

  /**
   * Content horizontal alignment for Page two
   */
  handleContentHorizontalAlignement = (e, reportIndex, col, contentIndex) => {
    let reportPages = [...this.state.reportPages];
    let reportPage = reportPages[reportIndex];
    if (col == "1") {
      let contents = reportPage.col1Contents;
      contents[contentIndex].horizontalAlign = e.target.value;
    } else if (col == "2") {
      let contents = reportPage.col2Contents;
      contents[contentIndex].horizontalAlign = e.target.value;
    } else {
      return;
    }
    this.setState({ reportPages });
  };

  handleDescriptionContentFontSize = (e, columnIndex, contentIndex) => {
    let descriptionBox = { ...this.state.descriptionBox };
    let columns = descriptionBox.columns;
    let column = columns[columnIndex];
    if (column) {
      column.contents[contentIndex].fontSize = e.target.value;
      this.setState({ descriptionBox });
    } else {
      return;
    }
  };

  handleDescriptionContentHorizontalAlignement = (
    e,
    columnIndex,
    contentIndex
  ) => {
    let descriptionBox = { ...this.state.descriptionBox };
    let columns = descriptionBox.columns;
    let column = columns[columnIndex];
    if (column) {
      column.contents[contentIndex].horizontalAlign = e.target.value;
      this.setState({ descriptionBox });
    } else {
      return;
    }
  };

  handleDescriptionContentRemoval = (columnIndex, contentIndex) => {
    let descriptionBox = { ...this.state.descriptionBox };
    descriptionBox.columns[columnIndex].contents.splice(contentIndex, 1);
    this.setState({ descriptionBox });
  };

  handleDescriptionContentReorderBtnClick = (
    columnIndex,
    fromIndex,
    toIndex
  ) => {
    let descriptionBox = { ...this.state.descriptionBox };
    let columns = descriptionBox.columns;
    let contents = columns[columnIndex].contents;
    let contentToMove = contents[fromIndex];
    contents.splice(fromIndex, 1);
    contents.splice(toIndex, 0, contentToMove);
    this.setState({ descriptionBox });
  };

  handleDescriptionFormDataChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let descriptionBox = { ...this.state.descriptionBox };
    descriptionBox[name] = value;
    this.setState({ descriptionBox });
  };

  handleDescriptionLayoutChange = (e) => {
    let descriptionBox = { ...this.state.descriptionBox },
      descriptionLayout = e.target.value;
    descriptionBox.layout = descriptionLayout;
    switch (descriptionLayout) {
      case "1col":
        descriptionBox.columns = [
          { width: "100%", contents: [], verticalAlign: "middle" },
        ];
        break;
      case "2col":
        descriptionBox.columns = [
          { width: "50%", contents: [], verticalAlign: "middle" },
          { width: "50%", contents: [], verticalAlign: "middle" },
        ];
        break;

      case "2cts":
        descriptionBox.columns = [
          { width: "34%", contents: [], verticalAlign: "middle" },
          { width: "66%", contents: [], verticalAlign: "middle" },
        ];
        break;
      case "2cst":
        descriptionBox.columns = [
          { width: "66%", contents: [], verticalAlign: "middle" },
          { width: "34%", contents: [], verticalAlign: "middle" },
        ];
        break;
      default:
        break;
    }
    this.setState({ descriptionBox, activeDescriptionColumn: 0 });
  };

  handleDescriptionMarginDataChange = (e) => {
    let descriptionBox = this.state.descriptionBox;
    const target = e.target;
    let value = target.value;
    const name = target.name;
    if (descriptionBox["marginSizeUnit"] === "mm") {
      value = target.value < 0 ? 0 : target.value > 40 ? 40 : target.value;
    } else {
      value = target.value < 0 ? 0 : target.value > 2 ? 2 : target.value;
    }
    if (descriptionBox["marginSizeUnit"] === "mm") {
      descriptionBox[name] = parseInt(value);
    } else {
      descriptionBox[name] = parseFloat(value);
    }
    this.setState({ descriptionBox });
  };

  handleDescriptionVerticalAlignement = (e, columnIndex) => {
    let descriptionBox = { ...this.state.descriptionBox };
    let columns = descriptionBox.columns;
    let column = columns[columnIndex];
    if (column) {
      column.verticalAlign = e.target.value;
      this.setState({ descriptionBox });
    } else {
      return;
    }
  };

  handleFormDataChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
    if (name === "paperSize") {
      const pageSize = { ...this.state.pageSize };
      if (value === "lt") {
        pageSize["width"] = 216;
        pageSize["height"] = 279;
      } else if (value === "a4") {
        pageSize["width"] = 210;
        pageSize["height"] = 297;
      }
      if (value === "custom") {
        this.setState({ orientation: "potrait" });
      } else {
        this.setState({
          pageSize,
          orientation: "portrait",
          pageSizeUnit: "mm",
        });
      }
    }
    if (name === "pageSizeUnit") {
      this.changePageSizeUnit(name, value);
      this.setState({ marginSizeUnit: value }, function () {
        let reportPages = [...this.state.reportPages];
        reportPages.forEach((el) => {
          el.marginSizeUnit = value;
          el.pageSizeUnit = value;
        });
        let header = { ...this.state.header };
        let footer = { ...this.state.footer };
        let descriptionBox = { ...this.state.descriptionBox };
        header["heightUnit"] = value;
        header["marginSizeUnit"] = value;
        footer["heightUnit"] = value;
        footer["marginSizeUnit"] = value;
        descriptionBox["heightUnit"] = value;
        descriptionBox["marginSizeUnit"] = value;
        this.setState({ reportPages, header, footer, descriptionBox });
      });
    }
  };

  handleHeaderContentFontSize = (e, columnIndex, contentIndex) => {
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    let columns = header.columns;
    let column = columns[columnIndex];
    if (column) {
      column.contents[contentIndex].fontSize = e.target.value;
      this.state.headerFooter === "header"
        ? this.setState({ header })
        : this.setState({ footer: header });
    } else {
      return;
    }
  };

  handleHeaderContentHorizontalAlignement = (e, columnIndex, contentIndex) => {
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    let columns = header.columns;
    let column = columns[columnIndex];
    if (column) {
      column.contents[contentIndex].horizontalAlign = e.target.value;
      this.state.headerFooter === "header"
        ? this.setState({ header })
        : this.setState({ footer: header });
    } else {
      return;
    }
  };

  handleHeaderContentRemoval = (columnIndex, contentIndex) => {
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    header.columns[columnIndex].contents.splice(contentIndex, 1);
    this.state.headerFooter === "header"
      ? this.setState({ header })
      : this.setState({ footer: header });
  };

  handleHeaderContentReorderBtnClick = (columnIndex, fromIndex, toIndex) => {
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    let columns = header.columns;
    let contents = columns[columnIndex].contents;
    let contentToMove = contents[fromIndex];
    contents.splice(fromIndex, 1);
    contents.splice(toIndex, 0, contentToMove);

    this.state.headerFooter === "header"
      ? this.setState({ header })
      : this.setState({ footer: header });
  };

  handleHeaderFormDataChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    header[name] = value;
    this.state.headerFooter === "header"
      ? this.setState({ header })
      : this.setState({ footer: header });
  };

  handleHeaderIncludeCheckboxChange = (e) => {
    let headerFooter = this.state.headerFooter;
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    let reportPageIndex = parseInt(e.target.value);
    let reportPageChecked = e.target.checked;
    if (reportPageChecked) {
      header.reportPageIndexes.push(reportPageIndex);
    } else {
      header.reportPageIndexes.splice(
        header.reportPageIndexes.indexOf(reportPageIndex),
        1
      );
    }
    headerFooter === "header"
      ? this.setState({ header })
      : this.setState({ footer: header });
  };

  /**
   * handle header layout change
   */
  handleHeaderLayoutChange = (e) => {
    let header =
        this.state.headerFooter === "header"
          ? { ...this.state.header }
          : { ...this.state.footer },
      headerLayout = e.target.value;
    header.layout = headerLayout;
    switch (headerLayout) {
      case "1col":
        header.columns = [{ width: 12, contents: [], verticalAlign: "middle" }];
        break;
      case "2col":
        header.columns = [
          { width: 6, contents: [], verticalAlign: "middle" },
          { width: 6, contents: [], verticalAlign: "middle" },
        ];
        break;
      case "2cte":
        header.columns = [
          { width: 3, contents: [], verticalAlign: "middle" },
          { width: 9, contents: [], verticalAlign: "middle" },
        ];
        break;
      case "3col":
        header.columns = [
          { width: 3, contents: [], verticalAlign: "middle" },
          { width: 6, contents: [], verticalAlign: "middle" },
          { width: 3, contents: [], verticalAlign: "middle" },
        ];
        break;
      default:
        break;
    }
    this.state.headerFooter === "header"
      ? this.setState({ header, activeColumnHeader: 0 })
      : this.setState({ footer: header, activeColumnHeader: 0 });
  };

  handleHeaderMarginFormDataChange = (e) => {
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    const target = e.target;
    let value = target.value;
    if (header["marginSizeUnit"] === "mm") {
      value = target.value < 0 ? 0 : target.value > 40 ? 40 : target.value;
    } else {
      value = target.value < 0 ? 0 : target.value > 2 ? 2 : target.value;
    }
    const name = target.name;
    if (header["marginSizeUnit"] === "mm") {
      header[name] = parseInt(value);
    } else {
      header[name] = parseFloat(value);
    }

    this.state.headerFooter === "header"
      ? this.setState({ header })
      : this.setState({ footer: header });
  };

  handleHeaderVerticalAlignement = (e, columnIndex) => {
    let header =
      this.state.headerFooter === "header"
        ? { ...this.state.header }
        : { ...this.state.footer };
    let columns = header.columns;
    let column = columns[columnIndex];
    if (column) {
      column.verticalAlign = e.target.value;
      this.state.headerFooter === "header"
        ? this.setState({ header })
        : this.setState({ footer: header });
    } else {
      return;
    }
  };

  handleLayoutChange = (e, reportPageIndex) => {
    let reportPages = [...this.state.reportPages];
    let reportPage = reportPages[reportPageIndex],
      reportPageLayout = e.target.value,
      layout = { layout: reportPageLayout },
      prevLayout = reportPage.layout;
    switch (reportPageLayout) {
      case "1col":
        layout.col1Width = "100%";
        layout.col2Width = "0%";
        layout.col1VerticalAlign = prevLayout.col1VerticalAlign
          ? prevLayout.col1VerticalAlign
          : "top";
        layout.col2VerticalAlign = prevLayout.col2VerticalAlign
          ? prevLayout.col2VerticalAlign
          : "top";
        break;
      case "2cff":
        layout.col1Width = "50%";
        layout.col2Width = "50%";
        layout.col1VerticalAlign = prevLayout.col1VerticalAlign
          ? prevLayout.col1VerticalAlign
          : "top";
        layout.col2VerticalAlign = prevLayout.col2VerticalAlign
          ? prevLayout.col2VerticalAlign
          : "top";
        break;
      case "2cts":
        layout.col1Width = "34%";
        layout.col2Width = "66%";
        layout.col1VerticalAlign = prevLayout.col1VerticalAlign
          ? prevLayout.col1VerticalAlign
          : "top";
        layout.col2VerticalAlign = prevLayout.col2VerticalAlign
          ? prevLayout.col2VerticalAlign
          : "top";
        break;
      case "2cst":
        layout.col1Width = "66%";
        layout.col2Width = "34%";
        layout.col1VerticalAlign = prevLayout.col1VerticalAlign
          ? prevLayout.col1VerticalAlign
          : "top";
        layout.col2VerticalAlign = prevLayout.col2VerticalAlign
          ? prevLayout.col2VerticalAlign
          : "top";
        break;
      default:
        break;
    }

    reportPage.layout = layout;

    this.setState({ reportPages, activeColumn: "1" });
  };

  handleOrientationChange = () => {
    let pageSize = { ...this.state.pageSize };
    let height = pageSize.width,
      width = pageSize.height;
    pageSize.height = height;
    pageSize.width = width;
    this.setState({ pageSize });
  };

  handlePageSizeChange = (e) => {
    let pageSizes = [297, 210, 216, 279];
    let target = e.target;
    let value = target.value;
    let name = target.name;
    let pageSize = { ...this.state.pageSize };
    if (this.state.pageSizeUnit === "mm") {
      pageSize[name] = parseInt(value);
    } else {
      pageSize[name] = parseFloat(value);
    }

    if (!pageSizes.includes(target.value)) {
      this.setState({ pageSize, paperSize: "custom" });
    } else {
      let reportPages = [...this.state.reportPages];
      reportPages[0].pageSize[name] = value;
      this.setState({ pageSize, reportPages });
    }
  };

  handleMarginChange = (e) => {
    const target = e.target;
    let value = e.target.value;
    if (this.state.marginSizeUnit === "mm") {
      value = target.value < 0 ? 0 : target.value > 40 ? 40 : target.value;
    } else {
      value = target.value < 0 ? 0 : target.value > 2 ? 2 : target.value;
    }
    const name = target.name;
    let margin = { ...this.state.margin };
    if (this.state.marginSizeUnit === "mm") {
      margin[name] = parseInt(value);
    } else {
      margin[name] = parseFloat(value);
    }

    this.setState({ margin }, function () {
      let reportPages = [...this.state.reportPages];
      reportPages[0].margin = {
        top: this.state.margin.top,
        bottom: this.state.margin.bottom,
        left: this.state.margin.left,
        right: this.state.margin.right,
      };
      this.setState({ reportPages: reportPages });
    });
  };

  handleNextBtnClick = (e) => {
    if (this.state.tabIndex === 0) {
      if (
        this.state.assessmentId !== null &&
        this.state.yearLevelIds.length > 0 &&
        this.state.reportName !== null &&
        this.state.publishingDate !== null &&
        this.state.publisher !== ""
      ) {
        let param = {};
        param.assessmentId = parseInt(this.state.assessmentId.split(",")[0]);
        param.yearLevelIds = this.state.yearLevelIds;
        this.getSubjectAssessmentScaleByAssessmentAndYearLevel(param);
        this.getGeneralAssessmentsByAssessmentAndYearLevel(param);
        this.getLegends(param);
        this.getGeneralComments(param);
        let prevAssessmentParam = {
          id: parseInt(this.state.assessmentId.split(",")[0]),
          yearLevelId: parseInt(this.state.selectedYearLevel),
          educationLevelId: this.state.educationLevelId,
        };
        this.getPreviousAssessments(prevAssessmentParam);
        const tabIndex = ++this.state.tabIndex;
        this.setState({ tabIndex });
      } else {
        let texts = `<p>Fill all the following fields<br><ol><li>Assessment</li><li>${localStorage.getItem(
          "yearLevel"
        )}</li><li>Report Name</li><li>Publishing Date</li><li>Publisher</li></ol></p>`;
        Swal.fire({
          title: "Error",
          html: texts,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
      }
    } else if (this.state.tabIndex === 2) {
      if (!this.state.isSubjectSetupBdsaved) {
        swal(
          "Warning",
          "Please setup the grade format in selected assessment scale.",
          ""
        );
      } else {
        const tabIndex = ++this.state.tabIndex;
        this.setState({ tabIndex });
      }
    } else {
      const tabIndex = ++this.state.tabIndex;
      this.setState({ tabIndex });
    }
    this.setState({ zoom: 36 });
  };

  handlePagesMarginChange = (e, reportPageIndex) => {
    let name = e.target.name;
    let value = 0;
    if (this.state.pageSizeUnit === "mm") {
      value =
        e.target.value < 0 ? 0 : e.target.value > 40 ? 40 : e.target.value;
    } else {
      value = e.target.value < 0 ? 0 : e.target.value > 2 ? 2 : e.target.value;
    }

    let reportPages = [...this.state.reportPages];
    if (this.state.pageSizeUnit === "mm") {
      reportPages[reportPageIndex].margin[name] = parseInt(value);
    } else {
      reportPages[reportPageIndex].margin[name] = parseFloat(value);
    }
    this.setState({ reportPages });
  };

  handlePercentageWeightChange = (e, i) => {
    let previousAssessments = [...this.state.previousAssessments];
    let assessment = previousAssessments[i];
    assessment.percentageWeight = parseInt(e.target.value);
    let totalPercentageWeight = 0;
    let sampleValuePercentage = 0;
    previousAssessments.forEach((assessment) => {
      if (assessment.isCurrentAssessment) {
        assessment.percentageWeight = 100 - totalPercentageWeight;
      }
      totalPercentageWeight += assessment.percentageWeight
        ? assessment.percentageWeight
        : 0;
      sampleValuePercentage += assessment.percentageWeight
        ? (assessment.percentageWeight * assessment.sampleValue) / 100
        : 0;
    });
    if (totalPercentageWeight > 100) {
      swal("Error", "Total percentage cannot exceed 100.");
      assessment.percentageWeight = 0;
    }

    this.setState({ previousAssessments, sampleValuePercentage });
  };

  handlePreviousBtnClick = (e) => {
    const tabIndex = --this.state.tabIndex;
    this.setState({ tabIndex, zoom: 36 });
  };

  handleRemoveReportPageBtnClick = () => {
    let reportPages = [...this.state.reportPages];
    if (reportPages.length > 1) {
      displayConfirmDeleteAlert(null, () => {
        reportPages.splice(this.state.activeReportPageIndex, 1);
        this.setState({ reportPages, activeReportPageIndex: 0 });
      });
    } else {
      swal("Error", "You must have at least one page.", "");
      return;
    }
  };

  handleReportPageVerticalAlignement = (e, reportPageIndex) => {
    let name = e.target.name;
    let value = e.target.value;
    let reportPages = [...this.state.reportPages];
    reportPages[reportPageIndex].layout[name] = value;
    this.setState({ reportPages });
  };

  handleSaveBtnClick = (e) => {
    if (
      this.state.assessmentId == null ||
      this.state.yearLevelIds == [] ||
      this.state.reportName == null ||
      this.state.reportName == "" ||
      this.state.publishingDate == null ||
      this.state.publishingDate == "" ||
      this.state.publisher == ""
    ) {
      let texts = `<p>Fill all the following fields<br><ol><li>Assessment</li><li>${localStorage.getItem(
        "yearLevel"
      )}</li><li>Report Name</li><li>Publishing Date</li><li>Publisher</li></ol></p>`;
      Swal.fire({
        title: "Error",
        html: texts,
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      return;
    }

    let param = {
      name: this.state.reportName,
      publishingDate: this.state.publishingDate,
      recruitmentId: this.state.publisher,
      paperSize: this.state.paperSize,
      pageSizeHeight: this.state.pageSize.height,
      pageSizeWidth: this.state.pageSize.width,
      pageSizeUnit: this.state.pageSizeUnit,
      orientation: this.state.orientation,
      marginTop: this.state.margin.top,
      marginLeft: this.state.margin.left,
      marginRight: this.state.margin.right,
      marginBottom: this.state.margin.bottom,
      marginSizeUnit: this.state.marginSizeUnit,
      assessmentId: this.state.assessmentId
        ? parseInt(this.state.assessmentId.split(",")[0])
        : null,
      yearLevelIds: this.state.yearLevelIds,
    };
    const reportPages = [...this.state.reportPages];
    let reportSetupPages = [];
    reportPages.forEach((reportPage) => {
      let reportSetupPage = {
        layout: reportPage.layout.layout,
        marginTop: reportPage.margin.top,
        marginBottom: reportPage.margin.bottom,
        marginLeft: reportPage.margin.left,
        marginRight: reportPage.margin.right,
        pageOrder: reportPage.pageOrder,
        marginSizeUnit: reportPage.marginSizeUnit,
      };
      // Adding columns

      let reportSetupPageColumn1 = {
        width: reportPage.layout.col1Width,
        verticalAlign: reportPage.layout.col1VerticalAlign,
      };
      // Adding contents inside first column
      let reportSetupPageColumn1Contents = [];
      reportPage.col1Contents.forEach((content, idx) => {
        //Adding assessment general comment and other configuration having same name.
        if (
          content.tableName == dbTable.assessmentGeneralCommentConfiguration ||
          content.tableName == dbTable.generalAssessmentOverallConfiguration ||
          content.tableName == dbTable.generalAssessmentRubricsConfiguration
        ) {
          let reportSetupPageColumnContents = this.addFromOriginalArray(
            content,
            idx
          );
          reportSetupPageColumn1Contents = [
            ...reportSetupPageColumn1Contents,
            ...reportSetupPageColumnContents,
          ];
        } else {
          if (content["updatedData"]) {
            let newContent = {
              uiOrder: idx + 1,
              tableName: content.tableName,
              fieldName: content.fieldName,
              // selectedLegendId: content?.selectedLegendId || null,
              // selectedTableName: content?.selectedTableName || null,
              tableUnId: content.tableUnId,
              customRecord: content.customRecord,
              horizontalAlign:
                content.horizontalAlign && content.horizontalAlign.length > 0
                  ? content.horizontalAlign
                  : "center",
              fontSize: content.fontSize ? content.fontSize : 10,
              customRecord:
                content.customRecord !== null ? content.customRecord : false,
              customText: content.customText,
              mapMultipleAssessmentScale: JSON.stringify(
                content?.mapMultipleAssessmentScale
              ),
            };
            reportSetupPageColumn1Contents.push(newContent);
          } else {
            let columnContent = {
              uiOrder: idx + 1,
              tableName: content.tableName,
              fieldName: content.fieldName,
              // selectedLegendId: content?.selectedLegendId || null,
              // selectedTableName: content?.selectedTableName || null,
              tableUnId: content.id,
              customRecord: content.customRecord,
              horizontalAlign:
                content.horizontalAlign && content.horizontalAlign.length > 0
                  ? content.horizontalAlign
                  : "center",
              fontSize: content.fontSize ? content.fontSize : 10,
              customRecord:
                content.customRecord !== null ? content.customRecord : false,
              customText: content.customText,
              mapMultipleAssessmentScale: JSON.stringify(
                content?.mapMultipleAssessmentScale
              ),
            };
            reportSetupPageColumn1Contents.push(columnContent);
          }
        }
      });
      reportSetupPageColumn1.reportSetupPageColumnContents =
        reportSetupPageColumn1Contents;

      let reportSetupPageColumn2 = {
        width: reportPage.layout.col2Width,
        verticalAlign: reportPage.layout.col2VerticalAlign,
      };
      // Adding contents inside second column
      let reportSetupPageColumn2Contents = [];

      reportPage.col2Contents.forEach((content, idx) => {
        //Adding assessment general comment and other configuration having same name.
        if (
          content.tableName == dbTable.assessmentGeneralCommentConfiguration ||
          content.tableName == dbTable.generalAssessmentOverallConfiguration ||
          content.tableName == dbTable.generalAssessmentRubricsConfiguration
        ) {
          let reportSetupPageColumnContents = this.addFromOriginalArray(
            content,
            idx
          );
          reportSetupPageColumn2Contents = [
            ...reportSetupPageColumn1Contents,
            ...reportSetupPageColumnContents,
          ];
        } else {
          if (content["updatedData"]) {
            let newContent = {
              uiOrder: idx + 1,
              tableName: content.tableName,
              fieldName: content.fieldName,
              // selectedLegendId: content?.selectedLegendId || null,
              // selectedTableName: content?.selectedTableName || null,
              tableUnId: content.tableUnId,
              customRecord: content.customRecord,
              horizontalAlign:
                content.horizontalAlign && content.horizontalAlign.length > 0
                  ? content.horizontalAlign
                  : "center",
              fontSize: content.fontSize ? content.fontSize : 10,
              customRecord:
                content.customRecord !== null ? content.customRecord : false,
              customText: content.customText,
              mapMultipleAssessmentScale: JSON.stringify(
                content?.mapMultipleAssessmentScale
              ),
            };
            reportSetupPageColumn1Contents.push(newContent);
          } else {
            let columnContent = {
              uiOrder: idx + 1,
              tableName: content.tableName,
              fieldName: content.fieldName,
              // selectedLegendId: content?.selectedLegendId || null,
              // selectedTableName: content?.selectedTableName || null,
              tableUnId: content.id,
              customRecord: content.customRecord,
              horizontalAlign:
                content.horizontalAlign && content.horizontalAlign.length > 0
                  ? content.horizontalAlign
                  : "center",
              fontSize: content.fontSize ? content.fontSize : 10,
              customRecord:
                content.customRecord !== null ? content.customRecord : false,
              customText: content.customText,
              mapMultipleAssessmentScale: JSON.stringify(
                content?.mapMultipleAssessmentScale
              ),
            };
            reportSetupPageColumn1Contents.push(columnContent);
          }
        }
      });

      reportSetupPageColumn2.reportSetupPageColumnContents =
        reportSetupPageColumn2Contents;
      let reportSetupPageColumns = [];
      reportSetupPageColumns.push(reportSetupPageColumn1);
      reportSetupPageColumns.push(reportSetupPageColumn2);

      //souvenir case adding this logic Oct 11
      // reportSetupPageColumns.forEach((column) => {
      //   column.reportSetupPageColumnContents.forEach((cc) => {
      //     const scale = cc.mapMultipleAssessmentScale || "";
      //     const parsedScale = JSON.parse(scale);
      //     if (parsedScale && parsedScale !== "" && parsedScale?.length) {
      //       parsedScale.forEach((s) => {
      //         const lookFor = this.state.defaultSubjectScaleConfigGroups.find(
      //           (rspc) => rspc.id === s
      //         );
      //         const data=
      //         if (!lookFor) {
      //           column.reportSetupPageColumnContents.push({
      //             uiOrder: cc.uiOrder,
      //             tableName: cc.tableName,
      //             tableUnId: s,
      //             horizontalAlign: cc.horizontalAlign,
      //             fontSize: cc.fontSize,
      //             mapMultipleAssessmentScale: cc?.mapMultipleAssessmentScale,
      //           });
      //         }
      //       });
      //     }
      //   });
      // });

      // End of adding columns
      reportSetupPage.reportSetupPageColumns = reportSetupPageColumns;
      reportSetupPages.push(reportSetupPage);
    });

    let header = { ...this.state.header },
      footer = { ...this.state.footer },
      headerFooterImages = [];
    header.headerFooter = "header";
    if (header.columns) {
      header.columns.forEach((col, colIdx) => {
        if (col.contents) {
          col.contents.forEach((el) => {
            if (el.type === "IMG") {
              el.colNumber = colIdx;
              el.colType = "header";
              el.fontSize = 0;
              headerFooterImages.push(el);
            }
            if (el.type === "linkImg") {
              delete el.file;
              delete el.height;
              delete el.width;
              delete el.type;
            }
          });
          col.reportSetupHeaderFooterColumnContents = [...col.contents];
          delete col.contents;
          col.reportSetupHeaderFooterColumnContents.forEach((column) => {
            delete column.name;
          });
        }
      });
    }
    header.reportSetupHeaderFooterColumns = header.columns;
    footer.headerFooter = "footer";

    if (footer.columns) {
      footer.columns.forEach((col, colIdx) => {
        if (col.contents) {
          col.contents.forEach((el) => {
            if (el.type === "IMG") {
              el.colNumber = colIdx;
              el.colType = "footer";
              el.fontSize = 0;
              headerFooterImages.push(el);
            }
            if (el.type === "linkImg") {
              delete el.file;
              delete el.height;
              delete el.width;
              delete el.type;
            }
          });
          col.reportSetupHeaderFooterColumnContents = [...col.contents];
          delete col.contents;
          col.reportSetupHeaderFooterColumnContents.forEach((column) => {
            delete column.name;
          });
        }
      });
    }
    footer.reportSetupHeaderFooterColumns = footer.columns;

    let descriptionBox = { ...this.state.descriptionBox };
    // header.headerFooter = "header";
    if (descriptionBox.columns) {
      descriptionBox.columns.forEach((col, colIdx) => {
        if (col.contents) {
          col.reportSetupDescriptionColumnContent = [...col.contents];
          delete col.contents;
          col.reportSetupDescriptionColumnContent.forEach((column) => {
            delete column.name;
          });
        }
      });
    }
    descriptionBox.reportSetupDescriptionColumn = descriptionBox.columns;

    let reportSetupHeaderFooters = [],
      reportSetupDescription = descriptionBox;
    // reportSetupDescription.push(descriptionBox);
    reportSetupHeaderFooters.push(header);
    reportSetupHeaderFooters.push(footer);
    reportSetupHeaderFooters.forEach((reportSetupHeaderFooter) => {
      delete reportSetupHeaderFooter.columns;
    });
    delete reportSetupDescription.columns;
    // reportSetupDescription.forEach((el) => {
    //   delete el.columns;
    // });
    param.reportSetupPages = reportSetupPages;
    param.reportSetupHeaderFooters = reportSetupHeaderFooters;
    param.reportSetupDescription = reportSetupDescription;

    const reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    let dataReportBds = [];
    reportSetupSubjectSettingBds.forEach((el) => {
      if (
        el.assessmentSubjectScaleConfigurationGroupId !== null &&
        el.assessmentScaleId !== null
      ) {
        let colCals = [];
        if (el.reportSetupSubjectSettingColCalcs.length > 0) {
          el.reportSetupSubjectSettingColCalcs.forEach((cc) => {
            colCals.push({
              assessmentId: cc.assessmentId,
              percentageWeight: cc.percentageWeight,
            });
          });
        }
        dataReportBds.push({
          assessmentSubjectScaleConfigurationGroupId:
            el.assessmentSubjectScaleConfigurationGroupId,
          assessmentScaleId: el.assessmentScaleId,
          breakdownTypeCode: el.breakdownTypeCode,
          showScoreAs: el.showScoreAs,
          showTotalFor: el.showTotalFor,
          showGradePoints: el.showGradePoints,
          showGrades: el.showGrades,
          showGpa: el.showGpa,
          showGradeEvaluation: el.showGradeEvaluation,
          showClassAverage: el.showClassAverage,
          showClassHighest: el.showClassHighest,
          highlightIfHighest: el.highlightIfHighest,
          highlightIfBelowMinimum: el.highlightIfBelowMinimum,
          reportSetupSubjectSettingColCalcs: colCals,
          selectedGradeFormatId: el.selectedGradeFormatId,
        });
      }
    });

    // this.state.defaultSubjectScaleConfigGroups.forEach((dsc) => {
    //   const lookFor = dataReportBds.find(
    //     (drb) => drb.assessmentSubjectScaleConfigurationGroupId === dsc.id
    //   );
    //   if (!lookFor) {
    //     const data = { ...rbd };
    //     data.assessmentSubjectScaleConfigurationGroupId = dsc.id;
    //   }
    // });

    param.reportSetupSubjectSettingBds = dataReportBds;
    if (this.props.update) {
      param.id = this.state.id;
    }
    let formData = new FormData();
    let fileDetails = [];
    for (const key of Object.keys(headerFooterImages)) {
      formData.append("files", headerFooterImages[key].file);
      fileDetails.push({
        colNumber: headerFooterImages[key].colNumber,
        horizontalAlign: headerFooterImages[key].horizontalAlign,
        colType: headerFooterImages[key].colType,
        fontSize: headerFooterImages[key].fontSize,
      });
      delete headerFooterImages[key].file;
      delete headerFooterImages[key].type;
      delete headerFooterImages[key].name;
      delete headerFooterImages[key].height;
      delete headerFooterImages[key].width;
    }

    formData.append("fileDetails", JSON.stringify(fileDetails));
    formData.append("jsonData", JSON.stringify(param));
    this.handleSubmit(formData);
  };

  handleSelectedForCustomColCalcCheckboxChange = (e, i) => {
    let assessments = [...this.state.previousAssessments];
    let sampleValueSummation = 0,
      sampleValueAverage = 0,
      totalSelected = 0;
    let assessment = assessments[i];
    assessment.selectedForCustomColCalc = e.target.checked;
    assessments.forEach((assessment) => {
      if (assessment.selectedForCustomColCalc) {
        let sampleValue = assessment.sampleValue;
        sampleValueSummation += sampleValue;
        totalSelected++;
      } else {
        assessment.percentageWeight = 0;
        this.handlePercentageWeightChange(e, i);
      }
    });
    sampleValueAverage = sampleValueSummation / totalSelected;
    this.setState({ assessments, sampleValueAverage, sampleValueSummation });
  };

  handleSubjectAssessmentScaleSettingOptionChange = (e) => {
    const target = e.target;
    const value = target.value.split(",");
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    if (value[2] == scaleTypeCode.mark) {
      reportSetupSubjectSettingBds[
        parseInt(value[3])
      ].assessmentSubjectScaleConfigurationGroupId = parseInt(value[0]);
      reportSetupSubjectSettingBds[parseInt(value[3])].assessmentScaleId =
        parseInt(value[1]);
    }

    this.setState({
      currentSettingGroupId: parseInt(value[0]),
      currentSettingAssessmentScaleId: parseInt(value[1]),
      currentSettingAssessmentTypeCode: value[2],
      selectedReportSetupBdsIndex: parseInt(value[3]),
      reportSetupSubjectSettingBds,
    });
  };

  /**
   * handles changing of subject setting breakdown
   */
  handleSubjectSettingBreakdownChange = (e) => {
    const selectedReportSetupBdsIndex = this.state.selectedReportSetupBdsIndex;
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    let reportSetupSubjectSettingBd =
      reportSetupSubjectSettingBds[selectedReportSetupBdsIndex];

    const target = e.target;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    // if (name === "showScoreAs" && (value === "gpoint" || value === "grade")) {
    // }
    reportSetupSubjectSettingBd[name] = value;
    this.setState({
      reportSetupSubjectSettingBds,
      isSubjectSetupBdsaved: true,
    });
  };

  getAssessmentScaleByCode = () => {
    const data = {
      assessmentScaleTypeCode: scaleTypeCode.gradePoint,
      assessmentModuleCode: this.props.code,
    };
    axiosPost(URL.selectAssessmentScaleByCode, data, (response) => {
      if (response.status === 200) {
        this.setState({
          assessmentScales: response.data.data,
        });
      }
    });
  };

  handleSubjectSettingBtnClick = (e) => {
    if (this.state.currentSettingAssessmentTypeCode == scaleTypeCode.mark) {
      this.toggleSctMarksSubjectSettingModal();
    }
  };

  handleSubmit = (param) => {
    let url = this.props.update ? URL.updateReportSetup : URL.insertReportSetup;
    axiosPost(
      url,
      param,
      (response) => {
        if (response.status === 200) {
          swal("Success", response.data.message, "");
          this.clearState();
          this.props.getReportFormatList(this.props.code);
          // this.getAssessments();
          if (this.props.update) {
            this.props.closeModal();
          }
        } else {
          swal("Error", response.data.message, "");
        }
      },
      (error) => {
        let errorResponse = error.response ? error.response.data : error;
        if (errorResponse.status === 400) {
          //if condition to check spring boot validation errors
          let errorMessage = "";
          if (errorResponse.errors) {
            errorResponse.errors.forEach((error) => {
              errorMessage += `${error.field
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })} ${error.defaultMessage} \n`; //ishan
            });
            swal(errorResponse.error, errorMessage, "");
          } else {
            swal(errorResponse.error, errorResponse.message, "");
          }
        } else {
          swal(
            errorResponse.error || "Network Error",
            errorResponse.message
              ? errorResponse.message
              : "Could Not Connect To The Server.",
            ""
          );
        }
        this.clearState();
        if (this.props.update) {
          this.setUpdateConfiguration();
        } else {
          this.setDefaultConfiguration();
          this.getDefaultRecruitmentId();
        }
      }
    );
  };

  handleYearLevelChage = (e) => {
    let target = e.target,
      name = target.name,
      value = target.type === "checked" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        yearLevelIds: [],
        selectedMultipleYearLevels: [],
      },
      function () {
        let yearLevelIds = [...this.state.yearLevelIds];
        if (!this.props.update) {
          let reportPages = [
            {
              layout: {
                layout: "1col",
                col1Width: "100%",
                col2Width: "0%",
                col1VerticalAlign: "top",
                col2VerticalAlign: "top",
              },

              margin: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10,
              },
              pageSize: {
                height: 297,
                width: 210,
              },
              pageOrder: 1,
              marginSizeUnit: "mm",
              pageSizeUnit: "mm",
              col1Contents: [],
              col2Contents: [],
            },
          ];
          this.setState({ reportPages });
        }
        yearLevelIds.push(value);
        let data = {
          id: parseInt(this.state.assessmentId.split(",")[0]),
          yearLevelId: parseInt(value),
          educationLevelId: this.state.educationLevelId,
        };
        axiosPost(URL.getYearLevelByPreviousAssessment, data, (response) => {
          if (response.status === 200) {
            this.setState({
              groupedYearLevels: response.data.data,
            });
          }
        });
        this.setState({ yearLevelIds });
      }
    );
  };

  handleYearLevelMultipleChange = (e) => {
    let yearLevelIds = [];
    yearLevelIds.push(this.state.selectedYearLevel);
    var selectedValues = [];
    var options = e.target.options;
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
        yearLevelIds.push(options[i].value);
      }
    }
    this.setState({ yearLevelIds, selectedMultipleYearLevels: selectedValues });
  };

  handleZoomIn = () => {
    let zoom = this.state.zoom;

    this.setState({ zoom: zoom + 1 });
  };

  handleZoomOut = () => {
    let zoom = this.state.zoom;
    let zoomOut = zoom - 1;
    if (zoomOut < 0) {
    } else {
      this.setState({ zoom: zoomOut });
    }
  };

  manageDescriptionContentTitle = () => {
    let descriptionBox = { ...this.state.descriptionBox };
    descriptionBox.columns.forEach((el) => {
      if (el.contents.length > 0) {
        el.contents.forEach((cc) => {
          if (cc.tableName === dbTable.reportSetup && cc.fieldName === "name") {
            cc.name = this.state.reportName;
          } else if (
            cc.tableName === dbTable.reportSetup &&
            cc.fieldName === "publishing_date"
          ) {
            cc.name = "Published Date: " + this.state.publishingDate;
          } else if (
            cc.tableName === dbTable.school &&
            cc.fieldName === "name"
          ) {
            const schoolName = this.state.school
              ? this.state.school.name
                ? this.state.school.name
                : "XXXXXXXX"
              : "XXXXXXXXXX";
            cc.name = "School Name: " + schoolName;
          } else if (
            cc.tableName === dbTable.school &&
            cc.fieldName === "address"
          ) {
            const schoolAddress = this.state.school
              ? this.state.school.address
                ? this.state.school.address
                : "XXXXXXXXXXX"
              : "XXXXXXXXXXX";
            cc.name = "School Address: " + schoolAddress;
          } else if (
            cc.tableName === dbTable.educationLevel &&
            cc.fieldName === "name"
          ) {
            cc.name = "Education Level: XXXXXX";
          } else if (
            cc.tableName === dbTable.yearLevel &&
            cc.fieldName === "name"
          ) {
            cc.name = `${localStorage.getItem("yearLevel")}: XXXXXX`;
          } else if (
            cc.tableName === dbTable.classroom &&
            cc.fieldName === "name"
          ) {
            cc.name = `${localStorage.getItem("classroom")}: XXX`;
          } else if (
            cc.tableName === dbTable.admission &&
            cc.fieldName === "name"
          ) {
            cc.name = "Student Name: Xxxxxxxxx Xxxxxx";
          } else if (
            cc.tableName === dbTable.studentClassroom &&
            cc.fieldName === "roll_number"
          ) {
            cc.name = "Roll Number: XX";
          } else if (
            (cc.tableName = dbTable.academicYear && cc.fieldName === "name")
          ) {
            cc.name = "Academic Year: XXXXXXXX";
          } else if (
            cc.tableName === dbTable.admission &&
            cc.fieldName === "photo"
          ) {
            cc.name = "Student Photo";
          }
        });
      }
    });
    this.setState({ descriptionBox });
  };

  manageMarksDropdownBdsRemoval = (contents, contentIndex) => {
    let content = contents[contentIndex];
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    reportSetupSubjectSettingBds.forEach((el) => {
      if (el.assessmentSubjectScaleConfigurationGroupId === content.id) {
        el.assessmentSubjectScaleConfigurationGroupId = null;
        el.assessmentScaleId = null;
      }
    });
    this.setState({ reportSetupSubjectSettingBds });
  };

  savePercentageWeightChange = () => {
    let previousAssessments = [...this.state.previousAssessments];
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    let reportSetupSubjectSettingBd =
      reportSetupSubjectSettingBds[this.state.selectedReportSetupBdsIndex];
    if (reportSetupSubjectSettingBd.breakdownTypeCode !== null) {
      previousAssessments.forEach((el) => {
        if (el.isCurrentAssessment && !el.selectedForCustomColCalc) {
          swal({
            title: "Error",
            text: "Current Assessment should be selected",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          return;
        }
        if (el.selectedForCustomColCalc) {
          let count = 0;
          if (
            reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs
              .length > 0
          ) {
            for (
              let i = 0;
              i <
              reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs
                .length;
              i++
            ) {
              if (
                reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs[i]
                  .assessmentId === el.id
              ) {
                count++;
              }
            }
            if (count === 0) {
              reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs.push(
                {
                  assessmentId: el.id,
                  percentageWeight:
                    reportSetupSubjectSettingBd.breakdownTypeCode ===
                    marksBreakdownTypeCode.downgradeToPercentAndSummation
                      ? el.percentageWeight
                      : null,
                }
              );
            }
          } else {
            reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs.push({
              assessmentId: el.id,
              percentageWeight:
                reportSetupSubjectSettingBd.breakdownTypeCode ===
                marksBreakdownTypeCode.downgradeToPercentAndSummation
                  ? el.percentageWeight
                  : null,
            });
          }
        }
      });
      this.setState({ reportSetupSubjectSettingBds }, function () {
        this.toggleCustomColumnCalculationModal();
      });
    } else {
      swal({
        title: "Error",
        text: "Breakdown Type cannot be null",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    }
  };

  selectAssessmentScaleByGroup = (groupId, action, assessment) => {
    let params = {
      id: groupId,
    };
    axiosPost(URL.selectAssessmentScaleByGroup, params, (response) => {
      if (response.status == 200) {
        let data = response.data.data;
        data.forEach((element) => {
          element.groupId = groupId;
          if (element.assessmentScaleTypeCode === scaleTypeCode.mark) {
            element.BdIndex = this.state.activeSubjectSettingBdIndex;
            let reportSetupSubjectSettingBds = [
              ...this.state.reportSetupSubjectSettingBds,
            ];
            let data = {};
            if (action === "update") {
              let setup = this.props.setup;
              if (setup.reportSetupSubjectSettingBds.length > 0) {
                setup.reportSetupSubjectSettingBds.forEach((el) => {
                  if (
                    el.assessmentSubjectScaleConfigurationGroupId ===
                      element.groupId &&
                    el.assessmentScaleId === element.id
                  ) {
                    data = {
                      assessmentSubjectScaleConfigurationGroupId:
                        el.assessmentSubjectScaleConfigurationGroupId,
                      assessmentScaleId: el.assessmentScaleId,
                      id: el.id,
                      reportSetupId: el.reportSetupId,
                      breakdownTypeCode: el.breakdownTypeCode,
                      showScoreAs: el.showScoreAs,
                      showTotalFor: el.showTotalFor,
                      showGradePoints: el.showGradePoints,
                      showGrades: el.showGrades,
                      showGpa: el.showGpa,
                      showGradeEvaluation: el.showGradeEvaluation,
                      showClassAverage: el.showClassAverage,
                      showClassHighest: el.showClassHighest,
                      highlightIfHighest: el.highlightIfHighest,
                      highlightIfBelowMinimum: el.highlightIfBelowMinimum,
                      reportSetupSubjectSettingColCalcs: [],
                      selectedGradeFormatId: el?.selectedGradeFormatId || null,
                    };
                    if (el.reportSetupSubjectSettingColCalcs.length > 0) {
                      el.reportSetupSubjectSettingColCalcs.forEach((colCal) => {
                        let dataCol = {};
                        dataCol.assessmentId = colCal.assessmentId;
                        dataCol.percentageWeight = colCal.percentageWeight;
                        dataCol.selectedForCustomColCalc = true;
                        data.reportSetupSubjectSettingColCalcs.push(dataCol);
                      });
                    }
                  }
                });
              } else {
                data = {
                  assessmentSubjectScaleConfigurationGroupId: null,
                  assessmentScaleId: null,
                  id: null,
                  reportSetupId: null,
                  breakdownTypeCode: null,
                  showScoreAs: "nopctg",
                  showTotalFor: "nopctg",
                  showGradePoints: true,
                  showGrades: true,
                  showGpa: true,
                  showGradeEvaluation: true,
                  showClassAverage: true,
                  showClassHighest: true,
                  highlightIfHighest: true,
                  highlightIfBelowMinimum: true,
                  reportSetupSubjectSettingColCalcs: [],
                  selectedGradeFormatId: null,
                };
              }
              reportSetupSubjectSettingBds.push(data);
              this.setState({ reportSetupSubjectSettingBds });
            }
            if (!action || action !== "update") {
              data = {
                assessmentSubjectScaleConfigurationGroupId: null,
                assessmentScaleId: null,
                id: null,
                reportSetupId: null,
                breakdownTypeCode: null,
                showScoreAs: "nopctg",
                showTotalFor: "nopctg",
                showGradePoints: true,
                showGrades: true,
                showGpa: true,
                showGradeEvaluation: true,
                showClassAverage: true,
                showClassHighest: true,
                highlightIfHighest: true,
                highlightIfBelowMinimum: true,
                reportSetupSubjectSettingColCalcs: [],
              };
              reportSetupSubjectSettingBds.push(data);
              this.setState({ reportSetupSubjectSettingBds });
            }

            this.setState({
              activeSubjectSettingBdIndex:
                this.state.activeSubjectSettingBdIndex + 1,
            });
          } else {
            element.BdIndex = null;
          }
          element.scales = assessment && assessment.scales;
        });

        let subjectAssessmentScaleSettingOptions = [
          ...this.state.subjectAssessmentScaleSettingOptions,
          ...data,
        ];
        this.setState({ subjectAssessmentScaleSettingOptions });
      }
    });
  };

  setDefaultConfiguration = () => {
    let paperSize = "a4",
      pageSize = {
        height: 297,
        width: 210,
      },
      pageSizeUnit = "mm",
      orientation = "portrait",
      margin = {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
      marginSizeUnit = "mm",
      headerHeight = 30,
      footerHeight = 15,
      reportPages = [
        {
          layout: {
            layout: "1col",
            col1Width: "100%",
            col2Width: "0%",
            col1VerticalAlign: "top",
            col2VerticalAlign: "top",
          },

          margin: {
            top: 10,
            left: 10,
            right: 10,
            bottom: 10,
          },
          pageSize: {
            height: 297,
            width: 210,
          },
          pageOrder: 1,
          marginSizeUnit: "mm",
          pageSizeUnit: "mm",
          col1Contents: [],
          col2Contents: [],
        },
      ],
      contentFontSizes = [6, 7, 8, 9, 10, 11, 12, 13, 14],
      contentAlignments = ["center", "left", "right"],
      descriptionBox = {
        layout: "2col",
        height: 30,
        heightUnit: "mm",
        marginTop: 5,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 5,
        marginSizeUnit: "mm",
        columns: [
          { width: "50%", contents: [], verticalAlign: "middle" },
          { width: "50%", contents: [], verticalAlign: "middle" },
        ],
        // reportPageIndexes: [],
      },
      // page 3
      header = {
        layout: "2cte",
        height: 30,
        heightUnit: "mm",
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        marginSizeUnit: "mm",
        columns: [
          { width: 3, contents: [], verticalAlign: "middle" },
          { width: 9, contents: [], verticalAlign: "middle" },
        ],
        reportPageIndexes: [],
      },
      footer = {
        layout: "1col",
        height: 20,
        heightUnit: "mm",
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        marginSizeUnit: "mm",
        columns: [{ width: 12, contents: [], verticalAlign: "middle" }],
        reportPageIndexes: [],
      };
    this.setState({
      paperSize,
      pageSize,
      pageSizeUnit,
      descriptionBox,
      orientation,
      margin,
      marginSizeUnit,
      headerHeight,
      footerHeight,
      reportPages,
      contentFontSizes,
      contentAlignments,
      header,
      footer,
    });
  };

  setUpdateConfiguration = () => {
    let setup = this.props.setup;
    let assessmentId = `${this.props.assessmentId},${setup.assessmentName},${this.props.educationLevelId}`;
    let contentFontSizes = [6, 7, 8, 9, 10, 11, 12, 13, 14],
      contentAlignments = ["center", "left", "right"];
    this.setState(
      {
        assessmentId: assessmentId,
        paperSize: setup.paperSize,
        reportName: setup.name,
        publishingDate: moment(setup.publishingDate).format("YYYY-MM-DD"),
        publisher: setup.recruitmentId,
        yearLevelIds: [],
        educationLevelId: this.props.educationLevelId,
        contentFontSizes,
        contentAlignments,
        id: setup.id,
      },
      function () {
        let param = {
          assessmentId: parseInt(this.props.assessmentId),
        };
        this.getYearLevelByAssessment(param);
        let selectedMultipleYearLevels = [];
        setup.yearLevelIds.forEach((el, i) => {
          if (i !== 0) {
            selectedMultipleYearLevels.push(el);
          }
        });
        let reportPages = [],
          reportPage = {};
        let pageSize = {
            height: setup.pageSizeHeight,
            width: setup.pageSizeWidth,
          },
          pageSizeUnit = setup.pageSizeUnit,
          orientation = setup.orientation,
          margin = {
            top: setup.marginTop,
            left: setup.marginLeft,
            right: setup.marginRight,
            bottom: setup.marginBottom,
          },
          marginSizeUnit = setup.marginSizeUnit;
        setup.reportSetupPages.forEach((el) => {
          reportPage.layout = {
            layout: el.layout,
            col1Width: el.reportSetupPageColumns[0].width,
            col2Width: el.reportSetupPageColumns[1].width,
            col1VerticalAlign: el.reportSetupPageColumns[0].verticalAlign,
            col2VerticalAlign: el.reportSetupPageColumns[1].verticalAlign,
          };
          reportPage.margin = {
            top: el.marginTop,
            left: el.marginLeft,
            right: el.marginRight,
            bottom: el.marginBottom,
          };
          reportPage.pageSize = {
            height: setup.pageSizeHeight,
            width: setup.pageSizeWidth,
          };
          reportPage.pageOrder = el.pageOrder;
          reportPage.marginSizeUnit = el.marginSizeUnit;
          reportPage.pageSizeUnit = el.marginSizeUnit;
          reportPage.col1Contents = [];
          reportPage.col2Contents = [];
          if (
            el.reportSetupPageColumns[0].reportSetupPageColumnContents.length >
            0
          ) {
            el.reportSetupPageColumns[0].reportSetupPageColumnContents.forEach(
              (cc) => {
                let assessment = {};
                let tableName = cc.tableName;
                assessment.customAssessmentHeaderName =
                  cc.customAssessmentHeaderName;
                assessment.tableName = tableName;
                assessment.fieldName = cc.fieldName;
                assessment.fontSize = cc.fontSize;
                assessment.id = cc.tableUnId;
                assessment.horizontalAlign = cc.horizontalAlign;
                assessment.updatedData = true;
                assessment.tableUnId = cc.tableUnId;
                assessment.uiOrder = cc.uiOrder;
                assessment.customRecord = cc.customRecord;
                assessment.selectedLegendId = cc.selectedGradeFormatId;
                assessment.selectedTableName = cc.selectedTableName;
                if (cc.customRecord) {
                  assessment.customText = cc.customAssessmentHeaderName;
                }
                if (tableName === dbTable.subjectAssessmentConfigurationGroup) {
                  this.selectAssessmentScaleByGroup(cc.tableUnId, "update");
                }
                reportPage.col1Contents.push(assessment);
              }
            );
          }
          if (
            el.reportSetupPageColumns[1].reportSetupPageColumnContents.length >
            0
          ) {
            el.reportSetupPageColumns[1].reportSetupPageColumnContents.forEach(
              (cc) => {
                let assessment = {};
                let tableName = cc.tableName;
                assessment.customAssessmentHeaderName =
                  cc.customAssessmentHeaderName;
                assessment.tableName = tableName;
                assessment.fieldName = cc.fieldName;
                assessment.fontSize = cc.fontSize;
                assessment.id = cc.tableUnId;
                assessment.horizontalAlign = cc.horizontalAlign;
                assessment.updatedData = true;
                assessment.tableUnId = cc.tableUnId;
                assessment.uiOrder = cc.uiOrder;
                assessment.customRecord = cc.customRecord;
                assessment.selectedLegendId = cc.selectedGradeFormatId;
                assessment.selectedTableName = cc.selectedTableName;
                if (cc.customRecord) {
                  assessment.customText = cc.customAssessmentHeaderName;
                }
                if (tableName === dbTable.subjectAssessmentConfigurationGroup) {
                  this.selectAssessmentScaleByGroup(cc.tableUnId, "update");
                }
                reportPage.col2Contents.push(assessment);
              }
            );
          }
          reportPages.push(reportPage);
          reportPage = {};
        });
        this.setState(
          {
            pageSize,
            pageSizeUnit,
            orientation,
            margin,
            reportPages,
            marginSizeUnit,
            selectedYearLevel: setup.yearLevelIds[0],
            yearLevelIds: setup.yearLevelIds,
            selectedMultipleYearLevels,
          },
          function () {
            let data = {
              id: parseInt(this.state.assessmentId.split(",")[0]),
              yearLevelId: parseInt(this.state.selectedYearLevel),
              educationLevelId: this.state.educationLevelId,
            };
            axiosPost(
              URL.getYearLevelByPreviousAssessment,
              data,
              (response) => {
                if (response.status === 200) {
                  this.setState({
                    groupedYearLevels: response.data.data,
                  });
                }
              }
            );
            let header = {},
              footer = {};

            if (setup.reportSetupDescription) {
              let descriptionBox = {
                layout: setup.reportSetupDescription.layout,
                height: setup.reportSetupDescription.height,
                heightUnit: setup.reportSetupDescription.heightUnit,
                marginTop: setup.reportSetupDescription.marginTop,
                marginLeft: setup.reportSetupDescription.marginLeft,
                marginRight: setup.reportSetupDescription.marginRight,
                marginBottom: setup.reportSetupDescription.marginBottom,
                marginSizeUnit: setup.reportSetupDescription.marginSizeUnit,
                columns: [],
              };
              setup.reportSetupDescription.reportSetupDescriptionColumn.forEach(
                (el) => {
                  let column = {
                    width: el.width,
                    verticalAlign: el.verticalAlign,
                    contents: [],
                  };
                  el.reportSetupDescriptionColumnContent.forEach((cc) => {
                    let content = {
                      name: cc.customAssessmentHeaderName,
                      tableName: cc.tableName,
                      fieldName: cc.fieldName,
                      fontSize: cc.fontSize,
                      horizontalAlign: cc.horizontalAlign,
                      tableUnId: cc.tableUnId,
                      uiOrder: cc.uiOrder,
                      prefix: cc.prefix,
                    };
                    if (cc.customRecord) {
                      content.name = cc.customText;
                      content.customText = cc.customText;
                      content.customRecord = cc.customRecord;
                    }
                    column.contents.push(content);
                  });
                  descriptionBox.columns.push(column);
                }
              );
              this.setState({ descriptionBox }, function () {
                this.manageDescriptionContentTitle();
              });
            }
            setup.reportSetupHeaderFooters.forEach((el) => {
              if (el.headerFooter === "header") {
                header = {
                  layout: el.layout,
                  height: el.height,
                  heightUnit: el.heightUnit,
                  marginTop: el.marginTop,
                  marginLeft: el.marginLeft,
                  marginRight: el.marginRight,
                  marginBottom: el.marginBottom,
                  marginSizeUnit: el.marginSizeUnit,
                  columns: [],
                  reportPageIndexes: el.reportPageIndexes,
                };
                el.reportSetupHeaderFooterColumns.forEach((cc) => {
                  let column = {};
                  column.width = parseInt(cc.width);
                  column.verticalAlign = cc.verticalAlign;
                  column.contents = [];
                  cc.reportSetupHeaderFooterColumnContents.forEach((ccc) => {
                    let content = {};
                    content = {
                      name: ccc.customAssessmentHeaderName,
                      tableName: ccc.tableName,
                      fieldName: ccc.fieldName,
                      fontSize: ccc.fontSize,
                      horizontalAlign: ccc.horizontalAlign,
                      tableUnId: ccc.tableUnId,
                      uiOrder: ccc.uiOrder,
                    };
                    if (ccc.customRecord) {
                      content.customText = ccc.customText;
                      content.customRecord = ccc.customRecord;
                    }
                    if (ccc.tableName === "images") {
                      content.file =
                        URL.imageSelectURL + ccc.customAssessmentHeaderName;
                      content.type = "linkImg";
                      content.height = el.height + el.heightUnit;
                      content.width = "auto";
                    }
                    column.contents.push(content);
                  });
                  header.columns.push(column);
                  if (cc.width === 12) {
                    let columnNull = {};
                    columnNull.width = 0;
                    columnNull.verticalAlign = "middle";
                    columnNull.contents = [];
                    header.columns.push(columnNull);
                  }
                });
              } else {
                footer = {
                  layout: el.layout,
                  height: el.height,
                  heightUnit: el.heightUnit,
                  marginTop: el.marginTop,
                  marginLeft: el.marginLeft,
                  marginRight: el.marginRight,
                  marginBottom: el.marginBottom,
                  marginSizeUnit: el.marginSizeUnit,
                  columns: [],
                  reportPageIndexes: el.reportPageIndexes,
                };
                el.reportSetupHeaderFooterColumns.forEach((cc) => {
                  let column = {};
                  column.width = parseInt(cc.width);
                  column.verticalAlign = cc.verticalAlign;
                  column.contents = [];
                  cc.reportSetupHeaderFooterColumnContents.forEach((ccc) => {
                    let content = {};
                    content = {
                      name: ccc.customAssessmentHeaderName,
                      tableName: ccc.tableName,
                      fieldName: ccc.fieldName,
                      fontSize: ccc.fontSize,
                      horizontalAlign: ccc.horizontalAlign,
                      tableUnId: ccc.tableUnId,
                      uiOrder: ccc.uiOrder,
                    };
                    if (ccc.customRecord) {
                      content.customText = ccc.customText;
                      content.customRecord = ccc.customRecord;
                    }
                    if (ccc.tableName === "images") {
                      content.file =
                        URL.imageSelectURL + ccc.customAssessmentHeaderName;
                      content.type = "linkImg";
                      content.height = el.height + el.heightUnit;
                      content.width = "auto";
                    }
                    column.contents.push(content);
                  });
                  footer.columns.push(column);
                  if (cc.width === 12) {
                    let columnNull = {};
                    columnNull.width = 0;
                    columnNull.verticalAlign = "middle";
                    columnNull.contents = [];
                    footer.columns.push(columnNull);
                  }
                });
              }
            });
            this.setState({ header, footer });
          }
        );
      }
    );
  };

  /**
   * Handles opening and closing of subject setting
   */
  toggleCustomColumnCalculationModal = () => {
    this.setState((prevState) => ({
      customColumnCalculationModal: !prevState.customColumnCalculationModal,
    }));
  };

  /**
   * Handles opening and closing of subject setting
   */
  toggleSctMarksSubjectSettingModal = () => {
    this.setState((prevState) => ({
      sctMarksSubjectSettingModal: !prevState.sctMarksSubjectSettingModal,
    }));
  };

  updatePercentageWeightChange = () => {
    let reportSetupSubjectSettingBds = [
      ...this.state.reportSetupSubjectSettingBds,
    ];
    let reportSetupSubjectSettingBd =
      reportSetupSubjectSettingBds[this.state.selectedReportSetupBdsIndex];
    reportSetupSubjectSettingBd.reportSetupSubjectSettingColCalcs = [];
    this.setState({ reportSetupSubjectSettingBds }, function () {
      this.savePercentageWeightChange();
    });
  };

  render() {
    return this.state.render ? (
      <>
        {this.state.isAvailable ? (
          <div
            className="tt-widgetContent-tab-holder"
            style={
              this.props.update ? { height: "86vh" } : { height: "79.5vh" }
            }
          >
            <div className="tt-group-header">
              {this.props.update ? "Update" : "Create"} Report Format
              {checkWidgetAdminOrNot(this.props.name) === true ? (
                <button
                  className="tt-button tt-button-primary float-right permissionBtnCSS"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
            <div
              className="tt-newListBox container-fluid"
              style={this.props.update ? { height: "75.6vh" } : {}}
            >
              <Tabs
                selectedIndex={this.state.tabIndex}
                onSelect={(tabIndex) => this.setState({ tabIndex })}
              >
                <TabList style={{ display: "none" }}>
                  <Tab>Step 1 of 4: Setup</Tab>
                  <Tab>Step 2 of 4: Description Box</Tab>
                  <Tab>Step 3 of 4: Report Page</Tab>
                  <Tab>Step 4 of 4: Extras</Tab>
                </TabList>

                <TabPanel>
                  <ReportingConfigurationPageSetup
                    assessmentId={this.state.assessmentId}
                    assessments={this.state.assessments}
                    footerHeight={this.state.footerHeight}
                    groupedYearLevels={this.state.groupedYearLevels}
                    handleAssessmentChange={this.handleAssessmentChange}
                    handleFormDataChange={this.handleFormDataChange}
                    handleMarginChange={this.handleMarginChange}
                    handleNextBtnClick={this.handleNextBtnClick}
                    handleOrientationChange={this.handleOrientationChange}
                    handlePageSizeChange={this.handlePageSizeChange}
                    handleSaveBtnClick={this.handleSaveBtnClick}
                    handleYearLevelChage={this.handleYearLevelChage}
                    handleYearLevelMultipleChange={
                      this.handleYearLevelMultipleChange
                    }
                    handleZoomIn={this.handleZoomIn}
                    handleZoomOut={this.handleZoomOut}
                    headerHeight={this.state.headerHeight}
                    margin={this.state.margin}
                    marginSizeUnit={this.state.marginSizeUnit}
                    orientation={this.state.orientation}
                    pageSize={this.state.pageSize}
                    pageSizeUnit={this.state.pageSizeUnit}
                    paperSize={this.state.paperSize}
                    publisher={this.state.publisher}
                    publishingDate={this.state.publishingDate}
                    reportName={this.state.reportName}
                    selectedMultipleYearLevels={
                      this.state.selectedMultipleYearLevels
                    }
                    selectedYearLevel={this.state.selectedYearLevel}
                    staffList={this.state.staffList}
                    yearLevels={this.state.yearLevels}
                    zoom={this.state.zoom}
                  />
                </TabPanel>
                <TabPanel>
                  <ReportingConfigurationDescriptionBox
                    activeDescriptionColumn={this.state.activeDescriptionColumn}
                    contentAlignments={this.state.contentAlignments}
                    contentFontSizes={this.state.contentFontSizes}
                    descriptionBox={this.state.descriptionBox}
                    descriptionCustomText={this.state.descriptionCustomText}
                    footerHeight={this.state.footerHeight}
                    handleAddCustomRowInDescriptionBtnClick={
                      this.handleAddCustomRowInDescriptionBtnClick
                    }
                    handleAddRowDescriptionChipClick={
                      this.handleAddRowDescriptionChipClick
                    }
                    handleDescriptionContentFontSize={
                      this.handleDescriptionContentFontSize
                    }
                    handleDescriptionContentHorizontalAlignement={
                      this.handleDescriptionContentHorizontalAlignement
                    }
                    handleDescriptionContentRemoval={
                      this.handleDescriptionContentRemoval
                    }
                    handleDescriptionContentReorderBtnClick={
                      this.handleDescriptionContentReorderBtnClick
                    }
                    handleDescriptionFormDataChange={
                      this.handleDescriptionFormDataChange
                    }
                    handleDescriptionLayoutChange={
                      this.handleDescriptionLayoutChange
                    }
                    handleDescriptionMarginDataChange={
                      this.handleDescriptionMarginDataChange
                    }
                    handleDescriptionVerticalAlignement={
                      this.handleDescriptionVerticalAlignement
                    }
                    handleFormDataChange={this.handleFormDataChange}
                    handleNextBtnClick={this.handleNextBtnClick}
                    handlePreviousBtnClick={this.handlePreviousBtnClick}
                    handleSaveBtnClick={this.handleSaveBtnClick}
                    handleZoomIn={this.handleZoomIn}
                    handleZoomOut={this.handleZoomOut}
                    headerHeight={this.state.headerHeight}
                    margin={this.state.margin}
                    marginSizeUnit={this.state.marginSizeUnit}
                    pageSize={this.state.pageSize}
                    pageSizeUnit={this.state.pageSizeUnit}
                    zoom={this.state.zoom}
                  />
                </TabPanel>
                <TabPanel>
                  <ReportingConfigurationPageContent
                    activeColumn={this.state.activeColumn}
                    activeReportPageIndex={this.state.activeReportPageIndex}
                    assessmentGeneralCustomAssessments={
                      this.state.assessmentGeneralCustomAssessments
                    }
                    assessmentSubjectScaleConfigGroups={
                      this.state.assessmentSubjectScaleConfigGroups
                    }
                    commentsChip={this.state.commentsChip}
                    contentAlignments={this.state.contentAlignments}
                    contentFontSizes={this.state.contentFontSizes}
                    customText={this.state.customText}
                    descriptionBox={this.state.descriptionBox}
                    footerHeight={this.state.footerHeight}
                    handleAddAssessmentChipClick={
                      this.handleAddAssessmentChipClick
                    }
                    handleAddReportPageBtnClick={
                      this.handleAddReportPageBtnClick
                    }
                    handleAddRowBtnClick={this.handleAddRowBtnClick}
                    handleAddRowChipClick={this.handleAddRowChipClick}
                    handleAssessmentRemoval={this.handleAssessmentRemoval}
                    handleAssessmentReorderBtnClick={
                      this.handleAssessmentReorderBtnClick
                    }
                    handleContentFontSize={this.handleContentFontSize}
                    handleContentHorizontalAlignement={
                      this.handleContentHorizontalAlignement
                    }
                    handleFormDataChange={this.handleFormDataChange}
                    handleLayoutChange={this.handleLayoutChange}
                    handleNextBtnClick={this.handleNextBtnClick}
                    handlePagesMarginChange={this.handlePagesMarginChange}
                    handlePreviousBtnClick={this.handlePreviousBtnClick}
                    handleRemoveReportPageBtnClick={
                      this.handleRemoveReportPageBtnClick
                    }
                    handleReportPageVerticalAlignement={
                      this.handleReportPageVerticalAlignement
                    }
                    handleSaveBtnClick={this.handleSaveBtnClick}
                    handleSubjectAssessmentScaleSettingOptionChange={
                      this.handleSubjectAssessmentScaleSettingOptionChange
                    }
                    handleSubjectSettingBtnClick={
                      this.handleSubjectSettingBtnClick
                    }
                    handleZoomIn={this.handleZoomIn}
                    handleZoomOut={this.handleZoomOut}
                    headerHeight={this.state.headerHeight}
                    margin={this.state.margin}
                    marginSizeUnit={this.state.marginSizeUnit}
                    legends={this.state.legends}
                    pageSize={this.state.pageSize}
                    pageSizeUnit={this.state.pageSizeUnit}
                    reportPages={this.state.reportPages}
                    reportSetupPageColumn1VerticalAlign={
                      this.state.reportSetupPageColumn1VerticalAlign
                    }
                    reportSetupPageColumn2VerticalAlign={
                      this.state.reportSetupPageColumn2VerticalAlign
                    }
                    reportSetupSubjectSettingBds={
                      this.state.reportSetupSubjectSettingBds
                    }
                    showPresetGpaLegend={this.state.showPresetGpaLegend}
                    subjectAssessmentScales={this.state.subjectAssessmentScales}
                    subjectAssessmentScaleSettingOptions={
                      this.state.subjectAssessmentScaleSettingOptions
                    }
                    zoom={this.state.zoom}
                  />
                </TabPanel>
                <TabPanel>
                  <ReportingConfigurationPageMiscellaneous
                    activeColumnHeader={this.state.activeColumnHeader}
                    contentAlignments={this.state.contentAlignments}
                    contentFontSizes={this.state.contentFontSizes}
                    footerHeight={this.state.footerHeight}
                    handleAddCustomRowInHeaderBtnClick={
                      this.handleAddCustomRowInHeaderBtnClick
                    }
                    handleAddImageInHeader={this.handleAddImageInHeader}
                    handleAddRowInHeaderChipClick={
                      this.handleAddRowInHeaderChipClick
                    }
                    handleFormDataChange={this.handleFormDataChange}
                    handleHeaderContentFontSize={
                      this.handleHeaderContentFontSize
                    }
                    handleHeaderContentHorizontalAlignement={
                      this.handleHeaderContentHorizontalAlignement
                    }
                    handleHeaderContentRemoval={this.handleHeaderContentRemoval}
                    handleHeaderContentReorderBtnClick={
                      this.handleHeaderContentReorderBtnClick
                    }
                    handleHeaderFormDataChange={this.handleHeaderFormDataChange}
                    handleHeaderIncludeCheckboxChange={
                      this.handleHeaderIncludeCheckboxChange
                    }
                    handleHeaderLayoutChange={this.handleHeaderLayoutChange}
                    handleHeaderMarginFormDataChange={
                      this.handleHeaderMarginFormDataChange
                    }
                    handleHeaderVerticalAlignement={
                      this.handleHeaderVerticalAlignement
                    }
                    handlePreviousBtnClick={this.handlePreviousBtnClick}
                    handleSaveBtnClick={this.handleSaveBtnClick}
                    handleZoomIn={this.handleZoomIn}
                    handleZoomOut={this.handleZoomOut}
                    header={
                      this.state.headerFooter === "header"
                        ? this.state.header
                        : this.state.footer
                    }
                    headerCustomText={this.state.headerCustomText}
                    headerFooter={this.state.headerFooter}
                    headerHeight={this.state.headerHeight}
                    margin={this.state.margin}
                    marginSizeUnit={this.state.marginSizeUnit}
                    pageSize={this.state.pageSize}
                    pageSizeUnit={this.state.pageSizeUnit}
                    reportPages={this.state.reportPages}
                    zoom={this.state.zoom}
                  />
                </TabPanel>
              </Tabs>
              {/* Subject setting modal */}
              <ModalWindow
                fullWidth={true}
                modal={this.state.sctMarksSubjectSettingModal}
                toggleModal={this.closeSctMarksSubjectSettingModal}
                modalHeader="Examination Marks Setting"
                modalBody={
                  <ReportingConfigurationSubjectSetting
                    handleAddPrevAssmtScoreBtnClick={
                      this.handleAddPrevAssmtScoreBtnClick
                    }
                    handleSubjectSettingBreakdownChange={
                      this.handleSubjectSettingBreakdownChange
                    }
                    previousAssessments={this.state.previousAssessments}
                    reportSetupSubjectSettingBd={
                      this.state.reportSetupSubjectSettingBds
                        ? this.state.reportSetupSubjectSettingBds[
                            this.state.selectedReportSetupBdsIndex
                          ]
                        : null
                    }
                    toggleSctMarksSubjectSettingModal={
                      this.toggleSctMarksSubjectSettingModal
                    }
                    assessmentScales={this.state.assessmentScales}
                  />
                }
              />
              {/* Custom column calculations modal */}
              <ModalWindow
                fullWidth={true}
                modal={this.state.customColumnCalculationModal}
                toggleModal={this.toggleCustomColumnCalculationModal}
                modalHeader="Custom Column Calculation"
                modalBody={
                  <ReportingConfigurationCustomColumnCalculation
                    reportSetupSubjectSettingBd={
                      this.state.reportSetupSubjectSettingBds
                        ? this.state.reportSetupSubjectSettingBds[
                            this.state.selectedReportSetupBdsIndex
                          ]
                        : null
                    }
                    previousAssessments={this.state.previousAssessments}
                    sampleValueSummation={this.state.sampleValueSummation}
                    sampleValueAverage={this.state.sampleValueAverage}
                    sampleValuePercentage={this.state.sampleValuePercentage}
                    handleSubjectSettingBreakdownChange={
                      this.handleSubjectSettingBreakdownChange
                    }
                    handleSelectedForCustomColCalcCheckboxChange={
                      this.handleSelectedForCustomColCalcCheckboxChange
                    }
                    handlePercentageWeightChange={
                      this.handlePercentageWeightChange
                    }
                  />
                }
                modalFooter={this.customCalculationFooter()}
              />
            </div>
            <ModalWindow
              modal={this.state.permissionModal}
              size="lg"
              id="tt-permissionModal"
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              modalBody={
                <GranularPermissionModal
                  widgetName={this.props.name}
                  moduleName="Reporting Setup"
                  header="Create Report Format"
                  activityName="insert-report-format"
                />
              }
            ></ModalWindow>
          </div>
        ) : (
          <div
            className="tt-widgetContent-tab-holder"
            style={
              this.props.update ? { height: "87vh" } : { height: "79.5vh" }
            }
          >
            {this.state.assignEducationlevel ? (
              <NoteDisplay textContent="To create report format , a recording of assessment must be started. "></NoteDisplay>
            ) : (
              <NoteDisplay
                textContent={
                  "Please assign education level to " +
                  this.props.name +
                  " first. Then create report format"
                }
              ></NoteDisplay>
            )}
          </div>
        )}
      </>
    ) : (
      <div
        className="tt-widgetContent-tab-holder"
        style={this.props.update ? { height: "86vh" } : { height: "79.5vh" }}
      ></div>
    );
  }
}
const mapActionToProps = {
  getReportFormatList,
};

const mapStateToProps = (state, props) => ({});

export default connect(
  mapStateToProps,
  mapActionToProps
)(ReportingConfiguration);
