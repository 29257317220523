import React, { Component } from "react";
import moment from "moment";

class SubjectListTableRow extends Component {
  render() {
    return (
      // <React.Fragment>
      //   <tr
      //     key={this.props.unKey}
      //     id={`${this.props.idx}${this.props.classroomId}`}
      //   >
      //     <td
      //       style={{ verticalAlign: "middle" }}
      //       rowSpan={
      //         this.props.subject.hasPractical && this.props.subject.hasTheory
      //           ? 2
      //           : 1
      //       }
      //     >
      //       <input
      //         type="checkbox"
      //         name="selectCheckbox"
      //         checked={this.props.subject.isChecked}
      //         onClick={(e) =>
      //           this.props.handleSelectRowCheckBoxClick(
      //             e,
      //             this.props.subject.subjectId,
      //             this.props.classroomId,
      //             this.props.yearLevelIndex,
      //             this.props.classroomIndex,
      //             this.props.subjectIndex
      //           )
      //         }
      //       />
      //     </td>

      //     <td
      //       style={{ verticalAlign: "middle" }}
      //       rowSpan={
      //         this.props.subject.hasPractical && this.props.subject.hasTheory
      //           ? 2
      //           : 1
      //       }
      //     >
      //       {this.props.subject.subjectName}
      //     </td>

      //     {this.props.subject.hasTheory ? (
      //       <>
      //         <td rowSpan={1}>Theory</td>

      //         <td style={{ display: "flex", alignItems: "center" }} rowSpan={1}>
      //           <input
      //             type="datetime-local"
      //             name="assessmentDateFromTheory"
      //             value={this.props.subject.assessmentFromDateTheory}
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "assessmentFromDateTheory",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={this.props.minStartDate}
      //             max={this.props.maxEndDate}
      //             // onKeyDown={(e) => e.preventDefault()}
      //           />
      //           <span style={{ marginLeft: "5px", marginRight: "5px" }}>-</span>
      //           <input
      //             type="datetime-local"
      //             name="assessmentToDateTheory"
      //             value={this.props.subject.assessmentToDateTheory}
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "assessmentToDateTheory",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={this.props.subject.assessmentFromDateTheory}
      //             max={this.props.maxEndDate}
      //             // onKeyDown={(e) => e.preventDefault()}
      //           />
      //         </td>

      //         <td rowSpan={1}>
      //           <input
      //             type="date"
      //             name="recordingDateTheory"
      //             rowSpan={1}
      //             className="form-control"
      //             value={
      //               this.props.subject.recordingDateTheory
      //                 ? this.props.subject.recordingDateTheory
      //                 : ""
      //             }
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "recordingDateTheory",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={moment(new Date()).format("YYYY-MM-DD")}
      //           />
      //         </td>

      //         <td rowSpan={1}>
      //           <input
      //             type="date"
      //             className="form-control"
      //             name="recordingDeadlineTheory"
      //             rowSpan={1}
      //             value={
      //               this.props.subject.recordingDeadlineTheory
      //                 ? this.props.subject.recordingDeadlineTheory
      //                 : ""
      //             }
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "recordingDeadlineTheory",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={this.props.subject.recordingDateTheory}
      //             disabled={
      //               this.props.subject.recordingDateTheory &&
      //               this.props.subject.recordingDateTheory !== ""
      //                 ? false
      //                 : true
      //             }
      //           />
      //         </td>
      //       </>
      //     ) : this.props.subject.hasPractical ? (
      //       <>
      //         <td rowSpan={1}>Practical</td>
      //         <td style={{ display: "flex", alignItems: "center" }} rowSpan={1}>
      //           <input
      //             type="datetime-local"
      //             name="assessmentFromDatePractical"
      //             value={this.props.subject.assessmentFromDatePractical}
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "assessmentFromDatePractical",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={this.props.minStartDate}
      //             max={this.props.maxEndDate}
      //             // onKeyDown={(e) => e.preventDefault()}
      //           />
      //           <span style={{ marginLeft: "5px", marginRight: "5px" }}>-</span>
      //           <input
      //             type="datetime-local"
      //             name="assessmentToDatePractical"
      //             value={this.props.subject.assessmentToDatePractical}
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "assessmentToDatePractical",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={this.props.subject.assessmentFromDatePractical}
      //             max={this.props.maxEndDate}
      //             // onKeyDown={(e) => e.preventDefault()}
      //           />
      //         </td>
      //         <td rowSpan={1}>
      //           <input
      //             type="date"
      //             rowSpan={2}
      //             name="recordingDatePractical"
      //             className="form-control"
      //             value={
      //               this.props.subject.recordingDatePractical
      //                 ? this.props.subject.recordingDatePractical
      //                 : ""
      //             }
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "recordingDatePractical",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={moment(new Date()).format("YYYY-MM-DD")}
      //           />
      //         </td>

      //         <td rowSpan={1}>
      //           <input
      //             type="date"
      //             rowSpan={2}
      //             className="form-control"
      //             name="recordingDeadlinePractical"
      //             value={
      //               this.props.subject.recordingDeadlinePractical
      //                 ? this.props.subject.recordingDeadlinePractical
      //                 : ""
      //             }
      //             onChange={(e) =>
      //               this.props.handleChange(
      //                 e,
      //                 this.props.idx,
      //                 this.props.yearLevel,
      //                 this.props.classroomId,
      //                 "recordingDeadlinePractical",
      //                 this.props.yearLevelIndex,
      //                 this.props.classroomIndex,
      //                 this.props.subjectIndex
      //               )
      //             }
      //             min={this.props.subject.recordingDatePractical}
      //             disabled={
      //               this.props.subject.recordingDatePractical &&
      //               this.props.subject.recordingDatePractical !== ""
      //                 ? false
      //                 : true
      //             }
      //           />
      //         </td>
      //       </>
      //     ) : null}
      //   </tr>

      //   {this.props.subject.hasPractical && this.props.subject.hasTheory ? (
      //     <tr>
      //       {this.props.subject.hasPractical !== null &&
      //       this.props.subject.hasPractical ? (
      //         <>
      //           <td rowSpan={1}>Practical</td>
      //           <td
      //             style={{ display: "flex", alignItems: "center" }}
      //             rowSpan={1}
      //           >
      //             <input
      //               type="datetime-local"
      //               name="assessmentFromDatePractical"
      //               value={this.props.subject.assessmentFromDatePractical}
      //               onChange={(e) =>
      //                 this.props.handleChange(
      //                   e,
      //                   this.props.idx,
      //                   this.props.yearLevel,
      //                   this.props.classroomId,
      //                   "assessmentFromDatePractical",
      //                   this.props.yearLevelIndex,
      //                   this.props.classroomIndex,
      //                   this.props.subjectIndex
      //                 )
      //               }
      //               min={this.props.minStartDate}
      //               max={this.props.maxEndDate}
      //               // onKeyDown={(e) => e.preventDefault()}
      //             />
      //             <span style={{ marginLeft: "5px", marginRight: "5px" }}>
      //               -
      //             </span>
      //             <input
      //               type="datetime-local"
      //               name="assessmentToDatePractical"
      //               value={this.props.subject.assessmentToDatePractical}
      //               onChange={(e) =>
      //                 this.props.handleChange(
      //                   e,
      //                   this.props.idx,
      //                   this.props.yearLevel,
      //                   this.props.classroomId,
      //                   "assessmentToDatePractical",
      //                   this.props.yearLevelIndex,
      //                   this.props.classroomIndex,
      //                   this.props.subjectIndex
      //                 )
      //               }
      //               min={this.props.subject.assessmentFromDatePractical}
      //               max={this.props.maxEndDate}
      //               // onKeyDown={(e) => e.preventDefault()}
      //             />
      //           </td>
      //           <td rowSpan={1}>
      //             <input
      //               type="date"
      //               rowSpan={2}
      //               name="recordingDatePractical"
      //               className="form-control"
      //               value={
      //                 this.props.subject.recordingDatePractical
      //                   ? this.props.subject.recordingDatePractical
      //                   : ""
      //               }
      //               onChange={(e) =>
      //                 this.props.handleChange(
      //                   e,
      //                   this.props.idx,
      //                   this.props.yearLevel,
      //                   this.props.classroomId,
      //                   "recordingDatePractical",
      //                   this.props.yearLevelIndex,
      //                   this.props.classroomIndex,
      //                   this.props.subjectIndex
      //                 )
      //               }
      //               min={moment(new Date()).format("YYYY-MM-DD")}
      //             />
      //           </td>

      //           <td rowSpan={1}>
      //             <input
      //               type="date"
      //               rowSpan={2}
      //               className="form-control"
      //               name="recordingDeadlinePractical"
      //               value={
      //                 this.props.subject.recordingDeadlinePractical
      //                   ? this.props.subject.recordingDeadlinePractical
      //                   : ""
      //               }
      //               onChange={(e) =>
      //                 this.props.handleChange(
      //                   e,
      //                   this.props.idx,
      //                   this.props.yearLevel,
      //                   this.props.classroomId,
      //                   "recordingDeadlinePractical",
      //                   this.props.yearLevelIndex,
      //                   this.props.classroomIndex,
      //                   this.props.subjectIndex
      //                 )
      //               }
      //               min={this.props.subject.recordingDatePractical}
      //               disabled={
      //                 this.props.subject.recordingDatePractical &&
      //                 this.props.subject.recordingDatePractical !== ""
      //                   ? false
      //                   : true
      //               }
      //             />
      //           </td>
      //         </>
      //       ) : null}
      //     </tr>
      //   ) : null}
      // </React.Fragment>
      <React.Fragment>
        <tr
          key={this.props.unKey}
          id={`${this.props.idx}${this.props.classroomId}`}
        >
          <td
            style={{ verticalAlign: "middle" }}
            rowSpan={
              this.props.subject.hasPractical && this.props.subject.hasTheory
                ? 2
                : 1
            }
          >
            <input
              type="checkbox"
              name="selectCheckbox"
              checked={this.props.subject.isChecked}
              onClick={(e) =>
                this.props.handleSelectRowCheckBoxClick(
                  e,
                  this.props.subject.subjectId,
                  this.props.classroomId,
                  this.props.yearLevelIndex,
                  this.props.classroomIndex,
                  this.props.subjectIndex
                )
              }
            />
          </td>

          <td
            style={{ verticalAlign: "middle" }}
            rowSpan={
              this.props.subject.hasPractical && this.props.subject.hasTheory
                ? 2
                : 1
            }
          >
            {this.props.subject.subjectName}
          </td>

          {this.props.subject.hasTheory ? (
            <>
              <td rowSpan={1}>Theory</td>

              <td style={{ display: "flex", alignItems: "center" }} rowSpan={1}>
                <input
                  type="datetime-local"
                  name="assessmentDateFromTheory"
                  value={this.props.subject.assessmentFromDateTheory}
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "assessmentFromDateTheory",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  min={this.props.minStartDate}
                  max={
                    this.props.subject.assessmentToDateTheory !== ""
                      ? this.props.subject.assessmentToDateTheory
                      : this.props.maxEndDate
                  }
                  // onKeyDown={(e) => e.preventDefault()}
                />
                <span style={{ marginLeft: "5px", marginRight: "5px" }}>-</span>
                <input
                  type="datetime-local"
                  name="assessmentToDateTheory"
                  value={this.props.subject.assessmentToDateTheory}
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "assessmentToDateTheory",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  disabled={
                    this.props.subject.assessmentFromDateTheory &&
                    this.props.subject.assessmentFromDateTheory !== ""
                      ? false
                      : true
                  }
                  min={this.props.subject.assessmentFromDateTheory}
                  max={this.props.maxEndDate}
                  // onKeyDown={(e) => e.preventDefault()}
                />
              </td>

              <td rowSpan={1}>
                <input
                  type="date"
                  name="recordingDateTheory"
                  rowSpan={1}
                  className="form-control"
                  value={
                    this.props.subject.recordingDateTheory
                      ? this.props.subject.recordingDateTheory
                      : ""
                  }
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "recordingDateTheory",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  min={moment(new Date()).format("YYYY-MM-DD")}
                />
              </td>

              <td rowSpan={1}>
                <input
                  type="date"
                  className="form-control"
                  name="recordingDeadlineTheory"
                  rowSpan={1}
                  value={
                    this.props.subject.recordingDeadlineTheory
                      ? this.props.subject.recordingDeadlineTheory
                      : ""
                  }
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "recordingDeadlineTheory",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  min={this.props.subject.recordingDateTheory}
                  disabled={
                    this.props.subject.recordingDateTheory &&
                    this.props.subject.recordingDateTheory !== ""
                      ? false
                      : true
                  }
                />
              </td>
            </>
          ) : this.props.subject.hasPractical ? (
            <>
              <td rowSpan={1}>Practical</td>
              <td style={{ display: "flex", alignItems: "center" }} rowSpan={1}>
                <input
                  type="datetime-local"
                  name="assessmentFromDatePractical"
                  value={this.props.subject.assessmentFromDatePractical}
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "assessmentFromDatePractical",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  min={this.props.minStartDate}
                  max={
                    this.props.subject.assessmentToDatePractical !== ""
                      ? this.props.subject.assessmentToDatePractical
                      : this.props.maxEndDate
                  }
                  // onKeyDown={(e) => e.preventDefault()}
                />
                <span style={{ marginLeft: "5px", marginRight: "5px" }}>-</span>
                <input
                  type="datetime-local"
                  name="assessmentToDatePractical"
                  value={this.props.subject.assessmentToDatePractical}
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "assessmentToDatePractical",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  min={this.props.subject.assessmentFromDatePractical}
                  max={this.props.maxEndDate}
                  disabled={
                    this.props.subject.assessmentFromDatePractical &&
                    this.props.subject.assessmentFromDatePractical !== ""
                      ? false
                      : true
                  }
                  // onKeyDown={(e) => e.preventDefault()}
                />
              </td>
              <td rowSpan={1}>
                <input
                  type="date"
                  rowSpan={2}
                  name="recordingDatePractical"
                  className="form-control"
                  value={
                    this.props.subject.recordingDatePractical
                      ? this.props.subject.recordingDatePractical
                      : ""
                  }
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "recordingDatePractical",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  min={moment(new Date()).format("YYYY-MM-DD")}
                />
              </td>

              <td rowSpan={1}>
                <input
                  type="date"
                  rowSpan={2}
                  className="form-control"
                  name="recordingDeadlinePractical"
                  value={
                    this.props.subject.recordingDeadlinePractical
                      ? this.props.subject.recordingDeadlinePractical
                      : ""
                  }
                  onChange={(e) =>
                    this.props.handleChange(
                      e,
                      this.props.idx,
                      this.props.yearLevel,
                      this.props.classroomId,
                      "recordingDeadlinePractical",
                      this.props.yearLevelIndex,
                      this.props.classroomIndex,
                      this.props.subjectIndex
                    )
                  }
                  min={this.props.subject.recordingDatePractical}
                  disabled={
                    this.props.subject.recordingDatePractical &&
                    this.props.subject.recordingDatePractical !== ""
                      ? false
                      : true
                  }
                />
              </td>
            </>
          ) : null}
        </tr>

        {this.props.subject.hasPractical && this.props.subject.hasTheory ? (
          <tr>
            {this.props.subject.hasPractical !== null &&
            this.props.subject.hasPractical ? (
              <>
                <td rowSpan={1}>Practical</td>
                <td
                  style={{ display: "flex", alignItems: "center" }}
                  rowSpan={1}
                >
                  <input
                    type="datetime-local"
                    name="assessmentFromDatePractical"
                    value={this.props.subject.assessmentFromDatePractical}
                    onChange={(e) =>
                      this.props.handleChange(
                        e,
                        this.props.idx,
                        this.props.yearLevel,
                        this.props.classroomId,
                        "assessmentFromDatePractical",
                        this.props.yearLevelIndex,
                        this.props.classroomIndex,
                        this.props.subjectIndex
                      )
                    }
                    min={this.props.minStartDate}
                    max={this.props.maxEndDate}

                    // onKeyDown={(e) => e.preventDefault()}
                  />
                  <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                    -
                  </span>
                  <input
                    type="datetime-local"
                    name="assessmentToDatePractical"
                    value={this.props.subject.assessmentToDatePractical}
                    onChange={(e) =>
                      this.props.handleChange(
                        e,
                        this.props.idx,
                        this.props.yearLevel,
                        this.props.classroomId,
                        "assessmentToDatePractical",
                        this.props.yearLevelIndex,
                        this.props.classroomIndex,
                        this.props.subjectIndex
                      )
                    }
                    min={this.props.subject.assessmentFromDatePractical}
                    max={this.props.maxEndDate}
                    disabled={
                      this.props.subject.assessmentFromDatePractical &&
                      this.props.subject.assessmentFromDatePractical !== ""
                        ? false
                        : true
                    }
                    // onKeyDown={(e) => e.preventDefault()}
                  />
                </td>
                <td rowSpan={1}>
                  <input
                    type="date"
                    rowSpan={2}
                    name="recordingDatePractical"
                    className="form-control"
                    value={
                      this.props.subject.recordingDatePractical
                        ? this.props.subject.recordingDatePractical
                        : ""
                    }
                    onChange={(e) =>
                      this.props.handleChange(
                        e,
                        this.props.idx,
                        this.props.yearLevel,
                        this.props.classroomId,
                        "recordingDatePractical",
                        this.props.yearLevelIndex,
                        this.props.classroomIndex,
                        this.props.subjectIndex
                      )
                    }
                    min={moment(new Date()).format("YYYY-MM-DD")}
                  />
                </td>

                <td rowSpan={1}>
                  <input
                    type="date"
                    rowSpan={2}
                    className="form-control"
                    name="recordingDeadlinePractical"
                    value={
                      this.props.subject.recordingDeadlinePractical
                        ? this.props.subject.recordingDeadlinePractical
                        : ""
                    }
                    onChange={(e) =>
                      this.props.handleChange(
                        e,
                        this.props.idx,
                        this.props.yearLevel,
                        this.props.classroomId,
                        "recordingDeadlinePractical",
                        this.props.yearLevelIndex,
                        this.props.classroomIndex,
                        this.props.subjectIndex
                      )
                    }
                    min={this.props.subject.recordingDatePractical}
                    disabled={
                      this.props.subject.recordingDatePractical &&
                      this.props.subject.recordingDatePractical !== ""
                        ? false
                        : true
                    }
                  />
                </td>
              </>
            ) : null}
          </tr>
        ) : null}
      </React.Fragment>
    );
  }
}

export default SubjectListTableRow;
