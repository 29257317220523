import React, { Component } from "react";
import "../../assets/scss/userDetail.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import ModalWindow from "../UI/ModalWindow";
import Information from "./Information";
import { checkRolePermissions } from "../../utils/Constants";

class UserDetail extends Component {
  state = {
    moreModal: false,
    sectionName: localStorage.getItem("classroom"),
    informationModal: false,
  };

  checkBirthday = (dateOfBirth) => {
    let userDate = moment(dateOfBirth);
    let todayDate = moment();
    return (
      userDate.date() === todayDate.date() &&
      userDate.month() === todayDate.month()
    );
  };

  displayMoreSubjects = () => {
    this.setState({ moreModal: !this.state.moreModal });
  };
  informationModal = () => {
    this.setState({ informationModal: !this.state.informationModal });
  };

  subjectAssignedUI = () => {
    if (localStorage.getItem("classroom") !== null) {
      return (
        <div>
          <ol>
            {this.props.userData
              ? this.props.userData.subjects
                ? this.props.userData.subjects.map((subject, idx) => {
                    return (
                      <li key={idx}>
                        {subject.name} -{" "}
                        {`${localStorage.getItem("classroom")} ${
                          subject.yearLevelName
                        }`}
                      </li>
                    );
                  })
                : null
              : null}
          </ol>
        </div>
      );
    }
  };

  render() {
    let data = this.props.userData;
    let groupName = this.props.groupPage ? this.props.groupData.name : "";

    return (
      <div className="container tt-mobileView">
        <div
          className={
            "tt-userDetailContainer " +
            (this.props.minimize ? "userDetailDisplay " : "")
          }
        >
          {this.props.familyPage ? (
            <div className="row">
              <div className="col">
                <p className="tt-userDetailTitle text-center">
                  {this.props.family
                    ? this.props.family.name
                    : "No family Assigned"}
                </p>
              </div>
            </div>
          ) : null}

          {this.props.groupPage ? (
            <div className="row">
              <div className="col text-center">
                <p className="tt-userDetailTitle">{groupName}</p>
              </div>
            </div>
          ) : null}

          {!this.props.familyPage &&
          !this.props.groupPage &&
          data.userRole === "Admin" ? (
            <div className="row">
              <div className="col text-center">
                <p className="tt-userDetailtitle">
                  {data.fullName} ({data.userRole})
                </p>
              </div>
            </div>
          ) : !this.props.familyPage &&
            !this.props.groupPage &&
            data.isStudent ? (
            <div className="row studentDetail">
              <div className="col-md-10">
                <p
                  className="tt-userDetailtitle"
                  style={{ textAlign: "left", fontWeight: "normal" }}
                >
                  {data.fullName} ({" "}
                  {data.yearLevelName
                    ? data.yearLevelName
                    : `No ${localStorage.getItem("yearLevel")} assigned`}{" "}
                  {/* -{" "} */}
                  {data.classroomName
                    ? data.classroomName
                    : "No section assigned"}
                  )
                </p>
                <p style={{ textAlign: "left", fontWeight: "normal" }}>
                  {moment().diff(data.dateOfBirth.substring(0, 10), "years") ===
                  0
                    ? "1 years old"
                    : `${moment().diff(
                        data.dateOfBirth.substring(0, 10),
                        "years"
                      )} years old`}
                  {" (Date of Birth: "}
                  {moment(data.dateOfBirth).format("dddd, MMMM Do YYYY ")}
                  {")"}
                  {this.checkBirthday(data.dateOfBirth) ? (
                    <FontAwesomeIcon icon={faBirthdayCake}></FontAwesomeIcon>
                  ) : (
                    ""
                  )}
                </p>

                <p style={{ textAlign: "left", fontWeight: "normal" }}>
                  Parents Name :{" "}
                  {data.parents
                    ? data.parents.length > 0
                      ? data.parents.map((parent, idx) => {
                          return (
                            <span key={idx}>
                              <span>
                                {parent.name}
                                {parent.relationship
                                  ? ` (${parent.relationship})`
                                  : ""}
                              </span>
                              <span>{", "}</span>
                            </span>
                          );
                        })
                      : "No parents assigned"
                    : "No parents assigned"}
                </p>

                <p style={{ textAlign: "left", fontWeight: "normal" }}>
                  Siblings Name :{" "}
                  {data.siblings
                    ? data.siblings.length > 0
                      ? data.siblings.map((sibling, idx) => {
                          return (
                            <span key={idx}>
                              <span>
                                {sibling.name}
                                {sibling.relationship
                                  ? ` (${sibling.relationship})`
                                  : ""}
                              </span>
                              <span>{", "}</span>
                            </span>
                          );
                        })
                      : "No siblings assigned"
                    : "No siblings assigned"}
                </p>
              </div>
              <div
                className="col-md-2 text-right"
                style={
                  {
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }
                }
              >
                {!this.props.familyPage &&
                !this.props.groupPage &&
                this.props.userData.isStudent ? (
                  <Link
                    to={{
                      pathname: `/familyPage/${this.props.userData.familyId}`,
                      familyPostFromProfile: true,
                    }}
                  >
                    <button className="tt-button tt-button-primary tt-button-fullWidth">
                      Family Page
                    </button>
                  </Link>
                ) : null}
                {checkRolePermissions(
                  "student-cruicial-profile-information",
                  "activity"
                ) ? (
                  !this.props.familyPage &&
                  !this.props.groupPage &&
                  this.props.userData.admsissionId != null ? (
                    <button
                      className="tt-button tt-button-primary tt-button-fullWidth"
                      onClick={() => this.informationModal()}
                    >
                      Information
                    </button>
                  ) : null
                ) : null}
              </div>
            </div>
          ) : !this.props.familyPage && !this.props.groupPage ? (
            <div className="row studentDetail">
              <div className="col">
                <p
                  className="tt-userDetailtitle"
                  style={{ textAlign: "left", fontWeight: "normal" }}
                >
                  {data.fullName}
                </p>

                {data.yearLevelName ? (
                  <p style={{ textAlign: "left", fontWeight: "normal" }}>
                    <strong>Homeroom teacher of : </strong>{" "}
                    {data.yearLevelName !== null
                      ? data.yearLevelName.includes(
                          localStorage.getItem("yearLevel")
                        )
                        ? data.yearLevelName
                        : localStorage.getItem("yearLevel") +
                          " " +
                          data.yearLevelName
                      : `No ${localStorage.getItem("yearLevel")} assigned`}{" "}
                    -{" "}
                    {data.classroomName !== null
                      ? data.classroomName.includes(
                          localStorage.getItem("classroom")
                        )
                        ? data.classroomName
                        : localStorage.getItem("classroom") +
                          " " +
                          data.classroomName
                      : `No ${localStorage.getItem("classroom")} assigned`}
                  </p>
                ) : null}

                {data.isCoordinator ? (
                  <p style={{ textAlign: "left", fontWeight: "normal" }}>
                    <strong>Coordinator of : </strong>{" "}
                    {data.coordinatorEducationLevelName
                      ? data.coordinatorEducationLevelName.length > 0
                        ? data.coordinatorEducationLevelName.map(
                            (coordinator, idx) => {
                              return (
                                <div className="userDetailHolder">
                                  <div className="userDetailEducationLevelHolder">
                                    {coordinator.educationLevelName + " School"}
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </p>
                ) : null}
                {data.isAdministrator ? (
                  <p style={{ textAlign: "left", fontWeight: "normal" }}>
                    <strong>Administrator of : </strong>{" "}
                    {data.administratorEducationLevelName
                      ? data.administratorEducationLevelName.length > 0
                        ? data.administratorEducationLevelName.map(
                            (administrator, idx) => {
                              return (
                                <div className="userDetailHolder">
                                  <div className="userDetailEducationLevelHolder">
                                    {administrator.educationLevelName +
                                      " School"}
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </p>
                ) : null}

                <div className="userDetailChipBox">
                  {data.subjects
                    ? data.subjects.length > 0
                      ? data.subjects.map((subject, idx) => {
                          return idx <= 4 ? (
                            <span key={idx}>
                              <div className="userDetailHolder">
                                <div className="userDetailYearHolder">
                                  {subject.name}
                                </div>
                                <div className="userDetailSectionHolder">
                                  {subject.yearLevelName}
                                </div>
                              </div>
                            </span>
                          ) : idx === data.subjects.length - 1 ? (
                            <span key={idx}>
                              <div className="userDetailHolder">
                                <div
                                  className="userDetailYearHolder displayMoreChips"
                                  onClick={this.displayMoreSubjects}
                                >
                                  + {data.subjects.length - 5} more
                                </div>
                              </div>
                            </span>
                          ) : (
                            ""
                          );
                        })
                      : "No subjects assigned"
                    : "No subjects assigned"}
                </div>
              </div>
              <div className="col-md-2 text-right">
                {checkRolePermissions(
                  "staff-cruicial-profile-information",
                  "activity"
                ) ? (
                  !this.props.familyPage &&
                  !this.props.groupPage &&
                  this.props.userData.recruitmentId != null ? (
                    <button
                      className="tt-button tt-button-primary tt-button-fullWidth"
                      onClick={() => this.informationModal()}
                    >
                      Information
                    </button>
                  ) : null
                ) : null}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <ModalWindow
          modal={this.state.moreModal}
          toggleModal={this.displayMoreSubjects}
          modalHeader="Subjects Assigned"
          modalBody={this.subjectAssignedUI()}
        ></ModalWindow>
        <ModalWindow
          modal={this.state.informationModal}
          toggleModal={this.informationModal}
          fullWidth={true}
          modalHeader={
            data
              ? data.fullName != "" || data.fullName != null
                ? data.fullName + "'s Information"
                : "Information"
              : null
          }
          modalBody={
            <Information profilePeopleId={this.props.profilePeopleId} />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default UserDetail;
