import React, { Component } from "react";
import Select from "react-select";
import { Spinner } from "react-mdl";

import makeAnimated from "react-select/animated";
import { axiosPost } from "../../utils/AxiosApi";
import { draggable, URL, postType } from "../../utils/Constants";
import swal from "sweetalert";
import {
  displayErrorAlert,
  displayFormDataValidationError,
  displayErrorMessage,
} from "../../utils/Utils";
import ModalWindow from "../UI/ModalWindow";
import CourseClassResource from "../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseClassResource";
import CourseClassResources from "../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseResourceTemplate";
import { connect } from "react-redux";
import { getNotices } from "../../ducks/PostsDucks";
import { now } from "moment";
import moment from "moment";
import Switch from "@material-ui/core/Switch";

const animatedComponents = makeAnimated();

class CreateSubjectAssignment extends Component {
  state = {
    gradeAliasName: localStorage.getItem("yearLevel"),
    sectionAliasName: localStorage.getItem("classroom"),
    gradeList: [],
    sectionList: [],
    subjectList: [],
    gradeId: "",
    sectionId: "",
    subjectId: "",
    allStudents: true,
    title: "",
    description: "",
    submissionDate: "",
    addResourceModal: false,
    updateResourceModal: false,
    contents: [],
    links: [],
    studentList: [],
    selectedIndividual: [],
    contents: [],
    error: false,
    assignment: "",
    files: [],
    startTime: "",
    checked: false,
    submissionRequired: false,
    point: "",
  };
  componentDidMount() {
    this.getClassForAssignment();
  }

  getAssignment = () => {
    let param = {
      id: this.props.id,
      activity: this.props.activity,
    };
    axiosPost(URL.selectAssignmentByKey, param, (response) => {
      if (response.data.status === 200) {
        let assignment = response.data.data;
        let gradeList = [...this.state.gradeList];
        gradeList.filter((grade) => {
          if (assignment.yearLevelId == grade.id.toString()) {
            this.setState(
              {
                sectionList: grade.classrooms,
              },
              () => {
                let sectionlist = [...this.state.sectionList];
                sectionlist.filter((section) => {
                  if (assignment.classroomId == section.id.toString()) {
                    this.setState({
                      subjectList: section.subjectlists,
                    });
                  }
                });
              }
            );
          }
        });
        // let dateArray = assignment.submissionDate.split(/[.\-_]/);

        var specialChars = [" "];
        var specialRegex = new RegExp("[" + specialChars.join("") + "]");
        let dateArray = assignment.submissionDate.split(specialRegex);
        this.setState(
          {
            title: assignment.title,
            description: assignment.description,
            gradeId: assignment.yearLevelId,
            sectionId: assignment.classroomId,
            subjectId: assignment.subjectId,
            submissionDate: assignment.submissionDate,
            assignment: assignment,
            checked: !assignment.canSubmit,
            submissionRequired: !assignment.submissionRequired,
            point: assignment.point,
            submissionDate: dateArray[0],
            startTime: dateArray[1],
          },
          () => {
            this.getStudentList();
            if (assignment.forWholeClass) {
              this.setState({ allStudents: true });
            } else {
              this.setState({ allStudents: false }, () => {
                let studentList = [];
                assignment.assignmentStudents.forEach((std) => {
                  let data = {
                    label: std?.admission?.people?.name,
                    value: std?.admission?.id,
                  };
                  studentList.push(data);
                });
                this.setState({ selectedIndividual: studentList });
              });
            }
          }
        );
        this.setContents();
      }
    });
  };

  handleSwitchChange = (e, param) => {
    e.preventDefault();
    if (param === "notRequired")
      this.setState({ checked: !this.state.checked });
    else if (param === "required")
      this.setState({ submissionRequired: !this.state.submissionRequired });
  };

  setContents = () => {
    let assignmentResources = this.state.assignment?.assignmentResources;
    let links = [];
    let contents = [];
    assignmentResources.map((el) => {
      if (el.fileType === "LNK") {
        links.push(el.link);
      } else {
        contents.push({
          id: el.id,
          src: `${URL.imageSelectURL + el.filePath}`,
          fileName: el.fileName,
          type: el.fileType,
        });
      }
    });

    this.setState({
      files: contents,
      links,
    });
  };

  getClassForAssignment = () => {
    axiosPost(URL.getClassForAssignment, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ gradeList: data }, () => {
          if (this.props.action === "update") {
            this.getAssignment();
          }
        });
      }
    });
  };
  handleGradeChange = (e) => {
    this.handleChange(e);
    let gradeList = [...this.state.gradeList];
    gradeList.filter((grade) => {
      if (e.target.value === grade.id.toString()) {
        this.setState({
          sectionList: grade.classrooms,
          sectionId: "",
          subjectId: "",
          studentList: [],
        });
      }
    });
  };
  handleSectionChange = async (e) => {
    await this.handleChange(e);
    let sectionList = [...this.state.sectionList];
    sectionList.filter((section) => {
      // if (e.target.value === section.id.toString()) {
      this.setState({ subjectList: section.subjectlists, subjectId: "" });
      // }
    });
    this.getStudentList();
  };

  getStudentList = () => {
    let param = {
      yearLevelId: this.state.gradeId,
      classroomId: this.state.sectionId,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status == 200) {
        let list = response.data.data;
        let studentList = [];
        list.forEach((std) => {
          let data = {
            label: std?.people?.name,
            value: std?.admissionId,
          };
          studentList.push(data);
        });
        this.setState({ studentList });
      }
    });
  };
  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getAdmissionId = () => {
    let students;
    if (this.state.allStudents) {
      students = [...this.state.studentList];
    } else {
      students = [...this.state.selectedIndividual];
    }
    let admissionIds = [];
    students.forEach((selected) => {
      admissionIds.push(selected.value);
    });
    return admissionIds;
  };

  handleAddResourceModal = () => {
    this.setState({
      addResourceModal: !this.state.addResourceModal,
    });
  };

  handleUpdateResourceModal = () => {
    this.setState({
      updateResourceModal: !this.state.updateResourceModal,
    });
  };

  saveResources = (contents, links) => {
    this.setState({ contents: contents, links: links });
    this.handleAddResourceModal();
  };

  updateResources = (contents, links, remainingPreviousFiles) => {
    this.setState({
      contents: contents,
      links: links,
      files: remainingPreviousFiles,
    });
    this.handleUpdateResourceModal();
  };

  resetState = () => {
    this.setState({
      sectionList: [],
      subjectList: [],
      gradeId: "",
      sectionId: "",
      subjectId: "",
      allStudents: true,
      title: "",
      description: "",
      submissionDate: "",
      addResourceModal: false,
      contents: [],
      links: [],
      studentList: [],
      selectedIndividual: [],
      contents: [],
      error: false,
      startTime: "",

      checked: false,
      point: "",
      submissionRequired: false,
    });
  };
  createAssignment = (isAssigned) => {
    if (
      this.state.title === "" ||
      this.state.gradeId == "" ||
      this.state.sectionId == "" ||
      this.state.subjectId == "" ||
      this.state.startTime == ""
    ) {
      this.setState({ error: true });
      swal({
        title: "Error",
        text: "Please fill the mandatory fields",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
    if (this.getAdmissionId().length <= 0) {
      swal({
        title: "Error",
        text: "Please select all students or individual student",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
    let formData = new FormData();
    if (this.state.contents.length < 0) {
      formData.append("files", []);
    } else {
      for (const key of Object.keys(this.state.contents)) {
        formData.append("files", this.state.contents[key]);
      }
    }
    formData.append(
      "jsonData",
      JSON.stringify({
        yearLevelId: this.state.gradeId,
        classroomId: this.state.sectionId,
        subjectId: this.state.subjectId,
        forWholeClass: this.state.allStudents,
        title: this.state.title,
        description: this.state.description,
        links: this.state.links,
        submissionDate: Date.parse(
          this.state.submissionDate + " " + this.state.startTime
        ),
        admissionIds: this.getAdmissionId(),
        isAssigned: isAssigned,
        canSubmit: this.state.checked ? false : true,
        submissionRequired: this.state.submissionRequired ? false : true,
        point: this.state.point,
      })
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.insertAssignment,
        formData,
        (res) => {
          if (res.status === 200) {
            swal({
              title: "Success",
              text: "Assignment Successfully created",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            this.setState({ showSpinner: false });
            draggable();
            this.resetState();
            let param = {
              postType: postType.noticeCode,
              createdAt: new Date(),
              peopleId: localStorage.getItem("peopleId"),
            };
            this.props.getNotices(param);
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          if (error?.response?.data?.data) {
            displayFormDataValidationError(error);
          } else {
            displayErrorMessage(error);
          }
          // this.resetState();
        }
      );
    });
  };

  updateAssignment = (data) => {
    if (
      this.state.title === "" ||
      this.state.gradeId == "" ||
      this.state.sectionId == "" ||
      this.state.subjectId == ""
    ) {
      this.setState({ error: true });
      swal({
        title: "Error",
        text: "Please fill the mandatory fields",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
    if (this.getAdmissionId().length <= 0) {
      swal({
        title: "Error",
        text: "Please select all students or individual student",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
    let formData = new FormData();
    for (const key of Object.keys(this.state.contents)) {
      formData.append("files", this.state.contents[key]);
    }
    if (this.state.contents.length <= 0) {
      formData.append("files", []);
    }
    let resources = [];
    let files = [...this.state.files];
    files.forEach((file) => {
      resources.push(file.id);
    });
    formData.append(
      "jsonData",
      JSON.stringify({
        id: this.props.id,
        yearLevelId: this.state.gradeId,
        classroomId: this.state.sectionId,
        subjectId: this.state.subjectId,
        forWholeClass: this.state.allStudents,
        title: this.state.title,
        description: this.state.description,
        links: this.state.links,
        // submissionDate: this.state.submissionDate,
        admissionIds: this.getAdmissionId(),
        isAssigned: data,
        previousResourcesIds: resources,
        submissionDate: Date.parse(
          this.state.submissionDate + " " + this.state.startTime
        ),
        canSubmit: this.state.checked ? false : true,
        submissionRequired: this.state.submissionRequired ? false : true,
        point: this.state.point,
      })
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.updateAssignment,
        formData,
        (res) => {
          if (res.status === 200) {
            swal({
              title: "Success",
              text: "Assignment Successfully Updated",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            this.setState({ showSpinner: false });
            draggable();
            let param = {
              postType: postType.noticeCode,
              createdAt: new Date(),
              peopleId: localStorage.getItem("peopleId"),
            };
            this.props.getNotices(param);
            this.props.closeModal();
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayFormDataValidationError(error);
          // this.resetState();
        }
      );
    });
  };
  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            {this.props.action !== "update"
              ? "Create Subject Assignment"
              : "Update Subject Assignment"}
          </div>
          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-md-2 text-right mt-1"
                  style={{ maxWidth: "12%" }}
                >
                  <strong>
                    {this.state.gradeAliasName}:
                    <span
                      className="tt-assessment-module-mandatory"
                      style={{ paddingLeft: "5px" }}
                    >
                      *
                    </span>{" "}
                  </strong>
                </div>
                <div className="col-md-2">
                  <select
                    className={
                      this.state.error && this.state.gradeId == ""
                        ? " form-control form-control-warn"
                        : "form-control"
                    }
                    name="gradeId"
                    value={this.state.gradeId}
                    onChange={this.handleGradeChange}
                    disabled={false}
                  >
                    <option value="" disabled>
                      Select {this.state.gradeAliasName}
                    </option>
                    {this.state.gradeList.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-2 text-right mt-1">
                  <strong>
                    {this.state.sectionAliasName}:
                    <span
                      className="tt-assessment-module-mandatory"
                      style={{ paddingLeft: "5px" }}
                    >
                      *
                    </span>
                  </strong>
                </div>
                <div className="col-md-2">
                  <select
                    className={
                      this.state.error && this.state.sectionId == ""
                        ? " form-control form-control-warn"
                        : "form-control"
                    }
                    name="sectionId"
                    value={this.state.sectionId}
                    onChange={this.handleSectionChange}
                  >
                    <option value="" disabled>
                      Select {this.state.sectionAliasName}
                    </option>
                    {this.state.sectionList.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-2 text-right mt-1">
                  <strong>
                    Subject:
                    <span
                      className="tt-assessment-module-mandatory"
                      style={{ paddingLeft: "5px" }}
                    >
                      *
                    </span>
                  </strong>
                </div>
                <div className="col-md-2">
                  <select
                    className={
                      this.state.error && this.state.subjectId == ""
                        ? " form-control form-control-warn"
                        : "form-control"
                    }
                    name="subjectId"
                    value={this.state.subjectId}
                    onChange={this.handleChange}
                    disabled={false}
                  >
                    <option value="" disabled>
                      Select Subjects
                    </option>
                    {this.state.subjectList.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-md-2 text-right mt-2"
                  style={{ maxWidth: "12%" }}
                >
                  {/* <strong>Select Students: </strong> */}
                </div>
                <div className="col-md-2 mt-2">
                  <input
                    type="checkbox"
                    name="allStudents"
                    checked={this.state.allStudents}
                    onChange={this.handleChange}
                  />

                  <label htmlFor="All Students" style={{ padding: "10px" }}>
                    <strong>All Students</strong>
                  </label>
                </div>
                <div className="col-md-1 mt-2">OR</div>
                <div className="col-md-6">
                  <Select
                    closeMenuOnSelect={false}
                    isClearable={true}
                    value={this.state.selectedIndividual}
                    components={animatedComponents}
                    isMulti
                    options={this.state.studentList}
                    placeholder="Choose Individual Students"
                    name="people"
                    onChange={this.handleIndividualSelect}
                    menuPlacement="auto"
                    isLoading={false}
                    isDisabled={this.state.allStudents}
                  />
                </div>
              </div>

              <div className="container-fluid">
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>
                      Title:
                      <span
                        className="tt-assessment-module-mandatory"
                        style={{ paddingLeft: "5px" }}
                      >
                        *
                      </span>
                    </strong>
                  </div>

                  {/* <div className="row"> */}
                  <div className="col">
                    <input
                      type="text"
                      name="title"
                      placeholder="Enter Title of the Assignment"
                      className={
                        this.state.error && this.state.title == ""
                          ? " form-control form-control-warn"
                          : "form-control"
                      }
                      value={this.state.title}
                      onChange={this.handleChange}
                    ></input>
                    {/* </div> */}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>Description: </strong>
                  </div>

                  {/* <div className="row"> */}
                  <div className="col">
                    <textarea
                      className="form-control"
                      name="description"
                      placeholder="Enter description / instructions for students"
                      value={this.state.description}
                      onChange={this.handleChange}
                    ></textarea>
                    {/* </div> */}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>
                      Submission Date:
                      <span
                        className="tt-assessment-module-mandatory"
                        style={{ paddingLeft: "5px" }}
                      >
                        *
                      </span>
                    </strong>
                  </div>
                  <div className="col-6">
                    <input
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                      // min="2021-07-28"
                      className={
                        this.state.error && this.state.submissionDate == ""
                          ? " form-control form-control-warn"
                          : "form-control"
                      }
                      name="submissionDate"
                      value={this.state.submissionDate}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                  <div className="col-4">
                    <input
                      type="time"
                      className="form-control"
                      name="startTime"
                      value={this.state.startTime}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>Assignment Point: </strong>
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      className="form-control"
                      name="point"
                      value={this.state.point}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>Submission After Deadline: </strong>
                  </div>
                  <div className="col-6">
                    <span className="ml-3 mr-2">Accept</span>
                    <Switch
                      checked={this.state.checked}
                      onClick={(e) => this.handleSwitchChange(e, "notRequired")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span className="ml-3 mr-2">Reject</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-2 mt-1">
                    <strong>Submission Required: </strong>
                  </div>
                  <div className="col-6">
                    <span className="ml-3 mr-2">Required</span>
                    <Switch
                      checked={this.state.submissionRequired}
                      onClick={(e) => this.handleSwitchChange(e, "required")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span className="ml-3 mr-2">Not Required</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col text-right">
                    {this.props.action === "update" ? (
                      <button
                        className="tt-button tt-button-primary"
                        onClick={this.handleUpdateResourceModal}
                      >
                        Update Resource
                      </button>
                    ) : (
                      <button
                        className="tt-button tt-button-primary"
                        onClick={this.handleAddResourceModal}
                      >
                        Add Resource
                      </button>
                    )}
                  </div>
                </div>
                <div className="row mt-2 button-bg-color">
                  <div className="col text-right">
                    {this.props.action === "update" ? (
                      <>
                        <button
                          className="tt-button tt-button-primary mr-3"
                          onClick={(e) => this.updateAssignment(true)}
                        >
                          Update and Assign
                        </button>
                        <button
                          className="tt-button tt-button-primary mr-3"
                          onClick={(e) => this.updateAssignment(false)}
                        >
                          Update Only
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="tt-button tt-button-primary mr-3"
                          onClick={(e) => this.createAssignment(true)}
                        >
                          Save and Assign
                        </button>
                        <button
                          className="tt-button tt-button-primary mr-3"
                          onClick={(e) => this.createAssignment(false)}
                        >
                          Save Only
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <ModalWindow
                  modal={this.state.addResourceModal}
                  toggleModal={this.handleAddResourceModal}
                  size="lg"
                  modalHeader="Add Resources"
                  modalBody={
                    <CourseClassResource
                      assignment={true}
                      updateAssignment={false}
                      toggleAddResourceModal={this.handleAddResourceModal}
                      saveResources={this.saveResources}
                      assignmentContents={this.state.contents}
                      assignmentLinks={this.state.links}
                    ></CourseClassResource>
                  }
                ></ModalWindow>
                <ModalWindow
                  modal={this.state.updateResourceModal}
                  toggleModal={this.handleUpdateResourceModal}
                  size="lg"
                  modalHeader="Update Resources"
                  modalBody={
                    <CourseClassResource
                      assignment={true}
                      updateAssignment={true}
                      toggleUpdateResourceModal={this.handleUpdateResourceModal}
                      updateResources={this.updateResources}
                      assignmentContents={this.state.contents}
                      assignmentLinks={this.state.links}
                      updateFiles={this.state.files}
                    ></CourseClassResource>
                  }
                ></ModalWindow>
                {this.state.showSpinner ? (
                  <div className="fullWindow-Spinner">
                    <div>
                      <Spinner color="white"></Spinner>
                    </div>
                    <div style={{ fontSize: "16px", marginTop: "15px" }}>
                      {this.props.action === "update"
                        ? "Please Wait. Updating Assignment ..."
                        : "Please Wait. Creating Assignment ..."}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, props) => ({});
const mapActionsToProps = {
  getNotices: getNotices,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(CreateSubjectAssignment);
