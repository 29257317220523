import React, { Component } from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL, postType, checkRolePermissions } from "../../../utils/Constants";

import JoditEditor from "jodit-react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: ["about", "file", "copyformat", "print"],
  // limitWords: 60000,
  limitChars: 60000,
  uploader: {
    insertImageAsBase64URI: true,
  },
};

// const animatedComponents = makeAnimated();

class Media extends Component {
  state = {
    groupsArray: [],
  };

  componentDidMount() {
    axiosPost(URL.getAllGroups, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        let groupsArray = [];
        groups.forEach((element) => {
          groupsArray.push({ label: element.name, value: element.id });
        });
        this.setState({
          groupsArray,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="row mb-2">
          <div className="col-md-1 mt-1 text-center">
            <strong>Heading</strong>
          </div>
          <div className="col-md-11">
            <input
              type="text"
              className="form-control"
              name="heading"
              value={this.props.heading}
              onChange={this.props.handleChange}
            ></input>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-5">
            <div className="tt-videoContainer">
              {this.props.files.length > 0 ? (
                <div className="tt-mediaImageHolder">
                  {this.props.files.map((file, idx) => {
                    return (
                      <div
                        key={idx}
                        className={idx === 0 ? "fullWidthImage" : ""}
                      >
                        <img
                          src={window.URL.createObjectURL(file)}
                          alt="notice"
                          className={
                            this.props.files.length > 1
                              ? "imageMediaHeight"
                              : ""
                          }
                        ></img>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="tt-fileDelete"
                          onClick={(e) => {
                            this.props.deleteFileContent(e, idx);
                          }}
                        ></FontAwesomeIcon>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <iframe
                  title="previewURL"
                  src={this.props.selectedURL ? this.props.selectedURL : ""}
                  frameBorder="1"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
              {this.props.files.length > 0 ||
              this.props.selectedURL == "" ? null : (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="tt-videoClose"
                  onClick={this.props.removeSelectedURL}
                ></FontAwesomeIcon>
              )}
            </div>
          </div>
          <div className="col-md-7 tt-mediaPostSection">
            <div style={{ minHeight: "200px" }}>
              <JoditEditor
                onChange={(e) => this.props.handleTextEditor(e)}
                value={this.props.textContent}
                config={config}
              />
            </div>
            <strong>Keywords</strong>
            <input
              type="text"
              className="form-control"
              name="mediaKeywords"
              value={this.props.mediaKeywords}
              onChange={this.props.handleChange}
            ></input>
            <div className="tt-buttonChips">
              <div
                className="red"
                onClick={(e) => this.props.insertMediaKeyword(e, "Knowledge")}
              >
                Knowledge
              </div>
              <div
                className="blue"
                onClick={(e) => this.props.insertMediaKeyword(e, "Skill")}
              >
                Skill
              </div>
              <div
                className="green"
                onClick={(e) => this.props.insertMediaKeyword(e, "Approaches")}
              >
                Approaches
              </div>
            </div>
            <div className="row mt-1">
              <div className="col">
                <select
                  className="form-control"
                  name="themes"
                  value={this.props.themes}
                  onChange={this.props.handleChange}
                >
                  <option value="" disabled selected>
                    Select Theme
                  </option>
                  {this.props.themeList
                    ? this.props.themeList.map((theme, i) => (
                        <option value={theme.id} key={i}>
                          {theme.theme}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="col-md-auto col-sm-auto">
                {!checkRolePermissions("add-theme") ? null : (
                  <button
                    className="tt-button tt-button-primary tt-button-fullWidth"
                    onClick={this.props.handleAddThemeClick}
                  >
                    Add Theme
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="row">
              <div className="col mobileMediaImageAdd">
                <p>
                  <strong>Attach Image (jpg , png, jpeg)</strong>
                </p>
              </div>
              <div className="col mobileMediaImageButton">
                <input
                  id="myInput"
                  type="file"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  onChange={this.props.handleFileSelect}
                  accept="image/x-png,image/jpeg"
                  multiple
                />
                <button
                  className="tt-button tt-button-primary"
                  onClick={() => {
                    this.upload.click();
                  }}
                  disabled={this.props.selectedURL ? true : false}
                >
                  Attach Image
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col text-center">
                <strong>OR</strong>
              </div>
            </div>

            <div className="mt-2 text-center">
              <p className="tt-postNoticeHeading">Embed Media</p>
            </div>
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <select
                  className="form-control"
                  name="selectedOrigin"
                  onChange={this.props.handleChange}
                  value={this.props.selectedOrigin}
                >
                  <option value="" disabled selected>
                    Youtube / Vimeo / Dailymotion / SlideShare
                  </option>
                  <option value="youtube">Youtube</option>
                  <option value="vimeo">Vimeo</option>
                  <option value="dailymotion">Dailymotion</option>
                  <option value="slideshare">SlideShare</option>
                  <option value="soundcloud">Sound Cloud</option>
                </select>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <input
                  type="text"
                  placeholder="URL"
                  name="url"
                  value={this.props.url}
                  onChange={this.props.handleChange}
                  className="form-control"
                ></input>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-2">
              <div className="col text-center">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.props.handleURLChanges}
                  disabled={this.props.files.length > 0 ? true : false}
                >
                  Add Link
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="row mt-2">
              <div className="col text-right">
                <button
                  className="tt-button tt-button-primary"
                  onClick={(e) =>
                    this.props.handleAddQuestionClick(e, postType.mediaCode)
                  }
                >
                  Add Questions
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-1">
                <p className="mb-0">
                  <strong>
                    What questions does this media document address ?
                  </strong>
                </p>
                <div className="tt-postNoticeBoxes mt-0 mediaQuestions">
                  <strong>Questions</strong>

                  <div className="tt-postEmbeded">
                    {this.props.questionsForMedia.length > 0 &&
                    this.props.questionsForMedia[0].question !== ""
                      ? this.props.questionsForMedia.map((question, idx) => {
                          return (
                            <div className="row" key={idx}>
                              <div className="col-md-11">
                                <p>{question.question}</p>
                              </div>
                              <div className="col-md-1">
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  onClick={(e) =>
                                    this.props.deleteQuestionsForMedia(e, idx)
                                  }
                                ></FontAwesomeIcon>
                              </div>
                            </div>
                          );
                        })
                      : "No questions are added"}
                  </div>
                </div>
                <div className="text-left">
                  <input
                    type="checkbox"
                    className="tt-record-deadline-checkbox"
                    id="isAcademicRepo"
                    name="isAcademicRepo"
                    onChange={this.props.handleChange}
                    value={this.props.isAcademicRepo}
                    checked={this.props.isAcademicRepo}
                  ></input>
                  <label htmlFor="acaRepo">
                    &nbsp;<strong>Submit for Academic Repository</strong>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={(e) =>
                this.props.submitPost(
                  e,
                  postType.mediaCode,
                  this.props.groupPost,
                  this.props.familyPost
                )
              }
            >
              Post Media
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Media;
