import React, { Component } from "react";
import ReportMagnifier from "./ReportMagnifier";

export default class ReportingConfigurationPageSetup extends Component {
  render() {
    const assessments = this.props.assessments,
      yearLevels = this.props.yearLevels,
      pageSize = this.props.pageSize,
      pageSizeUnit = this.props.pageSizeUnit,
      margin = this.props.margin,
      paperSize = this.props.paperSize,
      marginSizeUnit = this.props.marginSizeUnit,
      headerHeight = this.props.headerHeight,
      footerHeight = this.props.footerHeight,
      orientation = this.props.orientation,
      reportName = this.props.reportName,
      publishingDate = this.props.publishingDate,
      groupedYearLevels = this.props.groupedYearLevels;
    return (
      <div className="card">
        <div
          className="tt-group-header tt-stickyHeader"
          style={{ borderRadius: 0 }}
        >
          {"Create Report (Step 1 of 4: Setup)"}
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-7 tt-printbox">
                <div className="row">
                  <div className="col">
                    <strong>
                      Assessment
                      <span className="tt-assessment-module-mandatory">*</span>
                    </strong>
                  </div>
                  <div className="col">
                    <select
                      name="assessmentId"
                      id=""
                      className="form-control"
                      value={this.props.assessmentId}
                      onChange={(e) => this.props.handleAssessmentChange(e)}
                    >
                      <option value="" disabled selected>
                        {"List of Assessment"}
                      </option>
                      {assessments
                        ? assessments.map((assessment) => {
                            let value = `${assessment.id},${assessment.assessmentName},${assessment.educationLevelId}`;
                            return (
                              <option value={value}>
                                {assessment.assessmentName}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <strong>
                      {localStorage.getItem("yearLevel")}
                      <span className="tt-assessment-module-mandatory">*</span>
                    </strong>
                  </div>
                  <div className="col">
                    <select
                      name="selectedYearLevel"
                      className="form-control"
                      value={this.props.selectedYearLevel}
                      onChange={this.props.handleYearLevelChage}
                      disabled={this.props.assessmentId === null ? true : false}
                    >
                      <option value="" disabled selected>
                        Choose {localStorage.getItem("yearLevel")}
                      </option>
                      {yearLevels
                        ? yearLevels.map((yearLevel) => (
                            <option value={yearLevel.id}>
                              {/* {localStorage.getItem("yearLevel")}{" "} */}
                              {yearLevel.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <strong>
                      Apply these settings to
                      <span className="tt-assessment-module-mandatory">*</span>
                    </strong>
                    <br></br>
                    <i className="text-muted">
                      (Hold <strong>Ctrl</strong> to select multiple)
                    </i>
                  </div>
                  <div className="col">
                    <select
                      name="selectedMultipleYearLevels"
                      value={this.props.selectedMultipleYearLevels}
                      className="form-control"
                      multiple
                      onChange={this.props.handleYearLevelMultipleChange}
                      disabled={
                        this.props.selectedYearLevel === "" ? true : false
                      }
                    >
                      <option disabled selected>
                        Choose {localStorage.getItem("yearLevel")}s
                      </option>
                      {groupedYearLevels && groupedYearLevels.length > 0
                        ? groupedYearLevels
                          ? groupedYearLevels.map((yearLevel) => {
                              return (
                                <option value={yearLevel.id}>
                                  {yearLevel.name}
                                </option>
                              );
                            })
                          : null
                        : null}
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <strong>
                      Report name
                      <span className="tt-assessment-module-mandatory">*</span>
                    </strong>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="reportName"
                      value={reportName}
                      onChange={this.props.handleFormDataChange}
                      maxLength={255}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <strong>
                      Publishing date
                      <span className="tt-assessment-module-mandatory">*</span>
                    </strong>
                  </div>
                  <div className="col">
                    {/* <DatePicker /> */}
                    <input
                      type="date"
                      className="form-control"
                      name="publishingDate"
                      min={new Date().toISOString().slice(0, 10)}
                      value={publishingDate}
                      onChange={this.props.handleFormDataChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <strong>
                      Publisher
                      <span className="tt-assessment-module-mandatory">*</span>
                    </strong>
                  </div>
                  <div className="col">
                    <select
                      name="publisher"
                      value={this.props.publisher}
                      className="form-control"
                      onChange={this.props.handleFormDataChange}
                    >
                      <option value="" disabled>
                        Choose a publisher
                      </option>
                      {this.props.staffList
                        ? this.props.staffList.map((staff, idx) => {
                            return (
                              <option key={idx} value={staff.id}>
                                {staff.people.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="card mt-2">
                  <div className="card-header tt-group-header">
                    {"Report Setting"}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {/* Page size */}
                      <div className="col">
                        <strong>
                          Page size
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>
                        </strong>
                      </div>
                      <div className="col">
                        <select
                          name="paperSize"
                          id=""
                          className="form-control"
                          value={paperSize}
                          onChange={this.props.handleFormDataChange}
                        >
                          <option value="a4">A4</option>
                          <option value="lt">Letter</option>
                          <option value="custom">Custom</option>
                        </select>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="width"
                          // eslint-disable-next-line
                          name="width"
                          step={pageSizeUnit === "mm" ? 1 : 0.1}
                          value={
                            pageSizeUnit === "mm"
                              ? pageSize.width
                              : pageSize.width?.toFixed(3)
                          }
                          onChange={this.props.handlePageSizeChange}
                        />
                      </div>

                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="height"
                          // eslint-disable-next-line
                          name="height"
                          step={pageSizeUnit === "mm" ? 1 : 0.1}
                          value={
                            pageSizeUnit === "mm"
                              ? pageSize.height
                              : pageSize.height?.toFixed(3)
                          }
                          onChange={this.props.handlePageSizeChange}
                        />
                      </div>

                      <div className="col">
                        <select
                          name="pageSizeUnit"
                          id=""
                          className="form-control"
                          value={pageSizeUnit}
                          onChange={this.props.handleFormDataChange}
                        >
                          <option value="in">Inch</option>
                          <option value="mm">Milimeter</option>
                        </select>
                      </div>
                    </div>
                    {/* Orientation */}
                    <div className="row mt-2">
                      <div className="col">
                        <strong>
                          Orientation
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>
                        </strong>
                      </div>
                      <div className="col">
                        <select
                          name="orientation"
                          id=""
                          className="form-control"
                          value={orientation}
                          onChange={(e) => {
                            this.props.handleFormDataChange(e);
                            this.props.handleOrientationChange(e);
                          }}
                        >
                          <option value="portrait">Portrait</option>
                          <option value="landscape">Landscape</option>
                        </select>
                      </div>
                    </div>
                    {/* margins */}
                    <div className="row mt-2">
                      <div className="col">
                        <strong>
                          Margin
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>
                        </strong>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="top"
                          name="top"
                          value={
                            marginSizeUnit === "mm"
                              ? margin.top
                              : margin.top?.toFixed(3)
                          }
                          step={marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleMarginChange}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="right"
                          name="right"
                          value={
                            marginSizeUnit === "mm"
                              ? margin.right
                              : margin.right?.toFixed(3)
                          }
                          step={marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleMarginChange}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="bottom"
                          name="bottom"
                          value={
                            marginSizeUnit === "mm"
                              ? margin.bottom
                              : margin.bottom?.toFixed(3)
                          }
                          step={marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleMarginChange}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="left"
                          name="left"
                          value={
                            marginSizeUnit === "mm"
                              ? margin.left
                              : margin.left?.toFixed(3)
                          }
                          step={marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleMarginChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-5 tt-printbox1"
                style={{ backgroundColor: "#ccc", position: "relative" }}
              >
                <ReportMagnifier
                  handleZoomIn={this.props.handleZoomIn}
                  handleZoomOut={this.props.handleZoomOut}
                />
                <div
                  className="tt-printPreview"
                  style={{
                    zoom: this.props.zoom + "%",
                    height: `${pageSize.height}${pageSizeUnit}`,
                    maxWidth: `${pageSize.width}${pageSizeUnit}`,
                    width: `${pageSize.width}${pageSizeUnit}`,
                    paddingTop: `${margin.top}${marginSizeUnit}`,
                    paddingLeft: `${margin.left}${marginSizeUnit}`,
                    paddingRight: `${margin.right}${marginSizeUnit}`,
                    paddingBottom: `${margin.bottom}${marginSizeUnit}`,
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      maxWidth: "100%",
                      borderLeft: "1px dashed #ccc",
                      borderRight: "1px dashed #ccc",
                    }}
                  >
                    <div
                      style={{
                        height: `${headerHeight}${pageSizeUnit}`,
                        maxWidth: "100%",
                        border: "1px dashed #ccc",
                      }}
                    ></div>
                    <div
                      style={{
                        height: `${
                          pageSize.height -
                          (headerHeight +
                            footerHeight +
                            margin.top +
                            margin.bottom)
                        }${pageSizeUnit}`,
                        maxWidth: "100%",
                        border: "1px dashed #ccc",
                      }}
                    ></div>
                    <div
                      style={{
                        height: `${footerHeight}${pageSizeUnit}`,
                        maxWidth: "100%",
                        border: "1px dashed #ccc",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-footer"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          <div className="row">
            <div className="col"></div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleSaveBtnClick}
              >
                {"Save"}
              </button>
            </div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleNextBtnClick}
              >
                {"Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
