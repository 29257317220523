/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, RecruitmentForm, render the Recruitment Form with respect to the JSON input
 */
import React, { Component } from "react";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  addressCode,
  peopleCode,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import AddressCluster from "../../Admission/AddressCluster";
import IdentityCluster from "../../Admission/IdentityCluster";
import StaffType from "./StaffType";
import swal from "@sweetalert/with-react";
import { connect } from "react-redux";
import {
  getRecruitmentConfiguration,
  getStaffList,
  getStaffListPagination,
} from "../../../ducks/RecruitmentDucks";
import "../../../assets/scss/recruitment.scss";
import moment from "moment";
import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import NoteDisplay from "../../../utils/noteDisplay";
import { Spinner } from "reactstrap";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import { cloneDeep } from "lodash";
import TableForm from "../../Admission/Admission/TableForm";
import { convertNepaliDate, emailValidate } from "../../../utils/Utils";

class RecruitmentForm extends Component {
  state = {
    addressCustomFields: [],
    addressFields: [],
    academicYear: "",
    admissionTableFieldGroups: [],
    customFields: [],
    customTableFieldValues: [],
    cv: null,
    dateOfBirth: null,
    dateOfHire: "",
    department: "",
    departments: [],
    designation: "",
    designations: [],
    email: null,
    errors: {
      staffTypeId: false,
      dateOfHire: false,
      admissionTableFieldGroups: {},
    },
    gender: null,
    identityFields: [],
    isAvailable: false,
    isLoading: true,
    isSubmitting: false,
    maritalStatus: "sing",
    nationality: null,
    recruitmentImage: null,
    reGex: /^[a-zA-Z ]*$/,
    render: false,
    schoolEstablishedDate: "",
    selectedDepartments: null,
    selectedDesignations: null,
    staffType: [],
    staffTypeId: "",
    teachingLicense: "no",
    parentCredentials: false,
    userFetching: false,
    username: null,
    userFound: null,
    isEmailAvailable: null,
    emailFetching: false,
    yearOfJoiningToggleDate: false,
    dateOfHireInBS: null,
    identityClusterDatePickerToggle: false,
  };

  componentDidMount() {
    const crudName = this.props.editMode ? "update" : "insert";
    const peopleId = this.props.peopleId;
    this.props.getRecruitmentConfiguration(crudName, peopleId);
    this.getCustomFields();
    this.getSchoolInfo();
    this.getStaffType();
    if (this.props.editMode) {
      this.setUpdateConfiguration();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.recruitmentConfiguration !== this.props.recruitmentConfiguration
    ) {
      this.setState(
        {
          admissionTableFieldGroups: this.props.recruitmentConfiguration,
          isLoading: false,
        },
        async () => {
          if (this.props.editMode) {
            await this.setUpdateCustomFieldValues();
          }
        }
      );
    }
  }

  /**
   * Retrieves the identityCluster fields from the database
   */
  getCustomFields = () => {
    axiosPost(URL.getIdentityCluster, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ customFields: data });
      }
    });
    axiosPost(URL.getAddressCluster, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ addressCustomFields: data });
      }
    });
  };

  getDepartments = () => {
    axiosPost(
      URL.getDepartment,
      {},
      (response) => {
        if (response.status === 200) {
          let departments = response.data.data;
          departments = departments.map((d) => {
            d.label = d.name;
            d.value = d.id;
            return d;
          });
          this.setState({ departments }, function () {
            if (this.state.departments.length > 0) {
              this.setState({ isAvailable: true, render: true });
            } else {
              this.setState({ isAvailable: false, render: true });
            }
            this.getDesignations();
          });
        } else {
          this.setState({ isAvailable: false, render: true });
        }
      },
      (err) => {
        this.setState({ isAvailable: false, render: true });
      }
    );
  };

  getDesignations = () => {
    axiosPost(URL.getDesignations, {}, (response) => {
      if (response.status === 200) {
        let designations = response.data.data;
        designations = designations.map((d) => {
          d.label = d.name;
          d.value = d.id;
          return d;
        });
        this.setState({ designations });
      }
    });
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        let date = response.data.data[0].dateOfEstablishment;
        this.setState({
          schoolEstablishedDate: moment(date).format("YYYY-MM-DD"),
        });
      }
    });
  };

  getStaffType = () => {
    axiosPost(URL.getStaffType, {}, (response) => {
      if (response.status === 200) {
        let staffType = response.data.data;
        this.setState(
          {
            staffType,
          },
          function () {
            this.getDepartments();
          }
        );
      }
    });
  };

  handleBdayChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    const bsDate = convertNepaliDate(e.target.value);

    let newState = cloneDeep([...this.state[stateName]]);
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[
      customTableFieldIndex
    ].bsDateField = bsDate;
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].year =
      e.target.value.substring(0, 4);
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].month =
      e.target.value.substring(5, 7).substring(0, 1) === "0"
        ? e.target.value.substring(6, 7)
        : e.target.value.substring(5, 7);
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].day =
      e.target.value.substring(8, 10).substring(0, 1) === "0"
        ? e.target.value.substring(9, 10)
        : e.target.value.substring(8, 10);
    if (e.target.value.length > 0) {
      errors[stateName].year = false;
      errors[stateName].month = false;
      errors[stateName].day = false;
    } else {
      errors[stateName].year = true;
      errors[stateName].month = true;
      errors[stateName].day = true;
    }
    this.setState({ [stateName]: newState, errors });
  };

  checkIfEmailAvailable = (e, email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmail = re.test(email);
    if (validEmail) {
      const data = {
        username: email,
      };
      this.setState({ emailFetching: true, isEmailAvailable: false }, () => {
        axiosPost(
          URL.isPeopleAvailable,
          data,
          (response) => {
            if (response.status === 200) {
              this.setState({
                isEmailAvailable: response.data.data === null ? true : false,
                emailFetching: false,
              });
            }
          },
          (err) => {
            this.setState({ isEmailAvailable: false, emailFetching: false });
          }
        );
      });
    } else {
      this.setState({ isEmailAvailable: false });
    }
  };
  updateStateWithValues = async (data) => {
    const customTableFieldValuesByUsername = data.customTableFieldValues;
    const addressFields = data.peopleAddresses;
    let admissionTableFieldGroups = [...this.state.admissionTableFieldGroups];

    //set recruitment image
    const imageURL = URL.imageSelectURL + data.photo;
    const response = await fetch(imageURL);
    const imagedata = await response.blob();
    const metadata = {
      type: "image/jpeg",
    };
    const image =
      data.photo != null ? new File([imagedata], data.photo, metadata) : null;

    for (let i = 0; i < admissionTableFieldGroups.length; i++) {
      let group = admissionTableFieldGroups[i];
      const customTableFields = group.customTableFields;
      for (let j = 0; j < customTableFields.length; j++) {
        let field = customTableFields[j];
        if (field.fieldTypeCode === "pre-peop") {
          field.gender = data.gender;
          field.nationality = data.nationality;
          field.email = data.emailAddress;
          field.year = new Date(data.dateOfBirth).getFullYear();
          field.month = new Date(data.dateOfBirth).getMonth() + 1;
          field.day = new Date(data.dateOfBirth).getDate();
          if (
            (field.email && field.email != null) ||
            (field.email && field.email !== "")
          ) {
            this.checkIfEmailAvailable(null, data.emailAddress);
          }
          customTableFieldValuesByUsername.forEach((fieldU) => {
            if (fieldU.dbTableCode === "tbl-ppl") {
              field[fieldU.customTableFieldName] = fieldU.value;
            }
          });
        }
        for (let k = 0; k < addressFields.length; k++) {
          const addressField = addressFields[k];
          if (field.fieldTypeCode === "pre-addr") {
            //
            customTableFieldValuesByUsername.forEach((fieldU) => {
              if (fieldU.dbTableCode === "tbl-add") {
                field[fieldU.customTableFieldName] = fieldU.value;
              }
            });
            //
            field.country = addressField.countryId;
            field.countryFirstLevelId = addressField.countryFirstLevelId;
            field.countrySecondLevelId = addressField.countrySecondLevelId;
            field.countryThirdLevelId = addressField.countryThirdLevelId;
            const countryFirstLevelPromise = new Promise((res, rej) => {
              axiosPost(
                URL.getCountryFirstLevel,
                {
                  countryId: addressField.countryId,
                },
                (response) => {
                  if (response.status === 200) {
                    res(response.data.data);
                  }
                }
              );
            });
            const countryFirstLevel = await countryFirstLevelPromise;
            if (countryFirstLevel) {
              field.countryFirstLevel = countryFirstLevel;
            }
            const countrySecondLevelPromise = new Promise((res, rej) => {
              axiosPost(
                URL.getCountrySecondLevel,
                {
                  countryId: addressField.countryId,
                  countryFirstLevelId: addressField.countryFirstLevelId,
                },
                (response) => {
                  if (response.status === 200) {
                    res(response.data.data);
                  }
                }
              );
            });
            const countrySecondLevel = await countrySecondLevelPromise;
            if (countrySecondLevel) {
              field.countrySecondLevel = countrySecondLevel;
            }
            const countryThirdLevelPromise = new Promise((res, rej) => {
              axiosPost(
                URL.getCountryThirdLevel,
                {
                  countryId: addressField.countryId,
                  countryFirstLevelId: addressField.countryFirstLevelId,
                  countrySecondLevelId: addressField.countrySecondLevelId,
                },
                (response) => {
                  if (response.status === 200) {
                    res(response.data.data);
                  }
                }
              );
            });
            const countryThirdLevel = await countryThirdLevelPromise;
            if (countryThirdLevel) {
              field.countryThirdLevel = countryThirdLevel;
            }
          }
        }
      }
    }

    this.setState({
      admissionTableFieldGroups,
      recruitmentImage: image,
      customTableFieldValuesByUsername,
    });
  };

  checkisUserAvailable = () => {
    const data = {
      username: this.state.username,
    };
    this.setState({ userFetching: true, userFound: false }, () => {
      axiosPost(
        URL.isPeopleAvailable,
        data,
        (response) => {
          if (response.status === 200) {
            this.setState({
              userFound: response.data.data === null ? false : true,
              userFetching: false,
            });
            response.data.data &&
              this.updateStateWithValues(response.data.data);
          }
        },
        (err) => {
          this.setState({ userFound: false, userFetching: false });
        }
      );
    });
  };

  /**
   * This methods binds the input fields with its respective state, say value
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let errors = { ...this.state.errors };
    if (value.length > 0) {
      errors[name] = false;
    } else {
      errors[name] = true;
    }
    this.setState(
      {
        errors,
        userFound:
          name === "username" && value.length === 0
            ? null
            : this.state.userFound,
      },
      () => {
        this.setState(
          {
            [name]: value,
          },
          () => {
            if (name == "username") {
              this.checkisUserAvailable(e);
            }
          }
        );
      }
    );
  };

  /**
   * This handles change in the Identity Cluster Fields
   * @param  e is the synthetic event
   * @param idx is the corresponding index of the mapped value
   */
  handleCustomFieldChange = async (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName,
    fields
  ) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };

    // if (
    //   name === "email" &&
    //   this.state.username !== "" &&
    //   value === this.state.username
    // ) {
    //   //check email value with username
    //   swal({
    //     title: "Warning",
    //     text: "Email Address already exist. ",
    //   });
    //   return;
    // } else {
    newState[studentFieldIndex].customTableFields[customTableFieldIndex][name] =
      value;
    // }

    // newState[studentFieldIndex].customTableFields[customTableFieldIndex][name] =value;

    if (name === "country") {
      const data = {
        countryId: e.target.value,
      };
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "countryFirstLevelId"
      ] = "";
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "countrySecondLevelId"
      ] = "";
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "countryThirdLevelId"
      ] = "";

      const countryFirstLevelPromise = new Promise((res, rej) => {
        axiosPost(URL.getCountryFirstLevel, data, (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        });
      });

      const countryFirstLevel = await countryFirstLevelPromise;
      if (countryFirstLevel) {
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countryFirstLevel"
        ] = countryFirstLevel;
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countrySecondLevel"
        ] = [];
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countryThirdLevel"
        ] = [];
      }
    }
    if (name === "countryFirstLevelId") {
      const data = {
        countryId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
            "country"
          ],
        countryFirstLevelId: e.target.value,
      };

      const countrySecondLevelPromise = new Promise((res, rej) => {
        axiosPost(URL.getCountrySecondLevel, data, (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        });
      });

      const countrySecondLevel = await countrySecondLevelPromise;
      if (countrySecondLevel)
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countrySecondLevel"
        ] = countrySecondLevel;
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "countryThirdLevel"
      ] = [];
    }

    if (name === "countrySecondLevelId") {
      const data = {
        countryId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
            "country"
          ],
        countryFirstLevelId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
            "countryFirstLevelId"
          ],
        countrySecondLevelId: e.target.value,
      };

      const countryThirdLevelPromise = new Promise((res, rej) => {
        axiosPost(URL.getCountryThirdLevel, data, (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        });
      });
      const countryThirdLevel = await countryThirdLevelPromise;
      if (countryThirdLevel)
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countryThirdLevel"
        ] = countryThirdLevel;
    }
    if (fields && fields.mandatory)
      if (value.length > 0) {
        if (
          newState[studentFieldIndex].customTableFields[customTableFieldIndex]
            .error
        ) {
          newState[studentFieldIndex].customTableFields[
            customTableFieldIndex
          ].error[fields.fieldName] = false;
        }
      } else {
        if (
          newState[studentFieldIndex].customTableFields[customTableFieldIndex]
            .error
        ) {
          newState[studentFieldIndex].customTableFields[
            customTableFieldIndex
          ].error[fields.fieldName] = true;
        }
      }
    this.setState({ [stateName]: newState, errors });
  };

  handleCustomFieldTablesChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    columnIndex,
    subColumnIndex,
    rowIndex,
    stateName,
    subColumn
  ) => {
    let newState = cloneDeep(this.state[stateName]);
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    if (subColumn) {
      newState[studentFieldIndex].customTableFields[
        customTableFieldIndex
      ].customFieldTableColumns[columnIndex].rows[rowIndex][name] = value;
    } else {
      newState[studentFieldIndex].customTableFields[customTableFieldIndex].rows[
        rowIndex
      ][name] = value;
    }
    this.setState({ [stateName]: newState });
  };

  handleDateChange = (
    objKey,
    objValue,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    let newState = cloneDeep([...this.state[stateName]]);
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[customTableFieldIndex][
      objKey
    ] = objValue;
    if (objValue) {
      errors[stateName][objKey] = false;
    } else {
      errors[stateName][objKey] = true;
    }
    this.setState({ [stateName]: newState, errors });
  };

  handleDepartmentChange = (items, type) => {
    this.setState({ [type]: items });
  };

  handleDuplicate = (groupIndex, fieldIndex, stateName, subCol, ctfcIndex) => {
    let newState = cloneDeep(this.state[stateName]);
    if (subCol === "subCol") {
      let length =
        newState[groupIndex].customTableFields[fieldIndex]
          .customFieldTableColumns[ctfcIndex].rows.length;
      newState[groupIndex].customTableFields[
        fieldIndex
      ].customFieldTableColumns[ctfcIndex].rows.push({
        rowId: length === 0 ? length++ : length,
      });
    } else {
      if (newState[groupIndex].customTableFields[fieldIndex].rows) {
        let length =
          newState[groupIndex].customTableFields[fieldIndex].rows.length;
        newState[groupIndex].customTableFields[fieldIndex].rows.push({
          rowId: length === 0 ? length++ : length,
        });
      }
    }
    this.setState({ [stateName]: newState });
  };

  handleFileChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    var data = e.target.files[0];
    var extension = data.name.split(".").pop();
    if (extension === "jpeg" || extension === "png" || extension === "jpg") {
      this.setState({ recruitmentImage: data });
    } else {
      swal(MESSAGEHEADER.error, "Unsupported File Type", "");
    }
  };

  /**
   * @author Suyog Manandhar
   *
   * This method handles Change for nested JSON Object i.e. recruitmentConfiguration
   * @param e is the event
   * @param gIdx is the index of the corresponding input field
   * @param stateName is the name of the state input field binded with
   * @param fIdx is the index of the 2nd dimension JSON Object i.e. customTableFields
   */
  handleNestedChange = (e, gIdx, stateName, fIdx) => {
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (e.target.type === "text") {
      newState[gIdx].customTableFields[fIdx][name] = value;
    } else {
      newState[gIdx].customTableFields[fIdx][name] = value;
    }
    const fieldName = newState[gIdx].customTableFields[fIdx].fieldName;
    if (e.target.value.length > 0) {
      if (newState[gIdx].customTableFields[fIdx].error)
        newState[gIdx].customTableFields[fIdx].error = false;
    } else {
      if (newState[gIdx].customTableFields[fIdx].error)
        newState[gIdx].customTableFields[fIdx].error = true;
    }
    this.setState({
      [stateName]: newState,
      errors,
    });
  };

  handleRemoveDuplicate = (
    groupIndex,
    fieldIndex,
    stateName,
    rowIndex,
    subCol,
    ctfcIndex
  ) => {
    let newState = cloneDeep(this.state[stateName]);
    if (subCol === "subCol") {
      newState[groupIndex].customTableFields[
        fieldIndex
      ].customFieldTableColumns[ctfcIndex].rows.splice(rowIndex, 1);
    } else {
      newState[groupIndex].customTableFields[fieldIndex].rows.splice(
        rowIndex,
        1
      );
    }
    this.setState({ [stateName]: newState });
  };

  toggleDatePicker = () => {
    this.setState(
      {
        yearOfJoiningToggleDate: !this.state.yearOfJoiningToggleDate,
      },
      () => {
        if (this.state.yearOfJoiningToggleDate) {
          const nepalidate = convertNepaliDate(this.state.dateOfHire);

          this.setState({
            dateOfHireInBS: nepalidate,
          });
        } else {
          this.setState({
            dateOfHireInBS: null,
          });
        }
      }
    );
  };

  handleChangeNepaliDate = ({ bsDate, adDate }) => {
    this.setState({
      dateOfHireInBS: bsDate,
      dateOfHire: adDate,
    });
  };

  icToggleDatePicker = () => {
    this.setState({
      identityClusterDatePickerToggle:
        !this.state.identityClusterDatePickerToggle,
    });
  };

  handleStaticChange = (e) => {
    let err = 0;
    const name = e.target.name;
    const type = e.target.type;
    const value =
      type === "checkbox"
        ? e.target.checked
        : type === "file"
        ? e.target.files[0].name.split(".").pop() !== "pdf"
          ? err++
          : e.target.files[0]
        : // ? e.target.files[0]
          e.target.value;

    if (err > 0) {
      swal(MESSAGEHEADER.error, "Unsupported File Type", "");
      e.target.value = "";
    } else {
      this.setState({ [name]: value });
    }
  };

  //   renderErrorText = () => {
  //     return <span className="error-text">mandatory field</span>;
  //   };

  setUpdateConfiguration = () => {
    let staffData = this.props.staffData;
    this.setState({
      academicYear: staffData.academicYearId,
      staffTypeId: staffData.staffTypeId,
      dateOfHire: moment(staffData.dateOfHire).format("YYYY-MM-DD"),
      department: staffData.departments[0]?.id,
      designation: staffData.designations[0]?.id,
      recruitmentImage: staffData.people.photo,
      gender: staffData.people.gender,
      nationality: staffData.people.nationality,
      email: staffData.people.emailAddress,
      selectedDepartments:
        staffData.departments.length > 0
          ? staffData.departments.map((el) => ({
              name: el.details,
              label: el.name,
              id: el.id,
            }))
          : [],
      selectedDesignations:
        staffData.designations.length > 0
          ? staffData.designations.map((el) => ({
              name: el.details,
              label: el.name,
              id: el.id,
            }))
          : [],
    });
  };

  setUpdateCustomFieldValues = () => {
    let fieldGroups = [...this.state.admissionTableFieldGroups];
    if (fieldGroups.length > 0) {
      fieldGroups.forEach((el) => {
        if (el.customTableFields.length > 0) {
          el.customTableFields.forEach(async (field) => {
            if (field.fieldTypeCode === "pre-peop") {
              field.gender = this.props.staffData.people.gender;
              field.nationality = this.props.staffData.people.nationality;
              field.email = this.props.staffData.people.emailAddress;
              field.year = new Date(
                this.props.staffData.people.dateOfBirth
              ).getFullYear();
              field.month =
                new Date(this.props.staffData.people.dateOfBirth).getMonth() +
                1;
              field.day = new Date(
                this.props.staffData.people.dateOfBirth
              ).getDate();

              if (field.customTableFieldValues) {
                field.customTableFieldValues.forEach((v) => {
                  field[v.customTableFieldName] = v.value;
                });
              }
            }
            if (field.fieldTypeCode === "table") {
              field.customFieldTableColumns.forEach((column) => {
                if (column.customFieldTableSubColumns.length === 0) {
                  column.customFieldTableColumnValues.forEach((value) => {
                    const index = field.rows.findIndex(
                      (row) => row.rowId === value.rowId
                    );
                    if (index !== -1) {
                      field.rows[index][column.name] = value.value;
                    } else {
                      field.rows.push({
                        rowId: value.rowId,
                        [column.name]: value.value,
                      });
                    }
                  });
                } else {
                  column.customFieldTableSubColumns.forEach((subCol) => {
                    subCol.customFieldTableColumnValues.forEach((value) => {
                      const index = column.rows.findIndex(
                        (row) => row.rowId === value.rowId
                      );
                      if (index !== -1) {
                        column.rows[index][subCol.name] = value.value;
                      } else {
                        column.rows.push({
                          rowId: value.rowId,
                          [subCol.name]: value.value,
                        });
                      }
                    });
                  });
                }
              });
            }
            if (field.fieldTypeCode === "radio") {
              field[`value${field.id}`] = field.value;
            }
          });
        }
      });
    }
    this.setState({ admissionTableFieldGroups: fieldGroups }, async () => {
      let newadmissionTableFieldGroups = [
        ...this.state.admissionTableFieldGroups,
      ];

      //the use of gold old for loop is to keep the async await working inside a loop;
      // cause forEach failed to do so
      for (let i = 0; i < newadmissionTableFieldGroups.length; i++) {
        let group = newadmissionTableFieldGroups[i];
        const customTableFields = group.customTableFields;
        for (let j = 0; j < customTableFields.length; j++) {
          let customTableField = customTableFields[j];

          if (customTableField.customTableFieldValues) {
            customTableField.customTableFieldValues.forEach((v) => {
              customTableField[v.customTableFieldName] = v.value;
            });
          }
          if (this.props.staffData.people.peopleAddresses) {
            for (
              let k = 0;
              k < this.props.staffData.people.peopleAddresses.length;
              k++
            ) {
              let addressField = this.props.staffData.people.peopleAddresses[k];
              if (
                customTableField.id === addressField.customTableFieldId &&
                customTableField.fieldTypeCode === "pre-addr"
              ) {
                customTableField.country = addressField.countryId;
                customTableField.countryFirstLevelId =
                  addressField.countryFirstLevelId;
                customTableField.countrySecondLevelId =
                  addressField.countrySecondLevelId;
                customTableField.countryThirdLevelId =
                  addressField.countryThirdLevelId;
                const countryFirstLevelPromise = new Promise((res, rej) => {
                  axiosPost(
                    URL.getCountryFirstLevel,
                    {
                      countryId: addressField.countryId,
                    },
                    (response) => {
                      if (response.status === 200) {
                        res(response.data.data);
                      }
                    }
                  );
                });
                const countryFirstLevel = await countryFirstLevelPromise;
                if (countryFirstLevel) {
                  customTableField.countryFirstLevel = countryFirstLevel;
                }
                const countrySecondLevelPromise = new Promise((res, rej) => {
                  axiosPost(
                    URL.getCountrySecondLevel,
                    {
                      countryId: addressField.countryId,
                      countryFirstLevelId: addressField.countryFirstLevelId,
                    },
                    (response) => {
                      if (response.status === 200) {
                        res(response.data.data);
                      }
                    }
                  );
                });
                const countrySecondLevel = await countrySecondLevelPromise;
                if (countrySecondLevel) {
                  customTableField.countrySecondLevel = countrySecondLevel;
                }
                const countryThirdLevelPromise = new Promise((res, rej) => {
                  axiosPost(
                    URL.getCountryThirdLevel,
                    {
                      countryId: addressField.countryId,
                      countryFirstLevelId: addressField.countryFirstLevelId,
                      countrySecondLevelId: addressField.countrySecondLevelId,
                    },
                    (response) => {
                      if (response.status === 200) {
                        res(response.data.data);
                      }
                    }
                  );
                });
                const countryThirdLevel = await countryThirdLevelPromise;
                if (countryThirdLevel) {
                  customTableField.countryThirdLevel = countryThirdLevel;
                }
              }
            }
          }
        }
      }
      this.setState({
        admissionTableFieldGroups: newadmissionTableFieldGroups,
      });
    });
  };

  staffStatus = () => {
    axiosPost(URL.getRecruitmentStatus, {}, (response) => {
      if (response.status === 200) {
        this.setState({ staffStatus: response.data.data }, function () {
          let status = this.state.staffStatus;
          let id = "";
          status.forEach((el) => {
            if (el.name === "Current") {
              id = el.id;
            }
          });
          this.setState({ recruitmentStatusId: id }, function () {
            !this.props.editMode &&
              this.props.getStaffListPagination(
                this.state.recruitmentStatusId,
                this.state.offset,
                this.state.pageSize
              );
          });
        });
      }
    });
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (
  //     prevState.admissionTableFieldGroups !== nextProps.recruitmentConfiguration
  //   ) {
  //     return {
  //       admissionTableFieldGroups: nextProps.recruitmentConfiguration,
  //       isLoading: false,
  //     };
  //   }
  // }

  /**
   * This method is invoked on clicking submit button
   */
  submit = (e) => {
    e.preventDefault();

    //validation for mandatory fields
    let errorFields = [];
    let newAdmissionTableFieldGroups = [
      ...this.state.admissionTableFieldGroups,
    ];
    let newErrors = { ...this.state.errors };

    const staticFields = [
      "academicYear",
      "staffTypeId",
      "dateOfHire",
      // "department",
      // "designation",
    ];
    staticFields.forEach((field) => {
      if (this.state[field] === "") {
        errorFields.push(field);
        newErrors[field] = true;
      }
    });

    if (
      this.state.selectedDepartments === null ||
      this.state.selectedDepartments === []
    ) {
      errorFields.push("department");
      newErrors["department"] = true;
    }

    if (
      this.state.selectedDesignations === null ||
      this.state.selectedDesignations === []
    ) {
      errorFields.push("designation");
      newErrors["designation"] = true;
    }

    newAdmissionTableFieldGroups.forEach((config) => {
      config.customTableFields.forEach((field) => {
        if (
          field.fieldTypeCode !== addressCode &&
          field.fieldTypeCode !== peopleCode
        ) {
          if (
            field.isMandatory &&
            (field.value === "" || field.value === null)
          ) {
            errorFields.push(`${field.fieldName} `);
            field.error = true;
          }
        }
        if (field.fieldTypeCode === peopleCode) {
          field.error = {};
          this.state.customFields.forEach((cField) => {
            if (field[cField.fieldName] === undefined && cField.isMandatory) {
              errorFields.push(`${cField.fieldName} `);
              field.error[cField.fieldName] = true;
            }
          });
        }
        if (field.fieldTypeCode === addressCode) {
          field.error = {};
          if (!field.country || field.country == "" || field.country === null) {
            errorFields.push("Country");
            field.error.stdCountry = true;
          } else {
            if (field.countryFirstLevelId === "") {
              errorFields.push("Province");
              field.error.stdProvince = true;
            }
            if (field.countrySecondLevelId === "") {
              errorFields.push("District");
              field.error.stdDistrict = true;
            }
            if (field.countryThirdLevelId === "") {
              errorFields.push("City");
              field.error.stdCity = true;
            }
          }
          this.state.addressCustomFields.forEach((aField) => {
            // let keys = Object.keys(field);
            if (field[aField.fieldName] === undefined && aField.isMandatory) {
              errorFields.push(`${aField.fieldName} `);
              field.error[aField.fieldName] = true;
            }
          });
        }
      });
    });

    for (let i = 0; i < this.state.admissionTableFieldGroups.length; i++) {
      for (
        let j = 0;
        j < this.state.admissionTableFieldGroups[i].customTableFields.length;
        j++
      ) {
        const field =
          this.state.admissionTableFieldGroups[i].customTableFields[j];
        if (
          this.state.admissionTableFieldGroups[i].customTableFields[j]
            .fieldTypeCode === peopleCode
        ) {
          if (!field.year) {
            newErrors.admissionTableFieldGroups.year = true;
          }
          if (!field.month) {
            newErrors.admissionTableFieldGroups.month = true;
          }
          if (!field.day) {
            newErrors.admissionTableFieldGroups.day = true;
          }
          if (!field.year || !field.month || !field.day) {
            errorFields.push("Date of birth");
          }
          if (!field.gender) {
            errorFields.push("Gender");
            newErrors.admissionTableFieldGroups.gender = true;
          } else {
            newErrors.admissionTableFieldGroups.gender = false;
          }
          if (field.nationality === null || field.nationality === undefined) {
            errorFields.push("Nationality");
            newErrors.admissionTableFieldGroups.nationality = true;
          } else {
            newErrors.admissionTableFieldGroups.nationality = false;
          }

          if (field.email === null || field.email === undefined) {
            errorFields.push("Email Address");
            newErrors.admissionTableFieldGroups.email = true;
          } else {
            newErrors.admissionTableFieldGroups.email = false;
          }
          if (
            field.email !== null &&
            field.email !== undefined &&
            field.email !== "" &&
            !emailValidate(field.email)
          ) {
            swal({
              title: "Warning",
              text: "Staff's email address is not valid. Please enter valid email address.",
            });
            return;
          }
        } else {
          if (
            field.isMandatory &&
            field.fieldTypeCode !== peopleCode &&
            field.fieldTypeCode !== addressCode &&
            (field.value === null ||
              field.value === undefined ||
              field.value === "")
          ) {
            errorFields.push(`${field.fieldName} `);
            field.error = true;
          }
        }
      }
    }

    if (errorFields.length > 0) {
      swal({
        title: `${errorFields.length} mandatory fields are empty.`,
        content: (
          <div className="row">
            {errorFields.map((field) => {
              return (
                <div className="col-md-6">
                  <ul className="text-left">
                    <li>
                      {field === "academicYear"
                        ? "Academic Year"
                        : field === "staffTypeId"
                        ? "Staff Type"
                        : field === "dateOfHire"
                        ? "Date of hire"
                        : field === "department"
                        ? "Department"
                        : field === "designation"
                        ? "Designation"
                        : field}
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        ),
        dangerMode: true,
      });
      this.setState({
        errors: newErrors,
        admissionTableFieldGroups: newAdmissionTableFieldGroups,
      });
      return;
    }

    //static validation for fields in identity and address clusters
    //student
    // for (let i = 0; i < this.state.admissionTableFieldGroups.length; i++) {
    //   for (
    //     let j = 0;
    //     j < this.state.admissionTableFieldGroups[i].customTableFields.length;
    //     j++
    //   ) {
    //     const field =
    //       this.state.admissionTableFieldGroups[i].customTableFields[j];
    //     if (
    //       this.state.admissionTableFieldGroups[i].customTableFields[j]
    //         .fieldTypeCode === peopleCode
    //     ) {
    //       if (
    //         field.year === null ||
    //         field.month === null ||
    //         field.day === null
    //       ) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert staff's date of birth. ",
    //         });
    //         return;
    //       }
    //       if (field.gender === null || field.gender === "") {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert staff's gender.",
    //         });
    //         return;
    //       }
    //       if (field.nationality === null || field.nationality === undefined) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert staff's nationality.",
    //         });
    //         return;
    //       }

    //       // if (
    //       //   field.email === null ||
    //       //   field.email === undefined ||
    //       //   field.email === ""
    //       // ) {
    //       //   const department = this.state.departments.find(
    //       //     (d) => d.id === parseInt(this.state.department)
    //       //   );
    //       //   if (department && department?.label !== "Support & Services") {
    //       //     swal({
    //       //       title: "Warning",
    //       //       text: "Please insert staff's email address.",
    //       //     });
    //       //     return;
    //       //   }
    //       // }
    //       if (
    //         field.email === null ||
    //         field.email === undefined ||
    //         field.email === ""
    //       ) {
    //         const staffType = this.state.staffType.find(
    //           (d) => d.id === parseInt(this.state.staffTypeId)
    //         );
    //         // if (staffType && staffType?.name !== "Support & Services") {
    //         if (staffType && staffType?.code !== "st-suse") {
    //           swal({
    //             title: "Warning",
    //             text: "Please insert staff's email address.",
    //           });
    //           return;
    //         }
    //       }
    //     }
    //     if (
    //       this.state.admissionTableFieldGroups[i].customTableFields[j]
    //         .fieldTypeCode === addressCode
    //     ) {
    //       if (field.country === null || field.country === undefined) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert staff's country.",
    //         });
    //         return;
    //       }
    //     }
    //     if (
    //       field.email !== null &&
    //       field.email !== undefined &&
    //       field.email !== "" &&
    //       !emailValidate(field.email)
    //     ) {
    //       swal({
    //         title: "Warning",
    //         text: "Staff's email address is not valid. Please enter valid email address.",
    //       });
    //       return;
    //     }
    //   }
    // }

    //for image
    // if (
    //   (this.state.recruitmentImage === null ||
    //     this.state.recruitmentImage === "") &&
    //   !this.props.editMode
    // ) {
    //   swal({
    //     title: "Warning",
    //     text: "Please insert photo of the staff.",
    //   });
    //   return;
    // }

    //for document
    // if (this.state.cv === null && !this.props.editMode) {
    //   swal({
    //     title: "Warning",
    //     text: "Please insert cv document of the staff.",
    //   });
    //   return;
    // }

    let data = {
      id: this.props.recruitmentId,
      staffTypeId: parseInt(this.state.staffTypeId),
      dateOfHire: this.state.dateOfHire,
      // departmentId: parseInt(this.state.department),
      // designationId: parseInt(this.state.designation),
      departmentIds: this.state.selectedDepartments?.map((sd) => sd.id),
      designationIds: this.state.selectedDesignations?.map((sd) => sd.id),
      academicYearId: parseInt(this.state.academicYear),
      recruitmentStatusId: parseInt(this.props.recruitmentStatusId),
      username: this.state.parentCredentials ? this.state.username : null,
      // emergencyContactName: this.state.emergencyContactName,
      // emergencyContactNumber: this.state.emergencyContactNumber,
    };
    let people = {},
      customTableFieldValues = [],
      peopleDocument = { documentType: "cv" };
    people.name = "";
    if (this.props.editMode) people.id = this.props.peopleId;
    people.peopleAddresses = [];
    people.peopleDocument = peopleDocument;
    let addressCustomTableFieldValues = [];
    let pushToAddress = {};

    this.state.admissionTableFieldGroups.forEach((config) => {
      config.customTableFields.map((field) => {
        if (
          field.fieldTypeCode !== addressCode &&
          field.fieldTypeCode !== peopleCode &&
          field.fieldTypeCode !== "table"
        ) {
          if (field.isMulti && field.fieldTypeCode === "multi-checkbox") {
            let value = [];
            field.customTableFieldDatas.forEach((data) => {
              if (field[`data${field.id}`]) {
                value.push(data.data);
              }
            });
            customTableFieldValues.push({
              dbTableId: field.dbTableId,
              customTableFieldId: field.id,
              value: value.join(","),
              groupId: config.id,
            });
          } else if (field.isMulti && field.fieldTypeCode === "radio") {
            customTableFieldValues.push({
              dbTableId: field.dbTableId,
              customTableFieldId: field.id,
              value: field[`value${field.id}`],
              groupId: config.id,
            });
          } else {
            customTableFieldValues.push({
              dbTableId: field.dbTableId,
              customTableFieldId: field.id,
              value: field.value,
              groupId: config.id,
            });
          }
        }
        if (field.fieldTypeCode === peopleCode) {
          people.nationality = field.nationality;
          people.dateOfBirth = new Date(
            `${field.year}-${field.month}-${field.day}`
          );
          people.gender = field.gender;
          people.emailAddress = field.email;
          this.state.customFields.forEach((cField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (cField.fieldName === key) {
                people.name = people.name.concat(`${field[key]} `);
                customTableFieldValues.push({
                  dbTableId: cField.dbTableId,
                  customTableFieldId: cField.id,
                  value: field[key],
                  groupId: config.id,
                });
              }
            });
          });
        }
        if (field.fieldTypeCode === addressCode) {
          pushToAddress = {};
          addressCustomTableFieldValues = [];
          pushToAddress.countryId = field.country;
          pushToAddress.customTableFieldId = field.id;
          pushToAddress.countryFirstLevelId = field.countryFirstLevelId;
          pushToAddress.countrySecondLevelId = field.countrySecondLevelId;
          pushToAddress.countryThirdLevelId = field.countryThirdLevelId;
          this.state.addressCustomFields.forEach((adCField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (adCField.fieldName === key) {
                addressCustomTableFieldValues.push({
                  dbTableId: adCField.dbTableId,
                  customTableFieldId: adCField.id,
                  value: field[key],
                  groupId: config.id,
                });
              }
            });
          });
          pushToAddress.addressCustomTableFieldValues =
            addressCustomTableFieldValues;
          people.peopleAddresses.push(pushToAddress);
        }
        if (field.fieldTypeCode === "table") {
          let customFieldTableColumnValues = [];
          field.customFieldTableColumns.forEach((column) => {
            if (column.customFieldTableSubColumns?.length === 0) {
              field.rows.forEach((row, rowIndex) => {
                if (row[column.name] && column.fieldTypeCode === "checkbox") {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: config.id,
                    value: row[column.name],
                  });
                } else if (
                  row[column.name] === undefined &&
                  column.fieldTypeCode === "checkbox"
                ) {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: config.id,
                    value: false,
                  });
                } else if (
                  column.fieldTypeCode === "radio" &&
                  row[`${column.name}${rowIndex}`]
                ) {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: config.id,
                    value: row[`${column.name}${rowIndex}`],
                  });
                } else if (row[column.name]) {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: config.id,
                    value: row[column.name],
                  });
                }
              });
            } else {
              column.customFieldTableSubColumns.forEach((subCol) => {
                column.rows.forEach((row, rowIndex) => {
                  if (row[subCol.name]) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: subCol.id,
                      groupId: config.id,
                      value: row[subCol.name],
                    });
                  }
                  //add code for checkbox if value is needed as false
                  else if (
                    row[subCol.name] === undefined &&
                    subCol.fieldTypeCode === "checkbox"
                  ) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: subCol.id,
                      groupId: config.id,
                      value: false,
                    });
                  } else if (
                    subCol.fieldTypeCode === "radio" &&
                    row[`${subCol.name}${rowIndex}`]
                  ) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: subCol.id,
                      groupId: config.id,
                      value: row[`${subCol.name}${rowIndex}`],
                    });
                  } else if (row[column.name]) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: column.id,
                      groupId: config.id,
                      value: row[column.name],
                    });
                  }
                });
              });
            }
          });
          customTableFieldValues.push({
            dbTableId: field.dbTableId,
            customTableFieldId: field.id,
            fieldTypeCode: "table",
            groupId: config.id,
            customFieldTableColumnValues: customFieldTableColumnValues,
          });
        }
      });
    });

    people.customTableFieldValues = customTableFieldValues;
    data.people = people;
    let formData = new FormData();
    formData.append("recruitment", JSON.stringify(data));
    formData.append("recruitmentImage", this.state.recruitmentImage);
    formData.append("uploadFile", this.state.cv);

    const endPoint = this.props.editMode
      ? URL.recruitmentUpdate
      : URL.recruitmentFormInsert;
    const message = this.props.editMode
      ? "Staff data updated successfully"
      : "Recruitment Successfully Inserted";
    this.setState({ isSubmitting: true }, () => {
      axiosPost(
        endPoint,
        formData,
        (response) => {
          if (response.status === 200) {
            swal(MESSAGEHEADER.success, message);
            this.staffStatus();
            !this.props.editMode
              ? this.props.getStaffListPagination(3, 0, 10)
              : this.props.handleStaffSearch("noAlphabet");
            document.getElementById("cv").value = "";
            this.setState(
              {
                staffTypeId: !this.props.editMode ? "" : this.state.staffTypeId,
                dateOfHire: !this.props.editMode ? "" : this.state.dateOfHire,
                isSubmitting: false,
                errors: {
                  staffTypeId: false,
                  dateOfHire: false,
                  admissionTableFieldGroups: {},
                },
                selectedDepartments: null,
                selectedDesignations: null,
              },
              () => {
                !this.props.editMode &&
                  this.props.getRecruitmentConfiguration();
              }
            );
          }
        },
        (err) => {
          this.setState({
            isSubmitting: false,
          });
          swal({
            title: "Error",
            text: `${err.response?.data.message}`,
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
        }
      );
    });
  };
  // submit = (e) => {
  //   e.preventDefault();

  //   //validation for mandatory fields
  //   let errorFields = [];
  //   let newAdmissionTableFieldGroups = [
  //     ...this.state.admissionTableFieldGroups,
  //   ];
  //   let newErrors = { ...this.state.errors };

  //   const staticFields = [
  //     "academicYear",
  //     "staffTypeId",
  //     "dateOfHire",
  //     // "department",
  //     // "designation",
  //   ];
  //   staticFields.forEach((field) => {
  //     if (this.state[field] === "") {
  //       errorFields.push(field);
  //       newErrors[field] = true;
  //     }
  //   });

  //   if (
  //     this.state.selectedDepartments === null ||
  //     this.state.selectedDepartments === []
  //   ) {
  //     errorFields.push("department");
  //     newErrors["department"] = true;
  //   }

  //   if (
  //     this.state.selectedDesignations === null ||
  //     this.state.selectedDesignations === []
  //   ) {
  //     errorFields.push("designation");
  //     newErrors["designation"] = true;
  //   }

  //   newAdmissionTableFieldGroups.forEach((config) => {
  //     config.customTableFields.forEach((field) => {
  //       if (
  //         field.fieldTypeCode !== addressCode &&
  //         field.fieldTypeCode !== peopleCode
  //       ) {
  //         if (
  //           field.isMandatory &&
  //           (field.value === "" || field.value === null)
  //         ) {
  //           errorFields.push(`${field.fieldName} `);
  //           field.error = true;
  //         }
  //       }
  //       if (field.fieldTypeCode === peopleCode) {
  //         field.error = {};
  //         this.state.customFields.forEach((cField) => {
  //           if (field[cField.fieldName] === undefined && cField.isMandatory) {
  //             errorFields.push(`${cField.fieldName} `);
  //             field.error[cField.fieldName] = true;
  //           }
  //         });
  //       }
  //       if (field.fieldTypeCode === addressCode) {
  //         field.error = {};
  //         this.state.addressCustomFields.forEach((aField) => {
  //           // let keys = Object.keys(field);
  //           if (field[aField.fieldName] === undefined && aField.isMandatory) {
  //             errorFields.push(`${aField.fieldName} `);
  //             field.error[aField.fieldName] = true;
  //           }
  //         });
  //       }
  //     });
  //   });

  //   if (errorFields.length > 0) {
  //     swal({
  //       title: `${errorFields.length} mandatory fields are empty.`,
  //       content: (
  //         <div className="row">
  //           {errorFields.map((field) => {
  //             return (
  //               <div className="col-md-4">
  //                 <ul className="text-left">
  //                   <li>{field}</li>
  //                 </ul>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       ),
  //       dangerMode: true,
  //     });
  //     this.setState({
  //       errors: newErrors,
  //       admissionTableFieldGroups: newAdmissionTableFieldGroups,
  //     });
  //     return;
  //   }

  //   //static validation for fields in identity and address clusters
  //   //student
  //   for (let i = 0; i < this.state.admissionTableFieldGroups.length; i++) {
  //     for (
  //       let j = 0;
  //       j < this.state.admissionTableFieldGroups[i].customTableFields.length;
  //       j++
  //     ) {
  //       const field =
  //         this.state.admissionTableFieldGroups[i].customTableFields[j];
  //       if (
  //         this.state.admissionTableFieldGroups[i].customTableFields[j]
  //           .fieldTypeCode === peopleCode
  //       ) {
  //         if (
  //           field.year === null ||
  //           field.month === null ||
  //           field.day === null
  //         ) {
  //           swal({
  //             title: "Warning",
  //             text: "Please insert staff's date of birth. ",
  //           });
  //           return;
  //         }
  //         if (field.gender === null || field.gender === "") {
  //           swal({
  //             title: "Warning",
  //             text: "Please insert staff's gender.",
  //           });
  //           return;
  //         }
  //         if (field.nationality === null || field.nationality === undefined) {
  //           swal({
  //             title: "Warning",
  //             text: "Please insert staff's nationality.",
  //           });
  //           return;
  //         }

  //         // if (
  //         //   field.email === null ||
  //         //   field.email === undefined ||
  //         //   field.email === ""
  //         // ) {
  //         //   const department = this.state.departments.find(
  //         //     (d) => d.id === parseInt(this.state.department)
  //         //   );
  //         //   if (department && department?.label !== "Support & Services") {
  //         //     swal({
  //         //       title: "Warning",
  //         //       text: "Please insert staff's email address.",
  //         //     });
  //         //     return;
  //         //   }
  //         // }
  //         if (
  //           field.email === null ||
  //           field.email === undefined ||
  //           field.email === ""
  //         ) {
  //           const staffType = this.state.staffType.find(
  //             (d) => d.id === parseInt(this.state.staffTypeId)
  //           );
  //           // if (staffType && staffType?.name !== "Support & Services") {
  //           if (staffType && staffType?.code !== "st-suse") {
  //             swal({
  //               title: "Warning",
  //               text: "Please insert staff's email address.",
  //             });
  //             return;
  //           }
  //         }
  //       }
  //       if (
  //         this.state.admissionTableFieldGroups[i].customTableFields[j]
  //           .fieldTypeCode === addressCode
  //       ) {
  //         if (field.country === null || field.country === undefined) {
  //           swal({
  //             title: "Warning",
  //             text: "Please insert staff's country.",
  //           });
  //           return;
  //         }
  //       }
  //       if (
  //         field.email !== null &&
  //         field.email !== undefined &&
  //         field.email !== "" &&
  //         !emailValidate(field.email)
  //       ) {
  //         swal({
  //           title: "Warning",
  //           text: "Staff's email address is not valid. Please enter valid email address.",
  //         });
  //         return;
  //       }
  //     }
  //   }

  //   //for image
  //   // if (
  //   //   (this.state.recruitmentImage === null ||
  //   //     this.state.recruitmentImage === "") &&
  //   //   !this.props.editMode
  //   // ) {
  //   //   swal({
  //   //     title: "Warning",
  //   //     text: "Please insert photo of the staff.",
  //   //   });
  //   //   return;
  //   // }

  //   //for document
  //   // if (this.state.cv === null && !this.props.editMode) {
  //   //   swal({
  //   //     title: "Warning",
  //   //     text: "Please insert cv document of the staff.",
  //   //   });
  //   //   return;
  //   // }

  //   let data = {
  //     id: this.props.recruitmentId,
  //     staffTypeId: parseInt(this.state.staffTypeId),
  //     dateOfHire: this.state.dateOfHire,
  //     // departmentId: parseInt(this.state.department),
  //     // designationId: parseInt(this.state.designation),
  //     departmentIds: this.state.selectedDepartments?.map((sd) => sd.id),
  //     designationIds: this.state.selectedDesignations?.map((sd) => sd.id),
  //     academicYearId: parseInt(this.state.academicYear),
  //     recruitmentStatusId: parseInt(this.props.recruitmentStatusId),
  //     username: this.state.parentCredentials ? this.state.username : null,
  //   };
  //   let people = {},
  //     customTableFieldValues = [],
  //     peopleDocument = { documentType: "cv" };
  //   people.name = "";
  //   if (this.props.editMode) people.id = this.props.peopleId;
  //   people.peopleAddresses = [];
  //   people.peopleDocument = peopleDocument;
  //   let addressCustomTableFieldValues = [];
  //   let pushToAddress = {};

  //   this.state.admissionTableFieldGroups.forEach((config) => {
  //     config.customTableFields.map((field) => {
  //       if (
  //         field.fieldTypeCode !== addressCode &&
  //         field.fieldTypeCode !== peopleCode &&
  //         field.fieldTypeCode !== "table"
  //       ) {
  //         if (field.isMulti && field.fieldTypeCode === "multi-checkbox") {
  //           let value = [];
  //           field.customTableFieldDatas.forEach((data) => {
  //             if (field[`data${field.id}`]) {
  //               value.push(data.data);
  //             }
  //           });
  //           customTableFieldValues.push({
  //             dbTableId: field.dbTableId,
  //             customTableFieldId: field.id,
  //             value: value.join(","),
  //             groupId: config.id,
  //           });
  //         } else if (field.isMulti && field.fieldTypeCode === "radio") {
  //           customTableFieldValues.push({
  //             dbTableId: field.dbTableId,
  //             customTableFieldId: field.id,
  //             value: field[`value${field.id}`],
  //             groupId: config.id,
  //           });
  //         } else {
  //           customTableFieldValues.push({
  //             dbTableId: field.dbTableId,
  //             customTableFieldId: field.id,
  //             value: field.value,
  //             groupId: config.id,
  //           });
  //         }
  //       }
  //       if (field.fieldTypeCode === peopleCode) {
  //         people.nationality = field.nationality;
  //         people.dateOfBirth = new Date(
  //           `${field.year}-${field.month}-${field.day}`
  //         );
  //         people.gender = field.gender;
  //         people.emailAddress = field.email;
  //         this.state.customFields.forEach((cField) => {
  //           let keys = Object.keys(field);
  //           keys.forEach((key) => {
  //             if (cField.fieldName === key) {
  //               people.name = people.name.concat(`${field[key]} `);
  //               customTableFieldValues.push({
  //                 dbTableId: cField.dbTableId,
  //                 customTableFieldId: cField.id,
  //                 value: field[key],
  //                 groupId: config.id,
  //               });
  //             }
  //           });
  //         });
  //       }
  //       if (field.fieldTypeCode === addressCode) {
  //         pushToAddress = {};
  //         addressCustomTableFieldValues = [];
  //         pushToAddress.countryId = field.country;
  //         pushToAddress.customTableFieldId = field.id;
  //         pushToAddress.countryFirstLevelId = field.countryFirstLevelId;
  //         pushToAddress.countrySecondLevelId = field.countrySecondLevelId;
  //         pushToAddress.countryThirdLevelId = field.countryThirdLevelId;
  //         this.state.addressCustomFields.forEach((adCField) => {
  //           let keys = Object.keys(field);
  //           keys.forEach((key) => {
  //             if (adCField.fieldName === key) {
  //               addressCustomTableFieldValues.push({
  //                 dbTableId: adCField.dbTableId,
  //                 customTableFieldId: adCField.id,
  //                 value: field[key],
  //                 groupId: config.id,
  //               });
  //             }
  //           });
  //         });
  //         pushToAddress.addressCustomTableFieldValues =
  //           addressCustomTableFieldValues;
  //         people.peopleAddresses.push(pushToAddress);
  //       }
  //       if (field.fieldTypeCode === "table") {
  //         let customFieldTableColumnValues = [];
  //         field.customFieldTableColumns.forEach((column) => {
  //           if (column.customFieldTableSubColumns?.length === 0) {
  //             field.rows.forEach((row, rowIndex) => {
  //               if (row[column.name] && column.fieldTypeCode === "checkbox") {
  //                 customFieldTableColumnValues.push({
  //                   rowId: row.rowId,
  //                   columnId: column.id,
  //                   groupId: config.id,
  //                   value: row[column.name],
  //                 });
  //               } else if (
  //                 row[column.name] === undefined &&
  //                 column.fieldTypeCode === "checkbox"
  //               ) {
  //                 customFieldTableColumnValues.push({
  //                   rowId: row.rowId,
  //                   columnId: column.id,
  //                   groupId: config.id,
  //                   value: false,
  //                 });
  //               } else if (
  //                 column.fieldTypeCode === "radio" &&
  //                 row[`${column.name}${rowIndex}`]
  //               ) {
  //                 customFieldTableColumnValues.push({
  //                   rowId: row.rowId,
  //                   columnId: column.id,
  //                   groupId: config.id,
  //                   value: row[`${column.name}${rowIndex}`],
  //                 });
  //               } else if (row[column.name]) {
  //                 customFieldTableColumnValues.push({
  //                   rowId: row.rowId,
  //                   columnId: column.id,
  //                   groupId: config.id,
  //                   value: row[column.name],
  //                 });
  //               }
  //             });
  //           } else {
  //             column.customFieldTableSubColumns.forEach((subCol) => {
  //               column.rows.forEach((row, rowIndex) => {
  //                 if (row[subCol.name]) {
  //                   customFieldTableColumnValues.push({
  //                     rowId: row.rowId,
  //                     columnId: subCol.id,
  //                     groupId: config.id,
  //                     value: row[subCol.name],
  //                   });
  //                 }
  //                 //add code for checkbox if value is needed as false
  //                 else if (
  //                   row[subCol.name] === undefined &&
  //                   subCol.fieldTypeCode === "checkbox"
  //                 ) {
  //                   customFieldTableColumnValues.push({
  //                     rowId: row.rowId,
  //                     columnId: subCol.id,
  //                     groupId: config.id,
  //                     value: false,
  //                   });
  //                 } else if (
  //                   subCol.fieldTypeCode === "radio" &&
  //                   row[`${subCol.name}${rowIndex}`]
  //                 ) {
  //                   customFieldTableColumnValues.push({
  //                     rowId: row.rowId,
  //                     columnId: subCol.id,
  //                     groupId: config.id,
  //                     value: row[`${subCol.name}${rowIndex}`],
  //                   });
  //                 } else if (row[column.name]) {
  //                   customFieldTableColumnValues.push({
  //                     rowId: row.rowId,
  //                     columnId: column.id,
  //                     groupId: config.id,
  //                     value: row[column.name],
  //                   });
  //                 }
  //               });
  //             });
  //           }
  //         });
  //         customTableFieldValues.push({
  //           dbTableId: field.dbTableId,
  //           customTableFieldId: field.id,
  //           fieldTypeCode: "table",
  //           groupId: config.id,
  //           customFieldTableColumnValues: customFieldTableColumnValues,
  //         });
  //       }
  //     });
  //   });

  //   people.customTableFieldValues = customTableFieldValues;
  //   data.people = people;
  //   let formData = new FormData();
  //   formData.append("recruitment", JSON.stringify(data));
  //   formData.append("recruitmentImage", this.state.recruitmentImage);
  //   formData.append("uploadFile", this.state.cv);

  //   const endPoint = this.props.editMode
  //     ? URL.recruitmentUpdate
  //     : URL.recruitmentFormInsert;
  //   const message = this.props.editMode
  //     ? "Staff data updated successfully"
  //     : "Recruitment Successfully Inserted";
  //   this.setState({ isSubmitting: true }, () => {
  //     axiosPost(
  //       endPoint,
  //       formData,
  //       (response) => {
  //         if (response.status === 200) {
  //           swal(MESSAGEHEADER.success, message);
  //           this.staffStatus();
  //           !this.props.editMode
  //             ? this.props.getStaffListPagination(3, 0, 10)
  //             : this.props.handleStaffSearch("noAlphabet");
  //           document.getElementById("cv").value = "";
  //           this.setState(
  //             {
  //               staffTypeId: !this.props.editMode ? "" : this.state.staffTypeId,
  //               dateOfHire: !this.props.editMode ? "" : this.state.dateOfHire,
  //               isSubmitting: false,
  //               errors: {
  //                 staffTypeId: false,
  //                 dateOfHire: false,
  //                 admissionTableFieldGroups: {},
  //               },
  //               selectedDepartments: null,
  //               selectedDesignations: null,
  //             },
  //             () => {
  //               !this.props.editMode &&
  //                 this.props.getRecruitmentConfiguration();
  //             }
  //           );
  //         }
  //       },
  //       (err) => {
  //         this.setState({
  //           isSubmitting: false,
  //         });
  //         swal({
  //           title: "Error",
  //           text: `${err.response?.data.message}`,
  //           allowOutsideClick: false,
  //           closeOnClickOutside: false,
  //         });
  //       }
  //     );
  //   });
  // };

  render() {
    return this.state.render ? (
      <>
        {this.state.isAvailable ? (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <div className="tt-group-header">
              Recruitment Form
              {checkWidgetAdminOrNot("Recruitment") === true &&
              !this.props.editMode ? (
                <button
                  className="tt-button tt-button-primary float-right permissionBtnCSS"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
            {!this.state.isLoading ? (
              <div className="tt-newListBox container-fluid">
                <StaffType
                  yearOfJoiningToggleDate={this.state.yearOfJoiningToggleDate}
                  toggleDatePicker={this.toggleDatePicker}
                  dateOfHireInBS={this.state.dateOfHireInBS}
                  handleChangeNepaliDate={this.handleChangeNepaliDate}
                  staffTypeId={this.state.staffTypeId}
                  handleChange={this.handleChange}
                  dateOfHire={this.state.dateOfHire}
                  errors={this.state.errors}
                  schoolEstablishedDate={this.state.schoolEstablishedDate}
                  departments={this.state.departments}
                  designations={this.state.designations}
                  getDesignations={this.getDesignations}
                  staffType={this.state.staffType}
                  academicYear={this.state.academicYear}
                  department={this.state.department}
                  designation={this.state.designation}
                  editMode={this.props.editMode}
                  handleDepartmentChange={this.handleDepartmentChange}
                  selectedDepartments={this.state.selectedDepartments}
                  selectedDesignations={this.state.selectedDesignations}
                  parentCredentials={this.state.parentCredentials}
                  username={this.state.username}
                  checkisUserAvailable={this.checkisUserAvailable}
                  userFetching={this.state.userFetching}
                  userFound={this.state.userFound}
                  inputForm={true}
                />
                {this.state.admissionTableFieldGroups
                  ? this.state.admissionTableFieldGroups.map((el, elIdx) => {
                      return (
                        <div key={elIdx}>
                          {!el.headerHidden ? (
                            <div className="tt-group-header">{el.name}</div>
                          ) : null}
                          {el.customTableFields.map(
                            (customTableField, cusIdx) => {
                              return (
                                <div key={cusIdx}>
                                  <div className="form-group row">
                                    <div className="col-md-1"></div>
                                    {customTableField.fieldName === null ||
                                    customTableField.fieldName === "" ? null : (
                                      <div className="col-md-3">
                                        <label htmlFor={`id${cusIdx}`}>
                                          <strong>
                                            {customTableField.fieldName}{" "}
                                            {customTableField.isMandatory && (
                                              <span className="tt-assessment-module-mandatory">
                                                *
                                              </span>
                                            )}
                                          </strong>
                                        </label>
                                      </div>
                                    )}
                                    <div
                                      className={
                                        customTableField.fieldName === null ||
                                        customTableField.fieldName === ""
                                          ? "col-md-10"
                                          : "col-md-7"
                                      }
                                    >
                                      {customTableField.fieldTypeCode ===
                                      addressCode ? (
                                        <AddressCluster
                                          inputForm={true}
                                          key={cusIdx + "key"}
                                          customTableField={customTableField}
                                          handleCustomFieldChange={
                                            this.handleCustomFieldChange
                                          }
                                          studentFieldIndex={elIdx}
                                          customTableFieldIndex={cusIdx}
                                          stateName="admissionTableFieldGroups"
                                          errors={this.state.errors}
                                          editMode={this.props.editMode}
                                          addressFields={
                                            this.state.addressFields
                                          }
                                          config={false}
                                        />
                                      ) : customTableField.fieldTypeCode ===
                                        peopleCode ? (
                                        <IdentityCluster
                                          inputForm={true}
                                          yearOfJoiningToggleDate={
                                            this.state.yearOfJoiningToggleDate
                                          }
                                          toggleDatePicker={
                                            this.toggleDatePicker
                                          }
                                          icToggleDatePicker={
                                            this.icToggleDatePicker
                                          }
                                          identityClusterDatePickerToggle={
                                            this.state
                                              .identityClusterDatePickerToggle
                                          }
                                          dateOfHireInBS={
                                            this.state.dateOfHireInBS
                                          }
                                          studentFieldIndex={elIdx}
                                          customTableFieldIndex={cusIdx}
                                          stateName="admissionTableFieldGroups"
                                          handleBdayChange={
                                            this.handleBdayChange
                                          }
                                          handleDateChange={
                                            this.handleDateChange
                                          }
                                          customFields={this.state.customFields}
                                          customTableField={customTableField}
                                          handleCustomFieldChange={
                                            this.handleCustomFieldChange
                                          }
                                          handleFileChange={
                                            this.handleFileChange
                                          }
                                          errors={this.state.errors}
                                          student={false}
                                          editMode={this.props.editMode}
                                          identityFields={
                                            this.state.identityFields
                                          }
                                          email={this.state.email}
                                          adbsToggler={true}
                                          config={false}
                                        />
                                      ) : (
                                        <>
                                          {customTableField.fieldTypeName ===
                                          "file" ? (
                                            <input
                                              type={
                                                customTableField.fieldTypeName
                                              }
                                              name="value"
                                              id={`id${cusIdx}`}
                                              checked={customTableField.value}
                                              onChange={(e) =>
                                                this.handleNestedChange(
                                                  e,
                                                  elIdx,
                                                  "admissionTableFieldGroups",
                                                  cusIdx
                                                )
                                              }
                                              className={
                                                this.state.errors
                                                  .admissionTableFieldGroups[
                                                  customTableField.fieldName
                                                ]
                                                  ? "form-control indicate-error"
                                                  : customTableField.fieldTypeName ===
                                                    "file"
                                                  ? ""
                                                  : "form-control"
                                              }
                                            />
                                          ) : (
                                            <div style={{ display: "flex" }}>
                                              {customTableField.isMulti &&
                                              customTableField.fieldTypeCode !==
                                                "select" &&
                                              customTableField.fieldTypeCode !==
                                                "table" ? (
                                                customTableField.customTableFieldDatas.map(
                                                  (data) => (
                                                    <>
                                                      <label className="mr-1">
                                                        {data.data}
                                                      </label>
                                                      <input
                                                        type={
                                                          customTableField.fieldTypeCode ===
                                                          "multi-checkbox"
                                                            ? "checkbox"
                                                            : customTableField.fieldTypeCode
                                                        }
                                                        name={
                                                          customTableField.fieldTypeCode ===
                                                          "radio"
                                                            ? "value" +
                                                              customTableField.id
                                                            : "data" +
                                                              customTableField.id
                                                        }
                                                        defaultChecked={
                                                          customTableField.value ===
                                                          data.data
                                                            ? true
                                                            : false
                                                        }
                                                        maxLength="255"
                                                        id={`id${cusIdx}`}
                                                        value={data.data}
                                                        onChange={(e) =>
                                                          this.handleNestedChange(
                                                            e,
                                                            elIdx,
                                                            "admissionTableFieldGroups",
                                                            cusIdx
                                                          )
                                                        }
                                                        className={
                                                          customTableField.error
                                                            ? "indicate-error mr-3"
                                                            : "mr-3"
                                                        }
                                                      />
                                                    </>
                                                  )
                                                )
                                              ) : (
                                                <>
                                                  {customTableField.isMulti &&
                                                  customTableField.fieldTypeCode ===
                                                    "select" ? (
                                                    <select
                                                      name="value"
                                                      className={
                                                        customTableField.error
                                                          ? "form-control indicate-error"
                                                          : "form-control"
                                                      }
                                                      value={
                                                        customTableField.value
                                                      }
                                                      onChange={(e) =>
                                                        this.handleNestedChange(
                                                          e,
                                                          elIdx,
                                                          "admissionTableFieldGroups",
                                                          cusIdx
                                                        )
                                                      }
                                                    >
                                                      <option disabled selected>
                                                        Choose a value
                                                      </option>
                                                      {customTableField.customTableFieldDatas.map(
                                                        (data) => (
                                                          <option
                                                            value={data.data}
                                                          >
                                                            {data.data}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  ) : (
                                                    <>
                                                      {customTableField.fieldTypeCode ===
                                                      "textarea" ? (
                                                        <textarea
                                                          name="value"
                                                          value={
                                                            customTableField.value
                                                          }
                                                          defaultValue={
                                                            customTableField.value
                                                          }
                                                          onChange={(e) =>
                                                            this.handleNestedChange(
                                                              e,
                                                              elIdx,
                                                              "admissionTableFieldGroups",
                                                              cusIdx
                                                            )
                                                          }
                                                          className={
                                                            customTableField.error
                                                              ? "form-control indicate-error"
                                                              : "form-control"
                                                          }
                                                        />
                                                      ) : (
                                                        <>
                                                          {customTableField.fieldTypeCode ===
                                                          "table" ? (
                                                            <TableForm
                                                              field={
                                                                customTableField
                                                              }
                                                              studentFieldIndex={
                                                                elIdx
                                                              }
                                                              customTableFieldIndex={
                                                                cusIdx
                                                              }
                                                              handleDuplicate={
                                                                this
                                                                  .handleDuplicate
                                                              }
                                                              handleRemoveDuplicate={
                                                                this
                                                                  .handleRemoveDuplicate
                                                              }
                                                              stateName="admissionTableFieldGroups"
                                                              conf={false}
                                                              handleCustomFieldTablesChange={
                                                                this
                                                                  .handleCustomFieldTablesChange
                                                              }
                                                            />
                                                          ) : (
                                                            <input
                                                              type={
                                                                customTableField.fieldTypeCode
                                                              }
                                                              name={"value"}
                                                              maxLength="255"
                                                              id={`id${cusIdx}`}
                                                              value={
                                                                customTableField.value
                                                              }
                                                              onChange={(e) =>
                                                                this.handleNestedChange(
                                                                  e,
                                                                  elIdx,
                                                                  "admissionTableFieldGroups",
                                                                  cusIdx
                                                                )
                                                              }
                                                              className={
                                                                customTableField.error
                                                                  ? "form-control indicate-error"
                                                                  : "form-control"
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          )}
                                          {customTableField.mandatory ? (
                                            <span className="tt-assessment-module-mandatory">
                                              *
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </div>
                                    <div className="col-md-1">
                                      {this.state.errors[
                                        customTableField.fieldName
                                      ] ? (
                                        <span className="error-text">
                                          mandatory
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    })
                  : null}
                <div>
                  <div className="tt-group-header">Documents</div>
                  <div className="form-group row">
                    <div className="col-md-1"></div>
                    <div className="col-md-3">
                      <label>
                        <strong>CV</strong>
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="file"
                        name="cv"
                        id="cv"
                        // value={this.state.cv}
                        onChange={this.handleStaticChange}
                        accept="application/pdf"
                      />
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </div>

                {/* <div>
                   <div className="tt-group-header">Emergency Contact</div>
                   <div className="form-group row">
                     <div className="col-md-1"></div>
                     <div className="col-md-3">
                       <label>
                         <strong>Emrgency Contact Name</strong>
                       </label>
                     </div>
                     <div className="col-md-7">
                       <input
                         className="form-control"
                         type="text"
                         name="emergencyContactName"
                         value={this.state.emergencyContactName}
                         onChange={this.handleStaticChange}
                       />
                     </div>
                     <div className="col-md-1"></div>
                   </div>
                   <div className="form-group row">
                     <div className="col-md-1"></div>
                     <div className="col-md-3">
                       <label>
                         <strong>Emrgency Contact Number</strong>
                       </label>
                     </div>
                     <div className="col-md-7">
                       <input
                         className="form-control"
                         type="number"
                         name="emergencyContactNumber"
                         value={this.state.emergencyContactNumber}
                         onChange={this.handleStaticChange}
                       />
                     </div>
                     <div className="col-md-1"></div>
                   </div>
                 </div> */}

                {/* <div>
              <div className="tt-group-header">Qualifications</div>
              <div className="form-group row">
                <div className="col-md-1"></div>
                <div className="col-md-3"></div>
                <div className="col-md-7">
                  <input
                    type="text"
                    name=""
                    placeholder="Masters"
                    className="form-control mb-1"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="Bachelors"
                    className="form-control mb-1"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="High School"
                    className="form-control mb-1"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="School"
                    className="form-control mb-1"
                  />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div> */}
                {/* <div>
              <div className="tt-group-header">Employee History</div>
              <div className="form-group row">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  <label>
                    <strong>Last Worked at</strong>
                  </label>
                </div>
                <div className="col-md-7">
                  <input type="text" name="" className="form-control" />
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="form-group row">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  <label>
                    <strong>Position</strong>
                  </label>
                </div>
                <div className="col-md-7">
                  <input type="text" name="" className="form-control" />
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="form-group row">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  <label>
                    <strong>Years involved</strong>
                  </label>
                </div>
                <div className="col-md-7">
                  <input type="number" name="" className="form-control" min={0} />
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="form-group row">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  <label>
                    <strong>Reason for leave</strong>
                  </label>
                </div>
                <div className="col-md-7">
                  <input type="textarea" name="" className="form-control" />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div> */}
                {/* <div>
              <div className="tt-group-header">Teaching License</div>
              <div className="form-group row">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  <label>
                    <strong>Teaching license</strong>
                  </label>
                </div>
                <div className="col-md-7">
                  <select
                    name="teachingLicense"
                    onChange={this.handleStaticChange}
                    value={this.state.teachingLicense}
                    className="form-control"
                  >
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
                <div className="col-md-1"></div>
              </div>
              {this.state.teachingLicense === "yes" ? (
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <label>
                      <strong>Issued Date</strong>
                    </label>
                  </div>
                  <div className="col-md-7">
                    <input type="date" className="form-control" />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              ) : null}
            </div> */}
                <div className="form-group row mt-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4 admission-notice">
                    {/* <div className="notice-text">
                  The following must be submitted with the application:
                  <li> CV</li>
                  <li> Copy of citizenship</li>
                </div> */}
                  </div>
                </div>
                {this.state.admissionTableFieldGroups ? (
                  <div className="form-group row">
                    <div className="col-md-3"></div>
                    <div className="col-md-8 text-right">
                      <button
                        className="tt-button tt-button-primary"
                        onClick={this.submit}
                        disabled={this.state.isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="text-center" style={{ marginTop: "200px" }}>
                <Spinner color="primary" />
              </div>
            )}

            <ModalWindow
              modal={this.state.permissionModal}
              id="tt-permissionModal"
              size="lg"
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              modalBody={
                <GranularPermissionModal
                  widgetName="Recruitment"
                  moduleName="Recruitment"
                  header="Create Recruitment"
                  activityName="insert-recruitment"
                />
              }
            ></ModalWindow>
            {this.state.isSubmitting ? (
              <div className="fullWindow-Spinner">
                <div>
                  <Spinner color="white"></Spinner>
                </div>
                <div style={{ fontSize: "16px", marginTop: "15px" }}>
                  Please wait... {this.props.editMode ? "Updating" : "Creating"}{" "}
                  Staff
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          // <div
          //   className="tt-widgetContent-tab-holder"
          //   style={{ height: "79.5vh" }}
          // >
          //   <div className="tt-group-header">
          //     Recruitment Form
          //     {checkWidgetAdminOrNot("Recruitment") === true &&
          //     !this.props.editMode ? (
          //       <button
          //         className="tt-button tt-button-primary float-right permissionBtnCSS"
          //         onClick={() => {
          //           this.setState({
          //             permissionModal: !this.state.permissionModal,
          //           });
          //         }}
          //       >
          //         Permissions
          //       </button>
          //     ) : null}
          //   </div>
          //   {!this.state.isLoading ? (
          //     <div className="tt-newListBox container-fluid">
          //       <StaffType
          //         yearOfJoiningToggleDate={this.state.yearOfJoiningToggleDate}
          //         toggleDatePicker={this.toggleDatePicker}
          //         dateOfHireInBS={this.state.dateOfHireInBS}
          //         handleChangeNepaliDate={this.handleChangeNepaliDate}
          //         staffTypeId={this.state.staffTypeId}
          //         handleChange={this.handleChange}
          //         dateOfHire={this.state.dateOfHire}
          //         errors={this.state.errors}
          //         schoolEstablishedDate={this.state.schoolEstablishedDate}
          //         departments={this.state.departments}
          //         designations={this.state.designations}
          //         getDesignations={this.getDesignations}
          //         staffType={this.state.staffType}
          //         academicYear={this.state.academicYear}
          //         department={this.state.department}
          //         designation={this.state.designation}
          //         editMode={this.props.editMode}
          //         handleDepartmentChange={this.handleDepartmentChange}
          //         selectedDepartments={this.state.selectedDepartments}
          //         selectedDesignations={this.state.selectedDesignations}
          //         parentCredentials={this.state.parentCredentials}
          //         username={this.state.username}
          //         checkisUserAvailable={this.checkisUserAvailable}
          //         userFetching={this.state.userFetching}
          //         userFound={this.state.userFound}
          //       />
          //       {this.state.admissionTableFieldGroups
          //         ? this.state.admissionTableFieldGroups.map((el, elIdx) => {
          //             return (
          //               <div key={elIdx}>
          //                 {!el.headerHidden ? (
          //                   <div className="tt-group-header">{el.name}</div>
          //                 ) : null}
          //                 {el.customTableFields.map(
          //                   (customTableField, cusIdx) => {
          //                     return (
          //                       <div key={cusIdx}>
          //                         <div className="form-group row">
          //                           <div className="col-md-1"></div>
          //                           {customTableField.fieldName === null ||
          //                           customTableField.fieldName === "" ? null : (
          //                             <div className="col-md-3">
          //                               <label htmlFor={`id${cusIdx}`}>
          //                                 <strong>
          //                                   {customTableField.fieldName}
          //                                 </strong>
          //                               </label>
          //                             </div>
          //                           )}
          //                           <div
          //                             className={
          //                               customTableField.fieldName === null ||
          //                               customTableField.fieldName === ""
          //                                 ? "col-md-10"
          //                                 : "col-md-7"
          //                             }
          //                           >
          //                             {customTableField.fieldTypeCode ===
          //                             addressCode ? (
          //                               <AddressCluster
          //                                 key={cusIdx + "key"}
          //                                 customTableField={customTableField}
          //                                 handleCustomFieldChange={
          //                                   this.handleCustomFieldChange
          //                                 }
          //                                 studentFieldIndex={elIdx}
          //                                 customTableFieldIndex={cusIdx}
          //                                 stateName="admissionTableFieldGroups"
          //                                 errors={this.state.errors}
          //                                 editMode={this.props.editMode}
          //                                 addressFields={
          //                                   this.state.addressFields
          //                                 }
          //                               />
          //                             ) : customTableField.fieldTypeCode ===
          //                               peopleCode ? (
          //                               <IdentityCluster
          //                                 yearOfJoiningToggleDate={
          //                                   this.state.yearOfJoiningToggleDate
          //                                 }
          //                                 toggleDatePicker={
          //                                   this.toggleDatePicker
          //                                 }
          //                                 icToggleDatePicker={
          //                                   this.icToggleDatePicker
          //                                 }
          //                                 identityClusterDatePickerToggle={
          //                                   this.state
          //                                     .identityClusterDatePickerToggle
          //                                 }
          //                                 dateOfHireInBS={
          //                                   this.state.dateOfHireInBS
          //                                 }
          //                                 studentFieldIndex={elIdx}
          //                                 customTableFieldIndex={cusIdx}
          //                                 stateName="admissionTableFieldGroups"
          //                                 handleBdayChange={
          //                                   this.handleBdayChange
          //                                 }
          //                                 handleDateChange={
          //                                   this.handleDateChange
          //                                 }
          //                                 customFields={this.state.customFields}
          //                                 customTableField={customTableField}
          //                                 handleCustomFieldChange={
          //                                   this.handleCustomFieldChange
          //                                 }
          //                                 handleFileChange={
          //                                   this.handleFileChange
          //                                 }
          //                                 errors={this.state.errors}
          //                                 student={false}
          //                                 editMode={this.props.editMode}
          //                                 identityFields={
          //                                   this.state.identityFields
          //                                 }
          //                                 email={this.state.email}
          //                                 adbsToggler={true}
          //                               />
          //                             ) : (
          //                               <>
          //                                 {customTableField.fieldTypeName ===
          //                                 "file" ? (
          //                                   <input
          //                                     type={
          //                                       customTableField.fieldTypeName
          //                                     }
          //                                     name="value"
          //                                     id={`id${cusIdx}`}
          //                                     checked={customTableField.value}
          //                                     onChange={(e) =>
          //                                       this.handleNestedChange(
          //                                         e,
          //                                         elIdx,
          //                                         "admissionTableFieldGroups",
          //                                         cusIdx
          //                                       )
          //                                     }
          //                                     className={
          //                                       this.state.errors
          //                                         .admissionTableFieldGroups[
          //                                         customTableField.fieldName
          //                                       ]
          //                                         ? "form-control indicate-error"
          //                                         : customTableField.fieldTypeName ===
          //                                           "file"
          //                                         ? ""
          //                                         : "form-control"
          //                                     }
          //                                   />
          //                                 ) : (
          //                                   <div style={{ display: "flex" }}>
          //                                     {customTableField.isMulti &&
          //                                     customTableField.fieldTypeCode !==
          //                                       "select" &&
          //                                     customTableField.fieldTypeCode !==
          //                                       "table" ? (
          //                                       customTableField.customTableFieldDatas.map(
          //                                         (data) => (
          //                                           <>
          //                                             <label className="mr-1">
          //                                               {data.data}
          //                                             </label>
          //                                             <input
          //                                               type={
          //                                                 customTableField.fieldTypeCode ===
          //                                                 "multi-checkbox"
          //                                                   ? "checkbox"
          //                                                   : customTableField.fieldTypeCode
          //                                               }
          //                                               name={
          //                                                 customTableField.fieldTypeCode ===
          //                                                 "radio"
          //                                                   ? "value" +
          //                                                     customTableField.id
          //                                                   : "data" +
          //                                                     customTableField.id
          //                                               }
          //                                               defaultChecked={
          //                                                 customTableField.value ===
          //                                                 data.data
          //                                                   ? true
          //                                                   : false
          //                                               }
          //                                               maxLength="255"
          //                                               id={`id${cusIdx}`}
          //                                               value={data.data}
          //                                               onChange={(e) =>
          //                                                 this.handleNestedChange(
          //                                                   e,
          //                                                   elIdx,
          //                                                   "admissionTableFieldGroups",
          //                                                   cusIdx
          //                                                 )
          //                                               }
          //                                               className={
          //                                                 customTableField.error
          //                                                   ? "indicate-error mr-3"
          //                                                   : "mr-3"
          //                                               }
          //                                             />
          //                                           </>
          //                                         )
          //                                       )
          //                                     ) : (
          //                                       <>
          //                                         {customTableField.isMulti &&
          //                                         customTableField.fieldTypeCode ===
          //                                           "select" ? (
          //                                           <select
          //                                             name="value"
          //                                             className={
          //                                               customTableField.error
          //                                                 ? "form-control indicate-error"
          //                                                 : "form-control"
          //                                             }
          //                                             value={
          //                                               customTableField.value
          //                                             }
          //                                             onChange={(e) =>
          //                                               this.handleNestedChange(
          //                                                 e,
          //                                                 elIdx,
          //                                                 "admissionTableFieldGroups",
          //                                                 cusIdx
          //                                               )
          //                                             }
          //                                           >
          //                                             <option disabled selected>
          //                                               Choose a value
          //                                             </option>
          //                                             {customTableField.customTableFieldDatas.map(
          //                                               (data) => (
          //                                                 <option
          //                                                   value={data.data}
          //                                                 >
          //                                                   {data.data}
          //                                                 </option>
          //                                               )
          //                                             )}
          //                                           </select>
          //                                         ) : (
          //                                           <>
          //                                             {customTableField.fieldTypeCode ===
          //                                             "textarea" ? (
          //                                               <textarea
          //                                                 name="value"
          //                                                 value={
          //                                                   customTableField.value
          //                                                 }
          //                                                 defaultValue={
          //                                                   customTableField.value
          //                                                 }
          //                                                 onChange={(e) =>
          //                                                   this.handleNestedChange(
          //                                                     e,
          //                                                     elIdx,
          //                                                     "admissionTableFieldGroups",
          //                                                     cusIdx
          //                                                   )
          //                                                 }
          //                                                 className={
          //                                                   customTableField.error
          //                                                     ? "form-control indicate-error"
          //                                                     : "form-control"
          //                                                 }
          //                                               />
          //                                             ) : (
          //                                               <>
          //                                                 {customTableField.fieldTypeCode ===
          //                                                 "table" ? (
          //                                                   <TableForm
          //                                                     field={
          //                                                       customTableField
          //                                                     }
          //                                                     studentFieldIndex={
          //                                                       elIdx
          //                                                     }
          //                                                     customTableFieldIndex={
          //                                                       cusIdx
          //                                                     }
          //                                                     handleDuplicate={
          //                                                       this
          //                                                         .handleDuplicate
          //                                                     }
          //                                                     handleRemoveDuplicate={
          //                                                       this
          //                                                         .handleRemoveDuplicate
          //                                                     }
          //                                                     stateName="admissionTableFieldGroups"
          //                                                     conf={false}
          //                                                     handleCustomFieldTablesChange={
          //                                                       this
          //                                                         .handleCustomFieldTablesChange
          //                                                     }
          //                                                   />
          //                                                 ) : (
          //                                                   <input
          //                                                     type={
          //                                                       customTableField.fieldTypeCode
          //                                                     }
          //                                                     name={"value"}
          //                                                     maxLength="255"
          //                                                     id={`id${cusIdx}`}
          //                                                     value={
          //                                                       customTableField.value
          //                                                     }
          //                                                     onChange={(e) =>
          //                                                       this.handleNestedChange(
          //                                                         e,
          //                                                         elIdx,
          //                                                         "admissionTableFieldGroups",
          //                                                         cusIdx
          //                                                       )
          //                                                     }
          //                                                     className={
          //                                                       customTableField.error
          //                                                         ? "form-control indicate-error"
          //                                                         : "form-control"
          //                                                     }
          //                                                   />
          //                                                 )}
          //                                               </>
          //                                             )}
          //                                           </>
          //                                         )}
          //                                       </>
          //                                     )}
          //                                   </div>
          //                                 )}
          //                                 {customTableField.mandatory ? (
          //                                   <sup className="optionalSup">*</sup>
          //                                 ) : null}
          //                               </>
          //                             )}
          //                           </div>
          //                           <div className="col-md-1">
          //                             {this.state.errors[
          //                               customTableField.fieldName
          //                             ] ? (
          //                               <span className="error-text">
          //                                 mandatory
          //                               </span>
          //                             ) : null}
          //                           </div>
          //                         </div>
          //                       </div>
          //                     );
          //                   }
          //                 )}
          //               </div>
          //             );
          //           })
          //         : null}
          //       <div>
          //         <div className="tt-group-header">Documents</div>
          //         <div className="form-group row">
          //           <div className="col-md-1"></div>
          //           <div className="col-md-3">
          //             <label>
          //               <strong>CV</strong>
          //             </label>
          //           </div>
          //           <div className="col-md-7">
          //             <input
          //               type="file"
          //               name="cv"
          //               id="cv"
          //               // value={this.state.cv}
          //               onChange={this.handleStaticChange}
          //             />
          //           </div>
          //           <div className="col-md-1"></div>
          //         </div>
          //       </div>
          //       {/* <div>
          //    <div className="tt-group-header">Qualifications</div>
          //    <div className="form-group row">
          //      <div className="col-md-1"></div>
          //      <div className="col-md-3"></div>
          //      <div className="col-md-7">
          //        <input
          //          type="text"
          //          name=""
          //          placeholder="Masters"
          //          className="form-control mb-1"
          //        />
          //        <input
          //          type="text"
          //          name=""
          //          placeholder="Bachelors"
          //          className="form-control mb-1"
          //        />
          //        <input
          //          type="text"
          //          name=""
          //          placeholder="High School"
          //          className="form-control mb-1"
          //        />
          //        <input
          //          type="text"
          //          name=""
          //          placeholder="School"
          //          className="form-control mb-1"
          //        />
          //      </div>
          //      <div className="col-md-1"></div>
          //    </div>
          //  </div> */}
          //       {/* <div>
          //    <div className="tt-group-header">Employee History</div>
          //    <div className="form-group row">
          //      <div className="col-md-1"></div>
          //      <div className="col-md-3">
          //        <label>
          //          <strong>Last Worked at</strong>
          //        </label>
          //      </div>
          //      <div className="col-md-7">
          //        <input type="text" name="" className="form-control" />
          //      </div>
          //      <div className="col-md-1"></div>
          //    </div>
          //    <div className="form-group row">
          //      <div className="col-md-1"></div>
          //      <div className="col-md-3">
          //        <label>
          //          <strong>Position</strong>
          //        </label>
          //      </div>
          //      <div className="col-md-7">
          //        <input type="text" name="" className="form-control" />
          //      </div>
          //      <div className="col-md-1"></div>
          //    </div>
          //    <div className="form-group row">
          //      <div className="col-md-1"></div>
          //      <div className="col-md-3">
          //        <label>
          //          <strong>Years involved</strong>
          //        </label>
          //      </div>
          //      <div className="col-md-7">
          //        <input type="number" name="" className="form-control" min={0} />
          //      </div>
          //      <div className="col-md-1"></div>
          //    </div>
          //    <div className="form-group row">
          //      <div className="col-md-1"></div>
          //      <div className="col-md-3">
          //        <label>
          //          <strong>Reason for leave</strong>
          //        </label>
          //      </div>
          //      <div className="col-md-7">
          //        <input type="textarea" name="" className="form-control" />
          //      </div>
          //      <div className="col-md-1"></div>
          //    </div>
          //  </div> */}
          //       {/* <div>
          //    <div className="tt-group-header">Teaching License</div>
          //    <div className="form-group row">
          //      <div className="col-md-1"></div>
          //      <div className="col-md-3">
          //        <label>
          //          <strong>Teaching license</strong>
          //        </label>
          //      </div>
          //      <div className="col-md-7">
          //        <select
          //          name="teachingLicense"
          //          onChange={this.handleStaticChange}
          //          value={this.state.teachingLicense}
          //          className="form-control"
          //        >
          //          <option value="no">No</option>
          //          <option value="yes">Yes</option>
          //        </select>
          //      </div>
          //      <div className="col-md-1"></div>
          //    </div>
          //    {this.state.teachingLicense === "yes" ? (
          //      <div className="form-group row">
          //        <div className="col-md-1"></div>
          //        <div className="col-md-3">
          //          <label>
          //            <strong>Issued Date</strong>
          //          </label>
          //        </div>
          //        <div className="col-md-7">
          //          <input type="date" className="form-control" />
          //        </div>
          //        <div className="col-md-1"></div>
          //      </div>
          //    ) : null}
          //  </div> */}
          //       <div className="form-group row mt-3">
          //         <div className="col-md-8"></div>
          //         <div className="col-md-4 admission-notice">
          //           {/* <div className="notice-text">
          //        The following must be submitted with the application:
          //        <li> CV</li>
          //        <li> Copy of citizenship</li>
          //      </div> */}
          //         </div>
          //       </div>
          //       {this.state.admissionTableFieldGroups ? (
          //         <div className="form-group row">
          //           <div className="col-md-3"></div>
          //           <div className="col-md-8 text-right">
          //             <button
          //               className="tt-button tt-button-primary"
          //               onClick={this.submit}
          //               disabled={this.state.isSubmitting}
          //             >
          //               Submit
          //             </button>
          //           </div>
          //         </div>
          //       ) : null}
          //     </div>
          //   ) : (
          //     <div className="text-center" style={{ marginTop: "200px" }}>
          //       <Spinner color="primary" />
          //     </div>
          //   )}

          //   <ModalWindow
          //     modal={this.state.permissionModal}
          //     id="tt-permissionModal"
          //     size="lg"
          //     toggleModal={() => {
          //       this.setState({
          //         permissionModal: !this.state.permissionModal,
          //       });
          //     }}
          //     modalHeader={"Assign permission to user"}
          //     modalBody={
          //       <GranularPermissionModal
          //         widgetName="Recruitment"
          //         moduleName="Recruitment"
          //         header="Create Recruitment"
          //         activityName="insert-recruitment"
          //       />
          //     }
          //   ></ModalWindow>
          //   {this.state.isSubmitting ? (
          //     <div className="fullWindow-Spinner">
          //       <div>
          //         <Spinner color="white"></Spinner>
          //       </div>
          //       <div style={{ fontSize: "16px", marginTop: "15px" }}>
          //         Please wait... {this.props.editMode ? "Updating" : "Creating"}{" "}
          //         Staff
          //       </div>
          //     </div>
          //   ) : null}
          // </div>
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <NoteDisplay
              textContent={`To add a new Recruitment, you must first add departments. Please add departments and then add a new Recruitment`}
            ></NoteDisplay>
          </div>
        )}
      </>
    ) : (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      ></div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  recruitmentConfiguration: state.recruitment.recruitmentConfiguration,
});

const mapActionToProps = {
  getRecruitmentConfiguration,
  getStaffList,
  getStaffListPagination,
};

export default connect(mapStateToProps, mapActionToProps)(RecruitmentForm);
