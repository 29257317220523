import React, { Component } from "react";
import IndicatorTypeColorPicker from "./IndicatorTypeColorPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CounterInput from "react-counter-input/lib/CounterInput";
class IndicatorDescriptorDynamicForm extends Component {
  state = {};

  render() {
    return (
      <>
        {this.props.multipleGrades.map((val, i) => {
          let gradeId = `grade-${i}`,
            descriptorId = `descriptor-${i}`,
            descriptionId = `description-${i}`;
          return (
            <div className="row" key={i} style={{ paddingTop: "5px" }}>
              {this.props.useLevel ? (
                this.props.levelType === "alphabets" ||
                this.props.levelType === "" ? (
                  <div className="col-md-2">
                    {i === 0 ? (
                      <label>
                        <strong>Grade</strong>
                      </label>
                    ) : null}
                    <select
                      className={
                        val.errors
                          ? val.errors.grade
                            ? "form-control indicate-error"
                            : "form-control ttInput-field-dropdown ttInput-field"
                          : "form-control ttInput-field-dropdown ttInput-field"
                      }
                      value={val.grade}
                      name="grade"
                      onChange={(e) => this.props.handleGradeChange(e, i)}
                      data-id={i}
                      id={gradeId}
                      disabled={!this.props.useLevel}
                    >
                      <option value="" disabled>
                        Choose Grade
                      </option>
                      {this.props.gradeTypes !== undefined
                        ? this.props.gradeTypes.map((grade, i) => (
                            <option
                              key={i}
                              value={grade}
                              disabled={this.props.multipleGrades.some(
                                (item) => item.grade === grade
                              )}
                            >
                              {grade}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                ) : this.props.levelType === "number" ? (
                  <div className="col-md-2">
                    {i === 0 ? (
                      <label>
                        <strong>Number</strong>
                      </label>
                    ) : null}
                    <CounterInput
                      count={val.grade === "" ? 0 : 0}
                      min={0}
                      btnStyle={{
                        backgroundColor: "#000080",
                        color: "white",
                        backgroundImage: "linear-gradient(#000080, #000080)",
                      }}
                      inputStyle={{
                        height: "43px",
                        background: "white",
                        border: val.errors
                          ? val.errors.grade
                            ? "1px solid red"
                            : "1px solid black"
                          : "1px solid black",
                      }}
                      wrapperStyle={{
                        justifyContent: "center",
                      }}
                      onCountChange={(count) =>
                        this.props.handleLevelTypeNumberChange(count, i)
                      }
                    />
                  </div>
                ) : this.props.levelType === "text" ? (
                  <div className="col-md-2">
                    {i === 0 ? (
                      <label>
                        <strong>Text</strong>
                      </label>
                    ) : null}
                    <input
                      type="text"
                      className={
                        val.errors
                          ? val.errors.grade
                            ? "form-control indicate-error"
                            : "form-control"
                          : "form-control"
                      }
                      name="grade"
                      value={val.grade}
                      data-id={i}
                      onChange={(e) => this.props.handleGradeChange(e, i)}
                      id={gradeId}
                    />
                  </div>
                ) : this.props.levelType === "image" ? (
                  <div className="col-md-2 text-center">
                    {val.grade !== "" ? (
                      <div
                        style={{
                          width: "120px",
                          height: "120px",
                          border: "1px solid #ccc",
                          margin: "auto",
                        }}
                      >
                        <img
                          src={window.URL.createObjectURL(val.grade)}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <input
                          id="myInput"
                          type="file"
                          size="60"
                          ref={(ref) => (this.upload = ref)}
                          onChange={(e) =>
                            this.props.handleLevelTypeImageChange(e, i)
                          }
                          style={{ display: "none" }}
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                        <button
                          className="tt-button tt-button-primary ttindicator-image-field-label"
                          onClick={() => {
                            this.upload.click();
                          }}
                        >
                          Image
                        </button>
                      </>
                    )}
                  </div>
                ) : this.props.levelType === "colourStickers" ? (
                  <div className="col-md-2 ttindicator-color-pick">
                    <IndicatorTypeColorPicker
                      handleChangeForColorPicker={(e) =>
                        this.props.handleChangeForColorPicker(e, i)
                      }
                      handleCloseForColorPicker={
                        this.props.handleCloseForColorPicker
                      }
                      handleClickColorPicker={this.props.handleClickColorPicker}
                      displayColorPicker={this.props.displayColorPicker}
                      color={val.color}
                      idx={i}
                      isColorPickerOpen={val.isColorPickerOpen}
                    />
                  </div>
                ) : null
              ) : (
                <div className="col-md-2"></div>
              )}
              <div className="col-md-3">
                {i === 0 ? (
                  <label>
                    <strong>Descriptor</strong>
                  </label>
                ) : null}
                <input
                  type="text"
                  name="descriptor"
                  value={val.descriptor}
                  onChange={(e) => this.props.handleGradeChange(e, i)}
                  className={
                    val.errors
                      ? val.errors.descriptor
                        ? "form-control indicate-error"
                        : "form-control ttInput-field"
                      : "form-control ttInput-field"
                  }
                  data-id={i}
                  id={descriptorId}
                  maxLength={40}
                />
              </div>
              <div className="col-md-6">
                {i === 0 ? (
                  <label>
                    <strong>Description</strong>
                  </label>
                ) : null}
                <input
                  type="text"
                  name="descrip"
                  value={val.descrip}
                  onChange={(e) => this.props.handleGradeChange(e, i)}
                  className={
                    val.errors
                      ? val.errors.descrip
                        ? "form-control indicate-error"
                        : "form-control ttInput-field"
                      : "form-control ttInput-field"
                  }
                  data-id={i}
                  id={descriptionId}
                  maxLength={256}
                />
              </div>
              <div className="col-md-1 text-center">
                {/* {i === 0 ? (
                  <label>
                    <strong>Action</strong>
                  </label>
                ) : null*/}
                <div
                  className="tt-span-grade-remove"
                  onClick={() => this.props.handleRemoveGradeFormField(i)}
                >
                  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          );
        })}
        <div className="row mt-3">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-2"
              onClick={this.props.addMultipleGrade}
            >
              Add
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.handleGradeValuesSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default IndicatorDescriptorDynamicForm;
