import React, { Component } from "react";
import "../../assets/scss/account.scss";

class BillStudentList extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="accountStudentContainer">
          <div className="accountStudentHolder">
            {this.props.students.length > 0
              ? this.props.students.map((stu, idx) => {
                  return (
                    <div
                      className={
                        "row accountStudentRow " +
                        (stu.isActive ? "activeAccountStudentRow" : "")
                      }
                      key={idx}
                      style={
                        idx === this.props.students.length - 1
                          ? { borderBottom: "none" }
                          : null
                      }
                      onClick={() => this.props.handleActiveStatus(idx)}
                    >
                      <div className="col-md-auto">
                        <input
                          type="checkbox"
                          className="form-control"
                          name="isChecked"
                          checked={stu.isChecked}
                          // id={stu.people.name + stu.id}
                          onChange={(e) =>
                            this.props.handleStudentCheckbox(e, idx)
                          }
                        ></input>
                      </div>
                      <div className="col">
                        <label htmlFor={stu.people.name + stu.id}>
                          {stu.people.name}
                        </label>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="row footerAccount">
            <div className="col-md-1">
              <input
                type="checkbox"
                className="form-control"
                name="allChecked"
                id="checkAll"
                checked={this.props.allChecked}
                onChange={this.props.handleChange}
              ></input>
            </div>
            <div className="col">
              <label htmlFor="checkAll">Check All</label>
            </div>
            <div className="col-md-1">
              <input
                type="checkbox"
                className="form-control"
                name="allInventoriesChecked"
                id="inventoryCheck"
                checked={this.props.allInventoriesChecked}
                onChange={this.props.handleChange}
              ></input>
            </div>
            <div className="col pr-0">
              <label htmlFor="inventoryCheck">All Inventories</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BillStudentList;
