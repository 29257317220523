import React, { Component } from "react";
import ModalWindow from "../../UI/ModalWindow";
import AddNewEducationlevel from "./AddNewEducationlevel";
import CreateAcademicYearForm from "./CreateAcademicYearForm";

export default class AcademicYearSetup extends Component {
  state = {
    addEducationLevelModal: false,
  };

  handleAddEducationModal = () => {
    this.setState({
      addEducationLevelModal: !this.state.addEducationLevelModal,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="educationLevelName">
              <strong>Education Level : </strong>
            </label>
          </div>
          {this.props.noNavbar ? (
            <>
              {this.props.educationLevelList.length > 0 ? (
                <div className="col">
                  <select
                    onChange={this.props.handleEducationLevelChange}
                    name="educationLevelName"
                    id="educationLevelName"
                    value={this.props.educationLevelName}
                    className="form-control ttedu-level-dropdown"
                  >
                    <option value="All">All</option>
                    {this.props.educationLevelList !== undefined
                      ? this.props.educationLevelList.map(
                          (educationLevel, idx) => (
                            <option key={idx} value={educationLevel.id}>
                              {educationLevel.name}
                            </option>
                          )
                        )
                      : null}
                  </select>
                </div>
              ) : null}
              <div className="col-md-auto">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.props.importPrevious}
                  disabled={this.props.previousImported}
                >
                  Import Previous
                </button>
              </div>
              <div className="col-md-auto">
                <button
                  className="tt-button tt-button-primary"
                  onClick={() => {
                    this.props.addNewEducationLevel();
                    this.handleAddEducationModal();
                  }}
                >
                  Add New
                </button>
              </div>
            </>
          ) : (
            <div className="col-md-7 ">
              <select
                onChange={this.props.handleEducationLevelChange}
                name="educationLevelName"
                id="educationLevelName"
                value={this.props.educationLevelName}
                className="form-control ttedu-level-dropdown"
              >
                <option value="All">All</option>
                {this.props.educationLevelList !== undefined
                  ? this.props.educationLevelList.map((educationLevel, idx) => (
                      <option key={idx} value={educationLevel.id}>
                        {educationLevel.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          )}
        </div>
        {this.props.educationLevelName === "All" ? (
          <CreateAcademicYearForm
            termAlias={this.props.termAlias}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            numberOfTerms={this.props.numberOfTerms}
            increaseValue={this.props.increaseValue}
            decreaseValue={this.props.decreaseValue}
            handleSemesterChange={this.props.handleSemesterChange}
            handleAcademicYearChange={this.props.handleAcademicYearChange}
          />
        ) : this.props.educationLevelList !== undefined ? (
          this.props.educationLevelList.map((educationLevel, idx) => {
            let startDateId = `startDate-${idx}`,
              endDateId = `endDate-${idx}`,
              numberOfSemesterId = `numberOfSemester-${idx}`;

            return (
              <div key={idx} className="card mb-4">
                <div className="card-body">
                  <div className="tt-group-header">
                    {educationLevel.name.toUpperCase()}
                  </div>
                  <CreateAcademicYearForm
                    termAlias={this.props.termAlias}
                    levelName={educationLevel.name}
                    numberOfTerms={educationLevel.numberOfTerms}
                    startDate={educationLevel.startDate}
                    endDate={educationLevel.endDate}
                    dataId={idx}
                    startDateId={startDateId}
                    endDateId={endDateId}
                    numberOfSemesterId={numberOfSemesterId}
                    increaseValue={this.props.increaseValue}
                    decreaseValue={this.props.decreaseValue}
                    handleAcademicYearChange={
                      this.props.handleAcademicYearChange
                    }
                  />
                </div>
              </div>
            );
          })
        ) : null}
        <ModalWindow
          modal={this.state.addEducationLevelModal}
          size="lg"
          toggleModal={this.handleAddEducationModal}
          modalHeader="Add New Eduation Level"
          modalBody={
            <AddNewEducationlevel
              addNewEduLevel={this.props.addNewEduLevel}
              educationLevelList={this.props.educationLevelList}
              handleAddEducationModal={this.handleAddEducationModal}
              deleteNewEduLevel={this.props.deleteNewEduLevel}
            ></AddNewEducationlevel>
          }
        ></ModalWindow>
      </div>
    );
  }
}
