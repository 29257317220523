import { faFileAlt, faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { postType, URL } from "../../../../utils/Constants";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import ModalWindow from "../../../UI/ModalWindow";
import $ from "jquery";

class NoticeHistory extends Component {
  state = {
    keywordModal: false,
    targetGroups: [],
    slideShareURl: "",
  };

  toggleKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  getEmbedLink = (link) => {
    let embeddedURL = "";
    let videoId = "";
    if (link.includes("youtube") || link.includes("youtu.be")) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = link.match(regExp);
      if (match && match[7].length == 11) {
        videoId = match[7];
        embeddedURL = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (link.includes("dailymotion") || link.includes("dai.ly")) {
      videoId = this.getDailyMotionId(link);
      embeddedURL = `https://www.dailymotion.com/embed/video/${videoId}`;
    } else if (link.includes("vimeo")) {
      videoId = this.getVimeoId(link);
      embeddedURL = `https://player.vimeo.com/video/${videoId}`;
    } else if (link.includes("slideshare")) {
      let self = this;
      let src = "";
      if (self.state.slideShareURl === "") {
        $.getJSON(
          "https://noembed.com/embed",
          { format: "json", url: link },
          function (data) {
            if (data !== undefined || data) {
              let htmlCode = data.html;
              src = self.getSlideShareSrc(htmlCode);
              self.setState({ slideShareURl: src });
            }
          }
        );
      }
      embeddedURL = this.state.slideShareURl;
    }
    return embeddedURL;
  };

  getDailyMotionId = (url) => {
    var m = url.match(
      /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
    );
    if (m === null) {
      var n = url.match(
        /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
      );
      if (n !== null) {
        if (n[4] !== undefined) {
          return n[4];
        }
        return n[2];
      }
    } else if (m !== null) {
      if (m[4] !== undefined) {
        return m[4];
      }
      return m[2];
    } else {
      return null;
    }
  };

  getVimeoId = (url) => {
    var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    var parseUrl = url.match(regExp);
    return parseUrl[5];
  };

  displayKeywordModal = (e, groups) => {
    this.setState({
      keywordModal: !this.state.keywordModal,
      targetGroups: groups,
    });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.state.targetGroups
          ? this.state.targetGroups.map((group, idx) => {
              return <li key={idx}>{group.name}</li>;
            })
          : null}
      </ol>
    );
  };

  render() {
    let notice = this.props.history;
    return (
      <div
        className="container-fluid"
        style={{ height: "85vh", overflow: "auto", width: "94%" }}
      >
        <div className="row">
          <div className="col text-center">
            <p>
              <strong>
                {moment(notice.createdAt).format("dddd, Do MMMM, YYYY")}
              </strong>
            </p>
          </div>
        </div>
        <div className="tt-historyBorder">
          <div className="row">
            <div className="col">
              <div className="row mb-1">
                <div className="col" style={{ fontSize: "20px" }}>
                  <strong>{notice.heading}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row" id="top">
                    <div className="col-md-6">
                      <strong>{notice.peopleName}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tt-mobileNoticeGroup">
                {notice.groups && notice.groups.length > 0 ? (
                  <div className="tt-groupTargetList">
                    {notice.groups.map((group, idx) => {
                      if (group.name === "") {
                        return null;
                      } else {
                        return idx <= 1 ? (
                          <div
                            key={idx}
                            data-tip={group.name}
                            data-for="groupKey"
                          >
                            {group.name}
                          </div>
                        ) : idx === notice.groups.length - 1 ? (
                          <div
                            key={idx}
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              this.displayKeywordModal(e, notice.groups)
                            }
                          >
                            + {notice.groups.length - 2} more
                          </div>
                        ) : (
                          ""
                        );
                      }
                    })}
                  </div>
                ) : null}
              </div>
              <div className="tt-showMobileGroup">
                {notice.groups && notice.groups.length > 0 ? (
                  <div className="tt-groupTargetList">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        this.displayKeywordModal(e, notice.groups)
                      }
                    >
                      Groups Assigned
                    </div>
                  </div>
                ) : null}
              </div>
              {notice.contents
                ? notice.contents.map((content) => {
                    if (content.contentTypeCode === "LNK") {
                      return (
                        <div
                          className="tt-notice-link"
                          key={content.id}
                          style={{ width: "unset" }}
                        >
                          <iframe
                            src={this.getEmbedLink(content.content)}
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}

              <div className={"mt-3 wordBreakHyphen editorPostContent"}>
                {ReactHtmlParser(notice.textContent)}
              </div>
            </div>
            {notice.postType !== postType.profilePictureNotice &&
            notice.postType !== postType.coverPictureNotice &&
            notice.contents.length > 0 ? (
              <div className="col-md-3">
                {notice && notice.contents.length > 0 ? (
                  <div className="tt-attachedBox text-center">
                    <strong>Attached file(s)</strong>
                    <div className="row">
                      {notice.contents
                        ? notice.contents.map((content) => {
                            if (content.contentTypeCode === "IMG")
                              return (
                                <div className="col-md-6">
                                  <FontAwesomeIcon
                                    icon={faFileImage}
                                    size="3x"
                                  ></FontAwesomeIcon>
                                  <p
                                    style={{
                                      width: "100%",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      paddingLeft: "8px",
                                      whiteSpace: "nowrap",
                                      paddingRight: "8px",
                                    }}
                                  >
                                    <a
                                      href={
                                        URL.fileDownloadURL +
                                        content.content
                                      }
                                      target="_blank"
                                    >
                                      {content.origin}
                                    </a>
                                  </p>
                                </div>
                              );
                            if (
                              content.contentTypeCode === "PDF" ||
                              content.contentTypeCode === "TXT"
                            )
                              return (
                                <div className="col-md-6">
                                  <FontAwesomeIcon
                                    icon={faFileAlt}
                                    size="3x"
                                  ></FontAwesomeIcon>
                                  <p
                                    style={{
                                      width: "100%",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      paddingLeft: "8px",
                                      whiteSpace: "nowrap",
                                      paddingRight: "8px",
                                    }}
                                  >
                                    <a
                                      href={
                                        URL.fileDownloadURL +
                                        content.content
                                      }
                                      target="_blank"
                                      download
                                    >
                                      {content.origin}
                                    </a>
                                  </p>
                                </div>
                              );
                          })
                        : null}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <ModalWindow
          modal={this.state.keywordModal}
          toggleModal={this.toggleKeywordModal}
          modalHeader="Groups Targeted"
          modalBody={this.keywordModalBody()}
        ></ModalWindow>
      </div>
    );
  }
}

export default NoticeHistory;
