import React, { Component } from "react";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";

class ListStudentAttendanceDetails extends Component {
  state = {
    attendanceRecord: [],
    schoolInfos: "",
  };

  componentDidMount() {
    this.getSchoolInfo();
  }

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data[0],
        });
      }
    });
  };

  getStudeentName = () => {
    //fint the name of the std. whose attendance details is being downloaded
  };

  renderAttendanceStatus = (key) => {
    return this.props.subjects.map((sub, id) => {
      const isrecordthere = this.props.studentAttendanceRecord[key].find(
        (stdRecord) => stdRecord.subjectId === sub.id
      );

      if (isrecordthere) {
        return (
          <td>
            {isrecordthere.isPresent ? (
              <FontAwesomeIcon icon={faCheckCircle} color="#1abc9c" />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} color="#a70707" />
            )}
          </td>
        );
      } else {
        return <td>-</td>;
      }
    });
  };

  handleExportToPDF = async () => {
    document.getElementById("printAttendance").parentNode.style.overflow =
      "visible";
    const element = document.getElementById("printAttendance");
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.setFontSize(40);
    pdf.text(30, 20, "Hello world!");
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("AttendanceDetails.pdf");
  };

  render() {
    let countryString = "";
    if (this.state.schoolInfos?.countryThirdLevelName) {
      countryString += this.state.schoolInfos?.countryThirdLevelName;
    }
    if (this.state.schoolInfos?.countrySecondLevelName) {
      countryString +=
        (countryString ? ", " : "") +
        this.state.schoolInfos?.countrySecondLevelName;
    }
    if (this.state.schoolInfos?.countryFirstLevelName) {
      countryString +=
        (countryString ? ", " : "") +
        this.state.schoolInfos?.countryFirstLevelName;
    }
    if (this.state.schoolInfos?.countryName) {
      countryString +=
        (countryString ? ", " : "") + this.state.schoolInfos?.countryName;
    }
    return (
      <>
        {this.props.subjects.length > 0 ? (
          <div>
            <div id="printAttendance" className="container mt-5">
              {Object.keys(this.props.studentAttendanceRecord).length > 0 ? (
                <>
                  <div className="col-md-12">
                    <div className="container row  my-3 d-flex justify-content-center">
                      <div className="text-center">
                        <h2>{this.state.schoolInfos?.name}</h2>
                        <h5>{countryString}</h5>
                      </div>
                    </div>
                    <div className="py-2">
                      <div className="">
                        <b>Student name: </b>
                        {this.props.student?.people?.name}
                      </div>
                      <div className="">
                        <b>Class: </b>
                        {this.props.selectedClassroom.yearLevelName +
                          " " +
                          this.props.selectedClassroom.name}
                      </div>

                      <div className="">
                        <b>Month: </b>
                        {this.props.selectedMonth}
                      </div>
                    </div>
                  </div>

                  <table className="table table-bordered classroutineTable text-center">
                    <thead className="tt-group-header">
                      <th>Date</th>
                      {this.props.subjects.map((sub, id) => {
                        return (
                          <>
                            <th>{sub.name}</th>
                          </>
                        );
                      })}
                    </thead>
                    <tbody>
                      {Object.keys(this.props.studentAttendanceRecord).map(
                        (key, id) => {
                          return (
                            <tr>
                              <td>{key}</td>
                              {this.renderAttendanceStatus(key)}
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="text-center mt-4">No attendance recorded</div>
              )}
            </div>
            {Object.keys(this.props.studentAttendanceRecord).length > 0 && (
              <div className="container">
                <button
                  className="tt-button tt-button-primary my-2"
                  onClick={() => this.handleExportToPDF()}
                  style={{
                    display: this.props.showBtn ? " block" : "none",
                  }}
                >
                  Export as PDF
                </button>
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  }
}
export default ListStudentAttendanceDetails;
