import React, { Component } from "react";

class ObjectiveExamList extends Component {
  state = {};
  render() {
    return <>Objective Exam List</>;
  }
}

export default ObjectiveExamList;
