import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

class AssignInventoryItemHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <strong>Education Level</strong>
            <select
              className="form-control"
              name="educationLevel"
              value={this.props.educationLevel}
              onChange={this.props.handleChange}
            >
              <option value="" selected disabled>
                Select Education Level
              </option>
              {this.props.educationLevels.map((sl, idx) => {
                return (
                  <option value={sl.id} key={idx}>
                    {sl.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-4">
            <strong>{localStorage.getItem("yearLevel")}</strong>
            <select
              className="form-control"
              name="yearLevel"
              value={this.props.yearLevel}
              onChange={this.props.handleChange}
              disabled={this.props.educationLevel !== "" ? false : true}
            >
              <option value="" selected disabled>
                Select {localStorage.getItem("yearLevel")}
              </option>
              {this.props.yearLevels.map((sl, idx) => {
                return (
                  <option value={sl.id} key={idx}>
                    {sl.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-4">
            <strong>{localStorage.getItem("classroom")}</strong>
            <select
              className="form-control"
              name="classroom"
              value={this.props.classroom}
              onChange={this.props.handleChange}
              disabled={this.props.yearLevel !== "" ? false : true}
            >
              <option value="" selected disabled>
                Select {localStorage.getItem("classroom")}
              </option>
              {this.props.classrooms.map((el, idx) => {
                return (
                  <option value={el.id} key={idx}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-2 text-center mt-2">
            <input
              type="checkbox"
              name="allStudents"
              checked={this.props.allStudents}
              onChange={this.props.handleChange}
              id="All Students"
            />
            <label
              htmlFor="All Students"
              style={{ position: "relative", top: "-5px", left: "10px" }}
            >
              <strong>All Students</strong>
            </label>
          </div>
          <div className="col-md-1 mt-2 text-center">
            <strong>OR</strong>
          </div>
          <div className="col-md-5">
            <Select
              closeMenuOnSelect={false}
              isClearable={true}
              value={this.props.selectedIndividual}
              components={animatedComponents}
              isMulti
              options={this.props.studentList}
              placeholder="Choose Individual Students"
              name="people"
              onChange={this.props.handleIndividualSelect}
              menuPlacement="auto"
              isLoading={false}
              isDisabled={this.props.allStudents}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
      </>
    );
  }
}

export default AssignInventoryItemHeader;
