import React, { Component } from "react";
import { compareGardeLevelsByGPAPoint } from "../../../utils/Utils";

export default class ReportingConfigurationSubjectSetting extends Component {
  renderReferenceTable = () => {
    const { assessmentScales, reportSetupSubjectSettingBd } = this.props;
    const selectedScale = assessmentScales.find(
      (as) =>
        as.id === parseInt(reportSetupSubjectSettingBd.selectedGradeFormatId)
    );
    return (
      <table className="table table-striped table-bordered">
        <thead className="tt-group-header">
          <tr className="text-center">
            <th>Grade</th>
            <th>Minimum Percentage</th>
            <th>Maximum Percentage</th>
            <th>Grade Point</th>
            <th>Minimum GPA</th>
            <th>Maximum GPA</th>
            <th>GPA Evaluation</th>
          </tr>
        </thead>
        <tbody>
          {selectedScale
            ? selectedScale?.assessmentScaleGradePoints
                .sort(compareGardeLevelsByGPAPoint)
                .map((level, i) => (
                  <tr className="text-center">
                    <td>{level.grade}</td>
                    <td>{level.percentageMin}</td>
                    <td>{level.percentageMax}</td>
                    <td>{level.gradePoint}</td>
                    <td>{level.gpaMin}</td>
                    <td>{level.gpaMax}</td>
                    <td>{level.gpaEvaluation}</td>
                  </tr>
                ))
            : null}
        </tbody>
      </table>
    );
  };

  render() {
    const { assessmentScales, reportSetupSubjectSettingBd } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <table className="table table-striped table-bordered">
              <thead className="tt-group-header">
                <tr>
                  <th>Subjects</th>
                  <th>Marks</th>
                  <th>Percentage</th>
                  {reportSetupSubjectSettingBd.showGradePoints ? (
                    <th>Grade Point</th>
                  ) : null}
                  {reportSetupSubjectSettingBd.showGrades ? (
                    <th>Grade</th>
                  ) : null}
                  {reportSetupSubjectSettingBd.showClassAverage ? (
                    <th>Class Average</th>
                  ) : null}
                  {reportSetupSubjectSettingBd.showClassHighest ? (
                    <th>Class Highest</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sample Subject 1</td>
                  <td>75</td>
                  <td>100%</td>
                  {reportSetupSubjectSettingBd.showGradePoints ? (
                    <td>3.2</td>
                  ) : null}
                  {reportSetupSubjectSettingBd.showGrades ? <td>A+</td> : null}
                  {reportSetupSubjectSettingBd.showClassAverage ? (
                    <td>75</td>
                  ) : null}
                  {reportSetupSubjectSettingBd.showClassHighest ? (
                    <td>98</td>
                  ) : null}
                </tr>
                <tr>
                  <td>Sample Subject 2</td>

                  <td>
                    <p
                      style={
                        reportSetupSubjectSettingBd.highlightIfBelowMinimum
                          ? {
                              background: "#e74c3c",
                              padding: "5px",
                              borderRadius: "15px",
                            }
                          : {}
                      }
                    >
                      {" "}
                      15{" "}
                    </p>
                  </td>
                  <td>100%</td>
                  {reportSetupSubjectSettingBd.showGradePoints ? (
                    <td>0.8</td>
                  ) : null}
                  {reportSetupSubjectSettingBd.showGrades ? <td>E</td> : null}
                  {reportSetupSubjectSettingBd.showClassAverage ? (
                    <td>68</td>
                  ) : null}
                  {reportSetupSubjectSettingBd.showClassHighest ? (
                    <td>89</td>
                  ) : null}
                </tr>
                <tr>
                  <td>Sample Subject 3</td>
                  <td>
                    <p
                      style={
                        reportSetupSubjectSettingBd.highlightIfHighest
                          ? {
                              background: "#2ecc71",
                              padding: "5px",
                              borderRadius: "15px",
                            }
                          : {}
                      }
                    >
                      {" "}
                      95{" "}
                    </p>
                  </td>
                  <td>100%</td>
                  {reportSetupSubjectSettingBd.showGradePoints ? (
                    <td>3.6</td>
                  ) : null}
                  {reportSetupSubjectSettingBd.showGrades ? <td>A+</td> : null}
                  {reportSetupSubjectSettingBd.showClassAverage ? (
                    <td>70</td>
                  ) : null}
                  {reportSetupSubjectSettingBd.showClassHighest ? (
                    <td>95</td>
                  ) : null}
                </tr>
              </tbody>
              <thead className="tt-group-header">
                <tr>
                  <th></th>
                  {/* <th></th> */}
                  <th>Total</th>
                  <th>Total</th>
                  {reportSetupSubjectSettingBd.showGpa ? <th>GPA</th> : null}
                  {reportSetupSubjectSettingBd.showGradeEvaluation ? (
                    <th>Grade Evaluation</th>
                  ) : null}
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  {/* <td></td> */}
                  <td>237</td>
                  <td>53%</td>
                  {reportSetupSubjectSettingBd.showGpa ? <td>2.1</td> : null}
                  {reportSetupSubjectSettingBd.showGradeEvaluation ? (
                    <td>Satisfactory</td>
                  ) : null}
                  {/* <td></td> */}
                </tr>
              </tbody>
            </table>
            <div className="row mb-2">
              <div className="col-3">Show score as:</div>
              <div className="col-9">
                <select
                  className="form-control"
                  name="showScoreAs"
                  value={reportSetupSubjectSettingBd.showScoreAs}
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                >
                  <option value="" disabled selected>
                    Choose value
                  </option>
                  <option value="nopctg">Marks</option>
                  <option value="pctg">Percentage</option>
                  {/* <option value="both">Both Marks and Percentage</option> */}
                  <option value="grade">Grade</option>
                  <option value="gpoint">Grade Point</option>
                </select>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-3">Choose your grade format</div>
              <div className="col-9">
                <select
                  className="form-control"
                  name="selectedGradeFormatId"
                  value={reportSetupSubjectSettingBd.selectedGradeFormatId}
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                >
                  <option value="" disabled selected>
                    Choose value
                  </option>
                  {assessmentScales?.map((as) => (
                    <option value={as.id}>{as?.scoreName}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="row mb-2">
          <div className="col-3">Show totals for:</div>
          <div className="col-9">
            <select
              className="form-control"
              name="showTotalFor"
              value={reportSetupSubjectSettingBd.showTotalFor}
              onChange={this.props.handleSubjectSettingBreakdownChange}
            >
              <option value="" disabled selected>
                Choose value
              </option>
              <option value="both">Both marks and pctg.</option>
              <option value="nopctg">Only marks</option>
              <option value="pctg">Only pctg.</option>
            </select>
          </div>
        </div> */}

            <div className="row">
              <div className="col-3">
                <input
                  type="checkbox"
                  name="showGradePoints"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.showGradePoints}
                />{" "}
                Show Grade Points
              </div>

              <div className="col-3">
                <input
                  type="checkbox"
                  name="showGrades"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.showGrades}
                />{" "}
                Show Grades
              </div>

              <div className="col-3">
                <input
                  type="checkbox"
                  name="showClassAverage"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.showClassAverage}
                />{" "}
                Show Class Average
              </div>

              <div className="col-3">
                <input
                  type="checkbox"
                  name="showClassHighest"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.showClassHighest}
                />{" "}
                Show Class Highest
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <input
                  type="checkbox"
                  name="showGpa"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.showGpa}
                />{" "}
                Show GPA
              </div>

              <div className="col-3">
                <input
                  type="checkbox"
                  name="showGradeEvaluation"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.showGradeEvaluation}
                />{" "}
                Show Grade Evaluation
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <input
                  type="checkbox"
                  name="highlightIfHighest"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.highlightIfHighest}
                />{" "}
                Highlight if highest in class
              </div>
              <div className="col-4">
                <input
                  type="checkbox"
                  name="highlightIfBelowMinimum"
                  onChange={this.props.handleSubjectSettingBreakdownChange}
                  checked={reportSetupSubjectSettingBd.highlightIfBelowMinimum}
                />{" "}
                Highlight if below minimum achievement
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-4">
                {this.props.previousAssessments.length > 0 ? (
                  <button
                    className="tt-button tt-button-primary tt-button-fullWidth"
                    onClick={this.props.handleAddPrevAssmtScoreBtnClick}
                  >
                    Add score of previous assessment
                  </button>
                ) : null}
              </div>
              <div className="col-5"></div>
              <div className="col-3">
                <button
                  className="tt-button tt-button-primary tt-button-fullWidth"
                  onClick={this.props.toggleSctMarksSubjectSettingModal}
                >
                  Save Settings
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">{this.renderReferenceTable()}</div>
        </div>
      </div>
    );
  }
}
