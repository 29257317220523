import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import ModalWindow from "../../UI/ModalWindow";
import CategoryForm from "../CatagoryForm";

class InventoryCategory extends Component {
  state = {
    addModal: false,
    categories: null,
    tempCategory: null,
  };

  componentDidMount() {
    this.getCategories();
  }

  handleAddModal = () => {
    this.setState((prevState) => {
      return { ...prevState, addModal: !prevState.addModal };
    });
  };

  getCategories = () => {
    axiosPost(
      URL.getAllFeeCategory,
      { isInventoryCategory: true },
      (response) => {
        if (response.status === 200) {
          this.setState({ categories: response.data.data });
        }
      }
    );
  };

  editCategory = (id, idx) => {
    let categories = [...this.state.categories];
    let tempCategory = JSON.parse(JSON.stringify(categories[idx]));
    const editedCategories = categories.map((cat, catIdx) => {
      idx === catIdx ? (cat.editing = true) : (cat.editing = false);
      return cat;
    });
    this.setState({ categories: editedCategories, tempCategory });
  };

  cancelEditing = (id, idx) => {
    let categories = [...this.state.categories];
    categories[idx] = this.state.tempCategory;
    categories[idx].editing = false;
    this.setState({ categories, tempCategory: null });
  };

  saveEditedCategory = (id, idx, category) => {
    const param = {
      id: id,
      category: category,
    };
    axiosPost(URL.updateFeeCategory, param, (response) => {
      if (response.status === 200) {
        swal("Success", "Category Updated.", "");
        this.getCategories();
      }
    });
  };

  handleChange = (e, idx) => {
    const { value } = e.target;
    let categories = [...this.state.categories];
    categories[idx].category = value;
    this.setState({ categories });
  };

  deleteCategory = (id, idx) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure, you want to delete this category?",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const param = {
          id: id,
        };
        axiosPost(URL.deleteFeeCategory, param, (response) => {
          if (response.status === 200) {
            swal("Success", "Category is deleted", "");
            this.getCategories();
          }
        });
      }
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">Inventory Categories</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <button
                className="tt-button tt-button-primary"
                onClick={this.handleAddModal}
              >
                Add Inventory Category
              </button>
            </div>
          </div>
          <table className="table text-center table-bordered table-striped mt-2">
            <thead className="tt-group-header">
              <tr>
                <th width="80px">SN</th>
                <th>Name</th>
                <th width="200px">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.categories?.map((cat, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  {cat?.editing ? (
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="category"
                        value={cat.category}
                        onChange={(e) => this.handleChange(e, idx)}
                      />
                    </td>
                  ) : (
                    <td>{cat.category}</td>
                  )}

                  <td>
                    <button
                      className="tt-button tt-button-primary"
                      onClick={() =>
                        cat?.editing
                          ? this.saveEditedCategory(cat.id, idx, cat.category)
                          : this.editCategory(cat.id, idx)
                      }
                    >
                      {cat?.editing ? "Save" : "Edit"}
                    </button>
                    <button
                      className="tt-button tt-button-danger"
                      onClick={() =>
                        cat?.editing
                          ? this.cancelEditing(cat.id, idx)
                          : this.deleteCategory(cat.id, idx)
                      }
                    >
                      {cat?.editing ? "Cancel" : "Delete"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ModalWindow
          modal={this.state.addModal}
          toggleModal={this.handleAddModal}
          modalHeader={"Add Fee Cateogry"}
          modalBody={
            <CategoryForm
              toggleModal={this.handleAddModal}
              getCategories={this.getCategories}
              isInventory={true}
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default InventoryCategory;
