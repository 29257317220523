import React, { Component } from "react";
import { checkRolePermissions } from "../../../../utils/Constants";

class AddNewBuildingForm extends Component {
  state = {};
  handleKeyPress = (evt) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };
  render() {
    let errors = this.props.errors;
    return (
      <>
        <div className="form-group row">
          <div className="col-sm-2"></div>
          <label htmlFor="building" className="col-sm-2 col-form-label">
            {" "}
            <strong>Building</strong>
            <span className="tt-assessment-module-mandatory">*</span>
          </label>

          <div className="col-sm-4">
            <input
              id="building"
              type="text"
              className={
                errors.building ? "form-control indicate-error" : "form-control"
              }
              name="building"
              value={this.props.building}
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2"></div>
          <label htmlFor="noOfFloors" className="col-sm-2 col-form-label">
            {" "}
            <strong> Number of floors</strong>
            <span className="tt-assessment-module-mandatory">*</span>
          </label>
          <div className="col-sm-4">
            <input
              id="noOfFloors"
              type="number"
              className={
                errors.floors ? "form-control indicate-error" : "form-control"
              }
              name="floors"
              value={this.props.floors}
              onChange={(e) => this.props.handleChange(e)}
              min={0}
              onKeyPress={this.handleKeyPress}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2"></div>
          <label htmlFor="buildingSection" className="col-sm-2 col-form-label">
            <strong className="optional">Building section</strong>
          </label>
          <div className="col-sm-4">
            <input
              id="buildingSection"
              type="text"
              className="form-control"
              name="buildingSection"
              value={this.props.buildingSection}
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
        </div>
        {!this.props.updateBuilding ? (
          <div className="form-group row">
            <div className="col-sm-5"></div>
            <div className="col-sm-5 tt-inline">
              <span className="text-muted">Suggestion:&nbsp;</span>
              <div className="tt-horizontal">
                {this.props.suggestions
                  ? this.props.suggestions.map((suggesstion, index) => (
                      <div key={index}>
                        <a
                          href="!#"
                          onClick={(e) =>
                            this.props.handleDynamicFieldSuggestion(
                              e,
                              suggesstion
                            )
                          }
                        >
                          {suggesstion}
                        </a>
                        &nbsp;
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        ) : null}
        <div className="row button-bg-color">
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
          <div className="col-md-4 text-right">
            {checkRolePermissions("insert-facilities", "activity") ? (
              <button
                onClick={
                  this.props.updateBuilding
                    ? this.props.handleUpdateSubmit
                    : this.props.addFacilities
                }
                className="tt-button tt-button-primary mr-3"
              >
                {this.props.updateBuilding ? "Update Facility" : "Add Facility"}
              </button>
            ) : null}
            {this.props.updateBuilding ? (
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.toggleBuildingUpdateModal}
              >
                Cancel
              </button>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default AddNewBuildingForm;
