/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, Collapsible, render the full Collapsible i.e. header along the collapsible body
 *              pass in header props to dynamically display the header and collapsibleBody to interact with
 *              the corresponding body
 */

import React, { Component } from "react";
import CustomCollapse from "./CustomCollapse";
import {
  URL,
  draggable,
  checkSubsTeacherPermissions,
  checkRolePermissions,
} from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import HighlightedHeader from "../AssessmentRecordReport/Reports/SubjectAssessment/HighlightedHeader";
import StudentGeneralReport from "../AssessmentRecordReport/Reports/SubjectAssessment/StudentGeneralReport";
import { displaySuccessAlert, showErroMessage } from "../../utils/Utils";
import swal from "sweetalert";
import {
  getYearLevelForPublishAssessmentReport,
  getGeneralAssessmentList,
  handleCheckAssessmentFinalize,
  handleCheckFinalizeClickable,
} from "../../ducks/PrimaryARRDucks";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";
import moment from "moment";
import { MESSAGEHEADER } from "../../utils/DisplayMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

class Collapsible extends Component {
  constructor(props) {
    super(props);
    this.editor = React.createRef();
  }
  state = {
    collapsibleState: false,
    classrooms: [],
    assessmentList: [],
    studentDetails: {},
    assessmentGeneralConfig: [],
    studentGeneralAssessmentComments: [],
    selectedLevelsForAssessment: [],
    studentGeneralAssessmentCustomAssessmentScales: [],
    studentGeneralAssessmentCustomAssessmentRubricsScales: [],
    studentNameValuePair: [],
    studentOptionlSubjects: [],
    compulsorySubjects: [],
    recruitment: {},
    GeneralAssessmentData: [],
    finalize: false,
    finalizeClickable: true,
    cursorPosition: 0,
    finalizeGeneralPermission: null,
    unFinalizeGeneralPermission: null,
  };
  /**
   * This Toggles the Collapsible
   */

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    let finalizeGeneralPermission =
      checkRolePermissions(
        "finalize-general-assessment",
        "activity",
        this.props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "finalize-general-assessment",
        "activity",
        this.props.widgetName
      );

    let unFinalizeGeneralPermission =
      checkRolePermissions(
        "unfinalize-general-assessment",
        "activity",
        this.props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "unfinalize-general-assessment",
        "activity",
        this.props.widgetName
      );

    this.setState({
      finalizeGeneralPermission,
      unFinalizeGeneralPermission,
    });
  };

  handleStudentToggle = (index) => {};
  getRecruitmentIdByPeopleId = () => {
    let param = {
      peopleId: localStorage.getItem("peopleId"),
    };
    axiosPost(URL.selectRecruitmentByPeopleId, param, (response) => {
      if (response.status === 200) {
        this.setState({ recruitment: response.data.data });
      }
    });
  };

  toggleCollapse = () => {
    this.setState({
      collapsibleState: !this.state.collapsibleState,
    });
  };

  getStudentListByAdmissionId = (classroomId, yearLevelId, admissionId) => {
    let param = {
      classroomId: classroomId,
      yearLevelId: yearLevelId,
      admissionId: admissionId,
    };

    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status === 200) {
        this.setState({ studentDetails: response.data.data[0] });
      }
    });
  };
  handleGetNameValuePair = (studentId) => {
    let param = {
      id: studentId,
    };

    axiosPost(URL.studentNameValuePair, param, (response) => {
      if (response.status === 200) {
        this.setState({ studentNameValuePair: response.data.data });
      } else {
        showErroMessage(response);
      }
    });
  };

  getStudentOptionalSubject = (
    admissionId,
    yearLevelId,
    educationLevelId,
    educationLevelDivisionId
  ) => {
    let param = {
      admissionId: admissionId,
      yearLevelId: yearLevelId,
      assessmentModuleCode: this.props.assessmentModuleCode,
      educationLevelId: educationLevelId,
      educationLevelDivisionId: educationLevelDivisionId,
    };
    axiosPost(URL.selectStudentClassroomOptionalSubject, param, (response) => {
      if (response.status === 200) {
        this.setState({ studentOptionlSubjects: response.data.data });
      }
    });
  };

  getCompulsorySubject = (
    yearLevelId,
    educationLevelId,
    educationLevelDivisionId
  ) => {
    let params = {
      yearLevelId: yearLevelId,
      assessmentModuleCode: this.props.assessmentModuleCode,
      educationLevelId: educationLevelId,
      educationLevelDivisionId: educationLevelDivisionId,
      isCompulsory: true,
    };

    axiosPost(URL.getStudentSubjectsByYearlLevel, params, (response) => {
      if (response.status === 200) {
        this.setState({ compulsorySubjects: response.data.data });
      }
    });
  };

  addErrorField = () => {
    let datas = Object.assign(this.state.assessmentGeneralConfig);
    datas.assessmentGeneralCommentConfigurations.forEach((comment) => {
      comment.error = "";
    });

    this.setState({ assessmentGeneralConfig: datas });
  };

  getAssessmentConfigForStudents = (
    classroomId,
    yearLevelId,
    assessmentId,
    admissionId
  ) => {
    let param = {
      classroomId: classroomId,
      yearLevelId: yearLevelId,
      assessmentId: assessmentId,
      admissionId: admissionId,
    };

    axiosPost(URL.generalAssessmentSelect, param, (response) => {
      if (response.status === 200) {
        this.setState({ assessmentGeneralConfig: response.data.data }, () => {
          this.addErrorField();
          let datas = Object.assign(this.state.assessmentGeneralConfig);

          datas.assessmentGeneralCustomAssessmentConfigurations.forEach(
            (type) => {
              type.assessmentGeneralCustomAssessmentScaleConfigurations.forEach(
                (scale) => {
                  if (scale.level !== null) {
                    scale.assessmentScale.assessmentScaleLevels.forEach(
                      (level) => {
                        if (
                          level.level.toLowerCase() ===
                          scale.level.toLowerCase()
                        ) {
                          var assessmentLevelId = level.id;
                          scale.level = `${assessmentLevelId},${scale.assessmentGeneralCustomAssessmentConfigurationId},${scale.assessmentScaleId}`;
                        }
                      }
                    );
                  }
                }
              );

              type.assessmentGeneralCustomAssessmentRubricsConfigurations.forEach(
                (scale) => {
                  scale.assessmentGeneralCustomAssessmentRubricsScaleConfigurations.forEach(
                    (rubric) => {
                      if (rubric.level !== null) {
                        rubric.assessmentScale.assessmentScaleLevels.forEach(
                          (level) => {
                            if (
                              level.level.toLowerCase() ===
                              rubric.level.toLowerCase()
                            ) {
                              var assessmentLevelId = level.id;
                              rubric.level = `${assessmentLevelId},${rubric.assessmentGeneralCustomAssessmentConfigurationId},${rubric.assessmentGeneralCustomAssessmentRubricsConfigurationId},${rubric.assessmentScaleId}`;
                            }
                          }
                        );
                      }
                    }
                  );
                }
              );
            }
          );

          this.setState({ assessmentGeneralConfig: datas });
        });
      }
    });
  };

  addRubricScale = () => {
    let datas = Object.assign(this.state.assessmentGeneralConfig);

    datas.assessmentGeneralCustomAssessmentConfigurations.forEach((data, i) => {
      data.assessmentGeneralCustomAssessmentRubricsConfigurations.forEach(
        (scale) => {
          scale.assessmentGeneralCustomAssessmentRubricsScaleConfigurations.forEach(
            (levelScale) => {
              levelScale.rubricScaleName = "";
            }
          );
        }
      );
    });

    this.setState({ assessmentGeneralConfig: datas });
  };

  addFieldInComment = () => {
    let datas = Object.assign(this.state.assessmentGeneralConfig);

    datas.assessmentGeneralCommentConfigurations.forEach((data, i) => {
      data.commentTextValue = "";
    });

    this.setState({ assessmentGeneralConfig: datas });
  };

  handleChange = (e, index) => {
    let newData = Object.assign(this.state.assessmentGeneralConfig);
    const name = e.target.name;
    const value = e.target.value;

    newData.assessmentGeneralCommentConfigurations[index][name] = value;
    this.setState({ assessmentGeneralConfig: newData });
  };

  handleCommentChange = (value, index, parentDivId) => {
    let cursorPosition = this.editor.current.selectionStart;

    this.setState({ cursorPosition }, () => {
      if (document.getElementById(parentDivId)) {
        var doc = document.getElementById(parentDivId);
        var commentTemplate = doc.getElementsByTagName("span");
        for (let i = 0; i < commentTemplate.length; i++) {
          if (commentTemplate[i].dataset.template) {
            let nameValuePair = Object.entries(this.state.studentNameValuePair);
            for (let j = 0; j < nameValuePair.length; j++) {
              if (nameValuePair[j][0] === commentTemplate[i].dataset.template) {
                if (nameValuePair[j][1] === "") {
                  swal(nameValuePair[j][0] + " is Empty");
                  //commentTemplate[i].nextSibling.remove();
                  commentTemplate[i].remove();
                } else {
                  commentTemplate[i].innerText = nameValuePair[j][1];
                }
              }
            }
          }
        }

        var children = doc.getElementsByTagName("*");
        for (var i = 0; i < children.length; i++) {
          if (
            children[i].className === "jodit_wysiwyg" ||
            children[i].className === "jodit-wysiwyg"
          ) {
            value = children[i].innerHTML;
          }
        }
      }
      let nameValuePair = Object.entries(this.state.studentNameValuePair);
      for (let j = 0; j < nameValuePair.length; j++) {
        value = value.replaceAll(
          "[" + nameValuePair[j][0] + "]",
          nameValuePair[j][1]
        );
      }
      this.handleCommentChangeAfterReplaceMent(value, index);
    });
  };

  handleCommentChangeAfterReplaceMent = (value, index) => {
    let newData = Object.assign({}, this.state.assessmentGeneralConfig);
    newData.assessmentGeneralCommentConfigurations[index]["commentTextValue"] =
      value;
    this.setState({ assessmentGeneralConfig: newData }, () => {
      this.validate(index);
    });
  };

  validate = (index) => {
    // const errors = {};
    let newData = Object.assign({}, this.state.assessmentGeneralConfig);

    // get the string
    let commentString =
      newData.assessmentGeneralCommentConfigurations[index]["commentTextValue"];
    // eslint-disable-next-line
    let text = commentString.replace(/\&nbsp;/g, "");
    let newText = text.replace(/ /g, "");
    let totalStringLength = newText.length;
    if (
      totalStringLength <
      newData.assessmentGeneralCommentConfigurations[index]["minLength"]
    ) {
      newData.assessmentGeneralCommentConfigurations[index].error =
        "Comment word must be greater than" +
        newData.assessmentGeneralCommentConfigurations[index]["minLength"];
    } else if (
      totalStringLength >
      newData.assessmentGeneralCommentConfigurations[index]["maxLength"]
    ) {
      newData.assessmentGeneralCommentConfigurations[index].error =
        "Comment word must be less than" +
        newData.assessmentGeneralCommentConfigurations[index]["maxLength"];
    } else {
      newData.assessmentGeneralCommentConfigurations[index].error = "";
    }
    // return Object.keys(errors).length === 0 ? null : errors;
    this.setState({ assessmentGeneralConfig: newData });
  };

  multipleScaleNameChangeForRubric = (e, mainIndex, index, scaleIndex) => {
    let newData = Object.assign(this.state.assessmentGeneralConfig);
    const name = e.target.name;
    const value = e.target.value;

    newData.assessmentGeneralCustomAssessmentConfigurations[
      mainIndex
    ].assessmentGeneralCustomAssessmentRubricsConfigurations[
      index
    ].assessmentGeneralCustomAssessmentRubricsScaleConfigurations[scaleIndex][
      name
    ] = value;
    this.setState({ assessmentGeneralConfig: newData });
  };

  multipleScaleNameChange = (e, mainIndex, indx) => {
    let newData = Object.assign(this.state.assessmentGeneralConfig);
    const name = e.target.name;
    const value = e.target.value;

    newData.assessmentGeneralCustomAssessmentConfigurations[
      mainIndex
    ].assessmentGeneralCustomAssessmentScaleConfigurations[indx][name] = value;

    this.setState({ assessmentGeneralConfig: newData });
  };

  handleSubmitGeneralAssessment = (e) => {
    e.preventDefault();
    let assessmentGeneralConfig = Object.assign(
      this.state.assessmentGeneralConfig
    );
    let studentGeneralAssessmentComments =
      this.state.studentGeneralAssessmentComments;

    let studentGeneralAssessmentCustomAssessmentScales =
      this.state.studentGeneralAssessmentCustomAssessmentScales;

    let studentGeneralAssessmentCustomAssessmentRubricsScales =
      this.state.studentGeneralAssessmentCustomAssessmentRubricsScales;

    assessmentGeneralConfig.assessmentGeneralCustomAssessmentConfigurations.forEach(
      (assessment) => {
        assessment.assessmentGeneralCustomAssessmentRubricsConfigurations.forEach(
          (rubric) => {
            rubric.assessmentGeneralCustomAssessmentRubricsScaleConfigurations.forEach(
              (rubricScale) => {
                if (rubricScale.level !== null) {
                  let ids = rubricScale.level.split(",");
                  let newScale = {};
                  newScale.assessmentScaleLevelId = ids[0];
                  newScale.assessmentGeneralCustomAssessmentConfigurationId =
                    ids[1];
                  newScale.assessmentGeneralCustomAssessmentRubricsConfigurationid =
                    ids[2];
                  newScale.assessmentScaleId = ids[3];
                  studentGeneralAssessmentCustomAssessmentRubricsScales.push(
                    newScale
                  );
                }
              }
            );
          }
        );
      }
    );

    this.setState(
      { studentGeneralAssessmentCustomAssessmentRubricsScales },
      () => {
        assessmentGeneralConfig.assessmentGeneralCustomAssessmentConfigurations.forEach(
          (assessment) => {
            assessment.assessmentGeneralCustomAssessmentScaleConfigurations.forEach(
              (scale) => {
                if (scale.level !== null) {
                  let ids = scale.level.split(",");
                  let newScale = {};
                  newScale.assessmentScaleLevelId = ids[0];
                  newScale.assessmentGeneralCustomAssessmentConfigurationId =
                    ids[1];
                  newScale.assessmentScaleId = ids[2];
                  studentGeneralAssessmentCustomAssessmentScales.push(newScale);
                }
              }
            );
          }
        );

        this.setState(
          { studentGeneralAssessmentCustomAssessmentScales },
          () => {
            assessmentGeneralConfig.assessmentGeneralCommentConfigurations.forEach(
              (comment, i) => {
                let commentArr = {};
                commentArr.commentTextValue = comment.commentTextValue;
                commentArr.assessmentGeneralCommentConfigurationId = comment.id;
                studentGeneralAssessmentComments.push(commentArr);
              }
            );
            this.setState({ studentGeneralAssessmentComments }, () => {
              let param = {
                admissionId: this.props.admissionId,
                assessmentId: this.props.assessmentId,
                yearLevelId: this.props.yearId,
                classroomId: this.props.classroomId,
                studentGeneralAssessmentComments:
                  this.state.studentGeneralAssessmentComments,
                studentGeneralAssessmentCustomAssessmentScales:
                  this.state.studentGeneralAssessmentCustomAssessmentScales,
                studentGeneralAssessmentCustomAssessmentRubricsScales:
                  this.state
                    .studentGeneralAssessmentCustomAssessmentRubricsScales,
              };

              this.setState({ showSpinner: true }, () => {
                axiosPost(
                  URL.studentGeneralAssessmentInsert,
                  param,
                  (response) => {
                    if (response.status === 200) {
                      this.props.handleCheckAssessmentFinalize(
                        param.assessmentId,
                        param.classroomId,
                        param.yearLevelId
                      );
                      this.props.handleCheckFinalizeClickable(
                        param.assessmentId,
                        param.classroomId,
                        param.yearLevelId
                      );
                      displaySuccessAlert(response);

                      this.setState({
                        studentGeneralAssessmentComments: [],
                        studentGeneralAssessmentCustomAssessmentScales: [],
                        studentGeneralAssessmentCustomAssessmentRubricsScales:
                          [],
                      });
                      this.setState({ showSpinner: false });
                    } else {
                      showErroMessage(response);
                    }
                  },
                  (failure) => {
                    swal({
                      title: "Error",
                      text: "Error submitting assessment records!",
                    });
                    this.setState({ showSpinner: false });
                  }
                );
              });
            });
          }
        );
      }
    );
  };

  handleInsertComment = (e, comments, commentIdx) => {
    let selectStart = this.state.cursorPosition;
    let selectEnd = this.editor.current.selectionEnd;

    var newData = Object.assign({}, this.state.assessmentGeneralConfig);

    if (
      newData.assessmentGeneralCommentConfigurations[commentIdx][
        "commentTextValue"
      ] !== null
    ) {
      let textBeforeCursorPosition =
        newData.assessmentGeneralCommentConfigurations[commentIdx][
          "commentTextValue"
        ].substring(0, selectStart);
      let textAfterCursorPosition =
        newData.assessmentGeneralCommentConfigurations[commentIdx][
          "commentTextValue"
        ].substring(selectStart, selectEnd);
      newData.assessmentGeneralCommentConfigurations[commentIdx][
        "commentTextValue"
      ] =
        textBeforeCursorPosition +
        comments.commentText +
        textAfterCursorPosition;
    } else {
      newData.assessmentGeneralCommentConfigurations[commentIdx][
        "commentTextValue"
      ] = comments.commentText;
    }
    this.setState({ assessmentGeneralConfig: newData });
  };

  handleFinalizeConfirm = (e) => {
    swal({
      title: "Are you sure?",
      text: this.state.finalize
        ? "Are you sure, you want to unfinalize assessment ?"
        : "Are you sure, you want to finalize assessment ?",
      // icon: "warning",
      closeOnClickOutside: false,
      allowOutsideClick: false,
      buttons: true,
      dangerMode: true,
    }).then((deleteConfirm) => {
      if (deleteConfirm) {
        this.handleIsFinalizeClick();
      } else {
        swal("You clicked cancel");
      }
    });
    draggable();
  };

  handleUnFinalizeConfirm = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure, you want to unfinalize assessment ?",
      closeOnClickOutside: false,
      allowOutsideClick: false,
      buttons: true,
      dangerMode: true,
    }).then((deleteConfirm) => {
      if (deleteConfirm) {
        this.handleUnFinalizeClick();
      } else {
        swal("You clicked cancel");
      }
    });
    draggable();
  };

  handleUnFinalizeClick = () => {
    let param = {
      yearLevelId: this.props.yearLId,
      classroomId: this.props.classroomId,
      assessmentId: this.props.assessId,
      isFinalized: false,
    };

    axiosPost(URL.studentGeneralAssessmentFinalize, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.props.handleCheckAssessmentFinalize(
          this.props.assessId,
          this.props.classroomId,
          this.props.yearLId
        );
        this.props.getYearLevelForPublishAssessmentReport(
          this.props.assessmentModuleCode
        );
      }
    });
  };

  handleIsFinalizeClick = (e) => {
    let param = {
      yearLevelId: this.props.yearLId,
      classroomId: this.props.classroomId,
      assessmentId: this.props.assessId,
      isFinalized: true,
    };

    axiosPost(URL.studentGeneralAssessmentFinalize, param, (response) => {
      if (response.status === 200) {
        if (this.state.finalize) {
          swal(MESSAGEHEADER.success, "Assessment successfully unfinalized.");
        } else {
          swal(MESSAGEHEADER.success, "Assessment successfully finalized.");
        }
        this.props.handleCheckAssessmentFinalize(
          this.props.assessId,
          this.props.classroomId,
          this.props.yearLId
        );
        this.props.getYearLevelForPublishAssessmentReport(
          this.props.assessmentModuleCode
        );
      }
    });
  };

  handleCheckAssessmentFinalize = (assessmentId, classroomId, yearLevelId) => {
    let param = {
      assessmentId: assessmentId,
      classroomId: classroomId,
      yearLevelId: yearLevelId,
    };

    axiosPost(URL.selectGeneralAssessmentIsFinalized, param, (response) => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          if (response.data.data[0].isFinalized) {
            this.setState({ finalize: true });
          } else {
            this.setState({ finalize: false });
          }
        } else {
          this.setState({ finalize: false });
        }
      }
    });
  };

  handleCheckFinalizeClickable = (assessmentId, classroomId, yearLevelId) => {
    let param = {
      assessmentId: assessmentId,
      classroomId: classroomId,
      yearLevelId: yearLevelId,
    };
    axiosPost(URL.selectFinalizeClickable, param, (response) => {
      if (response.status === 200) {
        this.setState({
          finalizeClickable: response.data.data.finalizeClickable,
        });
      } else {
        showErroMessage(response);
      }
    });
  };

  render() {
    return (
      <div style={{ margin: "0px 5px" }}>
        {this.props.highlighted ? (
          <div onClick={this.toggleCollapse}>
            <HighlightedHeader />
          </div>
        ) : (
          <div
            onClick={
              this.props.name === "yearLevel"
                ? (e) => this.props.handleYearLevelToggle(this.props.idx)
                : this.props.name === "classRoom"
                ? (e) =>
                    this.props.handleToggleClassRoom(
                      this.props.idx,
                      this.props.classroomIdx,
                      this.props.classroomId,
                      this.props.yearLvlId
                    )
                : this.props.name === "section"
                ? (e) => {
                    this.props.handleSectionToggle(
                      this.props.assessmentIdx,
                      this.props.classroomId,
                      this.props.yearLId
                    );
                    this.props.handleCheckAssessmentFinalize(
                      this.props.assessId,
                      this.props.classroomId,
                      this.props.yearLId
                    );
                    this.props.handleCheckFinalizeClickable(
                      this.props.assessId,
                      this.props.classroomId,
                      this.props.yearLId
                    );
                  }
                : this.props.name === "student"
                ? (e) => {
                    this.props.handleStudentClick(this.props.studentIdx);
                    this.getAssessmentConfigForStudents(
                      this.props.classroomId,
                      this.props.yearId,
                      this.props.assessmentId,
                      this.props.admissionId
                    );
                    this.handleGetNameValuePair(this.props.studentId);
                    this.getStudentListByAdmissionId(
                      this.props.classroomId,
                      this.props.yearId,
                      this.props.admissionId
                    );
                    this.getStudentOptionalSubject(
                      this.props.admissionId,
                      this.props.yearId,
                      this.props.educationLevelId,
                      this.props.educationLevelDivisionId
                    );
                    this.getCompulsorySubject(
                      this.props.yearId,
                      this.props.educationLevelId,
                      this.props.educationLevelDivisionId
                    );
                    this.getRecruitmentIdByPeopleId();
                  }
                : this.toggleCollapse
            }
            className="tt-group-header text-left"
          >
            <div className="row">
              <div className="col">{this.props.header}</div>
              {this.props.name === "yearLevel" ? (
                <div className="col-md-1 text-center">
                  <FontAwesomeIcon
                    icon={this.props.isOpen ? faAngleUp : faAngleDown}
                  ></FontAwesomeIcon>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {this.props.classroomId && this.props.yearLvlId ? (
          <CustomCollapse
            isOpen={this.props.isOpenClassRoom}
            collapsibleBody={
              this.props.assessmentList
                ? this.props.assessmentList.map((assessment, assessmentIdx) => {
                    return (
                      <Collapsible
                        assessmentModuleCode={this.props.assessmentModuleCode}
                        key={assessmentIdx}
                        assessmentIdx={assessmentIdx}
                        assessId={assessment.assessmentId}
                        educationLevelId={assessment.educationLevelId}
                        educationLevelDivisionId={
                          assessment.educationLevelDivisionId
                        }
                        classroomId={this.props.classroomId}
                        yearLId={this.props.yearLvlId}
                        name="section"
                        handleSectionToggle={this.props.handleSectionToggle}
                        isAssessmentOpen={assessment.isAssessmentOpen}
                        studentList={this.props.studentList}
                        studentListSpinner={this.props.studentListSpinner}
                        handleStudentClick={this.props.handleStudentClick}
                        GeneralAssessmentData={this.state.GeneralAssessmentData}
                        finalize={this.props.finalize}
                        finalizeClickable={this.props.finalizeClickable}
                        getYearLevelForPublishAssessmentReport={
                          this.props.getYearLevelForPublishAssessmentReport
                        }
                        code={this.props.code}
                        getGeneralAssessmentList={
                          this.props.getGeneralAssessmentList
                        }
                        widgetName={this.props.widgetName}
                        handleCheckAssessmentFinalize={
                          this.props.handleCheckAssessmentFinalize
                        }
                        handleCheckFinalizeClickable={
                          this.props.handleCheckFinalizeClickable
                        }
                        header={
                          <div className="tt-general-assessment-classroom-list">
                            <span className="tt-general-assessment-classroom-list-header">
                              {assessment.assessment.assessmentName
                                ? assessment.assessment.assessmentName
                                : null}
                            </span>
                            <div style={{ marginLeft: "5px" }}>
                              <span className="tt-general-assessment-classroom-list-subheader">
                                (
                                {assessment.educationLevelDivisionName
                                  ? `${assessment.educationLevelDivisionName}, `
                                  : null}
                              </span>
                              <span className="tt-general-assessment-classroom-list-subheader">
                                {assessment.recordingDate
                                  ? `${moment(assessment.recordingDate).format(
                                      "Do MMMM, YYYY"
                                    )}, `
                                  : null}
                              </span>
                              <span className="tt-general-assessment-classroom-list-subheader">
                                {assessment.recordingDeadlineDate
                                  ? `${moment(
                                      assessment.recordingDeadlineDate
                                    ).format("Do MMMM, YYYY")}, `
                                  : null}
                              </span>
                              <span className="tt-general-assessment-classroom-list-subheader">
                                (
                                {assessment.assessmentFromDate
                                  ? moment(
                                      assessment.assessmentFromDate
                                    ).format("Do MMMM, YYYY")
                                  : null}{" "}
                              </span>
                              <span className="tt-general-assessment-classroom-list-subheader">
                                /
                              </span>
                              <span className="tt-general-assessment-classroom-list-subheader">
                                {assessment.assessmentToDate
                                  ? moment(assessment.assessmentToDate).format(
                                      "Do MMMM, YYYY"
                                    )
                                  : null}
                                ))
                              </span>
                            </div>
                            <div
                              style={{ display: "block", marginLeft: "auto" }}
                            >
                              <span className="tt-general-assessment-classroom-list-header">
                                {assessmentIdx + 1} of{" "}
                                {this.props.assessmentList.length} Records
                              </span>
                            </div>
                          </div>
                        }
                      />
                    );
                  })
                : "No Data"
            }
          />
        ) : this.props.classroomId &&
          this.props.yearLId &&
          this.props.assessId ? (
          <CustomCollapse
            isOpen={this.props.isAssessmentOpen}
            collapsibleBody={
              <>
                {this.props.studentList &&
                this.props.studentList.length > 0 ? null : this.props
                    .studentListSpinner ? (
                  <div className="text-center mt-1 mb-1">
                    <Spinner color="primary"></Spinner>
                    <br></br>
                    Loading Data ...
                  </div>
                ) : (
                  <div className="text-center mt-1 mb-1">No Students</div>
                )}
                {this.props.studentList
                  ? this.props.studentList.map((student, studentIdx) => {
                      return (
                        <Collapsible
                          header={
                            <div className="tt-student-list">
                              <span>
                                {student.people
                                  ? student.people.name
                                  : "Student Name"}
                                ,
                              </span>
                              <span className="tt-student-list-subheader">
                                {student.people ? student.people.gender : null}
                              </span>
                              <span
                                className="tt-general-assessment-classroom-list-header"
                                style={{ marginTop: "-3px" }}
                              >
                                {studentIdx + 1} of{" "}
                                {this.props.studentList.length} Records
                              </span>
                            </div>
                          }
                          key={studentIdx}
                          assessmentModuleCode={this.props.assessmentModuleCode}
                          studentId={student.people ? student.people.id : null}
                          admissionId={student.admissionId}
                          classroomId={this.props.classroomId}
                          yearId={this.props.yearLId}
                          educationLevelId={this.props.educationLevelId}
                          educationLevelDivisionId={
                            this.props.educationLevelDivisionId
                          }
                          assessmentId={this.props.assessId}
                          studentIdx={studentIdx}
                          studentNameValuePair={this.state.studentNameValuePair}
                          name={"student"}
                          handleStudentClick={this.props.handleStudentClick}
                          isStudentOpen={student.isStudentOpen}
                          getAssessmentConfigForStudents={
                            this.getAssessmentConfigForStudents
                          }
                          GeneralAssessmentData={
                            this.state.GeneralAssessmentData
                          }
                          finalize={this.props.finalize}
                          finalizeClickable={this.props.finalizeClickable}
                          getYearLevelForPublishAssessmentReport={
                            this.props.getYearLevelForPublishAssessmentReport
                          }
                          code={this.props.code}
                          getGeneralAssessmentList={
                            this.props.getGeneralAssessmentList
                          }
                          widgetName={this.props.widgetName}
                          handleCheckAssessmentFinalize={
                            this.props.handleCheckAssessmentFinalize
                          }
                          handleCheckFinalizeClickable={
                            this.props.handleCheckFinalizeClickable
                          }
                        />
                      );
                    })
                  : "No data"}
                <div className="text-right tt-generalAssessment-student-footer">
                  {this.state.finalizeGeneralPermission && (
                    <button
                      className="tt-button tt-button-primary mr-2"
                      onClick={(e) => this.handleFinalizeConfirm(e)}
                      disabled={
                        this.props.finalizeClickable &&
                        this.props.finalize === false
                          ? false
                          : true
                      }
                    >
                      Finalize assessment
                    </button>
                  )}
                  {this.state.unFinalizeGeneralPermission && (
                    <button
                      className="tt-button tt-button-primary ml-2"
                      onClick={(e) => this.handleUnFinalizeConfirm(e)}
                      disabled={this.props.finalize ? false : true}
                    >
                      Unfinalize assessment
                    </button>
                  )}
                </div>
              </>
            }
          />
        ) : this.props.admissionId &&
          this.props.classroomId &&
          this.props.yearId &&
          this.props.assessmentId ? (
          this.props.isStudentOpen ? (
            <CustomCollapse
              isOpen={this.props.isStudentOpen}
              collapsibleBody={
                <StudentGeneralReport
                  studentDetails={this.state.studentDetails}
                  assessmentGeneralConfig={this.state.assessmentGeneralConfig}
                  studentOptionlSubjects={this.state.studentOptionlSubjects}
                  compulsorySubjects={this.state.compulsorySubjects}
                  finalizeGeneralPermission={
                    this.state.finalizeGeneralPermission
                  }
                  handleChange={this.handleChange}
                  handleSubmitGeneralAssessment={
                    this.handleSubmitGeneralAssessment
                  }
                  multipleScaleNameChange={this.multipleScaleNameChange}
                  multipleScaleNameChangeForRubric={
                    this.multipleScaleNameChangeForRubric
                  }
                  handleCommentChange={this.handleCommentChange}
                  handleInsertComment={this.handleInsertComment}
                  peopleId={this.props.studentId}
                  studentNameValuePair={this.props.studentNameValuePair}
                  recruitment={this.state.recruitment}
                  finalize={this.props.finalize}
                  widgetName={this.props.widgetName}
                  isOpen={this.props.isStudentOpen}
                  showSpinner={this.state.showSpinner}
                  editor={this.editor}
                  handleJoditEditorClick={this.handleJoditEditorClick}
                />
              }
            />
          ) : null
        ) : (
          <CustomCollapse
            isOpen={this.props.isOpen}
            collapsibleBody={this.props.collapsibleBody}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  finalize: state.primaryArr.finalize,
  finalizeClickable: state.primaryArr.finalizeClickable,
});

const mapActionsToProps = {
  getYearLevelForPublishAssessmentReport:
    getYearLevelForPublishAssessmentReport,
  getGeneralAssessmentList: getGeneralAssessmentList,
  handleCheckAssessmentFinalize: handleCheckAssessmentFinalize,
  handleCheckFinalizeClickable: handleCheckFinalizeClickable,
};

export default connect(mapStateToProps, mapActionsToProps)(Collapsible);
