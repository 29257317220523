import React, { Component } from "react";

class SubjectiveExamList extends Component {
  state = {};
  render() {
    return <>Subjective Exam List</>;
  }
}

export default SubjectiveExamList;
