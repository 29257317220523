import React, { Component } from "react";
import { Spinner } from "react-mdl";
import swal from "sweetalert";
import { axiosPost } from "../../../../../utils/AxiosApi";
import { draggable, URL } from "../../../../../utils/Constants";
import { displayErrorAlert } from "../../../../../utils/Utils";
import ModalWindow from "../../../../UI/ModalWindow";
import CourseClassResource from "../CourseClassResources/CourseClassResource";

class CourseAssignment extends Component {
  state = {
    title: "",
    description: "",
    submissionDate: "",
    addResourceModal: false,
    contents: [],
    links: [],
    showSpinner: false,
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  handleAddResourceModal = () => {
    this.setState({
      addResourceModal: !this.state.addResourceModal,
    });
  };

  saveResources = (contents, links) => {
    this.setState({ contents: contents, links: links });
    this.handleAddResourceModal();
  };

  createAssignment = () => {
    if (this.state.title === "") {
      swal({
        title: "Error",
        text: "Title cannot be empty",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
    let formData = new FormData();
    for (const key of Object.keys(this.state.contents)) {
      formData.append("contents", this.state.contents[key]);
    }
    formData.append(
      "jsonData",
      JSON.stringify({
        courseClassId: this.props.selectedClassId,
        links: this.state.links,
        title: this.state.title,
        description: this.state.description,
        submissionDateLine: this.state.submissionDate,
        peopleId: localStorage.getItem("peopleId"),
      })
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.insertCourseClassAssignment,
        formData,
        (res) => {
          if (res.status === 200) {
            swal({
              title: "Success",
              text: "Assignment Successfully created",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            this.setState({ showSpinner: false });
            draggable();
            this.props.toggleAssignmentModal();
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
        }
      );
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <strong>Title: </strong>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input
              type="text"
              name="title"
              className="form-control"
              value={this.state.title}
              onChange={this.handleChange}
            ></input>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <strong>Description: </strong>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <textarea
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            ></textarea>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-auto mt-1">
            <strong>Submission Date: </strong>
          </div>
          <div className="col">
            <input
              type="date"
              className="form-control"
              name="submissionDate"
              value={this.state.submissionDate}
              onChange={this.handleChange}
            ></input>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={this.handleAddResourceModal}
            >
              Add Resource
            </button>
          </div>
        </div>
        <div className="row mt-2 button-bg-color">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.createAssignment}
            >
              Create
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.toggleAssignmentModal}
            >
              Cancel
            </button>
          </div>
        </div>
        <ModalWindow
          modal={this.state.addResourceModal}
          toggleModal={this.handleAddResourceModal}
          size="lg"
          modalHeader="Add Resources"
          modalBody={
            <CourseClassResource
              assignment={true}
              toggleAddResourceModal={this.handleAddResourceModal}
              saveResources={this.saveResources}
              assignmentContents={this.state.contents}
              assignmentLinks={this.state.links}
            ></CourseClassResource>
          }
        ></ModalWindow>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Creating Assignment ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CourseAssignment;
