import React, { Component } from "react";
import { handleError } from "../../utils/Utils";
import { Spinner } from "reactstrap";

class UploadForm extends Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-9">
            <div
              className="tt-profileFileName"
              onClick={() => {
                this.upload.click();
              }}
            >
              {this.props.tempImage
                ? this.props.tempImage.name
                : "Choose Profile Picture"}
            </div>
          </div>
          <div className="col-md-3" style={{ paddingLeft: 0 }}>
            <input
              id="image"
              type="file"
              accept="image/png, image/jpeg"
              ref={(ref) => (this.upload = ref)}
              style={{ display: "none" }}
              onChange={this.props.handleImageChange}
              required
            />
            <button
              className="tt-button tt-button-primary tt-button-fullWidth"
              onClick={() => {
                this.upload.click();
              }}
            >
              Attach File
            </button>
          </div>
        </div>
        <div className="tt-profileImages">
          <div className="tt-profilePictureHolder" style={{ padding: 0 }}>
            {this.props.imagePreview ? (
              <>
                <img
                  // src={URL.createObjectURL(this.props.tempImage)}
                  src={this.props.imagePreview}
                  alt="cover"
                  onError={(e) => handleError(e)}
                ></img>
                {/* <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="tt-fileDelete"
                  onClick={this.props.removeProfilePicture}
                ></FontAwesomeIcon> */}
              </>
            ) : (
              "No Image"
            )}
          </div>
        </div>
        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Uploading Image ...
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
export default UploadForm;
