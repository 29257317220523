/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, Education Level, is for rendering the viewfor the Eduction Levels Portion
 *              in the System Setup 1 portion of the System Setup Form
 */

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { displayConfirmDeleteAlert } from "../../utils/Utils";

class EducationLevel extends Component {
  render() {
    let errors = this.props.errors;
    // let renderErrorText = this.props.renderErrorText;

    return (
      <div className="card">
        <div className="card-body">
          <div className="tt-group-header">Education Levels</div>
          <div className="card-body">
            <form
              onSubmit={(e) => this.props.submit(e, this.props.getParameter())}
            >
              {/* Map out the multi dimensional 'levels' array with respect to its length */}
              {this.props.levels.map((level, idx) => {
                return (
                  <div key={idx}>
                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-4 tt-paddingLeft">
                        <label htmlFor="level">
                          <strong>Level {idx + 1}</strong>
                        </label>
                      </div>
                      <div className="col-md-6 tt-paddingRight">
                        <input
                          type="text"
                          className={
                            errors.educationLevels[idx] !== undefined
                              ? errors.educationLevels[idx].level
                                ? "form-control indicate-error"
                                : "form-control"
                              : "form-control"
                          }
                          name="level"
                          onChange={(e) =>
                            this.props.handleLevelsChange(e, idx)
                          }
                          value={level.level}
                          maxLength="255"
                        />
                        <sup className="optionalSup">*</sup>
                      </div>
                      <div className="col-md-1">
                        {/*Only render the Delete button if the array index is greater than Zero which take cares of not 
                       Showing a delete button in the first input box corresponding to the level 1 */}
                        {idx > 0 ? (
                          <>
                            <button
                              type="button"
                              className="tt-button tt-button-danger"
                              onClick={() =>
                                displayConfirmDeleteAlert(
                                  idx,
                                  this.props.removeLevel
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-5"></div>
                      <div className="col-sm-5 tt-inline">
                        <span className="text-muted">Suggestion:&nbsp;</span>
                        <div className="tt-horizontal">
                          {/*Dynamically Map out the "suggestions" array with respect to its length */}
                          {this.props.suggestions.map((sugg, index) => {
                            let len = this.props.suggestions.length;
                            return (
                              <div key={index}>
                                <a
                                  href="!#"
                                  onClick={(e) =>
                                    this.props.handleDynamicFieldSuggestion(
                                      e,
                                      idx,
                                      index,
                                      "level"
                                    )
                                  }
                                >
                                  {sugg}
                                  {index !== len - 1 ? "," : ""}
                                </a>
                                &nbsp;
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-sm-2">
                        {errors.educationLevels[idx] !== undefined
                          ? errors.educationLevels[idx].level
                            ? this.props.renderErrorText()
                            : null
                          : null}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="form-group row">
                <div className="col-md-9"></div>
                <div className="col-md-3 text-right">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.props.addLevel}
                  >
                    Add level
                  </button>
                </div>
              </div>
              {this.props.nonSystemSetup ? (
                <div className="form-group row">
                  <div className="col-md-5"></div>
                  <div className="col-md-2 text-right">
                    <button
                      type="submit"
                      className="tt-button tt-button-primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EducationLevel;
