import React, { Component } from "react";
import IndicatorListRow from "./IndicatorListRow";
import { generateGrades } from "../../../utils/Utils";
class ShowAssociateIndicatorForm extends Component {
  state = {};

  tableRow() {
    let levelListArr = this.props.levelList;
    let OrderedGradeArr = generateGrades();

    let sortedArray = levelListArr.sort((x, y) => {
      return (
        OrderedGradeArr.indexOf(x.level) - OrderedGradeArr.indexOf(y.level)
      );
    });

    return sortedArray
      ? sortedArray.map((val, id) => {
          return (
            <IndicatorListRow
              key={id}
              val={val}
              id={id}
              associateIndicatorDescriptorHandler={
                this.props.associateIndicatorDescriptorHandler
              }
              range={this.props.ranges[id]}
            />
          );
        })
      : null;
  }
  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "76vh", marginBottom: "10px" }}
        >
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-8 scale-content">
              <label className="indicator-descriptor-dropdown-label">
                <strong>Level scale: </strong>
              </label>
              <select
                className="form-control"
                onChange={this.props.handleAssessementScaleLevelHandler}
                name="assessmentScaleLevelId"
                value={this.props.assessmentScaleLevelId}
              >
                <option value="" disabled>
                  Select level
                </option>
                {this.props.assessmentScaleLevelList
                  ? this.props.assessmentScaleLevelList.map((level, idx) => (
                      <option key={idx} value={level.id}>
                        {level.scaleName}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row list-table-row">
            <div className="col indicator-descriptor-list">
              <table className="table table-striped table-bordered no-border-td">
                <thead className="tt-group-header">
                  <tr>
                    <th>( in % )</th>
                    <th>Grade</th>
                    <th>Descriptor</th>
                  </tr>
                </thead>
                <tbody>{this.tableRow()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="associate-modal-button">
          <button
            className="tt-button tt-button-primary tt-associate-button"
            onClick={this.props.associateScaleSubmit}
          >
            Associate Scale
          </button>
          <button
            className="tt-button mr-4 tt-button-primary tt-associate-button"
            onClick={this.props.cancelHandle}
          >
            Cancel
          </button>
        </div>
      </>
    );
  }
}

export default ShowAssociateIndicatorForm;
