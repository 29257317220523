import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayMessage, displaySuccessAlert } from "../../utils/Utils";
import "../../assets/scss/permissionConfiguration.scss";
import { userRoleCode, userRole } from "./../../utils/Constants";
import history from "../../utils/History";
import { Spinner } from "reactstrap";

import Navbar from "./../Profile/Navbar";

export default class PermissionConfiguration extends Component {
  state = {
    activities: [],
    userRoles: [],
    userSubRoles: [],
    widgets: [],
    modules: null,
    roleCode: null,
    subRoleId: null,
    allSelected: false,
    fullWidgets: [],
    render: false,
    dropdownOpen: false,
  };

  componentDidMount() {
    userRole !== userRoleCode.roleAdmin && history.push("/");
    this.getUserRoles();
    this.getWidgets();
  }
  getUserRoles = () => {
    axiosPost(URL.getUserRoles, {}, (response) => {
      if (response.status === 200) {
        this.setState({ userRoles: response.data.data });
      }
    });
  };

  getWidgets = () => {
    axiosPost(URL.getWidgets, {}, (response) => {
      if (response.status === 200) {
        this.setState({ widgets: response.data.data }, () => {
          this.getWidgetModules();
        });
      }
    });
  };

  getWidgetModules = () => {
    axiosPost(URL.getWidgetModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ widgetModules: response.data.data }, () => {
          this.getAllModules();
        });
      }
    });
  };

  getAllModules = () => {
    axiosPost(URL.getAllModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ modules: response.data.data }, () => {
          this.getAllActivity();
        });
      }
    });
  };

  getAllActivity = () => {
    axiosPost(URL.getAllActivity, {}, (response) => {
      if (response.status === 200) {
        let activitylist = response.data.data;
        this.setState({ activities: activitylist }, () => {
          //this.insertModulesAndActivities();
        });
      }
    });
  };

  insertModulesAndActivities = () => {
    let newWidgets = JSON.parse(JSON.stringify(this.state.widgets));
    let newModules = JSON.parse(JSON.stringify(this.state.modules));
    let newActivities = JSON.parse(JSON.stringify(this.state.activities));

    newModules.forEach((module) => {
      let activities = [];
      newActivities.forEach((activity) => {
        if (activity.moduleId === module.id) {
          activities.push(activity);
        }
      });
      module.activities = activities;
    });
    newWidgets.forEach((widget) => {
      let modules = [];
      this.state.widgetModules.forEach((widgetModule) => {
        if (widget.id === widgetModule.widgetId) {
          let moduleIndex = newModules.findIndex(
            (module) => module.id === widgetModule.moduleId
          );
          if (moduleIndex !== -1) {
            modules.push(newModules[moduleIndex]);
          }
        }
      });
      widget.modules = modules;
    });
    this.setState({ fullWidgets: newWidgets }, () => {
      this.insertPermissionIntoFullWidgets();
    });
  };

  getSubRoles = (roleCode) => {
    axiosPost(URL.getSubRolesByUserRole, { roleCode }, (response) => {
      if (response.status === 200) {
        this.setState({ userSubRoles: response.data.data });
      }
    });
  };

  getModulesForPermission = (param) => {
    axiosPost(URL.getModulesForPermission, param, (response) => {
      if (response.status === 200) {
        this.setState({ modules: response.data.data });
      }
    });
  };
  // Handle Functions from here on
  handleUserRoleChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        roleCode: value,
        subRoleId: null,
      },
      () => {
        this.getSubRoles(this.state.roleCode);
      }
    );
  };

  handleSelectSubRole = (e) => {
    let value = e.target.value;
    this.setState(
      {
        subRoleId: value,
      },
      () => {
        this.getPermissions();
      }
    );
  };

  getPermissions = () => {
    const data = {
      roleCode: this.state.roleCode,
      subRoleId: this.state.subRoleId,
    };
    axiosPost(URL.getPermissionList, data, (response) => {
      if (response.status === 200) {
        this.setState({ permissions: response.data.data }, () => {
          this.insertModulesAndActivities();
        });
      } else {
        this.setState(
          { render: true, fullWidgets: [], permissions: [] },
          () => {
            this.insertModulesAndActivities();
          }
        );
      }
    });
  };

  insertPermissionIntoFullWidgets = () => {
    //let newFullWidgets = [...this.state.fullWidgets];
    let newFullWidgets = JSON.parse(JSON.stringify(this.state.fullWidgets));
    if (this.state.permissions && this.state.permissions.length > 0) {
      this.state.permissions.forEach((permission) => {
        let widgetIndex = newFullWidgets.findIndex(
          (widget) => widget.id === permission.widgetId
        );

        if (widgetIndex !== -1) {
          let moduleIndex = newFullWidgets[widgetIndex].modules.findIndex(
            (module) => module.id === permission.moduleId
          );

          if (moduleIndex !== -1) {
            let activityIndex = newFullWidgets[widgetIndex].modules[
              moduleIndex
            ].activities.findIndex(
              (activity) => activity.id === permission.activityId
            );

            if (activityIndex !== -1) {
              newFullWidgets[widgetIndex].modules[moduleIndex].activities[
                activityIndex
              ].isTrue = true;
            } else {
              newFullWidgets[widgetIndex].modules[moduleIndex].activities[
                activityIndex
              ].isTrue = false;
            }
          }
        }
      });
    }
    this.setState({ render: true, fullWidgets: newFullWidgets });
  };

  handleActivityCheckboxChange = (
    e,
    widgetIndex,
    moduleIndex,
    activityIndex
  ) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets[widgetIndex].modules[moduleIndex].activities[
      activityIndex
    ].isTrue = e.target.checked;
    this.setState({ fullWidgets: newFullWidgets });
  };

  handleWidgetCheckboxChange = (e, widgetIndex) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets[widgetIndex].modules.forEach((module) => {
      module.moduleChecked = e.target.checked;
      module.activities.forEach((activity) => {
        activity.isTrue = e.target.checked;
      });
    });
    newFullWidgets[widgetIndex].widgetChecked = e.target.checked;
    this.setState({ fullWidgets: newFullWidgets });
  };

  handleModuleCheckboxChange = (e, widgetIndex, moduleIndex) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets[widgetIndex].modules[moduleIndex].activities.forEach(
      (activity) => {
        activity.isTrue = e.target.checked;
      }
    );
    newFullWidgets[widgetIndex].modules[moduleIndex].moduleChecked =
      e.target.checked;
    this.setState({ fullWidgets: newFullWidgets });
  };

  handleSelectAllCheckboxChange = (e) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets.forEach((widget) => {
      widget.widgetChecked = e.target.checked;
      widget.modules.forEach((module) => {
        module.moduleChecked = e.target.checked;
        module.activities.forEach((activity) => {
          activity.isTrue = e.target.checked;
        });
      });
    });
    this.setState({ fullWidgets: newFullWidgets });
  };

  handlePermissionsSubmit = (e) => {
    e.preventDefault();
    if (!this.state.roleCode || !this.state.subRoleId) {
      displayMessage("Error", "Please select user role and subrole first.");
    } else {
      let permissions = [];
      this.state.fullWidgets.forEach((widget) => {
        widget.modules.forEach((module) => {
          module.activities.forEach((activity) => {
            if (activity.isTrue && activity.isTrue === true) {
              permissions.push({
                widgetId: widget.id,
                moduleId: module.id,
                activityId: activity.id,
              });
            }
          });
        });
      });
      let data = {
        roleCode: this.state.roleCode,
        subRoleId: this.state.subRoleId,
        permissions: permissions,
      };

      this.setState(
        {
          submitSpinner: true,
        },
        () => {
          axiosPost(URL.insertPermissionConfiguration, data, (response) => {
            if (response.status === 200) {
              displaySuccessAlert(response);
              this.setState({
                submitSpinner: false,
              });
            }
          });
        }
      );
    }
  };
  //for nav bar
  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  render() {
    return (
      <>
        {this.state.submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... permissions are being assigned
            </div>
          </div>
        ) : (
          ""
        )}

        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
        />
        <form>
          <div className="container">
            <div className="row div-role">
              <div className="col-md-3"></div>
              {/* User Roles */}
              <div className="col-md-3 mt-2">
                <h4 className="text-center">User Roles</h4>
                <hr className="horiPermission"></hr>
                {this.state.userRoles.length > 0 ? (
                  <div className="row justify-content-center">
                    <select
                      className="permission-role-label"
                      value={this.state.roleCode || ""}
                      onChange={(e) => this.handleUserRoleChange(e)}
                    >
                      <option value="" disabled selected>
                        {"Select User Role"}
                      </option>
                      {this.state.userRoles.map((userRole) => (
                        <option value={userRole.code}>{userRole.name}</option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
              {/* User Sub Roles */}
              <div className="col-md-3 mt-2">
                <h4 className="text-center">User Sub Roles</h4>
                <hr className="horiPermission"></hr>
                {this.state.userSubRoles.length > 0 ? (
                  <div className="row justify-content-center">
                    <select
                      className="permission-role-label"
                      onChange={(e) => this.handleSelectSubRole(e)}
                      value={this.state.subRoleId || ""}
                    >
                      <option value="" disabled selected>
                        {"Select User Sub Role"}
                      </option>
                      {this.state.userSubRoles.map((userSubRole) => (
                        <option value={userSubRole.id}>
                          {userSubRole.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="tt-permissionColumn">
              {this.state.fullWidgets.length > 0 ? (
                <table className="table">
                  <thead className="tt-group-header permissionTable">
                    <tr>
                      <th className="text-center" width="10%">
                        <input
                          type="checkbox"
                          className="activity-checkbox"
                          //   checked={this.state.allSelected}
                          onChange={this.handleSelectAllCheckboxChange}
                        />
                      </th>
                      <th width="90%">
                        Select Widgtes With Modules And Activities
                      </th>
                    </tr>
                  </thead>
                </table>
              ) : (
                <div className="text-center mb-2 mt-2">
                  Select User Role and Sub Role First
                </div>
              )}
              {this.state.fullWidgets &&
                this.state.render &&
                this.state.fullWidgets.map((widget, widgetIndex) => (
                  <table className="table">
                    <thead className="tt-group-header permissionTable">
                      <tr>
                        <th
                          className="text-center"
                          width="10%"
                          style={{ backgroundColor: "#3f3f9e" }}
                        >
                          <input
                            type="checkbox"
                            className="activity-checkbox"
                            checked={widget.widgetChecked}
                            onChange={(e) =>
                              this.handleWidgetCheckboxChange(e, widgetIndex)
                            }
                          />
                        </th>
                        <th width="90%" style={{ backgroundColor: "#3f3f9e" }}>
                          {widget.name}
                        </th>
                      </tr>
                    </thead>
                    {widget.modules &&
                      widget.modules.map((module, moduleIndex) => (
                        <>
                          <thead className="tt-group-header permissionTable">
                            <tr>
                              <th width="10%" className="text-center">
                                <input
                                  type="checkbox"
                                  className="activity-checkbox"
                                  checked={module.moduleChecked}
                                  onChange={(e) =>
                                    this.handleModuleCheckboxChange(
                                      e,
                                      widgetIndex,
                                      moduleIndex
                                    )
                                  }
                                />
                              </th>
                              <th width="90%">{module.name}</th>
                            </tr>
                          </thead>
                          <tbody className="permissionTable">
                            {module.activities &&
                              module.activities.map(
                                (activity, activityIndex) => (
                                  <tr>
                                    <th width="10%" className="text-center">
                                      <input
                                        type="checkbox"
                                        className="activity-checkbox"
                                        checked={activity.isTrue || false}
                                        onChange={(e) =>
                                          this.handleActivityCheckboxChange(
                                            e,
                                            widgetIndex,
                                            moduleIndex,
                                            activityIndex
                                          )
                                        }
                                      />
                                    </th>
                                    <th width="90%">{activity.name}</th>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </>
                      ))}
                  </table>
                ))}
            </div>
            <div className="row">
              <div className="col text-right">
                <button
                  onClick={this.handlePermissionsSubmit}
                  className="tt-button tt-button-primary"
                >
                  {"Assign Permission"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
