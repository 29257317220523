import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "../../../assets/scss/calendarWidget.scss";
import moment from "moment";
import { URL } from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import ListAllCalendarEvents from "./ListAllCalendarEvents";
import * as htmlToImage from "html-to-image";
import { Spinner } from "reactstrap";
import { groupBy } from "lodash";

class DisplayAllCalendar extends Component {
  calendarComponentRef = React.createRef();
  state = {
    widgetEvents: [],
    currentAcYrId: "",
    currentAcYr: "",
    currentAcYrName: "",
    dates: [],
    filteredWidgetEvents: [],
    allCalendarEventTitle: [],
    allHolidayEvents: [],
    spinner: false,
    eventhavingSameName: "",
  };

  componentDidMount = () => {
    // this.getWidgetEvents();
    // this.getAllDates();
    this.getUserCurrentAcademicYear();
    this.getWidgetEvents();
  };

  getUserCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState(
            {
              currentAcYr: response.data.data,
              currentAcYrId: response.data.data.academicYearId,
              currentAcYrName: response.data.data.academicYearName,
            },
            function () {
              this.getAcYrDate();
            }
          );
        } else {
          this.getCurrentAcademicYear();
        }
      }
    });
  };
  getAcYrDate = () => {
    let startDates = moment(this.state.currentAcYr.startDate).format(
      "YYYY-MM-DD"
    );
    let x = startDates.split("-");
    let endDates = moment(this.state.currentAcYr.endDate).format("YYYY-MM-DD");
    let y = endDates.split("-");
    let newDates = [...this.state.dates];
    if (parseInt(x[0]) < parseInt(y[0]) || parseInt(x[0]) === parseInt(y[0])) {
      if (parseInt(x[0]) === parseInt(y[0])) {
        if (
          parseInt(x[1]) < parseInt(y[1]) ||
          parseInt(x[1]) === parseInt(y[1])
        ) {
          for (let i = parseInt(x[1]); i <= parseInt(y[1]); i++) {
            if (i < 10) {
              newDates.push(x[0] + "-0" + i.toString() + "-01");
            } else {
              newDates.push(x[0] + "-" + i.toString() + "-01");
            }
          }
        }
      } else {
        for (let i = parseInt(x[0]); i <= parseInt(y[0]); i++) {
          let mx, my;
          if (i === parseInt(x[0])) {
            mx = parseInt(x[1]);
            my = 12;
          } else if (i === parseInt(y[0])) {
            mx = 1;
            my = parseInt(y[1]);
          } else {
            mx = 1;
            my = 12;
          }
          for (let j = mx; j <= my; j++) {
            if (j < 10) {
              newDates.push(i.toString() + "-0" + j.toString() + "-01");
            } else {
              newDates.push(i.toString() + "-" + j.toString() + "-01");
            }
          }
        }
      }
    }
    this.setState({
      dates: newDates,
    });
    // ReactTooltip.rebuild();
  };

  getCurrentAcademicYear = () => {
    let data = {
      isCurrent: "true",
    };
    axiosPost(URL.getAcademicYears, data, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            currentAcYr: response.data.data[0],
            currentAcYrId: response.data.data[0].id,
            currentAcYrName: response.data.data[0].name,
          },
          function () {
            this.getAcYrDate();
          }
        );
      }
    });
  };

  getWidgetEvents = () => {
    axiosPost(URL.getEvents, {}, async (responseWidgets) => {
      if (responseWidgets.status === 200) {
        axiosPost(URL.getWeekDaysByEduDiv, {}, (response) => {
          if (response.status === 200) {
            const weekDays = [0, 1, 2, 3, 4, 5, 6];
            const days = response.data.data.map(({ id }) => id);
            let filteredDays = weekDays.filter((el) => !days.includes(el));
            let anEvent = [];
            anEvent.push({
              daysOfWeek: filteredDays,
              rendering: "background",
              color: `#668ef5`,
              overLap: false,
              allDay: true,
            });

            let newEvents = [...responseWidgets.data.data];
            newEvents.forEach((event) => {
              event.end = new Date(event.end).toISOString();
            });
            this.setState(
              {
                widgetEvents: [...anEvent, ...newEvents],
              },
              () => {
                this.manipulateWidgetEvent();
              }
            );
          }
        });
      }
    });
  };
  //Axios Get request to get values from the database
  getAllDates = () => {
    let newDates = [...this.state.dates];
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        newDates.push("0" + i.toString());
      } else {
        newDates.push(i.toString());
      }
    }
    this.setState({ dates: newDates });
  };

  handleEventPositioned = (arg) => {
    arg.el.setAttribute("data-tip", arg.event.extendedProps.description);
    // ReactTooltip.rebuild();
  };

  closeModal = (e) => {
    this.setState({
      modal: !this.state.modal,
      calendarTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      checked: false,
      selectedEvent: "",
    });
  };

  manipulateWidgetEvent = () => {
    let widgetEventsCopy = [...this.state.widgetEvents];
    const unique = [];
    widgetEventsCopy.map((x) =>
      unique.filter(
        (a) =>
          a.color == x.color &&
          a.title == x.title &&
          a.start == x.start &&
          a.end == x.end
      ).length > 0
        ? null
        : unique.push(x)
    );

    const reqEvent = unique.map((el) => ({
      eventTypeName: el.eventTypeName,
      color: el.color,
      start: el.start,
      end: el.end,
      title: el.title,
      holiday: el.isHoliday,
    }));

    const filteredReqEvent = [];
    reqEvent.map((x) =>
      filteredReqEvent.filter((a) => a.color == x.color).length > 0
        ? null
        : filteredReqEvent.push(x)
    );

    const filterNullValue = filteredReqEvent.filter(
      (el) => el.eventTypeName !== null && el.eventTypeName !== undefined
    );
    let dataWithSameEventName = [];
    unique.forEach((el, id) => {
      filterNullValue.forEach((evnt, id) => {
        if (evnt.eventTypeName === el.eventTypeName) {
          return dataWithSameEventName.push(el);
        }
      });
    });

    const groupByEventTypeName = groupBy(
      dataWithSameEventName,
      (d) => d.eventTypeName
    );

    const allHolidayEvents = unique.filter((el) => el.isHoliday === true);

    this.setState({
      filteredWidgetEvents: unique,
      allCalendarEventTitle: filterNullValue,
      allHolidayEvents: allHolidayEvents,
      eventhavingSameName: groupByEventTypeName,
    });
  };

  handleCalendarEventDownload = async () => {
    this.setState({ spinner: true });
    await htmlToImage
      .toJpeg(document.getElementById("printEvent"), {
        quality: 1.0,
        backgroundColor: "white",
      })
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = "Calendar.jpeg";
        link.href = dataUrl;
        link.click();
        this.setState({ spinner: false });
      });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-2 mb-2">
            <div className="col text-right">
              {this.state.widgetEvents?.length > 0 ? (
                <button
                  className="tt-button tt-button-primary"
                  onClick={() => this.handleCalendarEventDownload()}
                >
                  Download
                </button>
              ) : null}
            </div>
          </div>
          <div className="row" id="printEvent">
            <div
              className="col-md tt-adjustPadding tt-widgetContent-tab-holder"
              style={{ height: "auto" }}
            >
              <div className="tt-adjustPadding">
                <div
                  className="tt-displayDate"
                  style={{
                    height: "auto",
                    overflowY: "visible",
                    justifyContent: "space-evenly",
                  }}
                >
                  {this.state.dates.map((date, idx) => {
                    return (
                      <div
                        className="col-md-3 tt-widgetContent-tab-holder allCalendarDisplay m-3"
                        key={idx}
                      >
                        <FullCalendar
                          defaultDate={date}
                          header={{ left: "", center: "title", right: "" }}
                          defaultView="dayGridMonth"
                          plugins={[dayGridPlugin]}
                          weekends={true}
                          fullDay={true}
                          columnHeader={false}
                          events={this.state.filteredWidgetEvents || []}
                          validRange={{
                            start: this.state.currentAcYr.startDate,
                            end: this.state.currentAcYr.endDate,
                          }}
                          displayEventTime={false}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <ListAllCalendarEvents
                events={this.state.filteredWidgetEvents}
                eventsTitle={this.state.allCalendarEventTitle}
                holidayEvents={this.state.allHolidayEvents}
                eventsWithSameEventType={this.state.eventhavingSameName}
              />
            </div>
          </div>
        </div>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Downloading Academic Events
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
export default DisplayAllCalendar;
