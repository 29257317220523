import React, { Component } from "react";
import AdmissionGroupConfig from "../../Admission/AdmissionGroupConfig";
import ModalWindow from "../../UI/ModalWindow";
import CustomFieldForm from "../../Admission/CustomFieldForm";
import GroupForm from "../../Admission/GroupForm";
import { URL, checkWidgetAdminOrNot } from "../../../utils/Constants";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../../utils/AxiosApi";
import { getRecruitmentConfiguration } from "../../../ducks/RecruitmentDucks";
import { displayErrorAlert, displaySuccessAlert } from "../../../utils/Utils";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import { cloneDeep } from "lodash";
import Swal from "sweetalert2";

/**
 * @author Saurav Sitaula
 * @description Component for new recruitment form
 */

class NewRecruitmentConfiguration extends Component {
  state = {
    isOpen: false,
    setIsOpen: false,
    isMandatory: false,
    groupConfigs: [],
    studentContactGroups: [],
    currentCustomField: {
      id: null,
      code: "",
      fieldName: "",
      fieldTypeName: "",
      unalterable: 0,
      isMandatory: true,
    },
    currentGroupConfigIndex: null, //currently being added field
    // for modal
    isModalOpen: false,
    isGroupModalOpen: false,
    updatingCustomField: false,
    currentUpdateGroupIndex: null,
    currentCustomTabFieldIndex: null,
    studentGroup: false,
    isLoading: true,
  };

  componentDidMount() {
    this.populateGroupConfigs();
    this.populateDefaultCurrentCustomField();
  }

  populateDefaultCurrentCustomField = () => {
    this.setState({
      currentCustomField: this.getDefaultCurrentCustomField(),
    });
  };

  getDefaultCurrentCustomField = () => {
    return {
      fieldTypeCode: "",
      fieldTypeId: null,
      fieldName: "",
      fieldTypeName: "",
      isMandatory: true,
    };
  };

  populateGroupConfigs = () => {
    axiosPost(URL.getRecruitmentConfiguration, {}, (response) => {
      if (response.status === 200) {
        let groupConfigs = response.data.data;
        if (
          groupConfigs !== null &&
          groupConfigs !== undefined &&
          groupConfigs.length > 0
        )
          groupConfigs[0].isOpen = true;
        groupConfigs.forEach((element) => {
          element.customTableFields.forEach((field) => {
            field.value = "";
            field.year = null;
            field.month = null;
            field.day = null;
            field.gender = null;
            //below code added for custom table
            field.rows = [];
            field.rows.push({
              rowId: 0,
            });
            return field.customFieldTableColumns?.forEach((col) => {
              col.rows = [];
              col.rows.push({
                rowId: 0,
              });
            });
          });
        });
        this.setState({
          groupConfigs: groupConfigs,
          isLoading: false,
        });
      }
    });
  };

  handleGroupConfigToggle = (e, index, studentGroup) => {
    if (studentGroup === "studentGroup") {
      let newstudentContactGroups = [...this.state.studentContactGroups];
      newstudentContactGroups.forEach((studentGroup, i) => {
        index === i
          ? (studentGroup.isOpen = !studentGroup.isOpen)
          : (studentGroup.isOpen = false);
      });
      this.setState({ studentContactGroups: newstudentContactGroups });
    } else {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      groupConfigs.forEach((groupConfig, i) => {
        index === i
          ? (groupConfig.isOpen = !groupConfig.isOpen)
          : (groupConfig.isOpen = false);
      });
      this.setState({ groupConfigs: groupConfigs });
    }
  };

  handleMandatoryToggleBtnToggle = (index, fieldIndex, studentGroup) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      let subjectField = groupConfigs[index].customTableFields[fieldIndex];
      subjectField.isMandatory = !subjectField.isMandatory;
      this.setState({ groupConfigs: groupConfigs });
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      let subjectField =
        studentContactGroups[index].customTableFields[fieldIndex];
      subjectField.isMandatory = !subjectField.isMandatory;
      this.setState({ studentContactGroups: studentContactGroups });
    }
  };

  handleAddCustomFieldBtnClick = (e, groupConfigIndex, studentGroup) => {
    if (studentGroup !== "studentGroup") {
      this.setState({ currentGroupConfigIndex: groupConfigIndex }, () => {
        this.handleModalToggle();
      });
    } else {
      this.setState(
        { currentStudentGroupConfigIndex: groupConfigIndex },
        () => {
          this.handleModalToggle();
        }
      );
    }
  };

  handleCancelCustomFieldBtnClick = () => {
    this.setState(
      {
        currentCustomField: {
          id: null,
          fieldTypeCode: "",
          fieldName: "",
          fieldTypeName: "",
          unalterable: 0,
          isMandatory: true,
        },
        updatingCustomField: false,
      },
      () => {
        this.handleModalToggle();
      }
    );
  };

  //working on this
  handleUpdateCustomFieldBtnClick = (e) => {
    e.preventDefault();
    let newGroupConfigs = [...this.state.groupConfigs];

    let newStudentContactGroups = [...this.state.studentContactGroups];

    //get selected config group index and custom field index from state
    const gIndex = this.state.currentUpdateGroupIndex;
    const cIndex = this.state.currentCustomTabFieldIndex;
    const isStudentGroup = this.state.studentGroup;

    if (!isStudentGroup) {
      newGroupConfigs[gIndex].customTableFields[
        cIndex
      ] = this.state.currentCustomField;
    } else {
      newStudentContactGroups[gIndex].customTableFields[
        cIndex
      ] = this.state.currentCustomField;
    }
    // newGroupConfigs.find((group, i) => {
    //   group.customTableFields.find((customField, customIndex) => {
    //     if (customField.id === this.state.currentCustomField.id) {
    //       newGroupConfigs[i].customTableFields[
    //         customIndex
    //       ].fieldTypeId = this.state.currentCustomField.fieldTypeId;
    //       newGroupConfigs[i].customTableFields[
    //         customIndex
    //       ].fieldName = this.state.currentCustomField.fieldName;
    //       newGroupConfigs[i].customTableFields[
    //         customIndex
    //       ].fieldTypeName = this.state.currentCustomField.fieldTypeName;
    //       newGroupConfigs[i].customTableFields[
    //         customIndex
    //       ].fieldTypeCode = this.state.currentCustomField.fieldTypeCode;
    //     }
    //   });
    // });
    // let newStudentContactGroups = [...this.state.studentContactGroups];
    // // eslint-disable-next-line
    // newStudentContactGroups.find((studentContactGroup, i) => {
    //   // eslint-disable-next-line
    //   studentContactGroup.customTableFields.find((customField, customIndex) => {
    //     if (customField.id === this.state.currentCustomField.id) {
    //       newStudentContactGroups[i].customTableFields[
    //         customIndex
    //       ].fieldTypeId = this.state.currentCustomField.fieldTypeId;
    //       newStudentContactGroups[i].customTableFields[
    //         customIndex
    //       ].fieldName = this.state.currentCustomField.fieldName;
    //       newStudentContactGroups[i].customTableFields[
    //         customIndex
    //       ].fieldTypeName = this.state.currentCustomField.fieldTypeName;
    //       newStudentContactGroups[i].customTableFields[
    //         customIndex
    //       ].fieldTypeCode = this.state.currentCustomField.fieldTypeCode;
    //     }
    //   });
    // });

    this.setState(
      {
        groupConfigs: newGroupConfigs,
        studentContactGroups: newStudentContactGroups,
      },
      () => {
        this.handleModalToggle();
      }
    );
  };

  handleSubmitCustomFieldBtnClick = () => {
    const currentCustomField = this.state.currentCustomField;
    if (currentCustomField.fieldName === "") {
      swal("warning", "You have empty fields.");
      return;
    }
    if (currentCustomField.fieldTypeId === null) {
      swal("warning", "Please assign field type.");
      return;
    }
    if (currentCustomField.fieldTypeCode === "table") {
      for (
        let i = 0;
        i < currentCustomField.customFieldTableColumns.length;
        i++
      ) {
        const column = currentCustomField.customFieldTableColumns[i];
        if (column["name"] === undefined || column["name"] === "") {
          swal("warning", "You have empty fields.");
          return;
        }
        if (column.hasSubColumns) {
          for (let i = 0; i < column.customFieldTableSubColumns.length; i++) {
            const subColumn = column.customFieldTableSubColumns[i];
            if (subColumn["name"] === undefined || subColumn["name"] === "") {
              swal("warning", "You have empty fields.");
              return;
            }
            if (subColumn.fieldTypeId === null) {
              swal("warning", "Please assign field type.");
              return;
            }
          }
        } else {
          // if (column["name"] === undefined || column["name"] === "") {
          //   swal("warning", "You have empty fields.");
          //   return;
          // }
          if (column.fieldTypeId === null) {
            swal("warning", "Please assign field type.");
            return;
          }
        }
      }
    }

    let groupConfigs = Object.assign([], this.state.groupConfigs);
    groupConfigs[this.state.currentGroupConfigIndex].customTableFields.push(
      this.state.currentCustomField
    );
    this.setState(
      {
        groupConfigs: groupConfigs,
        currentCustomField: this.getDefaultCurrentCustomField(),
      },
      () => {
        this.setState({ updatingCustomField: false });
        this.handleModalToggle();
      }
    );
  };

  handleModalToggle = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  /**
   * Handle custom field type change
   */
  handleCustomFieldTypeChange = (e, fieldTypes) => {
    let currentCustomField = cloneDeep(this.state.currentCustomField);
    if (fieldTypes) {
      let fieldType = fieldTypes.find((fieldType) => {
        return fieldType.id === parseInt(e.target.value);
      });
      currentCustomField.fieldTypeId = fieldType.id;
      currentCustomField.fieldTypeName = fieldType.name;
      currentCustomField.fieldTypeCode = fieldType.code;
      currentCustomField.unalterable = 0;
      currentCustomField.isMulti = fieldType.isMulti;
      if (
        currentCustomField.isMulti &&
        currentCustomField.fieldTypeCode !== "table"
      ) {
        currentCustomField.customTableFieldDatas = [
          {
            customTableFieldId: fieldType.id,
            data: "",
          },
          {
            customTableFieldId: fieldType.id,
            data: "",
          },
        ];
      }
      if (
        currentCustomField.isMulti &&
        currentCustomField.fieldTypeCode === "table"
      ) {
        currentCustomField.customFieldTableColumns = [
          {
            fieldTypeId: null,
            customFieldTableColumnOptionDatas: [],
            hasSubColumns: false,
          },
        ];
        currentCustomField.rows = [];
        currentCustomField.rows.push({
          rowId: 0,
        });
      }
      this.setState({ currentCustomField: currentCustomField });
    }
  };

  handleAddDatas = (e) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    if (currentCustomField.isMulti) {
      currentCustomField.customTableFieldDatas.push({
        customTableFieldId: currentCustomField.fieldTypeId,
        data: "",
      });
    }
    this.setState({ currentCustomField: currentCustomField });
  };

  handleRemoveDatas = (e, index) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    currentCustomField.customTableFieldDatas.splice(index, 1);
    this.setState({ currentCustomField: currentCustomField });
  };

  handleCustomFieldDataChange = (e, index) => {
    let currentCustomField = { ...this.state.currentCustomField };
    currentCustomField.customTableFieldDatas[index].data = e.target.value;
    this.setState({ currentCustomField: currentCustomField });
  };

  handleCustomFieldNameChange = (e) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    currentCustomField[e.target.name] = e.target.value;
    this.setState({ currentCustomField: currentCustomField });
  };

  // custom field method end
  // group modal end
  handleAddNewGroupBtnClick = (e) => {
    this.handleGroupModalToggle();
  };

  handleCancelGroupBtnClick = () => {
    this.handleGroupModalToggle();
  };

  handleSubmitNewGroupBtnClick = (e, groupConfigName) => {
    let groupConfigs = Object.assign([], this.state.groupConfigs);
    groupConfigs.forEach((groupConfig) => {
      groupConfig.isOpen = false;
    });
    groupConfigs.push({
      name: groupConfigName,
      headerHidden: false,
      isProfileInfo: false,
      unalterable: 0,
      isOpen: true,
      customTableFields: [],
    });
    this.setState({ groupConfigs: groupConfigs }, () => {
      this.handleGroupModalToggle();
    });
  };

  handleGroupModalToggle = (e) => {
    this.setState({ isGroupModalOpen: !this.state.isGroupModalOpen });
  };

  /**
   * Handles show group header or hide
   */
  handleHeaderHiddenCheckboxClick = (e, groupConfigIndex, studentGroup) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      if (groupConfigs) {
        groupConfigs[groupConfigIndex].headerHidden = e.target.checked;
      }
      this.setState({ groupConfigs: groupConfigs });
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      if (studentContactGroups) {
        studentContactGroups[groupConfigIndex].headerHidden = e.target.checked;
      }
      this.setState({ studentContactGroups: studentContactGroups });
    }
  };
  /**
   * Handles add in profile information checkbox click
   * @author azzetaKarmacharya
   */
  handleisProfileInfoCheckboxClick = (e, groupConfigIndex, studentGroup) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      if (groupConfigs) {
        groupConfigs[groupConfigIndex].isProfileInfo = e.target.checked;
      }
      this.setState({ groupConfigs: groupConfigs });
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      if (studentContactGroups) {
        studentContactGroups[groupConfigIndex].isProfileInfo = e.target.checked;
      }
      this.setState({ studentContactGroups: studentContactGroups });
    }
  };
  /**
   * Changing groupConfig's order
   */
  handleGroupConfigReorderBtnClick = (fromIndex, toIndex) => {
    let groupConfigs = Object.assign([], this.state.groupConfigs);
    let groupConfigToMove = groupConfigs[fromIndex];
    groupConfigs.splice(fromIndex, 1);
    groupConfigs.splice(toIndex, 0, groupConfigToMove);
    this.setState({ groupConfigs: groupConfigs });
  };

  handleRemoveFieldFromGroupConfigBtnClick = ({
    groupConfigIndex,
    fieldIndex,
    studentGroup,
  }) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      groupConfigs[groupConfigIndex].customTableFields.splice(fieldIndex, 1);
      this.setState({ groupConfigs: groupConfigs });
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      studentContactGroups[groupConfigIndex].customTableFields.splice(
        fieldIndex,
        1
      );
      this.setState({ studentContactGroups: studentContactGroups });
    }
  };

  /**
   * Changing groupConfig field's order
   */
  handleGroupConfigFieldsReorderBtnClick = (
    groupConfigIndex,
    fromIndex,
    toIndex,
    studentGroup
  ) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      let customTableFields = groupConfigs[groupConfigIndex].customTableFields;
      let fieldToMove = customTableFields[fromIndex];
      customTableFields.splice(fromIndex, 1);
      customTableFields.splice(toIndex, 0, fieldToMove);
      this.setState({ groupConfigs: groupConfigs });
    } else {
      let newStudentContactGroups = [...this.state.studentContactGroups];
      let customTableFields =
        newStudentContactGroups[groupConfigIndex].customTableFields;
      let fieldToMove = customTableFields[fromIndex];
      customTableFields.splice(fromIndex, 1);
      customTableFields.splice(toIndex, 0, fieldToMove);
      this.setState({ studentContactGroups: newStudentContactGroups });
    }
  };

  // groupConfig name change
  handleGroupConfigNameDoubleClick = (index, studentGroup) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      groupConfigs[index].editMode = true;
      this.setState({ groupConfigs: groupConfigs }, () => {
        document
          .getElementById(
            `admission-groupConfig__update-group-textfield-${index}`
          )
          .focus();
      });
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      studentContactGroups[index].editMode = true;
      this.setState({ studentContactGroups: studentContactGroups }, () => {
        document
          .getElementById(
            `admission-studentContactGroups__update-group-textfield-${index}`
          )
          .focus();
      });
    }
  };

  handleGroupConfigNameBlur = (index, studentGroup) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      groupConfigs[index].editMode = false;
      this.setState({ groupConfigs: groupConfigs });
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      studentContactGroups[index].editMode = false;
      this.setState({ studentContactGroups: studentContactGroups });
    }
  };

  handleGroupConfigNameChange = (e, index, studentGroup) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      groupConfigs[index].name = e.target.value;
      this.setState({ groupConfigs: groupConfigs });
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      studentContactGroups[index].name = e.target.value;
      this.setState({ studentContactGroups: studentContactGroups });
    }
  };

  // setStateAsync(state) {
  //   return new Promise((resolve) => {
  //     this.setState(state, resolve);
  //   });
  // }

  // setCustomFieldToUpdate = async () => {
  //   await this.setStateAsync({ updatingCustomField: true });
  //   this.handleModalToggle();
  // };

  // updating groupConfig field edit
  // handleEditGroupConfigFieldBtnClick = (
  //   groupConfigIndex,
  //   fieldIndex,
  //   studentGroup
  // ) => {
  //   if (studentGroup !== "studentGroup") {
  //     let groupConfigs = Object.assign([], this.state.groupConfigs);
  //     let customTableFields = groupConfigs[groupConfigIndex].customTableFields;
  //     let currentCustomField = Object.assign({}, customTableFields[fieldIndex]);
  //     this.setState(
  //       { currentCustomField: currentCustomField },
  //       this.setCustomFieldToUpdate
  //     );
  //   } else {
  //     let studentContactGroups = [...this.state.studentContactGroups];
  //     let customTableFields =
  //       studentContactGroups[groupConfigIndex].customTableFields;
  //     let currentCustomField = Object.assign({}, customTableFields[fieldIndex]);
  //     this.setState(
  //       { currentCustomField: currentCustomField },
  //       this.setCustomFieldToUpdate
  //     );
  //   }
  // };

  handleEditGroupConfigFieldBtnClick = (
    groupConfigIndex,
    fieldIndex,
    studentGroup
  ) => {
    if (studentGroup !== "studentGroup") {
      let groupConfigs = Object.assign([], this.state.groupConfigs);
      let customTableFields = groupConfigs[groupConfigIndex].customTableFields;
      let currentCustomField = cloneDeep(customTableFields[fieldIndex]);
      this.setState(
        {
          currentCustomField: currentCustomField,
          currentUpdateGroupIndex: groupConfigIndex,
          currentCustomTabFieldIndex: fieldIndex,
          studentGroup: false,
        },
        () => {
          this.setState({ updatingCustomField: true }, () => {
            this.handleModalToggle();
          });
        }
      );
    } else {
      let studentContactGroups = [...this.state.studentContactGroups];
      let customTableFields =
        studentContactGroups[groupConfigIndex].customTableFields;
      let currentCustomField = Object.assign({}, customTableFields[fieldIndex]);
      this.setState(
        {
          currentCustomField: currentCustomField,
          currentUpdateGroupIndex: groupConfigIndex,
          currentCustomTabFieldIndex: fieldIndex,
          studentGroup: true,
        },
        () => {
          this.setState({ updatingCustomField: true }, () => {
            this.handleModalToggle();
          });
        }
      );
    }
  };

  /**
   * deletes group config by array index
   */
  handleGroupConfigDeleteBtnClick = (delIndex) => {
    let newGroupConfigs = [...this.state.groupConfigs];
    newGroupConfigs.splice(delIndex, 1);
    this.setState({ groupConfigs: newGroupConfigs });
  };

  /**
   * Handles field checkbox click to swtich groups.
   * When several customTableFields are selected and switch group is clicked, selected customTableFields will be
   * swichted to selectee group
   */
  handleSwitchGroupCheckboxClick = (e, groupConfigIndex, fieldIndex) => {
    let groupConfigs = Object.assign([], this.state.groupConfigs);
    let field = groupConfigs[groupConfigIndex].customTableFields[fieldIndex];
    field.selectedForGroupSwitch = !field.selectedForGroupSwitch;

    this.setState({ groupConfigs: groupConfigs });
  };

  handleSwitchAllGroupCheckboxClick = (e, groupConfigIndex) => {
    let groupConfigs = Object.assign([], this.state.groupConfigs);
    let customTableFields = groupConfigs[groupConfigIndex].customTableFields;
    customTableFields.forEach((field) => {
      field.selectedForGroupSwitch = e.target.checked;
    });
    this.setState({ groupConfigs: groupConfigs });
  };

  handleGroupSelectChange = (e, groupConfigIndex) => {
    let groupConfigs = Object.assign([], this.state.groupConfigs);
    if (groupConfigs[groupConfigIndex]) {
      groupConfigs[groupConfigIndex].selectedGroupForFieldMoveIndex =
        e.target.value;
      this.setState({ groupConfigs: groupConfigs });
    }
  };

  /**
   * Handle change group btn click
   */
  handleChangeGroupBtnClick = (e, fromGroupConfigIndex) => {
    let groupConfigs = Object.assign([], this.state.groupConfigs);
    let groupConfigFrom = groupConfigs[fromGroupConfigIndex];
    if (groupConfigFrom) {
      let customTableFields = groupConfigFrom.customTableFields;
      if (customTableFields) {
        if (groupConfigFrom.selectedGroupForFieldMoveIndex) {
          let groupConfigTo =
            groupConfigs[groupConfigFrom.selectedGroupForFieldMoveIndex];
          let customTableFieldsToMoveIdxs = [];
          customTableFields.forEach((field, idx) => {
            if (field.selectedForGroupSwitch) {
              field.selectedForGroupSwitch = false;
              customTableFieldsToMoveIdxs.push(idx);
              groupConfigTo.customTableFields.push(field);
            }
          });
          customTableFields = customTableFields.filter(function (value, index) {
            return customTableFieldsToMoveIdxs.indexOf(index) === -1;
          });
          groupConfigFrom.customTableFields = customTableFields;

          this.setState({ groupConfigs: groupConfigs });
        } else {
          swal("Error", "Select atleast one group to move to.", "");
        }
      }
    }
  };

  /**
   * Handle admission configuration submit
   */
  handleSubmit = () => {
    let param = this.prepareParameterForSubmit();
    let identityNumber = 0,
      addressNumber = 0;
    param.recruitmentTableFieldGroups.forEach((group) => {
      const indentityIndex = group.customTableFields.findIndex(
        (field) => field.fieldTypeCode === "pre-peop"
      );
      indentityIndex !== -1 && identityNumber++;
      const addressIndex = group.customTableFields.findIndex(
        (field) => field.fieldTypeCode === "pre-addr"
      );
      addressIndex !== -1 && addressNumber++;
    });
    if (identityNumber > 0 && addressNumber > 0) {
      Swal.fire({
        title: "Warning",
        text:
          "This is a one-time configuration. Are you sure about your configuration?",

        showCancelButton: true, // Add cancel button
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      }).then((result) => {
        if (result.isConfirmed) {
          axiosPost(
            URL.insertRecruitmentFormConfiguration,
            param,
            (response) => {
              if (response.status === 200) {
                displaySuccessAlert(response);
                this.props.getRecruitmentConfiguration();
              }
            },
            (err) => {
              displayErrorAlert(err);
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.close();
        }
      });
      // axiosPost(URL.insertRecruitmentFormConfiguration, param, (response) => {
      //   if (response.status === 200) {
      //     displaySuccessAlert(response);
      //     this.props.getRecruitmentConfiguration();
      //   }
      // });
    } else {
      swal({
        title: "Warning",
        text:
          "Identity cluster and Address cluster must be present in form configuration. Please add them.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
    }
  };

  prepareParameterForSubmit = () => {
    let recruitmentTableFieldGroups = Object.assign(
      [],
      this.state.groupConfigs
    );

    let param = {
      recruitmentTableFieldGroups,
    };
    return param;
  };

  handleCustomFieldColumnNameChange = (e, colIndex, subColIndex, subCol) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    if (subCol === "subCol") {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns[subColIndex][e.target.name] =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    } else {
      currentCustomField.customFieldTableColumns[colIndex][e.target.name] =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }
    this.setState({ currentCustomField: currentCustomField });
  };

  handleCustomFieldColumnTypeChange = (
    e,
    colIndex,
    fieldTypes,
    subColIndex,
    subCol
  ) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    if (fieldTypes) {
      let fieldType = fieldTypes.find((fieldType) => {
        return fieldType.id === parseInt(e.target.value);
      });

      if (subCol === "subCol") {
        currentCustomField.customFieldTableColumns[
          colIndex
        ].customFieldTableSubColumns[subColIndex].fieldTypeId = fieldType.id;
        currentCustomField.customFieldTableColumns[
          colIndex
        ].customFieldTableSubColumns[subColIndex].fieldTypeName =
          fieldType.name;
        currentCustomField.customFieldTableColumns[
          colIndex
        ].customFieldTableSubColumns[subColIndex].fieldTypeCode =
          fieldType.code;
        currentCustomField.customFieldTableColumns[
          colIndex
        ].customFieldTableSubColumns[subColIndex].unalterable = 0;
        currentCustomField.customFieldTableColumns[
          colIndex
        ].customFieldTableSubColumns[subColIndex].isMulti = fieldType.isMulti;
        if (
          currentCustomField.customFieldTableColumns[colIndex]
            .customFieldTableSubColumns[subColIndex].isMulti
        ) {
          currentCustomField.customFieldTableColumns[
            colIndex
          ].customFieldTableSubColumns[
            subColIndex
          ].customFieldTableColumnOptionDatas = [
            {
              optionData: "",
            },
            {
              optionData: "",
            },
          ];
        }
      } else {
        currentCustomField.customFieldTableColumns[colIndex].fieldTypeId =
          fieldType.id;
        currentCustomField.customFieldTableColumns[colIndex].fieldTypeName =
          fieldType.name;
        currentCustomField.customFieldTableColumns[colIndex].fieldTypeCode =
          fieldType.code;
        currentCustomField.customFieldTableColumns[colIndex].unalterable = 0;
        currentCustomField.customFieldTableColumns[colIndex].isMulti =
          fieldType.isMulti;
        if (currentCustomField.customFieldTableColumns[colIndex].isMulti) {
          currentCustomField.customFieldTableColumns[
            colIndex
          ].customFieldTableColumnOptionDatas = [
            {
              optionData: "",
            },
            {
              optionData: "",
            },
          ];
        }
      }
    }
    this.setState({ currentCustomField: currentCustomField });
  };

  handleHasSubColumns = (e, colIndex) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    currentCustomField.customFieldTableColumns[colIndex].hasSubColumns =
      e.target.checked;
    if (e.target.checked) {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns = [];
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns.push({
        fieldTypeId: null,
        customFieldTableColumnOptionDatas: [],
      });
      currentCustomField.customFieldTableColumns[colIndex].rows = [];
      currentCustomField.customFieldTableColumns[colIndex].rows.push({
        rowId: 0,
      });
    } else {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns = [];
      currentCustomField.customFieldTableColumns[colIndex].rows = [];
    }
    this.setState({ currentCustomField });
  };

  handleAddCustomTableFieldColumns = (e, colIndex, subColIndex, subCol) => {
    let currentCustomField = { ...this.state.currentCustomField };
    if (subCol === "subCol") {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns.push({
        fieldTypeId: "",
        customFieldTableColumnOptionDatas: [],
      });
    } else {
      currentCustomField.customFieldTableColumns.push({
        fieldTypeId: "",
        customFieldTableColumnOptionDatas: [],
      });
    }
    this.setState({ currentCustomField: currentCustomField });
  };

  handleRemoveCustomTableFieldColumns = (e, colIndex, subColIndex, subCol) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    if (subCol === "subCol") {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns.splice(subColIndex, 1);
    } else {
      currentCustomField.customFieldTableColumns.splice(colIndex, 1);
    }
    this.setState({ currentCustomField: currentCustomField });
  };

  handleCustomFieldColumnDataChange = (
    e,
    colIndex,
    subColIndex,
    dataIndex,
    subCol
  ) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    if (subCol === "subCol") {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns[
        subColIndex
      ].customFieldTableColumnOptionDatas[dataIndex][e.target.name] =
        e.target.value;
    } else {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableColumnOptionDatas[subColIndex][e.target.name] =
        e.target.value;
    }
    this.setState({ currentCustomField });
  };

  handleAddColumnDatas = (e, colIndex, subColIndex, subCol) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    if (subCol === "subCol") {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns[
        subColIndex
      ].customFieldTableColumnOptionDatas.push({
        optionData: "",
      });
    } else {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableColumnOptionDatas.push({
        optionData: "",
      });
    }
    this.setState({ currentCustomField });
  };

  handleRemoveColumnDatas = (e, colIndex, subColIndex, dataIndex, subCol) => {
    let currentCustomField = Object.assign({}, this.state.currentCustomField);
    if (subCol === "subCol") {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableSubColumns[
        subColIndex
      ].customFieldTableColumnOptionDatas.splice(dataIndex, 1);
    } else {
      currentCustomField.customFieldTableColumns[
        colIndex
      ].customFieldTableColumnOptionDatas.splice(subColIndex, 1);
    }
    this.setState({ currentCustomField });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          New Configuration
          {checkWidgetAdminOrNot("Recruitment") === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="card">
          <div
            className="card-body"
            style={{
              height: "61.1vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {this.state.groupConfigs.length > 0 ? (
              this.state.groupConfigs.map((groupConfig, index) => (
                <AdmissionGroupConfig
                  key={index}
                  studentContactGroup={false}
                  groupConfig={groupConfig}
                  groupConfigs={this.state.groupConfigs}
                  index={index}
                  lastIndex={this.state.groupConfigs.length - 1}
                  handleGroupConfigToggle={this.handleGroupConfigToggle}
                  handleMandatoryToggleBtnToggle={
                    this.handleMandatoryToggleBtnToggle
                  }
                  handleAddCustomFieldBtnClick={
                    this.handleAddCustomFieldBtnClick
                  }
                  handleGroupConfigReorderBtnClick={
                    this.handleGroupConfigReorderBtnClick
                  }
                  handleGroupConfigFieldsReorderBtnClick={
                    this.handleGroupConfigFieldsReorderBtnClick
                  }
                  handleEditGroupConfigFieldBtnClick={
                    this.handleEditGroupConfigFieldBtnClick
                  }
                  handleRemoveFieldFromGroupConfigBtnClick={
                    this.handleRemoveFieldFromGroupConfigBtnClick
                  }
                  handleGroupConfigNameDoubleClick={
                    this.handleGroupConfigNameDoubleClick
                  }
                  handleGroupConfigNameChange={this.handleGroupConfigNameChange}
                  handleGroupConfigNameBlur={this.handleGroupConfigNameBlur}
                  handleSwitchGroupCheckboxClick={
                    this.handleSwitchGroupCheckboxClick
                  }
                  handleSwitchAllGroupCheckboxClick={
                    this.handleSwitchAllGroupCheckboxClick
                  }
                  handleGroupSelectChange={this.handleGroupSelectChange}
                  handleChangeGroupBtnClick={this.handleChangeGroupBtnClick}
                  handleHeaderHiddenCheckboxClick={
                    this.handleHeaderHiddenCheckboxClick
                  }
                  handleisProfileInfoCheckboxClick={
                    this.handleisProfileInfoCheckboxClick
                  }
                  handleGroupConfigDeleteBtnClick={
                    this.handleGroupConfigDeleteBtnClick
                  }
                />
              ))
            ) : (
              <div className="text-center" style={{ marginTop: "200px" }}>
                {this.state.isLoading ? <Spinner color="primary" /> : null}
              </div>
            )}
            {this.state.studentContactGroups
              ? this.state.studentContactGroups.map((groupConfig, idx) => (
                  <AdmissionGroupConfig
                    studentContactGroup={true}
                    index={idx}
                    groupConfigs={this.state.studentContactGroups}
                    groupConfig={groupConfig}
                    handleGroupConfigToggle={this.handleGroupConfigToggle}
                    handleMandatoryToggleBtnToggle={
                      this.handleMandatoryToggleBtnToggle
                    }
                    handleAddCustomFieldBtnClick={
                      this.handleAddCustomFieldBtnClick
                    }
                    handleGroupConfigFieldsReorderBtnClick={
                      this.handleGroupConfigFieldsReorderBtnClick
                    }
                    handleEditGroupConfigFieldBtnClick={
                      this.handleEditGroupConfigFieldBtnClick
                    }
                    handleRemoveFieldFromGroupConfigBtnClick={
                      this.handleRemoveFieldFromGroupConfigBtnClick
                    }
                    handleGroupConfigNameDoubleClick={
                      this.handleGroupConfigNameDoubleClick
                    }
                    handleGroupConfigNameChange={
                      this.handleGroupConfigNameChange
                    }
                    handleGroupConfigNameBlur={this.handleGroupConfigNameBlur}
                    handleSwitchGroupCheckboxClick={
                      this.handleSwitchGroupCheckboxClick
                    }
                    handleSwitchAllGroupCheckboxClick={
                      this.handleSwitchAllGroupCheckboxClick
                    }
                    handleHeaderHiddenCheckboxClick={
                      this.handleHeaderHiddenCheckboxClick
                    }
                    handleisProfileInfoCheckboxClick={
                      this.handleisProfileInfoCheckboxClick
                    }
                  />
                ))
              : null}
          </div>
          <div className="card-footer">
            <button
              className="tt-button float-right tt-button-primary"
              onClick={this.handleSubmit}
            >
              {"Save Configuration"}
            </button>
            <button
              className="tt-button float-right tt-button-primary mr-4"
              onClick={this.handleAddNewGroupBtnClick}
            >
              Add New Group
            </button>
          </div>
        </div>

        {/* To add custom customTableFields */}
        <ModalWindow
          size={"lg"}
          modal={this.state.isModalOpen}
          // toggleModal={this.widgetModal}
          modalHeader={
            this.state.updatingCustomField
              ? "Update Custom Field"
              : "Add Custom Field"
          }
          modalBody={
            <CustomFieldForm
              handleCancelCustomFieldBtnClick={
                this.handleCancelCustomFieldBtnClick
              }
              handleCustomFieldNameChange={this.handleCustomFieldNameChange}
              handleCustomFieldTypeChange={this.handleCustomFieldTypeChange}
              handleSubmitCustomFieldBtnClick={
                this.handleSubmitCustomFieldBtnClick
              }
              handleUpdateCustomFieldBtnClick={
                this.handleUpdateCustomFieldBtnClick
              }
              currentCustomField={this.state.currentCustomField}
              updatingCustomField={this.state.updatingCustomField}
              handleAddDatas={this.handleAddDatas}
              handleRemoveDatas={this.handleRemoveDatas}
              handleCustomFieldDataChange={this.handleCustomFieldDataChange}
              handleAddCustomTableFieldColumns={
                this.handleAddCustomTableFieldColumns
              }
              handleRemoveCustomTableFieldColumns={
                this.handleRemoveCustomTableFieldColumns
              }
              handleCustomFieldColumnNameChange={
                this.handleCustomFieldColumnNameChange
              }
              handleCustomFieldColumnTypeChange={
                this.handleCustomFieldColumnTypeChange
              }
              handleCustomFieldColumnDataChange={
                this.handleCustomFieldColumnDataChange
              }
              handleAddColumnDatas={this.handleAddColumnDatas}
              handleRemoveColumnDatas={this.handleRemoveColumnDatas}
              handleHasSubColumns={this.handleHasSubColumns}
            />
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.isGroupModalOpen}
          modalHeader={"Add New Recruitment"}
          modalBody={
            <GroupForm
              handleCancelGroupBtnClick={this.handleCancelGroupBtnClick}
              handleSubmitNewGroupBtnClick={this.handleSubmitNewGroupBtnClick}
            />
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Recruitment"
              moduleName="Recruitment"
              header="Create Recruitment Configuration"
              activityName="insert-recruitment-configuration"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionToProps = {
  getRecruitmentConfiguration,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(NewRecruitmentConfiguration);
